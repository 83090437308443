import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import Dropdown from '../../../../../materialUi/dropdown';
import Languages from '../../../../../materialUi/languages';
import TextField from '../../../../../materialUi/textField';

function EmergencyContact(props) {
  const {
    control,
    formState,
    register,
    setValue,
    data,
  } = props;
  const { errors } = formState;

  const learnedFrom = [
    { name: 'Word of mouth' },
    { name: 'Social Media' },
    { name: 'Newsletter' },
    { name: 'dropped in' },
    { name: 'Other' },
  ];

  const relationshipList = [
    { name: 'Spouse' },
    { name: 'Child' },
    { name: 'Parent' },
    { name: 'Sibling' },
    { name: 'Grandparents' },
    { name: 'Grandchild' },
  ];

  const [emergencyContactModel, setModel] = React.useState({
    name: '',
    emergencyRelationship: '',
    homePhone: '',
    workPhone: '',
  });

  const addSavedEmergencyContactToForm = (keyName, value) => {
    setValue(`${keyName}`, value, { shouldValidate: true });
  };

  const addSavedEmergencyContact = () => {
    const emergencyContactObj = {};
    Object.keys(data.emergencyContact).forEach((key) => {
      addSavedEmergencyContactToForm(key, data.emergencyContact[key]);
      emergencyContactObj[key] = data.emergencyContact[key];
    });
    /*
      NOTE:
        Needs to send the values to the form. Otherwise it
        will replace when the user press submit again.
    */
    setValue('emergencyContact', emergencyContactObj);
  };

  React.useEffect(() => {
    if (data.emergencyContact) {
      addSavedEmergencyContact();
    }
    if (data.language) {
      setValue('language', data.language);
    }
    if (data.learnedAboutTheCenterFrom) {
      setValue('learnedAboutTheCenterFrom', data.learnedAboutTheCenterFrom);
    }
  }, [data]);

  const saveEmergencyContactModel = (keyName, value) => {
    emergencyContactModel[keyName] = value;
    setModel(emergencyContactModel);
    register('emergencyContact');
    setValue('emergencyContact', emergencyContactModel);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <h2 className="registration-form-sub-title">
          Language Spoken at Home
          </h2>
          <Controller
            name="language"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Dropdown
                name="language"
                label="Language"
                id="language"
                helperText={errors.language ? errors.language.message : 'Optional'}
                error={!!errors.language}
                listName={Languages}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <h2 className="registration-form-sub-title">
          First Learned About the Centre
          </h2>
          <Controller
            name="learnedAboutTheCenterFrom"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Dropdown
                name="learnedAboutTheCenterFrom"
                label="Learned From"
                id="learnedAboutTheCenterFrom"
                helperText={errors.learnedAboutTheCenterFrom ? errors.learnedAboutTheCenterFrom.message : 'Optional'}
                error={!!errors.learnedAboutTheCenterFrom}
                listName={learnedFrom}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} className="registration-form-sub-title">
          Emergency Contact
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            rules={{
              required: 'Relationship is required',
              maxLength: { value: 50, message: 'Max length 50' },
              validate: (value) => saveEmergencyContactModel('name', value),
            }}
            render={({ field }) => (
              <TextField
                id="contactName"
                type="text"
                name="contactName"
                label="Name"
                helperText={errors.name ? errors.name.message : ''}
                error={!!errors.name}
                width="100%"
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md>
          <Controller
            name="emergencyRelationship"
            control={control}
            defaultValue=""
            rules={{
              required: 'Relationship is required',
              maxLength: { value: 50, message: 'Max length 50' },
              validate: (value) => saveEmergencyContactModel('emergencyRelationship', value),
            }}
            render={({ field }) => (
              <Dropdown
                name="contactRelationship"
                label="Relationship"
                id="contactRelationship"
                helperText={errors.emergencyRelationship ? errors.emergencyRelationship.message : ''}
                error={!!errors.emergencyRelationship}
                listName={relationshipList}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md>
          <Controller
            name="homePhone"
            control={control}
            defaultValue=""
            rules={{
              required: 'Home Phone is required',
              maxLength: { value: 20, message: 'Max length 20' },
              pattern: {
                value: /^[\]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/i,
                message: 'Invalid Phone Format',
              },
              validate: (value) => saveEmergencyContactModel('homePhone', value),
            }}
            render={({ field }) => (
              <TextField
                id="contactHomePhone"
                type="text"
                name="contactHomePhone"
                label="Home Phone"
                helperText={errors.homePhone ? errors.homePhone.message : ''}
                error={!!errors.homePhone}
                width="100%"
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md>
          <Controller
            name="workPhone"
            control={control}
            defaultValue=""
            rules={{
              maxLength: { value: 20, message: 'Max length 20' },
              pattern: {
                value: /^[\]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/i,
                message: 'Invalid Phone Format',
              },
              validate: (value) => saveEmergencyContactModel('workPhone', value),
            }}
            render={({
              field: {
                onChange, onBlur, value, ref,
              },
            }) => (
              <TextField
                id="contactWorkPhone"
                type="text"
                name="contactWorkPhone"
                label="Work Phone"
                helperText={errors.workPhone ? errors.workPhone.message : 'Optional'}
                error={!!errors.workPhone}
                width="100%"
                inputRef={ref}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
}

EmergencyContact.propTypes = {
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  formState: PropTypes.shape({
    errors: PropTypes.any,
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  data: PropTypes.shape(
    {
      contactName: PropTypes.string,
      language: PropTypes.string,
      learnedAboutTheCenterFrom: PropTypes.string,
      emergencyContact: PropTypes.shape(
        {
          name: PropTypes.string,
          emergencyRelationship: PropTypes.string,
          homePhone: PropTypes.string,
          workPhone: PropTypes.string,
        },
      ),
    },
  ).isRequired,
};

export default EmergencyContact;
