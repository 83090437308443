export const DATE = function (validation) {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; // January is 0!

    const yyyy = today.getFullYear();
    if (dd < 10) {
        dd = `0${dd}`;
    }
    if (mm < 10) {
        mm = `0${mm}`;
    }
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    if (validation === true) {
        if (mm < 10) {
            mm = `0${mm}`;
        }
        today = `${dd}-${months[mm - 1]}-${yyyy}`;
    } else if (validation === 'defaultDate') {
        today = `${yyyy}-${mm}-${dd}`;
    } else {
        today = `${mm}/${dd}/${yyyy}`;
    }

    return today;
};

export const DATEPLUS = function () {
    const date = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    return date;
};

export const TIME = function (isoTime, validation) {
    const timeStr = isoTime;
    const date = new Date(timeStr);
    let day = date.getDate();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    let hours = date.getHours();
    const minutes = date.getMinutes();
    let ampm = 'am';
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    if (hours === 12) {
        ampm = 'pm';
    } else if (hours === 0) {
        hours = 12;
    } else if (hours > 12) {
        hours -= 12;
        ampm = 'pm';
    }
    let dateStr;
    if (validation === true) {
        day += 1;
        dateStr = `${months[month - 1]} ${day}, ${year}`;
    } else {
        dateStr = `${months[month - 1]} ${day}, ${year} - ${hours}:${minutes} ${ampm}`;
    }
    return dateStr;
};

export const CONVERTTIME = function (isoTime, validation) {
    const timeStr = isoTime;
    const date = new Date(timeStr);
    let day = date.getDate();
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    if (validation === true) {
        if (day < 10) {
            day = `0${day}`;
        }
        var dateStr = `${day}-${months[month - 1]}-${year}`;
    } else if (validation === 'bootstrap') {
        if (month < 10) {
            month = `0${month}`;
        }
        dateStr = `${year}-${month}-${day}`;
    } else if (validation === 'attendance') {
        dateStr = `${months[month - 1]} ${day}, ${year}`;
    } else {
        dateStr = `${months[month - 1]} ${day}`;
    }
    return dateStr;
};
export const CONVERBIRTH = function (isoTime, validation) {
    const timeStr = isoTime;
    const date = new Date(timeStr);
    let day = date.getDate();
    const year = date.getFullYear();
    let month = date.getMonth() + 1;

    if (validation === true) {
        if (day < 10) {
            day = `0${day}`;
        }
        if (month < 10) {
            month = `0${month}`;
        }
        const dateStr = `${year}-${month}-${day}`;

        return dateStr;
    }
};

export const GETMONTHS = function (number) {
    const d = new Date();
    d.setMonth(d.getMonth() - number);
    const dateSliced = d.toString().split(' ');
    return dateSliced[1];
};

export const GETAGE = function getAge(DOB) {
    const today = new Date();
    const birthDate = new Date(DOB);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age -= 1;
    }
    return age;
};

export const COMPARECATEGORY = function getAge(value, category) {
    // FIXTHIS
    // Date calculation wrong by 2 days
    const age = GETAGE(value);
    const splitAge = age.toString().split('');
    if (age < 0) {
        return false;
    }
    if (splitAge.length < 3) {
        if (category === 'Youth') {
            if (age < 13 || age > 17) {
                return false;
            }
        } else if (category === 'Senior') {
            if (age < 65) {
                return false;
            }
        } else if (category === 'Adult') {
            if (age <= 18 && age >= 64) {
                return false;
            }
        } else if (category === 'Child') {
            if (age > 12) {
                return false;
            }
        } else {
            return true;
        }
    }
    return true;
};

export const TIMETOAMPM = function (time) {
    // Check correct time format and split into components
    if (time) {
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) {
            // If time format correct
            time = time.slice(1); // Remove full string match value
            time[5] = +time[0] < 12 ? 'AM' : 'PM '; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(''); // return adjusted time or original string
    }
    return time;
};

export const CHECKPROGRAMAGES = (programAge, familyMemberAge) => {
    // FIXTHIS
    // Date calculation wrong by 2 days
    const age = GETAGE(familyMemberAge);
    const splitAge = age.toString().split('');
    if (age < 0) {
        return false;
    }
    if (splitAge.length < 3) {
        if (programAge === 'Youth (13-17)') {
            if (age >= 13 && age <= 17) {
                return true;
            }
        } else if (programAge === 'Senior') {
            if (age >= 65) {
                return true;
            }
        } else if (programAge === 'Adult (18+)') {
            if (age >= 18 && age <= 64) {
                return true;
            }
        } else if (programAge === 'Children (6-12)') {
            if (age >= 6 && age <= 12) {
                return true;
            }
        } else if (programAge === 'Children (8-12)') {
            if (age >= 8 && age <= 12) {
                return true;
            }
        }
        else if (programAge === 'Pre-school (0-5)') {
            if (age <= 5) {
                return true;
            }
        } else {
            return false;
        }
    }
    return false;
};

export const DATETOSEASON = function (date) {
    let result;
    switch (date.getMonth()) {
        case 12:
        case 1:
        case 2:
            result = 'winter';
            break;
        case 3:
        case 4:
        case 5:
            result = 'spring';
            break;
        case 6:
        case 7:
        case 8:
            result = 'summer';
            break;
        default:
            result = 'fall';
            break;
    }
    return result;
};

export const GETLASTSEASON = function (date) {
    let result;
    switch (date.getMonth()) {
        case 12:
        case 1:
        case 2:
            result = 'fall';
            break;
        case 3:
        case 4:
        case 5:
            result = 'winter';
            break;
        case 6:
        case 7:
        case 8:
            result = 'spring';
            break;
        default:
            result = 'summer';
            break;
    }
    return result;
};

export const calculateAgeGroup = (familyMemberAge) => {
    const age = GETAGE(familyMemberAge);
    const splitAge = age.toString().split('');
    if (splitAge.length < 3) {
        if (age >= 13 && age <= 17) {
            return 'Youth';
        }
        if (age >= 18 && age <= 64) {
            return 'Adult';
        }
        if (age >= 6 && age <= 12) {
            return 'Child';
        }
        if (age <= 5) {
            return 'Pre-school';
        }
        if (age >= 65) {
            return 'Senior';
        }
    }
    return false;
};

export const checkIfSameDate = (inputDate, date) => {
    if (
        new Date(inputDate).getFullYear() === new Date(date).getFullYear() &&
        new Date(inputDate).getMonth() === new Date(date).getMonth() &&
        new Date(inputDate).getDate() === new Date(date).getDate()
    ) {
        return true;
    } else {
        return false;
    }
};
