import React, { Component } from 'react';
import logo from '../../assets/SCCC_LOGO_SMALL.png';
import TextField from '../../materialUi/textField';
import Btn from '../../materialUi/btn';
import Alert from '../../materialUi/Alerts';
import { ResetPassword } from '../../API/API-resetPassword';
import { authenticationAPI } from '../../API/API-authentication';

class resetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordMessage: '',
      checkPassword: '',
      checkPasswordMessage: '',
      openAlert: false,
      disableBtn: false,
    };
  }

  componentDidMount() {
    const { token } = this.props.match.params;
    if (token) {
      authenticationAPI.authentication
        .getUser(token)
        .then((success) => {
          this.setState({
            userId: success.data._id,
            userType: success.data.userType,
            name: `${success.data.firstName} ${success.data.lastName}`,
          });
          console.log(success.data._id);
        })
        .catch((error) => {
          this.props.history.push('/');
        });
    }
  }

  handlePassword = (value) => {
    let message = '';
    if (value.target.value.length < 1) {
      message = 'Password cannot be empty';
    } else if (value.target.value.length < 6) {
      message = 'Password must be at least 6 digits long';
    } else if (!/(?=.*?[A-Z])/.test(value.target.value)) {
      message = 'Password must contains at least one Uppercase';
    } else if (value.target.value.length > 80) {
      message = 'Password cannot exced 80 chars';
    } else {
      message = null;
    }
    this.setState({ password: value.target.value, passwordMessage: message });
  };

  handleCheckPassword = (value) => {
    const { password } = this.state;
    let message = '';
    if (value.target.value.length > 0) {
      if (value.target.value !== password) {
        message = 'Passwords don’t match';
      } else {
        message = null;
      }
    } else {
      message = 'Password cannot be empty';
    }
    this.setState({ checkPassword: value.target.value, checkPasswordMessage: message });
  };

  handleResetPassword = () => {
    const { userId, passwordMessage, checkPasswordMessage, password, userType } = this.state;
    if (passwordMessage === null && checkPasswordMessage === null) {
      this.setState({ disableBtn: true });
      ResetPassword.resetPassword(userId, password)
        .then((success) => {
          console.log(success);
          console.log(userType)
          this.setState({
            openAlert: true,
            alertLocation: userType.toLowerCase() !== 'member' && userType.toLowerCase() !== 'volunteer' ? '/admin' : '/memberLogin',
            alertTitle: 'Email Sent',
            alertBody: 'Password successful reset!. Please login you account again. ',
            alertClose: 'LOGIN',
            invalidEmail: false,
            disableBtn: false,
          });
        })
        .catch((error) => {
          if (error.response.status === 500 || error.response.status === 404) {
            this.setState({
              openAlert: true,
              alertLocation: userType.toLowerCase() !== 'member' && userType.toLowerCase() !== 'volunteer' ? '/adminForgotPassword' : '/memberForgotPassword',
              alertTitle: 'SERVER ERROR!',
              alertBody: 'There is an error in the server. Please try again later!',
              alertClose: 'hidden',
              alertStay: 'CLOSE',
              invalidEmail: false,
              disableBtn: false,
            });
          }
        });
    } else {
      this.setState({
        passwordMessage: passwordMessage === '' ? 'Required field!' : passwordMessage,
        checkPasswordMessage: checkPasswordMessage === '' ? 'Required field!' : checkPasswordMessage,
      });
    }
  };

  render() {
    const { openAlert, alertLocation, alertTitle, alertBody, alertClose, alertStay, password, passwordMessage, disableBtn, checkPassword, checkPasswordMessage, name } = this.state;
    return (
      <div className="container text-center">
        <div className="row  header text-center pt-4">
          <div className="col-12 text-left">
            <div className="-Back-to-Member-lis" onClick={() => this.props.history.push('/')}>
              &lt; Back to SCCC Portal
            </div>
          </div>
          <div className="col-12 text-center mt-5">
            <img src={logo} alt="Logo" />
          </div>
        </div>
        <div className="d-flex flex-row justify-content-center flex-wrap">
          <div className="shadow-sm login-box ">
            <div className="row">
              <div className="col-12">
                <h1 className="Admin-Login ">Forgot Password</h1>
              </div>
              <div className="col-12">
                <h2 className="resetPassword-text">
                  Hi!&nbsp;
                  <b>{name}</b>
                  <br />
                  Enter you new password
                </h2>
              </div>
              <div className="col-12">
                <TextField
                  type="password"
                  label="New Password"
                  id="New Password"
                  value={password}
                  handleChanges={(value) => this.handlePassword(value)}
                  helperText={passwordMessage}
                  error={passwordMessage !== '' && passwordMessage !== null}
                  readOnly={false}
                />
                <TextField
                  type="password"
                  label="Re-enter password"
                  id="Re-enter password"
                  value={checkPassword}
                  handleChanges={(value) => this.handleCheckPassword(value)}
                  helperText={checkPasswordMessage}
                  error={checkPasswordMessage !== '' && checkPasswordMessage !== null}
                  readOnly={false}
                />
              </div>
              <div className="col-12 mt-5 text-center">
                <Btn
                  label="Reset Password"
                  id="Reset Password"
                  disable={disableBtn}
                  onClick={this.handleResetPassword}
                  bgColor="#e76f50"
                  color="white"
                  bgColorHover="#ff8c68"
                  colorHover="white"
                  height={42}
                  width={window.innerWidth < 600 ? 295 : 395}
                />
              </div>
            </div>
            <Alert open={openAlert} close={() => this.setState({ openAlert: false })} location={alertLocation} title={alertTitle} body={alertBody} stay={alertStay} leave={alertClose} />
          </div>
        </div>
      </div>
    );
  }
}

export default resetPassword;
