import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { IconButton, InputAdornment } from '@material-ui/core';
import VisibleIcon from '../assets/icons/show_password.png';
import InvisibleIcon from '../assets/icons/hide_password.png';
import './style.css';

function TextFields(props) {
  const {
    id, name, label, value, error, helperText, type, handleChanges, width, readOnly, endIcon, shrink,
  } = props;

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: error || helperText ? '0px' : '20px',
      width,
      '& .MuiFormHelperText-root.Mui-error': {
        marginTop: '0px !important',
      }
    },
  }));
  const classes = useStyles();


  const [showPassword, setShowPassword] = React.useState(false);

  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  };


  return (
    <TextField
      id={id}
      name={name}
      title={label}
      label={label}
      value={value === null ? '' : value}
      onChange={handleChanges}
      helperText={helperText}
      error={error}
      className={classes.root}
      variant="filled"
      InputProps={{
        readOnly,
        endAdornment: type === 'password'
          ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={showPasswordHandler}
              >
                {showPassword
                  ? (
                    <img
                      src={InvisibleIcon}
                      style={{ height: '25px', cursor: 'pointer' }}
                      alt="showIcon"
                    />
                  ) : (
                    <img
                      src={VisibleIcon}
                      style={{ height: '25px', cursor: 'pointer' }}
                      alt="hideIcon"
                    />
                  )}
              </IconButton>
            </InputAdornment>
          ) : endIcon,
        startAdornment: id === 'amount' && <InputAdornment position="start">$</InputAdornment>,
      }}
      InputLabelProps={{
        shrink,
      }}
      {...props}
      type={showPassword ? 'text' : type}
    />
  );
}

TextFields.prototype = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  helperText: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  type: PropTypes.string,
  handleChanges: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
};

TextFields.defaultProps = {
  type: 'text',
};

export default TextFields;
