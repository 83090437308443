import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import Covid19Portal from '../covid19/covid19';

// settings
import ProfileSettings from '../profile/profile';

const SecurityRoutes = function (userData) {
  return (
    <Switch>
      <Route path="/adminPortal/covid19" render={(props) => <Covid19Portal {...props} userData={userData} />} />
      { /* Profile */ }
      <Route path="/adminPortal/profile" render={(props) => <ProfileSettings {...props} userData={userData} />} />
;
      <Route path="*" render={(props) => <Covid19Portal {...props} userData={userData} />} />
    </Switch>
  );
};

export default SecurityRoutes;

SecurityRoutes.propTypes = {
  userData: PropTypes.shape({
    userType: PropTypes.string,
  }).isRequired,
};
