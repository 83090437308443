import axios from 'axios';
import baseURL from './baseURL';
import TOKEN from './Frontend-Token';

export const ReceiptsAPI = {
  GET: {
    getMembershipReceipts(membershipID) {
      return axios.get(`${baseURL}api/getMembershipReceipts/${membershipID}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    getReceipts() {
      return axios.get(`${baseURL}api/getReceipts`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
  },
};

export default ReceiptsAPI;
