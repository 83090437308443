import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import DemoPie from '../../charts/demographicPie';
import StatusPie from '../../charts/statusPie';

function activeDemographics(props) {
  
  const {
    activeMembers,
    expiringMembers,
    expiredMembers,
    adult,
    youth,
    senior,
    child,
    family,
  } = props.data;

  return (
    <Grid container className="root dashboard-spacing">
      <Grid item xs={6} className="dashboard-tabs-title">Membership Category Status</Grid>
      <Grid item xs={6} className="dashboard-tabs-title">Membership Status</Grid>
      <Grid container spacing={3} style={{ marginTop: '20px' }}>

        <Grid item xs={6}>
          <Paper style={{ padding: '20px' }}>
            <Grid container>
              <Grid item xs={12} lg={9}>
                <DemoPie
                  adult={adult}
                  youth={youth}
                  senior={senior}
                  child={child}
                  family={family}
                />
              </Grid>
              <Grid item xs={12} lg>
                <Grid
                  container
                  direction={window.innerWidth < 1260 ? 'row' : 'column'}
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  style={{ paddingTop: '70px' }}
                >
                  {window.innerWidth > 1260 ? (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <i className="fas fa-circle icon-active" />
                       Child
                      </Grid>
                      <Grid item xs={12}>
                        <i className="fas fa-circle icon-expiring" />
                       Youth
                      </Grid>
                      <Grid item xs={12}>
                        <i className="fas fa-circle icon-adult" />
                       Adult
                      </Grid>
                      <Grid item xs={12}>
                        <i className="fas fa-circle icon-expired" />
                       Senior
                      </Grid>
                      <Grid item xs={12}>
                        <i className="fas fa-circle icon-family" />
                       Family
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      <i className="fas fa-circle icon-active" />
                      Child
                      <i className="fas fa-circle ml-3 icon-expiring" />
                      Youth
                      <i className="fas fa-circle ml-3  icon-adult" />
                      Adult
                      <i className="fas fa-circle ml-3  icon-expired" />
                      Senior
                      <i className="fas fa-circle ml-3  icon-family" />
                      Family
                    </>
                  )}

                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={6}>
          <Paper style={{ padding: '20px' }}>
            <Grid container>
              <Grid item xs={12} lg={9}>
                <StatusPie
                  active={activeMembers}
                  expiring={expiringMembers}
                  expired={expiredMembers}
                />
              </Grid>
              <Grid item xs={12} lg>
                <Grid
                  container
                  direction={window.innerWidth < 1260 ? 'row' : 'column'}
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  style={window.innerWidth > 1260 ? { paddingTop: '100px' } : { paddingTop: '94px' }}
                >
                  {window.innerWidth > 1260 ? (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <i className="fas fa-circle icon-active" />
                    Active
                      </Grid>
                      <Grid item xs={12}>
                        <i className="fas fa-circle icon-expiring" />
                    Expiring
                      </Grid>
                      <Grid item xs={12}>
                        <i className="fas fa-circle icon-expired" />
                    Expired
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      <i className="fas fa-circle ml-3 icon-active" />
                    Active
                      <i className="fas fa-circle ml-3 icon-expiring" />
                    Expiring
                      <i className="fas fa-circle ml-3 icon-expired" />
                    Expired
                    </>
                  )}


                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
}

activeDemographics.propTypes = {
  activeMembers: PropTypes.number.isRequired,
  expiringMembers: PropTypes.number.isRequired,
  expiredMembers: PropTypes.number.isRequired,
  adult: PropTypes.number.isRequired,
  youth: PropTypes.number.isRequired,
  senior: PropTypes.number.isRequired,
  child: PropTypes.number.isRequired,
  family: PropTypes.number.isRequired,
};


export default activeDemographics;
