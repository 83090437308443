import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import Checkbox from '../../../../materialUi/checkBox';
import SearchTextField from '../../../../materialUi/searchTextField';
import '../../../../css/registrationList.css';

export default function Filter({
  search,
  handleFilter,
  values,
  displaySort,
}) {
  return (
    <Grid item xs={12}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Box className="registrationList-searchField">
          <SearchTextField label="Search" search={(value) => search(value.target.value)} width={200} />
        </Box>
        {
          (displaySort)
            ? (
              <div className="col-12 col-md-1 p-0 mt-3">
                <div className="dropdown mt-2">
                  <button className="btn btn-white dropdown-toggle mr-2 admin-name" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span className="mr-2 Filter">Status</span>
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <span className="dropdown-item"><Checkbox fontSize={15} bottom={0} value={values.approved} click={(value) => handleFilter('approved', value, 'Approved')} message="Approved" /></span>
                    <span className="dropdown-item"><Checkbox fontSize={15} bottom={0} value={values.pending} click={(value) => handleFilter('pending', value, 'Pending')} message="Pending" /></span>
                  </div>
                </div>
              </div>
            ) : ''
      }

      </Grid>
    </Grid>
  );
}

Filter.propTypes = {
  handleFilter: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  values: PropTypes.shape({
    approved: PropTypes.bool,
    pending: PropTypes.bool,
  }).isRequired,
  displaySort: PropTypes.bool.isRequired,
};
