import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Paper } from '@material-ui/core';
import Icon from '../../../../assets/icons/information.png';
import '../../../../css/payment.css';

function Fees({ data }) {
  return (
    <Grid item xs className="fees-col">
      <Paper className="payment-boxes-fees">
        <Box className="fee-title">Membership Fees</Box>
        <hr className="fees-hr" />
        <Grid className="payment-boxes-fees-spacing">
          <Grid container>
            <Grid item xs>
              <span className="fees-labels">Category</span>
            </Grid>
            <Grid item xs>
              <span className="fees-labels">Type</span>
            </Grid>
            <Grid item xs>
              <span className="fees-labels">Total Amount</span>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              {(data) && data.membership.membershipCategory}
              &nbsp;
              <img src={Icon} alt="Question mark" width="12" height="12" />
            </Grid>
            <Grid item xs>
              {(data) && data.membership.membershipType}
            </Grid>
            <Grid item xs>
              {(data) && data.membership.fee !== 'Free' && (<>$</>)}
              {(data) && data.membership.fee}
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      {/* <Grid container className="membershipCard-box">
        <Grid item xs>
          <Box className="Payment-title">Membership Card</Box>
          <Box className="Membership-card-text">
              To successfully complete the membership, scan the barcode on the back of the
              membership card.
            {' '}
          </Box>
          <Grid container>
            <Grid item xs={12} className="membershipCard-error" id={membershipCardError}>
                Membership cards are required!
            </Grid>
            <Grid item xs={12}>
              {familyMembersTodisplay.map((option, index) => (
                <Grid container key={index} style={{ marginTop: '20px' }}>
                  <Grid item xs={4}>
                    {index === 0 ? (
                      <span>
                        <b>
                          {option.firstName}
                            &nbsp;
                          {option.lastName}
:
                        </b>
                          (Primary)
                      </span>
                    ) : (
                      <b>
                        {option.firstName}
                          &nbsp;
                        {option.lastName}
                      </b>
                    )}
                  </Grid>
                  <Grid item xs>
                    <BootstrapTextField
                      id={`membershipCard${index}`}
                      type="text"
                      name="Membership Card"
                      value={values[`membershipCard${index}`]}
                      handleChanges={(value) => this.props.handleInputs(
                        `membershipCard${index}`,
                        value.target.value,
                        'name',
                        `${option.firstName} Card`,
                        index,
                      )}
                      message={messages[`membershipCard${index}`]}
                      readOnly={false}
                      error={errors[`membershipCard${index}`]}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}
    </Grid>
  );
}

Fees.propTypes = {
  data: PropTypes.shape(
    {
      membership: PropTypes.shape(
        {
          membershipCategory: PropTypes.string,
          membershipType: PropTypes.string,
          fee: PropTypes.number,
        },
      ),
    },
  ).isRequired,
};

export default Fees;
