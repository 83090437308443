import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import ReceiptPrint from './receiptPrint';
import { TIME } from '../../../../utils/dateController';
import '../../../../css/receipts.css';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class ReceiptAlert extends React.Component {

  handlePrint = () => {
    const { membershipID } = this.props;
    const printContent = document.getElementById('receipt').innerHTML;
    document.body.innerHTML = printContent;
    window.print();
    window.location = `/adminPortal/membership/receipts/${membershipID}`;
  };

  render() {
    const {
      receiptData,
      open,
      close,
      sendEmail,
    } = this.props;
    let street; let city; let province; let
      postalCode;
    if (receiptData.address) {
      street = receiptData.address.street;
      city = receiptData.address.city;
      province = receiptData.address.province;
      postalCode = receiptData.address.postalCode;
    }

    return (
      <>
        <Dialog
          onClose={() => close()}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth="md"
          fullWidth
          className="font-family-default"
        >
          <DialogContent dividers>
            <Grid container style={{ marginBottom: '15px' }}>
              <Grid item xs className="receipt-alert-title">
                 Receipt
              </Grid>
              <Grid>
                <Box onClick={() => close()} role="button"><i className="fas fa-times close-icon" /></Box>
              </Grid>
            </Grid>

            <Grid container>
              <Box>
                <i className="fas fa-check-circle fa-2x receipt-alert-icon" />
              </Box>
              <Box className="receipt-alert-successfull-text">
                  This transaction was successfully processed
              </Box>
            </Grid>

            <Grid container className="receipt-alert-body-title">
              <Grid item xs={12}>
                <Box border={1} borderRadius={4} borderColor="lightGrey" className="receipt-alert-box">
                  <Box className="receipt-alert-box-title">Receipt Details</Box>
                  <Grid container spacing={2} className="receipt-box-body">
                    <Grid item xs={12} md>
                      <Grid container>
                        <Grid item xs={6} md={4}>
                          Reference#:
                        </Grid>
                        <Grid item xs className="receipt-alert-body-text">
                          {receiptData.id}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md>
                      <Grid container>
                        <Grid item xs={6} md={4}>
                          Payment Method:
                        </Grid>
                        <Grid item xs className="receipt-alert-body-text">
                          {receiptData.paymentMethod}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="receipt-box-body">
                    <Grid item xs={12} md>
                      <Grid container>
                        <Grid item xs={6} md={4}>
                          Name:
                        </Grid>
                        <Grid item xs className="receipt-alert-body-text">
                          {receiptData.name}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md>
                      <Grid container>
                        <Grid item xs={6} md={4}>
                          Card Number:
                        </Grid>
                        <Grid item xs className="receipt-alert-body-text">
                          N/A
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="receipt-box-body">
                    <Grid item xs={12} md>
                      <Grid container>
                        <Grid item xs={6} md={4}>
                          Address:
                        </Grid>
                        <Grid item xs className="receipt-alert-body-text">
                          {street}
,
                          {' '}
                          {city}
,
                          {' '}
                          {province}
,
                          {' '}
                          {postalCode}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md>
                      <Grid container>
                        <Grid item xs={6} md={4}>
                          Card Type:
                        </Grid>
                        <Grid item xs className="receipt-alert-body-text">
                          {receiptData.cardType}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="receipt-box-body">
                    <Grid item xs={12} md>
                      <Grid container>
                        <Grid item xs={6} md={4}>
                          Date & Time:
                        </Grid>
                        <Grid item xs className="receipt-alert-body-text">
                          {TIME(receiptData.paymentDateISO, false)}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md>
                      <Grid container>
                        <Grid item xs className="receipts-alert-line">
                          <hr />
                        </Grid>
                        <Grid item xs />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="receipt-box-body">
                    <Grid item xs={12} md>
                      <Grid container>
                        <Grid item xs={6} md={4}>
                          Category:
                        </Grid>
                        <Grid item xs className="receipt-alert-body-text">
                          {receiptData.category}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md>
                      <Grid container>
                        <Grid item xs={6} md={4}>
                          Total:
                        </Grid>
                        <Grid item xs className="receipt-alert-body-text">
                          ${receiptData.amount}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button className="alert-btns" onClick={this.handlePrint}>
              Print
            </Button>
            <Button className="alert-btns" onClick={() => sendEmail()}>
              Email
            </Button>
          </DialogActions>
        </Dialog>
        <div className="hidden" id="receipt"><ReceiptPrint receiptData={receiptData} /></div>
      </>

    );
  }
}

ReceiptAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  membershipID: PropTypes.string.isRequired,
  receiptData: PropTypes.shape({
    id: PropTypes.number,
    address: PropTypes.objectOf(PropTypes.string),
    paymentMethod: PropTypes.string,
    name: PropTypes.string,
    cardType: PropTypes.string,
    paymentDateISO: PropTypes.string,
    category: PropTypes.string,
    amount: PropTypes.number,
  }).isRequired,
  close: PropTypes.func.isRequired,
  sendEmail: PropTypes.func.isRequired,
};


export default ReceiptAlert;
