import axios from 'axios';
import openSocket from 'socket.io-client';
import baseURL from './baseURL';
import TOKEN from './Frontend-Token';

const socket = openSocket(baseURL);

export const Covid19API = {
  POST: {
    addNewGuest(newGuestObj) {
      return axios.post(`${baseURL}api/addNewGuest`, newGuestObj, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    addDailyCheckIn(todayDate) {
      return axios.post(
        `${baseURL}api/addDailyCheckIn`,
        { todayDate },
        {
          headers: { 'x-access-token': TOKEN },
        }
      );
    },
    userCheckIn(userCheckInObj) {
      return axios.post(`${baseURL}api/userCheckIn`, userCheckInObj, {
        headers: { 'x-access-token': TOKEN },
      });
    },
  },
  PUT: {
    userCheckout(dailyCheckInID) {
      return axios.put(
        `${baseURL}api/userCheckout/${dailyCheckInID}`,
        {},
        {
          headers: { 'x-access-token': TOKEN },
        }
      );
    },
    changeCovid19Capacity(capacityID, dailyCheckIn) {
      return axios.put(
        `${baseURL}api/changeCovid19Capacity/${capacityID}`,
        {
          dailyCheckIn,
        },
        {
          headers: { 'x-access-token': TOKEN },
        }
      );
    },
  },
  GET: {
    getAllGuests() {
      return axios.get(`${baseURL}api/getAllGuests`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    getDailyCheckIn(todayDate) {
      return axios.get(`${baseURL}api/getDailyCheckIn/${todayDate}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    getCovid19Capacity() {
      return axios.get(`${baseURL}api/getCovid19Capacity`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    getUserCheckIns() {
      return axios.get(`${baseURL}api/getUserCheckIns`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    getSpecificUserCheckIn(userID) {
      return axios.get(`${baseURL}api/getSpecificUserCheckIn/${userID}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    getAllDailyCheckIns() {
      return axios.get(`${baseURL}api/getAllDailyCheckIns`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    getDailyCheckInByID(dailyCheckInID) {
      return axios.get(`${baseURL}api//getDailyCheckInByID/${dailyCheckInID}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    getUseCheckInByDailyID(dailyCheckInID) {
      return axios.get(`${baseURL}api/getUseCheckInByDailyID/${dailyCheckInID}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
  },
  EntranceUpdate: {
    checkIn(dailyCheckInObj, userAlreadyCheckedIn) {
      socket.emit('check-in', dailyCheckInObj, userAlreadyCheckedIn);
    },
    checkOut(dailyCheckInObj) {
      socket.emit('check-out', dailyCheckInObj);
    },
    getEntranceStatus(cb) {
      socket.on('getEntranceStatus', (updatedNumbers) => cb(null, updatedNumbers));
    },
    addNewGuest(addNewGuest, dailyCheckInObj) {
      socket.emit('addNewGuest', addNewGuest, dailyCheckInObj);
    },
    updateUsersList(cb) {
      socket.on('updateUsersList', (newGuest) => cb(null, newGuest));
    },
    updateUserInformation(selectedUser, phone) {
      socket.emit('updateUserInformation', (selectedUser, phone));
    },
    getInitialData() {
      const dateObj = new Date();
      const month = dateObj.getMonth() + 1;
      const day = dateObj.getDate();
      const year = dateObj.getFullYear();
      const newdate = `${month}-${day}-${year}`;
      socket.emit('getInitialData', newdate);
    },
  },
};

export default Covid19API;
