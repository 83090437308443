import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import QuestionnaireButtons from '../questionnaireButtons';
import FirstTimeTaxes from './components/firstTimeTaxes';
import AddressSection from './components/addressSection';
import Officer from './components/officer';
import '../../../../css/taxes.css';

function StepTwo({ data, handleNextStep, handlePrevious }) {
  const useFormMethods = useForm({ mode: 'all' });
  const {
    handleSubmit,
    setValue,
  } = useFormMethods;

  const [formAction, setFormAction] = React.useState('');

  const handleFormAction = (action) => {
    setFormAction(action);
  };

  const handleFormSubmit = (formData) => {
    console.log(formData)
    if (formAction === 'save') {
      handleNextStep(formData, 1);
    } else {
      handleNextStep(formData, 2);
    }
  };

  React.useEffect(() => {
    if (data.currentAddress) {
      setValue('firstTimeTax', data.firstTimeTax, { shouldValidate: true });
      setValue('firstTimeTaxPrint', data.firstTimeTaxPrint, { shouldValidate: true });
      setValue('residing', data.residing, { shouldValidate: true });
      setValue('incomeOutsideCanada', data.incomeOutsideCanada, { shouldValidate: true });
      setValue('foreignProperty', data.foreignProperty, { shouldValidate: true });
      setValue('sameHomeAddress', data.sameHomeAddress, { shouldValidate: true });
      setValue('currentAddress', data.currentAddress, { shouldValidate: true });
      setValue('isAnOfficer', data.isAnOfficer, { shouldValidate: true });
      setValue('workInCanada', data.workInCanada, { shouldValidate: true });
      setValue('differentEmployers', data.differentEmployers, { shouldValidate: true });
      setValue('receivedBenefits', data.receivedBenefits, { shouldValidate: true });
      setValue('appliedForBenefits', data.appliedForBenefits, { shouldValidate: true });
      setValue('benefits', data.benefits, { shouldValidate: true });
      if (data.extraAddress) {
        if (data.extraAddress.length > 0 ) {
          setValue('extraAddress', data.extraAddress, { shouldValidate: true });
        }
      }
    }
  }, [data]);

  return (
    <FormProvider {...useFormMethods}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container>

          {/* Form Body */}
          <FirstTimeTaxes {...useFormMethods} data={data} />
          <AddressSection {...useFormMethods} data={data} />
          <Officer {...useFormMethods} data={data} />
        </Grid>

        {/* Action Buttons */}
        <hr className="tax_custom_hr" />
        <QuestionnaireButtons
          handleAction={(value) => handleFormAction(value)}
          handlePrevious={handlePrevious}
        />
      </form>
    </FormProvider>
  );
}

export default withRouter(StepTwo);
