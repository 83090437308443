import React from 'react';
import { Grid } from '@material-ui/core';
import { CardAPI } from '../../../API/API-Card';
import { ProgramRegistrationAPI } from '../../../API/API-programRegistration';
import Alert from '../../../materialUi/Alerts';
import Loading from '../../../materialUi/loading';
import Payment from '../../../materialUi/programs/payment/payment';
import Success from '../../../materialUi/programs/success/success';
import BackButton from '../../../materialUi/backButton';

function WaitingListRegistration(props) {

  const { userData, match, history } = props;

  const [step, setStep] = React.useState(0);
  const [data, setData] = React.useState(null);
  const [programData, setProgramData] = React.useState(null);
  const [fee, setFee] = React.useState(10);
  const [alertValues, setAlertValues] = React.useState({});
  const [paymentStatus, setPaymentStatus] = React.useState(false);

  const checkIfMembershipIDMatchesRegistration = (data) => {
    let idToCompare = '';
    if (userData.userData.userType === 'Member') {
      idToCompare = userData.userData.membershipID;
    } else {
      idToCompare = match.params.membershipID;
    }
    const membershipData = data.membershipData[0] || data.membershipData;
    const registrationMembershipID = membershipData.membershipID
    if (idToCompare !== registrationMembershipID) {
      return setAlertValues({
        openAlert: true,
        alertLocation: '/',
        alertTitle: 'Validation Error!',
        alertBody: 'The membership registered in the waiting list does not correspond with the user logged in.',
        alertClose: 'hidden',
        alertStay: 'CLOSE',
      });
    }
  };

  const getRegularProgramVariableFee = (data) => {
    const { membershipData, programData } = data;
    if (membershipData.membershipType.toLowerCase() === 'associate') {
      return programData[0].associateFee;
    }
    return programData[0].communityFee;
  };

  const getRegularProgramFee = (data) => {
    const { variableFee, fee } = data.programData[0];
    if (variableFee && variableFee.toLowerCase() === 'yes') return getRegularProgramVariableFee(data);
    return fee;
  };

  const getProgramFee = (data) => {
    const { familyMembers } = data;
    const fee = getRegularProgramFee(data);
    return fee * familyMembers.length;
  };

  const getProgramRegistrationInformation = async () => {
    const { waitingListID } = props.match.params;
    await ProgramRegistrationAPI.GET.getRegistrationByWaitingListID(waitingListID)
      .then((success) => {
        const { programData } = success.data[0];
        setData(success.data[0]);
        setProgramData(programData[0]);
        checkIfMembershipIDMatchesRegistration(success.data[0]);
        setFee(getProgramFee(success.data[0]));
        if (success.data[0].paymentStatus !== 'pending') return setStep(1);
      })
      .catch((error) => {
        console.log(error)
        setAlertValues({
          openAlert: true,
          alertLocation: '/',
          alertTitle: 'Server Error!',
          alertBody: 'Please try again later.',
          alertClose: 'hidden',
          alertStay: 'CLOSE',
        });
      });
  };

  const handleFee = (newFee) => {
    setFee(parseFloat(newFee));
  };

  const addReceiptInformation = (paymentMethod, cardType) => {
    const { membershipData } = data;
    const { membershipID, familyMembersInformation } = membershipData;
    const copyData = data;
    copyData.receipt = {
      membershipID: membershipID,
      fee: fee,
      paymentMethod,
      cardType,
      referenceNumber: Math.floor(100000 + Math.random() * 90000000),
      name: `${familyMembersInformation[0].firstName} ${familyMembersInformation[0].lastName}`,
      status: 'Approved',
      category: 'Program Registration',
      address: membershipData.address,
      paymentDate: new Date(),
      description: programData.name
    };
    return copyData;
  };

  const submitProgramRegistration = async (newData) => {
    const { waitingListData } = newData;
    const { membershipID } = props.match.params;
    newData.waitingListID = waitingListData[0]._id;
    newData.step = 1;
    sessionStorage.setItem(`waitingList_${membershipID}`, JSON.stringify(newData));
    setData(newData);
    await ProgramRegistrationAPI.PUT.programWaitingListPayment(newData, newData._id)
      .then((response) => {
        console.log(response.data);
        setStep(1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleApprovedCardPayment = async (responseData) => {
    const cardType = responseData.result.payment.card_details.card.card_brand;
    const newData = addReceiptInformation('Credit card', cardType);
    newData.receipt.cardType = cardType;
    newData.receipt.cardInformation = responseData.result.payment;
    await submitProgramRegistration(newData);
  };

  const formateCardPaymentFee = (fee) => {
    return fee.toString().includes('.') ? fee.toString().replace('.', '') : `${fee.toString()}00`;
  };

  const submitCardPayment = (nonce, cardHolderName) => {
    const { membershipID } = props.match.params;
    CardAPI.Square.cardPayment(
      nonce,
      membershipID,
      `Program Registration,  Membership: ${membershipID}, Card Holder Name: ${cardHolderName}`,
      `${formateCardPaymentFee(fee)}`,
    )
      .then(async (response) => {
        await handleApprovedCardPayment(response.data);
      })
      .catch((error) => {
        setPaymentStatus(true);
        console.log(error);
        setAlertValues({
          openAlert: true,
          alertLocation: '/',
          alertTitle: 'Server Error!',
          alertBody: 'Payment Declined. Please, check the data entered.',
          alertClose: 'hidden',
          alertStay: 'CLOSE',
        });
      });
  };

  const submitCashPayment = (type) => {
    const newData = addReceiptInformation(type, type);
    submitProgramRegistration(newData);
  };

  const handleSubmitPayment = (paymentObj) => {
    const { type, nonce, cardHolderName } = paymentObj;
    if (type === 'card') {
      submitCardPayment(nonce, cardHolderName);
    } else {
      submitCashPayment(type);
    }
  };

  // TODO: Needs to be removed after we merge the adminPortal with scccPortal
  const typeOfPortal = () => {
    const { userType } = userData.userData;
    if (userType.toLowerCase() !== 'member' && userType.toLowerCase() !== 'public') {
      return 'adminPortal';
    }
    return 'scccPortal';
  };

  const handleLeave = () => {
    sessionStorage.clear();
    window.location = `/${typeOfPortal()}/programs/available/`;
  };

  const handlePrint = () => {
    const printContent = document.getElementById('receipt').innerHTML;
    document.body.innerHTML = printContent;
    window.print();
    window.location = history.location.pathname;
  };

  React.useEffect(() => {
    async function fetchData() {
      await getProgramRegistrationInformation()
    }

    const { membershipID } = props.match.params;
    const session = JSON.parse(sessionStorage.getItem(`waitingList_${membershipID}`));
    if (session) {
      setStep(session.step);
      if (data === null) {
        setProgramData(session.programData)
        setData(session);
      }
    } else {
      if (data === null) fetchData();
    }
  }, [step, data]);

  if (!programData) return <Loading top="50%" right="40%" />

  return (
    <>
      <Grid container className="programRegistration-wrapper">
        <Grid item xs={12}>
          <BackButton
            handleClick={() => history.push(`/adminPortal/programs/registrationList/${match.params.programID}`)}
            label={'Back to registration list'}
          />
        </Grid>
      </Grid>
      {!step && (
        <Payment
          data={data}
          userData={userData}
          programData={programData}
          handleSubmitPayment={handleSubmitPayment}
          handleFee={handleFee}
          fee={fee}
          initialFee={getProgramFee(data) * data.familyMembers.length}
          paymentStatus={paymentStatus}
        />
      )}


      {step === 1 && (
        <Success data={data} handleLeave={handleLeave} handlePrint={handlePrint} />
      )}

      <Alert
        open={alertValues.openAlert}
        close={() => setAlertValues(oldObject => ({
          ...oldObject,
          openAlert: false
        }))}
        location="/"
        title={alertValues.alertTitle}
        body={alertValues.alertBody}
        stay={alertValues.alertClose}
        leave={alertValues.alertStay}
      />

    </>
  )
}

export default WaitingListRegistration