import React from 'react';
import { CONVERTTIME } from '../../../../../utils/dateController';

function AdminTableExport({
  filteredReports,
}) {

  const formatStatus = (status) => {
    if (status === 'missingInfo') {
      return 'Missing Info';
    }
    if (status === 'inReview') {
      return 'In Review';
    }
    return status;
  };

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Form ID</th>
            <th scope="col">Application Name</th>
            <th scope="col">Submission Date</th>
            <th scope="col">Email</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredReports.map((item, index) => (
            <tr key={index}>
              <td>
                {item.formID}
              </td>
              <td>
                {item.fullName}
              </td>
              <td>
               {CONVERTTIME(item.createdAt, true)}
              </td>
              <td>
                {item.email}
              </td>
              <td>
                {formatStatus(item.status)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

AdminTableExport.propTypes = {

};

export default AdminTableExport;
