/* eslint-disable no-mixed-operators */
import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import Script from 'react-load-script';
import TextField from '../../../../../materialUi/textField';
import DateTextField from '../../../../../materialUi/dateTextField';
import '../../../../../css/registerMember.css';

const GOOGLE_API_KEY = `${process.env.REACT_APP_GOOGLE_API_KEY}`;

function NewAddress(props) {
  const {
    formState,
    setValue,
    clearErrors,
    setError,
    addressID,
    unregister,
    register,
    data,
    getValues,
    formState: { errors },
    control,
  } = props;

  // SAVE EXTRA ADDRESS TO FORM 
  const handleExtraAddress = (newExtraAddress) => {
    const copyExtraAddress = getValues('extraAddress');
    copyExtraAddress[addressID] = newExtraAddress;
    setValue('extraAddress', copyExtraAddress);
    clearErrors(`extraAddress${addressID}`);

    console.log(getValues('extraAddress'));

    setValue(`extraAddress${addressID}`,
     `${copyExtraAddress[addressID].street}, ${copyExtraAddress[addressID].city}, ${copyExtraAddress[addressID].province}`
    );
  };

  const addExtraAddressValue = (name, value) => {
    const copyExtraAddress = getValues('extraAddress');
    copyExtraAddress[addressID][name] = value;
    setValue('extraAddress', copyExtraAddress);
    console.log(getValues('extraAddress'));
  };

  const handleApartment = (value) => {
    if (value && value.length > 20) return 'Max length 20';
    addExtraAddressValue('apt', value);
    return true;
  };

  const setAddressErrors = () => {
    setValue(`extraAddress${addressID}`, 'Invalid Address', { shouldValidate: true });
    setError(`extraAddress${addressID}`, {
      type: 'invalidAddress',
      message: 'The selected address is invalid.',
    });
  };

  const checkIfDateIsValid = (date, name) => {
    if (date.toString() === 'Invalid Date') {
      return 'Invalid Date Format';
    }
    addExtraAddressValue(name, date);
    return true;
  };

  const handleStartDate = (value) => {
    const END_DATE = getValues(`extraAddressEndDate${addressID}`)
      ? new Date(getValues(`extraAddressEndDate${addressID}`))
      : false;

    const START_DATE = new Date(value);

    if (END_DATE && START_DATE >= END_DATE) {
      return 'Start Date cannot be more than End Date';
    }
    return checkIfDateIsValid(value, 'startDate');
  };

  const handleEndDate = (value) => {
    const START_DATE = getValues(`extraAddressStartDate${addressID}`)
      ? new Date(getValues(`extraAddressStartDate${addressID}`))
      : false;

    const END_DATE = new Date(value);

    if (START_DATE && END_DATE <= START_DATE) {
      return 'End Date must be more than Start Date';
    }
    return checkIfDateIsValid(value, 'endDate');
  };

  const validateAddress = (value) => {
    const currentAddress = `${getValues('extraAddress')[addressID].street}, ${getValues('extraAddress')[addressID].city}, ${getValues('extraAddress')[addressID].province}`;
    if (value !== currentAddress) return 'Please start typing the street number, and select the address from the list'
    return true;
  };

  const setLocalAddress = (extraAddress) => {
    setValue(`extraAddress${addressID}`,
     `${extraAddress[addressID].street}, ${extraAddress[addressID].city}, ${extraAddress[addressID].province}`
    );
    setValue(`extraAddressApt${addressID}`, extraAddress[addressID].apt);
    setValue(`extraAddressStartDate${addressID}`, extraAddress[addressID].startDate);
    setValue(`extraAddressEndDate${addressID}`, extraAddress[addressID].endDate);
    console.log(addressID);
  };

  // <--- Address autocomplete validation --->
  const handlePlaceSelect = () => {
    const addressObject = autocomplete.getPlace();
    const address = addressObject.address_components;
    if (address) {
      if (
        address[0] == null
        || address[1] == null
        || address[2] == null
        || address[5] == null
        || address[6] == null
      ) {
        setAddressErrors();
      } else {
        let postalCode;
        for (let i = 0; i < address.length; i++) {
          for (let j = 0; j < address[i].types.length; j++) {
            if (address[i].types[j] === 'postal_code') {
              postalCode = address[i].long_name;
            }
          }
        }
        const newExtraAddress = {
          street: `${address[0].long_name} ${address[1].long_name}`,
          city: address[2].long_name,
          province: address[5].long_name,
          postalCode,
          apt: getValues('extraAddress')[addressID].apt,
          startDate: getValues('extraAddress')[addressID].startDate,
          endDate: getValues('extraAddress')[addressID].endDate,
        };
        handleExtraAddress(newExtraAddress);
      }
    } else {
      setAddressErrors();
    }
  };

  const options = { types: ['address'], componentRestrictions: { country: 'ca' } };
  let autocomplete;

  const handleScriptLoad = () => {
    autocomplete = new google.maps.places.Autocomplete(document.getElementById(`autocomplete${addressID}`), options);
    /* global google */
    autocomplete.addListener('place_changed', handlePlaceSelect);
  };

  React.useEffect(() => {
    if (getValues('extraAddress') && getValues('extraAddress')[addressID].street) {
      setLocalAddress(getValues('extraAddress'));
    }
    console.log(getValues('extraAddress'));
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Script
          url={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`}
          onLoad={handleScriptLoad}
        />
        <Grid item xs={12} lg>
          <Controller
              name={`extraAddress${addressID}`}
              control={control}
              rules={{
                required: 'Address is required',
                minLength: { value: 1, message: 'Max length 1' },
                validate: (value) => validateAddress(value)
              }}
              render={({ field }) => (
                <TextField
                  id={`autocomplete${addressID}`}
                  type="search"
                  name={`extraAddress${addressID}`}
                  label={`Address ${addressID + 1}`}
                  helperText={errors[`extraAddress${addressID}`] ? errors[`extraAddress${addressID}`].message : ''}
                  error={!!errors[`extraAddress${addressID}`]}
                  width="100%"
                  {...field}
                />
              )}
            />
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
        <Controller
              name={`extraAddressApt${addressID}`}
              control={control}
              rules={{
                validate: (value) => handleApartment(value)
              }}
              render={({ field }) => (
                <TextField
                  label="Apt#"
                  id={`extraAddressApt${addressID}`}
                  name="Apt Number"
                  value={getValues('extraAddress')[addressID].apt}
                  helperText={errors[`extraAddressApt${addressID}`] ? errors[`extraAddressApt${addressID}`].message : ''}
                  error={!!errors[`extraAddressApt${addressID}`]}
                  {...field}
                />
              )}
            />
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <TextField
            label="Postal Code"
            id={`extraAddressPostalCode${addressID}`}
            name="Postal Code"
            value={getValues('extraAddress')[addressID].postalCode}
            handleChanges={() => {}}
            helperText=""
            error={false}
            readOnly
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={3} xl={2}>
          <Controller
            name={`extraAddressStartDate${addressID}`}
            control={control}
            rules={{
              required: 'Start Date is Required',
              validate: (value) => handleStartDate(value),
            }}
            render={({ field }) => (
              <DateTextField
                label="Start Date"
                name={`extraAddressStartDate${addressID}`}
                id={`extraAddressStartDate${addressID}`}
                helperText={errors[`extraAddressStartDate${addressID}`] && errors[`extraAddressStartDate${addressID}`].message}
                error={errors[`extraAddressStartDate${addressID}`] && errors[`extraAddressStartDate${addressID}`]}
                readOnly={false}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3} xl={2}>
          <Controller
            name={`extraAddressEndDate${addressID}`}
            control={control}
            rules={{
              required: 'End Date is Required',
              validate: (value) => handleEndDate(value),
            }}
            render={({ field }) => (
              <DateTextField
                label="End Date"
                name={`extraAddressEndDate${addressID}`}
                id={`extraAddressEndDate${addressID}`}
                helperText={errors[`extraAddressEndDate${addressID}`] && errors[`extraAddressEndDate${addressID}`].message}
                error={errors[`extraAddressEndDate${addressID}`] && errors[`extraAddressEndDate${addressID}`]}
                readOnly={false}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
}

NewAddress.propTypes = {
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  formState: PropTypes.shape({
    errors: PropTypes.any,
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  addressID: PropTypes.number.isRequired,
  unregister: PropTypes.number.isRequired,
  data: PropTypes.shape(
    {
      extraAddress: PropTypes.string,
    },
  ).isRequired,
};


export default NewAddress;
