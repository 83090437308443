import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import StepButtons from '../../../../materialUi/stepButtons';
import BackButton from '../../../../materialUi/backButton';
import BasicInformation from './basicInformation';
import ProgramFee from './programFee';
import Demographics from './demographics';

function ProgramInformation({ handleNextStep, history, data }) {
  const useFormMethods = useForm({ mode: 'all' });
  const {
    handleSubmit,
    setValue,
    register,
    watch,
  } = useFormMethods;

  const handleFormSubmit = (formData) => {
    handleNextStep(formData, 1);
  };

  React.useEffect(() => {
    watch('dropIn');
    if (data.name) {
      setValue('programID', data.programID);
      setValue('name', data.name, { shouldValidate: true });
      setValue('programType', data.programType, { shouldValidate: true });
      setValue('dropIn', data.dropIn, { shouldValidate: true });
      setValue('weeklyProgram', data.weeklyProgram, { shouldValidate: true });
      setValue('enrollmentStartDate', data.enrollmentStartDate, { shouldValidate: true });
      setValue('enrollmentEndDate', data.enrollmentEndDate, { shouldValidate: true });
      setValue('programDescription', data.programDescription, { shouldValidate: true });
      setValue('variableFee', data.variableFee, { shouldValidate: true });
      setValue('fee', data.fee, { shouldValidate: true });
      setValue('associateFee', data.associateFee, { shouldValidate: true });
      setValue('communityFee', data.communityFee, { shouldValidate: true });
      setValue('gender', data.gender, { shouldValidate: true });
      setValue('membershipType', data.membershipType, { shouldValidate: true });
      setValue('numberOfSpots', data.numberOfSpots, { shouldValidate: true });
      setValue('communitySpots', data.communitySpots, { shouldValidate: true });
      setValue('associateSpots', data.associateSpots, { shouldValidate: true });
      setValue('ages', data.ages, { shouldValidate: true });
      setValue('ageMinimum', data.ageMinimum, { shouldValidate: true });
      setValue('ageMaximum', data.ageMaximum, { shouldValidate: true });
    } else {
      setValue('enrollmentStartDate', null);
      setValue('enrollmentEndDate', null);
      register('programID');
      setValue('programID', Math.floor(100000 + Math.random() * 90000000));
      watch('programID');
    }
  }, []);

  return (
    <FormProvider {...useFormMethods}>
      <form onSubmit={handleSubmit(handleFormSubmit)} className="addNewProgram-wrapper">
        <BackButton
          handleClick={() => {
            history.push('/adminPortal/programs/available');
          }}
          label="Back to Programs List"
        />
        <Grid container spacing={2} style={{ paddingTop: '15px' }}>
          <BasicInformation {...useFormMethods} data={data} />
          <ProgramFee {...useFormMethods} data={data} />
          <Demographics {...useFormMethods} data={data} />
        </Grid>

        {/* Action Buttons */}
        <hr className="tax_custom_hr" />
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
          <StepButtons
            location="/adminPortal/programs/available"
            handleSubmit={() => { }}
            type="submit"
          />
        </Grid>
      </form>
    </FormProvider>
  );
}

ProgramInformation.propTypes = {
  handleNextStep: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  data: PropTypes.shape(
    {
      name: PropTypes.string,
      programType: PropTypes.string,
      dropIn: PropTypes.bool,
      weeklyProgram: PropTypes.bool,
      enrollmentStartDate: PropTypes.string,
      enrollmentEndDate: PropTypes.string,
      programDescription: PropTypes.string,
      variableFee: PropTypes.bool,
      fee: PropTypes.number,
      associateFee: PropTypes.string,
      communityFee: PropTypes.string,
      gender: PropTypes.string,
      membershipType: PropTypes.string,
      numberOfSpots: PropTypes.string,
      communitySpots: PropTypes.number,
      associateSpots: PropTypes.string,
      ages: PropTypes.string,
      ageMinimum: PropTypes.string,
      ageMaximum: PropTypes.string,
    },
  ).isRequired,
};

export default withRouter(ProgramInformation);
