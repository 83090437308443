import React, { Component } from "react";
import PropTypes from 'prop-types'; 
import AttendeesHeader from './components/attendeesHeader';
import AttendeesTable from './components/attendessTable';
import { ProgramsAPI } from '../../../API/API-programs';

// Define the class component
class EditAttendance extends Component {
    constructor(props) {
      super(props);
      const { match } = this.props;
      this.state = {
        addedMembers: [],
        programID: match.params.programID, // Ensure match.params.programID exists and is correct
        programName: '',
        programEnrolmentEndDate: '',
        numberOfSpots: 0,
        fee: 0,
        search: '',
        programData: {},
        openAlert: false,
        alertLocation: '',
        alertTitle: '',
        alertBody: '',
        alertClose: '',
        tableSelected: '',
        values: {},
        userData: { userData: { userType: '' } }, // Initialize with default structure
        attendanceNumberCheck: false,
        headerTotals: 0,
        weeklySchedule: [],
        selectedWeek: 0,
        messages: [],
      };
    }

    componentDidMount() {
        window.scroll({ top: 0, behavior: 'smooth' });
        const { programID } = this.state;
        
    
        // get program information
        ProgramsAPI.GET.getProgramByID(programID)
          .then((success) => {
            this.setState({
              programName: success.data.program[0].name,
              programEnrolmentEndDate: success.data.program[0].enrollmentEndDate,
              numberOfSpots: success.data.program[0].numberOfSpots,
              fee: success.data.program[0].fee,
              programData: success.data.program[0],
            });
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              openAlert: true,
              alertLocation: '/adminPortal/programs',
              alertTitle: 'Program not found!',
              alertBody: 'We could not find the program. Please try again later!',
              alertClose: 'CLOSE',
            });
          });
    }

    render() {
        const { history } = this.props;
      const {
        programName,
        values,
        userData,
        attendanceNumberCheck,
        headerTotals,
        weeklySchedule,
        selectedWeek,
        addedMembers,
        messages,
      } = this.state;

      return (
        <>
          <AttendeesHeader
            programName={programName}
            handleChangeTable={(value) => this.setState({ tableSelected: value })}
            handleAddNewProgram={this.handleAddNewRegistrant}
            handleBack={() => history.push('/adminPortal/programs/available')}
            values={values}
            userType={userData.userData.userType}
            attendanceNumberCheck={attendanceNumberCheck}
            headerTotals={headerTotals}
            weeklySchedule={weeklySchedule}
            selectedWeek={selectedWeek}
            handleDate={this.handleDate}
            messages={messages}
            handleReset={this.resetFilters}
            handleSelectedWeek={(index) => this.setState({ selectedWeek: index })}
          />
          <AttendeesTable
        programID={0}
        attendanceData={addedMembers}
      />
        </>
      );
    }
}

EditAttendance.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programID: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.object.isRequired,
};

export default EditAttendance;
