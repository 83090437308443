import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import '../../../../css/registrationList.css';

function createData(membershipID, firstName, lastName, phone, id, programAttendanceID) {
  return {
    membershipID,
    firstName,
    lastName,
    phone,
    id,
  };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

// collums titels
const headRows = [
  {
    id: 'membershipID',
    numeric: false,
    disablePadding: true,
    label: 'Membership ID',
  },
  {
    id: 'firstName',
    numeric: false,
    disablePadding: false,
    label: 'First Name',
  },
  {
    id: 'lastName',
    numeric: false,
    disablePadding: false,
    label: 'Last Name',
  },
  {
    id: 'phone',
    numeric: false,
    disablePadding: false,
    label: 'Phone',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: '',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="bg-light">
      <TableRow>
        <TableCell padding="checkbox" className="check" />
        {headRows.map((row) => (
          <TableCell
            key={row.id}
            align={row.numeric ? 'center' : 'left'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
            className="table-cell"
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => <div />;

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

export default function AttendeesTable({
  attendanceData,
  sortSearch,
  handleDeleteParticipant,
  handleSelectParticipant,
}) {
  const rows = [];
  if (attendanceData) {
    for (let i = 0; i < attendanceData.familyMembers.length; i++) {
      const phone = attendanceData.familyMembers[i].phone
        ? attendanceData.familyMembers[i].phone
        : 'N/A';
      if (sortSearch) {
        if (
          attendanceData.familyMembers[i].firstName
            .toLowerCase()
            .includes(sortSearch.toLowerCase()) ||
          attendanceData.familyMembers[i].lastName
            .toLowerCase()
            .includes(sortSearch.toLowerCase()) ||
          attendanceData.familyMembers[i].membershipID.includes(sortSearch)
        ) {
          rows.push(
            createData(
              attendanceData.familyMembers[i].membershipID,
              attendanceData.familyMembers[i].firstName,
              attendanceData.familyMembers[i].lastName,
              phone,
              attendanceData.familyMembers[i]._id,
            )
          );
        }
      } else {
        rows.push(
          createData(
            attendanceData.familyMembers[i].membershipID,
            attendanceData.familyMembers[i].firstName,
            attendanceData.familyMembers[i].lastName,
            phone,
            attendanceData.familyMembers[i]._id,
          )
        );
      }
    }
  }

  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="button"
                      tabIndex={0}
                      className="table-tabSelection"
                      key={row.id}
                    >
                      <TableCell padding="checkbox" className="check" />
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        className="table-cell"
                        onClick={(event) => handleSelectParticipant(row.membershipID, row.id)}
                      >
                        {row.membershipID}
                      </TableCell>
                      <TableCell
                        align="left"
                        onClick={(event) => handleSelectParticipant(row.membershipID, row.id)}
                        className="text-capitalize"
                      >
                        {row.firstName}
                      </TableCell>
                      <TableCell
                        align="left"
                        onClick={(event) => handleSelectParticipant(row.membershipID, row.id)}
                        className="text-capitalize"
                      >
                        {row.lastName}
                      </TableCell>
                      <TableCell
                        align="left"
                        onClick={(event) => handleSelectParticipant(row.membershipID, row.id)}
                      >
                        {row.phone}
                      </TableCell>
                      <TableCell align="center">
                        <DeleteIcon
                          className="settings-delete-icon"
                          fontSize="small"
                          onClick={() =>
                            handleDeleteParticipant({
                              name: `${row.firstName} ${row.lastName}`,
                              familyMemberID: row.id,
                              membershipID: row.membershipID,
                            })
                          }
                        />
                        <span
                          className="settings-delete"
                          onClick={() =>
                            handleDeleteParticipant({
                              name: `${row.firstName} ${row.lastName}`,
                              familyMemberID: row.id,
                              membershipID: row.membershipID,
                            })
                          }
                        >
                          Delete
                        </span>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && <TableRow style={{ height: 100 * emptyRows }} />}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

AttendeesTable.propTypes = {
  sortSearch: PropTypes.string.isRequired,
  handleDeleteParticipant: PropTypes.func.isRequired,
  handleSelectParticipant: PropTypes.func.isRequired,
  attendanceData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      familyMembers: PropTypes.arrayOf(
        PropTypes.shape({
          phone: PropTypes.string,
          firstName: PropTypes.string,
          lastName: PropTypes.string,
          membershipID: PropTypes.string,
        })
      ),
    }),
  ]).isRequired,
};
