import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { withRouter, Link } from 'react-router-dom';
import { Grid, Box } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import logo from '../../assets/SCCC_LOGO_SMALL.png';
import BackButtons from '../../materialUi/backButton';
import TextField from '../../materialUi/textField';
import Button from '../../materialUi/btn';
import { authenticationAPI } from '../../API/API-authentication';
import Alert from '../../materialUi/Alerts';
import './login.css';

function AdminLogin({ history }) {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    clearErrors,
  } = useForm({ mode: 'all' });

  React.useEffect(() => {
    authenticationAPI.authentication
      .getUser(Cookies.get('token'))
      .then((success) => {
        if (success.data.userType !== 'Member' && success.data.userType !== 'provisory member') {
          history.push('/adminPortal/dashboard');
        } else {
          history.push('/scccPortal/membership');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [openErrorMessage, setOpenErrorMessage] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState({
    title: '',
    body: '',
  });

  const sendUserToRightRoute = (userType) => {
    if (userType === 'Receptionist') {
      window.location = '/adminPortal/membership';
    } else if (userType === 'Program Staff') {
      window.location = '/adminPortal/programs';
    } else if (userType === 'Tax Volunteer') {
      window.location = '/adminPortal/reports';
    } else if (userType === 'provisory member') {
      window.location = '/scccPortal/taxes/questionnaire';
    } else {
      window.location = '/adminPortal/dashboard';
    }
  };

  const setDialogError = (title, body) => {
    const copyErrorMessage = errorMessage;
    copyErrorMessage.title = title;
    copyErrorMessage.body = body;
    setErrorMessage(copyErrorMessage);
    setOpenErrorMessage(true);
  };

  const setFieldsErrors = (name, message) => {
    setError(name, {
      type: 'Required',
      message,
    });
  };

  const checkIfUserIsAdmin = (userType) => {
    if (userType !== 'Member' && userType !== 'Volunteer' && userType !== 'provisory member') {
      return true;
    }
    return false;
  };

  const handleLogin = (data) => {
    authenticationAPI.authentication
      .login(data.email, data.password)
      .then((success) => {
        if (checkIfUserIsAdmin(success.data.userType)) {
          Cookies.set('token', success.data.token, { expires: 1 });
          sessionStorage.clear();
          sendUserToRightRoute(success.data.userType);
        } else {
          setDialogError('PERMISSION ERROR!', 'The users is not and admin, please visit the member login page');
          clearErrors('email');
          clearErrors('password');
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            return setFieldsErrors('password', 'Invalid Password! Please Try Again.');
          }
          if (error.response.status === 400) {
            return setFieldsErrors('email', 'User Not found! Please Try Again.');
          }
          if (error.response.status === 500 || error.response.status === 404) {
            return setDialogError('SERVER ERROR!', 'There is an error in the server. Please try again later!');
          }
        }
        return '';
      });
  };

  return (
    <Grid container className="logins-wrapper">
      <BackButtons label="Back to onboarding" handleClick={() => history.push('/')} />
      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Box>
            <img src={logo} alt="Sccc Logo" className="login-logo" />
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Box className="login-boxes">
            <form onSubmit={handleSubmit(handleLogin)}>
              <h1 className="login-title">Admin Login</h1>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: 'Email is required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'Invalid Email Format',
                      },
                    }}
                    render={({ field }) => (
                      <TextField id="email" type="search" name="email" label="Email address" helperText={errors.email ? errors.email.message : ''} error={!!errors.email} width="100%" {...field} />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: 'Password is required',
                    }}
                    render={({ field }) => (
                      <TextField
                        id="password"
                        type="password"
                        name="password"
                        label="Password"
                        helperText={errors.password ? errors.password.message : ''}
                        error={!!errors.password}
                        width="100%"
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} align="end">
                  <Link to="/adminForgotPassword" className="links ">
                    <span className="FORGOT-PASSWORD mr-2">FORGOT PASSWORD?</span>
                  </Link>
                </Grid>
                <Grid item xs={12} className="login-button-wrapper">
                  <Button label="Login" type="submit" onClick={() => {}} bgColor="#e76f50" color="white" bgColorHover="#ff8c68" colorHover="white" width="100%" height={42} />
                </Grid>
              </Grid>
            </form>
          </Box>
        </Grid>
      </Grid>
      <Alert open={openErrorMessage} close={() => setOpenErrorMessage(false)} location="" title={errorMessage.title} body={errorMessage.body} stay="Close" leave="hidden" />
    </Grid>
  );
}

AdminLogin.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(AdminLogin);
