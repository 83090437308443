import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '../../../../materialUi/btn';


export default function ViewDocumentDialog({
  open, setOpen, document,
}) {
  return (
    <Dialog
      open={open}
      onClose={setOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="View Documents"
      fullWidth
      maxWidth="lg"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="text-center">
            <img src={(document) && document.filePath} alt="document" />
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: '8px 24px' }}>
        <Button
          label="CLOSE"
          bgColor="#e76f50"
          color="white"
          colorHover="white"
          bgColorHover="#ff8c68"
          width={128}
          onClick={setOpen}
          type="submit"
        />
      </DialogActions>
    </Dialog>
  );
}

ViewDocumentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  document: PropTypes.string.isRequired,
  data: PropTypes.shape(
    {
      taxReturnFiles: PropTypes.arrayOf(PropTypes.any),
    },
  ).isRequired,
};
