import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import TextField from '../../../../materialUi/textField';
import RadioGroup from '../../../../materialUi/radioGroup';


function ProgramFee(props) {
  const {
    control,
    register,
    formState: { errors },
    unregister,
    getValues,
    watch,
    data,
  } = props;

  const maritalChangeList = [
    {
      value: 'no',
      label: 'Common Fee',
    },
    {
      value: 'yes',
      label: 'Community & Associate Fee',
    },
  ];

  const removeValues = (valuesArray) => {
    valuesArray.forEach((item) => {
      unregister(item);
      if (data[item]) {
        delete data[item];
      }
    });
  };

  const handleVariableFee = (value) => {
    if (value === 'no') {
      removeValues(['communityFee', 'associateFee']);
    } else {
      removeValues(['fee']);
    }
  };

  React.useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' });
    register('variableFee', 'no');
    watch('variableFee');
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <h1 className="addNewProgram-titles">
          Program Fee
        </h1>
      </Grid>
      <Grid item xs={12} style={{ paddingBottom: '0px' }}>
        <Controller
          name="variableFee"
          control={control}
          defaultValue="no"
          rules={{
            required: 'Please select one',
            validate: (value) => handleVariableFee(value),
          }}
          render={({ field }) => (
            <RadioGroup
              listOptions={maritalChangeList}
              ariaLabel="Type of Fee, "
              name="Variable Fee"
              inline
              {...field}
            />
          )}
        />
      </Grid>
      {getValues('variableFee') === 'yes' ? (
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Controller
                name="communityFee"
                control={control}
                rules={{
                  required: getValues('variableFee') === 'yes' ? 'Community Fee is required' : false,
                  maxLength: { value: 100, message: 'Max length 100' },
                  pattern: {
                    value: /^(\d*([.,](?=\d{3}))?\d+)+((?!\2)[.,]\d\d)?$/i,
                    message: 'Invalid Fee Format',
                  },
                }}
                render={({ field }) => (
                  <TextField
                    id="amount"
                    type="number"
                    name="communityFee"
                    label="Community Fee"
                    helperText={errors.communityFee ? errors.communityFee.message : ''}
                    error={!!errors.communityFee}
                    width="100%"
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="associateFee"
                control={control}
                rules={{
                  required: getValues('variableFee') === 'yes' ? 'Community Fee is required' : false,
                  maxLength: { value: 100, message: 'Max length 100' },
                  pattern: {
                    value: /^(\d*([.,](?=\d{3}))?\d+)+((?!\2)[.,]\d\d)?$/i,
                    message: 'Invalid Fee Format',
                  },
                }}
                render={({ field }) => (
                  <TextField
                    id="amount"
                    type="search"
                    name="associateFee"
                    label="Associate Fee"
                    helperText={errors.associateFee ? errors.associateFee.message : ''}
                    error={!!errors.associateFee}
                    width="100%"
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Controller
            name="fee"
            control={control}
            rules={{
              required: 'Fee is required',
              maxLength: { value: 5, message: 'Max length 5' },
              pattern: {
                value: /^(\d*([.,](?=\d{3}))?\d+)+((?!\2)[.,]\d\d)?$/i,
                message: 'Invalid Fee Format',
              },
            }}
            render={({ field }) => (
              <TextField
                id="amount"
                type="search"
                name="fee"
                label="Fee"
                helperText={errors.fee ? errors.fee.message : ''}
                error={!!errors.fee}
                width="100%"
                {...field}
              />
            )}
          />
        </Grid>
      ) }
    </>
  );
}

ProgramFee.propTypes = {
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  formState: PropTypes.shape({
    errors: PropTypes.any,
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
  data: PropTypes.shape(
    {
      variableFee: PropTypes.bool,
      fee: PropTypes.string,
      communityFee: PropTypes.string,
      associateFee: PropTypes.string,
    },
  ).isRequired,
};

export default ProgramFee;
