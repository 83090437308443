import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {
     TableContainer, Checkbox,
  } from '@mui/material';
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import '../../../../css/registrationList.css';

function createData(membershipID, firstName, lastName, phone, id) {
  return {
    membershipID,
    firstName,
    lastName,
    phone,
    id,
  };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

// collums titels
const headRows = [
  {
    id: "membershipID",
    numeric: false,
    disablePadding: true,
    label: "Membership ID",
  },
  {
    id: "firstName",
    numeric: false,
    disablePadding: false,
    label: "First Name",
  },
  {
    id: "lastName",
    numeric: false,
    disablePadding: false,
    label: "Last Name",
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: false,
    label: "Phone",
  },
];



function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="bg-light">
      <TableRow>
        <TableCell padding="checkbox" className="check">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            style={{ padding: '0 16px' }}
            inputProps={{ 'aria-label': 'select all items' }}
          />
        </TableCell>
        {headRows.map((row) => (
          <TableCell
            key={row.id}
            align={row.numeric ? "center" : "left"}
            
            sortDirection={orderBy === row.id ? order : false}
            className="table-cell"
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => <div />;

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  check: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export default function AttendeesTable({ attendanceData  }) {
  const rows = [];
  if (attendanceData ) {
    for (let i = 0; i < attendanceData.length; i++) {
      const phone = attendanceData[i].phone ? attendanceData[i].phone : "N/A";

      rows.push(
        createData(
            attendanceData[i].membershipID,
          attendanceData[i].firstName,
          attendanceData[i].lastName,
          phone,
          attendanceData[i]._id
        )
      );
    }
  }

  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    console.log(event);
    if (event.target.checked) {
      const newSelecteds = membershipData.map((n) => n.name);
      setSelected(newSelecteds);
      console.log('Selected rows:', newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

//   function handleChangeRowsPerPage(event) {
//     setRowsPerPage(+event.target.value);
//   }


  const handleSelect = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
    console.log('Selected rows:', newSelected);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, membershipData.length - page * rowsPerPage);


  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <EnhancedTableToolbar numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={(event, property) => {
                const isAsc = orderBy === property && order === 'asc';
                setOrder(isAsc ? 'desc' : 'asc');
                setOrderBy(property);
              }}
              rowCount={membershipData.length} />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={setOrder}
              rowCount={membershipData.length}
            />
            <TableBody>
              {membershipData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleSelect(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      className={classes.tableHoverNone}
                    >
                      <TableCell padding="checkbox" className={classes.check}>
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="default"
                        className={classes.tableCell}
                      >
                        {row.membershipId}
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        {row.firstName}
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        {row.lastName}
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        {row.phone}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 100 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={membershipData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

AttendeesTable.propTypes = {
  attendanceData: PropTypes.arrayOf(
    PropTypes.shape({
      phone: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    })
  ).isRequired,
  membershipID: PropTypes.string,
  addData: PropTypes.bool,
};

const membershipData = [
    {
      membershipId: 'M001',
      firstName: 'John',
      lastName: 'Doe',
      phone: '123-456-7890'
    },
    {
      membershipId: 'M002',
      firstName: 'Jane',
      lastName: 'Smith',
      phone: '234-567-8901'
    },
    {
      membershipId: 'M003',
      firstName: 'Michael',
      lastName: 'Johnson',
      phone: '345-678-9012'
    },
    {
      membershipId: 'M004',
      firstName: 'Emily',
      lastName: 'Davis',
      phone: '456-789-0123'
    },
    {
      membershipId: 'M005',
      firstName: 'Daniel',
      lastName: 'Wilson',
      phone: '567-890-1234'
    },
    {
      membershipId: 'M006',
      firstName: 'Emma',
      lastName: 'Taylor',
      phone: '678-901-2345'
    },
    {
      membershipId: 'M007',
      firstName: 'David',
      lastName: 'Moore',
      phone: '789-012-3456'
    },
    {
      membershipId: 'M008',
      firstName: 'Sophia',
      lastName: 'Brown',
      phone: '890-123-4567'
    },
    {
      membershipId: 'M009',
      firstName: 'James',
      lastName: 'Anderson',
      phone: '901-234-5678'
    },
    {
      membershipId: 'M010',
      firstName: 'Olivia',
      lastName: 'Thomas',
      phone: '012-345-6789'
    }
  ];