import React from 'react';
import { Grid } from '@material-ui/core';
import { membershipAPI } from '../API/API-membership';

function DataUpload(props) {

  const tsvToJson = (tsv) => {
    const [headers, ...rows] = tsv.trim().split('\n').map(r => r.split ('\t'))
    return rows.reduce((a, r) => [
      ...a, 
      Object.assign(...(r.map(
        (x, i, _, c = x.trim()) => ({[headers[i].trim()]: isNaN(c) ? c : Number(c)})
      )))
    ], [])
  }

  const handleQuestionUpload = (event) => {
    const selectedFile = event.target.files;
    const reader = new FileReader();
    reader.readAsText(selectedFile[0]);

    reader.onload = (read) => {
      const data = read.target.result;
      console.log(tsvToJson(data));
      const formateJson = tsvToJson(data);
      for (let i = 0; i < formateJson.length; i++) {
        membershipAPI.POST.importMemberships(formateJson[i]).then((response) => {
          console.log(response);
        }).catch((error) => {
          console.log(error);
        });
      }
    };
  };


  return (
    <Grid container >
      <Grid item xs={12}>
        <h1>Please, upload the TSV file:</h1>
        <input type="file" name="dataUpload" accept=".tsv" onChange={handleQuestionUpload} />
      </Grid>
    </Grid>
  );
}

export default DataUpload;
