import axios from 'axios';
import baseURL from './baseURL';
import { formatePhoneNumber } from '../utils/supportFunctions';
import TOKEN from './Frontend-Token';

export const membershipAPI = {
  POST: {
    membershipRegistration(membershipToSaveOnDB) {
      return axios.post(`${baseURL}api/membershipRegistration`, membershipToSaveOnDB);
    },
    importMemberships(data) {
      return axios.post(
        `${baseURL}api/importMemberships`,
        data,
        {
          headers: { 'x-access-token': TOKEN },
        },
      );
    },
    adminRegisterMembership(membershipToSaveOnDB, familyMembers, user, receipt) {
      return axios.post(
        `${baseURL}api/adminRegisterMembership`,
        {
          membershipID: membershipToSaveOnDB.membershipID,
          primaryMember: membershipToSaveOnDB.primaryMember,
          email: membershipToSaveOnDB.email,
          address: membershipToSaveOnDB.address,
          emergencyContact: membershipToSaveOnDB.emergencyContact,
          language: membershipToSaveOnDB.language,
          learnedAboutTheCenterFrom: membershipToSaveOnDB.learnedAboutTheCenterFrom,
          membershipType: membershipToSaveOnDB.membershipType,
          status: membershipToSaveOnDB.status,
          registrationDate: membershipToSaveOnDB.registrationDate,
          expirationDate: membershipToSaveOnDB.expirationDate,
          firstRegistered: membershipToSaveOnDB.firstRegistered,
          membershipCategory: membershipToSaveOnDB.membershipCategory,
          shareEmail: membershipToSaveOnDB.shareEmail,
          fee: membershipToSaveOnDB.fee,
          membershipCard: membershipToSaveOnDB.membershipCard,
          familyMembers,
          user,
          receipt,
        },
        {
          headers: { 'x-access-token': TOKEN },
        }
      );
    },
  },
  GET: {
    getAllMemberships() {
      return axios.get(`${baseURL}api/getMemberships`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    getMembershipByID(membershipID) {
      return axios.get(`${baseURL}api/getByMembershipID/${membershipID}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    getByProgramID(programRegistrationID) {
      return axios.get(`${baseURL}api/getByProgramRegistrationID/${programRegistrationID}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
  },
  PUT: {
    renewMembership(data) {
      return axios.put(
        `${baseURL}api/renewMembership/${data.membershipID}`,
        data,
        {
          headers: { 'x-access-token': TOKEN },
        },
      );
    },

    editOneMembership(membershipID, membershipToSaveOnDB) {
      return axios.put(
        `${baseURL}api/editOneMembership/${membershipID}`,
        {
          address: membershipToSaveOnDB.address,
          emergencyContact: membershipToSaveOnDB.emergencyContact,
          language: membershipToSaveOnDB.language,
          learnedAboutTheCenterFrom: membershipToSaveOnDB.learnedAboutTheCenterFrom,
          email: membershipToSaveOnDB.email,
          membershipType: membershipToSaveOnDB.membershipType,
          primaryMember: membershipToSaveOnDB.primaryMember,
        },
        {
          headers: { 'x-access-token': TOKEN },
        }
      );
    },
    verifyAddress(membershipID, membershipToSaveOnDB, familyMembersToSaveOnDB) {
      return axios.put(
        `${baseURL}api/verifyAddress/${membershipID}`,
        {
          membershipCard: membershipToSaveOnDB.membershipCard,
          expirationDate: membershipToSaveOnDB.expirationDate,
          status: membershipToSaveOnDB.status,
          familyMembers: familyMembersToSaveOnDB,
          receipt: membershipToSaveOnDB.receipt,
        },
        {
          headers: { 'x-access-token': TOKEN },
        }
      );
    },
    changeMembershipCategory(membershipID, membershipCategory, receipt) {
      return axios.put(
        `${baseURL}api/changeMemberCategory/${membershipID}`,
        {
          membershipCategory,
          receipt,
        },
        {
          headers: { 'x-access-token': TOKEN },
        }
      );
    },
    changeMembershipCard(ID, membershipCard, primaryMemberID) {
      return axios.put(
        `${baseURL}api/changeMembershipCard/${ID}`,
        {
          membershipCard,
          primaryMemberID,
        },
        {
          headers: { 'x-access-token': TOKEN },
        }
      );
    },
    renewMembershipMemberPortal(membershipID, receipt) {
      return axios.put(
        `${baseURL}api/renewMembershipMemberPortal/${membershipID}`,
        {
          receipt,
        },
        {
          headers: { 'x-access-token': TOKEN },
        }
      );
    },
    deleteMember(membershipID) {
      return axios.put(
        `${baseURL}api/deleteMember/${membershipID}`,
        {},
        {
          headers: { 'x-access-token': TOKEN },
        }
      );
    },
    editMembershipEmail(membershipID, email) {
      return axios.put(
        `${baseURL}api/editMembershipEmail/${membershipID}`,
        {
          email,
        },
        {
          headers: { 'x-access-token': TOKEN },
        }
      );
    },
  },
};

export default membershipAPI;
