import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import SearchTextField from '../../../../../materialUi/searchTextField';
import DatePicker from '../../../../../materialUi/datePicker';
import Checkbox from '../../../../../materialUi/checkBox';
import '../../../../../css/receipts.css';

export default function filter({
  handleDate,
  values,
  messages,
  handleReset,
  handleSearch,
  handleFilter,
}) {
  return (
    <Grid item xs={12} className="reports-covid19-filter-wrapper">
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Box className="receipts-filter-searchBox">
          <SearchTextField
            label="Search by name, phone #, email"
            search={(value) => handleSearch(value.target.value)}
            width={256}
          />
        </Box>
        <div className="dropdown receipt-btn-filter">
          <button
            className="btn btn-white dropdown-toggle mr-2 admin-name"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="receipt-covid19-filter-dropdown">User Type</span>
          </button>
          <div className="dropdown-menu receipt-covid19-filter-popup" aria-labelledby="dropdownMenuButton">
            <span className="dropdown-item">
              <Checkbox
                fontSize={15}
                bottom={0}
                value={values.member}
                click={(value) => handleFilter('member', value, 'status', 'Member')}
                message="Member"
              />
            </span>
            <span className="dropdown-item">
              <Checkbox
                fontSize={15}
                bottom={0}
                value={values.guest}
                click={(value) => handleFilter('guest', value, 'status', 'Guest')}
                message="Guest"
              />
            </span>
            <span className="dropdown-item">
              <Checkbox
                fontSize={15}
                bottom={0}
                value={values.staff}
                click={(value) => handleFilter('staff', value, 'status', 'Staff')}
                message="Staff"
              />
            </span>
          </div>
        </div>
        <Box className="receipts-filter-date">
          <DatePicker
            label="Start Date"
            id="startDate"
            name="Start Date"
            value={values.startDate}
            handleChanges={(value) => handleDate('startDate', value, 'date')}
            helperText={messages.startDate}
            error={messages.startDate !== ''}
            readOnly={false}
            dateFormat="MM-dd-yyyy"
          />
        </Box>
        <Box className="receipts-filter-date-to">
          To
        </Box>
        <Box className="receipts-filter-date">
          <DatePicker
            label="End Date"
            id="endDate"
            name="End Date"
            value={values.endDate}
            handleChanges={(value) => handleDate('endDate', value, 'date')}
            helperText={messages.endDate}
            error={messages.endDate !== ''}
            readOnly={false}
            type="date"
            dateFormat="MM-dd-yyyy"
          />
        </Box>
        <Box
          className="receipts-filter-restart"
          onClick={handleReset}
          onKeyPress={handleReset}
          tabIndex={0}
          role="button"
        >
          <i className="fas fa-redo-alt" />
        </Box>
        <div className="dropdown receipt-btn-filter">
          <button
            className="btn btn-white dropdown-toggle mr-2 admin-name"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="receipt-covid19-filter-dropdown dropdown-results">Results</span>
          </button>
          <div className="dropdown-menu receipt-covid19-filter-popup filter-results" aria-labelledby="dropdownMenuButton">
            <span className="dropdown-item">
              <Checkbox
                fontSize={15}
                bottom={0}
                value={values.negative}
                click={(value) => handleFilter('negative', value, 'result', 'Negative')}
                message="Negative"
              />
            </span>
            <span className="dropdown-item">
              <Checkbox
                fontSize={15}
                bottom={0}
                value={values.positive}
                click={(value) => handleFilter('positive', value, 'result', 'Positive')}
                message="Positive"
              />
            </span>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

filter.propTypes = {
  values: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.number,
    member: PropTypes.bool,
    guest: PropTypes.bool,
    staff: PropTypes.bool,
    positive: PropTypes.bool,
    negative: PropTypes.bool,
  }).isRequired,
  messages: PropTypes.shape({
    startDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.instanceOf(Date),
    ]),
    endDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    member: PropTypes.string,
    guest: PropTypes.string,
    staff: PropTypes.string,
    positive: PropTypes.string,
    negative: PropTypes.string,
  }).isRequired,
  handleDate: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired,
};
