import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

/* < ------------   Membership routes  ---------------- > */
// Membership registration
import MembershipRegistration from '../../pages/membership/membershipRegistration/membershipRegistration';

// Verify Membership
import VerifyMembership from '../membership/verifyMembership/verifyMembership';

// change membership Type
import ChangeMembershipTypeStep1 from '../membership/changeMembershipType/step1/selectCategory';
import ChangeMembershipTypeStep2 from '../membership/changeMembershipType/step2/payment';
import ChangeMembershipTypeSuccess from '../membership/changeMembershipType/success/success';

// renew Membership
import RenewMembership from '../../pages/membership/renewMembership/renewMembership';

// Verify Address
import VerifyAddressStep1 from '../membership/verifyAddress/step1/verifyAddress';
import VerifyAddressStep2 from '../membership/verifyAddress/step2/payment';
import VerifyAddressSuccess from '../membership/verifyAddress/success/succes';

// Membership Receipt
import MembershipReceipt from '../membership/receipts/receipts';

// Membership List
import MembershipList from '../membership/memberList/membershipList';

// Membership Reports
import Reports from '../reports/reports';
import MembersReport from '../reports/memberships/membersReport';

// Add A Family Member
import AddNewFamilyMember from '../membership/addNewFamilyMember/addFamilyMember';
import FamilyMemberCard from '../membership/addNewFamilyMember/familyCard';

// Change Membership Card
import ChangeCard from '../membership/changeCard/membershipCard';

// settings
import ProfileSettings from '../profile/profile';

import Dashboard from '../dashboard/dashboard';

const MembershipAdminRoutes = function (userData) {
  return (
    <Switch>
      {/* Dashboard */}
      <Route path="/adminPortal/dashboard/:tab?" render={(props) => <Dashboard {...props} userData={userData} />} />

      {/* Membership Registration Route */}
      <Route path="/adminPortal/membership/register" render={(props) => <MembershipRegistration {...props} userData={userData} />} />

      {/* Reports */}
      <Route path="/adminPortal/reports/membersReport" component={MembersReport} />

      <Route path="/adminPortal/reports" render={(props) => <Reports {...props} userData={userData} />} />

      {/* Membership Routes */}
      <Route path="/adminPortal/membership/changeMembershipType/success" component={ChangeMembershipTypeSuccess} />
      <Route path="/adminPortal/membership/changeMembershipType/payment/:membershipID" component={ChangeMembershipTypeStep2} />
      <Route path="/adminPortal/membership/changeMembershipType/:membershipID" component={ChangeMembershipTypeStep1} />
      <Route path="/adminPortal/membership/verifyMembership/:membershipID" component={VerifyMembership} />
      <Route path="/adminPortal/membership/receipts/:membershipID" component={MembershipReceipt} />
      <Route path="/adminPortal/membership/verifyAddress/payment/:membershipID" component={VerifyAddressStep2} />
      <Route path="/adminPortal/membership/verifyAddress/success" component={VerifyAddressSuccess} />
      <Route path="/adminPortal/membership/verifyAddress/:membershipID" component={VerifyAddressStep1} />
      <Route path="/adminPortal/membership/addFamilyMember/:membershipID" component={AddNewFamilyMember} />
      <Route path="/adminPortal/membership/addFamilyMemberCard/:membershipID" component={FamilyMemberCard} />
      <Route path="/adminPortal/membership/changeCard/:membershipID" component={ChangeCard} />
      <Route path="/adminPortal/membership/renewMembership/:membershipID" render={(props) => <RenewMembership {...props} userData={userData} />} />
      <Route path="/adminPortal/membership" component={MembershipList} />
      {/* Profile */}
      <Route path="/adminPortal/profile" render={(props) => <ProfileSettings {...props} userData={userData} />} />

      <Route path="/adminPortal/*" exact component={MembershipList} />
    </Switch>
  );
};

export default MembershipAdminRoutes;

MembershipAdminRoutes.propTypes = {
  userData: PropTypes.shape({
    userType: PropTypes.string,
  }).isRequired,
};
