function createData(
  firstName,
  lastName,
  email,
  dayPhone,
  nightPhone,
  type,
  id,
  index,
) {
  return {
    firstName,
    lastName,
    email,
    dayPhone,
    nightPhone,
    type,
    id,
    index,
  };
}


export const searchUsers = (usersData, searchItem) => {
  if (
    usersData.firstName
      .toLowerCase().includes(searchItem.toLowerCase())
      || usersData.lastName
        .toLowerCase().includes(searchItem.toLowerCase())
      || usersData.email
        .toLowerCase().includes(searchItem.toLowerCase())) {
    return true;
  }
  return false;
};

export const pushUsersResults = (rows, usersData, index) => rows.push(
  createData(
    usersData.firstName,
    usersData.lastName,
    usersData.email,
    usersData.dayPhone,
    usersData.nightPhone,
    usersData.userType,
    usersData._id,
    index,
  ),
);
