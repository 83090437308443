import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import { BootstrapTextField } from './bootstrapInputs';
import Alert from './Alerts';
import { EmailAPI } from '../API/API-Email';
import Button from './btn';

function SendEmailReceipt({ email, name, programName }) {
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertValues, setAlertValues] = React.useState({});
  const [emailChange, setEmailChange] = React.useState(email || '');
  const [emailMessage, setEmailMessage] = React.useState('');

  const handleEmail = (event) => {
    const { value } = event.target;
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      setEmailMessage('Invalid email');
    } else {
      setEmailMessage('');
    }
    setEmailChange(value);
  };

  const handleSendEmail = () => {
    const data = {
      email: emailChange,
      name,
      programName,
    };
    console.log(data)
    EmailAPI.EMAIL.programRegistrationEmail(data).then((success) => {
      console.log(success);
      setOpenAlert(true);
      setAlertValues({
        alertTitle: 'Email send!',
        body: 'The email confirmation was successfully sent!',
        leave: 'hidden',
        stay: 'CLOSE',
      });
    }).catch((error) => {
      console.log(error);
      setAlertValues({
        alertTitle: 'Server Error!',
        body: 'Please try again later!',
        leave: 'hidden',
        stay: 'CLOSE',
      });
    });
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
      style={{ marginTop: '40px', marginBottom: '40px' }}
    >
      <Grid item className="email-label">Email:</Grid>
      <Grid item xs className="email-textfield">
        <BootstrapTextField
          id="email"
          type="text"
          name="email"
          value={emailChange}
          handleChanges={handleEmail}
          message={emailMessage}
          readOnly={false}
          error={emailMessage !== ''}
        />
      </Grid>
      <Grid item className="email-button">
        <Button
          label="SEND"
          onClick={handleSendEmail}
          bgColor="#2a9d8f"
          color="white"
          bgColorHover="#414158"
          colorHover="white"
          outlineColor="#2a9d8f"
          width={94}
          height={45}
        />
      </Grid>
      <Alert
        open={openAlert}
        close={() => setOpenAlert(false)}
        location="/"
        title={alertValues.alertTitle}
        body={alertValues.body}
        stay={alertValues.stay}
        leave={alertValues.leave}
      />
    </Grid>
  );
}

SendEmailReceipt.propTypes = {
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  programName: PropTypes.string.isRequired,
};

export default SendEmailReceipt;
