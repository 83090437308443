import React from 'react';
import PropTypes from 'prop-types';
import { CONVERTTIME } from '../../../../utils/dateController';
import '../../../../css/submitedAttendances.css';

function createData(programName, date, startTime, present, absent, id, dateTosend) {
  return {
    programName, date, startTime, present, absent, id, dateTosend,
  };
}

export default function TableExport({ data, values }) {
  const rows = [];
  if (data) {
    for (let i = 0; i < data.length; i++) {
      if (values.startDate !== '' && values.endDate !== '') {
        const dateAdjustment = new Date(data[i].date);
        dateAdjustment.setDate(dateAdjustment.getDate());
        if (
          new Date(data[i].date).toISOString() >= new Date(values.startDate).toISOString()
          && new Date(dateAdjustment).toISOString() <= new Date(values.endDate).toISOString()
        ) {
          rows.push(
            createData(
              data[i].programName,
              data[i].date,
              data[i].startTime,
              data[i].present,
              data[i].absent,
              data[i]._id,
            ),
          );
        }
      } else {
        rows.push(
          createData(
            data[i].programName,
            data[i].date,
            data[i].startTime,
            data[i].present,
            data[i].absent,
            data[i]._id,
          ),
        );
      }
    }
  }

  return (
    <div>

      <table className="table">
        <thead>
          <tr>
            <th scope="col">Program</th>
            <th scope="col">Date</th>
            <th scope="col">Start Time</th>
            <th scope="col">Present</th>
            <th scope="col">Absent</th>
          </tr>
        </thead>
        <tbody>


          {rows.map((value, index) => (
            <tr key={index}>
              <td>
                {value.programName}
              </td>
              <td>
                {CONVERTTIME(value.date, true)}
              </td>
              <td>
                {CONVERTTIME(value.startTime, true)}
              </td>
              <td>
                {value.present}
              </td>
              <td>
                {value.absent}
              </td>
            </tr>
          ))}

        </tbody>
      </table>

    </div>
  );
}

TableExport.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  values: PropTypes.shape({
    startDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    endDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
  }).isRequired,
};
