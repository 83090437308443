import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { Grid, Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '../../../materialUi/btn';
import TextField from '../../../materialUi/textField';

function ConfirmSubmission({
  open, handleCancel, taxVolunteerName, handleConfirmSubmission
}) {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="Upload dialog, upload your documents by selecting the documents and click on submit."
      fullWidth
      maxWidth="md"
    >
      <h1 className="taxes-confirm-submission-title">
        Confirm Submission
      </h1>
      <DialogContent>
        <form onSubmit={handleSubmit(handleConfirmSubmission)}>
          <button
            type="button"
            className="upload-dialog-close"
            onClick={handleCancel}
          >
            <CloseIcon />
          </button>
          <Grid container>
            <Grid item xs={12}>
              <div className="taxes-question">
                {taxVolunteerName}
                {' '}
                has completed the filing process of your submission,
                  please review the information entered, and that they are correct to
                    the best of your knowledge. Please read below and sign your name.
              </div>
            </Grid>
            <Grid item xs={12} style={{}}>
              <span className="custom-error">
                {(errors.confirmInformation) && errors.confirmInformation.message}
              </span>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                className="taxes-checkboxes-wrapper"
              >
                <Box>
                  <Controller
                    name="confirmInformation"
                    control={control}
                    rules={{
                      required: 'Please, accept the terms to continue',
                    }}
                    render={({ field }) => (
                      <Checkbox
                        inputProps={{ 'aria-label': 'Declare consent' }}
                        {...field}
                      />
                    )}
                  />
                </Box>
                <Box className="taxes-checkbox-label">
                    I declare that the information entered in Part A and the amounts
                      shown in Part C of CVITP Taxpayer Authorization are correct and
                      complete, and fully discloses my income from all sources. I also
                        declare that I have read the information above, and the
                        electronic filer identified in Part E is electronically
                          filing my income tax and benefit return.
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <span className="custom-error">
                {(errors.knowledgeCheck) && errors.knowledgeCheck.message}
              </span>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                className="taxes-checkboxes-wrapper"
              >
                <Box>
                  <Controller
                    name="sendDocumentAgreement"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        inputProps={{ 'aria-label': 'Declare consent' }}
                        {...field}
                      />
                    )}
                  />
                </Box>
                <Box className="taxes-checkbox-label">
                  I agree to have Volunteer send my documentation directly to the
                   Canada Revenue Agency on my behalf.
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div className="taxes-confirm-submission-note">
                <span className="taxes-confirm-submission-text">*Note: </span>
                  If you would like to send your tax questionnaire directly to
                   the Canada Revenue Agency yourself, please leave the above checkbox empty.
              </div>
            </Grid>
            <Grid item xs={6} className="taxes-consent-textFields">
              <Controller
                name="knowledgeSign"
                control={control}
                rules={{
                  required: 'Your Name is required',
                  maxLength: { value: 100, message: 'Max length 100' },
                }}
                render={({ field }) => (
                  <TextField
                    id="knowledgeSign"
                    type="search"
                    name="knowledgeSign"
                    label="Your Name"
                    helperText={errors.knowledgeSign ? errors.knowledgeSign.message : ''}
                    error={!!errors.knowledgeSign}
                    width="100%"
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                style={{ padding: '8px 24px 26px 24px' }}
              >
                <Box style={{ marginRight: '10px' }}>
                  <Button
                    label="Cancel"
                    bgColor="white"
                    color="#E76F51"
                    bgColorHover="#E76F51"
                    colorHover="white"
                    borderColor="#E76F51"
                    variant="outlined"
                    width={128}
                    onClick={handleCancel}
                    type="button"
                  />
                </Box>
                <Button
                  label="CONFIRM SUBMISSION"
                  bgColor="#e76f50"
                  color="white"
                  colorHover="white"
                  bgColorHover="#ff8c68"
                  width={210}
                  onClick={() => {}}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}

ConfirmSubmission.propTypes = {
  handleConfirmSubmission: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  taxVolunteerName: PropTypes.string.isRequired,
};

export default ConfirmSubmission;
