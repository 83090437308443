import React from 'react';
import { Grid } from '@material-ui/core';
import FeesBox from './components/feesBox';
import MembershipCategory from './components/membershipCategory';
import './selectCategory.css';

function SelectCategory({ handleNextStep, data }) {

  const handleCategorySubmission = (submissionData) => {
    const copyData = data;
    copyData.membershipCategory = submissionData.membershipCategory;
    handleNextStep(copyData, 0);
  };

  return (
    <Grid item xs className="select-category-wrapper">
      <h1 className="select-category-title">
        Membership Registration
      </h1>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <FeesBox />
        <MembershipCategory handleFormSubmit={handleCategorySubmission} />
      </Grid>
    </Grid>
  );
}

export default SelectCategory;
