import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import Header from './components/header';
import ReceiptTable from './components/receiptsTable';
import { ReceiptsAPI } from '../../../API/API-receipts';
import TableExport from './components/tableExport';
import ReceiptAlert from './components/receiptAlert';
import SendEmail from './components/sendEmail';
import '../../../css/receipts.css';

class Reciepts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      receiptsData: [],
      search: '',
      receiptAlert: false,
      sendEmailAlert: false,
      selectedReceiptData: {},
      membershipID: this.props.match.params.membershipID,
      values: {
        startDate: '',
        endDate: '',
      },
      messages: {
        startDate: '',
        endDate: '',
      },
    };
  }

  componentDidMount() {
    const { membershipID } = this.state;
    ReceiptsAPI.GET.getMembershipReceipts(membershipID).then((success) => {
      this.setState({ receiptsData: success.data });
    }).catch((error) => {
      console.log(error);
    });
  }

  handleExport=(value) => {
    const { membershipID } = this.state;
    if (value === 'Print') {
      const printContent = document.getElementById('el').innerHTML;
      document.body.innerHTML = printContent;
      window.print();
      window.location = `/adminPortal/membership/receipts/${membershipID}`;
    } else {
      const table = document.getElementById('el').innerHTML;
      const convertTable = table.toString().replace(/<div>/g, '')
        .toString().replace(/<\/div>/g, '')
        .toString()
        .replace(/<table class="table">/g, '')
        .toString()
        .replace(/<\/table>/g, '')
        .toString()
        .replace(/<td>/g, '')
        .toString()
        .replace(/<\/td>/g, ',')
        .toString()
        .replace(/<tr>/g, '')
        .toString()
        .replace(/<\/tr>/g, '\r\n')
        .toString()
        .replace(/<th scope="col">/g, '')
        .toString()
        .replace(/<\/th>/g, ',')
        .toString()
        .replace(/<thead>/g, '')
        .toString()
        .replace(/<\/thead>/g, '')
        .toString()
        .replace(/<tbody>/g, '')
        .toString()
        .replace(/<\/tbody>/g, '')
        .toString()
        .replace(/<\/t>/g, '')
        .toString()
        .replace(/<\/n>/g, '');
      const myLink = document.createElement('a');
      myLink.download = 'receipts.csv';
      myLink.href = `data:application/csv,${escape(convertTable)}`;
      myLink.click();
    }
  }

  handleDate=(name, value, type, requirement = '') => {
    const { values, messages } = this.state;
    values[name] = value;
    messages[name] = '';
    this.setState({ values, messages });
  }

  handleReset = () => {
    const { values, messages } = this.state;
    values.startDate = '';
    values.endDate = '';
    messages.startDate = '';
    messages.endDate = '';
    this.setState({ values, messages });
  }

  render() {
    const {
      membershipID,
      receiptsData,
      search,
      receiptAlert,
      selectedReceiptData,
      values,
      messages,
      sendEmailAlert,
    } = this.state;
    return (
      <Grid>
        <Header
          handleBack={() => this.props.history.push(`/adminPortal/membership/verifyMembership/${membershipID}`)}
          handleSearch={(value) => this.setState({ search: value })}
          handleExport={this.handleExport}
          handleDate={this.handleDate}
          values={values}
          messages={messages}
          handleReset={this.handleReset}
        />
        <ReceiptTable
          data={receiptsData}
          searchItem={search}
          handleClick={(value) => this.setState({ receiptAlert: true, selectedReceiptData: value })}
          values={values}
        />
        <Grid className="table-export" id="el">
          <TableExport
            receiptsData={receiptsData}
            sortItem={''}
            searchItem={search}
            values={values}
          />
        </Grid>
        <ReceiptAlert
          open={receiptAlert}
          close={() => this.setState({ receiptAlert: false })}
          receiptData={selectedReceiptData}
          membershipID={membershipID}
          sendEmail={() => this.setState({ sendEmailAlert: true, receiptAlert: false })}
        />
        <SendEmail
          open={sendEmailAlert}
          close={() => this.setState({ sendEmailAlert: false })}
          receiptData={selectedReceiptData}
          membershipID={membershipID}
        />
      </Grid>
    );
  }
}

export default Reciepts;
