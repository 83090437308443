import React from 'react';
import { Grid, Paper, Box } from '@material-ui/core';
import Icon from '../../../../../assets/icons/information.png';
import '../../../../../css/selectCategory.css';

function Fees() {
  return (
    <Grid item xs>
      <Paper shadow={2} className="selectCategory-box-fee-shadow">
        <Grid container>
          <Grid item xs>
            <Box className="selectCategory-fees-title">Annual Membership Fees</Box>
            <hr className="feesHr" />
          </Grid>
          <table className="table table-borderless selectCategory-box-fees">
            <thead>
              <tr>
                <th><Box className="selectCategory-table-header">Type</Box></th>
                <th><Box className="selectCategory-table-header">Community</Box></th>
                <th><Box className="selectCategory-table-header">Associate</Box></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Family&nbsp;
                  <img src={Icon} alt="Question mark" width="12" height="12" />
                </td>
                <td>$8</td>
                <td>$15</td>
              </tr>
              <tr>
                <td>Adult</td>
                <td>$5</td>
                <td>$10</td>
              </tr>
              <tr>
                <td>Youth (13-17)</td>
                <td>$2</td>
                <td>$4</td>
              </tr>
              <tr>
                <td>Senior (65+)</td>
                <td>$1</td>
                <td>$2</td>
              </tr>
              <tr>
                <td>Child</td>
                <td>$1</td>
                <td>$2</td>
              </tr>
            </tbody>
          </table>
          <Grid item xs={12}>
            <hr />
            <Box className="selectCategory-table-spacing">
              <span className="selectCategory-table-text">
                <span className="selectCategory-table-text-highlight">
                  Community memberships&nbsp;
                </span>
                are available to residents in our primary catchment area: Spadina Ave, College St, Euclid Ave, and Front St W.
              </span>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className="selectCategory-table-spacing">
              <span className="selectCategory-table-text">
                <span className="selectCategory-table-text-highlight">
                  Associate memberships&nbsp;
                </span>
                are available to residents who live outside
                the catchment area
              </span>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

export default Fees;
