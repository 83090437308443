import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { Controller } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import TextField from '../../../../materialUi/textField';
import CheckBox from '../../../../materialUi/checkBox';


function WeeklySchedule(props) {
  const {
    control,
    formState: { errors },
    data,
    getValues,
    setValue,
    watch
  } = props;

  const goToNextWeekday = (date, days) => {
    var updateDate = new Date(date);
    updateDate.setDate(updateDate.getDate() + days);
    return updateDate;
  };

  const goToPreviousWeekday = (date, days) => {
    const updateDate = new Date(date);
    updateDate.setDate(updateDate.getDate() - days);
    return updateDate;
  };

  const removeWeekends = () => {
    data.weeklySchedule.forEach((item) => {
      const startDays = new Date(item.startDate).getDay();
      const endDays = new Date(item.endDate).getDay();
      if (startDays === 0) item.startDate = goToNextWeekday(item.startDate, 1);
      if (startDays === 6) item.startDate = goToNextWeekday(item.startDate, 2);
      if (endDays === 0) item.endDate = goToPreviousWeekday(item.endDate, 2);
      if (endDays === 6) item.endDate = goToPreviousWeekday(item.endDate, 1);
    });
    setValue('weeklySchedule', data.weeklySchedule);
  };

  const addWeekends = () => {
    const originalSchedule = JSON.parse(sessionStorage.getItem('originalSchedule'));
    data.weeklySchedule = originalSchedule;
    setValue('weeklySchedule', originalSchedule);
  };

  const handleProgramOption = (value) => {
    setValue('weekdaysOnly', value);
    data.weekdaysOnly = value;
    if (value === true) return removeWeekends();
    return addWeekends();
  };

  React.useEffect(() => {
    watch('weekdaysOnly');
    if (data.weekdaysOnly) {
      setValue('weekdaysOnly', data.weekdaysOnly);
      handleProgramOption(data.weekdaysOnly);
    }
  }, [data.weeklySchedule])

  return (
    <>
      {data.weeklySchedule && data.weeklySchedule.length > 0 && (
        <>
          {getValues('weeklySchedule') && (
            <Grid item xs={12}>
              <CheckBox
                id="Include only weekdays in the schedule."
                message="Include only weekdays in the schedule."
                value={getValues('weekdaysOnly')}
                click={(value) => handleProgramOption(value)}
              />
            </Grid>
          )}
          <Grid item xs={12} className="addNewProgram-weekly-schedule-box">
            <Grid container>
              {data.weeklySchedule.map((item) => (
                <Grid item xs={12} md={6} className="addNewProgram-weekly-schedule-date">
                  <Moment date={item.startDate} format="dddd, MMM DD" /> - <Moment date={item.endDate} format="dddd, MMM DD, YYYY " />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="weeklyStartTime"
              control={control}
              rules={{
                required: 'Start Time is required',
                maxLength: { value: 100, message: 'Max length 100' },
              }}
              defaultValue={data.weeklyStartTime}
              render={({ field }) => (
                <TextField
                  id="weeklyStartTime"
                  type="search"
                  name="weeklyStartTime"
                  label="Start Time"
                  helperText={errors.weeklyStartTime ? errors.weeklyStartTime.message : ''}
                  error={!!errors.weeklyStartTime}
                  width="100%"
                  readOnly={false}
                  type="time"
                  shrink
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="weeklyEndTime"
              control={control}
              rules={{
                required: 'End Time is required',
                maxLength: { value: 100, message: 'Max length 100' },
              }}
              defaultValue={data.weeklyEndTime}
              render={({ field }) => (
                <TextField
                  id="weeklyEndTime"
                  type="search"
                  name="weeklyEndTime"
                  label="End Time"
                  helperText={errors.weeklyEndTime ? errors.weeklyEndTime.message : ''}
                  error={!!errors.weeklyEndTime}
                  width="100%"
                  readOnly={false}
                  type="time"
                  shrink
                  {...field}
                />
              )}
            />
          </Grid>
        </>
      )}
    </>
  );
}

WeeklySchedule.propTypes = {
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  formState: PropTypes.func.isRequired,
  data: PropTypes.shape(
    {
      weeklySchedule: PropTypes.string,
      weeklyStartTime: PropTypes.arrayOf(PropTypes.any),
      weeklyEndTime: PropTypes.string,
    },
  ).isRequired,
};

export default WeeklySchedule;
