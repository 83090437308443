import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';

function CustomTab({ tabOptions, setTabNumber, tabNumber }) {
  return (
    <Grid item xs={12}>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        {tabOptions.map((item) => (
          <>
            <button
              type="button"
              className={tabNumber === item.value ? 'taxSubmissionList-activeTab' : 'taxSubmissionList-deactiveTab'}
              onClick={() => setTabNumber(item.value)}
            >
              {item.label}
            </button>
            {item.value !== tabOptions.length - 1 && (
            <Box className="taxSubmissionList-tabSpacing">|</Box>
            )}
          </>
        ))}
      </Grid>
    </Grid>
  );
}

CustomTab.propTypes = {
  tabOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  })).isRequired,
  setTabNumber: PropTypes.func.isRequired,
};

export default CustomTab;
