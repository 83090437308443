import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableRow from '@material-ui/core/TableRow';
import Moment from 'react-moment';
import MenuDropdown from '../../../../../materialUi/menuDropdown';
import Button from '../../../../../materialUi/btn';
import PreLoading from '../../../../../materialUi/preLoading';


function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

// collums titles
const headRows = [
  {
    id: 'formID', numeric: false, disablePadding: true, label: 'Form ID',
  },
  {
    id: 'applicantName', numeric: false, disablePadding: true, label: 'Applicant Name',
  },
  {
    id: 'submissionDate', numeric: false, disablePadding: false, label: 'Submission Date',
  },
  {
    id: 'email', numeric: false, disablePadding: false, label: 'Email',
  },
  {
    id: 'assignedTo', numeric: false, disablePadding: false, label: 'Assigned To',
  },
  {
    id: 'status', numeric: false, disablePadding: false, label: 'Status',
  },
  {
    id: 'viewSubmission', numeric: false, disablePadding: false, label: '',
  },
];


function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="bg-light">
      <TableRow>
        {headRows.map((row) => (
          <TableCell
            key={row.id}
            align={row.numeric ? 'center' : 'left'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
            className="table-cell submissionList-table-title"
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
              className="submissionList-table-title"
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

function AdminSubmissionTable({
  data,
  filteredReports,
  history,
  taxVolunteerData,
  handleChangeTaxVolunteer,
  noData
}) {


  const getTaxVolunteer = (volunteerID) => {
    let volunteerLabel = false;
    for (let i = 0; i < taxVolunteerData.length; i++) {
      if (taxVolunteerData[i]._id === volunteerID) {
        volunteerLabel = `${taxVolunteerData[i].firstName} ${taxVolunteerData[i].lastName.charAt(0)}.`;
      }
    }
    if (!volunteerLabel) {
      return 'NOT SELECTED';
    }
    return volunteerLabel;
  };


  const formatStatus = (status) => {
    if (status === 'missingInfo') {
      return 'Missing Info';
    }
    if (status === 'inReview') {
      return 'In Review';
    }
    if (status === 'craIssue') {
      return 'CRA Issue'
    }
    return status;
  };

  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('submissionDate');
  const [selected, setSelected] = React.useState([]);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);


  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredReports.length - page * rowsPerPage);

  if (noData) return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className="mt-5"
    >
      No data to show
    </Grid>
  )

  if (filteredReports.length === 0 && (data.length !== filteredReports.length)) return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className="mt-5"
    >
      No data to show
    </Grid>
  )


  if (data.length === 0 && filteredReports.length === 0) return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className="mt-5"
    >
      <PreLoading />
    </Grid>
  )

  return (
    <>
      <Grid className="font-family-default">
        <Grid className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {filteredReports.length > 0 ? (
                stableSort(filteredReports, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow
                      hover
                      key={row.membershipID}
                    >
                      <TableCell component="th" scope="row" padding="none" className="table-cell">
                        {row.formID}
                      </TableCell>
                      <TableCell align="left" className="text-capitalize">
                        {row.fullName}
                      </TableCell>
                      <TableCell align="left"><Moment format="DD-MMM-YY">{row.createdAt}</Moment></TableCell>
                      <TableCell align="left">
                        {row.email}
                      </TableCell>
                      <TableCell align="left">
                        <MenuDropdown
                          listItems={taxVolunteerData}
                          handleChanges={(selectedVolunteer) => handleChangeTaxVolunteer(selectedVolunteer, index)}
                          selectedItemLabel={getTaxVolunteer(row.taxVolunteer)}
                        />
                      </TableCell>
                      <TableCell align="center" style={{ width: '15px' }}>
                        <div className={`taxes-status taxes-status-${row.status}`}>
                          {formatStatus(row.status)}
                        </div>
                      </TableCell>
                      <TableCell align="center" style={{ width: '15px' }}>
                        <Button
                          label="VIEW"
                          bgColor="#e76f50"
                          color="white"
                          colorHover="white"
                          bgColorHover="#ff8c68"
                          width={81}
                          height={27}
                          fontSize={14}
                          onClick={() => history.push(`/adminPortal/reports/taxes/view/${row._id}`)}
                          type="submit"
                        />
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <div className="taxSubmissionList-loading-table">
                  <PreLoading />
                </div>
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }} />
              )}
            </TableBody>
          </Table>
        </Grid>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredReports.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
    </>
  );
}

AdminSubmissionTable.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    status: PropTypes.string,
    formID: PropTypes.string,
    fullName: PropTypes.string,
    createdAt: PropTypes.string,
    email: PropTypes.string,
    taxVolunteer: PropTypes.string,
    _id: PropTypes.string,
  })).isRequired,
  filteredReports: PropTypes.arrayOf(PropTypes.shape({
    status: PropTypes.string,
    formID: PropTypes.string,
    fullName: PropTypes.string,
    createdAt: PropTypes.string,
    email: PropTypes.string,
    taxVolunteer: PropTypes.string,
    _id: PropTypes.string,
  })).isRequired,
  taxVolunteerData: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleChangeTaxVolunteer: PropTypes.func.isRequired,
};

export default withRouter(AdminSubmissionTable);
