import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';

export default function AdditionalInfo({ data }) {
  return (
    <Grid item xs={12} className="programDetails-spacing programDetails-bottom-box">
      <Box className="programDetails-boxes">
        <Grid container>
          <Grid item xs={12} className="programDetails-boxes-main-title">
          Additional Information:
          </Grid>
          <Grid item xs={12} md={6}>
            {(data && data.fee === 0 && data.variableFee === 'no') ? (
              <Grid container className="programDetails-boxes-text">
                <Grid item xs={6} md={4}>
                    Program Fee:
                </Grid>
                <Grid item xs>
                  <b>Free</b>
                </Grid>
              </Grid>
            ) : (data && data.variableFee === 'no') && (
            <Grid container className="programDetails-boxes-text">
              <Grid item xs={6} md={4}>
                    Program Fee:
              </Grid>
              <Grid item xs>
                <b>
                  $
                  {data.fee}
                  .00
                  {data && data.weeklyProgram && '/ Week'}
                </b>
              </Grid>
            </Grid>
            )}

            {(data && data.variableFee === 'yes') && (
            <Grid container className="programDetails-boxes-text">
              <Grid item xs={6} md={4}>
                Program Fee (Community):
              </Grid>
              <Grid item xs>
                <b>
                  $
                  {data.communityFee}
                  .00
                  {data && data.weeklyProgram && '/ Week'}
                </b>
              </Grid>
            </Grid>
            )}
            {(data && data.variableFee === 'yes') && (
            <Grid container className="programDetails-boxes-text">
              <Grid item xs={6} md={4}>
                    Program Fee (Associate):
              </Grid>
              <Grid item xs>
                <b>
                  $
                  {data.associateFee}
                  .00
                  {data && data.weeklyProgram && '/ Week'}
                </b>
              </Grid>
            </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Grid container className="programDetails-boxes-text">
              <Grid item xs={2} md={2}>
                Program Description:
              </Grid>
              <Grid item xs className="description">
                <b>{data && data.programDescription}</b>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}

AdditionalInfo.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      program: PropTypes.arrayOf(PropTypes.any),
    }),
  ]).isRequired,
};
