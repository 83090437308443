import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { Grid, Box } from '@material-ui/core';
import { BootstrapTextField } from '../../../../materialUi/bootstrapInputs';

export default function Details({ data }) {
  return (
    <Grid container>
      <Grid item xs={6} className="programDetails-spacing">
        <Box className="programDetails-boxes">
          <Grid container>
            <Grid item xs={12} className="programDetails-boxes-main-title">
              Participant Information:
            </Grid>
            <Grid item xs={12}>
              <Grid container className="programDetails-boxes-text">
                <Grid item xs={6} md={4}>
                  Name:
                </Grid>
                <Grid item xs>
                  <b>{data.participantData.firstName} {data.participantData.lastName}</b>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container className="programDetails-boxes-text">
                <Grid item xs={6} md={4}>
                  Relationship:
                </Grid>
                <Grid item xs>
                  <b>{data.participantData.relationship}</b>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container className="programDetails-boxes-text">
                <Grid item xs={6} md={4}>
                  Phone:
                </Grid>
                <Grid item xs>
                  <b>{data.participantData.phone}</b>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container className="programDetails-boxes-text">
                <Grid item xs={6} md={4}>
                  Date of Birth:
                </Grid>
                <Grid item xs>
                  <b><Moment format="YYYY-MM-DD">{data.participantData.dateOfBirth}</Moment></b>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container className="programDetails-boxes-text">
                <Grid item xs={6} md={4} className="pt-2">
                  OHIP Number:
                </Grid>
                <Grid item xs>
                  <BootstrapTextField
                    id="ohipNumber"
                    type="text"
                    name="OHIP Number"
                    value={data.participantMedicalInformation.ohipNumber}
                    handleChanges={(value) => false}
                    message={''}
                    readOnly
                    error={false}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={6} className="programDetails-spacing">
        <Box className="programDetails-boxes">
          <Grid container>
            <Grid item xs={12} className="programDetails-boxes-main-title">
              Membership:
            </Grid>
            <Grid item xs={12}>
              <Grid container className="programDetails-boxes-text">
                <Grid item xs={6} md={4}>
                  Account Holder Name:
                </Grid>
                <Grid item xs>
                  <b>{data.membership.primaryMember.firstName} {data.membership.primaryMember.lastName}</b>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container className="programDetails-boxes-text">
                <Grid item xs={6} md={4}>
                  Email:
                </Grid>
                <Grid item xs>
                  <b>{(data.membership.membership.email) ? data.membership.membership.email : 'N/A'}</b>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container className="programDetails-boxes-text">
                <Grid item xs={6} md={4}>
                  Phone:
                </Grid>
                <Grid item xs>
                  <b>{data.membership.primaryMember.phone}</b>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container className="programDetails-boxes-text">
                <Grid item xs={6} md={4}>
                  Address:
                </Grid>
                <Grid item xs>
                  <b>{data.membership.membership.address.street}, {data.membership.membership.address.apt} {data.membership.membership.address.city} {data.membership.membership.address.province}</b>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="programDetails-emergencyContact">
              <Grid container className="programDetails-boxes-text">
                <Grid item xs={6} md={4}>
                  Member Type:
                </Grid>
                <Grid item xs>
                  <b>{data.membership.membership.membershipType}</b>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

Details.propTypes = {
  data: PropTypes.shape({
    participantData: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      relationship: PropTypes.string,
      phone: PropTypes.string,
      dateOfBirth: PropTypes.string,
    }),
    paymentStatus: PropTypes.string,
    participantMedicalInformation: PropTypes.shape({
      ohipNumber: PropTypes.string,
    }),
    membership: PropTypes.shape({
      membership: PropTypes.shape({
        email: PropTypes.string,
        address: PropTypes.shape({
          apt: PropTypes.string,
          city: PropTypes.string,
          province: PropTypes.string,
          street: PropTypes.string,
        }),
        membershipType: PropTypes.string,
      }),
      primaryMember: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        phone: PropTypes.string,
      }),
    }),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      programID: PropTypes.string,
      membershipID: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

Details.defaultProps = {
  data: {
    participantData: {
      firstName: '',
      lastName: '',
      relationship: '',
      phone: '',
      dateOfBirth: '',
    },
    paymentStatus: '',
    participantMedicalInformation: {
      ohipNumber: '',
    },
    membership: {
      membership: {
        email: '',
        address: {
          apt: '',
          city: '',
          province: '',
          street: '',
        },
        membershipType: '',
      },
      primaryMember: {
        firstName: '',
        lastName: '',
        phone: '',
      },
    },
  },
};
