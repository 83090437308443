import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import QuestionnaireButtons from '../questionnaireButtons';
import BasicInformation from './components/basicInformation';
import Language from './components/language';
import Dependents from './components/dependents';
import '../../../../css/taxes.css';

function StepOne({ data, handleNextStep, cancelRoute, handlePrevious }) {
  const useFormMethods = useForm({ mode: 'all' });
  const {
    handleSubmit,
    setValue,
  } = useFormMethods;

  const [formAction, setFormAction] = React.useState('');

  const handleFormAction = (action) => {
    setFormAction(action);
  };

  const handleFormSubmit = (formData) => {
    if (formAction === 'save') {
      handleNextStep(formData, 0);
    } else {
      handleNextStep(formData, 1);
    }
  };

  React.useEffect(() => {
    if (data.fullName) {
      setValue('fullName', data.fullName, { shouldValidate: true });
      setValue('email', data.email, { shouldValidate: true });
      setValue('homePhone', data.homePhone, { shouldValidate: true });
      setValue('workPhone', data.workPhone, { shouldValidate: true });
      setValue('canadaStatus', data.canadaStatus, { shouldValidate: true });
      setValue('language', data.language, { shouldValidate: true });
      setValue('province', data.province, { shouldValidate: true });
      setValue('maritalStatus', data.maritalStatus, { shouldValidate: true });
      setValue('maritalChange', data.maritalChange, { shouldValidate: true });
      setValue('familyHead', data.familyHead, { shouldValidate: true });
      setValue('anyDependents', data.anyDependents, { shouldValidate: true });
      setValue('dependantsBelowAge', data.dependantsBelowAge, { shouldValidate: true });
      setValue('dependantsAboveAge', data.dependantsAboveAge, { shouldValidate: true });
    }
  }, [data]);

  return (
    <FormProvider {...useFormMethods}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container>

          {/* Form Body */}
          <BasicInformation {...useFormMethods} data={data} />
          <Language {...useFormMethods} data={data} />
          <Dependents {...useFormMethods} data={data} />
        </Grid>

        {/* Action Buttons */}
        <hr className="tax_custom_hr" />
        <QuestionnaireButtons
          location={cancelRoute}
          handleAction={(value) => handleFormAction(value)}
          handlePrevious={handlePrevious}
        />
      </form>
    </FormProvider>
  );
}

StepOne.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  data: PropTypes.shape(
    {
      fullName: PropTypes.string,
      email: PropTypes.string,
      homePhone: PropTypes.string,
      workPhone: PropTypes.string,
      canadaStatus: PropTypes.string,
      language: PropTypes.string,
      province: PropTypes.string,
      maritalStatus: PropTypes.string,
      maritalChange: PropTypes.string,
      familyHead: PropTypes.string,
      anyDependents: PropTypes.string,
    },
  ).isRequired,
  handleNextStep: PropTypes.func.isRequired,
  cancelRoute: PropTypes.string.isRequired,
};

export default withRouter(StepOne);
