import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Header from './components/header';
import Details from './components/details';
import CareGiverDetails from './components/caregiverDetails';
import OtherInfo from './components/otherInfo';
import { ProgramRegistrationAPI } from '../../../API/API-programRegistration';
import { membershipAPI } from '../../../API/API-membership';
import { FamilyAPI } from '../../../API/API-familyMembers';

class RegistrationDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { programID, membershipID, familyMemberID } = this.props.match.params;
    ProgramRegistrationAPI.GET.getProgramRegistrationDetails(programID, membershipID)
      .then((success) => {
        let updateData;
        console.log(success.data);
        for (let i = 0; i < success.data.length; i++) {
          for (let s = 0; s < success.data[i].medicalInformation[0].length; s++) {
            if (familyMemberID === success.data[i].medicalInformation[0][s]._id) {
              updateData = success.data[i];
            }
          }
        }
        membershipAPI.GET.getMembershipByID(membershipID)
          .then((membershipResonse) => {
            updateData.membership = membershipResonse.data;
            FamilyAPI.GET.getFamilyMemberByID(familyMemberID)
              .then((familyMemberResponse) => {
                updateData.participantData = familyMemberResponse.data[0];
                let participantMedicalInformation = {};
                for (let i = 0; i < updateData.medicalInformation.length; i++) {
                  if (updateData.medicalInformation[i][0]._id === familyMemberID) {
                    participantMedicalInformation = updateData.medicalInformation[i][0];
                  }
                }
                updateData.participantMedicalInformation = participantMedicalInformation;
                this.setState({ data: updateData });
              })
              .catch((errors) => {
                console.log(errors);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const { data } = this.state;
    const { match, history } = this.props;
    return (
      <>
        <Grid item xs className="font-family-default">
          <Header data={data} history={history} match={match} />
          <Details data={data} history={history} match={match} />
          <CareGiverDetails data={data} history={history} match={match} />
          <OtherInfo data={data} history={history} match={match} />
        </Grid>
      </>
    );
  }
}

RegistrationDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programID: PropTypes.string,
      membershipID: PropTypes.string,
      familyMemberID: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  userData: PropTypes.shape({
    userType: PropTypes.string,
  }),
};

export default RegistrationDetails;
