/* eslint-disable no-lonely-if */
import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableRow from '@material-ui/core/TableRow';
import '../../../../../css/receipts.css';
import Btn from '../../../../../materialUi/btn';

function createData(date, membersCheckedIn, guestsCheckedIn, staffCheckedIn, totalVisits, dailyCheckInID) {
  return {
    date,
    membersCheckedIn,
    guestsCheckedIn,
    staffCheckedIn,
    totalVisits,
    dailyCheckInID,
  };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

// columns titles
const headRows = [
  {
    id: 'date',
    numeric: true,
    disablePadding: true,
    label: 'Date',
  },
  {
    id: 'membersCheckedIn',
    numeric: true,
    disablePadding: true,
    label: 'Members Checked-In',
  },
  {
    id: 'guestsCheckedIn',
    numeric: true,
    disablePadding: false,
    label: 'Guests Checked-In',
  },
  {
    id: 'staffCheckedIn',
    numeric: true,
    disablePadding: false,
    label: 'Staff Checked-In',
  },
  {
    id: 'totalVisits',
    numeric: true,
    disablePadding: false,
    label: 'Total Visits',
  },
  {
    id: 'dailyCheckInID',
    numeric: true,
    disablePadding: false,
    label: '',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="bg-light">
      <TableRow>
        {headRows.map((row, index) => (
          <TableCell key={index} align={row.numeric ? 'center' : 'left'} padding={row.disablePadding ? 'none' : 'default'} sortDirection={orderBy === row.id ? order : false} className="table-cell">
            <TableSortLabel active={orderBy === row.id} direction={order} onClick={createSortHandler(row.id)}>
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

export default function CheckInsDataTable({ data, handleClick, values }) {
  const rows = [];
  const sortByDate = (userData) => {
    const dateAdjustment = new Date(userData.date);
    dateAdjustment.setDate(dateAdjustment.getDate());
    return new Date(userData.date).toISOString() >= new Date(values.startDate).toISOString() && new Date(dateAdjustment).toISOString() <= new Date(values.endDate).toISOString();
  };

  if (data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      console.log(data[i].date);
      if (values.startDate !== '') {
        if (sortByDate(data[i])) {
          rows.push(createData(data[i].date, data[i].members.length, data[i].guests.length, data[i].staff.length, data[i].totalVisits, data[i].dailyCheckInID));
        }
      } else {
        rows.push(createData(data[i].date, data[i].members.length, data[i].guests.length, data[i].staff.length, data[i].totalVisits, data[i].dailyCheckInID));
      }
    }
  }

  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('date');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  return (
    <Grid className="font-family-default">
      <Grid className={classes.tableWrapper}>
        <Table className={classes.table} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
          <EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={data.length} />
          <TableBody>
            {stableSort(rows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow hover role="button" tabIndex={1} className="table-tabSelection" key={index}>
                  <TableCell align="center">
                    <Moment date={new Date(row.date)} format="DD-MMM-YY" />
                  </TableCell>
                  <TableCell align="center">{row.membersCheckedIn}</TableCell>
                  <TableCell align="center">{row.guestsCheckedIn}</TableCell>
                  <TableCell align="center">{row.staffCheckedIn}</TableCell>
                  <TableCell align="center">{row.totalVisits}</TableCell>
                  <TableCell align="center">
                    <Btn label="View Details" onClick={() => handleClick(row)} bgColor="#e76f50" color="white" bgColorHover="#ff8c68" colorHover="white" width={123} height={24} fontSize={14} />
                  </TableCell>
                </TableRow>
              ))}
            {emptyRows > 0 && <TableRow style={{ height: 49 * emptyRows }} />}
          </TableBody>
        </Table>
      </Grid>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Grid>
  );
}

CheckInsDataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  handleClick: PropTypes.func.isRequired,
};
