import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import Header from './components/header';
import ProgramsTable from './components/programsTable';
import TableExport from './components/tableExport';
import { ProgramsAPI } from '../../../API/API-programs';
import '../../../css/programsList.css';

export default class ProgramsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      programsData: [],
      search: '',
      ages: [],
      seasons: [],
      selectedItems: [],
      values: {
        allAges: false,
        preSchool: false,
        children: false,
        youth: false,
        adult: false,
        senior: false,
        information: false,
        allSeasons: false,
        winter: false,
        spring: false,
        summer: false,
        fall: false,
      },
    };
  }

  componentDidMount() {
    // <--- get all programs on DB --->
    ProgramsAPI.GET.getAll()
      .then((success) => {
        this.setState({ programsData: success.data });
        console.log(success.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleFilter = (name, value, filterName, selectedAge) => {
    const { values, selectedItems } = this.state;
    if (value === true) {
      values[name] = value;
      this.setState({
        values,
        [filterName]: this.state[filterName].concat([selectedAge]),
        selectedItems: selectedItems.concat([
          <Box className="programsList-selectedItems" key={selectedAge} name={name} id={filterName}>
            {selectedAge}
            <i
              label="filterItems"
              className="far fa-times-circle ml-2 "
              onClick={() => this.handleFilter(name, false, filterName, selectedAge)}
              onKeyPress={() => this.handleFilter(name, false, filterName, selectedAge)}
              role="button"
              tabIndex={0}
            />
          </Box>,
        ]),
      });
    } else {
      // remove filter
      const updateArray = this.state[filterName];
      for (let i = 0; i < updateArray.length; i++) {
        if (updateArray[i] === selectedAge) {
          updateArray.splice(i, 1);
        }
      }

      // remove selectedItems
      const updateSelectedItems = this.state.selectedItems;
      for (let index = 0; index < updateSelectedItems.length; index++) {
        if (updateSelectedItems[index].key === selectedAge) {
          updateSelectedItems.splice(index, 1);
        }
      }

      values[name] = value;
      this.setState({ values, [filterName]: updateArray, selectedItems: updateSelectedItems });
    }
  };

  resetFilters = () => {
    const { values } = this.state;
    Object.keys(values).forEach((key) => {
      values[key] = false;
    });
    this.setState({
      values,
      seasons: [],
      ages: [],
      selectedItems: [],
    });
  };

  render() {
    const { ages, seasons, values, selectedItems, programsData, search } = this.state;
    const { history, userData } = this.props;

    const membershipType = userData.userData.membership ? userData.userData.membership.membership.membershipType : 'public';
    return (
      <Grid item xs className="font-family-default">
        <Header
          getSearch={(value) => this.setState({ search: value })}
          handleFilter={this.handleFilter}
          search={(value) => this.setState({ search: value })}
          ages={ages}
          seasons={seasons}
          values={values}
          selectedItems={selectedItems}
          resetFilters={this.resetFilters}
        />

        <ProgramsTable
          data={programsData}
          sortSearch={search}
          handleProgramSelection={(programID) => history.push(`/scccPortal/programs/programDetails/${programID}`)}
          membershipType={membershipType}
        />
        <Grid className="hidden" id="tableExport">
          <TableExport programsData={programsData} sortSeason={seasons} sortSearch={search} sortAges={ages} />
        </Grid>
      </Grid>
    );
  }
}

ProgramsList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
