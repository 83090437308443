import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { CONVERTTIME, TIMETOAMPM } from '../../../../utils/dateController';
import MenuIcon from '../../../../materialUi/menuIcon';

export function RenderAll({ data, handleProgramSelection }) {
  const menuOptions = ['Program Details', 'Attendees'];
  const duration = `${CONVERTTIME(data.durationStartDate, false)} - ${CONVERTTIME(
    data.durationEndDate,
    false
  )}`;
  const days = data && data.days && data.days
    .map((i) => i.substring(0, 3))
    .toString()
    .replace(/,/g, '-');
  const time = `${TIMETOAMPM(data.programStartTime)} - ${TIMETOAMPM(data.programEndTime)}`;
  return (
    <TableRow hover role="button" tabIndex={0} key={data.programID} className="table-tabSelection">
      <TableCell
        component="th"
        id={data.programID}
        scope="row"
        padding="none"
        className="table-cell"
      >
        {data.name}
      </TableCell>
      <TableCell align="left">{data.ages}</TableCell>
      <TableCell align="left">{data.season}</TableCell>
      <TableCell align="left">{days}</TableCell>
      <TableCell align="left">{time}</TableCell>
      <TableCell align="left">{duration}</TableCell>
      <TableCell
        align="center"
        style={{ width: '200px', paddingRight: '60px', paddingLeft: '60px' }}
      >
        <div
          className={
            data.numberOfSpots < 1 ? 'programsList-thereISNoSpotsLeft' : 'programsList-hasSpotsLeft'
          }
        >
          {data.numberOfSpots}
        </div>
      </TableCell>
      <TableCell align="right">
        <MenuIcon
          list={menuOptions}
          onClick={(value) =>
            handleProgramSelection({
              selectionType: value,
              programID: data.programID,
              programName: data.name,
            })
          }
          width="187"
        />
      </TableCell>
    </TableRow>
  );
}

RenderAll.propTypes = {
  handleProgramSelection: PropTypes.func.isRequired,
  data: PropTypes.shape({
    durationStartDate: PropTypes.string,
    durationEndDate: PropTypes.string,
    days: PropTypes.arrayOf(PropTypes.any),
    programStartTime: PropTypes.string,
    programEndTime: PropTypes.string,
    numberOfSpots: PropTypes.number,
    programID: PropTypes.string,
    name: PropTypes.string,
    ages: PropTypes.string,
    season: PropTypes.string,
  }).isRequired,
};

export function RenderSearchField({
  data,
  sortSearch,
  sortAges,
  sortSeason,
  handleProgramSelection,
}) {
  const menuOptions = ['Program Details', 'Attendees'];
  const duration = `${CONVERTTIME(data.durationStartDate, false)} - ${CONVERTTIME(
    data.durationEndDate,
    false
  )}`;
  const days = days && data.days
    .map((i) => i.substring(0, 3))
    .toString()
    .replace(/,/g, '-');
  const time = `${TIMETOAMPM(data.programStartTime)} - ${TIMETOAMPM(data.programEndTime)}`;

  /* case 1: search = true
             ages = fasle
             seasons = false
  */
  if (
    data.name.toLowerCase().includes(sortSearch.toLowerCase()) &&
    sortAges.length < 1 &&
    sortSeason.length < 1
  ) {
    return (
      <TableRow
        hover
        role="button"
        tabIndex={0}
        key={data.programID}
        className="table-tabSelection"
      >
        <TableCell
          component="th"
          id={data.programID}
          scope="row"
          padding="none"
          className="table-cell"
        >
          {data.name}
        </TableCell>
        <TableCell align="left">{data.ages}</TableCell>
        <TableCell align="left">{data.season}</TableCell>
        <TableCell align="left">{days}</TableCell>
        <TableCell align="left">{time}</TableCell>
        <TableCell align="left">{duration}</TableCell>
        <TableCell
          align="center"
          style={{ width: '200px', paddingRight: '60px', paddingLeft: '60px' }}
        >
          <div
            className={
              data.numberOfSpots < 1
                ? 'programsList-thereISNoSpotsLeft'
                : 'programsList-hasSpotsLeft'
            }
          >
            {data.numberOfSpots}
          </div>
        </TableCell>
        <TableCell align="right">
          <MenuIcon
            list={menuOptions}
            onClick={(value) =>
              handleProgramSelection({
                selectionType: value,
                programID: data.programID,
                programName: data.name,
              })
            }
            width="187"
          />
        </TableCell>
      </TableRow>
    );
  }

  /* case 2: search = true
             ages = true
             seasons = false
  */
  if (
    data.name.toLowerCase().includes(sortSearch.toLowerCase()) &&
    sortAges.length > 0 &&
    sortSeason.length < 1
  ) {
    const menuOptions = ['Program Details', 'Attendees'];
    let result = <></>;
    sortAges.map((age) => {
      if (data.ages.toLowerCase() === age.toLowerCase()) {
        result = (
          <TableRow
            hover
            role="button"
            tabIndex={0}
            key={data.programID}
            className="table-tabSelection"
          >
            <TableCell
              component="th"
              id={data.programID}
              scope="row"
              padding="none"
              className="table-cell"
            >
              {data.name}
            </TableCell>
            <TableCell align="left">{data.ages}</TableCell>
            <TableCell align="left">{data.season}</TableCell>
            <TableCell align="left">{days}</TableCell>
            <TableCell align="left">{time}</TableCell>
            <TableCell align="left">{duration}</TableCell>
            <TableCell
              align="center"
              style={{ width: '200px', paddingRight: '60px', paddingLeft: '60px' }}
            >
              <div
                className={
                  data.numberOfSpots < 1
                    ? 'programsList-thereISNoSpotsLeft'
                    : 'programsList-hasSpotsLeft'
                }
              >
                {data.numberOfSpots}
              </div>
            </TableCell>
            <TableCell align="right">
              <MenuIcon
                list={menuOptions}
                onClick={(value) =>
                  handleProgramSelection({
                    selectionType: value,
                    programID: data.programID,
                    programName: data.name,
                  })
                }
                width="187"
              />
            </TableCell>
          </TableRow>
        );
      }
      return result;
    });
    return result;
  }

  /* case 3: search = true
             ages = true
             seasons = true
  */
  if (
    data.name.toLowerCase().includes(sortSearch.toLowerCase()) &&
    sortAges.length > 0 &&
    sortSeason.length > 0
  ) {
    const menuOptions = ['Program Details', 'Attendees'];
    let result;
    sortSeason.map((season) => {
      if (data.season.toLowerCase() === season.toLowerCase()) {
        result = (
          <TableRow
            hover
            onClick={() => handleProgramSelection(data.programID)}
            role="button"
            tabIndex={0}
            className="table-tabSelection"
            onKeyPress={() => handleProgramSelection(data.programID)}
            key={data.programID}
          >
            <TableCell
              component="th"
              id={data.programID}
              scope="row"
              padding="none"
              className="table-cell"
            >
              {data.name}
            </TableCell>
            <TableCell align="left">{data.ages}</TableCell>
            <TableCell align="left">{data.season}</TableCell>
            <TableCell align="left">{days}</TableCell>
            <TableCell align="left">{time}</TableCell>
            <TableCell align="left">{duration}</TableCell>
            <TableCell
              align="center"
              style={{ width: '200px', paddingRight: '60px', paddingLeft: '60px' }}
            >
              <div
                className={
                  data.numberOfSpots < 1
                    ? 'programsList-thereISNoSpotsLeft'
                    : 'programsList-hasSpotsLeft'
                }
              >
                {data.numberOfSpots}
              </div>
            </TableCell>
            <TableCell align="right">
              <MenuIcon
                list={menuOptions}
                onClick={(value) =>
                  handleProgramSelection({
                    selectionType: value,
                    programID: data.programID,
                    programName: data.name,
                  })
                }
                width="187"
              />
            </TableCell>
          </TableRow>
        );
      } else {
        result = <></>;
      }
      return result;
    });
    return result;
  }
  return <></>;
}

RenderSearchField.propTypes = {
  handleProgramSelection: PropTypes.func.isRequired,
  data: PropTypes.shape({
    durationStartDate: PropTypes.string,
    durationEndDate: PropTypes.string,
    days: PropTypes.arrayOf(PropTypes.any),
    programStartTime: PropTypes.string,
    programEndTime: PropTypes.string,
    numberOfSpots: PropTypes.number,
    programID: PropTypes.string,
    name: PropTypes.string,
    ages: PropTypes.string,
    season: PropTypes.string,
  }).isRequired,
};

export function RenderAgesFilter({ data, sortAges, sortSeason, handleProgramSelection }) {
  const menuOptions = ['Program Details', 'Attendees'];
  const duration = `${CONVERTTIME(data.durationStartDate, false)} - ${CONVERTTIME(
    data.durationEndDate,
    false
  )}`;
  const days = days && data.days
    .map((i) => i.substring(0, 3))
    .toString()
    .replace(/,/g, '-');
  const time = `${TIMETOAMPM(data.programStartTime)} - ${TIMETOAMPM(data.programEndTime)}`;
  /*
  case: search = false
        ages = true
        season = false
  */
  if (sortAges.length > 0 && sortSeason.length < 1) {
    let result = <></>;
    sortAges.map((age) => {
      if (data.ages.toLowerCase() === age.toLowerCase()) {
        result = (
          <TableRow
            hover
            onClick={() => handleProgramSelection(data.programID)}
            role="button"
            tabIndex={0}
            className="table-tabSelection"
            key={data.programID}
          >
            <TableCell
              component="th"
              id={data.programID}
              scope="row"
              padding="none"
              className="table-cell"
            >
              {data.name}
            </TableCell>
            <TableCell align="left">{data.ages}</TableCell>
            <TableCell align="left">{data.season}</TableCell>
            <TableCell align="left">{days}</TableCell>
            <TableCell align="left">{time}</TableCell>
            <TableCell align="left">{duration}</TableCell>
            <TableCell
              align="center"
              style={{ width: '200px', paddingRight: '60px', paddingLeft: '60px' }}
            >
              <div
                className={
                  data.numberOfSpots < 1
                    ? 'programsList-thereISNoSpotsLeft'
                    : 'programsList-hasSpotsLeft'
                }
              >
                {data.numberOfSpots}
              </div>
            </TableCell>
            <TableCell align="right">
              <MenuIcon
                list={menuOptions}
                onClick={(value) =>
                  handleProgramSelection({
                    selectionType: value,
                    programID: data.programID,
                    programName: data.name,
                  })
                }
                width="187"
              />
            </TableCell>
          </TableRow>
        );
      }
      return result;
    });
    return result;
  }

  /*
  case: search = false
        ages = true
        season = true
  */
  if (sortAges.length > 0 && sortSeason.length > 0) {
    const menuOptions = ['Program Details', 'Attendees'];
    let result = <></>;
    sortAges.map((age) => {
      if (data.ages.toLowerCase() === age.toLowerCase()) {
        sortSeason.map((season) => {
          if (data.season.toLowerCase() === season.toLowerCase()) {
            result = (
              <TableRow
                hover
                onClick={() => handleProgramSelection(data.programID)}
                role="button"
                tabIndex={0}
                className="table-tabSelection"
                key={data.programID}
              >
                <TableCell
                  component="th"
                  id={data.programID}
                  scope="row"
                  padding="none"
                  className="table-cell"
                >
                  {data.name}
                </TableCell>
                <TableCell align="left">{data.ages}</TableCell>
                <TableCell align="left">{data.season}</TableCell>
                <TableCell align="left">{days}</TableCell>
                <TableCell align="left">{time}</TableCell>
                <TableCell align="left">{duration}</TableCell>
                <TableCell
                  align="center"
                  style={{ width: '200px', paddingRight: '60px', paddingLeft: '60px' }}
                >
                  <div
                    className={
                      data.numberOfSpots < 1
                        ? 'programsList-thereISNoSpotsLeft'
                        : 'programsList-hasSpotsLeft'
                    }
                  >
                    {data.numberOfSpots}
                  </div>
                </TableCell>
                <TableCell align="right">
                  <MenuIcon
                    list={menuOptions}
                    onClick={(value) =>
                      handleProgramSelection({
                        selectionType: value,
                        programID: data.programID,
                        programName: data.name,
                      })
                    }
                    width="187"
                  />
                </TableCell>
              </TableRow>
            );
          }
          return result;
        });
      }
      return result;
    });
    return result;
  }
  return '';
}

RenderAgesFilter.propTypes = {
  handleProgramSelection: PropTypes.func.isRequired,
  data: PropTypes.shape({
    durationStartDate: PropTypes.string,
    durationEndDate: PropTypes.string,
    days: PropTypes.arrayOf(PropTypes.any),
    programStartTime: PropTypes.string,
    programEndTime: PropTypes.string,
    numberOfSpots: PropTypes.number,
    programID: PropTypes.string,
    name: PropTypes.string,
    ages: PropTypes.string,
    season: PropTypes.string,
  }).isRequired,
};

export function RenderSeasonFilter({ data, sortAges, sortSeason, handleProgramSelection }) {
  const duration = `${CONVERTTIME(data.durationStartDate, false)} - ${CONVERTTIME(
    data.durationEndDate,
    false
  )}`;
  const days = days && data.days
    .map((i) => i.substring(0, 3))
    .toString()
    .replace(/,/g, '-');
  const time = `${TIMETOAMPM(data.programStartTime)} - ${TIMETOAMPM(data.programEndTime)}`;
  /*
  case: search = false
        ages = false
        season = true
  */
  if (sortSeason.length > 0 && sortAges.length < 1) {
    const menuOptions = ['Program Details', 'Attendees'];
    let result = <></>;
    sortSeason.map((season) => {
      if (data.season.toLowerCase() === season.toLowerCase()) {
        result = (
          <TableRow
            hover
            onClick={() => handleProgramSelection(data.programID)}
            role="button"
            tabIndex={0}
            className="table-tabSelection"
            key={data.programID}
          >
            <TableCell
              component="th"
              id={data.programID}
              scope="row"
              padding="none"
              className="table-cell"
            >
              {data.name}
            </TableCell>
            <TableCell align="left">{data.ages}</TableCell>
            <TableCell align="left">{data.season}</TableCell>
            <TableCell align="left">{days}</TableCell>
            <TableCell align="left">{time}</TableCell>
            <TableCell align="left">{duration}</TableCell>
            <TableCell
              align="center"
              style={{ width: '200px', paddingRight: '60px', paddingLeft: '60px' }}
            >
              <div
                className={
                  data.numberOfSpots < 1
                    ? 'programsList-thereISNoSpotsLeft'
                    : 'programsList-hasSpotsLeft'
                }
              >
                {data.numberOfSpots}
              </div>
            </TableCell>
            <TableCell align="right">
              <MenuIcon
                list={menuOptions}
                onClick={(value) =>
                  handleProgramSelection({
                    selectionType: value,
                    programID: data.programID,
                    programName: data.name,
                  })
                }
                width="187"
              />
            </TableCell>
          </TableRow>
        );
      }
      return result;
    });
    return result;
  }

  /*
  case: search = false
        ages = true
        season = true
  */
  if (sortAges.length > 0 && sortSeason.length > 0) {
    const menuOptions = ['Program Details', 'Attendees'];
    let result = <></>;
    sortAges.map((age) => {
      if (data.ages.toLowerCase() === age.toLowerCase()) {
        sortSeason.map((season) => {
          if (data.season.toLowerCase() === season.toLowerCase()) {
            result = (
              <TableRow
                hover
                onClick={() => handleProgramSelection(data.programID)}
                role="button"
                tabIndex={0}
                className="table-tabSelection"
                key={data.programID}
              >
                <TableCell
                  component="th"
                  id={data.programID}
                  scope="row"
                  padding="none"
                  className="table-cell"
                >
                  {data.name}
                </TableCell>
                <TableCell align="left">{data.ages}</TableCell>
                <TableCell align="left">{data.season}</TableCell>
                <TableCell align="left">{days}</TableCell>
                <TableCell align="left">{time}</TableCell>
                <TableCell align="left">{duration}</TableCell>
                <TableCell
                  align="center"
                  style={{ width: '200px', paddingRight: '60px', paddingLeft: '60px' }}
                >
                  <div
                    className={
                      data.numberOfSpots < 1
                        ? 'programsList-thereISNoSpotsLeft'
                        : 'programsList-hasSpotsLeft'
                    }
                  >
                    {data.numberOfSpots}
                  </div>
                </TableCell>
                <TableCell align="right">
                  <MenuIcon
                    list={menuOptions}
                    onClick={(value) =>
                      handleProgramSelection({
                        selectionType: value,
                        programID: data.programID,
                        programName: data.name,
                      })
                    }
                    width="187"
                  />
                </TableCell>
              </TableRow>
            );
          }
          return result;
        });
      }
      return result;
    });
    return result;
  }
  return '';
}

RenderSeasonFilter.propTypes = {
  handleProgramSelection: PropTypes.func.isRequired,
  data: PropTypes.shape({
    durationStartDate: PropTypes.string,
    durationEndDate: PropTypes.string,
    days: PropTypes.arrayOf(PropTypes.any),
    programStartTime: PropTypes.string,
    programEndTime: PropTypes.string,
    numberOfSpots: PropTypes.number,
    programID: PropTypes.string,
    name: PropTypes.string,
    ages: PropTypes.string,
    season: PropTypes.string,
  }).isRequired,
};
