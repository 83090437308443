import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

function MemberFound({ selectedMember, deleteSelectedMember }) {
  const { familyMembersInformation } = selectedMember;
  return (
    <Grid item xs={12}>
      <Grid container className="programRegistration-membership-box mb-4">
        <button
          type="button"
          className="programRegistration-box-close-icon"
          onClick={() => deleteSelectedMember()}
        >
          <CloseIcon />
        </button>
        <Grid item xs={12}>
          <h3 className="programRegistration-membership-box-title">
            {familyMembersInformation[0].firstName}
            {' '}
            {familyMembersInformation[0].lastName}
          </h3>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item>
              <h4 className="programRegistration-membership-box-subTitle">
                Email:
              </h4>
              {selectedMember.email || 'N/A'}
              <span className="boxes-divider">|</span>
            </Grid>
            <Grid item>
              <h4 className="programRegistration-membership-box-subTitle">
                Phone Number:
              </h4>
              {familyMembersInformation[0].phone}
              <span className="boxes-divider">|</span>
            </Grid>
            <Grid item>
              <h4 className="programRegistration-membership-box-subTitle">
                Address:
              </h4>
              {selectedMember.address.street}
              {', '}
              {selectedMember.address.city}
              {', '}
              {selectedMember.address.province}
            </Grid>
            <Grid item xs={12}>
              <h4 className="programRegistration-membership-box-subTitle">
                Membership:
              </h4>
              {selectedMember.membershipType}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

MemberFound.propTypes = {

};

export default MemberFound;
