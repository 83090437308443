import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '../../../materialUi/textField';
import { Covid19API } from '../../../API/API-covid19';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


export default class ChangeCapacity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      capacity: '',
      capacityError: '',
      capacityMessage: '',
    };
  }

  handleSubmit = () => {
    const { capacityID, dailyCheckIn, close } = this.props;
    const {
      capacity, capacityError,
    } = this.state;
    if (capacityError === false) {
      const peopleInsideTheCenter = dailyCheckIn.capacity - dailyCheckIn.availableSpots;
      dailyCheckIn.availableSpots = capacity - peopleInsideTheCenter;
      dailyCheckIn.capacity = capacity;

      Covid19API.PUT.changeCovid19Capacity(capacityID, dailyCheckIn)
        .then((success) => {
          console.log(success);
          Covid19API.EntranceUpdate.getInitialData();
          close();
        }).catch((error) => {
          console.log(error.response);
        });
    } else {
      this.setState({ capacityError: true, capacityMessage: 'Capacity Cannot be empty'});
    }
  }

  handleCapacity = (value) =>{
    if (value.length < 1) {
      this.setState({
        capacityError: true, capacityMessage: 'Capacity Cannot be empty', capacity: value 
      });
    } else {
      this.setState({ capacityError: false, capacityMessage: '', capacity: value });
    }
  }

  handleClose = () => {
    const { close } = this.props;
    this.setState({ showErrorMessage: false });
    close();
  }

  render() {
    const {
      open,
      close,
    } = this.props;
    const {
      capacity, capacityMessage, capacityError,
    } = this.state;
    return (
      <Grid className="font-family-default">
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth="xs"
          fullWidth
        >
          <DialogContent dividers>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className="covid19-changeCapacity-alert"
            >
              <button
                onClick={this.handleClose}
                type="button"
                className="covid19-checkout-close"
                aria-label="Close Checkout Dialog"
              >
                <i className="fas fa-times close-icon" />
              </button>
              <Box>
                <Grid container>
                  <Grid item xs={12} className="covid19-checkout-title">
                    Change the Center Capacity
                  </Grid>
                  <Grid item xs={12} className="covid19-checkout-confirm">
                    <TextField
                      label="Capacity"
                      id="centerCapacity"
                      name="Capacity"
                      value={capacity}
                      handleChanges={(value) => this.handleCapacity(value.target.value)}
                      helperText={capacityMessage}
                      error={capacityError}
                      readOnly={false}
                      type="number"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid className="settings-btns-body-delete">
              <Button onClick={close} className="alert-btns">
                Cancel
              </Button>
              <Button onClick={this.handleSubmit} className="alert-btns">
                Submit
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      </Grid>

    );
  }
}


ChangeCapacity.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  capacityID: PropTypes.string.isRequired,
  dailyCheckIn: PropTypes.shape({
    dailyCheckInID: PropTypes.string,
    capacity: PropTypes.number,
    availableSpots: PropTypes.number,
  }).isRequired,
};
