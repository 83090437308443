import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";
import "../../../../css/registrationList.css";

function createData(programName, date, numberOfAttendees, id) {
  const formattedDate = formatDate(date);
  return {
    programName,
    formattedDate,
    numberOfAttendees,
    id,
  };
}
function formatDate(isoString) {
  const date = new Date(isoString);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  {
    id: "ProgramName",
    numeric: false,
    disablePadding: true,
    label: "Program name",
  },
  {
    id: "createdDate",
    numeric: false,
    disablePadding: false,
    label: "Created date",
  },
  {
    id: "NumberOfAttendees",
    numeric: false,
    disablePadding: false,
    label: "Number of Attendees",
  },
  {
    id: "action1",
    numeric: false,
    disablePadding: false,
    label: "",
  },
  {
    id: "action2",
    numeric: false,
    disablePadding: false,
    label: "",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="bg-light">
      <TableRow>
        <TableCell padding="checkbox" className="check" />
        {headRows.map((row) => (
          <TableCell
            key={row.id}
            align={row.numeric ? "center" : "left"}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? order : false}
            className="table-cell"
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => <div />;

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "inherit",
    },
  },
}));

export default function AttendeesTable({
  attendanceData,
  sortSearch,
  programName,
  values,
  handleDeleteParticipant,
}) {
  const rows = [];

  for (let i = 0; i < attendanceData.length; i++) {
    if (values.startDate !== "" && values.endDate !== "") {
      const dateAdjustment = new Date(attendanceData[i].date);
      dateAdjustment.setDate(dateAdjustment.getDate());
      if (
        new Date(attendanceData[i].date).toISOString() >=
          new Date(values.startDate).toISOString() &&
        new Date(dateAdjustment).toISOString() <=
          new Date(values.endDate).toISOString()
      ) {
        rows.push(
          createData(
            programName,
            attendanceData[i].date,
            attendanceData[i].familyMembers.length,
            attendanceData[i]._id
          )
        );
      }
    } else {
      rows.push(
        createData(
          programName,
          attendanceData[i].date,
          attendanceData[i].familyMembers.length,
          attendanceData[i]._id
        )
      );
    }
  }

  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("formattedDate");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const history = useHistory();

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleEditParticipant(attendanceId) {
    const url = window.location.href;
    const id = url.substring(url.lastIndexOf("/") + 1);
    const grabSelectedItemsDate = attendanceData.find(
      (item) => item._id === attendanceId.attendanceId
    );
    sessionStorage.setItem(
      "exisitingAttendanceDate",
      JSON.stringify(grabSelectedItemsDate.date)
    );
    history.push(
      `/adminPortal/programs/addAttendance/${id}/${attendanceId.attendanceId}`
    );
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="button"
                      tabIndex={0}
                      className="table-hoverNone"
                      key={row.id}
                    >
                      <TableCell padding="checkbox" className="check" />
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        className="table-cell"
                      >
                        {row.programName}
                      </TableCell>
                      <TableCell align="left" className="text-capitalize">
                        {row.formattedDate}
                      </TableCell>
                      <TableCell align="left" className="text-capitalize">
                        {row.numberOfAttendees}
                      </TableCell>
                      <TableCell align="center">
                        <EditIcon
                          className="settings-edit"
                          fontSize="small"
                          onClick={() =>
                            handleEditParticipant({
                              attendanceId: row.id,
                            })
                          }
                        />
                        <span
                          className="settings-edit"
                          onClick={() =>
                            handleEditParticipant({
                              attendanceId: row.id,
                            })
                          }
                        >
                          Edit
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <DeleteIcon
                          className="settings-delete-icon"
                          fontSize="small"
                          onClick={() =>
                            handleDeleteParticipant({
                              attendanceId: row.id,
                            })
                          }
                        />
                        <span
                          className="settings-delete"
                          onClick={() =>
                            handleDeleteParticipant({
                              attendanceId: row.id,
                            })
                          }
                        >
                          Delete
                        </span>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }} />
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

AttendeesTable.propTypes = {
  sortSearch: PropTypes.string.isRequired,
  handleDeleteParticipant: PropTypes.func.isRequired,
  attendanceData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
    .isRequired,
  programName: PropTypes.string.isRequired,
  values: PropTypes.shape({
    startDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    endDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
  }).isRequired,
};
