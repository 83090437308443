import { CONVERTTIME, TIMETOAMPM } from '../../../../utils/dateController';

function createData(programID, name, ages, seasons, days, time, duration, numberOfSpots) {
  return {
    programID,
    name,
    ages,
    seasons,
    days,
    time,
    duration,
    numberOfSpots,
  };
}

export const searchProgram = (programData, sortSearch) => {
  if (programData.name.toLowerCase().includes(sortSearch.toLowerCase())) {
    return true;
  }
  return false;
};

const formatDays = (days) => {
  const formattedDays =
    days && days.length > 0
      ? days
        .map((i) => i.substring(0, 3))
        .toString()
        .replace(/,/g, '-')
      : 'Variable';
  return formattedDays;
};

const formatSeasons = (programData) => {
  let result = programData.seasons;
  if (programData.season && !programData.seasons) {
    result = programData.season;
  } else if (programData.seasons.length > 1) {
    return 'Multiple';
  }
  return result;
};

const formateAges = (programData) => programData.ages || `${programData.ageMinimum} - ${programData.ageMaximum}`;

const formateTime = (programData) => {
  if (programData.weeklyProgram) {
    return `${TIMETOAMPM(programData.weeklyStartTime)} - ${TIMETOAMPM(programData.weeklyEndTime)}`;
  }
  if (programData.variableSchedule && programData.variableSchedule.length > 0) {
    return 'Variable';
  }
  return `${TIMETOAMPM(programData.programStartTime)} - ${TIMETOAMPM(programData.programEndTime)}`;
};

const formateDuration = (programData) => {
  return `${CONVERTTIME(programData.durationStartDate, false)} - ${CONVERTTIME(programData.durationEndDate, false)}`;
};

const getWeeklySpots = (programData) => {
  const weeklySchedule = programData.weeklySchedule;
  let result = 0;
  weeklySchedule.forEach((week) => {
    result += (week.associateSpots + week.communitySpots - week.familyMembers.length)
  });
  return result;
};

const formateNumberOfSpots = (programData) => {
  if (programData.weeklyProgram) {
    return getWeeklySpots(programData);
  }
  return programData.associateSpots + programData.communitySpots;
};

export const pushProgramsResults = (rows, programData) => {
  rows.push(
    createData(
      programData.programID,
      programData.name,
      formateAges(programData),
      formatSeasons(programData),
      formatDays(programData.days),
      formateTime(programData),
      formateDuration(programData),
      formateNumberOfSpots(programData)
    )
  );
};
