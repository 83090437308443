import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CloseIcon from '@material-ui/icons/Close';
import UploadIcon from '../../../../assets/icons/upload-cloud.svg';
import PDFIcon from '../../../../assets/icons/pdf-icon.svg';
import PPTIcon from '../../../../assets/icons/pptIcon.png';
import imageIcon from '../../../../assets/icons/imageIcon.png';
import docIcon from '../../../../assets/icons/docIcon.png';
import Button from '../../../../materialUi/btn';
import RadioGroup from '../../../../materialUi/radioGroup';
import { taxReturnAPI } from '../../../../API/API-taxReturn';


export default function UploadDialog({
  open, setOpen, handleCancel, data, handleUpdateList,
}) {
  const [localFiles, setLocalFiles] = React.useState([]);
  const [filesToSave, setFilesToSave] = React.useState([]);
  const [change, setChange] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [permission, setPermission] = React.useState('admin');

  const permissionOptions = [
    {
      value: 'admin',
      label: 'Upload to Admin portal only',
    },
    {
      value: 'user',
      label: 'Send attachments to user to view',
    },
  ];

  const validateFileSize = (files) => {
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > 100000000) {
        setErrorMessage('File size too large! Please, select a file smaller than 100MB');
        return false;
      }
    }
    return true;
  };

  const validateFileFormat = (files) => {
    for (let i = 0; i < files.length; i++) {
      if (files[i].type !== 'application/msword'
      && files[i].type !== 'application/pdf'
      && files[i].type !== 'application/vnd.ms-powerpoint'
      && files[i].type !== 'image/png'
      && files[i].type !== 'image/jpeg'
      && files[i].type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      && files[i].type !== 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
      ) {
        setErrorMessage('Files must be in .pdf, .doc, .docx, .pptx, .png or .jpeg format');
        return false;
      }
    }
    return true;
  };

  const renderIconBasedOnDocType = (item) => {
    if (item.type === 'application/msword') {
      return docIcon;
    } if (item.type === 'application/pdf') {
      return PDFIcon;
    } if (item.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
      return PPTIcon;
    }
    return imageIcon;
  };

  const sendFile = (event) => {
    const { files } = event.target;
    if (validateFileSize(files) && validateFileFormat(files)) {
      setFilesToSave(files);
      setLocalFiles(Array.from(files));
      setErrorMessage('');
    }
  };

  const handleRemoveFile = (index) => {
    localFiles.splice(index, 1);
    setLocalFiles(localFiles);
    setChange(!change);
  };

  const checkIfUserHasSelectedAFile = () => {
    if (filesToSave.length < 1) {
      setErrorMessage('Please select at least one file');
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (checkIfUserHasSelectedAFile()) {
      taxReturnAPI.POST.uploadAdminTaxFiles(filesToSave, data.userID, permission).then((response) => {
        console.log(response);
        handleUpdateList();
        handleCancel();
        setLocalFiles([]);
        setFilesToSave([]);
      }).catch((error) => {
        console.log(error);
      });
    }
  };

  const handlePermission = (event) => {
    setPermission(event.target.value);
  };


  return (
    <Dialog
      open={open}
      onClose={setOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="Upload dialog, upload your documents by selecting the documents and click on submit."
      fullWidth
      maxWidth="sm"
    >
      <h1 className="upload-dialog-title">
        Upload File and Images
      </h1>
      <DialogContent>
        <button
          type="button"
          className="upload-dialog-close"
          onClick={setOpen}
        >
          <CloseIcon />
        </button>
        <DialogContentText id="alert-dialog-description">
          <Grid container className="upload-dialog-box">
            <Grid item xs={12} align="center">
              <img src={UploadIcon} alt="upload icon" />
            </Grid>
            <Grid item xs={12} align="center">
              <h2 className="upload-dialog-box-title">
                File Upload
              </h2>
              <div>
                <button
                  tabIndex="0"
                  type="button"
                  className="upload-dialog-upload-button"
                >
                  CHOOSE FILE
                  <input
                    id="myFileInput"
                    name="taxReturnFiles"
                    type="file"
                    accept="image/*;capture=camera"
                    className="upload-dialog-input-button"
                    multiple
                    onChange={sendFile}
                  />
                </button>
              </div>
              <div className="custom-error mt-3">
                {errorMessage}
              </div>
              <h4 className="upload-dialog-box-description">
                Files must be in .pdf, .doc, .docx, .pptx, .png or .jpeg format. Max 100MB.
              </h4>
            </Grid>
          </Grid>
          <Grid container>
            {localFiles.map((item, index) => (
              <Box className="upload-dialog-document" key={item.name}>
                <img
                  src={renderIconBasedOnDocType(item)}
                  alt="PDF icon"
                  width="25px"
                  height="25px"
                />
                <span className="upload-dialog-document-text">
                  {item.name || item.fileName}
                  <button
                    type="button"
                    className="upload-dialog-remove-file"
                    onClick={() => handleRemoveFile(index)}
                  >
                    <CloseIcon fontSize="small" />
                  </button>
                </span>
              </Box>
            ))}
          </Grid>
          <h3 className="upload-dialog-permissions">Permissions</h3>
          <RadioGroup
            listOptions={permissionOptions}
            ariaLabel="Permissions"
            name="permissions"
            value={permission}
            handleChange={handlePermission}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: '8px 24px' }}>
        <Box style={{ marginRight: '10px' }}>
          <Button
            label="Cancel"
            bgColor="white"
            color="#E76F51"
            bgColorHover="#E76F51"
            colorHover="white"
            borderColor="#E76F51"
            variant="outlined"
            width={128}
            onClick={handleCancel}
            type="button"
          />
        </Box>
        <Button
          label="Submit"
          bgColor="#e76f50"
          color="white"
          colorHover="white"
          bgColorHover="#ff8c68"
          width={128}
          onClick={handleSubmit}
          type="submit"
        />
      </DialogActions>
    </Dialog>
  );
}

UploadDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleUpdateList: PropTypes.func.isRequired,
  data: PropTypes.shape(
    {
      userID: PropTypes.string,
    },
  ).isRequired,
};
