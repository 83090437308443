import React from 'react';
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';

export default function StackedBarChart(props) {
  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { dailyCheckInPerMonth } = props;

  let data;
  if (props.dailyCheckInPerMonth) {
    data = [
      {
        name: 'Jan',
        Member: dailyCheckInPerMonth.jan.members,
        Staff: dailyCheckInPerMonth.jan.staff,
        Guest: dailyCheckInPerMonth.jan.guests,
      },
      {
        name: 'Feb',
        Member: dailyCheckInPerMonth.feb.members,
        Staff: dailyCheckInPerMonth.feb.staff,
        Guest: dailyCheckInPerMonth.feb.guests,
      },
      {
        name: 'Mar',
        Member: dailyCheckInPerMonth.mar.members,
        Staff: dailyCheckInPerMonth.mar.staff,
        Guest: dailyCheckInPerMonth.mar.guests,
      },
      {
        name: 'Apr',
        Member: dailyCheckInPerMonth.apr.members,
        Staff: dailyCheckInPerMonth.apr.staff,
        Guest: dailyCheckInPerMonth.apr.guests,
      },
      {
        name: 'May',
        Member: dailyCheckInPerMonth.may.members,
        Staff: dailyCheckInPerMonth.may.staff,
        Guest: dailyCheckInPerMonth.may.guests,
      },
      {
        name: 'June',
        Member: dailyCheckInPerMonth.jun.members,
        Staff: dailyCheckInPerMonth.jun.staff,
        Guest: dailyCheckInPerMonth.jun.guests,
      },
      {
        name: 'Jul',
        Member: dailyCheckInPerMonth.jul.members,
        Staff: dailyCheckInPerMonth.jul.staff,
        Guest: dailyCheckInPerMonth.jul.guests,
      },
      {
        name: 'Aug',
        Member: dailyCheckInPerMonth.aug.members,
        Staff: dailyCheckInPerMonth.aug.staff,
        Guest: dailyCheckInPerMonth.aug.guests,
      },
      {
        name: 'Sept',
        Member: dailyCheckInPerMonth.sep.members,
        Staff: dailyCheckInPerMonth.sep.staff,
        Guest: dailyCheckInPerMonth.sep.guests,
      },
      {
        name: 'Oct',
        Member: dailyCheckInPerMonth.oct.members,
        Staff: dailyCheckInPerMonth.oct.staff,
        Guest: dailyCheckInPerMonth.oct.guests,
      },
      {
        name: 'Nov',
        Member: dailyCheckInPerMonth.nov.members,
        Staff: dailyCheckInPerMonth.nov.staff,
        Guest: dailyCheckInPerMonth.nov.guests,
      },
      {
        name: 'Dez',
        Member: dailyCheckInPerMonth.dez.members,
        Staff: dailyCheckInPerMonth.dez.staff,
        Guest: dailyCheckInPerMonth.dez.guests,
      },
    ];
  }

  if (!dailyCheckInPerMonth) return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      className="graph-no-data"
    >
      No data to display
    </Grid>
  );

  return (
    <ResponsiveContainer width={mdScreen ? "83%" : "90%"} height={500} className="stackedBarChart">
      <BarChart
        width={100}
        height={400}
        data={data}
        margin={{
          top: 60,
          right: 0,
          left: 20,
          bottom: 5,
        }}
      >
        <Legend
          payload={[
            {
              id: 'Member',
              value: 'Member',
              type: 'square',
              color: '#202F35',
            },
            {
              id: 'Staff',
              value: 'Staff',
              type: 'square',
              color: '#E9C46A',
            },
            {
              id: 'Guest',
              value: 'Guest',
              type: 'square',
              color: '#E76F51',
            },
          ]}
          className="stackedBarChart-legend"
          layout="horizontal"
          verticalAlign="top"
          align="left"
        />
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Member" stackId="a" fill="#202F35" dot={false} barSize={44} />
        <Bar dataKey="Staff" stackId="a" fill="#E9C46A" dot={false} barSize={44} />
        <Bar dataKey="Guest" stackId="a" fill="#E76F51" dot={false} barSize={44} />
      </BarChart>
    </ResponsiveContainer>
  );
}

StackedBarChart.propTypes = {};
