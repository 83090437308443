import React from 'react';
import { Grid } from '@material-ui/core';
import Button from '../../../../materialUi/btn';
import VariableScheduleBox from './variableScheduleBox';

function Variable(props) {
  const {
    register,
    unregister,
    setValue,
    data,
  } = props;

  const [variableSchedule, setVariableSchedule] = React.useState([{ date: null, startTime: '', endTime: '' }]);

  const handleAddNewSchedule = () => {
    const scheduleModel = { date: null, startTime: '', endTime: '' };
    const copySchedule = [...variableSchedule];
    copySchedule.push(scheduleModel);
    setVariableSchedule(copySchedule);
  };

  const unregisterDeletedSchedule = (scheduleNumber, newSchedule) => {
    unregister(`date${scheduleNumber}`);
    unregister(`startTime${scheduleNumber}`);
    unregister(`endTime${scheduleNumber}`);
    setValue('variableSchedule', newSchedule);
  };

  const deleteSchedule = (scheduleNumber) => {
    const copySchedule = [...variableSchedule];
    if (scheduleNumber !== 0) {
      copySchedule.splice(scheduleNumber, 1);
      unregisterDeletedSchedule(scheduleNumber, copySchedule);
    }
    setVariableSchedule(copySchedule);
  };

  React.useEffect(() => {
    if (!data.variableSchedule) {
      register('variableSchedule', variableSchedule);
    } else {
      let copySchedule = [...variableSchedule];
      copySchedule = data.variableSchedule;
      setVariableSchedule(copySchedule);
      setValue('variableSchedule', data.variableSchedule);
    }
  }, []);

  return (
    <Grid container>
      {variableSchedule.map((item, index) => (
        <VariableScheduleBox
          scheduleNumber={index}
          deleteSchedule={deleteSchedule}
          variableSchedule={variableSchedule}
          {...props}
        />
      ))}
      <Grid item xs={12}>
        <Button
          label="Add New schedule"
          bgColor="white"
          color="#E76F51"
          bgColorHover="#E76F51"
          colorHover="white"
          borderColor="#E76F51"
          variant="outlined"
          width={200}
          height={36}
          type="button"
          onClick={handleAddNewSchedule}
        />
      </Grid>
    </Grid>
  );
}

export default Variable;
