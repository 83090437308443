import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import TopContent from '../../../materialUi/taxes/questionnaire/topContent';
import Stepper from '../../../materialUi/stepper';
import StepOne from '../../../materialUi/taxes/questionnaire/stepOne/stepOne';
import StepTwo from '../../../materialUi/taxes/questionnaire/stepTwo/stepTwo';
import StepThree from '../../../materialUi/taxes/questionnaire/stepThree/stepThree';
import { taxReturnAPI } from '../../../API/API-taxReturn';
import PreLoading from '../../../materialUi/preLoading';
import DefaultAlert from '../../../materialUi/defaultAlert';

function EditTaxSubmission({ history, match }) {
  const [step, setStep] = React.useState(0);
  const stepsList = window.innerWidth < 600 ? ['', '', '', ''] : ['Personal Information', 'Tax Information', 'Upload Documents'];
  const [loadData, setLoadData] = React.useState(false);
  const [data, setData] = React.useState({});

  const getTaxReturnData = () => {
    const { formID } = match.params;
    taxReturnAPI.GET.getTaxSubmissionById(formID)
      .then((response) => {
        setData(response.data);
        setLoadData(true);
      })
      .catch((err) => {
        const { error } = err.response.data;
        if (error === 'tax return id is required') {
          window.location.reload();
          setLoadData(false);
        } else {
          setLoadData(true);
        }
      });
  };

  const goBackToTheErrorStep = () => {
    setStep(step - 1);
  };

  const uploadTaxFiles = (formData) => {
    taxReturnAPI.POST.uploadTaxFiles(formData.selectedFiles, formData.userID)
      .then((success) => {
        getTaxReturnData();
        // console.log(success.data.uploaded);
      })
      .catch((error) => {
        goBackToTheErrorStep();
        handleAlert('error', 'Server error', (error.response.data) ? error.response.data : 'Server Error!');
      });
  };

  const uploadDirectDeposit = (formData) => {
    taxReturnAPI.POST.uploadDirectDepositForm(formData.selectedDepositForm)
      .then((success) => {
        getTaxReturnData();
      })
      .catch((error) => {
        console.log(error);
        goBackToTheErrorStep();
        handleAlert('error', 'Server error', 'Please try again later');
      });
  };

  const [defaultAlert, setDefaultAlert] = React.useState({
    open: false,
    severity: '',
    title: '',
    body: '',
  });

  const handleAlert = (severity, title, body) => {
    setDefaultAlert({
      open: true,
      severity,
      title,
      body,
    });
    setTimeout(() => {
      setDefaultAlert({
        open: false,
        severity,
        title,
        body,
      });
    }, 5000);
  };

  const updateOneTaxReturn = (formData) => {
    taxReturnAPI.PUT.updateOneTaxReturn(formData)
      .then((response) => {
        console.log(response.data.message);
        if (step <= 2) {
          if (formData.selectedFiles) {
            uploadTaxFiles(formData);
          }
          if (formData.selectedDepositForm) {
            uploadDirectDeposit(formData);
          }
        }
        handleAlert('success', 'Success', 'Your tax return file was saved!');
        getTaxReturnData();
      })
      .catch((error) => {
        console.log(error);
        handleAlert('error', 'Server error', 'Please try again later');
      });
  };

  const saveCurrentTaxReturn = (formData) => {
    updateOneTaxReturn(formData);
  };

  const handleNextStep = async (newData, newStep) => {
    let copyData = data;
    copyData = { ...data, ...newData };
    await saveCurrentTaxReturn(copyData);
    // newStep 3 means done
    if (newStep === 3) {
      history.push('/scccPortal/taxes/submissions');
    } else {
      setStep(newStep);
    }
  };

  const handleBackSteps = () => {
    if (step - 1 >= 0) return setStep(step - 1);
    return history.push(`/scccPortal/taxes/view/${data._id}`);
  };

  React.useEffect(() => {
    getTaxReturnData();
  }, []);

  if (loadData) {
    return (
      <Grid item xs className="font-family-default" style={{position: 'relative'}}>
        <DefaultAlert
          open={defaultAlert.open}
          severity={defaultAlert.severity}
          title={defaultAlert.title}
          body={defaultAlert.body}
          top="150px"
        />
        <Stepper labels={stepsList} step={step} />
        <div className="questionnaire-wrapper">
          <TopContent 
            step={step} 
            text={stepsList[step - 1] ? stepsList[step - 1] : 'Back to view submission'}
            handleStep={handleBackSteps} 
            disableBack={false} 
          />
          {(step === 0 || step === 4) && (
            <StepOne 
              data={data} 
              handleNextStep={handleNextStep} 
              handlePrevious={() => history.push(`/scccPortal/taxes/view/${data._id}`)}
            />
          )}
          {step === 1 && (
            <StepTwo 
              data={data} 
              handleNextStep={handleNextStep} 
              handlePrevious={() => setStep(step -1)}
            />
          )}
          {step === 2 && (
            <StepThree 
              data={data} 
              handleNextStep={handleNextStep} 
              handleAlert={handleAlert} 
              handlePrevious={() => setStep(step -1)}
              editTax
            />
          )}
        </div>
      </Grid>
    );
  }
  return (
    <div style={{ position: 'absolute', top: '50%', right: '45%' }}>
      <PreLoading />
    </div>
  );
}

EditTaxSubmission.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(EditTaxSubmission);
