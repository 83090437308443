import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Successful from '../../../assets/icons/fireworks@3x.png';
import Button from '../../../materialUi/btn';

function Success({ data, history }) {

  const handleViewSubmission = () => {
    history.push('/scccPortal/taxes/submissions');
  };

  const pastYear = new Date().getFullYear() - 1;

  return (
    <Grid container className="registration-success-wrapper">
      <Grid item xs={2}>
        <img src={Successful} alt="logo" width="104px" height="120px" />
      </Grid>
      <Grid item>
        <h1 className="registration-success-title">
        Successful!
        </h1>
        <h3 className="taxes-formID">
          Form ID: {data.formID}
        </h3>
        <h2 className="registration-success-text">
          You have submitted your Personal Income Tax Form {pastYear}!
           A volunteer will review your submission and contact you
            if there are any further questions.
          <br />
          <br />
          You will need to wait for a
          {' '}
          <b>confirmation</b>
          , indicating your
           form is approved. You can check your application status on the portal.
        </h2>
        <div style={{ marginTop: '20px', marginBottom: '40px' }}>
          <Button
            label="View Status"
            type="button"
            onClick={handleViewSubmission}
            bgColor="#e76f50"
            color="white"
            bgColorHover="#ff8c68"
            colorHover="white"
            width={173}
            height={47}
          />
        </div>
        {/* <div className="registration-success-box">
          <div style={{ marginBottom: '16px', textTransform: 'capitalize' }}>
            Primary Member Name:
            {' '}

          </div>
          <div style={{ marginBottom: '16px' }}>
            Membership ID:
            {' '}

          </div>
          <div className="registration-success-box-text">
            Email address:
            {' '}
          </div>
        </div> */}
      </Grid>
    </Grid>
  );
}

Success.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  data: PropTypes.shape(
    {
      formID: PropTypes.string,
    },
  ).isRequired,
};

export default withRouter(Success);
