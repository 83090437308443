import axios from 'axios';
import Cookies from 'js-cookie';
import baseURL from './baseURL';
import TOKEN from './Frontend-Token';

const USER_INFO = Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : { id: 'null' };

export const taxReturnAPI = {
  POST: {
    addNewTaxReturn(taxReturn, userID) {
      return axios.post(`${baseURL}api/addNewTaxReturn/${userID}`, taxReturn, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    uploadDirectDepositForm(file, userID) {
      const data = new FormData();
      data.append('directDepositForm', file[0]);
      return axios.post(`${baseURL}api/uploadDirectDepositForm/${userID}`, data, {
        headers: { 'x-access-token': TOKEN, 'Content-Type': 'multipart/form-data' },
      });
    },
    uploadTaxFiles(files, userID) {
      const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append('taxReturnFiles', files[i]);
      }
      return axios.post(`${baseURL}api/uploadTaxFiles/${userID}`, data, {
        headers: { 'x-access-token': TOKEN, 'Content-Type': 'multipart/form-data' },
      });
    },
    uploadAdminTaxFiles(files, userID, permission) {
      const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append('taxReturnFiles', files[i]);
      }
      return axios.post(`${baseURL}api/uploadAdminTaxFiles/${userID}/${permission}`, data, {
        headers: { 'x-access-token': TOKEN, 'Content-Type': 'multipart/form-data' },
      });
    },
  },
  GET: {
    getTaxSubmissionById(id) {
      return axios.get(`${baseURL}api/admin/taxSubmission/${id}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    getCurrentTaxReturn(userID) {
      return axios.get(`${baseURL}api/getCurrentTaxReturn/${userID}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    getTaxReturnByUserID() {
      return axios.get(`${baseURL}api/getTaxReturnByUserID/${USER_INFO.id}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    getAllLastYearTaxReturn() {
      return axios.get(`${baseURL}api/getAllLastYearTaxReturn`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    getTaxReturnByFormID(formID) {
      return axios.get(`${baseURL}api/getTaxReturnByFormID/${formID}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
  },
  PUT: {
    updateOneTaxReturn(taxReturn) {
      return axios.put(`${baseURL}api/updateOneTaxReturn/${taxReturn.userID}`, taxReturn, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    adminUpdateOneTaxReturn(taxReturn) {
      return axios.put(`${baseURL}api/adminUpdateOneTaxReturn/${taxReturn._id}`, taxReturn, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    removeOneTaxAdminFile(taxReturn, fileName) {
      return axios.put(
        `${baseURL}api/removeOneTaxAdminFile/${taxReturn._id}`,
        {
          taxReturn,
          fileName,
        },
        {
          headers: { 'x-access-token': TOKEN },
        },
      );
    },
  },
};

export default taxReturnAPI;
