import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Header from './components/header';
import NewAttendanceTable from './components/newAttendanceTable';
import { ProgramAttendance } from '../../../API/API-programAttendance';
import { SubmittedAttendanceAPI } from '../../../API/API-submittedAttendanceList';
import { ProgramWeekScheduleAPI } from '../../../API/API-programWeekSchedule';
import { ProgramsAPI } from '../../../API/API-programs';
import Alert from '../../../materialUi/Alerts';
import '../../../css/addNewAttendance.css';

export default class AddNewAttendance extends Component {
  constructor(props) {
    super(props);
    const { match } = this.props;
    this.state = {
      programID: match.params.programID,
      programName: '',
      programStartTime: '',
      programExpirationDate: '',
      attendanceData: '',
      weeklySchedule: null,
      selectedWeek: 0,
      status: '',
      search: '',
      presentCouter: 0,
      absentCouter: 0,
      attendanceListID: Math.floor(100000 + Math.random() * 90000000),
      openAlert: false,
      alertLocation: '',
      alertTitle: '',
      alertBody: '',
      alertStay: '',
      alertClose: '',
      errorMessage: '',
      mousePopOver: false,
    };
  }

  componentDidMount() {
    const { programID } = this.state;
    const attendanceFromSession = JSON.parse(sessionStorage.getItem(`attendence_${programID}`));
    if (attendanceFromSession) {
      this.setState({
        attendanceData: attendanceFromSession,
        presentCouter: attendanceFromSession.present,
        absentCouter: attendanceFromSession.absent,
      });
    } else {
      // <--- get all programs on DB --->
      ProgramAttendance.GET.getProgramAttendanceByProgramID(programID)
        .then((success) => {
          this.setState({ attendanceData: success.data });
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // get program information
    ProgramsAPI.GET.getProgramByID(programID)
      .then((success) => {
        if (success.data.program[0].weeklyProgram) {
          // Weekly schedule API
          ProgramWeekScheduleAPI.GET.getProgramWeekScheduleByProgramID(programID)
            .then((response) => {
              this.setState({
                weeklySchedule: response.data,
              });
            }).catch((error) => {
              if (error.response) {
                this.setState({
                  openAlert: true,
                  alertLocation: '/adminPortal/programs',
                  alertTitle: error.response.data.errorTitle,
                  alertBody: error.response.data.errorMessage,
                  alertClose: 'CLOSE',
                });
              }
            });
        }
        this.setState({
          programName: success.data.program[0].name,
          programStartTime: this.assignProgramTime(success.data.program[0]),
          programExpirationDate: success.data.program[0].durationEndDate,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          openAlert: true,
          alertLocation: '/adminPortal/programs',
          alertTitle: 'Program not found!',
          alertBody: 'We could not find the program. Please try again later!',
          alertClose: 'hidden',
          alertStay: 'CLOSE',
        });
      });
  }

  assignProgramTime = (program) => {
    if (program.typeOfSchedule === 'variable') {
      return program.variableSchedule[0].startTime;
    }
    return program.programStartTime;
  };

  handleStatusClick = (status, index) => {
    const {
      attendanceData, presentCouter, absentCouter, programID, weeklySchedule, selectedWeek
    } = this.state;
    let present = 0;
    let absent = 0;
    const attendenceDataToUpdate = weeklySchedule ? weeklySchedule[selectedWeek] : attendanceData;
    // check the status of family member and count them
    if (status === 'Present' && attendenceDataToUpdate.familyMembers[index].status !== 'Present') {
      if (attendenceDataToUpdate.familyMembers[index].status === 'Absent') {
        present++;
        absent--;
      } else {
        present++;
      }
    } else if (status === 'Absent' && attendenceDataToUpdate.familyMembers[index].status !== 'Absent') {
      if (attendenceDataToUpdate.familyMembers[index].status === 'Present') {
        absent++;
        present--;
      } else {
        absent++;
      }
    }
    // add the status to the family member
    attendenceDataToUpdate.familyMembers[index].status = status;
    attendenceDataToUpdate.present = parseInt(presentCouter, 10) + present;
    attendenceDataToUpdate.absent = parseInt(absentCouter, 10) + absent;

    // add to the state
    this.setState({
      presentCouter: parseInt(presentCouter, 10) + present,
      absentCouter: parseInt(absentCouter, 10) + absent,
      attendanceData: attendenceDataToUpdate,
      errorMessage: '',
    });
    // save on session
    sessionStorage.setItem(`attendence_${programID}`, JSON.stringify(attendenceDataToUpdate));
  };

  handleSubmit = () => {
    const {
      attendanceData, attendanceListID, presentCouter, absentCouter, programID, programName, programStartTime, programExpirationDate, weeklySchedule, selectedWeek
    } = this.state;
    let checkStatus = true;
    const memberAttendanceToSaveOnDB = [];
    let memberAttendance;
    const attendenceDataToUpdate = weeklySchedule ? weeklySchedule[selectedWeek] : attendanceData;
    for (let i = 0; i < attendenceDataToUpdate.familyMembers.length; i++) {
      if (attendenceDataToUpdate.familyMembers[i].status === undefined || attendenceDataToUpdate.familyMembers[i].status === 'Not Taken') {
        this.setState({
          openAlert: true,
          alertTitle: 'Cannot Submit the Attendance List',
          alertBody: 'To successfully submit the attendance list you must to update all status!',
          alertStay: 'CLOSE',
          alertClose: 'hidden',
          errorMessage: '* Please update all status',
        });
        checkStatus = false;
      }
      memberAttendance = {
        attendanceListID,
        programID,
        membershipID: attendenceDataToUpdate.familyMembers[i].membershipID,
        programName,
        date: new Date(),
        expirationDate: programExpirationDate,
        startTime: programStartTime,
        status: attendenceDataToUpdate.familyMembers[i].status,
        familyMember: attendenceDataToUpdate.familyMembers[i]._id,
        name: `${attendenceDataToUpdate.familyMembers[i].firstName} ${attendenceDataToUpdate.familyMembers[i].lastName}`,
      };
      memberAttendanceToSaveOnDB.push(memberAttendance);
    }
    if (checkStatus) {
      const attendanceListToSave = {
        attendanceListID,
        programName,
        programID,
        date: new Date(),
        expirationDate: new Date(programExpirationDate),
        startTime: programStartTime,
        present: presentCouter,
        absent: absentCouter,
        memberAttendanceObj: memberAttendanceToSaveOnDB,
        familyMembers: attendenceDataToUpdate.familyMembers,
      };
      SubmittedAttendanceAPI.POST.addNewAttendanceList(attendanceListToSave)
        .then((success) => {
          sessionStorage.removeItem(`attendence_${programID}`);
          window.location = `/adminPortal/programs/submitedAttendances/${programID}`;
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            openAlert: true,
            alertLocation: '/adminPortal/programs',
            alertTitle: 'Server Error!',
            alertBody: 'Please try again later!',
            alertStay: 'CLOSE',
            alertClose: 'hidden',
          });
        });
    }
  };

  render() {
    const {
      programID, programName, presentCouter, absentCouter, openAlert, alertLocation, alertTitle, alertBody, alertStay, alertClose, attendanceData, search, status, errorMessage, weeklySchedule,
      selectedWeek, mousePopOver,
    } = this.state;
    const { history } = this.props;
    return (
      <Grid item xs className="font-family-default">
        <Header
          handleBack={() => history.push(`/adminPortal/programs/submitedAttendances/${programID}`)}
          handleReset={() => this.setState({ search: '' })}
          programName={programName}
          presentCouter={presentCouter}
          absentCouter={absentCouter}
          handleSubmit={this.handleSubmit}
          search={(value) => this.setState({ search: value })}
          weeklySchedule={weeklySchedule}
          selectedWeek={selectedWeek}
          handleSelectedWeek={(index) => this.setState({ selectedWeek: index })}
        />
        <Grid item xs={12} id={errorMessage === '' && 'hidden'}>
          <h2 className="addNewAttendance-errorMessage addNewAttendance-spacing">{errorMessage}</h2>
        </Grid>
        <Alert open={openAlert} close={() => () => this.setState({ openAlert: false })} location={alertLocation} title={alertTitle} body={alertBody} stay={alertStay} leave={alertClose} />
        <NewAttendanceTable data={weeklySchedule ? weeklySchedule[selectedWeek] : attendanceData} search={search} status={status} statusClick={this.handleStatusClick} />
      </Grid>
    );
  }
}

AddNewAttendance.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programID: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
