import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { TIMETOAMPM } from '../../../../utils/dateController';
import '../../../../css/programsList.css';


function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

// collums titles
const headRows = [
  {
    id: 'program', numeric: false, disablePadding: true, label: 'Program Name',
  },
  {
    id: 'ages', numeric: false, disablePadding: true, label: 'Ages',
  },
  {
    id: 'days', numeric: false, disablePadding: false, label: 'Days',
  },
  {
    id: 'time', numeric: false, disablePadding: false, label: 'Time',
  },
  {
    id: 'duration', numeric: false, disablePadding: false, label: 'Duration',
  },
  {
    id: 'spotsLeft', numeric: true, disablePadding: false, label: 'Spots Left',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="bg-light">
      <TableRow>
        {headRows.map((row) => (
          <TableCell
            key={row.id}
            align={row.numeric ? 'center' : 'left'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
            className="table-cell"
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
              tabIndex={-1}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));


export default function ProgramsTable({
  data,
  handleProgramSelection,
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage] = React.useState(5);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }


  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={2}>
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const days = row.days && row.days.map((i) => i.substring(0, 3)).toString().replace(/,/g, '-');
                  const time = row.weeklyProgram ? `${TIMETOAMPM(row.weeklyStartTime)} - ${TIMETOAMPM(row.weeklyEndTime)}` : 
                  row.variableSchedule.length > 0 ? 'Variable' : `${TIMETOAMPM(row.programStartTime)} - ${TIMETOAMPM(row.programEndTime)}`;
                  return (
                    <TableRow
                      hover
                      onClick={() => handleProgramSelection(
                        row.programID,
                      )}
                      role="button"
                      tabIndex={0}
                      onKeyPress={() => handleProgramSelection(
                        row.programID,
                      )}
                      key={index}
                      className="table-tabSelection"
                    >
                      <TableCell component="th" id={data.programID} scope="row" padding="none" className="table-cell">
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.ages}</TableCell>
                      <TableCell align="left">{row.season}</TableCell>
                      <TableCell align="left">{days}</TableCell>
                      <TableCell align="left">{time}</TableCell>
                      <TableCell align="center" style={{ width: '200px', paddingRight: '60px', paddingLeft: '60px' }}><div className={(data.numberOfSpots < 1) ? 'dashboard-thereISNoSpotsLeft' : 'dashboard-hasSpotsLeft'}>{row.numberOfSpots}</div></TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }} />
              )}
            </TableBody>
          </Table>
        </div>
      </Paper>

    </div>
  );
}


ProgramsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleProgramSelection: PropTypes.func.isRequired,
};
