import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Box, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { EmailAPI } from '../../../../API/API-Email';
import { TIME } from '../../../../utils/dateController';
import TextField from '../../../../materialUi/textField';
import '../../../../css/receipts.css';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class ReceiptAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailMessage: '',
      emailError: '',
    };
  }

  handleEmail = (value) => {
    let message = '';
    let error = '';
    if (value.target.value.length > 0) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.target.value)) {
        message = 'Not a valid email';
        error = true;
      } else {
        message = '';
        error = false;
      }
    } else {
      message = 'Email cannot be empty';
      error = true;
    }
    this.setState({
      email: value.target.value,
      emailMessage: message,
      emailError: error,
    });
  }


  handleSendEmail = () => {
    const { receiptData, close } = this.props;
    const { email, emailError, emailMessage } = this.state;
    let street; let city; let province; let
      postalCode;
    if (receiptData.address) {
      street = receiptData.address.street;
      city = receiptData.address.city;
      province = receiptData.address.province;
      postalCode = receiptData.address.postalCode;
    }
    const address = `${street} ${city} ${province} ${postalCode}`;
    const receiptObj = {
      referenceNumber: receiptData.id,
      name: receiptData.name,
      cardType: receiptData.cardType,
      category: receiptData.category,
      paymentMethod: receiptData.paymentMethod,
      address,
      date: TIME(receiptData.paymentDateISO, true),
      fee: receiptData.amount,
    };
    if (emailError === '' || emailError === true) {
      this.setState({
        emailMessage: (emailMessage === '') ? 'Required Field' : emailMessage,
        emailError: true,
      });
    } else {
      EmailAPI.EMAIL.emailReceipt(email, receiptObj).then((success) => {
        console.log(success);
        close();
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  handlePrint = () => {
    const printContent = document.getElementById('receipt').innerHTML;
    document.body.innerHTML = printContent;
    window.print();
    window.location = '/adminPortal/reports';
  };

  render() {
    const { open, close } = this.props;
    const { email, emailMessage, emailError } = this.state;
    return (
      <>
        <Dialog
          onClose={() => close()}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth="sm"
          fullWidth
          className="font-family-default"
        >
          <DialogContent dividers>
            <Grid container style={{ marginBottom: '15px' }}>
              <Grid item xs className="receipt-alert-title">
                 Send Email Receipt
              </Grid>
              <Grid>
                <Box onClick={() => close()} role="button"><i className="fas fa-times close-icon" /></Box>
              </Grid>
            </Grid>
            <TextField
              id="email"
              label="Email"
              name="Email"
              value={email}
              handleChanges={(value) => this.handleEmail(value)}
              helperText={emailMessage}
              readOnly={false}
              error={emailError}
            />

          </DialogContent>

          <DialogActions>
            <Button className="alert-btns" onClick={this.handleSendEmail}>
              Send
            </Button>
          </DialogActions>
        </Dialog>
      </>

    );
  }
}

ReceiptAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  receiptData: PropTypes.shape({
    id: PropTypes.number,
    address: PropTypes.objectOf(PropTypes.string),
    paymentMethod: PropTypes.string,
    name: PropTypes.string,
    cardType: PropTypes.string,
    paymentDateISO: PropTypes.string,
    category: PropTypes.string,
    amount: PropTypes.number,
  }).isRequired,
  close: PropTypes.func.isRequired,
};


export default ReceiptAlert;
