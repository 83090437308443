import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export default function SimpleTabs({ tabItems, value, handleChange, minWidth }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      '& .MuiPaper-root': {
        backgroundColor: 'transparent',
        color: '#C7C7C7',
        boxShadow: 'none',
      },
      '& .Mui-selected': {
        color: theme.palette.primary.main,
        fontFamily: 'Work Sans, sans-serif',
      },
      '& .MuiTab-root': {
        fontFamily: 'Work Sans, sans-serif',
        minWidth,
      },
      '& .MuiTabs-indicator': {
        background: theme.palette.primary.main,
        height: '3px',
      },
      '& .MuiTab-wrapper': {
        textTransform: 'none',
      },
    },
  }));
  const classes = useStyles();

  const handleTab = (event, newValue) => {
    handleChange(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleTab} aria-label="Tab Panel">
          {tabItems.map((option, index) => (
            <Tab label={option} key={index} />
          ))}
        </Tabs>
      </AppBar>
    </div>
  );
}


SimpleTabs.propTypes = {
  tabItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  minWidth: PropTypes.string,
};

SimpleTabs.defaultProps = {
  minWidth: '160px',
};
