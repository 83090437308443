/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import StepButtons from '../../../../materialUi/stepButtons';
import SearchMembers from './components/searchMembers';
import ProgramParticipant from './components/programParticipant';
import CaregiverInformation from './components/caregiverInformation';
import Schedule from './components/schedule';
import '../../../../css/programRegistration.css';

function RegistrationForm({
  data, handleNextStep, userData, programData
}) {
  const useFormMethods = useForm({ mode: 'all' });

  const {
    handleSubmit,
    getValues,
    setValue,
  } = useFormMethods;

  const handleFormSubmit = (formData) => {
    handleNextStep(formData);
  };

  React.useEffect(() => {
    if (data.membership) {
      setValue('membership', data.membership);
    }
    if (data.familyMembers) {
      setValue('familyMembers', data.familyMembers);
    }
    if (data.caregiverName) {
      setValue('caregiverName', data.caregiverName);
    }
    if (data.caregiverHomePhone) {
      setValue('caregiverHomePhone', data.caregiverHomePhone);
    }
    if (data.caregiverRelationship) {
      setValue('caregiverRelationship', data.caregiverRelationship);
    }


  }, []);

  return (
    <FormProvider {...useFormMethods}>
      <form onSubmit={handleSubmit(handleFormSubmit)} className="programRegistration-wrapper">
        <SearchMembers
          data={data}
          userData={userData}
          programData={programData}
          {...useFormMethods}
        />
        {getValues('membership') && (
          <ProgramParticipant
            data={data}
            programData={programData}
            userData={userData}
            {...useFormMethods}
          />
        )}
        {/*  */}
        {programData.weeklyProgram && getValues('familyMembers') && (
          <Schedule
            data={data}
            programData={programData}
            userData={userData}
            {...useFormMethods}
          />
        )}
        <CaregiverInformation
          data={data}
          programData={programData}
          {...useFormMethods}
        />

        {/* Action Buttons */}
        <hr className="tax_custom_hr" />
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
          <StepButtons
            location="/adminPortal/programs/available"
            handleSubmit={() => { }}
            type="submit"
          />
        </Grid>
      </form>
    </FormProvider>
  );
}

RegistrationForm.propTypes = {
  handleNextStep: PropTypes.func.isRequired,
  data: PropTypes.shape(
    {
      name: PropTypes.string,
    },
  ).isRequired,
  programData: PropTypes.shape(
    {
      membership: PropTypes.string,
      weeklyProgram: PropTypes.bool,
    },
  ).isRequired,
  userData: PropTypes.shape({
    userData: PropTypes.shape({
      userType: PropTypes.string,
    }),
  }).isRequired,
};

export default RegistrationForm;
