import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Successful from '../../../../assets/icons/fireworks@3x.png';
import Button from '../../../../materialUi/btn';
import Receipt from './receipt';
import ConfirmationEmail from '../../../../materialUi/programRegistrationEmail';

function Success({ data, history, userData }) {
  const { userType } = userData.userData;

  const handleDone = () => {
    sessionStorage.clear();
    // TODO: Needs to be removed after we merge the adminPortal with scccPortal
    const typeOfPortal = () => {
      if (userType.toLowerCase() !== 'member' && userType.toLowerCase() !== 'public') {
        return 'adminPortal';
      }
      return 'scccPortal';
    };
    window.location = `/${typeOfPortal()}/programs/available/`;
  };

  if (Object.keys(data).length === 0) return (<></>)

  return (
    <Grid container className="registration-success-wrapper">
      <Grid item xs={12} md={2}>
        <img src={Successful} alt="logo" width="104px" height="120px" />
      </Grid>
      <Grid item>
        <h1 className="registration-success-title">
          Successful!
        </h1>
        <h2 className="registration-success-text">
          Program Registration completed!
        </h2>
        {data && !data.freeProgram && (
          <Receipt data={data.receipt} />
        )}
        <div>
          {data && (
            <>
              <h3 className="registration-success-sub-text">Please, add your email to receive the email confirmation</h3>
              <ConfirmationEmail
                email={data.membership.email}
                name={`${data.membership.familyMembersInformation[0].firstName} ${data.membership.familyMembersInformation[0].lastName}`}
                programName={data.program.name}
              />
            </>
          )}
        </div>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          style={{ paddingTop: '25px' }}
        >
          <Button
            label="DONE"
            type="button"
            bgColor="#e76f50"
            color="white"
            bgColorHover="#ff8c68"
            colorHover="white"
            width={120}
            height={47}
            onClick={() => handleDone()}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withRouter(Success);
