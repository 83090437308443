import React from 'react';
import PropTypes from 'prop-types';
import '../../../../css/registrationList.css';

function createData(membershipID, firstName, lastName, phone, id) {
  return {
    membershipID, firstName, lastName, phone, id,
  };
}

export default function AttendeesExportTable({
  attendanceData,
  sortSearch,
}) {
  const rows = [];
  if (attendanceData) {
    for (let i = 0; i < attendanceData.familyMembers.length; i++) {
      const phone = (attendanceData.familyMembers[i].phone) ? attendanceData.familyMembers[i].phone : 'N/A';

      if (sortSearch) {
        if (attendanceData.familyMembers[i].firstName.toLowerCase()
          .includes(sortSearch.toLowerCase())
          || attendanceData.familyMembers[i].lastName.toLowerCase()
            .includes(sortSearch.toLowerCase())
          || attendanceData.familyMembers[i].membershipID.includes(sortSearch)
        ) {
          rows.push(
            createData(
              attendanceData.familyMembers[i].membershipID,
              attendanceData.familyMembers[i].firstName,
              attendanceData.familyMembers[i].lastName,
              phone,
              attendanceData.familyMembers[i]._id,
            ),
          );
        }
      } else {
        rows.push(
          createData(
            attendanceData.familyMembers[i].membershipID,
            attendanceData.familyMembers[i].firstName,
            attendanceData.familyMembers[i].lastName,
            phone,
            attendanceData.familyMembers[i]._id,
          ),
        );
      }
    }
  }
  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Membership ID</th>
            <th scope="col">First Name</th>
            <th scope="col">Last Name</th>
            <th scope="col">Phone</th>
          </tr>
        </thead>
        <tbody>

          {rows.map((value, index) => (
            <tr key={index}>
                <td>
                  {value.membershipID}
                </td>
                <td>
                  {value.firstName}
                </td>
                <td>
                  {value.lastName}
                </td>
                <td>
                  {value.phone}
                </td>
              </tr>
          ))}

        </tbody>
      </table>

    </div>
  );
}

AttendeesExportTable.propTypes = {
  sortSearch: PropTypes.string.isRequired,
  attendanceData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      familyMembers: PropTypes.arrayOf(PropTypes.shape({
        phone: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        membershipID: PropTypes.string,
      })),
    }),
  ]).isRequired,
};
