import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import gymImg from '../../../../assets/gym-1.4.png';
import '../reservationSession.css';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';

export default function ReservationCard({props}) {
    return (
        <Grid xs={12} className='reservation__card'>
            <Grid container>
                <Grid xs={12} className='reservation__card-title'>
                    Weight Room
                    <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        className='reservation__card-more-opt'
                    >
                        <MoreVertIcon />
                    </IconButton>

                </Grid>
                <Grid xs={12} className='reservation__card-description'>
                    Max Capacity: 20
                </Grid>
                <Grid xs={12} className='reservation__card-thumbnail'>
                    <img src={gymImg} alt='' />
                </Grid>
            </Grid>
        </Grid>
    );
};