/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Grid, FormControl, FormGroup, FormControlLabel, Checkbox, Radio, Box,
} from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import TextField from '../../../materialUi/textField';
import PDFIcon from '../../../assets/icons/pdf-icon.svg';
import PPTIcon from '../../../assets/icons/pptIcon.png';
import imageIcon from '../../../assets/icons/imageIcon.png';
import docIcon from '../../../assets/icons/docIcon.png';
import BackButton from '../../../materialUi/backButton';
import Button from '../../../materialUi/btn';
import { taxReturnAPI } from '../../../API/API-taxReturn';
import Loading from '../../../materialUi/loading';

function ViewUserSubmission({ history, match }) {
  const { formID } = match.params;
  const renderDate = function (isoTime) {
    const timeStr = isoTime;
    const date = new Date(timeStr);
    let day = date.getDate();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    if (day < 10) {
      day = `0${day}`;
    }
    const dateStr = `${day}-${months[month - 1]}-${year}`;
    return dateStr;
  };

  const pastYear = new Date().getFullYear() - 1;

  const renderIdentification = (id) => {
    if (id === 'landingPaper') {
      return 'Landing Paper';
    } if (id === 'driverLicence') {
      return 'Driver Licence';
    } if (id === 'passport') {
      return 'Passport';
    } if (id === 'ontarioPhotoCard') {
      return 'Ontario Photo Card';
    } if (id === 'PR') {
      return 'Permanent Resident (PR) Card';
    } if (id === 'refugee') {
      return 'Refugee Claimant Document';
    }
    return id;
  };

  const renderIncomeSlip = (name) => {
    if (name === 'T4') {
      return 'Salary and Wages [T4]';
    } if (name === 'otherIncome') {
      return 'Other Income [T4A e.g. CREB, CRB, etc.]';
    } if (name === 'T4A(P)') {
      return 'Pension & Retirement [T4A(P)]';
    } if (name === 'T4A(OAS)') {
      return 'Old Age Security Benefits [T4A(OAS)]';
    } if (name === 'T4E') {
      return 'Employment Insurance [T4E]';
    } if (name === 'T5') {
      return 'Interest & Dividends [T5]';
    } if (name === 'T3') {
      return 'Statement of Trust Income [T3]';
    } if (name === 'T5007') {
      return 'Government Assistance/ Social Assistance [T5007]';
    } if (name === 'RC62') {
      return 'Universal Child Care Benefit Statement [RC62]';
    } if (name === 'selfEmployed') {
      return 'Self-employed business income';
    } if (name === 'noIncome') {
      return `No income to report for ${pastYear}`;
    }
    return name;
  };

  const renderBenefits = (name) => {
    if (name === 'CERB') {
      return 'Canada Emergency Response Benefit (CERB)';
    } if (name === 'CESB') {
      return 'Canada Emergency Student Benefit (CESB)';
    } if (name === 'CRB') {
      return 'Canada Recovery Benefit (CRB)';
    } if (name === 'CRCB') {
      return 'Canada Recovery Caregiving Benefit (CRCB)';
    } if (name === 'CRSB') {
      return 'Canada Recovery Sickness Benefit (CRSB)';
    }
  };

  const renderFilledTaxForm = (filledTaxForm) => {
    if (filledTaxForm === 'eFile') {
      return 'E-file';
    }
    return 'Provide me a hard copy';
  };

  const renderIconBasedOnDocType = (item) => {
    if (item.type === 'application/msword' || item.fileType === '.doc' || item.fileType === '.docx') {
      return docIcon;
    } if (item.type === 'application/pdf' || item.fileType === '.pdf') {
      return PDFIcon;
    } if (item.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || item.fileType === '.pptx') {
      return PPTIcon;
    }
    return imageIcon;
  };

  const [uploadedFiles, setUploadedFiles] = React.useState(false);
  const [data, setData] = React.useState(false);

  const getAllReturnFiles = () => {
    taxReturnAPI.GET.getTaxReturnByFormID(formID).then((response) => {
      setData(response.data[0]);
      if (response.data[0].submissionMethod) {
        if (response.data[0].submissionMethod === 'upload') {
          let uploadedFilesCopy = response.data[0].taxReturnFiles;
          if (response.data[0].directDepositForm) {
            uploadedFilesCopy = response.data[0].taxReturnFiles.concat(response.data[0].directDepositForm);
          }
          setUploadedFiles(uploadedFilesCopy);
        } else if (response.data[0].directDepositForm && response.data[0].directDepositForm.length > 0) {
          setUploadedFiles(response.data[0].directDepositForm);
        }
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  React.useEffect(() => {
    getAllReturnFiles();
  }, []);


  if (!data) return (
    <Grid
    container
    direction="row"
    justifyContent="center"
    alignItems="center"
  >
    <Loading/>
  </Grid>
  )


  return (
    <Grid container className="questionnaire-wrapper">
      <Grid item xs={12}>
        <BackButton
          handleClick={( )=> history.push('/scccPortal/taxes/submissions')}
          label="Back to submission list"
        />
      </Grid>
      <Grid item>
        <h1 className="taxes-title mt-4 mb-2">
          Tax Questionnaire #
          {data.formID}
        </h1>
      </Grid>
      <Grid item xs style={{paddingTop: '10px'}}>
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
          <Button
            label="Edit"
            bgColor="#e76f50"
            color="white"
            colorHover="white"
            bgColorHover="#ff8c68"
            width={(window.innerWidth < 600) ? 100 : 128}
            onClick={() => history.push(`/scccPortal/taxes/edit/${data._id}`)}
            type="submit"
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">
          1. What is your name?
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          id="name"
          type="search"
          name="name"
          label="Your Name"
          width="100%"
          readOnly
          value={data.fullName}
        />
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">
          2. Please provide a source of contact to reach you.
        </div>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            id="consentSign"
            type="search"
            name="consentSign"
            label="Email"
            width="100%"
            value={data.email}
            readOnly
          />
        </Grid>
        <Grid item xs={12} md>
          <TextField
            id="homePhone"
            type="search"
            name="homePhone"
            label="Home Phone"
            width="100%"
            value={data.homePhone}
            readOnly
          />
        </Grid>
        {data.workPhone && (
        <Grid item xs={12} md>
          <TextField
            id="workPhone"
            type="search"
            name="workPhone"
            label="Work Phone"
            width="100%"
            value={data.workPhone}
            readOnly
          />
        </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">
          3. What is your status in Canada?
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          id="status"
          type="search"
          name="status"
          label="Status"
          width="100%"
          readOnly
          value={data.canadaStatus}
        />
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">
          4. What is your preferred language of correspondence and tax return?
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-custom-radio-label">
          <Radio
            checked={data.language}
            title="language"
            value={data.language}
            name="language"
            icon={<RadioButtonUncheckedIcon fontSize="medium" />}
            checkedIcon={<RadioButtonCheckedIcon fontSize="medium" />}
          />
          {data.language}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">
          5. What is your province of residence (until December 31, {pastYear})?
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          id="province"
          type="search"
          name="province"
          label="Province"
          width="100%"
          readOnly
          value={data.province}
        />
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">
          6. Please state your marital status as recorded until December 31, {pastYear}.
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-custom-radio-label">
          <Radio
            checked={data.maritalStatus}
            title="maritialStatus"
            value={data.maritalStatus}
            name="maritialStatus"
            icon={<RadioButtonUncheckedIcon fontSize="medium" />}
            checkedIcon={<RadioButtonCheckedIcon fontSize="medium" />}
          />
          {data.maritalStatus}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">
          6a. Did your marital status change in {pastYear} (including separated for less than 90 days)?
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-custom-radio-label">
          <Radio
            checked={data.maritalChange}
            title="maritalChange"
            value={data.maritalChange}
            name="maritalChange"
            icon={<RadioButtonUncheckedIcon fontSize="medium" />}
            checkedIcon={<RadioButtonCheckedIcon fontSize="medium" />}
          />
          {data.maritalChange}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">
          6b.	Are you the head of the family?
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-custom-radio-label">
          <Radio
            checked={data.familyHead}
            title="familyHead"
            value={data.familyHead}
            name="familyHead"
            icon={<RadioButtonUncheckedIcon fontSize="medium" />}
            checkedIcon={<RadioButtonCheckedIcon fontSize="medium" />}
          />
          {data.familyHead}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">
          6c. Do you have any dependants (Children)?
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-custom-radio-label">
          <Radio
            checked={data.anyDependents}
            title="anyDependents"
            value={data.anyDependents}
            name="anyDependents"
            icon={<RadioButtonUncheckedIcon fontSize="medium" />}
            checkedIcon={<RadioButtonCheckedIcon fontSize="medium" />}
          />
          {data.anyDependents}
        </div>
      </Grid>
      {data.anyDependents === 'yes' && (
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            id="dependantsAboveAge"
            type="search"
            name="dependantsAboveAge"
            label="Dependant Above Age"
            width="100%"
            readOnly
            value={data.dependantsAboveAge}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="dependantsBelowAge"
            type="search"
            name="dependantsBelowAge"
            label="Dependant Below Age"
            width="100%"
            readOnly
            value={data.dependantsBelowAge}
          />
        </Grid>
      </Grid>
      )}
      <Grid item xs={12}>
        <div className="taxes-question">
          7. Is this your first year to file for taxes in Canada?
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-custom-radio-label">
          <Radio
            checked={data.firstTimeTax}
            title="firstTimeTax"
            value={data.firstTimeTax}
            name="firstTimeTax"
            icon={<RadioButtonUncheckedIcon fontSize="medium" />}
            checkedIcon={<RadioButtonCheckedIcon fontSize="medium" />}
          />
          {data.firstTimeTax}
        </div>
      </Grid>
      {data.firstTimeTax === 'yes' && (
      <Grid item xs={12}>
        <div className="taxes-custom-radio-label">
          <Radio
            checked={data.firstTimeTaxPrint}
            title="firstTimeTaxPrint"
            value={data.firstTimeTaxPrint}
            name="firstTimeTaxPrint"
            icon={<RadioButtonUncheckedIcon fontSize="medium" />}
            checkedIcon={<RadioButtonCheckedIcon fontSize="medium" />}
          />
          {(data.firstTimeTaxPrint === 'email')
            ? 'Email me a PDF copy to print myself'
            : 'Pick up form at Scadding Court Community Center'}
        </div>
      </Grid>
      )}
      <Grid item xs={12}>
        <div className="taxes-question">
          8. Were you residing in Canada in {pastYear}?
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-custom-radio-label">
          <Radio
            checked={data.residing}
            title="residing"
            value={data.residing}
            name="residing"
            icon={<RadioButtonUncheckedIcon fontSize="medium" />}
            checkedIcon={<RadioButtonCheckedIcon fontSize="medium" />}
          />
          {data.residing}
        </div>
      </Grid>
      {data.residing === 'no' && (
      <Grid item xs={12} md={6}>
        <TextField
          id="incomeOutsideCanada"
          type="search"
          name="incomeOutsideCanada"
          label="Income Outside Canada"
          width="100%"
          readOnly
          value={data.incomeOutsideCanada}
        />
      </Grid>
      )}
      <Grid item xs={12}>
        <div className="taxes-question">
          8b. Did you own foreign property in {pastYear} with a total cost over CAN$100,000?
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-custom-radio-label">
          <Radio
            checked={data.foreignProperty}
            title="foreignProperty"
            value={data.foreignProperty}
            name="foreignProperty"
            icon={<RadioButtonUncheckedIcon fontSize="medium" />}
            checkedIcon={<RadioButtonCheckedIcon fontSize="medium" />}
          />
          {data.foreignProperty}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">
          9. From January 1st, {pastYear} until December 31st, {pastYear} (Tax year), have you lived in the same home address?
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-custom-radio-label">
          <Radio
            checked={data.sameHomeAddress}
            title="sameHomeAddress"
            value={data.sameHomeAddress}
            name="sameHomeAddress"
            icon={<RadioButtonUncheckedIcon fontSize="medium" />}
            checkedIcon={<RadioButtonCheckedIcon fontSize="medium" />}
          />
          {data.sameHomeAddress}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">
        9a. If yes, please provide the complete address.
        </div>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            id="currentAddress"
            type="search"
            name="currentAddress"
            label="Current Address"
            width="100%"
            readOnly
            value={`${data.currentAddress.street}, ${data.currentAddress.city}, ${data.currentAddress.province} `}
          />
        </Grid>
        <Grid item xs={6} md>
          <TextField
            id="apt#"
            type="apt#"
            name="status"
            label="Apt #"
            width="100%"
            readOnly
            value={data.currentAddress.apt}
          />
        </Grid>
        <Grid item xs={6} md>
          <TextField
            id="postalCode"
            type="postalCode"
            name="status"
            label="Postal Code"
            width="100%"
            readOnly
            value={data.currentAddress.postalCode}
          />
        </Grid>
      </Grid>
      {data.extraAddress && data.extraAddress.length > 0 && (
        <>
          <Grid item xs={12}>
            <div className="taxes-question">
          9b. From January 1st, {pastYear} until December 31st, {pastYear},
          if you have lived in more than one address, please provide
            them all and the periods of stay in each.
            </div>
          </Grid>
          {data.extraAddress.map((item) => (
            <Grid container spacing={2} key={item.street}>
              <Grid item xs={12} md={6}>
                <TextField
                  id="currentAddress"
                  type="search"
                  name="currentAddress"
                  label="Current Address"
                  width="100%"
                  readOnly
                  value={`${item.street}, ${item.city}, ${item.province} `}
                />
              </Grid>
              <Grid item xs={6} md>
                <TextField
                  id="apt#"
                  type="search"
                  name="status"
                  label="Apt #"
                  width="100%"
                  readOnly
                  value={item.apt}
                />
              </Grid>
              <Grid item xs={6} md>
                <TextField
                  id="postalCode"
                  type="search"
                  name="status"
                  label="Postal Code"
                  width="100%"
                  readOnly
                  value={item.postalCode}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="startDate"
                  type="search"
                  name="startDate"
                  label="Start Date"
                  width="100%"
                  readOnly
                  value={renderDate(item.startDate)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="endDate"
                  type="search"
                  name="endDate"
                  label="End Date"
                  width="100%"
                  readOnly
                  value={renderDate(item.endDate)}
                />
              </Grid>
            </Grid>
          ))}
        </>
      )}
      <Grid item xs={12}>
        <div className="taxes-question">
          10. Are you an officer or a servant of another country?
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-custom-radio-label">
          <Radio
            checked={data.isAnOfficer}
            title="isAnOfficer"
            value={data.isAnOfficer}
            name="isAnOfficer"
            icon={<RadioButtonUncheckedIcon fontSize="medium" />}
            checkedIcon={<RadioButtonCheckedIcon fontSize="medium" />}
          />
          {data.isAnOfficer}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">
          11. Did you work in Canada in {pastYear}?
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-custom-radio-label">
          <Radio
            checked={data.workInCanada}
            title="workInCanada"
            value={data.workInCanada}
            name="workInCanada"
            icon={<RadioButtonUncheckedIcon fontSize="medium" />}
            checkedIcon={<RadioButtonCheckedIcon fontSize="medium" />}
          />
          {data.workInCanada}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">
          11a. Did you work for different employers in {pastYear}?
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-custom-radio-label">
          <Radio
            checked={data.differentEmployers}
            title="differentEmployers"
            value={data.differentEmployers}
            name="differentEmployers"
            icon={<RadioButtonUncheckedIcon fontSize="medium" />}
            checkedIcon={<RadioButtonCheckedIcon fontSize="medium" />}
          />
          {data.differentEmployers}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">
          12. Have you received any of the COVID-19 emergency or recovery benefits
           from the CRA before December 31, {pastYear}? (T4A slip(s))
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-custom-radio-label">
          <Radio
            checked={data.receivedBenefits}
            title="receivedBenefits"
            value={data.receivedBenefits}
            name="receivedBenefits"
            icon={<RadioButtonUncheckedIcon fontSize="medium" />}
            checkedIcon={<RadioButtonCheckedIcon fontSize="medium" />}
          />
          {data.receivedBenefits}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">
          12a. Have you applied to Employment Insurance (EI) and received any COVID benefits from Service Canada (T4E slip)?
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-custom-radio-label">
          <Radio
            checked={data.appliedForBenefits}
            title="appliedForBenefits"
            value={data.appliedForBenefits}
            name="appliedForBenefits"
            icon={<RadioButtonUncheckedIcon fontSize="medium" />}
            checkedIcon={<RadioButtonCheckedIcon fontSize="medium" />}
          />
          {data.appliedForBenefits}
        </div>
      </Grid>
      {data.receivedBenefits === 'yes' && (
        <>
          <Grid item xs={12}>
            <div className="taxes-question">
              12b. Canada Benefits:
            </div>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              component="fieldset"
            >
              <FormGroup>
                {
              data.benefits.map((item) => (
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={item}
                      name={item}
                    />
                    )}
                  label={renderBenefits(item)}
                />
              ))
            }
              </FormGroup>
            </FormControl>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <div className="taxes-question">
          13. Please specify which government issued ID (Identifying Document)
           you will use to file your tax:
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-custom-radio-label">
          <Radio
            checked={data.identification}
            title="receivedBenefits"
            value={data.identification}
            name="receivedBenefits"
            icon={<RadioButtonUncheckedIcon fontSize="medium" />}
            checkedIcon={<RadioButtonCheckedIcon fontSize="medium" />}
          />
          {renderIdentification(data.identification)}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">
          14. Please state all slips of income that you have received:
        </div>
      </Grid>
      <Grid item xs={12}>
        <FormControl
          component="fieldset"
        >
          <FormGroup>
            {
              data.incomeSlip.map((item) => (
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={item}
                      name={item}
                    />
                    )}
                  label={renderIncomeSlip(item)}
                />
              ))
            }
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">
          15. Do you have all rent receipts of the year {pastYear}?
        </div>
        <h5 className="taxes-sub-title">
        *For those who reside in a building, please ask the management
         office to provide you with a letter of your rent payments.
        </h5>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">
        16. Please state through which method you will be
         submitting documents in order to file for your tax return.
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-custom-radio-wrapper">
          <Radio
            checked={data.submissionMethod}
            value=""
            title="dads"
            className="YesNo"
            name="documentsByEmail"
            icon={<RadioButtonUncheckedIcon fontSize="medium" />}
            checkedIcon={<RadioButtonCheckedIcon fontSize="medium" />}
          />
          <span>
            {data.submissionMethod === 'upload' ? (
              '  Upload Documents (i.e. Photos, PDF)'
            ) : data.submissionMethod === 'upload' ? (
              'E-mail'
            ) : 'In-person drop off'}
            <span className="radio-sub-label">
              {data.submissionMethod === 'upload' ? (
                '*Please make sure you have a smart device to take photos of your ID and documents.'
              ) : data.submissionMethod === 'upload' ? (
                '*Note: you will receive an email requesting you to provide the documents.'
              ) : '(at Scadding Court Community Centre, 707 Dundas Street W)'}
            </span>
          </span>
        </div>
      </Grid>
      {data.bankInformation && (
        <>
          <Grid item xs={12}>
            <div className="taxes-question">
                17. Direct deposit information
            </div>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs>
              <TextField
                id="transitNumber"
                type="search"
                name="transitNumber"
                label="Transit Number"
                width="100%"
                readOnly
                value={data.bankInformation.transitNumber}
              />
            </Grid>
            <Grid item xs>
              <TextField
                id="institutionNumber"
                type="search"
                name="institutionNumber"
                label="Institutio Number"
                width="100%"
                readOnly
                value={data.bankInformation.institutionNumber}
              />
            </Grid>
            <Grid item xs>
              <TextField
                id="accountNumber"
                type="search"
                name="accountNumber"
                label="Account Number"
                width="100%"
                readOnly
                value={data.bankInformation.accountNumber}
              />
            </Grid>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <div className="taxes-question">
        18. Please read the following carefully to provide
         your consent to having a volunteer file your tax returns.
        </div>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          className="taxes-checkboxes-wrapper"
        >
          <Box>
            <Checkbox
              inputProps={{ 'aria-label': 'primary checkbox' }}
              checked={data.consentCheck}
            />
          </Box>
          <Box className="taxes-checkbox-label">
            I am fully aware that my income tax and benefit return
             is being prepared by a volunteer under the Community Volunteer
              Income Tax Program and that this volunteer is not
               acting as an agent of the Canada Revenue Agency.
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} className="taxes-consent-textFields">
        <TextField
          id="consentSign"
          type="search"
          name="consentSign"
          label="Your Name"
          width="100%"
          readOnly
          value={data.consentSign}
        />
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">
        19. Please select how you would like your taxes filed.
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-custom-radio-label">
          <Radio
            checked={data.filledTaxOption}
            title="receivedBenefits"
            value={data.filledTaxOption}
            name="receivedBenefits"
            icon={<RadioButtonUncheckedIcon fontSize="medium" />}
            checkedIcon={<RadioButtonCheckedIcon fontSize="medium" />}
          />
          {renderFilledTaxForm(data.filledTaxOption)}
        </div>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: '40px' }}>
        <Grid container className="taxes-uploads-box">
          <Grid item xs={12} className="taxes-uploads-box-title">
            Uploaded Documents (
            {uploadedFiles ? uploadedFiles.length : '0'}
            )
          </Grid>
          {uploadedFiles && uploadedFiles.map((item) => (
            <Box className="upload-dialog-document" key={item.name}>
              <img
                src={renderIconBasedOnDocType(item)}
                alt="PDF icon"
                width="25px"
                height="25px"
              />
              <span className="upload-dialog-document-text">
                {item.name || item.fileName}
              </span>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

ViewUserSubmission.propTypes = {
  data: PropTypes.shape(
    {
      membershipCategory: PropTypes.string,
      submissionMethod: PropTypes.string,
      taxReturnFiles: PropTypes.arrayOf(PropTypes.any),
      directDepositForm: PropTypes.any,
      formID: PropTypes.string,
      status: PropTypes.string,
    },
  ).isRequired,
};

export default withRouter(ViewUserSubmission);
