import React from 'react';
import { Grid, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import MemberIcon from '../../../../../assets/icons/member-token.svg';
import StaffIcon from '../../../../../assets/icons/staff-token.svg';
import GuestIcon from '../../../../../assets/icons/guest-token.svg';
import AlertIcon from '../../../../../assets/icons/alert-circle.svg';
import PhoneIcon from '../../../../../assets/icons/userFound-phone.svg';
import EmailIcon from '../../../../../assets/icons/userFound-email.svg';
import '../../../../../css/receipts.css';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      history,
      userInfo,
      backRoute,
    } = this.props;

    return (
      <Grid container className="root receipts-header-spacing">
        <Grid className="mb-2">
          <button
            onClick={() => {
              if (backRoute === 'history') {
                history.push('/adminPortal/reports/checkInHistory');
              } else {
                history.push(`/adminPortal/reports/dailyCheckIn/${backRoute}`)
              }
            }}
            type="button"
            className="reports-back-buttons"
          >
            &lt;  {(backRoute === 'history') ? 'Back to Check-in History' : 'Back to Daily Check-in'}
          </button>
        </Grid>
        <Grid item xs={12} className="mb-5">
          <Grid container>
            <Grid className="reports-covid-token-wrapper">
              {
              (userInfo.userType === 'Member') ? (
                <img src={MemberIcon} alt="Member Token" width="34px" height="34px" className="reports-covid-token" />
              )
                : (userInfo.userType === 'Guest') ? (
                  <img src={GuestIcon} alt="Guest Token" width="34px" height="34px" className="reports-covid-token" />
                )
                  : <img src={StaffIcon} alt="Staff Token" width="34px" height="34px" className="reports-covid-token" />
              }
            </Grid>
            <Grid item xs>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className="reports-covid19-header"
              >
                <Box>
                  <Grid item xs={12}>
                    <h1 className="reports-covid-name-title">{`${userInfo.firstName} ${userInfo.lastName}`}</h1>
                  </Grid>
                  <Grid item xs={12}>
                    <h2 className="reports-covid-date">
                      Last Visited:
                      {' '}
                      <Moment date={userInfo.date} format="ddd, MMMM DD YYYY HH:mm a" />
                    </h2>
                  </Grid>
                </Box>
                <Box>
                  <Grid item xs={12}>
                    <h2 className="reports-covid-date">
                      <img
                        src={(!userInfo.email || userInfo.email === '') ? AlertIcon : EmailIcon}
                        alt={(!userInfo.email || userInfo.email === '') ? 'Email icon' : 'No email found'}
                        width="14px"
                        height="14px"
                        className="reports-covid-phoneIcon"
                      />
                      {(!userInfo.email || userInfo.email === '') ? <span className="covid19-text-alert">No email found</span> : userInfo.email}
                    </h2>
                  </Grid>
                  <Grid item xs={12}>
                    <h2 className="reports-covid-date">
                      <img src={PhoneIcon} alt="Phone Icon" width="14px" height="14px" className="reports-covid-phoneIcon" />
                      {userInfo.phone}
                    </h2>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="reports-receipts-title">
            Check-in History
        </Grid>
      </Grid>
    );
  }
}

Header.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  userInfo: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    date: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
  backRoute: PropTypes.string.isRequired,
};
export default Header;
