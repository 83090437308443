import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import DownloadIcon from '../../../../assets/icons/download-receipt.svg';
import PrintIcon from '../../../../assets/icons/printer-icon.svg';
import ReceiptPrint from './receiptPrint';
import Moment from 'react-moment';

function Receipt({ data }) {

  const handlePrint = () => {
    const printContent = document.getElementById('receipt').innerHTML;
    document.body.innerHTML = printContent;
    window.print();
    window.location = window.location.pathname;
  };

  return (
    <div>
      <h3 className="registration-success-receipt-title">
        Receipt
      </h3>
      <div style={{ textAlign: 'end' }}>
        <button type="button" className="success-receipt-button" onClick={handlePrint}>
          <img src={DownloadIcon} alt="download" />
        </button>
        <button type="button" className="success-receipt-button" onClick={handlePrint}>
          <img src={PrintIcon} alt="Print" />
        </button>
      </div>
      <div className="receipt-box">
        <Grid container>
          <Grid item xs={7}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box className="success-receipt-labels">
                Reference #:
              </Box>
              <Box className="success-receipt-values">
                {' '}
                {data.referenceNumber}
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box className="success-receipt-labels">
                Name:
              </Box>
              <Box className="success-receipt-values">
                {' '}
                {data.familyMembers[0].firstName}
                {' '}
                {data.familyMembers[0].lastName}
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box className="success-receipt-labels">
                Address:
              </Box>
              <Box className="success-receipt-values">
                {' '}
                {data.address.street}
                , {' '}
                {data.address.city}
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box className="success-receipt-labels">
                Date & Time:
              </Box>
              <Box className="success-receipt-values">
                {' '}
                <Moment format="MM/DD/YYYY hh:mm A">{data.cardInformation.created_at}</Moment>
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box className="success-receipt-labels">
                Category:
              </Box>
              <Box className="success-receipt-values">
                {' '}
                Membership
              </Box>
            </Grid>
          </Grid>
          <Grid item xs>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box className="success-receipt-labels">
                Payment Method:
              </Box>
              <Box className="success-receipt-values">
                {data.paymentMethod}
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box className="success-receipt-labels">
                Card Number:
              </Box>
              <Box className="success-receipt-values">
                {' '}
                {data.cardInformation !== 'N/A' ? '****' + data.cardInformation.card_details.card.last_4 : data.cardInformation}
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box className="success-receipt-labels">
                Card Type:
              </Box>
              <Box className="success-receipt-values">
                {' '}
                {data.cardType}
              </Box>
            </Grid>
            <hr />
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box className="success-receipt-labels">
                Total Amount:
              </Box>
              <Box className="success-receipt-values">
                $
                {' '}
                {data.fee.toFixed(2)}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <div className="hidden" id="receipt">
          <ReceiptPrint receiptData={data} />
        </div>
      </div>
    </div>
  );
}

Receipt.propTypes = {
  data: PropTypes.shape(
    {
      membershipCategory: PropTypes.string,
      membershipType: PropTypes.string,
      contactName: PropTypes.string,
      email: PropTypes.string,
      cardType: PropTypes.string,
      membershipID: PropTypes.string,
      fee: PropTypes.string,
      familyMembers: PropTypes.arrayOf(
        PropTypes.shape(
          {
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            dateOfBirth: PropTypes.string,
            phone: PropTypes.string,
            relationship: PropTypes.string,
            gender: PropTypes.string,
            membershipID: PropTypes.string,
          },
        ),
      ),
      address: PropTypes.shape(
        {
          city: PropTypes.string,
          street: PropTypes.string,
          province: PropTypes.string,
          postalCode: PropTypes.string,
        },
      ),
    },
  ).isRequired,
};

export default Receipt;
