import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Btn from '../../../materialUi/btn';
import { Covid19API } from '../../../API/API-covid19';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default class CheckOut extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorMessage: false,
    };
  }

  handleSubmit = () => {
    const { dailyCheckIn, close } = this.props;
    Covid19API.PUT.userCheckout(dailyCheckIn.dailyCheckInID)
      .then((success) => {
        console.log(success);
        Covid19API.EntranceUpdate.checkOut(dailyCheckIn);
        this.setState({ showErrorMessage: false });
        close();
      })
      .catch((error) => {
        console.log(error.response.data);
        if (error.response.data && error.response.data === 'Minimum capacity reached!') {
          this.setState({ showErrorMessage: true });
        }
      });
  };

  handleClose = () => {
    const { close } = this.props;
    this.setState({ showErrorMessage: false });
    close();
  };

  render() {
    const { open } = this.props;
    const { showErrorMessage } = this.state;
    return (
      <Grid className="font-family-default">
        <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="xs" fullWidth>
          <DialogContent dividers>
            <Grid container direction="row" justifyContent="center" alignItems="center" className="covid19-checkout-alert">
              <button onClick={this.handleClose} type="button" className="covid19-checkout-close" aria-label="Close Checkout Dialog">
                <i className="fas fa-times close-icon" />
              </button>
              <Box>
                <Grid container>
                  <Grid item xs={12} className="covid19-checkout-title">
                    {showErrorMessage && (
                      <>
                        <Box className="covid19-error-message">Cannot checkout!</Box>
                        <Box className="covid19-error-message">There center has reached the minimum capacity.</Box>
                      </>
                    )}
                    Confirm Check Out?
                  </Grid>
                  <Grid item xs={12} className="covid19-checkout-confirm">
                    <Btn label="Confirm" onClick={this.handleSubmit} bgColor="#e76f50" color="white" bgColorHover="#ff8c68" colorHover="white" width={295} height={36} ariaLabel="Confirm Checkout" />
                  </Grid>
                  <Grid item xs={12} className="mt-3">
                    <Btn
                      label="Cancel"
                      onClick={this.handleClose}
                      bgColor="white"
                      color="#e76f50"
                      bgColorHover="#eceff1"
                      colorHover="white"
                      borderColor="white"
                      variant="outlined"
                      width={295}
                      height={36}
                      ariaLabel="Cancel Checkout"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </DialogContent>
        </Dialog>
      </Grid>
    );
  }
}

CheckOut.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  dailyCheckIn: PropTypes.shape({
    dailyCheckInID: PropTypes.string,
  }).isRequired,
};
