import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { Grid } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';

function ParticipantFound({ participant, deleteParticipant, index }) {
  return (
    <>
      <button
        type="button"
        className="programRegistration-box-close-icon"
        onClick={() => deleteParticipant(index)}
      >
        <CloseIcon />
      </button>
      <Grid item xs={12}>
        <h3 className="programRegistration-membership-box-title">
          {participant.firstName}
          {' '}
          {participant.lastName}
          {participant.ohipNumber && (
            <span className="medical-check-wrapper">
              <CheckCircleIcon fontSize="medium" />
              <span className="medical-check-text">Medical Information</span>
            </span>
          )}
        </h3>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item>
            <h4 className="programRegistration-membership-box-subTitle">
              Relationship:
            </h4>
            {participant.relationship || 'Primary Member'}
            <span className="boxes-divider">|</span>
          </Grid>
          <Grid item>
            <h4 className="programRegistration-membership-box-subTitle">
              Phone Number:
            </h4>
            {participant.phone || 'N/A'}
            <span className="boxes-divider">|</span>
          </Grid>
          <Grid item>
            <h4 className="programRegistration-membership-box-subTitle">
              Date Of Birth:
            </h4>
            <Moment date={participant.dateOfBirth} format="DD-MM-YYYY" />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

ParticipantFound.propTypes = {

};

export default ParticipantFound;
