import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import '../../../../css/verifyAddress.css';
import Stepper from '../../../../materialUi/stepper';
import CancelAlert from '../../../../materialUi/cancelAlert';
import MemberInfo from './components/memberInfo';
import MemberType from './components/memberType';
import Btn from '../../../../materialUi/btn';
import { membershipAPI } from '../../../../API/API-membership';
import Alert from '../../../../materialUi/Alerts';
import Header from './components/header';

class VerifyMembership extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAlert: false,
      alertBody: '',
      alertTitle: '',
      membershipType: '',
      address: {},
      membershipData: {},
      btnNextvalidation: true,
      btnVerifyValidation: false,
      addressMessageValidation: 'hidden',
      addressError: '',
      openCancelAlert: false,
    };
  }

  componentDidMount() {
    const verifyMembershipFromSession = JSON.parse(sessionStorage.getItem(`verifyMembership_${this.props.match.params.membershipID}`));
    if (verifyMembershipFromSession) {
      this.setState({
        membershipType: verifyMembershipFromSession.membership.membershipType,
        address: verifyMembershipFromSession.membership.address,
        membershipData: verifyMembershipFromSession,
      });
    } else {
      membershipAPI.GET.getMembershipByID(this.props.match.params.membershipID)
        .then((success) => {
          this.setState({
            membershipData: success.data,
            address: success.data.membership.address,
            membershipType: success.data.membership.membershipType,
          });
          sessionStorage.setItem(`verifyMembership_${this.props.match.params.membershipID}`, JSON.stringify(success.data));
        })
        .catch((error) => {
          this.setState({
            openAlert: true,
            alertLocation: '/adminPortal/membership',
            alertTitle: 'User not found!',
            alertBody: 'We could not find the membership. Please try again later!',
            alertClose: 'CLOSE',
          });
          console.log(error);
        });
    }
  }

  // address verified message
  handleVerifyAddress = () => {
    const { btnVerifyValidation } = this.state;
    if (btnVerifyValidation !== true) {
      this.setState({
        btnNextvalidation: false,
        addressMessageValidation: '',
        btnVerifyValidation: true,
      });
    }
  };

  handleInputs = (address, error) => {
    if (error === false) {
      const membershipFromSession = JSON.parse(sessionStorage.getItem(`verifyMembership_${this.props.match.params.membershipID}`));
      const addressList = address.split(',');
      const updateAddress = {
        street: `${addressList[0]},${addressList[1]}`,
        postalCode: addressList[4],
        province: addressList[3],
        city: addressList[2],
        apt: membershipFromSession.membership.address.apt,
      };
      this.setState({
        addressError: error,

        address: updateAddress,
        membershipType: addressList[5],
      });
      membershipFromSession.membership.address = updateAddress;
      membershipFromSession.membership.membershipType = addressList[5];
      // save the new address to session and update the membership data with the new address
      sessionStorage.setItem(`verifyMembership_${this.props.match.params.membershipID}`, JSON.stringify(membershipFromSession));
    } else {
      this.setState({ addressError: true });
    }
  };

  handleSubmit = () => {
    const { btnNextvalidation, addressError } = this.state;
    if (btnNextvalidation !== true) {
      if (addressError !== true) {
        // send validation for the payment
        const membershipFromSession = JSON.parse(sessionStorage.getItem(`verifyMembership_${this.props.match.params.membershipID}`));
        membershipFromSession.verifyAddressValidation = true;
        sessionStorage.setItem(`verifyMembership_${this.props.match.params.membershipID}`, JSON.stringify(membershipFromSession));
        this.props.history.push(`/adminPortal/membership/verifyAddress/payment/${this.props.match.params.membershipID}`);
      } else {
        this.setState({ addressError: true });
        window.scrollTo(0, 0);
      }
    }
  };

  render() {
    const { addressMessageValidation, membershipData, address, membershipType, btnNextvalidation, btnVerifyValidation, openCancelAlert, openAlert, alertTitle, alertBody, addressError } = this.state;
    return (
      <Grid className="font-family-default">
        <Stepper labels={['Verify Address', 'Payment & Membership Card']} step={0} />
        <Header addressMessageValidation={addressMessageValidation} handleBack={() => this.props.history.push(`/adminPortal/membership/verifyMembership/${this.props.match.params.membershipID}`)} />
        <MemberInfo membershipData={membershipData} address={address} handleInputs={this.handleInputs} addressError={addressError} />
        <MemberType membershipType={membershipType} />
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
          <Grid item className="verify-bottom-btns">
            <Btn
              label="Cancel"
              bgColor="white"
              color="black"
              bgColorHover="#eceff1"
              colorHover="white"
              borderColor="white"
              variant="outlined"
              width={128}
              onClick={() => this.setState({ openCancelAlert: true })}
            />
          </Grid>
          <Grid item className="verify-bottom-btns">
            <Btn
              label="VERIFY ADDRESS"
              disable={btnVerifyValidation}
              onClick={() => this.handleVerifyAddress()}
              bgColor="#e76f50"
              color="white"
              bgColorHover="#ff8c68"
              colorHover="white"
              width={208}
            />
          </Grid>
          <Grid item className="verify-bottom-next">
            <Btn label="Next" disable={btnNextvalidation} onClick={() => this.handleSubmit()} bgColor="#e76f50" color="white" bgColorHover="#ff8c68" colorHover="white" width={128} />
          </Grid>
        </Grid>
        <CancelAlert open={openCancelAlert} close={() => this.setState({ openCancelAlert: false })} location="/adminPortal/membership" />
        <Alert open={openAlert} close={() => this.setState({ openAlert: false })} location="/adminPortal/membership" title={alertTitle} body={alertBody} stay="" leave="DONE!" />
      </Grid>
    );
  }
}

export default VerifyMembership;
