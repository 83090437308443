import React from 'react';
import { Grid, Box, Paper } from '@material-ui/core';
import '../../../../../css/payment.css';
import Icon from '../../../../../assets/icons/information.png';

function fees({ data, calculatePrice }) {
  return (
    <Grid item xs={12} md className="fees-col">
      <Paper className="payment-boxes-fees">
        <Box className="fee-title">Membership Fees</Box>
        <hr className="feesHr" />
        <Grid className="payment-boxes-fees-spacing">
          <Grid container>
            <Grid item xs>
              <span className="Type">Category</span>
            </Grid>
            <Grid item xs>
              <span className="Type">Type</span>
            </Grid>
            <Grid item xs>
              <span className="Type">Total Amount</span>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              {data.membershipCategory}
              &nbsp;
              <img src={Icon} alt="Question mark" width="12" height="12" />
            </Grid>
            <Grid item xs>
              <span className="price">
                {data.membershipType}
              </span>
            </Grid>
            <Grid item xs>
              {calculatePrice !== 'Free' && (<>$</>)}
              {calculatePrice}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}


export default fees;
