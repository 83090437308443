import React, { Component } from 'react';
import { CONVERTTIME } from '../../../../utils/dateController';


function createData(membershipDB_id, membershipID, firstName, lastName, email, phone, registrationDate, status) {
  return {
    membershipDB_id, membershipID, firstName, lastName, email, phone, registrationDate, status,
  };
}

class tableExport extends Component {
  render() {
    const programsData = [];


    for (var i = 0; i < this.props.programsData.length; i++) {
      const duration = `${CONVERTTIME(this.props.programsData[i].durationStartDate, false)} - ${CONVERTTIME(this.props.programsData[i].durationEndDate, false)}`;
      const days = this.props.programsData[i].days && this.props.programsData[i].days.map((i) => i.substring(0, 3)).toString().replace(/,/g, '-');
      const time = '';
      // filter Starts

      if (this.props.sortSearch) {
        // if search field value
        if (this.props.programsData[i].name.toLowerCase().includes(this.props.sortSearch.toLowerCase())) {
          // if sort ages is set within search
          if (this.props.sortAges.length > 0) {
            // eslint-disable-next-line
        this.props.sortAges.map(age=>{
              if (this.props.programsData[i].ages.toLowerCase() === age.toLowerCase()) {
                // if sort seasons is set within search and ages
                if (this.props.sortSeason.length > 0) {
                  this.props.sortSeason.map((seasons) => {
                    if (this.props.programsData[i].season.toLowerCase() === seasons.toLowerCase()) {
                      return programsData.push(
                        createData(this.props.programsData[i].programID, this.props.programsData[i].name, this.props.programsData[i].ages, days,
                          time, duration, this.props.programsData[i].fee, this.props.programsData[i].numberOfSpots, this.props.programsData[i].programAttendance, this.props.programsData[i].programStartTime, this.props.programsData[i].durationEndDate, this.props.programsData[i].season),
                      );
                    }
                    return programsData.push(
                      createData(this.props.programsData[i].programID, this.props.programsData[i].name, this.props.programsData[i].ages, days,
                        time, duration, this.props.programsData[i].fee, this.props.programsData[i].numberOfSpots, this.props.programsData[i].programAttendance, this.props.programsData[i].programStartTime, this.props.programsData[i].durationEndDate, this.props.programsData[i].season),
                    );
                  });
                } else {
                  programsData.push(
                    createData(this.props.programsData[i].programID, this.props.programsData[i].name, this.props.programsData[i].ages, days,
                      time, duration, this.props.programsData[i].fee, this.props.programsData[i].numberOfSpots, this.props.programsData[i].programAttendance, this.props.programsData[i].programStartTime, this.props.programsData[i].durationEndDate, this.props.programsData[i].season),
                  );
                }
              }
              return 'Not Found!';
            });
          } else {
            // if sort seasons is set
            if (this.props.sortSeason.length > 0) {
              // eslint-disable-next-line
          this.props.sortSeason.map(seasons=>{
                if (this.props.programsData[i].season.toLowerCase() === seasons.toLowerCase()) {
                  return programsData.push(
                    createData(this.props.programsData[i].programID, this.props.programsData[i].name, this.props.programsData[i].ages, days,
                      time, duration, this.props.programsData[i].fee, this.props.programsData[i].numberOfSpots, this.props.programsData[i].programAttendance, this.props.programsData[i].programStartTime, this.props.programsData[i].durationEndDate, this.props.programsData[i].season),
                  );
                }
                return 'Not Found!';
              });
            } else {
              programsData.push(
                createData(this.props.programsData[i].programID, this.props.programsData[i].name, this.props.programsData[i].ages, days,
                  time, duration, this.props.programsData[i].fee, this.props.programsData[i].numberOfSpots, this.props.programsData[i].programAttendance, this.props.programsData[i].programStartTime, this.props.programsData[i].durationEndDate, this.props.programsData[i].season),
              );
            }
          }
        }
      } else {
        // if sort ages is set
        if (this.props.sortAges.length > 0) {
        // eslint-disable-next-line
        this.props.sortAges.map(age=>{
            if (this.props.programsData[i].ages.toLowerCase() === age.toLowerCase()) {
            // if sort season is set within ages
              if (this.props.sortSeason.length > 0) {
                this.props.sortSeason.map((seasons) => {
                  if (this.props.programsData[i].season.toLowerCase() === seasons.toLowerCase()) {
                    return programsData.push(
                      createData(this.props.programsData[i].programID, this.props.programsData[i].name, this.props.programsData[i].ages, days,
                        time, duration, this.props.programsData[i].fee, this.props.programsData[i].numberOfSpots, this.props.programsData[i].programAttendance, this.props.programsData[i].programStartTime, this.props.programsData[i].durationEndDate, this.props.programsData[i].season),
                    );
                  }
                  return 'Not Found!';
                });
              } else {
                programsData.push(
                  createData(this.props.programsData[i].programID, this.props.programsData[i].name, this.props.programsData[i].ages, days,
                    time, duration, this.props.programsData[i].fee, this.props.programsData[i].numberOfSpots, this.props.programsData[i].programAttendance, this.props.programsData[i].programStartTime, this.props.programsData[i].durationEndDate, this.props.programsData[i].season),
                );
              }
            }
            return 'Not Found!';
          });
        } else {
          // if sort seasons is set
          if (this.props.sortSeason.length > 0) {
          // eslint-disable-next-line
          this.props.sortSeason.map(seasons=>{
              if (this.props.programsData[i].season.toLowerCase() === seasons.toLowerCase()) {
                return programsData.push(
                  createData(this.props.programsData[i].programID, this.props.programsData[i].name, this.props.programsData[i].ages, days,
                    time, duration, this.props.programsData[i].fee, this.props.programsData[i].numberOfSpots, this.props.programsData[i].programAttendance, this.props.programsData[i].programStartTime, this.props.programsData[i].durationEndDate, this.props.programsData[i].season),
                );
              }

              return 'Not Found!';
            });
          } else {
            programsData.push(
              createData(this.props.programsData[i].programID, this.props.programsData[i].name, this.props.programsData[i].ages, days,
                time, duration, this.props.programsData[i].fee, this.props.programsData[i].numberOfSpots, this.props.programsData[i].programAttendance, this.props.programsData[i].programStartTime, this.props.programsData[i].durationEndDate, this.props.programsData[i].season),
            );
          }
        }
      }
    }

    return (
      <div>

        <table className="table">
          <thead>
            <tr>
              <th scope="col">Program Name</th>
              <th scope="col">Ages</th>
              <th scope="col">Season</th>
              <th scope="col">Days</th>
              <th scope="col">Time</th>
              <th scope="col">Duration</th>
              <th scope="col">Spots Left</th>
            </tr>
          </thead>
          <tbody>


            {programsData.map((value, index) => (
              <tr key={index}>
                <td>
                  {value.membershipID}
                </td>
                <td>
                  {value.firstName}
                </td>
                <td>
                  {value.lastName}
                </td>
                <td>
                  {value.email}
                </td>
                <td>
                  {value.phone}
                </td>
                <td>
                  {CONVERTTIME(value.registrationDate, true)}
                </td>
                <td>
                  {value.status}
                </td>
              </tr>
            ))}

          </tbody>
        </table>

      </div>
    );
  }
}

export default tableExport;
