import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import { taxReturnAPI } from '../../../API/API-taxReturn';
import InformationIcon from '../../../assets/icons/information-icon.svg';
import WelcomePage from './welcomePage';
import SubmissionTable from './submissionTable';
import ViewDocuments from './viewDocuments';


function SubmissionsList({ history }) {
  const [screen, setScreen] = React.useState(null);
  const [data, setData] = React.useState({});
  const [selectedSubmission, setSelectedSubmission] = React.useState(false);
  const [latestSubmission, setLatestSubmission] = React.useState(0);


  const getUserTaxReturn = () => {
    taxReturnAPI.GET.getTaxReturnByUserID().then((response) => {
      setData(response.data);
      setLatestSubmission(response.data.length - 1);
      if (response.data[0].status !== 'submitted') {
        setScreen(1);
      } else {
        setScreen(0);
      }
    }).catch((error) => {
      window.location = '/scccPortal/taxes/questionnaire';
      console.log(error);
    });
  };

  React.useEffect(() => {
    getUserTaxReturn();
  }, []);

  const handleViewSubmission = (index) => {
    history.push(`/scccPortal/taxes/view/${data[index]._id}`)
    setSelectedSubmission(index);
  };

  const handleViewDocuments = () => {
    setScreen(2);
  };

  const handleStatusMessage = (status) => {
    if (status === 'pending') {
      return 'Your submission requires confirmation. Please view documents received and submission form to ensure all information is correct. If information is correct, please click “View Documents” and confirm form.';
    }
    if (status === 'craIssue') {
      return 'Filing your tax return electronically was not possible. It will need to be sent by mail. Our team at Scadding Court Community Centre will contact you for further details. ';
    }
    if (status === 'complete') {
      return 'E-filing your tax return was successful. Please download your confirmation number and keep it for your records';
    }
    return 'Your submission is missing/has incomplete information. A volunteer will contact you shortly through the contact information you provided.';
  };

  return (
    <Grid item xs className="font-family-default">
      {screen === 0 && <WelcomePage setStep={() => setScreen(1)} />}
      {screen === 1 && (
        <>
          <>
            <div className="submissionList-list-wrapper">
              <h1 className="submissionList-title">
              Review Submission
              </h1>
            </div>
            {data[latestSubmission].status === 'pending'
              || data[latestSubmission].status === 'missingInfo'
              || data[latestSubmission].status === 'craIssue'
              || data[latestSubmission].status === 'complete' ? (
                <div>
                  <Box className="submissionList-list-status-message">
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Box className="pr-2">
                        <img src={InformationIcon} alt="information" />
                      </Box>
                      <Box className="submissionList-list-status-text">
                        {handleStatusMessage(data[latestSubmission].status)}
                      </Box>
                    </Grid>
                  </Box>
                </div>
              ) : ''}
            <SubmissionTable
              handleViewSubmission={handleViewSubmission}
              data={data}
              handleViewDocuments={handleViewDocuments}
            />
          </>
         
        </>
      )}
      {screen === 2 && (
        <ViewDocuments
          data={data[0]}
          handleBack={() => setScreen(1)}
          updateData={getUserTaxReturn}
        />
      )}
    </Grid>
  );
}

SubmissionsList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};


export default SubmissionsList;
