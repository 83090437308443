import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import Btn from '../../../../materialUi/btn';
import DropdownIcon from '../../../../assets/icons/menu.png';
import MenuIcon from '../../../../materialUi/menuIcon';
import Filter from './filter';
import '../../../../css/memberAttendance.css';

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayFilter: false,
    };
  }

  handleDisplayFilter = () => {
    const { displayFilter } = this.state;
    const { handleReset } = this.props;
    if (displayFilter) {
      this.setState({ displayFilter: false });
      handleReset();
    } else {
      this.setState({ displayFilter: true });
    }
  }

  render() {
    const {
      handleBack,
      handleProgramDetails,
      programName,
      handleDate,
      handleReset,
      values,
      messages,
      handleExport,
      handleSearch,
    } = this.props;
    const { displayFilter } = this.state;
    const options = [
      'Download CSV',
      'Print',
    ];
    return (
      <Grid container className="memberAttendance-spacing">
        <Grid item xs={12}>
          <Grid
            className="memberAttendance-back"
            onClick={handleBack}
            onKeyPress={handleBack}
            role="button"
            tabIndex={0}
          >
            &lt; Back to Membership Details
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} xl={6}>
          <h1 className="memberAttendance-header">Attendance </h1>
          <h2 className="memberAttendance-subHeader">{programName}</h2>
        </Grid>
        <Grid item xs={12} md={8} xl={6}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid className="memberAttendance-btn-createNew">
              <Btn
                label="Program Details"
                onClick={handleProgramDetails}
                bgColor="white"
                color="#e76f50"
                bgColorHover="#eceff1"
                width={180}
                variant="outlined"
                borderColor="#e76f50"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs className="memberAttendance-filter-space">
              <Box
                onClick={() => this.handleDisplayFilter()}
                role="button"
                tabIndex={0}
                onKeyPress={() => this.handleDisplayFilter()}
                className="memberAttendance-filter"
              >
                <img className="Filter" src={DropdownIcon} alt="Dropdown icon" />
                Filter
              </Box>
            </Grid>
            <Grid item xs>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <MenuIcon
                  list={options}
                  onClick={(value) => handleExport(value)}
                />
              </Grid>
            </Grid>
            {
              (displayFilter)
                ? (
                  <Filter
                    handleDate={handleDate}
                    values={values}
                    messages={messages}
                    handleReset={handleReset}
                    handleSearch={handleSearch}
                  />
                ) : ''
            }
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

Header.propTypes = {
  handleBack: PropTypes.func.isRequired,
  handleProgramDetails: PropTypes.func.isRequired,
  programName: PropTypes.string.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleExport: PropTypes.func.isRequired,
  handleDate: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  values: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  messages: PropTypes.shape({
    startDate: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
    ]),
    endDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
  }).isRequired,
};
