import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import TextField from '../../../../../materialUi/textField';

function Password(props) {
  const { control, formState, getValues } = props;
  const { errors } = formState;

  const [passwordError, setPasswordError] = React.useState(false);


  const handleConfirmPassword = (value) => {
    if (getValues('password')) {
      if (getValues('password') !== value) {
        setPasswordError(true);
      } else {
        setPasswordError(false);
      }
    }
  };

  const handlePassword = (value) => {
    if (getValues('passwordCheck')) {
      if (getValues('passwordCheck') !== value) {
        setPasswordError(true);
      } else {
        setPasswordError(false);
      }
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} style={{ paddingTop: '10px' }}>
        <h2 className="registration-form-sub-title">Password</h2>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <div style={{ marginBottom: '12px' }}>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{
                required: 'Password is required',
                maxLength: { value: 100, message: 'Max length 100' },
                minLength: { value: 8, message: 'Minimum length 8' },
                validate: (value) => handlePassword(value),
              }}
              render={({ field }) => (
                <TextField
                  label="Create password"
                  id="password"
                  helperText={errors.password ? errors.password.message : ''}
                  error={!!errors.password}
                  readOnly={false}
                  type="password"
                  {...field}
                />
              )}
            />
          </div>
          <Controller
            name="passwordCheck"
            control={control}
            defaultValue=""
            rules={{
              required: 'Confirm Password is required',
              maxLength: { value: 100, message: 'Max length 100' },
              minLength: { value: 8, message: 'Minimum length 8' },
              validate: (value) => handleConfirmPassword(value),
            }}
            render={({ field }) => (
              <TextField
                label="Confirm password"
                id="passwordCheck"
                helperText={errors.passwordCheck ? errors.passwordCheck.message : ''}
                error={!!errors.passwordCheck}
                readOnly={false}
                type="password"
                {...field}
              />
            )}
          />
          <span className={passwordError ? 'display-password-error' : 'hidden-password-error'}>*Passwords does not match</span>
        </Grid>
        <Grid item xs={12} md={6} className="registration-password-note">
          <span className="registration-password-note-red">*Note:</span>
          {' '}
          A membership ID will be generated for you after you complete your registration.
          This membership ID will be required whenever
          you login.
        </Grid>
      </Grid>
    </Grid>
  );
}

Password.propTypes = {
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  getValues: PropTypes.func.isRequired,
  formState: PropTypes.shape({
    errors: PropTypes.any,
  }).isRequired,
};

export default Password;
