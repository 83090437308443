/* eslint-disable no-param-reassign */
import React from 'react';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import BackButton from '../../../../materialUi/backButton';
import Alert from '../../../../materialUi/Alerts';
import Fees from './components/fees';
import PaymentMethod from './components/paymentMethod';
import { membershipAPI } from '../../../../API/API-membership';
import { CardAPI } from '../../../../API/API-Card';
import { EmailAPI } from '../../../../API/API-Email';
import CashIcon from '../../../../assets/icons/noun-payment-1806092@2x.png';
import './payment.css';

function Payment({ handleNextStep, data, password, userData }) {
  const MEMBERSHIP_ID = Math.floor(100000 + Math.random() * 90000000);
  const REFERENCE_NUMBER = Math.floor(100000 + Math.random() * 90000000);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertValues, setAlertValues] = React.useState({});
  const [paymentMethod, setPaymentMethod] = React.useState('Cash');
  const [paymentStatus, setPaymentStatus] = React.useState(false);
  const [calculatedFee, setCalculatedFee] = React.useState(null);

  const addMembershipIDToFamilyMembers = () => {
    for (let i = 0; i < data.familyMembers.length; i++) {
      data.familyMembers[i].membershipID = MEMBERSHIP_ID;
    }
  };

  const initialFee = data.membershipType && calculateMembershipPrice(data.membershipType, data.membershipCategory);

  const addNecessaryFields = (copyData) => {
    const addData = copyData;
    addData.fee = calculatedFee || initialFee;
    addData.membershipID = MEMBERSHIP_ID;
    addData.referenceNumber = REFERENCE_NUMBER;
    addData.status = 'active';
    if (userData && userData.userData.userType.toLowerCase() === 'provisory member') {
      addData.userExists = true;
    }
    return addData;
  };

  const handleFee = (newFee) => {
    if (newFee === 'Free') return setCalculatedFee(newFee);
    return setCalculatedFee(parseFloat(newFee));
  };

  const formatReceiptData = () => {
    const RECEIPT = {
      email: data.email,
      referenceNumber: REFERENCE_NUMBER,
      name: `${data.familyMembers[0].firstName} ${data.familyMembers[0].lastName}`,
      cardType: data.cardType,
      category: 'Membership Registration',
      paymentMethod: data.paymentMethod,
      address: `${data.address.street}, ${data.address.city}, ${data.address.province}, ${data.address.postalCode}`,
      date: moment(new Date()).format('DD/MM/YYYY'),
      total: calculatedFee === 'Free' ? 0 : calculatedFee,
    };
    return RECEIPT;
  };

  const sendEmail = () => {
    const RECEIPT = formatReceiptData();
    EmailAPI.EMAIL.sendReceipt(RECEIPT)
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });

    EmailAPI.EMAIL.membershipConfirmation(data)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const submitMembershipRegistration = async () => {
    try {
      data.fee = calculatedFee === 'Free' ? 0 : calculatedFee;
      if (!calculatedFee) {
        data.fee = initialFee;
      }
      console.log("from API " + JSON.stringify(data));

      const finalData = {
        ...data,
        address:{
          postalCode: data.address.postalCode ? data.address.postalCode : "M5T 2W6",
          street: data.address.street,
          city: data.address.city,
          province: data.address.province,
          apt: data.address.apt,
        }
      } 
      await membershipAPI.POST.membershipRegistration(finalData)
        .then(async (success) => {
          data.payment = true;
  
          handleNextStep(data, 2);
          if (data.email && data.email !== '') {
            await sendEmail();
          }
        });
    } catch (error) {
      setOpenAlert(true);
      setAlertValues({
        alertTitle: 'SERVER ERROR!',
        alertBody: 'There is an error on the server. Please try again later!',
        leave: 'hidden',
        stay: 'CLOSE',
      });
    }
  };
  
  const submitCardPayment = (nonce, cardHolderName) => {
    data.paymentMethod = 'Credit Card';
    const formattedFee = data.fee.toString().includes('.') ? data.fee.toString().replace('.', '') : `${data.fee.toString()}00`;
    CardAPI.Square.cardPayment(nonce, MEMBERSHIP_ID, `Membership Registration,  Membership: ${MEMBERSHIP_ID}, Card Holder Name: ${cardHolderName}`, `${formattedFee}`)
      .then(async (response) => {
        data.cardType = response.data.result.payment.card_details.card.card_brand;
        data.cardInformation = response.data.result.payment;
        await submitMembershipRegistration();
      })
      .catch((error) => {
        setPaymentStatus(true);
        console.log(error);
        setOpenAlert(true);
        setAlertValues({
          alertTitle: 'PAYMENT ERROR!',
          alertBody: 'Payment Declined. Please, check the data entered.',
          leave: 'hidden',
          stay: 'CLOSE',
        });
      });
  };

  const validateStatusBasedOnUserType = () => {
    if (!userData) return 'Unverified';
    const { userType } = userData.userData;
    if (
      userType.toLowerCase() !== 'member' ||
      userType.toLowerCase() !== 'provisory member'
    ) return 'Active';
    return 'Unverified';
  }

  const submitCashPayment = async (paymentMethod) => {
    data.paymentMethod = paymentMethod;
    data.status = validateStatusBasedOnUserType();
    data.cardType = 'N/A';
    data.cardInformation = 'N/A';
    await submitMembershipRegistration();
  };

  const handleSubmitMembership = async (nonce, cardHolderName, paymentMethod = false) => {
    if (paymentMethod) {
      await submitCashPayment(paymentMethod);
    } else {
      await submitCardPayment(nonce, cardHolderName);
    }
  };

  React.useEffect(() => {
    const copyData = addNecessaryFields(data);
    if (data.familyMembers) {
      addMembershipIDToFamilyMembers();
    }
    data = copyData;

    if (!userData) {
      if (!password) {
        handleNextStep(data, 0);
      } else {
        copyData.password = password;
      }
    }

    if (userData) {
      if (
        userData.userData.userType.toLowerCase() !== 'provisory member' &&
        userData.userData.userType.toLowerCase() !== 'member'
      ) {
        if (!password) {
          handleNextStep(data, 0);
        } else {
          copyData.password = password;
        }
      }
    }

  }, [data]);

  return (
    <div className="payment-membership-wrapper">
      <BackButton handleClick={() => handleNextStep(data, 0)} label="Back to Registration form" />
      <Grid container>
        <Grid item xs={12} className="Payment-title">
          Payment
        </Grid>
        <Fees data={data} calculatePrice={calculatedFee || initialFee} />
        <PaymentMethod
          handleSaveOnDB={handleSubmitMembership}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          paymentStatus={paymentStatus}
          handleFee={handleFee}
          initialFee={initialFee}
          userData={userData}
        />
      </Grid>
      <Alert open={openAlert} close={() => setOpenAlert(false)} location="/" title={alertValues.alertTitle} body={alertValues.body} stay={alertValues.stay} leave={alertValues.leave} />
      {paymentMethod === 'Cash' && (
        <>
          {!userData && (
            <div className="payment-cash-wrapper">
              <img src={CashIcon} alt="cashIcon" aria-hidden="true" className="payment-cash-icon" />
              For <b>cash payment</b>, click next and <b>visit reception</b>.
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Payment;

export const calculateMembershipPrice = (type, category) => {
  const formattedType = type.toLowerCase();
  const formattedCategory = category.toLowerCase();
  if (formattedType === 'community') {
    if (formattedCategory === 'family') {
      return 8;
    }
    if (formattedCategory === 'adult') {
      return 5;
    }
    if (formattedCategory === 'youth') {
      return 2;
    }
    if (formattedCategory === 'senior') {
      return 1;
    }
    if (formattedCategory === 'child') {
      return 1;
    }
  }
  if (formattedType === 'associate') {
    if (formattedCategory === 'family') {
      return 15;
    }
    if (formattedCategory === 'adult') {
      return 10;
    }
    if (formattedCategory === 'youth') {
      return 4;
    }
    if (formattedCategory === 'senior') {
      return 2;
    }
    if (formattedCategory === 'child') {
      return 2;
    }
  }
};
