import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableRow from '@material-ui/core/TableRow';
import MenuIcon from '../../../../materialUi/menuIcon';
import '../../../../css/membershipList.css';
import { pushProgramsResults, searchProgram } from './listFunctions';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  {
    id: 'program',
    numeric: false,
    disablePadding: true,
    label: 'Program Name',
  },
  {
    id: 'ages',
    numeric: false,
    disablePadding: true,
    label: 'Ages',
  },
  {
    id: 'season',
    numeric: false,
    disablePadding: false,
    label: 'Season',
  },
  {
    id: 'days',
    numeric: false,
    disablePadding: false,
    label: 'Days',
  },
  {
    id: 'time',
    numeric: false,
    disablePadding: false,
    label: 'Time',
  },
  {
    id: 'duration',
    numeric: false,
    disablePadding: false,
    label: 'Duration',
  },
  {
    id: 'spotsLeft',
    numeric: true,
    disablePadding: false,
    label: 'Spots Left',
  },
  {
    id: 'menu',
    numeric: false,
    disablePadding: false,
    label: '',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="bg-light">
      <TableRow>
        {headRows.map((row) => (
          <TableCell key={row.id} align={row.numeric ? 'center' : 'left'} padding={row.disablePadding ? 'none' : 'default'} sortDirection={orderBy === row.id ? order : false} className="table-cell">
            <TableSortLabel active={orderBy === row.id} direction={order} onClick={createSortHandler(row.id)}>
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

export default function ProgramsListTable({
  data,
  handleProgramSelection,
  sortSearch,
}) {
  const rows = [];
  for (let i = 0; i < data.length; i++) {
    if (searchProgram(data[i], sortSearch)) {
      pushProgramsResults(rows, data[i]);
    }
  }

  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('status');
  const [selected, setSelected] = React.useState([]);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  const menuOptions = ['Program Details', 'Program Registration', 'Event Attendance', 'Attendees', 'Edit', 'Delete'];

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  return (
    <Grid className="font-family-default">
      <Grid className={classes.tableWrapper}>
        <Table className={classes.table} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
          />
          <TableBody>
            {stableSort(rows, getSorting(order, orderBy))
              .slice((!sortSearch ? page : 0) * rowsPerPage, (!sortSearch ? page : 0) * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow
                  hover
                  key={row.programID}
                  className="memberList-tableselect"
                >
                  <TableCell component="th" scope="row" padding="none" className="table-cell">
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.ages}</TableCell>
                  <TableCell align="left">{row.seasons}</TableCell>
                  <TableCell align="left">{row.days}</TableCell>
                  <TableCell align="left">{row.time}</TableCell>
                  <TableCell align="left">{row.duration}</TableCell>
                  <TableCell align="center" style={{ width: '200px', paddingRight: '60px', paddingLeft: '60px' }}>
                    <div
                      className={
                        row.numberOfSpots < 1
                          ? 'programsList-thereISNoSpotsLeft'
                          : 'programsList-hasSpotsLeft'
                      }
                    >
                      {row.numberOfSpots < 0 ? 0 : row.numberOfSpots}
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    <MenuIcon
                      list={menuOptions}
                      onClick={(value) => handleProgramSelection({
                        selectionType: value,
                        programID: row.programID,
                        programName: row.name,
                        data: row,
                      })}
                      width="187"
                    />
                  </TableCell>
                </TableRow>
              ))}
            {emptyRows > 0 && <TableRow style={{ height: 49 * emptyRows }} />}
          </TableBody>
        </Table>
      </Grid>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Grid>
  );
}

ProgramsListTable.propTypes = {
  handleProgramSelection: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  sortSearch: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
};
