import axios from 'axios';
import baseURL from './baseURL';
import TOKEN from './Frontend-Token';

export const userAPI = {
  POST: {
    register(userObj) {
      return axios.post(
        `${baseURL}api/addNewUser`,
        {
          userObj,
        },
        {
          headers: { 'x-access-token': TOKEN },
        },
      );
    },
    addProvisoryMember(userObj) {
      return axios.post(`${baseURL}api/addProvisoryMember`, {
        userObj,
      });
    },
  },
  PUT: {
    delete(userID) {
      return axios.put(`${baseURL}api/deleteUser/${userID}`, {},
        {
          headers: { 'x-access-token': TOKEN },
        });
    },
    editMemberPassword(membershipID, password, newPassword) {
      return axios.put(`${baseURL}api/editMemberPassword/${membershipID}`, {
        password,
        newPassword,
      });
    },
    editUserPassword(userID, password, newPassword) {
      return axios.put(`${baseURL}api/editUserPassword/${userID}`, {
        password,
        newPassword,
      });
    },
    editDiscountPassword(password, newPassword) {
      return axios.put(
        `${baseURL}api/editDiscountPassword`,
        {
          password,
          newPassword,
        },
        {
          headers: { 'x-access-token': TOKEN },
        },
      );
    },
    editUser(userID, userObj) {
      return axios.put(`${baseURL}api/editUser/${userID}`, {
        userObj,
      });
    },
  },
  GET: {
    getAllUser() {
      return axios.get(`${baseURL}api/getAllUsers`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    getAllInstructorsUsers() {
      return axios.get(`${baseURL}api/getAllInstructorsUsers`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    checkDiscountPassword(password) {
      return axios.get(`${baseURL}api/checkDiscountPassword/${password}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    checkUserEmail(email) {
      return axios.get(`${baseURL}api/checkUserEmail/${email}`);
    },
    getUsersByUserType(userType) {
      return axios.get(`${baseURL}api/getUsersByUserType/${userType}`,
        {
          headers: { 'x-access-token': TOKEN },
        });
    },
    getUserByID(userID) {
      return axios.get(`${baseURL}api/getUserByID/${userID}`,
        {
          headers: { 'x-access-token': TOKEN },
        });
    },
  },
  user: {
    updateExpirationDate(userId, expirationDate, status) {
      return axios.put(`${baseURL}api/editUserPassword/${userId}`, {
        expirationDate,
        status,
      });
    },
  },
};

export default userAPI;
