import React, { Component } from 'react';
import { Grid, Box } from '@material-ui/core';
import Header from './components/header';
import UsersTable from './components/usersTable';
import AddNewUser from './components/addNewUser';
import { userAPI } from '../../API/API-user';
import DeleteUser from './components/deleteUser';
import EditUser from './components/editUser';
import '../../css/settings.css';

export default class settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      selectedItems: [],
      userTypes: [],
      deleteErrorMessage: false,
      values: {
        members: false,
        superAdmin: false,
        receptionist: false,
        membershipAdmin: false,
      },
      addNewUser: false,
      editUser: false,
      deleteUser: false,
      usersData: [],
      userIndex: -1,
      userID: '',
    };
  }

  componentDidMount() {
    userAPI.GET.getAllUser().then((success) => {
      this.setState({ usersData: success.data });
    }).catch((error) => {
      console.log(error);
    });
  }


  handleFilter =(name, value, selectedUser) => {
    const {
      values,
      selectedItems,
      userTypes,
    } = this.state;
    const updateValues = values;
    if (value === true) {
      updateValues[name] = value;
      this.setState({
        values: updateValues,
        userTypes: userTypes.concat([selectedUser]),
        selectedItems: selectedItems.concat([
          <Box className="memeberList-selectedItems" key={selectedUser} name={name}>
            {selectedUser}
            <i className="far fa-times-circle ml-2 " onClick={() => this.handleFilter(name, false, selectedUser)} />
          </Box>,
        ]),
      });
    } else {
      // remove filter
      for (let i = 0; i < userTypes.length; i++) {
        if (userTypes[i] === selectedUser) {
          userTypes.splice(i, 1);
        }
      }

      // remove selectedItems
      for (let index = 0; index < selectedItems.length; index++) {
        if (selectedItems[index].key === selectedUser) {
          selectedItems.splice(index, 1);
        }
      }

      updateValues[name] = value;
      this.setState({ values, userTypes, selectedItems });
    }
  }

  resetFilters=() => {
    const { values } = this.state;
    for (const key in values) {
      values[key] = false;
    }
    this.setState({ values, status: [], selectedItems: [] });
  }

  handleEdit =(id, index) => {
    this.setState({
      userID: id,
      userIndex: index,
      editUser: true,
    });
  }


  render() {
    const {
      selectedItems,
      values,
      usersData,
      search,
      userTypes,
      editUser,
      userID,
      userIndex,
      addNewUser,
      deleteUser,
    } = this.state;
    return (
      <Grid>
        <Header
          search={(value) => this.setState({ search: value })}
          handleClickNew={() => this.setState({ addNewUser: true })}
          values={values}
          handleFilter={this.handleFilter}
          selectedItems={selectedItems}
          resetFilters={this.resetFilters}
        />
        <UsersTable
          data={usersData}
          sortSearch={search}
          sortTypes={userTypes}
          handleDelete={this.handleDelete}
          handleEdit={this.handleEdit}
        />
        <EditUser
          usersData={usersData}
          open={editUser}
          close={() => this.setState({ editUser: false })}
          userID={userID}
          userIndex={userIndex}
          handleDelete={() => this.setState({ deleteUser: true, editUser: false })}
        />
        <DeleteUser
          usersData={usersData}
          open={deleteUser}
          close={() => this.setState({ deleteUser: false })}
          userID={userID}
          userIndex={userIndex}
        />
        <AddNewUser
          open={addNewUser}
          close={() => this.setState({ addNewUser: false })}
        />
      </Grid>
    );
  }
}
