import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import Cookies from 'js-cookie';
import logo from '../../assets/logo.svg';
import membershipIcon from '../../assets/icons/membership.png';
import programIcon from '../../assets/icons/assignment-ind-24-px.png';
import facilityIcon from '../../assets/icons/paste-24-px.png';
import volunteeringIcon from '../../assets/icons/spa-24-px.png';
import gymIcon from '../../assets/icons/fitness-center-24-px.png';
import hoursIcon from '../../assets/icons/access-time-24-px.png';
import taxIcon from '../../assets/icons/tax-icon.svg';
import Btn from '../../materialUi/btn';
import { authenticationAPI } from '../../API/API-authentication';
import './landingPage.css';

function InitialPortal({ history }) {
  const [typeOfPortal, setTypeOfPortal] = React.useState('');

  React.useEffect(() => {
    const result = async () => {
      authenticationAPI.authentication
        .getUser(Cookies.get('token'))
        .then((success) => {
          if (success.data.userType === 'Member' || success.data.userType === 'provisory member') {
            setTypeOfPortal('/scccPortal');
          } else {
            setTypeOfPortal('/adminPortal');
          }
        })
        .catch(() => {
          setTypeOfPortal('/scccPortal');
        });
    };
    result();
  }, [setTypeOfPortal]);

  const listOfPortals = [
    {
      icon: membershipIcon,
      iconClassName: 'boxes-membership-icons',
      title: 'Membership',
      location: 'membership',
      color: '#279D8F',
      extraMessage: '',
    },
    {
      icon: programIcon,
      iconClassName: 'boxes-program-icons',
      title: 'Program registration',
      location: 'programs',
      color: '#E76F51',
      extraMessage: '',
    },
    {
      icon: facilityIcon,
      iconClassName: 'boxes-facility-icons',
      title: 'Facility Rentals',
      location: '',
      color: '#E9C46A',
      extraMessage: 'Coming soon',
    },
    {
      icon: volunteeringIcon,
      iconClassName: 'boxes-volunteering-icons',
      title: 'Volunteering',
      location: '',
      color: '#414158',
      extraMessage: 'Coming soon',
    },
    {
      icon: gymIcon,
      iconClassName: 'boxes-gym-icons',
      title: 'Gym Membership',
      location: '',
      color: '#279D8F',
      extraMessage: 'Coming soon',
    },
    {
      icon: hoursIcon,
      iconClassName: 'boxes-hours-icons',
      title: 'Hours',
      location: '',
      color: '#414158',
      extraMessage: 'Coming soon',
    },
  ];

  return (
    <>
      <Grid container className="app-wrapper ">
        <Grid item xs={12} className="initial-portal-top-wrapper">
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Box>
              <img src={logo} alt="Sccc Logo" className="initial-logo" />
            </Box>
          </Grid>
          <Box className="initial-button">
            <Btn
              label="MEMBER LOGIN"
              bgColor="white"
              color="#e76f50"
              bgColorHover="#eceff1"
              colorHover="#ff8c68"
              borderColor="#e76f50"
              variant="outlined"
              width={window.innerWidth < 600 ? 145 : 168}
              height={window.innerWidth < 600 ? 29 : 35}
              onClick={() => history.push('/memberLogin')}
            />
          </Box>
        </Grid>
        <Grid item xs={12} className="initial-portal-wrapper">
          <Grid container direction="row" justifyContent="center" alignItems="center">
            {listOfPortals.map((item) => (
              <button
                className="initial-portal-boxes"
                style={{ backgroundColor: item.color }}
                onClick={() => item.extraMessage === '' && history.push(`${typeOfPortal}/${item.location}`)}
                type="button"
                key={item.title}
              >
                <Grid item xs={12}>
                  <img src={item.icon} alt={item.title} aria-hidden="true" className={item.iconClassName} />
                </Grid>
                <Grid item xs={12} className="initial-portal-box-title">
                  {item.title}
                </Grid>
                <Grid item xs={12} className="initial-portal-box-extra-message">
                  {item.extraMessage}
                </Grid>
              </button>
            ))}
          </Grid>
          {/* <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <button
              item
              className="initial-vertical-box"
              onClick={() => history.push('/preRegistration')}
              type="button"
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid className="initial-vertical-desktop">
                  <img src={taxIcon} alt="membership icon" className="initial-vertical-box-icon" />
                  <span className="initial-vertical-box-title">
                    TAX QUESTIONNAIRE
                  </span>
                </Grid>
              </Grid>
            </button>
          </Grid> */}
        </Grid>
      </Grid>
    </>
  );
}

export default InitialPortal;

InitialPortal.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
