import { formatePhoneNumber } from '../../../../utils/supportFunctions';
import deburr from 'lodash/deburr';

function createData(membershipDB_id, membershipID, name, email, phone, registrationDate, status) {
  return {
    membershipDB_id,
    membershipID,
    name,
    email,
    phone,
    registrationDate,
    status,
  };
}

export const familyMemberMembershipCard = function (familyMemberArray, searchItem) {
  let result;
  for (let i = 0; i < familyMemberArray.length; i++) {
    if (familyMemberArray[i].membershipCard) {
      if (familyMemberArray[i].membershipCard.includes(searchItem)) {
        result = familyMemberArray[i];
      }
    }
  }
  return result;
};

const searchFamilyMembers = (member, inputValue) => {
  const result = []
  member.familyMembersInformation.map((item) => {
    const name = `${item.firstName} ${item.lastName} `
    if (name.toLowerCase()
      .includes(inputValue)) {
      result.push(item);
    }
  })
  return result.length > 0
}

const searchByEmail = (member, inputValue) => {
  if (member.email && member.email
    .includes(inputValue)) {
    return true
  }
  return false

}

export const searchMembers = (membershipData, searchItem) => {
  const inputValue = deburr(searchItem.trim()).toLowerCase();
  const member = membershipData.familyMembersInformation[0];
  if (!membershipData.familyMembersInformation[0]) return false;

  function isNotAllLowercase(str) {
    return str !== str.toLowerCase();
  }
  if (membershipData.email) {
    if (isNotAllLowercase(membershipData.email)) {
      console.log(membershipData.email)
    }
  }

  if (
    searchFamilyMembers(membershipData, inputValue) ||
    searchByEmail(membershipData, inputValue) ||
    formatePhoneNumber(member.phone)
      .toLowerCase()
      .includes(searchItem.toLowerCase()) ||
    member.membershipID.toLowerCase().includes(searchItem.toLowerCase()) ||
    familyMemberMembershipCard(membershipData.familyMembersInformation, searchItem)
  ) {
    return true;
  }

  return false;
};

export const changeStatus = (membershipData) => {
  const membershipDate = new Date(membershipData.expirationDate);
  const todayDate = new Date();
  const endDate = new Date();
  endDate.setDate(endDate.getDate() + 30);
  if (membershipData.status === 'Unverified') {
    return 'Unverified';
  }
  if (membershipDate > todayDate && membershipDate < endDate) {
    return 'Expiring';
  }
  if (membershipDate < todayDate) {
    return 'Expired';
  }
  return 'Active';
};

export const pushMembershipResults = (rows, membershipData) => {
  // if (membershipData.familyMembersInformation[0] === undefined) {
  //   console.log(membershipData);
  // }
  if (membershipData.familyMembersInformation[0]) {
    return rows.push(
      createData(
        membershipData._id,
        membershipData.membershipID,
        `${membershipData.familyMembersInformation[0].firstName} ${membershipData.familyMembersInformation[0].lastName}`,
        membershipData.email,
        membershipData.familyMembersInformation[0].phone,
        membershipData.registrationDate,
        changeStatus(membershipData)
      )
    );
  }
};
