import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { ProgramsAPI } from "../../../API/API-programs";
import BackButton from "../../../materialUi/backButton";
import RegistrationForm from "./registrationForm/registrationForm";
import Loading from "../../../materialUi/loading";

function AddNewAttendance({ history, match, userData }) {
  const { programID } = match.params;
  const [data, setData] = useState({});
  const [programData, setProgramData] = useState({});

  const sessionValues = () => {
    const SESSION_DATA = JSON.parse(
      sessionStorage.getItem(`programRegistration_${programID}`)
    );
    if (SESSION_DATA) return SESSION_DATA;
    return false;
  };

  const getProgram = () => {
    ProgramsAPI.GET.getProgramByID(programID)
      .then((response) => {
        const { program, instructor } = response.data;
        program[0].instructor = instructor;
        if (sessionValues()) {
          if (sessionValues().currentStep === 2) {
            setData(sessionValues());
          }
        }
        setProgramData(program[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    async function fetchData() {
      await getProgram();
    }
    fetchData();
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  if (!Object.keys(programData).length === 0)
    return <Loading top="50%" right="40%" />;

  return (
    <Grid item xs>
      <div className="programRegistration-wrapper">
        <BackButton
          handleClick={() => {
            history.goBack(1);
          }}
          label={"Back to Attendees list"}
        />
      </div>
      <RegistrationForm
        data={data}
        userData={userData}
        programData={programData}
      />
    </Grid>
  );
}

export default AddNewAttendance;
