import React, { Component } from 'react';
import { Grid, Box } from '@material-ui/core';
import Fees from './components/fees';
import Type from './components/type';
import Btn from '../../../../materialUi/btn';
import Stepper from '../../../../materialUi/stepper';
import '../../../../css/selectCategory.css';
import CancelAlert from '../../../../materialUi/cancelAlert';
import { membershipAPI } from '../../../../API/API-membership';
import Alert from '../../../../materialUi/Alerts';

class registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAlert: false,
      openCancelAlert: false,
    };
  }

  componentDidMount() {
    membershipAPI.GET.getMembershipByID(this.props.match.params.membershipID)
      .then((success) => {
        console.log(success);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          openAlert: true,
          alertTitle: 'User not found!',
          alertBody: 'We could not find the membership. Please try again later!',
          alertClose: 'CLOSE',
        });
      });
  }

  handleClick = () => {
    sessionStorage.setItem('changeMembershipValidation', true);
    this.props.history.push(`/adminPortal/membership/changeMembershipType/payment/${this.props.match.params.membershipID}`);
  };

  render() {
    const { openCancelAlert, alertClose, openAlert, alertTitle, alertBody } = this.state;
    return (
      <Grid item xs className="font-family-default">
        <Stepper labels={['Registration form', 'Payment', 'Success']} step={0} />
        <Grid container className="selectCategory-spacing" onKeyPress={this.handleClick}>
          <Grid item xs={12} className="selectCategory-backbtn">
            <Box onClick={() => this.props.history.push(`/adminPortal/membership/verifyMembership/${this.props.match.params.membershipID}`)}>&lt; Back to Account Details </Box>
          </Grid>
          <Grid item xs={12} className="selectCategory-main-titles">
            Membership registration Form
          </Grid>
          <Fees />
          <Grid item xs className="selectCategory-family">
            <Type />
            <Grid item xs={12} className="selectCategory-btns-bottom">
              <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <Box style={{ marginRight: '5px' }}>
                  <Btn
                    label="Cancel"
                    bgColor="white"
                    color="black"
                    bgColorHover="#eceff1"
                    colorHover="white"
                    borderColor="white"
                    variant="outlined"
                    width={128}
                    onClick={() => this.setState({ openCancelAlert: true })}
                  />
                  <CancelAlert open={openCancelAlert} close={() => this.setState({ openCancelAlert: false })} location="/" />
                </Box>
                <Box>
                  <Btn label="Next" onClick={() => this.handleClick('next')} bgColor="#e76f50" color="white" bgColorHover="#ff8c68" colorHover="white" width={128} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Alert open={openAlert} close={() => this.setState({ openAlert: false })} location="/adminPortal/membership" title={alertTitle} body={alertBody} stay="" leave={alertClose} />
      </Grid>
    );
  }
}

export default registration;
