import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import { userAPI } from '../../../API/API-user';
import { FamilyAPI } from '../../../API/API-familyMembers';
import { Covid19API } from '../../../API/API-covid19';
import UserFound from './usersFound';
import SearchBox from './searchBox';
import Capacity from './capacity';
import Checkout from './checkOut';
import '../../../css/covid19.css';

export default class SearchUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usersData: [],
      usersFound: false,
      searchValue: '',
      openCheckout: false,
    };
  }

  componentDidMount() {
    const { usersData } = this.state;
    userAPI.GET.getAllUser().then((success) => {
      const usersData = success.data;
      FamilyAPI.GET.getAllFamilyMembers().then((familySuccess) => {
        const familyData = familySuccess.data;
        Covid19API.GET.getAllGuests().then((guestSuccess) => {
          const guestData = usersData.concat(guestSuccess.data);
          this.setState({ usersData: familyData.concat(guestData) });
        }).catch((er) => {
          console.log(er);
        });
      }).catch((err) => {
        console.log(err);
      });
    }).catch((error) => {
      console.log(error);
    });

    Covid19API.EntranceUpdate.updateUsersList((err, newUser) => this.setState({ usersData: usersData.concat(newUser) }));
  }


  verifyUserType = (usersData) => {
    if (usersData.userType) {
      if (usersData.userType !== 'Member') {
        return usersData;
      }
    }
    if (usersData.relationship) {
      const UpdateUserType = usersData;
      UpdateUserType.userType = 'Member';
      return UpdateUserType;
    }
    return null;
  }

  searchUsers = (usersData, searchValue) => {
    const updateUsers = [];
    for (let i = 0; i < usersData.length; i++) {
      if (usersData[i].firstName) {
        if (
          usersData[i].firstName.toLowerCase().includes(searchValue.toLowerCase())
            || usersData[i].lastName.toLowerCase().includes(searchValue.toLowerCase())
        ) {
          if (this.verifyUserType(usersData[i])) {
            updateUsers.push(this.verifyUserType(usersData[i]));
          }
        } else if (usersData[i].phone) {
          if (usersData[i].phone.includes(searchValue.toLowerCase())) {
            if (this.verifyUserType(usersData[i])) {
              updateUsers.push(this.verifyUserType(usersData[i]));
            }
          }
        } else if (usersData[i].dayPhone) {
          if (usersData[i].dayPhone.includes(searchValue.toLowerCase())) {
            if (this.verifyUserType(usersData[i])) {
              updateUsers.push(this.verifyUserType(usersData[i]));
            }
          }
        } else if (usersData[i].nightPhone) {
          if (usersData[i].nightPhone.includes(searchValue.toLowerCase())) {
            if (this.verifyUserType(usersData[i])) {
              updateUsers.push(this.verifyUserType(usersData[i]));
            }
          }
        } else if (usersData[i].email) {
          if (usersData[i].email.includes(searchValue.toLowerCase())) {
            if (this.verifyUserType(usersData[i])) {
              updateUsers.push(this.verifyUserType(usersData[i]));
            }
          }
        }
      }
    }
    return updateUsers;
  }

  handleSearchUsers = (event) => {
    const { usersData, searchValue } = this.state;
    if (searchValue !== '' && (event.keyCode === 13 || event.type === 'click')) {
      this.setState({
        usersFound: this.searchUsers(usersData, searchValue),
      });
    }
  }


  render() {
    const { usersFound, openCheckout } = this.state;
    const {
      handleSelectedUser, handleAddNewGuest, dailyCheckIn, userData,
    } = this.props;
    return (
      <Grid item xs={12}>
        <Capacity
          handleViewUsers={() => { handleSelectedUser(); this.setState({ usersFound: false }); }}
          showBackButton={usersFound}
          dailyCheckIn={dailyCheckIn}
          userData={userData}
        />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Box className="covid19-main-wrapper" onKeyDown={this.handleSearchUsers}>
            <SearchBox
              handleSearchUsers={this.handleSearchUsers}
              usersFound={usersFound}
              handleSearch={(value) => this.setState({ searchValue: value.target.value })}
              handleAddNewGuest={handleAddNewGuest}
              handleCheckout={() => this.setState({ openCheckout: true })}
            />
            { (usersFound) && (
              usersFound.map((item) => (
                <UserFound
                  key={item._id}
                  userData={item}
                  handleSelectedUser={handleSelectedUser}
                />
              ))
            )}
          </Box>
        </Grid>
        <Checkout open={openCheckout} close={() => this.setState({ openCheckout: false })} dailyCheckIn={dailyCheckIn} />
      </Grid>
    );
  }
}


SearchUsers.propTypes = {
  handleSelectedUser: PropTypes.func.isRequired,
  handleAddNewGuest: PropTypes.func.isRequired,
  dailyCheckIn: PropTypes.shape({
    availableSpots: PropTypes.number,
  }).isRequired,
  userData: PropTypes.shape({
    userType: PropTypes.string,
  }).isRequired,
};
