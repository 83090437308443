import React from 'react';
import PropTypes from 'prop-types';
import { CONVERTTIME } from '../../../../utils/dateController';
import '../../../../css/registrationList.css';

function createData(
  membershipID, firstName, lastName, email, phone, registrationDate, paymentStatus,
) {
  return {
    membershipID, firstName, lastName, email, phone, registrationDate, paymentStatus,
  };
}
export default function RegistrationTableExport({
  registrationData,
  sortStatus,
  sortSearch,
}) {
  let data = [];
  for (let i = 0; i < registrationData.length; i++) {
    if (sortStatus.length > 0) {
      // eslint-disable-next-line
            sortStatus.map(status=>{
        if (status.toLowerCase() === registrationData[i].paymentStatus.toLowerCase()) {
          if (sortSearch) {
            if (registrationData[i].membershipData.familyMembersInformation[0].firstName.toLowerCase().includes(sortSearch.toLowerCase())
                    || registrationData[i].membershipData.familyMembersInformation[0].lastName.toLowerCase().includes(sortSearch.toLowerCase())
                    || registrationData[i].email.toLowerCase().includes(sortSearch.toLowerCase())) {
              data.push(createData(
                registrationData[i].membershipID,
                registrationData[i].membershipData.familyMembersInformation[0].firstName,
                registrationData[i].membershipData.familyMembersInformation[0].lastName,
                registrationData[i].membershipData.email,
                registrationData[i].phone,
                CONVERTTIME(registrationData[i].registrationDate, true),
                registrationData[i].paymentStatus,
              ));
            }
          } else {
            data.push(createData(
              registrationData[i].membershipID,
              registrationData[i].membershipData.familyMembersInformation[0].firstName,
              registrationData[i].membershipData.familyMembersInformation[0].lastName,
              registrationData[i].membershipData.email,
              registrationData[i].phone,
              CONVERTTIME(registrationData[i].registrationDate, true),
              registrationData[i].paymentStatus,
            ));
          }
        }
      });
    } else if (sortSearch) {
      if (registrationData[i].membershipData.familyMembersInformation[0].firstName.toLowerCase().includes(sortSearch.toLowerCase())
                || registrationData[i].membershipData.familyMembersInformation[0].lastName.toLowerCase().includes(sortSearch.toLowerCase())
                || registrationData[i].membershipData.email.toLowerCase().includes(sortSearch.toLowerCase())) {
        data.push(createData(
          registrationData[i].membershipID,
          registrationData[i].membershipData.familyMembersInformation[0].firstName,
          registrationData[i].membershipData.familyMembersInformation[0].lastName,
          registrationData[i].membershipData.email,
          registrationData[i].phone,
          CONVERTTIME(registrationData[i].registrationDate, true),
          registrationData[i].paymentStatus,
        ));
      }
    } else {
      data.push(createData(
        registrationData[i].membershipID,
        registrationData[i].membershipData.familyMembersInformation[0].firstName,
        registrationData[i].membershipData.familyMembersInformation[0].lastName,
        registrationData[i].membershipData.email,
        registrationData[i].phone,
        CONVERTTIME(registrationData[i].registrationDate, true),
        registrationData[i].paymentStatus,
      ));
    }
  }

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Membership ID</th>
            <th scope="col">First Name</th>
            <th scope="col">Last Name</th>
            <th scope="col">Email</th>
            <th scope="col">Phone</th>
            <th scope="col">Registration Date</th>
            <th scope="col">paymentStatus</th>
          </tr>
        </thead>
        <tbody>


          {data.map((value, index) => (
            <tr key={index}>
              <td>
                {value.membershipID}
              </td>
              <td>
                {value.firstName}
              </td>
              <td>
                {value.lastName}
              </td>
              <td>
                {value.email}
              </td>
              <td>
                {value.phone}
              </td>
              <td>
                {CONVERTTIME(value.registrationDate, true)}
              </td>
              <td>
                {value.paymentStatus}
              </td>
            </tr>
          ))}

        </tbody>
      </table>
    </div>
  );
}

RegistrationTableExport.propTypes = {
  sortStatus: PropTypes.arrayOf(PropTypes.string).isRequired,
  sortSearch: PropTypes.PropTypes.string.isRequired,
  registrationData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      familyMembers: PropTypes.arrayOf(PropTypes.shape({
        phone: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        membershipID: PropTypes.string,
      })),
    }),
  ]).isRequired,
};
