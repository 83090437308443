import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import CheckInHeader from './checkInHeader';
import ResultMessage from './resultMessage';

function CheckInResults({ selectedUser, screeningResult, handleBack }) {
  return (
    <Grid item xs={12} className="root covid19-wrapper">
      <Grid container>
        <CheckInHeader selectedUser={selectedUser} />
        <ResultMessage
          handleBack={handleBack}
          screeningResult={screeningResult}
          selectedUser={selectedUser}
        />
      </Grid>
    </Grid>
  );
}


CheckInResults.propTypes = {
  selectedUser: PropTypes.shape({
    userType: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  screeningResult: PropTypes.bool.isRequired,
  handleBack: PropTypes.func.isRequired,
};

export default CheckInResults;
