import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import '../../../css/settings.css';
import {
  searchUsers,
  pushUsersResults,
} from './listFunctions';


const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
}));

export default function UsersTable({
  data,
  sortTypes,
  sortSearch,
  handleEdit,
}) {
  const rows = [];


  for (let i = 0; i < data.length; i++) {
    if (data[i].userType !== 'Member' && data[i].userType !== 'provisory member') {
      if (sortTypes.length > 0) {
        sortTypes.map((type) => {
          if (data[i].userType.toLowerCase() === type.toLowerCase()) {
            if (sortSearch) {
              if (searchUsers(data[i], sortSearch)) {
                pushUsersResults(rows, data[i], i);
              }
            } else {
              pushUsersResults(rows, data[i], i);
            }
          }
          return '';
        });
      } else if (sortSearch) {
        if (searchUsers(data[i], sortSearch)) {
          pushUsersResults(rows, data[i], i);
        }
      } else {
        pushUsersResults(rows, data[i], i);
      }
  }
  }


  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <Grid className="font-family-default">
      <Paper className={classes.paper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}
        >
          <TableHead className="memberList-table-header">
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Day Phone</TableCell>
              <TableCell>Night Phone</TableCell>
              <TableCell>User Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    onClick={() => handleEdit(row.id, row.index)}
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    className="settings-selectRows"
                  >
                    <TableCell component="th" id={labelId} scope="row" padding="none" className="table-cell text-capitalize">
                      {row.firstName}
                    </TableCell>
                    <TableCell align="left" className="text-capitalize">{row.lastName}</TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">{row.dayPhone}</TableCell>
                    <TableCell align="left">{row.nightPhone}</TableCell>
                    <TableCell align="left">{row.type}</TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }} />
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </Grid>
  );
}


UsersTable.propTypes = {
  handleEdit: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  sortTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  sortSearch: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
};
