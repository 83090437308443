import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { Grid } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';
import Header from './layout/header';
import Sidebar from './layout/sideBar';
import AssignRoutes from './routes/assignRoutes';
import { authenticationAPI } from '../API/API-authentication';
import { membershipAPI } from '../API/API-membership';

function ScccPortal(props) {
  const [userData, setUserData] = React.useState(false);

  // TODO: NEEDS TO REFACTOR IT AND INSTEAD ON MEMBER TYPE BE EXPIRED WE WILL MOVE IT TO STATUS
  const typeOfMember = (data) => {
    let userStatus;
    const membershipDate = new Date(data.expirationDate);
    const membershipYear = membershipDate.getFullYear();
    const membershipMonth = membershipDate.getMonth();
    const membershipDay = membershipDate.getUTCDate();
    if (membershipYear <= new Date().getFullYear() && membershipMonth <= new Date().getMonth() && membershipDay < new Date().getUTCDate()) {
      userStatus = 'Expired';
    } else if (data.status === 'Unverified') {
      userStatus = 'Unverified';
    } else if (data === false) {
      return false;
    } else {
      userStatus = 'Active';
    }
    const userToSave = {
      membershipID: data.membershipID,
      userType: data.userType,
      userStatus,
      id: data._id,
      email: data.email,
    };
    Cookies.set('userInfo', JSON.stringify(userToSave));
    return userStatus;
  };

  const addMembershipInformation = async (data) => {
    await membershipAPI.GET.getMembershipByID(data.membershipID)
      .then((success) => {
        const copyData = data;
        copyData.membership = success.data;
        setUserData(copyData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateUser =  React.useCallback(async(data) => { 
    if (data.userType !== 'Member' && data.userType === false && data.userType !== 'provisory member') {
      return props.history.push('/adminPortal');
    }

    if (data.userType !== 'provisory member') {
      await addMembershipInformation(data);
      await typeOfMember(data);
    } else {
      const userToSave = {
        membershipID: data.membershipID,
        userType: data.userType,
        id: data._id,
        email: data.email,
      };
      setUserData(userToSave);
      Cookies.set('userInfo', JSON.stringify(userToSave));
    }
  },[])

  const redirectUserToPublicPortal = React.useCallback(() => {
    setUserData({ userType: 'public' });
    typeOfMember(false);
    Cookies.set('userInfo', {});
  },[])

  React.useEffect(() => {
    async function fetchData() {
      await authenticationAPI.authentication
        .getUser(Cookies.get('token'))
        .then((success) => {
          validateUser(success.data);
        })
        .catch(() => {
          redirectUserToPublicPortal();
        });
    }
    fetchData();
  }, [redirectUserToPublicPortal, validateUser]);

  return (
    <Grid>
      <BrowserRouter>
        <Header userData={userData} />
        <Grid container>
          <Sidebar userData={userData} />
          <Grid item xs>
            {AssignRoutes(userData)}
          </Grid>
        </Grid>
      </BrowserRouter>
    </Grid>
  );
}

ScccPortal.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default ScccPortal;
