import axios from 'axios';
import baseURL from './baseURL';
import TOKEN from './Frontend-Token';

export const ProgramsWaitingListAPI = {
  GET: {
    getOneWaitingList(programID) {
      return axios.get(`${baseURL}api/getOneWaitingList/${programID}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
  },
};

export default ProgramsWaitingListAPI;
