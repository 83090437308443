import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  PieChart, Pie, Cell, ResponsiveContainer,
} from 'recharts';


const COLORS = ['#2a9d8f', '#e9c46a', '#414158', '#e76f51', '#3F69AA'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
           {(percent * 100).toFixed(0) > 1 && (
        `${(percent * 100).toFixed(0)}%`
      )}
    </text>
  );
};

export default class DemographicPie extends PureComponent {
  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/3Leoa7f4/';

  render() {
    const {
      adult,
      youth,
      senior,
      child,
      family,
    } = this.props;
    const data = [
      { name: 'child', value: child },
      { name: 'youth', value: youth },
      { name: 'adult', value: adult },
      { name: 'senior', value: senior },
      { name: 'family', value: family },
    ];
    return (
      <ResponsiveContainer width="100%" height={300}>
        <PieChart onMouseEnter={this.onPieEnter}>
          <Pie
            data={data}
            cx={window.innerWidth > 800 ? 180 : 140}
            cy={160}
            innerRadius={30}
            outerRadius={130}
            fill="#8884d8"
            paddingAngle={3}
            dataKey="value"
            labelLine={false}
            label={renderCustomizedLabel}
          >
            {
              data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
            }
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  }
}

DemographicPie.propTypes = {
  adult: PropTypes.number.isRequired,
  youth: PropTypes.number.isRequired,
  senior: PropTypes.number.isRequired,
  child: PropTypes.number.isRequired,
  family: PropTypes.number.isRequired,
};
