import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

/* < -------------------------------   Reports routes  ------------------------------------- > */
import Reports from '../reports/reports';
// Taxes
import TaxSubmissionList from '../reports/taxes/taxSubmissionList/taxSubmissionList';
import ViewTaxSubmission from '../reports/taxes/viewTaxSubmission/viewTaxSubmission';
import EditTaxSubmission from '../reports/taxes/editTaxSubmission/editTaxSubmission';

// settings
import ProfileSettings from '../profile/profile';

const TaxVolunteerRoutes = function (userData) {
  return (
    <Switch>
      <Route
        path="/adminPortal/reports/taxes/edit/:formID"
        render={(props) => <EditTaxSubmission {...props} userData={userData} />}
      />
      <Route
        path="/adminPortal/reports/taxes/view/:formID/:tabNumber?"
        render={(props) => <ViewTaxSubmission {...props} userData={userData} />}
      />
      <Route path="/adminPortal/reports/taxes" render={(props) => <TaxSubmissionList {...props} userData={userData} />} />
      <Route path="/adminPortal/reports" render={(props) => <Reports {...props} userData={userData} />} />

      {/* Profile */}
      <Route path="/adminPortal/profile" render={(props) => <ProfileSettings {...props} userData={userData} />} />

      <Route path="/adminPortal/*" exact component={Reports} />
    </Switch>
  );
};

export default TaxVolunteerRoutes;

TaxVolunteerRoutes.propTypes = {
  userData: PropTypes.shape({
    userType: PropTypes.string,
  }).isRequired,
};
