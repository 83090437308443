/* eslint-disable no-lonely-if */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableRow from '@material-ui/core/TableRow';
import '../../../../../css/receipts.css';
import Btn from '../../../../../materialUi/btn';

function createData(userID, firstName, lastName, email, phone, id) {
  return {
    userID,
    firstName,
    lastName,
    email,
    phone,
    id,
  };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

// columns titles
const headRows = [
  {
    id: 'userID',
    numeric: true,
    disablePadding: true,
    label: 'variable',
  },
  {
    id: 'firstName',
    numeric: true,
    disablePadding: false,
    label: 'First Name',
  },
  {
    id: 'lastName',
    numeric: true,
    disablePadding: false,
    label: 'Last Name',
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'phone',
    numeric: true,
    disablePadding: false,
    label: 'Phone',
  },
  {
    id: 'id',
    numeric: true,
    disablePadding: false,
    label: '',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, selectedData } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="bg-light">
      <TableRow>
        {headRows.map((row, index) => (
          <TableCell
            key={index}
            align={row.numeric ? 'center' : 'left'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.firstName ? order : false}
            className="table-cell"
          >
            <TableSortLabel active={orderBy === row.firstName} direction={order} onClick={createSortHandler(row.id)}>
              {row.label === 'variable' ? (selectedData === 'membersData' ? 'Membership ID' : selectedData === 'guestsData' ? 'Guest ID' : 'Staff ID') : row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

export default function DailyCheckInTable({ data, handleClick, selectedData, searchItem }) {
  const rows = [];

  const sortSearchTextField = (userData) =>
    userData.firstName.toLowerCase().includes(searchItem.toLowerCase()) ||
    userData.lastName.toLowerCase().includes(searchItem.toLowerCase()) ||
    (userData.phone || userData.dayPhone).toLowerCase().includes(searchItem.toLowerCase());

  if (data.length > 0) {
    if (data[0][selectedData].length > 0) {
      for (let i = 0; i < data[0][selectedData].length; i++) {
        const userData = data[0][selectedData];
        if (searchItem) {
          if (sortSearchTextField(userData[i])) {
            rows.push(
              createData(
                userData[i].membershipID || userData[i].guestID || userData[i]._id,
                userData[i].firstName,
                userData[i].lastName,
                userData[i].email,
                userData[i].phone || userData[i].dayPhone,
                userData[i]._id
              )
            );
          }
        } else {
          rows.push(
            createData(
              userData[i].membershipID || userData[i].guestID || userData[i]._id,
              userData[i].firstName,
              userData[i].lastName,
              userData[i].email,
              userData[i].phone || userData[i].dayPhone,
              userData[i]._id
            )
          );
        }
      }
    }
  }

  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('firstName');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  return (
    <Grid className="font-family-default">
      <Grid className={classes.tableWrapper}>
        <Table className={classes.table} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length > 0 ? data[0][selectedData].length : 0}
            selectedData={selectedData}
          />
          <TableBody>
            {stableSort(rows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow hover role="button" tabIndex={1} className="table-tabSelection" key={index}>
                  <TableCell align="center" style={{ paddingRight: '45px' }}>
                    {row.userID.slice(0, 10)}
                  </TableCell>
                  <TableCell align="center" style={{ paddingRight: '45px' }} className="text-capitalize">
                    {row.firstName}
                  </TableCell>
                  <TableCell align="center" style={{ paddingRight: '45px' }} className="text-capitalize">
                    {row.lastName}
                  </TableCell>
                  <TableCell align="center" style={{ paddingRight: '45px' }}>
                    {row.email}
                  </TableCell>
                  <TableCell align="center" style={{ paddingRight: '45px' }}>
                    {row.phone}
                  </TableCell>
                  <TableCell align="center">
                    <Btn label="View" onClick={() => handleClick(row)} bgColor="#e76f50" color="white" bgColorHover="#ff8c68" colorHover="white" width={123} height={24} fontSize={14} />
                  </TableCell>
                </TableRow>
              ))}
            {emptyRows > 0 && <TableRow style={{ height: 49 * emptyRows }} />}
          </TableBody>
        </Table>
      </Grid>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Grid>
  );
}

DailyCheckInTable.propTypes = {
  data: PropTypes.shape({
    membersData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    guestsData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    staffData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  }).isRequired,
  handleClick: PropTypes.func.isRequired,
  selectedData: PropTypes.string.isRequired,
  searchItem: PropTypes.string.isRequired,
};
