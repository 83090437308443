import React from 'react';
import PropTypes from 'prop-types';
import PreLoading from '../../materialUi/preLoading';
import PublicRoutes from './publicRoutes';
import TaxUserRoutes from './taxUserRoutes';
import MemberRoutes from './memberRoutes';

const AssignRoutes = function (userData) {
  if (userData) {
    const userType = userData.userType.toLowerCase();
    if (userType === 'public') {
      return <PublicRoutes userData={userData} />;
    }
    if (userType === 'member') {
      return <MemberRoutes userData={userData} />;
    }
    if (userType === 'provisory member') {
      return <TaxUserRoutes userData={userData} />;
    }
    // if (userData.userType === 'Super Admin') {
    //   return <SuperAdminRoutes userData={userData} />;
    // }
    // if (userData.userType === 'Receptionist') {
    //   return <ReceptionistRoutes userData={userData} />;
    // }
    // if (userData.userType === 'Program Admin') {
    //   return <ProgramAdminRoutes userData={userData} />;
    // }
    // if (userData.userType === 'Membership Admin') {
    //   return <MembershipAdminRoutes userData={userData} />;
    // }
    // if (userData.userType === 'Program Staff') {
    //   return <ProgramStaffRoutes userData={userData} />;
    // }
    // if (userData.userType === 'Security') {
    //   return <SecurityRoutes userData={userData} />;
    // }
  } else {
    return (
      <div style={{ position: 'absolute', top: '50%', right: '45%' }}>
        <PreLoading />
      </div>
    );
  }
};

AssignRoutes.propTypes = {
  userData: PropTypes.shape({
    userType: PropTypes.string,
  }).isRequired,
};

export default AssignRoutes;
