import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import AddGuestHeader from './addGuestHeader';
import AddGuestForm from './addGuestForm';
import Questions from './questions';
import ActionButtons from './actionButtons';
import ResultMessage from './resultMessage';
import { validateField } from '../../../utils/fieldsValidation';
import { sendErrorMessages } from '../../../utils/supportFunctions';
import { Covid19API } from '../../../API/API-covid19';

class AddGuest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0,
      openCancelAlert: false,
      screeningResult: false,
      prescreeningError: false,
      values: {
        firstName: '',
        lastName: '',
        dayPhone: '',
        email: '',
        Q1Answer: '',
        Q2Answer: '',
        Q3Answer: '',
        Q4Answer: '',
      },
      messages: {
        firstName: '',
        lastName: '',
        dayPhone: '',
        email: '',
      },
      errors: {
        firstName: '',
        lastName: '',
        dayPhone: '',
        email: false,
      },
    };
  }

  componentDidMount() {
    const addGuestFromSession = JSON.parse(sessionStorage.getItem('addGuest'));
    const preScreeningFromSession = JSON.parse(sessionStorage.getItem('preScreening'));
    if (addGuestFromSession) {
      if (addGuestFromSession.currentStep) {
        this.setState({ currentStep: addGuestFromSession.currentStep });
        this.handleSessionValues(addGuestFromSession);
      } else {
        this.handleSessionValues(addGuestFromSession);
      }
    }
    if (preScreeningFromSession && preScreeningFromSession.validation) {
      this.setState({ currentStep: 2 });
    } else {
      sessionStorage.setItem('preScreening', JSON.stringify({ currentStep: 0, screeningResult: false }));
    }
  }

  handleQuestions = (name, value) => {
    const { values } = this.state;
    values[name] = value.target.value;
    this.setState({ values, prescreeningError: false });
  }

  handleSessionValues = (addGuestFromSession) => {
    Object.keys(addGuestFromSession).forEach((value) => {
      if (addGuestFromSession[value] !== '') {
        if (value === 'firstName') { this.handleInputs(value, addGuestFromSession[value], 'name', 'First Name'); }
        if (value === 'lastName') { this.handleInputs(value, addGuestFromSession[value], 'name', 'First Name'); }
        if (value === 'email') { this.handleInputs(value, addGuestFromSession[value], 'email'); }
        if (value === 'dayPhone') { this.handleInputs(value, addGuestFromSession[value], 'phone', false); }
      }
    });
  }

  handleInputs = (name, value, type, requirement = '') => {
    const { values, messages, errors } = this.state;
    const addGuestFromSession = JSON.parse(sessionStorage.getItem('addGuest'));
    if (validateField(value, type, requirement) === true) {
      values[name] = value;
      messages[name] = '';
      errors[name] = false;
    } else {
      values[name] = value;
      messages[name] = validateField(value, type, requirement);
      errors[name] = true;
    }
    if (addGuestFromSession) values.currentStep = addGuestFromSession.currentStep;
    sessionStorage.setItem('addGuest', JSON.stringify(values));
    this.setState({ values, messages, errors });
  }

  handleBackStep = () => {
    const { currentStep } = this.state;
    const { handleBack } = this.props;
    if (currentStep === 2 || currentStep === 0) {
      return handleBack();
    }
    return this.setState({ currentStep: currentStep - 1 });
  }

  handleFirstStep = () => {
    const addGuestFromSession = JSON.parse(sessionStorage.getItem('addGuest'));
    if (sendErrorMessages(this.state) === true) {
      addGuestFromSession.currentStep = 1;
      sessionStorage.setItem('addGuest', JSON.stringify(addGuestFromSession));
      return this.setState({ currentStep: 1 });
    }
    const { messages } = sendErrorMessages(this.state);
    const { errors } = sendErrorMessages(this.state);
    return this.setState({ messages, errors });
  }


  updateSessionValues =() => {
    const preScreeningFromSession = JSON.parse(sessionStorage.getItem('preScreening'));
    preScreeningFromSession.validation = true;
    preScreeningFromSession.currentStep = 2;
    sessionStorage.setItem('preScreening', JSON.stringify(preScreeningFromSession));
  }


  handleLiveUpdate = (values) => {
    const { dailyCheckIn } = this.props;
    Covid19API.EntranceUpdate.addNewGuest(values, dailyCheckIn);
    // Covid19API.EntranceUpdate.checkIn(dailyCheckIn, values);
  }

  handleCheckIn =(values) => {
    Covid19API.POST.addNewGuest(values).then((success) => {
      this.updateSessionValues();
      this.handleLiveUpdate(values);
      this.setState({ currentStep: 2, screeningResult: this.validateSubmission() });
    }).catch((error) => {
      console.log(error);
    });
  }

  validateSubmission = () => {
    const { values } = this.state;
    const checkScreeningError = (values.Q1Answer === '' || values.Q2Answer === '' || values.Q3Answer === '' || values.Q4Answer === '');
    if (checkScreeningError) {
      this.setState({ prescreeningError: true });
      window.scrollTo(0, 0);
      return 'fail';
    }
    const screeningResult = (values.Q1Answer === 'No' && values.Q2Answer === 'No' && values.Q3Answer === 'No' && values.Q4Answer === 'No');
    return screeningResult;
  }

  handleSecondStep = () => {
    const { dailyCheckIn } = this.props;
    const { values } = this.state;
    values.screeningResult = this.validateSubmission();
    values.dailyCheckInID = dailyCheckIn.dailyCheckInID;

    if (this.validateSubmission() !== 'fail') {
      this.handleCheckIn(values);
    }
  }

  handleSubmit = () => {
    const { handleBack } = this.props;
    const { currentStep } = this.state;
    if (currentStep === 0) {
      return this.handleFirstStep();
    }
    if (currentStep === 1) {
      return this.handleSecondStep();
    }
    return handleBack();
  }

  render() {
    const {
      currentStep, openCancelAlert, values, messages, errors, screeningResult, prescreeningError,
    } = this.state;
    const { handleBack } = this.props;
    return (
      <Grid item xs={12} className="root covid19-wrapper">
        <Grid container>
          <AddGuestHeader
            currentStep={currentStep}
            handleBack={this.handleBackStep}
          />

          {currentStep === 0 && (
            <AddGuestForm
              handleSubmit={this.handleSubmit}
              openCancelAlert={openCancelAlert}
              handleCancelAlert={(value) => this.setState({ openCancelAlert: value })}
              handleInputs={this.handleInputs}
              values={values}
              messages={messages}
              errors={errors}
            />
          )}

          {currentStep === 1 && (
            <Grid item xs={12} className="covid19-addGuest-questions">
              <Questions
                questions={values}
                handleQuestions={this.handleQuestions}
                prescreeningError={prescreeningError}
              />
              <Box className="covid19-screening-spacing mt-4">
                <ActionButtons
                  handleSubmit={this.handleSubmit}
                  openCancelAlert={openCancelAlert}
                  handleCancelAlert={(value) => this.setState({ openCancelAlert: value })}
                />
              </Box>
            </Grid>
          )}

          {currentStep === 2 && (
            <ResultMessage
              handleBack={handleBack}
              screeningResult={screeningResult}
              selectedUser={values}
            />
          )}

        </Grid>
      </Grid>
    );
  }
}

AddGuest.propTypes = {
  handleBack: PropTypes.func.isRequired,
  dailyCheckIn: PropTypes.shape({
    dailyCheckInID: PropTypes.string,
  }).isRequired,
};

export default AddGuest;
