import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid, Box } from '@material-ui/core';
import { BootstrapTextField, BootstrapSelectDropdown } from '../../../../materialUi/bootstrapInputs';

function emergencyContact({
  values,
  handleInputs,
  messages,
  errors,
}) {
  const relationship = ['Select One', 'Spouse', 'Child', 'Parent', 'Sibling', 'Grandparents', 'Grandchild', 'Relative', 'Friend', 'Other'];

  const relationshipValue = values.emergencyRelationship.charAt(0).toUpperCase() + values.emergencyRelationship.slice(1);


  return (
    <Paper className="body-boxes">
      <Grid item xs={12} className="Boxes-main-title">Emergency Contact</Grid>
      <Grid item xs={12} className="boxes-spacing">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Box className="boxes-labels">Name:</Box>
          </Grid>
          <Grid item xs>
            <BootstrapTextField
              id="emergencyName"
              type="text"
              name="emergencyName"
              value={values.emergencyName}
              handleChanges={(value) => handleInputs('emergencyName', value.target.value, 'name', 'Name')}
              message={messages.emergencyName}
              readOnly={false}
              error={errors.emergencyName}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="boxes-spacing">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Box className="boxes-labels">Home Phone:</Box>
          </Grid>
          <Grid item xs>
            <BootstrapTextField
              id="emergencyHomePhone"
              type="text"
              name="emergencyHomePhone"
              value={values.emergencyHomePhone}
              handleChanges={(value) => handleInputs('emergencyHomePhone', value.target.value, 'phone')}
              message={messages.emergencyHomePhone}
              readOnly={false}
              error={errors.emergencyHomePhone}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="boxes-spacing">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Box className="boxes-labels">Work Phone:</Box>
          </Grid>
          <Grid item xs>
            <BootstrapTextField
              id="emergencyWorkPhone"
              type="text"
              name="emergencyWorkPhone"
              value={values.emergencyWorkPhone}
              handleChanges={(value) => handleInputs('emergencyWorkPhone', value.target.value, 'phone', true)}
              message={messages.emergencyWorkPhone}
              readOnly={false}
              error={errors.emergencyWorkPhone}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="boxes-spacing">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Box className="boxes-labels">Relationship:</Box>
          </Grid>
          <Grid item xs>
            <BootstrapSelectDropdown
              value={relationshipValue}
              list={relationship}
              handleChanges={(value) => handleInputs('emergencyRelationship', value.target.value, 'dropdown')}
              message={messages.emergencyRelationship}
              error={errors.emergencyRelationship}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

emergencyContact.propTypes = {
  values: PropTypes.shape({
    emergencyName: PropTypes.string,
    emergencyHomePhone: PropTypes.string,
    emergencyWorkPhone: PropTypes.string,
    emergencyRelationship: PropTypes.string,
  }).isRequired,
  messages: PropTypes.shape({
    emergencyName: PropTypes.string,
    emergencyHomePhone: PropTypes.string,
    emergencyWorkPhone: PropTypes.string,
    emergencyRelationship: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    emergencyName: PropTypes.bool,
    emergencyHomePhone: PropTypes.bool,
    emergencyWorkPhone: PropTypes.bool,
    emergencyRelationship: PropTypes.bool,
  }).isRequired,
  handleInputs: PropTypes.func.isRequired,
};

export default emergencyContact;
