import React, { Component } from 'react';
import { Grid, withStyles, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import DefaultListItem from '@material-ui/core/ListItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CloseIcon from '@material-ui/icons/Close';
import '../../css/sideBar.css';

const ListItem = withStyles({
  root: {
    padding: '18px',
    fontWeight: 'bold',
    fontFamily: "'Work Sans', 'sans-serif'",
    color: 'white',
    fontSize: '22px',
    '&:hover': {
      background: ' #414158',
      cursor: 'pointer',
      width: '100%',
      fontWeight: 'bold',
      fontFamily: "'Work Sans', 'sans-serif'",
      color: 'white',
    },

    '&$selected': {
      background: ' #414158',
      cursor: 'pointer',
      width: '100%',
      fontWeight: 'bold',
      fontFamily: "'Work Sans', 'sans-serif'",
      color: 'white',
      fontSize: '22px',
      padding: '20px',
      '&:hover': {
        background: ' #414158',
        cursor: 'pointer',
        width: '100%',
        fontWeight: 'bold',
        fontFamily: "'Work Sans', 'sans-serif'",
        color: 'white',
      },
    },
  },
  selected: {},
})(DefaultListItem);

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: window.location.pathname,
      expandProgramsTab: false,
      expandReservationsTab: false,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
  }

  escFunction = (event) => {
    const { handleMobileMenu } = this.props;
    if (event.keyCode === 27) {
      handleMobileMenu();
    }
  };

  handleSelectedItem = (name, isSubTab) => {
    const { selectedItem, expandProgramsTab, expandReservationsTab } = this.state;
    const { handleMobileMenu } = this.props;
    if (selectedItem !== `/adminPortal/${name}`) {
      this.setState({
        selectedItem: `/adminPortal/${name}`,
        expandProgramsTab: name.includes('programs'),
        expandReservationsTab: name.includes('reservations'),
      });
    }
    if (name === 'programs/available' && !isSubTab) {
      this.setState({ expandProgramsTab: !expandProgramsTab });
    } else if (window.innerWidth < 1100) {
      handleMobileMenu();
    }
    if (name === 'reservations/list' && !isSubTab) {
      this.setState({ expandReservationsTab: !expandReservationsTab });
    } else if (window.innerWidth < 1100) {
      handleMobileMenu();
    }
    sessionStorage.clear();
  };

  render() {
    const { userData, menuClick, handleMobileMenu } = this.props;
    const { selectedItem, expandProgramsTab, expandReservationsTab } = this.state;

    let userType;
    if (userData) {
      userType = userData.userType.toLowerCase();
    }

    const validateDashboard = () => {
      if (userType === 'super admin' || userType === 'membership admin' || userType === 'program admin') {
        return true;
      }
      return false;
    };

    const validateMembership = () => {
      if (userType === 'super admin' || userType === 'membership admin' || userType === 'receptionist') {
        return true;
      }
      return false;
    };

    const validatePrograms = () => {
      if (userType === 'super admin' || userType === 'program admin' || userType === 'receptionist' || userType === 'member' || userType === 'program staff') {
        return true;
      }
      return false;
    };

    const validateAvailablePrograms = () => {
      if (userType === 'super admin' || userType === 'program admin' || userType === 'receptionist' || userType === 'program staff') {
        return true;
      }
      return false;
    };

    const validateProgramHistory = () => {
      if (userType === 'super admin' || userType === 'program admin') {
        return true;
      }
      return false;
    };

    const validateReports = () => {
      if (userType === 'super admin' || userType === 'membership admin' || userType === 'receptionist' || userType === 'tax volunteer') {
        return true;
      }
      return false;
    };

    const validateUsersList = () => {
      if (userType === 'super admin') {
        return true;
      }
      return false;
    };

    const validateCovid19 = () => {
      if (userType === 'super admin' || userType === 'security') {
        return true;
      }
      return false;
    };

    const validateTaxes = () => {
      if (userType === 'member') {
        return true;
      }
      return false;
    };

    const validateReservations = () => {
      if (userType === 'super admin' || userType === 'receptionist' || userType === 'member') {
        return true;
      }
      return false;
    };

    const validateReservationsList = () => {
      if (userType === 'super admin' || userType === 'receptionist' || userType === 'member') {
        return true;
      }
      return false;
    };

    const validateUserType = (item) => {
      if (item === 'dashboard') {
        return validateDashboard();
      }
      if (item === 'membership') {
        return validateMembership();
      }
      if (item === 'programs') {
        return validatePrograms();
      }
      if (item === 'available programs') {
        return validateAvailablePrograms();
      }
      if (item === 'program history') {
        return validateProgramHistory();
      }
      if (item === 'reports') {
        return validateReports();
      }
      if (item === 'users list') {
        return validateUsersList();
      }
      if (item === 'covid19') {
        return validateCovid19();
      }
      if (item === 'taxes') {
        return validateTaxes();
      }
      if (item === 'reservations') {
        return validateReservations();
      }
      if (item === 'reservations list') {
        return validateReservationsList();
      }
      return false;
    };

    const displayItem = (item) => {
      if (validateUserType(item)) {
        return '';
      }
      return 'hidden_item';
    };

    const displayProgramArrow = () => {
      if (userType !== 'public' && userType !== 'member' && userType !== 'provisory member' && userType !== 'program staff' && userType !== 'receptionist') {
        return true;
      }
      return false;
    };

    return (
      <Grid item xs={menuClick ? 12 : 2} className={menuClick ? 'mobileNav' : 'sidebar'}>
        {menuClick && <CloseIcon className="closeBtn" onClick={handleMobileMenu} />}
        <Link to="/adminPortal/dashboard" className={`${displayItem('dashboard')} custom_links`} onClick={() => this.handleSelectedItem('dashboard')}>
          <ListItem button selected={selectedItem.includes('dashboard')} tabIndex="-1">
            Dashboard
          </ListItem>
        </Link>
        <Link to="/adminPortal/membership" className={`${displayItem('membership')} custom_links`} onClick={() => this.handleSelectedItem('membership')}>
          <ListItem button selected={selectedItem.includes('membership')} className="col-12" tabIndex="-1">
            Membership
          </ListItem>
        </Link>
        <Link to="/adminPortal/programs/available" className={`${displayItem('programs')} custom_links`} onClick={() => this.handleSelectedItem('programs/available')}>
          <ListItem button selected={selectedItem.includes('/adminPortal/programs')} className="col-12" tabIndex="-1">
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Box>Programs</Box>
              <Box>{displayProgramArrow() === true && <Box>{!expandProgramsTab ? <ArrowDropDownIcon fontSize="large" /> : <ArrowDropUpIcon fontSize="large" />}</Box>}</Box>
            </Grid>
          </ListItem>
        </Link>
        {expandProgramsTab && (
          <>
            <Link to="/adminPortal/programs/available" className={`${displayItem('available programs')} custom_links`} onClick={() => this.handleSelectedItem('programs/available', true)}>
              <ListItem button selected={selectedItem.includes('programs/available')} className={selectedItem.includes('programs/available') ? '' : 'sideBar-subTabs'} tabIndex="-1">
                Available Program
              </ListItem>
            </Link>
            <Link to="/adminPortal/programs/history" className={`${displayItem('program history')} custom_links`} onClick={() => this.handleSelectedItem('programs/history')}>
              <ListItem button selected={selectedItem.includes('programs/history')} className={selectedItem.includes('programs/history') ? '' : 'sideBar-subTabs'} tabIndex="-1">
                History
              </ListItem>
            </Link>
          </>
        )}
        <Link to="/adminPortal/reports" className={`${displayItem('reports')} custom_links`} onClick={() => this.handleSelectedItem('reports')}>
          <ListItem button selected={selectedItem.includes('/adminPortal/reports')} className="col-12" tabIndex="-1">
            Reports
          </ListItem>
        </Link>
        <Link to="/adminPortal/settings" className={`${displayItem('users list')} custom_links`} onClick={() => this.handleSelectedItem('settings')}>
          <ListItem button selected={selectedItem.includes('/adminPortal/settings')} className="col-12" tabIndex="-1">
            Users List
          </ListItem>
        </Link>
        <Link to="/adminPortal/covid19" className={`${displayItem('covid19')} custom_links`} onClick={() => this.handleSelectedItem('covid19')}>
          <ListItem button selected={selectedItem.includes('covid19')} className="col-12" tabIndex="-1">
            Covid19 Screening
          </ListItem>
        </Link>
        {/* <Link to="/adminPortal/reservations/sessions" className={`${displayItem('reservations')} custom_links`} onClick={() => this.handleSelectedItem('reservations/sessions')}>
          <ListItem button selected={selectedItem.includes('/adminPortal/reservations')} className="col-12" tabIndex="-1">
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Box>Reservations</Box>
              <Box>{displayProgramArrow() === true && <Box>{!expandReservationsTab ? <ArrowDropDownIcon fontSize="large" /> : <ArrowDropUpIcon fontSize="large" />}</Box>}</Box>
            </Grid>
          </ListItem>
        </Link>
        {expandReservationsTab && (
          <>
            <Link to="/adminPortal/reservations/list" className={`${displayItem('reservations list')} custom_links`} onClick={() => this.handleSelectedItem('reservations/list', true)}>
              <ListItem button selected={selectedItem.includes('reservations/list')} className={selectedItem.includes('reservations/list') ? '' : 'sideBar-subTabs'} tabIndex="-1">
                Reservation List
              </ListItem>
            </Link>
          </>
        )} */}
        {/* <Link
          to="/scccPortal/taxes/questionnaire"
          onClick={() => this.handleSelectedItem('taxes')}
          style={{ textDecoration: 'none' }}
          className={`${displayItem('taxes')} custom_links`}
        >
          <ListItem
            button
            selected={selectedItem.includes('/scccPortal/taxes')}
            className="col-12 pt-1 pb-1"
            tabIndex="-1"
          >
            Personal Income Tax Form
          </ListItem>
        </Link> */}
      </Grid>
    );
  }
}

Sidebar.propTypes = {
  userData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      userType: PropTypes.string,
    }),
  ]).isRequired,
  menuClick: PropTypes.bool,
  handleMobileMenu: PropTypes.func,
};

Sidebar.defaultProps = {
  menuClick: false,
  handleMobileMenu: () => {},
};

export default Sidebar;
