import React from 'react';
import { Route, Switch } from 'react-router-dom';

// Membership registration
import MembershipRegistration from '../membership/membershipRegistration/membershipRegistration';

// Programs Routes
import ProgramsList from '../programs/programsList/programsList';
import ProgramDetails from '../programs/programDetails/programDetails';

const PublicRoutes = function (userData) {
  return (
    <Switch>
      {/* Programs */}
      <Route
        path="/scccPortal/programs/programDetails/:programID"
        render={(props) => <ProgramDetails {...props} userData={userData} />}
      />
      <Route
        path="/scccPortal/programs"
        render={(props) => <ProgramsList {...props} userData={userData} />}
      />

      {/* Membership */}
      <Route path="/scccPortal/membership" component={MembershipRegistration} />
      <Route path="*" exact component={MembershipRegistration} />
    </Switch>
  );
};

export default PublicRoutes;
