import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import Header from './components/header';
import ProgramsTable from './components/programsTable';
import TableExport from './components/tableExport';
import { ProgramsAPI } from '../../../API/API-programs';
import Alert from '../../../materialUi/Alerts';
import DeleteProgram from './components/deleteProgram';
import { CONVERTTIME } from '../../../utils/dateController';
import '../../../css/programsList.css';

export default class ProgramsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      programsData: [],
      search: '',
      ages: [],
      seasons: [],
      selectedItems: [],
      openDeleteProgram: false,
      selectedProgramID: '',
      selectedProgramName: '',
      openAlert: false,
      alertLocation: '',
      alertTitle: '',
      alertBody: '',
      alertStay: '',
      alertClose: '',
      values: {
        allAges: false,
        preSchool: false,
        children: false,
        youth: false,
        adult: false,
        senior: false,
        information: false,
        allSeasons: false,
        winter: false,
        spring: false,
        summer: false,
        fall: false,
      },
    };
  }

  componentDidMount() {
    const { userData } = this.props;
    if (userData.userData.userType === 'Program Staff') {
      ProgramsAPI.GET.getProgramByInstructorID(userData.userData._id)
        .then((success) => {
          this.setState({ programsData: success.data });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      // <--- get all programs on DB --->
      ProgramsAPI.GET.getAll()
        .then((success) => {
          this.setState({ programsData: success.data });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  handleFilter = (name, value, filterName, selectedAge) => {
    const { values, selectedItems } = this.state;
    if (value === true) {
      values[name] = value;
      this.setState({
        values,
        [filterName]: this.state[filterName].concat([selectedAge]),
        selectedItems: selectedItems.concat([
          <Box className="programsList-selectedItems" key={selectedAge} name={name} id={filterName}>
            {selectedAge}
            <i
              label="filterItems"
              className="far fa-times-circle ml-2 "
              onClick={() => this.handleFilter(name, false, filterName, selectedAge)}
              onKeyPress={() => this.handleFilter(name, false, filterName, selectedAge)}
              role="button"
              tabIndex={0}
            />
          </Box>,
        ]),
      });
    } else {
      // remove filter
      const updateArray = this.state[filterName];
      for (let i = 0; i < updateArray.length; i++) {
        if (updateArray[i] === selectedAge) {
          updateArray.splice(i, 1);
        }
      }

      // remove selectedItems
      const updateSelectedItems = this.state.selectedItems;
      for (let index = 0; index < updateSelectedItems.length; index++) {
        if (updateSelectedItems[index].key === selectedAge) {
          updateSelectedItems.splice(index, 1);
        }
      }

      values[name] = value;
      this.setState({ values, [filterName]: updateArray, selectedItems: updateSelectedItems });
    }
  };

  resetFilters = () => {
    const { values } = this.state;
    Object.keys(values).forEach((key) => {
      values[key] = false;
    });
    this.setState({
      values,
      seasons: [],
      ages: [],
      selectedItems: [],
    });
  };

  handleExport = (value) => {
    if (value === 'Print') {
      const printContent = document.getElementById('tableExport').innerHTML;
      document.body.innerHTML = printContent;
      window.print();
      window.location = '/adminPortal/programs';
    } else {
      const table = document.getElementById('tableExport').innerHTML;
      const convertTable = table
        .toString()
        .replace(/<div>/g, '')
        .toString()
        .replace(/<\/div>/g, '')
        .toString()
        .replace(/<table class="table">/g, '')
        .toString()
        .replace(/<\/table>/g, '')
        .toString()
        .replace(/<td>/g, '')
        .toString()
        .replace(/<\/td>/g, ',')
        .toString()
        .replace(/<tr>/g, '')
        .toString()
        .replace(/<\/tr>/g, '\r\n')
        .toString()
        .replace(/<th scope="col">/g, '')
        .toString()
        .replace(/<\/th>/g, ',')
        .toString()
        .replace(/<thead>/g, '')
        .toString()
        .replace(/<\/thead>/g, '')
        .toString()
        .replace(/<tbody>/g, '')
        .toString()
        .replace(/<\/tbody>/g, '')
        .toString()
        .replace(/<\/t>/g, '')
        .toString()
        .replace(/<\/n>/g, '');
      const myLink = document.createElement('a');
      myLink.download = 'programsList.csv';
      myLink.href = `data:application/csv,${escape(convertTable)}`;
      myLink.click();
    }
  };

  handleProgramSelection = (selectionObj) => {
    const { history } = this.props;
    switch (selectionObj.selectionType) {
      case 'Program Registration':
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const enrollmentEndDate = new Date(selectionObj.data.enrollmentEndDate);
        enrollmentEndDate.setHours(0, 0, 0, 0);
        const enrollmentStartDate = new Date(selectionObj.data.enrollmentStartDate);
        enrollmentStartDate.setHours(0, 0, 0, 0);
        if (enrollmentStartDate.getTime() > today.getTime()) {
          return this.setState({
            openAlert: true,
            alertLocation: `/adminPortal/programs/registrationList/${selectionObj.programID}`,
            alertTitle: 'Error',
            alertBody: `Program enrolment date starts on ${CONVERTTIME(enrollmentStartDate, true)}`,
            alertClose: 'hidden',
            alertStay: 'CLOSE',
          });
        }
        if (!selectionObj.data.dropIn && enrollmentEndDate.getTime() < today.getTime()) {
          return this.setState({
            openAlert: true,
            alertLocation: `/adminPortal/programs/registrationList/${selectionObj.programID}`,
            alertTitle: 'Error',
            alertBody: 'Program enrolment date has passed.',
            alertClose: 'hidden',
            alertStay: 'CLOSE',
          });
        }
        history.push(`/adminPortal/programs/programRegistration/${selectionObj.programID}/Available Programs`);
        break;
      case 'Program Details':
        history.push(`/adminPortal/programs/programDetails/${selectionObj.programID}`);
        break;
      case 'Edit':
        history.push({
          pathname: `/adminPortal/programs/editProgram/${selectionObj.programID}`,
          state: { from: this.props.location.pathname }
        });
        break;
      case 'Attendees':
        history.push(`/adminPortal/programs/registrationList/${selectionObj.programID}`);
        break;
        case 'Event Attendance':
        history.push(`/adminPortal/programs/createAttendance/${selectionObj.programID}`);
        break;
      case 'Delete':
        this.setState({ selectedProgramID: selectionObj.programID, selectedProgramName: selectionObj.programName, openDeleteProgram: true });
        break;
      default:
        break;
    }
  };

  render() {
    const {
      ages,
      seasons,
      values,
      selectedItems,
      programsData,
      search,
      openDeleteProgram,
      selectedProgramID,
      selectedProgramName,
      openAlert,
      alertLocation,
      alertTitle,
      alertBody,
      alertStay,
      alertClose,
    } = this.state;
    const { userData } = this.props;
    return (
      <Grid item xs className="font-family-default">
        <Header
          handleExport={this.handleExport}
          getSearch={(value) => this.setState({ search: value })}
          handleFilter={this.handleFilter}
          search={(value) => this.setState({ search: value })}
          ages={ages}
          seasons={seasons}
          values={values}
          selectedItems={selectedItems}
          resetFilters={this.resetFilters}
          userType={userData.userData.userType}
        />

        <ProgramsTable data={programsData} sortSeason={seasons} sortSearch={search} sortAges={ages} handleProgramSelection={this.handleProgramSelection} userType={userData.userData.userType} />
        <Grid className="hidden" id="tableExport">
          <TableExport data={programsData} sortSeason={seasons} sortSearch={search} sortAges={ages} />
        </Grid>

        <DeleteProgram open={openDeleteProgram} close={() => this.setState({ openDeleteProgram: false })} selectedProgramID={selectedProgramID} selectedProgramName={selectedProgramName} />
        <Alert open={openAlert} close={() => this.setState({ openAlert: false })} location={alertLocation} title={alertTitle} body={alertBody} stay={alertStay} leave={alertClose} />
      </Grid>
    );
  }
}

ProgramsList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  userData: PropTypes.shape({
    _id: PropTypes.string,
    userType: PropTypes.string,
  }).isRequired,
};
