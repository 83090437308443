import React, { Component } from 'react';
import { Grid, Box } from '@material-ui/core';
import { ProgramsAPI } from '../../../API/API-programs';
import { ProgramRegistrationAPI } from '../../../API/API-programRegistration';
import { userAPI } from '../../../API/API-user';
import {
  YEARSFILTER,
  PROGRAMREGISTRATIONS,
  PROGRAMSTOTALBYSEASON,
  PROGRAMREGISTRATIONSBYYEAR,
} from '../dashboardController';
import StatsOverview from './components/statsOverview';
import Registrations from './components/registrations';
import ProgramsTable from './components/programsTable';
// import ActiveDemographics from './components/activeDemographics';

class Programs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      programRegistrations: [],
      programsTotal: [],
      instructorsTotal: 0,
      programRegistrationsByYear: [],

      numberOfMembersPerMonth: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      selectedMonth: new Date().getFullYear(),
      membersData: '',
      programsData: [],
    };
  }

  componentDidMount() {
    // <--- get all programs on DB for the programs table --->
    ProgramsAPI.GET.getAll().then((success) => {
      this.setState({
        programsData: success.data, programsTotal: PROGRAMSTOTALBYSEASON(success.data),
      });
    }).catch((error) => {
      console.log(error);
    });

    // <--- get all programs on DB for the programs table --->
    ProgramRegistrationAPI.GET.getAllProgramRegistrations().then((success) => {
      this.setState({
        programRegistrations: PROGRAMREGISTRATIONS(success.data),
        programRegistrationsByYear: PROGRAMREGISTRATIONSBYYEAR(success.data),
      });
    }).catch((error) => {
      console.log(error);
    });

    userAPI.GET.getUsersByUserType('Program Staff').then((success) => {
      this.setState({
        instructorsTotal: success.data.length,
      });
    }).catch((error) => {
      console.log(error);
    });
  }


  handleYearSelection = (value) => {
    const { membersData } = this.state;
    const monthsCounter = YEARSFILTER(membersData, value);
    this.setState({
      numberOfMembersPerMonth: monthsCounter,
      selectedMonth: value,
    });
  }

  render() {
    const {
      programsData,
    } = this.state;

    const pastYear = new Date().getFullYear();

    return (
      <Grid item xs className="font-family-default">
        <StatsOverview
          data={this.state}
        />

        <Registrations
          data={this.state}
        />
        <Grid container className="root dashboard-tabs-spacing-newMember">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box className="dashboard-tabs-title"> Programs for winter {pastYear}</Box>
            <Box
              onClick={() => window.location = '/adminPortal/programs/available'}
              onKeyPress={() => window.location = '/adminPortal/programs/available'}
              role="button"
              tabIndex={0}
              className="dashboard-tabs-viewAll"
            >
              View All
            </Box>
          </Grid>
          <ProgramsTable
            data={programsData}
            handleProgramSelection={(programID) => window.location = `/adminPortal/programs/programDetails/${programID}`}
          />
        </Grid>

      </Grid>
    );
  }
}

export default Programs;
