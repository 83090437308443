import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { userAPI } from '../../../../API/API-user';
import ProgramStaffDropdown from './programStaffDropdown';

function SearchProgramStaff(props) {
  const {
    register,
    setValue,
    formState: { errors },
    data,
    clearErrors,
    watch,
    getValues,
  } = props;

  const [programStaffList, setProgramStaffList] = React.useState([]);

  const staffAlreadyAdded = (staff) => getValues('instructor').some((item) => item._id === staff._id);

  const addStaff = (staff) => {
    const copyStaff = getValues('instructor');
    copyStaff.push(staff);
    setValue('instructor', copyStaff);
    clearErrors('instructor');
  };

  const handleProgramStaffSelection = (event) => {
    const { value } = event.target;
    if (!staffAlreadyAdded(value)) {
      addStaff(value);
    }
  };

  const deleteStaff = (index) => {
    const copyStaff = getValues('instructor');
    copyStaff.splice(index, 1);
    setValue('instructor', copyStaff, { required: 'Instructor is required' });
  };

  const getProgramStaffList = () => {
    userAPI.GET.getUsersByUserType('Program Staff').then((success) => {
      setProgramStaffList(success.data);
    }).catch((error) => {
      console.log(error);
    });
  };

  const addInitialFormStaffData = () => {
    register('instructor', { required: 'Instructor is required' });
    watch('instructor');
    if (data.instructor) {
      setValue('instructor', data.instructor);
    } else {
      setValue('instructor', []);
    }
  };

  React.useEffect(() => {
    getProgramStaffList();
    addInitialFormStaffData();
  }, [setProgramStaffList, data]);

  return (
    <>
      <Grid item xs={12} className="addNewProgram-titles">
        Program Staff
      </Grid>
      <Grid item xs={12}>
        <ProgramStaffDropdown
          list={programStaffList}
          id="selectedStaff"
          name="selectedStaff"
          label="Search Program Staff"
          value={getValues('instructor')}
          handleChanges={handleProgramStaffSelection}
          helperText={errors.instructor ? errors.instructor.message : ''}
          error={!!errors.instructor}
        />
      </Grid>
      {getValues('instructor') && getValues('instructor').length > 0 && (
        getValues('instructor').map((staff, index) => (
          <Grid item xs={12}>
            <Grid container className="addNewProgram-programStaff-box">
              <button
                type="button"
                className="addNewProgram-box-close-icon"
                onClick={() => deleteStaff(index)}
              >
                <CloseIcon />
              </button>
              <Grid item xs={12}>
                <h3 className="addNewProgram-programStaff-box-title">
                  {staff.firstName}
                  {' '}
                  {staff.lastName}
                </h3>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    <h4 className="addNewProgram-programStaff-box-subTitle">
                    Email:
                    </h4>
                    {staff.email}
                    {' '}
                    {'|'}
                  </Grid>
                  <Grid item>
                    <h4 className="addNewProgram-programStaff-box-subTitle">
                      Day Phone Number:
                    </h4>
                    {staff.dayPhone}
                    {' '}
                    {'|'}
                  </Grid>
                  <Grid item>
                    <h4 className="addNewProgram-programStaff-box-subTitle">
                      {' '}
                      Day Phone Number:
                    </h4>
                    {staff.nightPhone}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))
      )}
    </>
  );
}

SearchProgramStaff.propTypes = {
  formState: PropTypes.shape({
    errors: PropTypes.any,
  }).isRequired,
  clearErrors: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  data: PropTypes.shape({
    instructor: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};

export default SearchProgramStaff;
