import React, { Component } from 'react';
import { membershipAPI } from '../../../API/API-membership';
import { ReceiptsAPI } from '../../../API/API-receipts';
import {
  MEMBERCATEGORY,
  MEMBERSEXPIIRATION,
  YEARSFILTER,
  NEWMEMBERSHIPS,
  FEES,
  ACTIVEMEMBERS,
} from '../dashboardController';
import StatsOverview from './components/statsOverview';
import NewMembership from './components/newMembership';
import ActiveDemographics from './components/activeDemographics';

class Membership extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMembers: 0,
      expiringMembers: 0,
      expiredMembers: 0,
      child: 0,
      youth: 0,
      adult: 0,
      senior: 0,
      family: 0,
      numberOfMembersPerMonth: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      selectedMonth: new Date().getFullYear(),
      newMemberships: 0,
      newMembershipsLastMonth: 0,
      fees: 0,
      feesLastMonth: 0,
      activeMembersThisMonth: 0,
      membersData: '',
    };
  }

  componentDidMount() {
    const { selectedMonth } = this.state;
    membershipAPI.GET.getAllMemberships().then((success) => {
      // get all member Categories
      const memberCategory = MEMBERCATEGORY(success.data);

      // get members active
      const membersStatus = MEMBERSEXPIIRATION(success.data);

      // number of members per month
      const membersPerMonth = YEARSFILTER(success.data, selectedMonth);

      // new memberships
      const newMembershipsResults = NEWMEMBERSHIPS(success.data);

      // get program registration
      const activeMembersResult = ACTIVEMEMBERS(success.data);

      this.setState({
        activeMembers: membersStatus[0],
        expiringMembers: membersStatus[1],
        expiredMembers: membersStatus[2],
        child: memberCategory[0],
        youth: memberCategory[1],
        adult: memberCategory[2],
        senior: memberCategory[3],
        family: memberCategory[4],
        numberOfMembersPerMonth: membersPerMonth,
        newMemberships: newMembershipsResults[0],
        newMembershipsLastMonth: newMembershipsResults[1],
        activeMembersThisMonth: activeMembersResult,
        membersData: success.data,
      });
    }).catch((error) => {
      console.log(error);
    });

    ReceiptsAPI.GET.getReceipts().then((success) => {
      const feesThisMonth = FEES(success.data);
      this.setState({
        fees: feesThisMonth[0],
        feesLastMonth: feesThisMonth[1],
      });
    }).catch((error) => {
      console.log(error);
    });
  }


  handleYearSelection = (value) => {
    const { membersData } = this.state;
    const monthsCounter = YEARSFILTER(membersData, value);
    this.setState({
      numberOfMembersPerMonth: monthsCounter,
      selectedMonth:value,
    });
  }

  render() {
    return (
      <>
        <StatsOverview
            data={this.state}
        />
        <NewMembership
          handleYearSelection={this.handleYearSelection}
          data={this.state}
        />
        <ActiveDemographics
          data={this.state}
        />
      </>
    );
  }
}

export default Membership;
