import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import '../../css/reports.css';

// eslint-disable-next-line react/prefer-stateless-function
export default class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { history, userData } = this.props;
    const userType = (userData) ? userData.userData.userType : 'none';
    return (
      <Grid item xs className="font-family-default">
        <Grid container>
          <Grid item xs={12} className="programDetails-spacing programDetails-bottom-box">
            <Box className={userType.toLowerCase() === 'super admin' || userType.toLowerCase() === 'receptionist' ? 'programDetails-boxes' : 'hidden'}>
              <Grid container>
                <Grid item xs={12} className="programDetails-boxes-main-title">
                  Revenue
                </Grid>
                <Grid item xs={12} md={6} />
                <Grid item xs={12}>
                  <Grid container className="programDetails-boxes-text">
                    <Grid item xs>
                      <button onClick={() => history.push('/adminPortal/reports/receipts')} type="button" className="reports-link">
                        Receipts
                      </button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box className={userType.toLowerCase() === 'super admin' || userType.toLowerCase() === 'membership admin' ? 'programDetails-boxes mt-4' : 'hidden'}>
              <Grid container>
                <Grid item xs={12} className="programDetails-boxes-main-title">
                  Memberships
                </Grid>
                <Grid item xs={12} md={6} />
                <Grid item xs={12}>
                  <Grid container className="programDetails-boxes-text">
                    <Grid item xs>
                      <button onClick={() => history.push('/adminPortal/reports/membersReport')} type="button" className="reports-link">
                        Members Report
                      </button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box className={userType.toLowerCase() === 'super admin' || userType.toLowerCase() === 'receptionist' ? 'programDetails-boxes mt-4' : 'hidden'}>
              <Grid container>
                <Grid item xs={12} className="programDetails-boxes-main-title">
                  COVID19
                </Grid>
                <Grid item xs={12} md={6} />
                <Grid item xs={12}>
                  <Grid container className="programDetails-boxes-text">
                    <Grid item xs>
                      <button onClick={() => history.push('/adminPortal/reports/checkInHistory')} type="button" className="reports-link">
                        Check-in History
                      </button>
                    </Grid>
                    <Grid item xs>
                      <button onClick={() => history.push('/adminPortal/reports/dailyCheckInList')} type="button" className="reports-link">
                        Daily Check-in
                      </button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box className={userType.toLowerCase() === 'super admin' || userType.toLowerCase() === 'tax volunteer' ? 'programDetails-boxes mt-4' : 'hidden'}>
              <Grid container>
                <Grid item xs={12} className="programDetails-boxes-main-title">
                  Personal Income Tax Forms
                </Grid>
                <Grid item xs={12} md={6} />
                <Grid item xs={12}>
                  <Grid container className="programDetails-boxes-text">
                    <Grid item xs>
                      <button onClick={() => history.push('/adminPortal/reports/taxes')} type="button" className="reports-link">
                      Tax Questionnaire
                      </button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

Reports.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  userData: PropTypes.shape({
    userData: PropTypes.object,
  }).isRequired,
};
