import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, useMediaQuery } from '@material-ui/core';
import ViewFilesTable from './viewFilesTable';
import BackButton from '../../../materialUi/backButton';
import Btn from '../../../materialUi/btn';
import ConfirmSubmission from './confirmSubmission';
import DeclineSubmission from './declineSubmission';
import ViewDocumentDialog from './viewDocumentDialog';
import { userAPI } from '../../../API/API-user';
import { taxReturnAPI } from '../../../API/API-taxReturn';

function ViewDocuments({ data, handleBack, updateData }) {
  const mobile = useMediaQuery('(max-width:460px)');
  const [confirmationDialog, setConfirmationDialog] = React.useState(false);
  const [declineDialog, setDeclineDialog] = React.useState(false);
  const [taxVolunteerName, setTaxVolunteerName] = React.useState('');
  const [openViewDocuments, setOpenViewDocuments] = React.useState(false);
  const [selectedDocument, setSelectedDocument] = React.useState();

  const updateOneTaxReturn = (formData) => {
    taxReturnAPI.PUT.updateOneTaxReturn(formData).then((response) => {
      console.log(response);
      updateData();
    }).catch((error) => {
      console.log(error);
    });
  };


  const getVolunteer = () => {
    userAPI.GET.getUserByID(data.taxVolunteer).then((response) => {
      setTaxVolunteerName(`${response.data.firstName} ${response.data.lastName}`);
    }).catch((error) => {
      console.log(error);
    });
  };

  React.useEffect(() => {
    getVolunteer();
  }, []);

  const handleViewDocument = (selectedDoc) => {
    if (selectedDoc.fileType !== '.png'
    && selectedDoc.fileType !== '.jpg'
    && selectedDoc.fileType !== '.jpeg'
    ) {
      window.location = selectedDoc.filePath;
    } else {
      setSelectedDocument(selectedDoc);
      setOpenViewDocuments(true);
    }
  };

  const handleConfirmSubmission = (confirmationData) => {
    let copyData = data;
    copyData = { ...data, ...confirmationData };
    copyData.status = 'approved';
    setConfirmationDialog(false);
    updateOneTaxReturn(copyData);
  };

  const handleDeclineSubmission = (declineData) => {
    let copyData = data;
    copyData = { ...data, ...declineData };
    copyData.status = 'declined';
    setDeclineDialog(false);
    updateOneTaxReturn(copyData);
  };

  return (
    <div>
      {data && (
        <>
          <Grid container className="questionnaire-wrapper">
            <Grid item xs={12}>
              <BackButton
                handleClick={handleBack}
                label="Back"
              />
            </Grid>
            <Grid item xs={12}>
              <Grid 
                container 
                direction="row" 
                justifyContent="space-between" 
                alignItems="center" 
                className="taxes-view-docs-header"
              >
                <Box>
                  <h1 className="taxes-title mt-4 mb-3">
                    Document Received
                    {data.status === 'pending' && (
                    <span className="taxes-confirm-submission-status">
                      Awaiting Confirmation
                    </span>
                    )}
                  </h1>
                </Box>
                {data.status === 'pending' && (
                <Box>
                  <Grid 
                    container 
                    direction="row" 
                    justify={mobile ? 'center' : 'flex-start'} 
                    alignItems="center" 
                    spacing={1}
                  >
                    <Grid item>
                      <Btn
                        label="CONFIRM SUBMISSION"
                        bgColor="white"
                        color="#e76f50"
                        colorHover="white"
                        bgColorHover="#e76f50"
                        variant="outlined"
                        width={210}
                        height={36}
                        fontSize={16}
                        onClick={() => setConfirmationDialog(true)}
                        type="button"
                      />
                    </Grid>
                    <Grid item>
                      <Btn
                        label="DECLINE SUBMISSION"
                        bgColor="#e76f50"
                        color="white"
                        colorHover="white"
                        bgColorHover="#ff8c68"
                        width={210}
                        height={36}
                        fontSize={16}
                        onClick={() => setDeclineDialog(true)}
                        type="button"
                      />
                    </Grid>
                  </Grid>
                </Box>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} className="taxes-submission-message">
            Please review and ensure that all information from documents received are correct.
             If information is correct, please click “confirm submission” to confirm.
            </Grid>
          </Grid>
          <ViewFilesTable
            data={data.adminDocuments}
            handleViewDocument={handleViewDocument}
          />
          <ConfirmSubmission
            open={confirmationDialog}
            handleCancel={() => setConfirmationDialog(false)}
            handleConfirmSubmission={handleConfirmSubmission}
            taxVolunteerName={taxVolunteerName}
          />
          <DeclineSubmission
            open={declineDialog}
            handleCancel={() => setDeclineDialog(false)}
            handleDeclineSubmission={handleDeclineSubmission}
          />
          <ViewDocumentDialog
            open={openViewDocuments}
            setOpen={() => setOpenViewDocuments(false)}
            document={selectedDocument}
          />
        </>
      )}
    </div>
  );
}

ViewDocuments.propTypes = {
  handleBack: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    adminDocuments: PropTypes.arrayOf(PropTypes.any),
  })).isRequired,
};

export default ViewDocuments;
