import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import Checkbox from '../../../../materialUi/checkBox';
import SearchTextField from '../../../../materialUi/searchTextField';
import '../../../../css/programsList.css';

export default function Filter({
  search, values, handleFilter,
}) {
  return (
    <Grid item xs={12}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Box className="memberList-searchField">
          <SearchTextField
            label="Search Member"
            search={(value) => search(value.target.value)}
            width={200}
          />
        </Box>
        <div className="dropdown memberList-btn-filter">
          <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span className="memberList-status-dropdown">Status</span>
          </button>
          <div className="dropdown-menu memberList-item-dropdown" aria-labelledby="dropdownMenuButton">
            <span className="dropdown-item">
                <Checkbox
                  fontSize={15} 
                  bottom={0} 
                  value={values.active} 
                  click={(value) => handleFilter('active', value, 'Active')} 
                  message="Active" 
                />
            </span>
            <span className="dropdown-item">
              <Checkbox 
                fontSize={15}
                bottom={0} 
                value={values.unverified} 
                click={(value) => handleFilter('unverified', value, 'Unverified')} 
                message="Unverified" 
              />
            </span>
            <span className="dropdown-item"><Checkbox fontSize={15} bottom={0} value={values.expiring} click={(value) => handleFilter('expiring', value, 'Expiring')} message="Expiring" /></span>
            <span className="dropdown-item"><Checkbox fontSize={15} bottom={0} value={values.expired} click={(value) => handleFilter('expired', value, 'Expired')} message="Expired" /></span>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

Filter.propTypes = {
  values: PropTypes.shape({
    active: PropTypes.bool,
    unverified: PropTypes.bool,
    expiring: PropTypes.bool,
    expired: PropTypes.bool,
  }).isRequired,
  search: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired,
};
