import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowUpIcon from '@material-ui/icons/ArrowDropUp';

export default function MenuDropdown({ listItems, handleChanges, selectedItemLabel }) {
  const [openMenu, setOpenMenu] = React.useState(null);

  const handleClose = () => {
    setOpenMenu(null);
  };

  const handleMenuClick = (event, selectedItem) => {
    if (selectedItem === false) {
      setOpenMenu(event.currentTarget);
    } else {
      handleChanges(selectedItem);
      handleClose();
    }
  };

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={(event) => handleMenuClick(event, false)}
        className="menuDropdown-button"
      >
        {selectedItemLabel}
        {openMenu === null ? (
          <ArrowUpIcon />
        ) : (
          <ArrowDownIcon />
        )}

      </Button>
      <Menu
        id="simple-menu"
        anchorEl={openMenu}
        keepMounted
        open={Boolean(openMenu)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {listItems.map((item) => (
          <MenuItem key={item._id || item.label} onClick={(event) => handleMenuClick(event, item)}>
            {item.firstName || item.label}
            {' '}
            {(item.lastName) ? item.lastName.charAt(0) : ''}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

MenuDropdown.propTypes = {
  listItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleChanges: PropTypes.func.isRequired,
  selectedItemLabel: PropTypes.string.isRequired,
};
