import React from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import StepButtons from '../../../../materialUi/stepButtons';
import BackButton from '../../../../materialUi/backButton';
import SearchProgramStaff from './searchProgramStaff';

function ProgramStaff({
  handleNextStep, setStepBack, history, data,
}) {
  const useFormMethods = useForm({ mode: 'all' });
  const {
    handleSubmit,
  } = useFormMethods;

  const handleFormSubmit = (formData) => {
    handleNextStep(formData, 2, true);
  };

  return (
    <FormProvider {...useFormMethods}>
      <form onSubmit={handleSubmit(handleFormSubmit)} className="addNewProgram-wrapper">
        <BackButton
          handleClick={() => {
            setStepBack(1);
          }}
          label="Back to Program Schedule"
        />
        <Grid container spacing={2} style={{ paddingTop: '15px' }}>
          <SearchProgramStaff {...useFormMethods} data={data} />
        </Grid>

        {/* Action Buttons */}
        <hr className="tax_custom_hr" />
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
          <StepButtons
            location="/adminPortal/programs/available"
            handleSubmit={() => {}}
            type="submit"
            finalStep
          />
        </Grid>
      </form>
    </FormProvider>
  );
}

ProgramStaff.propTypes = {
  handleNextStep: PropTypes.func.isRequired,
  setStepBack: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  data: PropTypes.shape(
    {
      instructor: PropTypes.string,
    },
  ).isRequired,
};

export default ProgramStaff;
