import React from 'react';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { membershipAPI } from '../../../../../API/API-membership';

// <--- search for the member on DB --->
let suggestions = [];
membershipAPI.GET.getAllMemberships().then((success) => {
  suggestions = success.data;
}).catch((error) => {
  console.log(error);
});

function renderInputComponent(inputProps) {
  const {
    classes, inputRef = () => { }, ref, ...other
  } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: (node) => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
}

const renderRightName = (member, query) => {
  const inputValue = deburr(query.trim()).toLowerCase();
  const primaryMember = member.familyMembersInformation[0];
  let result = `${primaryMember.firstName} ${primaryMember.lastName}`;

  if (!result.toLowerCase().includes(inputValue)) {
    member.familyMembersInformation.map((item) => {
      const name = `${item.firstName} ${item.lastName} `
      if (name.toLowerCase()
        .includes(inputValue)) {
        result = `${item.firstName} ${item.lastName} `;
      }
    })
  }

  return result
}


function renderSuggestion(suggestion, { query, isHighlighted }) {

  const matches = match(suggestion.familyMembersInformation[0].firstName, query);
  const parts = parse(`${renderRightName(suggestion, query)} `, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part) => (
          <span key={part.text} style={{ fontWeight: part.highlight ? 500 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    </MenuItem>
  );
}

const searchFamilyMembers = (member, inputValue) => {
  const result = []
  member.familyMembersInformation.map((item) => {
    const name = `${item.firstName} ${item.lastName} `
    if (name.toLowerCase()
      .includes(inputValue)) {
      result.push(item);
    }
  })
  return result.length > 0
}

const searchByMembershipCard = (member, inputValue) => {
  if (!member.familyMembersInformation[0]) return false
  if (!member.familyMembersInformation[0].membershipCard) return false
  if (member.familyMembersInformation[0].membershipCard
    .includes(inputValue)) return true
  return false;
}

function getSuggestions(value) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;
  return inputLength === 0
    ? []
    :
    // search by first, lastName, membershipID and membershipCard
    suggestions.filter((member) => {
      let keep;

      if (member.status !== 'inactive' && member.status !== 'expired') {
        keep = (
          count < 100
          && member.familyMembersInformation[0] && searchFamilyMembers(member, inputValue))
          || (count < 100
            && searchByMembershipCard(member, inputValue)
          )
          || (count < 100
            && member.familyMembersInformation[0] && member.familyMembersInformation[0].membershipID.toLowerCase()
              .includes(inputValue));
        if (keep) {
          count += 1;
        }
      }
      return keep;
    });
}

// send the instructor found to the parent component
let memberData = '';
function getSuggestionValue(suggestion) {
  memberData = suggestion;
  return `${suggestion.familyMembersInformation[0].firstName} ${suggestion.familyMembersInformation[0].lastName} `;
}
const theme = createTheme({
  palette: {
    primary: {
      main: '#279d8f',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    height: 50,
    flexGrow: 1,
    zIndex: 100,
    position: 'relative',
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 100,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(2),
  },
  paper: {
    zIndex: 100,
  }

}));


export default function IntegrationAutosuggest(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    single: '',
    popper: '',
  });

  const [stateSuggestions, setSuggestions] = React.useState([]);

  const handleSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleChange = (name) => (event, { newValue }) => {
    setState({
      ...state,
      [name]: newValue,
    });
    // send the Program Staff data
    if (props.typeOfSearch === 'member') {
      props.handleMemberFound(memberData);
    } else {
      props.handleFamilyMemberFound(memberData);
    }

    // reset the  Program Staff data after the instructor was found
    memberData = '';
  };

  const autosuggestProps = {
    renderInputComponent,
    suggestions: stateSuggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
  };


  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            id: memberData.firstName,
            label: props.label,
            placeholder: props.label,
            value: state.single,
            name: props.label,
            onChange: handleChange('single'),
            variant: 'filled',
            helperText: props.helperText,
            error: (props.error === '') ? false : props.error,
          }}

          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}

          renderSuggestionsContainer={(options) => (
            <Paper {...options.containerProps} square className={classes.paper} style={{ overflow: 'scroll', maxHeight: '200px' }}>
              {options.children}
            </Paper>
          )}
        />
      </ThemeProvider>
    </div>
  );
}
