import React from 'react';
import Moment from 'react-moment';
import { Grid, Box, Paper } from '@material-ui/core';
import '../../../../css/payment.css';
import { TIMETOAMPM } from '../../../../utils/dateController';

function Fees({ data, programData, fee }) {
  return (
    <Grid item xs className="mb-3">
      <Paper className="payment-boxes-fees">
        <Box className="fee-title">
          {programData.weeklyProgram ? 'Program Fees' : 'Program Fee'}
        </Box>
        <hr className="fees-hr" />
        <Grid className="payment-boxes-fees-spacing">
          <Grid container>
            <Grid item xs>
              <span className="fees-labels">Total Amount</span>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              {fee && fee !== 'Free' && (<>$</>)}
              {fee}
            </Grid>

            {programData.weeklyProgram && (
              <>
                <Grid item xs={12} className="payment-weeklySchedule-title">
                  Selected Schedule:
                </Grid>
                {
                  data.selectedWeeks.map((item, index) => (
                    <Grid item xs={12} className="payment-weeklySchedule-dates">
                      <Moment date={item.startDate} format="MMM DD" />
                      -
                      <Moment date={item.endDate} format="MMM DD, YYYY" />
                      {', '}
                      {TIMETOAMPM(programData.weeklyStartTime)}
                      {' - '}
                      {TIMETOAMPM(programData.weeklyEndTime)}
                    </Grid>
                  ))
                }
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

export default Fees;
