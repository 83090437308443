import * as React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import Collapse from '@material-ui/core/Collapse';

export default function DefaultAlert({ open, severity, title, body, top, right }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: 'fit-content',
      position: 'fixed',
      right: right,
      top: top,
      zIndex: 2000,
      '& .MuiAlert-filledSuccess': {
        background: '#31D196',
        color: '#172E38',
      },
      '& .MuiAlert-message': {
        // fontFamily: theme.fontFamily.default,
        paddingBottom: '5px',
        color: '#172E38',
      },
      '& .MuiAlertTitle-root ': {
        padding: '0px',
        marginBottom: '0px',
      },
      '& > * + *': {
        marginTop: theme.spacing(2),
        right: '-10px',
      },
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Collapse in={open}>
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert severity={severity}>
            <AlertTitle>{title}</AlertTitle>
            {body}
          </Alert>
        </Stack>
      </Collapse>
    </div>
  );
}

DefaultAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  severity: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};

DefaultAlert.defaultProps = {
  top: '145px',
  right: '25px'
}

// Function to change the response value from the server
// const handleAlert = (severity, title, body) => {
//   setDefaultAlert({
//     open: true,
//     severity,
//     title,
//     body,
//   })
//   setTimeout(() => {
//     setDefaultAlert({
//       open: false,
//       severity,
//       title,
//       body,
//     })
//   }, 5000)
// }

// Calling the function
// handleAlert('success', response.data.title, response.data.body)

// Severities
// "error"
// "warning"
// "info"
// "success"
