import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Radio, RadioGroup, FormControlLabel, FormControl } from '@material-ui/core';
import { BootstrapTextField } from '../../../../../materialUi/bootstrapInputs';
import Btn from '../../../../../materialUi/btn';

export default function Submit({ data, handleRadio, handleInputs, handleSubmit, handleDone }) {
  return (
    <>
      <Grid item xs={12} className="waiting-box">
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="Notification Option"
            name="notificationOption"
            value={data.radioSelection}
            onChange={(value) => handleRadio(value.target.value)}
            row
          >
            <FormControlLabel value="email" control={<Radio className="YesNo" />} label="Email" />
            <FormControlLabel
              value="phone"
              control={<Radio className="YesNo" />}
              label="Phone number"
            />
          </RadioGroup>
        </FormControl>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="bootstrap-fix-top"
        >
          <Grid item xs={12} sm={3} className="Success-email-label waitList-email-mobile">
            {data.radioSelection === 'email' ? 'Email:' : 'Phone Number:'}
          </Grid>
          <Grid
            item
            xs
            className={`${
              data.errors.email || data.errors.phone
                ? 'bootstrapField-spacing-error'
                : 'bootstrapField-spacing'
            } `}
          >
            {data.radioSelection === 'email' ? (
              <BootstrapTextField
                id="email"
                type="text"
                name="email"
                value={data.values.email}
                handleChanges={(value) => {
                  handleInputs('email', value.target.value, 'email', true);
                }}
                message={data.messages.email}
                readOnly={false}
                error={data.errors.email}
              />
            ) : (
              <BootstrapTextField
                id="phone"
                type="text"
                name="phone"
                value={data.values.phone}
                handleChanges={(value) => {
                  handleInputs('phone', value.target.value, 'phone', 'Phone');
                }}
                message={data.messages.phone}
                readOnly={false}
                error={data.errors.phone}
              />
            )}
          </Grid>
          <Grid item xs={3} className="sendEmail-btn">
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              <Box>
                <Btn
                  label="Submit"
                  onClick={handleSubmit}
                  bgColor="#2a9d8f"
                  color="white"
                  bgColorHover="#414158"
                  colorHover="white"
                  outlineColor="#2a9d8f"
                  width={94}
                  height={45}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt-5 waiting-done-btn">
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
          <Box>
            <Btn
              label="Done"
              onClick={handleDone}
              bgColor="#E76F51"
              color="white"
              bgColorHover="#E76F51"
              colorHover="white"
              width={128}
              height={47}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

Submit.propTypes = {
  data: PropTypes.shape({
    values: PropTypes.shape({
      phone: PropTypes.string,
      email: PropTypes.string,
    }),
    messages: PropTypes.shape({
      phone: PropTypes.string,
      email: PropTypes.string,
    }),
    errors: PropTypes.shape({
      phone: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      email: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    }),
    radioSelection: PropTypes.string,
  }).isRequired,
  handleRadio: PropTypes.func.isRequired,
  handleInputs: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleDone: PropTypes.func.isRequired,
};
