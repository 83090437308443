import React, {useState} from 'react';
import PropTypes from 'prop-types';
import AdminSubmissionList from './admin/adminSubmission';
import VolunteerSubmissionList from './volunteer/volunteerSubmission';
import { taxReturnAPI } from '../../../../API/API-taxReturn';
import './taxSubmissionList.css';

function TaxSubmissionList({ userData }) {
  const { userType } = userData.userData;
  const [data, setData] = React.useState([]);
  const [reports, setReports] = useState();
  const [filteredReports, setFilteredReports] = useState([]);
  const [searchInput, setSearchInput] = useState();
  const [noData, setNoData] = useState(false);


  const getAllReturnFiles = () => {
    taxReturnAPI.GET.getAllLastYearTaxReturn().then((response) => {
      setData(response.data);
      setReports(response.data);
      setFilteredReports(response.data);
      if (response.data.length === 0) setNoData(true);
    }).catch((error) => {
      console.log(error);
    });
  };

  const [filter, setFilter] = useState({
    search: '',
    status: undefined,
    isap: undefined,
    date: undefined
  });


  const handleFilter = (name, value) => {
    filter[name] = value;
    setFilter(oldObject => ({
      ...oldObject,
      [name]: value
    }))
    handleReports(filter);
  }

  const handleReports = (filter) => {
    let arrayToRender = [];
    for(let i=0; i < reports.length; i++) {
      if(filter.search != ''){
        if(searchInputField(reports[i])){
          arrayToRender.push(reports[i]);
        }
      }
      else{
        arrayToRender = reports
      }
    }
    setFilteredReports(arrayToRender);
    handleStatus(filter, arrayToRender);
  }

  const handleStatus = (filter, arrayToRender) => {
    let newArray = [];
    if(filter.status != undefined && filter.status.length != 0){
      for(let i=0; i < arrayToRender.length; i++){
        for(let j=0; j<filter.status.length; j++){
          if(filter.status[j].checked){
            if(arrayToRender[i].status === filter.status[j].value){
              newArray.push(arrayToRender[i])
            }
          }
        }
      }
      setFilteredReports(newArray);
      handleIsap(filter, newArray);
    }
    else{
      setFilteredReports(arrayToRender);
      handleIsap(filter, arrayToRender);
    }
  }

  const handleIsap = (filter, arrayToRender) => {
    let newArray=[];
    if(filter.isap != undefined && filter.isap.length != 0){
      for(let i=0; i< arrayToRender.length; i++){
        for(let j=0; j<filter.isap.length; j++){
          if(filter.isap[j].checked && filter.isap[j].value === 'eligible'){
            if(arrayToRender[i].canadaStatus.toLowerCase() === 'permanent resident' || arrayToRender[i].canadaStatus.toLowerCase() === 'protected persons' || arrayToRender[i].canadaStatus.toLowerCase() === 'convention refugee'){
              newArray.push(arrayToRender[i]);
            }
          }
          else if(filter.isap[j].checked && filter.isap[j].value === 'not eligible'){
            if(!(arrayToRender[i].canadaStatus.toLowerCase() === 'permanent resident' || arrayToRender[i].canadaStatus.toLowerCase() === 'protected persons' || arrayToRender[i].canadaStatus.toLowerCase() === 'convention refugee')){
              newArray.push(arrayToRender[i]);
            }
          }
          else{
            setFilteredReports(arrayToRender);
            handleDate(filter, arrayToRender);
          }
        }
      }
      setFilteredReports(newArray);
      handleDate(filter, newArray);
    } else {
      setFilteredReports(arrayToRender);
      handleDate(filter, arrayToRender);
    }
  }

  const handleDate = (filter, arrayToRender) => {
    let newArray=[];
     if(filter.date != undefined && filter.date.length != 0 && filter.date[0] != '' && filter.date[1] != ''){
      for(let i = 0; i < arrayToRender.length; i++){
        if(dateFilter(arrayToRender[i].createdAt, filter.date[0], filter.date[1])){
          newArray.push(arrayToRender[i]);
        }
      }
      setFilteredReports(newArray);
    } else {
      setFilteredReports(arrayToRender);
    }
  }


  const dateFilter = (compareDate, startDate, endDate) => {
    const createdAt = new Date(compareDate).toISOString();
    const start = new Date(startDate);
    start.setDate(start.getDate() - 1);
    const end = new Date(endDate).toISOString();
    if (createdAt >= start.toISOString() && createdAt <= end) {
      return true;
    }
    return false;
  };

  //search handler
  const searchInputField = (formData) => {
    const { fullName, formID, email } = formData;
    if (fullName
      .toLowerCase()
      .includes(searchInput.toLowerCase())
      || formID
        .toLowerCase()
        .includes(searchInput.toLowerCase())
      || email
        .toLowerCase()
        .includes(searchInput.toLowerCase())) {
      return true;
    }
    return false;
  };

  React.useEffect(() => {
    getAllReturnFiles();
  }, []);

  React.useEffect(() => {
    if(reports){
      handleReports(filter);
    }
  }, [filter])

  return (
    <>
      {userType === 'Super Admin' ? (
        <AdminSubmissionList
          userData={userData}
          data={data}
          reports={reports}
          filteredReports={filteredReports}
          handleUpdateData={getAllReturnFiles}
          setData={setData}
          handleFilter={handleFilter}
          setSearchInput={setSearchInput}
          searchInput={searchInput}
          noData={noData}
        />
      )
        : (
          <VolunteerSubmissionList
            userData={userData}
            data={data}
            reports={reports}
            filteredReports={filteredReports}
            handleUpdateData={getAllReturnFiles}
            setData={setData}
            handleFilter={handleFilter}
            setSearchInput={setSearchInput}
            searchInput={searchInput}
            noData={noData}
          />
        )}
    </>
  );
}

TaxSubmissionList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  userData: PropTypes.shape({
    userData: PropTypes.object,
  }).isRequired,
};

export default TaxSubmissionList;
