import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import Alert from '../../../../../materialUi/Alerts';
import SearchMemberTextField from './searchMemberTextField';
import MemberFoundBox from './memberFoundBox';

function SearchMembers(props) {
  const {
    register,
    watch,
    formState: { errors },
    getValues,
    setValue,
    data,
    userData,
    programData,
  } = props;

  const { userType, membership } = userData.userData;

  const [openAlert, setOpenAlert] = useState(false);
  const [alertContent, setAlertContent] = useState({
    alertLocation: '',
    alertTitle: 'Error',
    alertBody: '',
    alertClose: 'hidden',
    alertStay: 'CLOSE',
  });

  const alertMessage = (message) => {
    setAlertContent({
      alertLocation: '',
      alertTitle: 'Error',
      alertBody: message,
      alertClose: 'hidden',
      alertStay: 'CLOSE',
    });
    setOpenAlert(true);
  };

  const membershipTypeValidation = () => {
    const USER_MEMBERSHIP_CATEGORY = getValues('membership') && getValues('membership').membershipCategory;
    if (programData.membershipType !== 'All') {
      if (programData.membershipType === 'Child and Youth') {
        if (USER_MEMBERSHIP_CATEGORY !== 'Child' && USER_MEMBERSHIP_CATEGORY !== 'Youth') {
          alertMessage('Only Child (6-12) and Youth (13-17) membership categories can apply for this program.');
          return false;
        }
        if (programData.membershipType !== USER_MEMBERSHIP_CATEGORY) {
          alertMessage(`Only membership category ${programData.membershipType} can apply for this program.`);
          return false;
        }
      }
    }
    return true;
  };

  const handleMemberFound = (member) => {
    if (!member) return false;;
    if (membershipTypeValidation() && memberStatusValidation(member)) {
      setValue('membership', member);
    }
  };

  const deleteSelectedMember = () => {
    if (membership) return false;
    // 1- Delete the selected member - DONE
    // 2- Delete Family Members - DONE
    // 3- Delete Caregiver information
    if (getValues('familyMembers')) {
      setValue('familyMembers', null);
    }
    setValue('membership', null);

    // const { handleMemberErrors, programID } = this.props;

    // programRegistrationFromSession.member = '';
    // programRegistrationFromSession.familyMembers = '';
    // sessionStorage.setItem(`programRegistration_${programID}`, JSON.stringify(programRegistrationFromSession));
    // // set false the member error on the parente
    // handleMemberErrors('member', 'false', '');
    // // set false the familyMemberSelected error on the parente
    // handleMemberErrors('familyMemberSelected', false, '');
  };

  const isMember = () => {
    if (userType.toLowerCase() === 'member' || userType.toLowerCase() === 'public') {
      return true;
    }
    return false;
  };

  const addUserToMembership = () => {
    const copyMembership = membership;
    copyMembership.membership.familyMembersInformation = membership.familyMembersInformation;
    // TODO: we might have to validate this user, because if the user access this page
    // by input the URL, the membership is not validated.
    // For example: It will not validate if the user membership
    // category is family and the program does not allow family membership application.
    // In that case if we want to validate it, we will need to call the
    // handleMemberFound(copyMembership.membership) instead of the below function.
    return setValue('membership', copyMembership.membership);
  };

  watch('membership');

  const alertMessageLeave = (message) => {
    setAlertContent({
      alertLocation: '/scccPortal',
      alertTitle: 'Error',
      alertBody: message,
      alertClose: 'CLOSE',
      alertStay: 'hidden',
    });
    setOpenAlert(true);
  };

  const isAdminUser = () => {
    if (userData.userData.userType.toLowerCase() !== 'member') return true;
    return false;
  }

  const memberStatusValidation = (membership) => {
    const USER_STATUS = membership.status.toLowerCase();
    const EXPIRATION_DATE = new Date(membership.expirationDate);

    if (USER_STATUS === 'unverified') {
      if (isAdminUser()) {
        alertMessage('Membership is status unverified, please verify the membership first.');
      } else {
        alertMessageLeave('Please visit reception to verify your membership first.');
      }
      return false;
    }

    
    if (EXPIRATION_DATE <= new Date()) {
      if (isAdminUser(userData)) {
        alertMessage('Membership is expired, please renew the membership first.');
      } else {
        alertMessageLeave('Please visit reception to renew your membership first.');
      }
      return false;
    }

    return true;
  };

  React.useEffect(() => {
    if (userData && userData.userData.membership) {
      memberStatusValidation(userData.userData.membership.membership);
    }
    if (!data.membership) {
      register('membership', { required: 'Membership is required!' });
    } else {
      setValue('membership', data.membership);
    }
    if (isMember()) {
      return addUserToMembership();
    }
    watch('membership');
    
  }, [userData.userData.membership]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <h1 className="programRegistration-header">Program Registration Form</h1>
        <h2 className="programRegistration-titles">Membership</h2>
      </Grid>
      <Grid item xs={12}>
        {!getValues('membership') ? (
          <SearchMemberTextField
            typeOfSearch="member"
            label="Search Membership"
            handleMemberFound={handleMemberFound}
            helperText={errors.membership ? errors.membership.message : ''}
            error={!!errors.membership}
          />
        ) : (
          <MemberFoundBox selectedMember={getValues('membership')} deleteSelectedMember={deleteSelectedMember} />
        )}
      </Grid>
      <Alert
        open={openAlert}
        title={alertContent.alertTitle}
        body={alertContent.alertBody}
        stay={alertContent.alertStay}
        leave={alertContent.alertClose}
        location={alertContent.alertLocation}
        close={() => setOpenAlert(false)}
      />
    </Grid>
  );
}


export default SearchMembers;
