import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import SearchTextField from '../../../../materialUi/searchTextField';
import DatePicker from '../../../../materialUi/datePicker';
import '../../../../css/receipts.css';

export default function filter({
  handleDate,
  values,
  messages,
  handleReset,
  handleSearch,
}) {
  return (
    <Grid item xs={12} style={{ marginBottom: '10px' }}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Box className="memberAttendance-filter-searchBox">
          <SearchTextField label="Search" search={(value) => handleSearch(value.target.value)} />
        </Box>
        <Box className="memberAttendance-filter-date">
          <DatePicker
            label="Start Date"
            id="startDate"
            name="Start Date"
            value={values.startDate}
            handleChanges={(value) => handleDate('startDate', value, 'date')}
            helperText={messages.startDate}
            error={messages.startDate !== ''}
            readOnly={false}
          />
        </Box>
        <Box className="memberAttendance-filter-date-to">
                    To
        </Box>
        <Box className="memberAttendance-filter-date">
          <DatePicker
            label="End Date"
            id="endDate"
            name="End Date"
            value={values.endDate}
            handleChanges={(value) => handleDate('endDate', value, 'date')}
            helperText={messages.endDate}
            error={messages.endDate !== ''}
            readOnly={false}
            type="date"
          />
        </Box>
        <Box
          className="memberAttendance-filter-restart"
          onClick={handleReset}
          onKeyPress={handleReset}
          tabIndex={0}
          role="button"
        >
          <i className="fas fa-redo-alt" />
        </Box>
      </Grid>
    </Grid>
  );
}

filter.propTypes = {
  values: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  messages: PropTypes.shape({
    startDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    endDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
  }).isRequired,
  handleDate: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
};
