import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Typography from '@material-ui/core/Typography';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function EmailAlert({ open, handleClose, handleProceed }) {
  return (
    <div>
      <Dialog aria-labelledby="customized-dialog-title" open={open} maxWidth="sm">
        <DialogContent dividers>
          <Typography gutterBottom className="alert-header">
            <span style={{ marginRight: '5px' }}>
              <ErrorOutlineIcon color="error" />
            </span>
            Are you sure you’d like to proceed without an email?
          </Typography>
          <Typography gutterBottom className="alert-text">
            Note that you will not receive a confirmation email or
             receipt in that case. If you would like to receive a confirmation email
              and receipt, please provide an email address.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button className="alert-LEAVE" onClick={handleClose}>
              cancel
          </Button>
          <Button className="alert-STAY" onClick={handleProceed}>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

EmailAlert.propTypes = {
  handleProceed: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default EmailAlert;

/*
Implementation example:
    <Alert
      open={openAlert}
      close={() => this.setState({ openAlert: false })}
      location={alertLocation}
      title={alertTitle}
      body={alertBody}
      stay={alertStay}
      leave={alertClose}
    />
*/
