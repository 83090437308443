import React from 'react';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { taxReturnAPI } from '../../../API/API-taxReturn';
import TopContent from '../../../materialUi/taxes/questionnaire/topContent';
import Stepper from '../../../materialUi/stepper';
import StepOne from '../../../materialUi/taxes/questionnaire/stepOne/stepOne';
import StepTwo from '../../../materialUi/taxes/questionnaire/stepTwo/stepTwo';
import StepThree from '../../../materialUi/taxes/questionnaire/stepThree/stepThree';
import StepFour from '../../../materialUi/taxes/questionnaire/stepFour/stepFour';
import PreLoading from '../../../materialUi/preLoading';
import DefaultAlert from '../../../materialUi/defaultAlert';
import Success from './success';


function Questionnaire({ history }) {
  const USER_INFO = Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : { id: 'null' };
  const [step, setStep] = React.useState(0);
  const stepsList = (window.innerWidth < 600) ? ['', '', '', ''] : ['Personal Information', 'Tax Information', 'Upload Documents', 'Review/Submit'];
  const [loadData, setLoadData] = React.useState(false);
  const [data, setData] = React.useState({});

  const getTaxReturnData = () => {
    taxReturnAPI.GET.getCurrentTaxReturn(USER_INFO.id).then((response) => {
      setData(response.data);
      setStep(parseInt(response.data.currentStep, 10));
      setLoadData(true);
      // TODO: Needs to check if this year taxes was submitted
      if (response.data.status !== 'submitted' && response.data.status !== 'noSubmission') {
        history.push('/scccPortal/taxes/submissions');
      }
    }).catch((err) => {
      const { error } = err.response.data;
      if (error === 'No user ID') {
        window.location.reload();
        setLoadData(false);
      } else {
        setLoadData(true);
      }
    });
  };


  const createNewTaxReturn = (formData) => {
    taxReturnAPI.POST.addNewTaxReturn(formData, USER_INFO.id).then((response) => {
      handleAlert('success', 'Success', 'Your tax return file was saved!');
    }).catch((error) => {
      console.log(error);
      handleAlert('error', 'Server error','Server Error!');
    });
  };

  const uploadTaxFiles = (formData) => {
    taxReturnAPI.POST.uploadTaxFiles(formData.selectedFiles, USER_INFO.id).then((success) => {
      getTaxReturnData();
    }).catch((error) => {
      goBackToTheErrorStep(formData);
      handleAlert('error', 'Server error', (error.response.data) ? error.response.data : 'Server Error!');
    });
  };

  const uploadDirectDeposit = (formData) => {
    taxReturnAPI.POST.uploadDirectDepositForm(formData.selectedDepositForm, USER_INFO.id).then((success) => {
      getTaxReturnData();
    }).catch((error) => {
      console.log(error);
      goBackToTheErrorStep(formData);
      handleAlert('error', 'Server error', 'Please try again later');
    });
  };


  const [defaultAlert, setDefaultAlert] = React.useState({
    open: false,
    severity: '',
    title: '',
    body: '',
  });

  const handleAlert = (severity, title, body) => {
    console.log(severity);
    setDefaultAlert({
      open: true,
      severity,
      title,
      body,
    });
    setTimeout(() => {
      setDefaultAlert({
        open: false,
        severity,
        title,
        body,
      });
    }, 5000);
  };

  const updateOneTaxReturn = (formData) => {
    formData.userID = USER_INFO.id;
    taxReturnAPI.PUT.updateOneTaxReturn(formData).then((response) => {
      if (step <= 2) {
        if (formData.selectedFiles) {
          uploadTaxFiles(formData);
        }
        if (formData.selectedDepositForm) {
          uploadDirectDeposit(formData);
        }
      }
      handleAlert('success', 'Success', 'Your tax return file was saved!');
      getTaxReturnData();
    }).catch((error) => {
      console.log(error);
      handleAlert('error', 'Server error', 'Please try again later');
    });
  };

  const goBackToTheErrorStep = (formData) => {
    formData.currentStep -= 1;
    setStep(step);
  };

  const saveCurrentTaxReturn = (formData) => {
    updateOneTaxReturn(formData);
  };


  const handleNextStep = async (newData, newStep) => {
    let copyData = data;
    copyData = { ...data, ...newData };
    copyData.currentStep = newStep;
    if (!data.fullName) {
      setData(copyData);
      await createNewTaxReturn(copyData);
    } else {
      await saveCurrentTaxReturn(copyData);
    }
    setStep(newStep);
    if (step !== newStep) {
      setData(copyData);
    }
  };

  React.useEffect(() => {
    getTaxReturnData();
  }, []);

  if (loadData) {
    return (
      <Grid item xs className="font-family-default">
         <DefaultAlert
          open={defaultAlert.open}
          severity={defaultAlert.severity}
          title={defaultAlert.title}
          body={defaultAlert.body}
        />
        {step !== 4 && (
          <Stepper
            labels={stepsList}
            step={step}
          />
        )}
        <div className="questionnaire-wrapper">
          {step !== 3 && step !== 4 && (
          <TopContent
            step={step}
            text={stepsList[step - 1]}
            handleStep={() => setStep(step - 1)}
            disableBack={step === 0}
          />
          )}
          {step === 0 && <StepOne data={data} handleNextStep={handleNextStep} cancelRoute="/scccPortal" />}
          {step === 1 && <StepTwo data={data} handleNextStep={handleNextStep} handlePrevious={() => setStep(step -1)} />}
          {step === 2 && <StepThree data={data} handleNextStep={handleNextStep} handleAlert={handleAlert} handlePrevious={() => setStep(step -1)} cancelRoute="/scccPortal"/>}
          {step === 3 && <StepFour data={data} handleNextStep={handleNextStep} handlePrevious={() => setStep(step -1)}  cancelRoute="/scccPortal"/>}
          {step === 4 && <Success data={data} />}
        </div>
      </Grid>
    );
  }
  return (
    <div style={{ position: 'absolute', top: '50%', right: '45%' }}>
      <PreLoading />
    </div>
  );
}

Questionnaire.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(Questionnaire);
