import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

// Tax
import TaxQuestionnaire from '../taxes/questionnaire/questionnaire';
import SubmissionList from '../taxes/submissionList/submissionList';
import LockedScreen from '../taxes/lockedScreen';
import EditTaxSubmission from '../taxes/editTaxSubmission/editTaxSubmission';
import ViewUserSubmission from '../taxes/viewUserSubmission/viewUserSubmission'


// Membership registration
import MembershipRegistration from '../membership/membershipRegistration/membershipRegistration';

const MembersRoutes = function (userData) {
  if (userData !== '') {
    return (
      <Switch>
        {/* Taxes */}
        <Route 
          path="/scccPortal/taxes/view/:formID" 
          render={(props) => <ViewUserSubmission {...props} userData={userData} />} 
        />
        <Route 
          path="/scccPortal/taxes/edit/:formID" 
          render={(props) => <EditTaxSubmission {...props} userData={userData} />} 
        />
        <Route
          path="/scccPortal/taxes/questionnaire"
          render={(props) => <TaxQuestionnaire {...props} userData={userData} />}
        />
        <Route
          path="/scccPortal/taxes/submissions"
          render={(props) => <SubmissionList {...props} userData={userData} />}
        />

        {/* Membership */}
        <Route path="/scccPortal/membershipRegistration" render={(props) => <MembershipRegistration {...props} userData={userData} />} />

        <Route path="*" render={(props) => <LockedScreen {...props} userData={userData} />} />
      </Switch>
    );
  }
};

MembersRoutes.propTypes = {
  userData: PropTypes.shape({
    userType: PropTypes.string,
  }).isRequired,
};

export default MembersRoutes;
