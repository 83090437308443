import React from 'react';
import { Grid, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { withRouter } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from '../../assets/logo.svg';
import SideBar from './sideBar';
import { authenticationAPI } from '../../API/API-authentication';
import logout from '../../assets/icons/exit-to-app-24-px-1.png';
import Folder from '../../assets/icons/admin-profile.png';
import '../../css/navigation.css';

function Header(props) {
  const { userData, history } = props;
  const [menuClick, setMenuClick] = React.useState(false);
  const userType = userData && userData.userType.toLowerCase();

  const disconnectTheUser = () => {
    sessionStorage.clear();
    Cookies.remove('token');
    Cookies.remove('userInfo');
    window.location = '/';
  };

  const handleLogout = () => {
    authenticationAPI.authentication
      .logout()
      .then(() => {
        disconnectTheUser();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setActionsBasedOnUserType = () => {
    const actions = [];
    if (userType === 'Program Staff') {
      actions.push({
        name: 'Programs',
        link: '/adminPortal/programs/available',
      });
    }
    if (userType === 'Program Admin' || userType === 'Super Admin') {
      actions.push({
        name: 'Add new program',
        link: '/adminPortal/programs/addNewProgram/programInformation',
      });
    }
    if (userType === 'Receptionist' || userType === 'Super Admin' || userType === 'Membership Admin') {
      actions.push({
        name: 'Add new member',
        link: '/adminPortal/membership/register',
      });
    }
    return actions;
  };

  const quickActionOptions = setActionsBasedOnUserType();

  const handleQuickAction = (link) => {
    sessionStorage.clear();
    history.push(link)
  };

  return (
    <>
      {/* Desktop Header */}
      <Box boxShadow={1} className="navShadow header-desktop">
        <Grid container>
          <Grid item xs={6}>
            <button
              onClick={() => (window.location = '/')}
              type="button"
              className="logo-button"
            >
              <img
                src={Logo}
                alt="Sccc Logo"
                width="118px"
                height="48px"
                className="logo-main-navigation"
              />
            </button>
          </Grid>
          <Grid item xs={6} className="navTop2">
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              {userType !== 'member' && userType !== 'public' && userType !== 'provisory member' && userType !== 'security' && (
                <div className="dropdown mt-2">
                  <button
                    className="btn btn-white dropdown-toggle mr-2 admin-name"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="mr-2">Quick Actions</span>
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {quickActionOptions.map((item, index) => (
                      <button
                        className="dropdown-item header-links"
                        type="button"
                        onClick={() => handleQuickAction(item.link)}
                      >
                        <div className="logout">{item.name}</div>
                      </button>
                    ))}
                  </div>
                </div>
              )}
              <div className="dropdown mt-2 mr-2">
                {
                  userType !== 'public' && (
                    <button
                      className="btn btn-white dropdown-user-icon dropdown-toggle mr-2 admin-name"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span className="mr-2">
                        <img className="mr-2" src={Folder} alt="logo" />
                        {userData.firstName || 'Tax User'}
                      </span>
                    </button>
                  )
                }
                {
                  userType === 'public' && (
                    <button
                      className="btn btn-white admin-name"
                      type="button"
                      onClick={() => window.location = '/memberLogin'}
                    >
                      <span className="mr-1 login-public">Login </span>
                      <img
                        src={logout}
                        className="login-icon-public"
                        alt="Logo"
                      />
                    </button>
                  )
                }
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  {userType !== 'member' && userType !== 'provisory member' && (
                    <button
                      className="dropdown-item header-links"
                      type="button"
                      onClick={() => history.push('/scccPortal/profile')}
                    >
                      <div className="logout">Profile Setting</div>
                    </button>
                  )}
                  <button
                    className="dropdown-item header-links"
                    type="button"
                    onClick={() => handleLogout()}
                  >
                    <div className="d-flex justify-content-between">
                      <div className="logout">Logout</div>
                      <div>
                        <img src={logout} alt="button" />
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {/* Mobile Header */}
      <Box boxShadow={1} className="navShadow header-mobile">
        <Grid container>
          {userData && userData.userType !== 'Security' && (
            <>
              <SideBar
                userData={userData}
                menuClick={menuClick}
                handleMobileMenu={() => setMenuClick(!menuClick)}
              />
              <MenuIcon
                className="header-mobile-menu"
                fontSize="large"
                onClick={() => setMenuClick(!menuClick)}
              />
            </>
          )}
          <Grid item xs={12} className="mobile-text-center">
            <button
              onClick={() => (window.location = '/')}
              type="button"
              className="logo-button"
            >
              <img
                src={Logo}
                alt="Sccc Logo"
                width="118px"
                height="48px"
                className="logo-main-navigation"
              />
            </button>
          </Grid>
          {userType !== 'public' && (
            <Grid item xs={12} className="mobile-text-center">
              <button
                className="btn btn-white dropdown-toggle admin-name"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="mr-2">Quick Actions</span>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {userType !== 'member'
                  && quickActionOptions.map((item, index) => (
                    <button
                      className="dropdown-item header-links"
                      type="button"
                      onClick={() => handleQuickAction(item.link)}
                    >
                      <div className="logout">{item.name || 'Tax User'}</div>
                    </button>
                  ))}
                {userType !== 'member' && userType !== 'public' && userType !== 'provisory member' && (
                  <button
                    className="dropdown-item header-links"
                    type="button"
                    onClick={() => history.push('/scccPortal/profile')}
                  >
                    <div className="logout">Profile Setting</div>
                  </button>
                )}
                <button
                  className="dropdown-item header-links"
                  type="button"
                  onClick={() => handleLogout()}
                >
                  <div className="d-flex justify-content-between">
                    <div className="logout">Logout</div>
                    <div>
                      <img src={logout} alt="button" />
                    </div>
                  </div>
                </button>
              </div>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
}

export default withRouter(Header);

Header.propTypes = {
  userData: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      userType: PropTypes.string,
    }),
  ]),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

Header.defaultProps = {
  userData: {
    userType: 'public',
  },
};
