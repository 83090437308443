import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Header from './components/header';
import UserInformation from './components/userInformation';
import ChangePassword from './components/editPassword';
import ChangeDiscountPassword from './components/editDiscount';

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openEditPassword: false,
      openEditDiscount: false,
    };
  }

  handleMenu = (value) => {
    if (value === 'Change Password') {
      this.setState({ openEditPassword: true });
    } else {
      this.setState({ openEditDiscount: true });
    }
  };

  render() {
    const { userData } = this.props;
    const { openEditPassword, openEditDiscount } = this.state;
    return (
      <>
        <Grid item xs className="font-family-default">
          <Header userData={userData} handleMenu={this.handleMenu} />
          <UserInformation userData={userData} />
          <ChangePassword
            open={openEditPassword}
            close={() => this.setState({ openEditPassword: false })}
            userData={userData}
          />
          <ChangeDiscountPassword
            open={openEditDiscount}
            close={() => this.setState({ openEditDiscount: false })}
            userData={userData}
          />
        </Grid>
      </>
    );
  }
}

Profile.propTypes = {
  userData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    userType: PropTypes.string,
  }).isRequired,
};
