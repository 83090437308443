import React from 'react';
import PropTypes from 'prop-types';
import '../../../../../css/verifyAddress.css';
import { Grid, Box } from '@material-ui/core';

function MemberInfo({ membershipType }) {

  return (
    <Grid item xs className="verify-header-spacing">
      <Box className="address-verify-title">Membership Type</Box>
      <div className="address-Membership-type">
        {membershipType}
        &nbsp;
        Membership
      </div>
    </Grid>
  );
}

MemberInfo.propTypes = {
  membershipType: PropTypes.string.isRequired,
};

export default MemberInfo;
