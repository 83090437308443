import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function StatsOverview(props) {
  const {
    programRegistrations,
    programsTotal,
    instructorsTotal,
  } = props.data;


  const getPercentage = (increaseValue, originalValue) => {
    if (increaseValue === 0 && originalValue === 0) return 0;
    if (increaseValue === originalValue) return 0;
    if (increaseValue > originalValue) {
      return parseFloat(Math.round((increaseValue - originalValue) * 100)).toFixed();
    }
    if (increaseValue < originalValue) {
      return parseFloat(Math.round((increaseValue - originalValue) * 100)).toFixed();
    }
  };

  return (
    <Grid container className="root dashboard-tabs-spacing">
      <Grid item xs={6} className="dashboard-tabs-title"> Stats Overview</Grid>
      <Grid item xs={6} className="dashboard-tabs-datashow"> Showing Data from This Season </Grid>
      <Grid container spacing={3}>
        <Grid item xs>
          <Paper className="dashboard-tabs-paper">
            <Grid container>
              <Grid item xs={12} className="dashboard-tabs-box-title">
                Registrations
              </Grid>
              <Grid item xs={6} className="dashboard-tabs-box-text">
                {programRegistrations[1] ? programRegistrations[1] : 0}
              </Grid>
              <Grid item xs={6} className="dashboard-tabs-box-subText">
                {
                  (getPercentage(programRegistrations[1], programRegistrations[0]) > 0) ? (
                    <i className="fas fa-long-arrow-alt-up dashboard-tabs-box-arrowUp" />
                  ) : (
                    <i className="fas fa-long-arrow-alt-down dashboard-tabs-box-arrowDown" />
                  )
                }
                <span className={getPercentage(programRegistrations[1], programRegistrations[0]) > 0 ? 'dashboard-tabs-box-arrowUp' : 'dashboard-tabs-box-arrowDown'}>
                  {getPercentage(programRegistrations[1], programRegistrations[0])}
                  %
                </span>
              </Grid>
              <Grid item xs={12} className="dashboard-tabs-box-bottom">
                Last Season
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper className="dashboard-tabs-paper">
            <Grid container>
              <Grid item xs={12} className="dashboard-tabs-box-title">
                Programs
              </Grid>
              <Grid item xs={6} className="dashboard-tabs-box-text">
                {programsTotal[1]}
              </Grid>
              <Grid item xs={6} className="dashboard-tabs-box-subText">
                {
                  (getPercentage(programsTotal[1], programsTotal[0]) > 0) ? (
                    <i className="fas fa-long-arrow-alt-up dashboard-tabs-box-arrowUp" />
                  ) : (
                      <i className="fas fa-long-arrow-alt-down dashboard-tabs-box-arrowDown" />
                    )
                }
                <span className={getPercentage(programsTotal[1], programsTotal[0]) > 0 ? 'dashboard-tabs-box-arrowUp' : 'dashboard-tabs-box-arrowDown'}>
                  {getPercentage(programsTotal[1], programsTotal[0])}
                  %
                </span>
              </Grid>
              <Grid item xs={12} className="dashboard-tabs-box-bottom">
                Last Season
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper className="dashboard-tabs-paper" style={{ height: '150px' }}>
            <Grid container>
              <Grid item xs={12} className="dashboard-tabs-box-title">
                Instructors
              </Grid>
              <Grid item xs={6} className="dashboard-tabs-box-text">
                {instructorsTotal}
              </Grid>

            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
}

StatsOverview.propTypes = {
  data: PropTypes.shape({
    programRegistrations: PropTypes.arrayOf(PropTypes.number).isRequired,
    programsTotal: PropTypes.arrayOf(PropTypes.number).isRequired,
    instructorsTotal: PropTypes.number.isRequired,
  }).isRequired,
};
