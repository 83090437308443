import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Fees from './fees';
import PaymentMethod from './paymentMethod';
import { CardAPI } from '../../../../API/API-Card';
import { ProgramRegistrationAPI } from '../../../../API/API-programRegistration';
import Alert from '../../../../materialUi/Alerts';

function Payment({
  data, programData, handleNextStep, userData,
}) {
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertValues, setAlertValues] = React.useState({});
  const [paymentStatus, setPaymentStatus] = React.useState(false);

  const getWeeklyVariableFee = () => {
    if (data.membership.membershipType.toLowerCase() === 'associate') {
      return programData.associateFee * data.selectedWeeks.length;
    }
    return programData.communityFee * data.selectedWeeks.length;
  };

  const getWeeklyProgramFee = () => {
    if (programData.variableFee.toLowerCase() === 'yes') return getWeeklyVariableFee();
    return programData.fee * data.selectedWeeks.length;
  };

  const getRegularProgramVariableFee = () => {
    if (data.membership.membershipType.toLowerCase() === 'associate') {
      return programData.associateFee;
    }
    return programData.communityFee;
  };

  const getRegularProgramFee = () => {
    if (programData.variableFee && programData.variableFee.toLowerCase() === 'yes') return getRegularProgramVariableFee();
    return programData.fee;
  };

  const getProgramFee = () => {
    let fee = 0;
    if (programData.weeklyProgram) {
      fee = getWeeklyProgramFee();
    } else {
      fee = getRegularProgramFee()
    }
    const participants = data.familyMembers && data.familyMembers.length;
    return fee * participants;
  };

  const [calculatedFee, setCalculatedFee] = React.useState(null);

  const handleFee = (newFee) => {
    if (newFee === 'Free') return setCalculatedFee(newFee);
    return setCalculatedFee(parseFloat(newFee));
  };

  const initialFee = getProgramFee();

  const getFinalFee = () => {
    if (calculatedFee === null) {
      return initialFee
    }
    return calculatedFee === 'Free' ? 0 : calculatedFee;
  };

  const addReceiptInformation = (paymentMethod, cardType) => {
    const copyData = data;
    copyData.receipt = {
      membershipID: data.membership.membershipID,
      fee: getFinalFee(),
      paymentMethod,
      cardType,
      referenceNumber: Math.floor(100000 + Math.random() * 90000000),
      name: `${data.membership.familyMembersInformation[0].firstName} ${data.membership.familyMembersInformation[0].lastName}`,
      status: 'Approved',
      category: 'Program Registration',
      address: data.membership.address,
      paymentDate: new Date(),
      description: programData.name
    };
    return copyData;
  };

  const submitProgramRegistration = async (newData) => {
    data.fee = calculatedFee === 'Free' ? 0 : calculatedFee;
    if (!calculatedFee) {
      data.fee = initialFee
    }
    await ProgramRegistrationAPI.POST.addNewProgramRegistration(newData).then((response) => {
      console.log(response);
      handleNextStep(data, true);
      data.currentStep = 2
      sessionStorage.setItem(`programRegistration_${programData.programID}`, JSON.stringify(data));
    }).catch((error) => {
      // Send alert Message
      console.log(error);
    });
  };

  const submitCashPayment = async (type) => {
    const newData = addReceiptInformation(type, type);
    await submitProgramRegistration(newData);
  };

  const handleApprovedCardPayment = async (responseData) => {
    const cardType = responseData.result.payment.card_details.card.card_brand;
    const newData = addReceiptInformation('Card', cardType);
    newData.membership.cardType = cardType;
    newData.membership.cardInformation = responseData.result.payment;
    await submitProgramRegistration(newData);
  };

  const formateCardPaymentFee = (fee) => {
    return fee.toString().includes('.') ? fee.toString().replace('.', '') : `${fee.toString()}00`;
  };

  const submitCardPayment = async (nonce, cardHolderName) => {
    await CardAPI.Square.cardPayment(
      nonce,
      data.membership.membershipID,
      `Program Registration,  Membership: ${data.membership.membershipID}, Card Holder Name: ${cardHolderName}`,
      `${formateCardPaymentFee(calculatedFee || initialFee)}`,
    )
      .then(async (response) => {
        await handleApprovedCardPayment(response.data);
        // SetStep to show success message
      })
      .catch((error) => {
        setPaymentStatus(true);
        console.log(error);
        setOpenAlert(true);
        setAlertValues({
          alertTitle: 'PAYMENT ERROR!',
          body: 'Payment Declined. Please, check the data entered.',
          leave: 'hidden',
          stay: 'CLOSE',
        });
      });
  };

  const handleSubmitPayment = async (paymentObj) => {
    const { type, nonce, cardHolderName } = paymentObj;
    if (type === 'card') {
      await submitCardPayment(nonce, cardHolderName);
    } else {
      await submitCashPayment(type);
    }
  };

  return (
    <Grid container className="programRegistration-wrapper">
      <Grid item xs={12} className="Payment-title">
        Payment
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Fees
            data={data}
            programData={programData}
            fee={calculatedFee || initialFee}
          />
          <PaymentMethod
            submitPayment={handleSubmitPayment}
            handleFee={handleFee}
            initialFee={getProgramFee()}
            userData={userData}
            paymentStatus={paymentStatus}
          />
        </Grid>
      </Grid>
      <Alert
        open={openAlert}
        close={() => setOpenAlert(false)}
        location="/"
        title={alertValues.alertTitle}
        body={alertValues.body}
        stay={alertValues.stay}
        leave={alertValues.leave}
      />
    </Grid>
  );
}

Payment.propTypes = {
  handleNextStep: PropTypes.func.isRequired,
  userData: PropTypes.shape({
    userData: PropTypes.shape({
      userType: PropTypes.string,
    }),
  }).isRequired,
  data: PropTypes.shape(
    {
      selectedWeeks: PropTypes.arrayOf(PropTypes.any),
      membership: PropTypes.shape(
        {
          membershipCategory: PropTypes.string,
          membershipType: PropTypes.string,
          fee: PropTypes.number,
          membershipID: PropTypes.string,
          address: PropTypes.objectOf(PropTypes.any),
          familyMembersInformation: PropTypes.arrayOf(PropTypes.any),
        },
      ),
    },
  ).isRequired,
  programData: PropTypes.shape(
    {
      name: PropTypes.string,
      variableFee: PropTypes.string,
      associateFee: PropTypes.string,
      communityFee: PropTypes.string,
      fee: PropTypes.number,
      weeklyProgram: PropTypes.bool,
    },
  ).isRequired,
};

export default Payment;
