import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import SearchUsers from './components/searchUsers';
import PreScreening from './components/preScreening';
import AddGuest from './components/addGuest';
import { Covid19API } from '../../API/API-covid19';


export default class Covid19 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUser: false,
      displayResults: false,
      addNewGuest: false,
      dailyCheckIn: {
        availableSpots: 0,
        dailyCheckInID: '',
      },
    };
  }


  componentDidMount() {
    const portalRedirectionFromSession = JSON.parse(sessionStorage.getItem('portalRedirection'));
    if (portalRedirectionFromSession) {
      this.setState(portalRedirectionFromSession);
    } else {
      sessionStorage.setItem('portalRedirection', JSON.stringify(this.state));
    }
    const dateObj = new Date();
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    const newdate = `${month}-${day}-${year}`;
    Covid19API.EntranceUpdate.getInitialData();

    Covid19API.GET.getDailyCheckIn(newdate).then((success) => {
      console.log(success);
    }).catch((error) => {
      console.log(error);
      Covid19API.POST.addDailyCheckIn(newdate).then((response) => {
        console.log(response);
        Covid19API.GET.getDailyCheckIn(newdate).then((res) => {
          this.setState({
            dailyCheckIn: res.data,
          });
        }).catch((e) => {
          console.log(e);
        });
      }).catch((err) => {
        console.log(err);
      });
    });
    Covid19API.EntranceUpdate.getEntranceStatus((err, dailyCheckIn) => {
      this.setState({ dailyCheckIn });
    });
  }


  handlePortalRedirection = (action, value) => {
    const portalRedirectionFromSession = JSON.parse(sessionStorage.getItem('portalRedirection'));
    if (action === 'selectedUser') {
      this.setState({ selectedUser: value });
      portalRedirectionFromSession.selectedUser = value;
      portalRedirectionFromSession.addNewGuest = false;
    } else if (action === 'addNewGuest') {
      this.setState({ addNewGuest: value, selectedUser: false });
      portalRedirectionFromSession.addNewGuest = true;
      portalRedirectionFromSession.selectedUser = false;
    }
    sessionStorage.setItem('portalRedirection', JSON.stringify(portalRedirectionFromSession));
  }

  handleBack = () => {
    this.setState({ selectedUser: false, viewUsers: false, addNewGuest: false });
    const resetState = {
      selectedUser: false,
      displayResults: false,
      addNewGuest: false,
    };

    const dailyCheckInFromSession = JSON.parse(sessionStorage.getItem('dailyCheckIn'));
    sessionStorage.clear();
    sessionStorage.setItem('dailyCheckIn', JSON.stringify(dailyCheckInFromSession));
    sessionStorage.setItem('portalRedirection', JSON.stringify(resetState));
  }

  render() {
    const {
      selectedUser, addNewGuest, timestamp, dailyCheckIn,
    } = this.state;
    const { userData } = this.props;
    return (
      <Grid item xs className="root covid19-wrapper">
        <Grid container>
          {timestamp}
          {(!selectedUser && !addNewGuest) && (
          <SearchUsers
            handleSelectedUser={(value) => this.handlePortalRedirection('selectedUser', value)}
            handleAddNewGuest={() => this.handlePortalRedirection('addNewGuest', true)}
            dailyCheckIn={dailyCheckIn}
            userData={userData}
          />
          )}

          {selectedUser && (
          <PreScreening
            selectedUser={selectedUser}
            handleBack={this.handleBack}
            dailyCheckIn={dailyCheckIn}
          />
          )}

          {addNewGuest && (
            <AddGuest
              handleBack={this.handleBack}
              dailyCheckIn={dailyCheckIn}
            />
          )}

        </Grid>
      </Grid>
    );
  }
}


Covid19.propTypes = {
  userData: PropTypes.shape({
    userType: PropTypes.string,
  }).isRequired,
};
