import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import Btn from '../../../../../materialUi/btn';
import '../../../../../css/success.css';

function receipt({ handlePrintReceipt, handleDone }) {
  return (
    <Grid container>
      <Grid item xs={12} className="Successful-print">
        Print Receipt
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} md={1} className="Success-email-label">
            Print:
          </Grid>
          <Grid item xs={6}>
            <Btn label="PRINT RECEIPT" onClick={handlePrintReceipt} bgColor="#2a9d8f" color="white" bgColorHover="#414158" colorHover="#ff8c68" width={140} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="Success-done-btn">
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
          <Grid>
            <Btn label="DONE" onClick={() => handleDone()} bgColor="#e76f50" color="white" bgColorHover="#ff8c68" colorHover="white" width={128} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

receipt.propTypes = {
  handlePrintReceipt: PropTypes.func.isRequired,
  handleDone: PropTypes.func.isRequired,
};

export default receipt;
