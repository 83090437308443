import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import Checkbox from '../../../materialUi/checkBox';
import SearchTextField from '../../../materialUi/searchTextField';
import '../../../css/settings.css';

export default function filter({
  search, values, handleFilter,
}) {
  return (
    <Grid item xs={12}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Box className="settings-searchField">
          <SearchTextField
            label="Search Users"
            search={(value) => search(value.target.value)}
            width={200}
          />
        </Box>
        <div className="dropdown settings-btn-filter">
          <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span className="settings-users-dropdown">Users Type</span>
          </button>
          <div className="dropdown-menu settings-item-dropdown" aria-labelledby="dropdownMenuButton">
            <span className="dropdown-item"><Checkbox className="dropdown-item" fontSize={14} bottom={0} value={values.members} click={(value) => handleFilter('members', value, 'Members')} message="Members" /></span>
            <span className="dropdown-item"><Checkbox fontSize={14} bottom={0} value={values.superAdmin} click={(value) => handleFilter('superAdmin', value, 'Super Admin')} message="Super Admin" /></span>
            <span className="dropdown-item"><Checkbox fontSize={14} bottom={0} value={values.receptionist} click={(value) => handleFilter('receptionist', value, 'Receptionist')} message="Receptionist" /></span>
            <span className="dropdown-item"><Checkbox fontSize={14} bottom={0} value={values.membershipAdmin} click={(value) => handleFilter('membershipAdmin', value, 'Membership Admin')} message="Membership Adm" /></span>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

filter.propTypes = {
  values: PropTypes.shape({
    members: PropTypes.bool,
    superAdmin: PropTypes.bool,
    receptionist: PropTypes.bool,
    membershipAdmin: PropTypes.bool,
  }).isRequired,
  search: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired,
};
