import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import Dropdown from '../../../../materialUi/dropdown';
import Chip from '../../../../materialUi/chip';


export const getAllSeasons = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  if (month >= 0 && month <= 3) {
    return {
      winter: `Winter ${year}`,
      spring: `Spring ${year}`,
      summer: `Summer ${year}`,
      fall: `Fall ${year}`,
    };
  } if (month >= 4 && month <= 6) {
    return {
      winter: `Winter ${year + 1}`,
      spring: `Spring ${year}`,
      summer: `Summer ${year}`,
      fall: `Fall ${year}`,
    };
  } if (month >= 7 && month <= 9) {
    return {
      winter: `Winter ${year + 1}`,
      spring: `Spring ${year + 1}`,
      summer: `Summer ${year}`,
      fall: `Fall ${year}`,
    };
  } if (month >= 10 && month <= 12) {
    return {
      winter: `Winter ${year + 1}`,
      spring: `Spring ${year + 1}`,
      summer: `Summer ${year + 1}`,
      fall: `Fall ${year}`,
    };
  }
};

function Seasons(props) {
  const {
    control,
    formState: { errors },
    register,
    unregister,
    setValue,
    data,
  } = props;

  const {
    winter, fall, spring, summer,
  } = getAllSeasons();

  const seasonsList = [
    { name: winter },
    { name: spring },
    { name: summer },
    { name: fall },
  ];

  const [seasons, setSeasons] = React.useState(data.seasons ? data.seasons : []);

  const updateSeasons = (value) => {
    if (value) {
      const copySeasons = [...seasons];
      copySeasons.push(value);
      setSeasons(copySeasons);
      setValue('seasons', copySeasons);
    }
  };

  const handleSeasons = (value) => {
    register('seasons', []);
    if (!seasons.includes(value)) {
      updateSeasons(value);
    }
    // setValue('selectedSeason', '');
    return true;
  };

  const handleDeleteSeason = (index) => {
    const copySeasons = [...seasons];
    copySeasons.splice(index, 1);
    setSeasons(copySeasons);
    setValue('seasons', copySeasons);
    if (copySeasons.length === 0) {
      register('selectedSeason');
      setValue('selectedSeason', '', { shouldValidate: true });
    } else {
      setValue('selectedSeason', '', { shouldValidate: false });
      unregister('selectedSeason');
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <h2 className="addNewProgram-sub-titles">
          Season(s)
        </h2>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="selectedSeason"
          control={control}
          rules={{
            required: seasons.length > 0 ? false : 'Please select at least one season',
            validate: (value) => handleSeasons(value),
          }}
          defaultValue=""
          render={({ field }) => (
            <Dropdown
              name="selectedSeason"
              label="Seasons"
              id="selectedSeason"
              value=""
              handleChanges={() => {}}
              helperText={errors.selectedSeason ? errors.selectedSeason.message : ''}
              error={!!errors.selectedSeason}
              listName={seasonsList}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          {seasons.map((item, index) => (
            <Chip
              name={item}
              handleDelete={() => handleDeleteSeason(index)}
            />
          ))}
        </Grid>
      </Grid>
    </>
  );
}

Seasons.propTypes = {
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  formState: PropTypes.shape({
    errors: PropTypes.any,
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  data: PropTypes.shape(
    {
      seasons: PropTypes.arrayOf(PropTypes.any),
      selectedSeason: PropTypes.string,
    },
  ).isRequired,
};

export default Seasons;
