import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { Grid, Box } from '@material-ui/core';
import { CONVERTTIME, TIMETOAMPM } from '../../../../utils/dateController';
import CalendarIcon from '../../../../assets/icons/calendar-icon.svg';

export default function Details({ data, userData }) {
  const { userType, membership } = userData.userData;

  const returnSpotsBasedOnMembershipType = () => {
    const MEMBERSHIP_TYPE = membership.membership.membershipType.toLowerCase();
    if (MEMBERSHIP_TYPE === 'associate') {
      return data.associateSpots;
    }
    return data.communitySpots;
  };

  const displayNumberOfSpots = () => {
    if (userType.toLowerCase() === 'member') {
      return returnSpotsBasedOnMembershipType();
    }
    return data.communitySpots + data.associateSpots;
  };

  const returnWeeklySpotsBasedOnMembershipType = (week) => {
    const MEMBERSHIP_TYPE = membership.membership.membershipType.toLowerCase();
    if (MEMBERSHIP_TYPE === 'associate') {
      return week.associateSpots;
    }
    return week.communitySpots;
  };

  const displayWeeklySpots = (week) => {
    if (userType.toLowerCase() === 'member') {
      return returnWeeklySpotsBasedOnMembershipType(week);
    }
    return week.communitySpots + week.associateSpots - week.familyMembers.length;
  };

  const displaySeasons = () => (data.season ? data.season.toString().replace(/,/g, ' - ') : data.seasons.toString().replace(/,/g, ' - '));

  const displayTime = () => `${TIMETOAMPM(data.programStartTime)} - ${TIMETOAMPM(data.programEndTime)}`;

  return (
    <Grid item xs={12} className="programDetails-spacing">
      <Box className="programDetails-boxes">
        <Grid container>
          <Grid item xs={12} className="programDetails-boxes-main-title">
            Program Details:
          </Grid>
          {data && data.typeOfSchedule === 'regular' && (
            <>
              <Grid item xs={12} md={6}>
                <Grid container className="programDetails-boxes-text">
                  <Grid item xs={6} md={4}>
                    Time:
                  </Grid>
                  <Grid item xs>
                    <b>{displayTime()}</b>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container className="programDetails-boxes-text">
                  <Grid item xs={6} md={4}>
                    Day(s) Weeks:
                  </Grid>
                  <Grid item xs>
                    <b>
                      {data && data.days
                        .map((i) => i.substring(0, 3))
                        .toString()
                        .replace(/,/g, ' - ')}
                    </b>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          {data && data.variableSchedule && data.typeOfSchedule === 'variable' && (
            <Grid item xs={12}>
              <Grid container className="programDetails-boxes-text schedule-container">
                <Grid item xs={12} md={2}>
                  Schedule(s):
                </Grid>
                <Grid item>
                  {data.variableSchedule.map((option, index) => (
                    <div key={index} className="mb-2">
                      <img src={CalendarIcon} alt="calendar icon" className="calendar-icon" />
                      <b className="ml-2 pt-1">
                        <Moment date={option.date} format="DD-MM-YYYY" />
                      </b>
                      <b className="ml-3">
                        {TIMETOAMPM(option.startTime)}
                        {' '}
                        -
                        {' '}
                      </b>
                      <b>
                        {' '}
                        <b>{TIMETOAMPM(option.endTime)}</b>
                      </b>
                    </div>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <Grid container className="programDetails-boxes-text">
              <Grid item xs={6} md={4}>
                Season:
              </Grid>
              <Grid item xs>
                <b>{data && displaySeasons()}</b>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container className="programDetails-boxes-text">
              <Grid item xs={6} md={4}>
                Age Group:
              </Grid>
              <Grid item xs>
                <b>{(data) ? data.ages || `${data.ageMinimum} to ${data.ageMaximum} years old` : ''}</b>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container className="programDetails-boxes-text">
              <Grid item xs={6} md={4}>
                Duration:
              </Grid>
              <Grid item xs>
                <b>{data && `${CONVERTTIME(data.durationStartDate, false)} - ${CONVERTTIME(data.durationEndDate, false)}`}</b>
              </Grid>
            </Grid>
          </Grid>
          {data && !data.weeklyProgram && (
            <Grid item xs={12} md={6}>
              <Grid container className="programDetails-boxes-text">
                <Grid item xs={6} md={4}>
                  Spots Left:
                </Grid>
                <Grid item xs>
                  <b>{data && displayNumberOfSpots()}</b>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Grid container className="programDetails-boxes-text">
              <Grid item xs={6} md={4}>
                Membership Type:
              </Grid>
              <Grid item xs>
                <b>{data && data.membershipType}</b>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container className="programDetails-boxes-text">
              <Grid item xs={6} md={4}>
                Gender:
              </Grid>
              <Grid item xs>
                <b>{data && data.gender}</b>
              </Grid>
            </Grid>
          </Grid>
          {data && data.weeklyProgram && (
            <Grid item xs={12}>
              <Grid container className="programDetails-boxes-text schedule-container">
                <Grid item xs={12} md={2}>
                  Schedule(s):
                </Grid>
                <Grid item>
                  {data.weeklySchedule.map((option, index) => (
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={6}
                    >
                      <Grid item key={index} className="mb-2">
                        <img src={CalendarIcon} alt="calendar icon" className="calendar-icon" />
                        <b className="ml-3">
                          <Moment date={option.startDate} format="DD-MMM" />
                          {' '}
                          -
                          {' '}
                        </b>
                        <b>
                          {' '}
                          <b>
                            <Moment date={option.endDate} format="DD-MMM" />
                          </b>
                        </b>
                        {' '}
                        {' '}
                        (Spots Left:
                        {' '}
                        {displayWeeklySpots(option)}
                        )
                      </Grid>
                      <Grid item>
                        <b>{TIMETOAMPM(data.weeklyStartTime)}</b>
                        {' '}
                        -
                        {' '}
                        <b>{TIMETOAMPM(data.weeklyEndTime)}</b>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
  );
}

Details.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      program: PropTypes.arrayOf(PropTypes.any),
    }),
  ]).isRequired,
  userData: PropTypes.shape({
    userData: PropTypes.shape({
      userType: PropTypes.string,
    }),
  }).isRequired,
};
