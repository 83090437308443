import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ScccPortal from './pages/scccPortal';
import AddData from './utils/dataMigration';
import LandingPage from './pages/landingPage/landingPage';
import AdminPortal from './portalAdmin/adminPortal';
import AdminLogin from './pages/login/adminLogin';
import MemberLogin from './pages/login/memberLogin';
import MemberForgotPassword from './pages/login/memberForgotPassword';
import AdminForgotPassword from './pages/login/adminForgotPassword';
import ResetPassword from './pages/login/resetPassword';
import PreRegistration from './pages/login/preRegistration';
import PreRegistrationSuccess from './pages/login/preRegistrationSuccess';
import './App.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#279d8f',
      secondary: '#279d8f',
    },
  },
});

export default function App() {
  React.useEffect(() => {
    window.addEventListener('popstate', (e) => {
      window.location.reload();
    });
  }, []);

  return (
    <div className="app-wrapper">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            <Route path="/addData" component={AddData} />
            <Route path="/admin" component={AdminLogin} />
            <Route path="/memberLogin/:action?/:param1?/:param2?/:param3?" component={MemberLogin} />
            <Route path="/adminPortal" component={AdminPortal} />
            <Route path="/memberForgotPassword" component={MemberForgotPassword} />
            <Route path="/adminForgotPassword" component={AdminForgotPassword} />
            <Route path="/resetPassword/:token" component={ResetPassword} />
            <Route path="/preRegistration" component={PreRegistration} />
            <Route path="/preRegistrationSuccess" component={PreRegistrationSuccess} />
            <Route path="/scccPortal" component={ScccPortal} />
            <Route path="*" component={LandingPage} />
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}
