import React from 'react';
import PropTypes from 'prop-types';
import '../../../../../css/verifyAddress.css';
import { Grid, Box } from '@material-ui/core';

function header({ handleBack, addressMessageValidation }) {
  return (
    <Grid container className="verify-header-spacing">
      <Grid item xs={12}>
        <Box className="back-to-verify" onClick={handleBack}>&lt; Back to Verify Membership </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item className="">
            <Box className="address-verify-title"> Address Verification </Box>
          </Grid>
          <Grid item id={addressMessageValidation}>
            <i className="fas fa-check-circle circle-verify-address" />
            <span className="Address-Verified">Address Verified</span>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

header.propTypes = {
  handleBack: PropTypes.func.isRequired,
  addressMessageValidation: PropTypes.string.isRequired,
};

export default header;
