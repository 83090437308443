import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import HelpIcon from '@material-ui/icons/Help';
import { Grid, Box } from '@material-ui/core';
import DropdownIcon from '../../../../assets/icons/menu.png';
import MenuIcon from '../../../../materialUi/menuIcon';
import Btn from '../../../../materialUi/btn';
import MousePopOver from '../../../../materialUi/mousePopOver';
import Filter from './filter';
import '../../../../css/registrationList.css';

export default class AttendeesHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayFilter: false,
      mousePopOver: false,
    };
  }

  handleDisplayFilter = () => {
    const { displayFilter } = this.state;
    const { resetFilters } = this.props;
    if (displayFilter) {
      this.setState({ displayFilter: false });
      // reset filter
      resetFilters('');
    } else {
      this.setState({ displayFilter: true });
    }
  };

  render() {
    const {
      handleBack, handleAddNewProgram, programName, handleChangeTable, handleExport, handleFilter, search, values, userType, attendanceNumberCheck, headerTotals, weeklySchedule, selectedWeek, handleSelectedWeek
    } = this.props;

    const { displayFilter, mousePopOver } = this.state;

    let options;
    if (userType === 'Receptionist' || attendanceNumberCheck) {
      options = ['Program Details', 'Download CSV', 'Print'];
    } else {
      options = ['Program Details', 'Attendance List', 'Download CSV', 'Print'];
    }

    return (
      <Grid container className="registrationList-spacing">
        <Grid item xs={12}>
          <Grid className="registrationList-back" onClick={handleBack} onKeyPress={handleBack} role="button" tabIndex={0}>
            &lt; Back to Programs List
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} xl={6}>
          <h1 className="registrationList-header">{programName}</h1>
        </Grid>
        <Grid item xs={12} md={8} xl={6}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Grid className="registrationList-btn-createNew" id={userType === 'Program Staff' ? 'hidden' : ''}>
              <Btn label="REGISTER" onClick={handleAddNewProgram} bgColor="#e76f50" color="white" bgColorHover="#ff8c68" colorHover="white" width={128} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box className="registrationList-box">
            <h2 className="registrationList-activeList">
              Attendees
              {' '}
              {headerTotals.attendees}
            </h2>
            {' '}
            &nbsp; | &nbsp;
            {' '}
          </Box>
          {!weeklySchedule && (
            <>
              <Box
                onClick={() => handleChangeTable('Waiting List')}
                onKeyPress={() => handleChangeTable('Waiting List')}
                role="button" tabIndex={0}
                className="registrationList-box"
              >
                <h2 className="registrationList-deactivatedList">
                  Waiting List
                  {' '}
                  {headerTotals.waitingList}
                </h2>
              </Box>
              {' '}
              &nbsp; | &nbsp;
              {' '}
            </>
          )}
          <Box
            onClick={() => handleChangeTable('Program Registrations')}
            onKeyPress={() => handleChangeTable('Program Registrations')}
            role="button"
            tabIndex={0}
            className="registrationList-box"
          >
            <h2 className="registrationList-deactivatedList">
              Program Registration
              {' '}
              {headerTotals.registrations}
            </h2>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {weeklySchedule && (
            <Grid container className="registrationList-weekly-wrapper">
              <Grid item xs={12} className="registrationList-weekly-box">
                <h3 className="registrationList-weekly-title">
                  Weekly Program
                  {' '}
                  <HelpIcon
                    className="registrationList-help-icon"
                    onMouseEnter={(event) => this.setState({ mousePopOver: event.currentTarget })}
                    onMouseLeave={() => this.setState({ mousePopOver: null })}
                    onClick={(event) => this.setState({ mousePopOver: event.currentTarget })}
                  />
                  <MousePopOver open={mousePopOver} message="Please, select a week below." />
                </h3>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                >
                  {weeklySchedule.map((option, index) => (
                    <Grid item key={index}>
                      <button
                        type="button"
                        className={`registrationList-weekly-options ${selectedWeek === index && 'registrationList-weekly-options-active'} `}
                        onClick={() => handleSelectedWeek(index)}
                      >
                        <Moment date={option.startDate} format="DD-MMM" />
                        {' '}
                        -
                        {' '}
                        {' '}
                        <Moment date={option.endDate} format="DD-MMM" />
                        {' '}
                        {' '}
                        (Spots left:
                        {' '}
                        {(option.communitySpots + option.associateSpots) - option.familyMembers.length}
                        )
                      </button>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs className="registrationList-filter-space">
              <Box
                onClick={() => this.handleDisplayFilter()}
                role="button"
                tabIndex={0}
                onKeyPress={() => this.handleDisplayFilter()}
                className="registrationList-filter"
              >
                <img className="Filter" src={DropdownIcon} alt="Dropdown icon" />
                Filter
              </Box>
            </Grid>
            <Grid item xs>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <MenuIcon width={180} list={options} onClick={(value) => handleExport(value)} />
              </Grid>
            </Grid>
            {displayFilter ? <Filter handleFilter={handleFilter} search={search} values={values} displaySort={false} /> : ''}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

AttendeesHeader.propTypes = {
  programName: PropTypes.string.isRequired,
  handleChangeTable: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  handleAddNewProgram: PropTypes.func.isRequired,
  handleExport: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  values: PropTypes.shape({
    approved: PropTypes.bool,
    pending: PropTypes.bool,
  }).isRequired,
  resetFilters: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired,
  attendanceNumberCheck: PropTypes.bool.isRequired,
  headerTotals: PropTypes.shape({
    registrations: PropTypes.number,
    attendees: PropTypes.number,
    waitingList: PropTypes.number,
  }).isRequired,
};
