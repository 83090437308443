import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import LockIcon from '../../assets/icons/lockBigIcon.svg';
import Btn from '../../materialUi/btn';

function LockedScreen({ history }) {
  return (
    <Grid item xs className="font-family-default">
      <Grid container>
        <Grid item xs={12} className="taxes-lock-icon">
          <img src={LockIcon} alt="lock icon" />
        </Grid>
        <Grid item xs={12} className="taxes-lock-text">
        If you’d like to access other features on the portal,
         you must first complete your membership registration.
        </Grid>
        <Grid item xs={12} className="text-center">
          <Btn
            label="Start Registration"
            bgColor="#e76f50"
            color="white"
            colorHover="white"
            bgColorHover="#ff8c68"
            width={211}
            height={36}
            onClick={() => history.push('/scccPortal/membershipRegistration')}
            type="submit"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

LockedScreen.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default LockedScreen;
