import React from 'react';
import { Grid } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import TextField from '../../../../../materialUi/textField';
import Dropdown from '../../../../../materialUi/dropdown';

function CaregiverInformation(props) {
  const {
    control,
    formState: { errors },
    programData,
  } = props;

  const relationshipList = [{ name: 'Spouse' }, { name: 'Child' }, { name: 'Parent' }, { name: 'Sibling' }, { name: 'Grand Parents' }, { name: 'Grand Child' }];

  const formatDate = function(validation) {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) {
      dd = `0${dd}`;
    }
    if (mm < 10) {
      mm = `0${mm}`;
    }
    today = `${yyyy}-${mm}-${dd}`;
    return today;
  };

  return (
    <Grid container style={{ marginTop: '30px' }}>
      <Grid item xs={12}>
        <h3 className="programRegistration-titles">Caregiver Information</h3>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md>
            <Controller
              name="caregiverName"
              control={control}
              rules={{
                maxLength: { value: 100, message: 'Max length 100' },
              }}
              render={({ field }) => (
                <TextField
                  id="caregiverName"
                  type="search"
                  name="caregiverName"
                  label="Caregiver Name"
                  helperText={errors.caregiverName ? errors.caregiverName.message : ''}
                  error={!!errors.caregiverName}
                  width="100%"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md>
            <Controller
              name="caregiverRelationship"
              control={control}
              render={({ field }) => (
                <Dropdown
                  name="caregiverRelationship"
                  label="Relationship"
                  id="caregiverRelationship"
                  value=""
                  handleChanges={() => {}}
                  helperText={errors.caregiverRelationship ? errors.caregiverRelationship.message : ''}
                  error={!!errors.caregiverRelationship}
                  listName={relationshipList}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md>
            <Controller
              name="caregiverHomePhone"
              control={control}
              rules={{
                maxLength: { value: 20, message: 'Max length 20' },
                pattern: {
                  value: /^[\]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/i,
                  message: 'Invalid Phone Format',
                },
              }}
              render={({ field }) => (
                <TextField
                  id="caregiverHomePhone"
                  type="search"
                  name="caregiverHomePhone"
                  label="Home Phone"
                  helperText={errors.caregiverHomePhone ? errors.caregiverHomePhone.message : ''}
                  error={!!errors.caregiverHomePhone}
                  width="100%"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md>
            <Controller
              name="caregiverWorkPhone"
              control={control}
              rules={{
                maxLength: { value: 20, message: 'Max length 20' },
                pattern: {
                  value: /^[\]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/i,
                  message: 'Invalid Phone Format',
                },
              }}
              render={({ field }) => (
                <TextField
                  id="caregiverWorkPhone"
                  type="search"
                  name="caregiverWorkPhone"
                  label="Work Phone"
                  helperText={errors.caregiverWorkPhone ? errors.caregiverWorkPhone.message : ''}
                  error={!!errors.caregiverWorkPhone}
                  width="100%"
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Controller
              name="signature"
              control={control}
              rules={{
                maxLength: { value: 100, message: 'Max length 100' },
              }}
              render={({ field }) => (
                <TextField
                  id="signature"
                  type="search"
                  name="signature"
                  label="Parent Signature"
                  helperText={errors.signature ? errors.signature.message : ''}
                  error={!!errors.signature}
                  width="100%"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField id="date" type="date" name="date" label="Date" helperText="" error={false} width="100%" value={formatDate(new Date())} readOnly shrink />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CaregiverInformation;
