import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import TextField from '../../../../materialUi/textField';
import Dropdown from '../../../../materialUi/dropdown';
import CheckBox from '../../../../materialUi/checkBox';
import DateTextField from '../../../../materialUi/dateTextField';
import MultilineTextField from '../../../../materialUi/multilineTextField';

function BasicInformation(props) {
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    unregister,
    watch,
    data,
  } = props;

  const programTypeList = [
    { name: 'Early Years' },
    { name: 'Summer Programs' },
    { name: 'Children Programs' },
    { name: 'Youth Programs' },
    { name: 'Disabilities Programs' },
    { name: 'Newcomers Service Programs' },
    { name: 'Senior Programs' },
    { name: 'Adult Programs' },
  ];

  const removeValues = (valuesArray) => {
    valuesArray.forEach((item) => {
      unregister(item);
      if (data[item]) {
        delete data[item];
      }
    });
  };

  const removeErrors = (name, value) => {
    if (name === 'weeklyProgram' && value === true) {
      removeValues(['ages']);
    } else if ((name === 'weeklyProgram' && value === false) || (name === 'dropIn' && value === true)) {
      removeValues(['ageMinimum', 'ageMaximum']);
    }
  };

  const handleProgramOption = (name, value) => {
    const OTHER_SELECTION_NAME = name === 'dropIn' ? 'weeklyProgram' : 'dropIn';
    setValue(OTHER_SELECTION_NAME, false);
    setValue(name, value);
    removeErrors(name, value);
  };

  const checkIfDateIsValid = (date) => {
    if (date.toString() === 'Invalid Date') {
      return 'Invalid Date Format';
    }
    return true;
  };

  const handleStartDate = (value) => {
    const END_DATE = getValues('enrollmentEndDate') ? new Date(getValues('enrollmentEndDate')) : false;

    const START_DATE = new Date(value);

    if (END_DATE && START_DATE > END_DATE) {
      return 'Start Date cannot be more than End Date';
    }
    return checkIfDateIsValid(value);
  };

  const handleEndDate = (value) => {
    const START_DATE = getValues('enrollmentStartDate') ? new Date(getValues('enrollmentStartDate')) : false;

    const END_DATE = new Date(value);

    if (START_DATE && END_DATE < START_DATE) {
      return 'End Date must be more than Start Date';
    }
    return checkIfDateIsValid(value);
  };

  React.useEffect(() => {
    watch('weeklyProgram');
  }, [watch]);

  return (
    <>
      <Grid item xs={12}>
        <h1 className="addNewProgram-titles">New Program</h1>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="name"
          control={control}
          rules={{
            required: 'Program name is required',
            maxLength: { value: 100, message: 'Max length 100' },
          }}
          render={({ field }) => (
            <TextField id="programName" type="search" name="programName" label="Program Name" helperText={errors.name ? errors.name.message : ''} error={!!errors.name} width="100%" {...field} />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Controller
              name="programType"
              control={control}
              rules={{
                required: 'Program Type is required',
              }}
              defaultValue={data.programType}
              render={({ field }) => (
                <Dropdown
                  name="programType"
                  label="Program Type"
                  id="programType"
                  value=""
                  handleChanges={() => { }}
                  helperText={errors.programType ? errors.programType.message : ''}
                  error={!!errors.programType}
                  listName={programTypeList}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={{ paddingTop: '10px' }}>
              <CheckBox id="Drop In" message="Drop In" value={getValues('dropIn')} click={(value) => handleProgramOption('dropIn', value)} />
              <CheckBox id="weekly" message="Weekly (Summer Camp)" value={getValues('weeklyProgram')} click={(value) => handleProgramOption('weeklyProgram', value)} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Controller
              name="enrollmentStartDate"
              control={control}
              rules={{
                required: 'Enrollment Start Date is Required',
                validate: (value) => handleStartDate(value),
              }}
              defaultValue={data.enrollmentStartDate}
              render={({ field }) => (
                <DateTextField
                  label="Enrollment start date"
                  name="enrollmentStartDate"
                  id="enrollmentStartDate"
                  helperText={errors.enrollmentStartDate ? errors.enrollmentStartDate.message : ''}
                  error={!!errors.enrollmentStartDate}
                  readOnly={false}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="enrollmentEndDate"
              control={control}
              rules={{
                required: !getValues('dropIn') && 'Enrollment End Date is Required',
                validate: (value) => (getValues('dropIn') === false ? handleEndDate(value) : true),
              }}
              defaultValue={data.enrollmentEndDate}
              render={({ field }) => (
                <DateTextField
                  label="Enrollment end date"
                  name="enrollmentEndDate"
                  id="enrollmentEndDate"
                  helperText={errors.enrollmentEndDate ? errors.enrollmentEndDate.message : ''}
                  error={!!errors.enrollmentEndDate}
                  readOnly={false}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Controller
            name="programDescription"
            control={control}
            rules={{
              required: 'Program Description is required',
              maxLength: { value: 500, message: 'Max length 500' },
            }}
            render={({ field }) => (
              <MultilineTextField
                id="programDescription"
                type="search"
                name="programDescription"
                label="Program Description"
                helperText={errors.programDescription ? errors.programDescription.message : ''}
                error={!!errors.programDescription}
                width="100%"
                lines={6}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
}

BasicInformation.propTypes = {
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  formState: PropTypes.shape({
    errors: PropTypes.any,
  }).isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string,
    programType: PropTypes.string,
    dropIn: PropTypes.bool,
    weeklyProgram: PropTypes.bool,
    enrollmentStartDate: PropTypes.string,
    enrollmentEndDate: PropTypes.string,
    programDescription: PropTypes.string,
  }).isRequired,
};

export default BasicInformation;
