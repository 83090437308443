import React, { Component } from 'react';
import { Grid, Box } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import '../../../../../css/selectCategory.css';

class Type extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 'Family',
    };
  }

  componentDidMount() {
    if (!sessionStorage.getItem('membershipCategory')) {
      sessionStorage.setItem('membershipCategory', 'Family');
    } else {
      this.setState({ selected: sessionStorage.getItem('membershipCategory') });
      sessionStorage.setItem('membershipCategory', 'Family');
    }
  }

  // membership category on session
  optionsUpdate = (option) => {
    this.setState({
      selected: option,
    });
    sessionStorage.setItem('membershipCategory', option);
  }

  render() {
    const { selected } = this.state;
    return (
      <Grid item xs>
        <Box className="selectCategory-subTitles">Select the type of membership category.</Box>

        <Grid container>
          <Grid item xs={6}>
            <Radio
              checked={selected === 'Family'}
              onClick={() => this.optionsUpdate('Family')}
              value="Family"
              className="radio"
              name="Family selected"
              inputProps={{ 'aria-label': 'Family' }}
              icon={<RadioButtonUncheckedIcon fontSize="large" />}
              checkedIcon={<RadioButtonCheckedIcon fontSize="large" />}
            />
            Family
          </Grid>
          <Grid item xs={6}>
            <Radio
              checked={selected === 'Senior'}
              onClick={() => this.optionsUpdate('Senior')}
              value="Senior"
              className="radio"
              name="Senior Selected"
              inputProps={{ 'aria-label': 'Senior' }}
              icon={<RadioButtonUncheckedIcon fontSize="large" />}
              checkedIcon={<RadioButtonCheckedIcon fontSize="large" />}
            />
            Senior (65+)
          </Grid>
          <Grid item xs={6}>
            <Radio
              checked={selected === 'Adult'}
              onClick={() => this.optionsUpdate('Adult')}
              value="Adult"
              className="radio"
              name="Adult Selected"
              inputProps={{ 'aria-label': 'Adult' }}
              icon={<RadioButtonUncheckedIcon fontSize="large" />}
              checkedIcon={<RadioButtonCheckedIcon fontSize="large" />}
            />
            Adult
          </Grid>
          <Grid item xs={6}>
            <Radio
              checked={selected === 'Child'}
              onClick={() => this.optionsUpdate('Child')}
              value="Child"
              className="radio"
              name="Child Selected"
              inputProps={{ 'aria-label': 'Child' }}
              icon={<RadioButtonUncheckedIcon fontSize="large" />}
              checkedIcon={<RadioButtonCheckedIcon fontSize="large" />}
            />
            Child
          </Grid>
          <Grid item xs={6}>
            <Radio
              checked={selected === 'Youth'}
              onClick={() => this.optionsUpdate('Youth')}
              value="Youth"
              className="radio"
              name="Youth selected"
              inputProps={{ 'aria-label': 'Youth' }}
              icon={<RadioButtonUncheckedIcon fontSize="large" />}
              checkedIcon={<RadioButtonCheckedIcon fontSize="large" />}
            />
            Youth (13-17)
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default Type;
