import axios from 'axios';
import baseURL from './baseURL';
import TOKEN from './Frontend-Token';

export const MemberAttendanceAPI = {
  PUT: {
    updateMemberAttendance(attendanceListDate, attendanceListID, attendanceListObj) {
      return axios.put(
        `${baseURL}api/updateMemberAttendance/${attendanceListDate}/${attendanceListID}`,
        {
          attendanceListObj,
        },
        {
          headers: { 'x-access-token': TOKEN },
        }
      );
    },
  },
  GET: {
    getByProgramID(programID) {
      return axios.get(`${baseURL}api/getMemberAttendanceByProgram/${programID}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },

    getOneByMembershipID(membershipID) {
      return axios.get(`${baseURL}api/getMemberAttendanceByMembershipID/${membershipID}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    getMembersAttendanceByDateAndProgramID(date, programID) {
      return axios.get(`${baseURL}api/getMemberAttendanceByDate/${date}/${programID}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
  },
};
export default MemberAttendanceAPI;
