import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import TextField from '../../../materialUi/textField';
import { userAPI } from '../../../API/API-user';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class editPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      membershipID: props.membershipID,
      alertOpen: false,
      passwordMatch: '',
      serverResponse: '',

      currentPassword: '',
      newPassword: '',
      passwordCheck: '',

      currentPasswordMessage: '',
      newPasswordMessage: '',
      passwordCheckMessage: '',

      currentPasswordError: '',
      newPasswordError: '',
      passwordCheckError: '',
    };
  }

  componentDidUpdate(previousProps) {
    const { open } = this.props;
    if (previousProps.open !== open) {
      this.setState({ alertOpen: open });
    }
  }

  handleCurrentPassword = (value) => {
    let message = '';
    let error = false;
    if (value.target.value.length < 1) {
      message = 'Current password cannot be empty';
      error = true;
    } else {
      message = '';
      error = false;
    }
    this.setState({
      currentPassword: value.target.value,
      currentPasswordMessage: message,
      currentPasswordError: error,
    });
  };

  handleNewPassword = (value) => {
    let message = '';
    let error = false;
    if (value.target.value.length < 1) {
      message = 'Password cannot be empty';
      error = true;
    } else if (value.target.value.length < 6) {
      message = 'Password must be 6 digits long';
      error = true;
    } else {
      message = '';
      error = false;
    }
    this.setState({
      newPassword: value.target.value,
      newPasswordMessage: message,
      newPasswordError: error,
    });
  };

  handlePasswordCheck = (value) => {
    let message = '';
    let error = false;
    let checkPasswords = '';
    const { newPassword } = this.state;
    if (value.target.value.length < 1) {
      message = 'New password cannot be empty';
      error = true;
    } else if (value.target.value.length < 6) {
      message = 'New Password must be 6 digits long';
      error = true;
    } else if (newPassword === value.target.value) {
      message = '';
      error = false;
      checkPasswords = true;
    } else {
      message = '';
      error = true;
      checkPasswords = false;
    }
    this.setState({
      passwordCheck: value.target.value,
      passwordCheckMessage: message,
      passwordMatch: checkPasswords,
      passwordCheckError: error,
    });
  };

  handleSubmit = () => {
    const {
      currentPasswordError,
      newPasswordError,
      passwordCheckError,
      currentPasswordMessage,
      newPasswordMessage,
      passwordCheckMessage,
      currentPassword,
      newPassword,
    } = this.state;
    const { close, userData } = this.props;
    let fieldsvalidation = 0;
    if (currentPasswordError !== false) {
      fieldsvalidation += 1;
      this.setState({
        currentPasswordError: true,
        currentPasswordMessage:
          currentPasswordMessage === '' ? 'Required Field' : currentPasswordMessage,
      });
    }
    if (newPasswordError !== false) {
      fieldsvalidation += 1;
      this.setState({
        newPasswordError: true,
        newPasswordMessage: newPasswordMessage === '' ? 'Required Field' : newPasswordMessage,
      });
    }
    if (passwordCheckError !== false) {
      fieldsvalidation += 1;
      this.setState({
        passwordCheckError: true,
        passwordCheckMessage: passwordCheckMessage === '' ? 'Required Field' : passwordCheckMessage,
      });
    }
    if (fieldsvalidation === 0) {
      console.log()
      userAPI.PUT.editUserPassword(userData.userData._id, currentPassword, newPassword)
        .then((success) => {
          console.log(success);
          close();
        })
        .catch((error) => {
          if (error.response.status === 401) this.setState({ serverResponse: 'Wrong Password!' });
          if (error.response.status === 500)
            this.setState({ serverResponse: 'Server Error! Please try again later.' });
          console.log(error.response);
        });
    }
  };

  render() {
    const { close } = this.props;
    const {
      alertOpen,
      currentPassword,
      newPassword,
      passwordCheck,
      currentPasswordMessage,
      newPasswordMessage,
      passwordCheckMessage,
      currentPasswordError,
      newPasswordError,
      passwordCheckError,
      passwordMatch,
      serverResponse,
    } = this.state;
    return (
      <div>
        <Dialog
          onClose={() => close()}
          aria-labelledby="customized-dialog-title"
          open={alertOpen}
          maxWidth="sm"
          fullWidth
        >
          <DialogContent dividers>
            <Grid container>
              <Grid item xs>
                <Box className="Boxes-main-title">Change Password</Box>
              </Grid>
              <Grid onClick={() => close()}>
                <i className="fas fa-times close-icon" />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs className="verify-serverResponse">
                {serverResponse}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  label="Current Password"
                  id="currentPassword"
                  name="Current Password"
                  value={currentPassword}
                  handleChanges={(value) => this.handleCurrentPassword(value)}
                  helperText={currentPasswordMessage}
                  error={currentPasswordError === '' ? false : currentPasswordError}
                  readOnly={false}
                  type="password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="New Password"
                  id="newPassword"
                  name="New Password"
                  value={newPassword}
                  handleChanges={(value) => this.handleNewPassword(value)}
                  helperText={newPasswordMessage}
                  error={newPasswordError === '' ? false : newPasswordError}
                  readOnly={false}
                  type="password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Re-enter the new Password"
                  id="passwordCheck"
                  name="Re-enter the new Password"
                  value={passwordCheck}
                  handleChanges={(value) => this.handlePasswordCheck(value)}
                  helperText={passwordCheckMessage}
                  error={passwordCheckError === '' ? false : passwordCheckError}
                  readOnly={false}
                  type="password"
                />
                {passwordMatch && (
                  <span className="verify-editPassword-match">
                    <i className="fas fa-check" /> Passwords match!
                  </span>
                )}
                {passwordMatch === false && (
                  <span className="verify-editPassword-notMatch">
                    <i className="fas fa-times-circle" /> Passwords does not match!
                  </span>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="m-2">
            <Button className="alert-btns" onClick={() => close()}>
              Cancel
            </Button>
            <Button className="alert-btns " onClick={this.handleSubmit}>
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

editPassword.propTypes = {
  userData: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default editPassword;
