import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Covid19API } from '../../../../API/API-covid19';
import Header from './components/header';
import DailyCheckInTable from './components/dailyCheckInTable';
import '../../../../css/reports.css';

export default class DailyCheckIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dailyCheckInData: [],
      selectedData: 'membersData',
      searchItem: '',
    };
  }

  componentDidMount() {
    const { match } = this.props;
    Covid19API.GET.getDailyCheckInByID(match.params.dailyCheckInID).then((success) => {
      this.setState({
        dailyCheckInData: success.data,
      });
      console.log(success.data);
    }).catch((error) => {
      console.log(error);
    });
  }

  handleSelection = (userSelected) => {
    const { history } = this.props;
    const { dailyCheckInData } = this.state;
    history.push(`/adminPortal/reports/checkInHistory/userHistory/${userSelected.id}/${dailyCheckInData[0].dailyCheckInID}`);
    sessionStorage.setItem('userInfo', JSON.stringify(userSelected));
  }


  handleTabSelection = (selectedItem) => {
    const updateSelectedData = (selectedItem === 0) ? 'membersData' : (selectedItem === 1) ? 'guestsData' : 'staffData';
    this.setState({ selectedData: updateSelectedData });
  }


  render() {
    const { history, match } = this.props;
    const {
      dailyCheckInData,
      selectedData,
      searchItem,
    } = this.state;
    return (
      <Grid item xs className="font-family-default">
        <Grid container>
          <Grid item xs={12}>
            <Header
              history={history}
              match={match}
              selectedData={selectedData}
              handleTabSelection={(selectedItem) => this.handleTabSelection(selectedItem)}
              handleSearch={(value) => this.setState({ searchItem: value })}
            />
            <DailyCheckInTable
              data={dailyCheckInData}
              handleClick={(userSelected) => this.handleSelection(userSelected)}
              selectedData={selectedData}
              searchItem={searchItem}
            />
          </Grid>
          <Grid className="table-export" id="el">
            {/* <TableExport
              data={userCheckInData}
              searchItem={search}
              values={values}
              sortStatus={status}
              sortResult={result}
            /> */}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

DailyCheckIn.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      dailyCheckInID: PropTypes.string,
      backRoute: PropTypes.string,
    }),
  }).isRequired,
};
