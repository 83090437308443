import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { ProgramsAPI } from '../../../API/API-programs';
import { ProgramRegistrationAPI } from '../../../API/API-programRegistration';
import Stepper from '../../../materialUi/stepper';
import BackButton from '../../../materialUi/backButton';
import RegistrationForm from './registrationForm/registrationForm';
import Payment from './payment/payment';
import Success from './success/success';
import WaitingListSuccess from './waitingSuccess/waitingSuccess';
import Loading from '../../../materialUi/loading';

function ProgramRegistration({ history, match, userData }) {
  const { backPage, programID } = match.params;
  const stepsList = ['Registration form', 'Payment'];
  const [step, setStep] = useState(0);
  const [data, setData] = useState({});
  const [programData, setProgramData] = useState({});

  const saveDateOnSession = (newData) => {
    sessionStorage.setItem(`programRegistration_${programID}`, JSON.stringify(newData));
  };

  const addNewData = (newData) => {
    let copyData = data;
    copyData = { ...data, ...newData };
    setData(copyData);
    saveDateOnSession(copyData);
    return copyData;
  };

  const addStep = (newStep, dataToSave) => {
    const copyData = dataToSave;
    copyData.currentStep = newStep;
    copyData.program = programData;
    addNewData(copyData);
    setStep(newStep);
  };

  const submitFreeProgram = (dataToSave) => {
    const copyData = dataToSave;
    copyData.program = programData;
    ProgramRegistrationAPI.POST.freeProgramRegistration(copyData)
      .then((response) => {
        console.log(response);
        copyData.freeProgram = true;
        addStep(2, copyData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const submitWaitingList = (dataToSave) => {
    const copyData = dataToSave;
    copyData.program = programData;
    ProgramRegistrationAPI.POST.waitingListRegistration(copyData)
      .then((response) => {
        console.log(response);
        addStep(3, dataToSave);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const goToPaymentPage = (dataToSave) => {
    addStep(1, dataToSave);
  };

  const getWeeklySpots = (dataToSave) => {
    console.log(dataToSave)
  };

  const noSpotsLeft = (dataToSave) => {
    if (programData.weeklyProgram) return false;
    const membershipType = dataToSave.membership.membershipType.toLowerCase();
    const participants = dataToSave.familyMembers.length;
    if (membershipType === 'associate') {
      if (participants > programData.associateSpots) return true;
    } else  {
      if (participants > programData.communitySpots) return true;
    }

    return false;
  };

  const getMemberFee = (membershipType) => {
    const { communityFee, associateFee } = programData;
    const type = membershipType.toLowerCase();
    if (type === 'associate') return associateFee;
    return communityFee;
  };

  const isFreeProgram = (dataToSave) => {
    const { variableFee, fee  } = programData;
    const membershipType = dataToSave.membership.membershipType.toLowerCase();
    const memberFee = variableFee !== 'yes' ? fee : getMemberFee(membershipType);
    if (memberFee === 0) return 'Free program'
    return false;
  };

  const handleNextStep = (newData, confirmation = false) => {
    const dataToSave = addNewData(newData);
    if (!confirmation) {
      if (noSpotsLeft(dataToSave)) return submitWaitingList(dataToSave);
      if (isFreeProgram(dataToSave)) return submitFreeProgram(dataToSave);
      goToPaymentPage(dataToSave);
    } else {
      addStep(2, dataToSave);
    }
    return true;
  };

  const sessionValues = () => {
    const SESSION_DATA = JSON.parse(sessionStorage.getItem(`programRegistration_${programID}`));
    if (SESSION_DATA) return SESSION_DATA;
    return false;
  };

  const getProgram = () => {
    ProgramsAPI.GET.getProgramByID(programID)
      .then((response) => {
        const { program, instructor } = response.data;
        program[0].instructor = instructor;
        if (sessionValues()) {
          console.log(sessionValues())
          if (sessionValues().currentStep === 2) {
            setStep(sessionValues().currentStep);
            setData(sessionValues());
            addStep(sessionValues().currentStep, sessionValues());
          }
        }
        setProgramData(program[0]);
        // If there are no spots left display the alert and send the user back
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    async function fetchData() {
      await getProgram();
    }
    fetchData();
    window.scroll({ top: 0, behavior: 'smooth' });
  }, []);

  if (!Object.keys(programData).length === 0) return <Loading top="50%" right="40%" />;

  return (
    <Grid item xs>
      {(step === 0 || step === 1) && (
        <>
          <Stepper labels={stepsList} step={step} />
          <div className="programRegistration-wrapper">
            <BackButton
              handleClick={() => {
                if (step === 0) {
                  history.goBack(1);
                } else {
                  setStep(0);
                }
              }}
              label={step === 0 ? `Back to ${backPage}` : 'Back to Registration form'}
            />
          </div>
        </>
      )}
      {step === 0 && <RegistrationForm data={data} handleNextStep={handleNextStep} userData={userData} programData={programData} />}
      {step === 1 && <Payment data={data} userData={userData} programData={programData} handleNextStep={handleNextStep} />}
      {step === 2 && <Success data={data} userData={userData} />}
      {step === 3 && (
        // TODO: Needs to refactor the waiting list success page
        <WaitingListSuccess data={data} userData={userData} programData={programData} />
      )}
    </Grid>
  );
}

export default ProgramRegistration;
