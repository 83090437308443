import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { DATE } from '../../../../utils/dateController';
import NewMembershipsChart from './newMembershipChart';


function NewMembership(props) {
  const { numberOfMembersPerMonth, handleYearSelection, selectedMonth } = props.data;
  const splitMonth = DATE(true).split('-');
  const thisYear = splitMonth[2];
  const numberOfYears = thisYear - 2019;

  const years = [];
  for (let i = 0; i <= numberOfYears; i++) {
    years.push(2019 + i);
  }
  return (
    <Grid container className="dashboard-tabs-spacing-newMember">
      <Grid item xs={6} className="dashboard-tabs-title">New Memberships</Grid>
      <Grid item xs={6} className="dashboard-tabs-datashow">
        <div className="dropdown">
          <button 
            className="btn dropdown-toggle" 
            type="button" 
            id="dropdownMenuButton" 
            data-toggle="dropdown" 
            aria-haspopup="true" 
            aria-expanded="false"
          >
            <span className="dashboard-label-dropdown">{selectedMonth}</span>
          </button>
          <div className="dropdown-menu dashboard-item-dropdown" aria-labelledby="dropdownMenuButton">
            {years.map((option) => <span key={option} className="dropdown-item " onClick={() => handleYearSelection(option)}>{option}</span>)}
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Paper className="dashboard-tabs-paper">
          <NewMembershipsChart numberOfMembersPerMonth={numberOfMembersPerMonth} />
        </Paper>
      </Grid>
    </Grid>
  );
}

NewMembership.propTypes = {
  numberOfMembersPerMonth: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleYearSelection: PropTypes.func.isRequired,
  selectedMonth: PropTypes.number.isRequired,
};

export default NewMembership;
