import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

export default function Instructors({ data }) {
  let instructorName;
  let telephoneDay1;
  let telephoneNight1;

  if (data) {
    instructorName = data.firstName + ' ' + data.lastName;
    telephoneDay1 = data.dayPhone;
    telephoneNight1 = data.nightPhone;
  }

  return (
    <Grid container>
      <Grid item xs={12} md={4}>
        <Grid container className="programDetails-boxes-text">
          <Grid item xs={6} md={4}>
            Name:
          </Grid>
          <Grid item xs>
            <b>{instructorName}</b>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <Grid container className="programDetails-boxes-text">
          <Grid item xs={6} md={4}>
            Telephone (Day):
          </Grid>
          <Grid item xs>
            <b>{telephoneDay1}</b>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <Grid container className="programDetails-boxes-text">
          <Grid item xs={6} md={4}>
            Telephone (Night):
          </Grid>
          <Grid item xs>
            <b>{telephoneNight1}</b>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}


Instructors.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      program: PropTypes.arrayOf(PropTypes.any),
    }),
  ]).isRequired,
};
