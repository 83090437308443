import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import SearchTextField from '../../../../materialUi/searchTextField';
import '../../../../css/specificAttendenceList.css';

export default function Filter({ search }) {
  return (
    <Grid item xs={12} style={{ marginTop: '20px' }}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <SearchTextField search={(value) => search(value.target.value)} label="Search" width={200} />
      </Grid>
    </Grid>
  );
}

Filter.propTypes = {
  search: PropTypes.func.isRequired,
};
