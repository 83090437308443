import React from 'react';
import { Grid, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import DropdownIcon from '../../../assets/icons/menu.png';
import Btn from '../../../materialUi/btn';
import Filter from './filter';
import '../../../css/settings.css';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayFilter: false,
    };
  }

  handleDisplayFilter = () => {
    const { displayFilter } = this.state;
    const { resetFilters } = this.props;
    if (displayFilter) {
      this.setState({ displayFilter: false });
      // reset filter
      resetFilters('');
    } else {
      this.setState({ displayFilter: true });
    }
  };

  render() {
    const { handleFilter, search, values, handleClickNew, selectedItems } = this.props;
    const { displayFilter } = this.state;
    return (
      <Grid container className="root settings-header-spacing">
        <Grid item xs={12} md={4} xl={6} className="settings-title">
          Users List
        </Grid>
        <Grid item xs={12} md={8} xl={6}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Grid className="settings-search">
              <Btn label="Create New" onClick={handleClickNew} bgColor="#e76f50" color="white" bgColorHover="#ff8c68" colorHover="white" width={128} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '21px' }}>
          <Grid container>
            <Grid item xs md={2} lg={1}>
              <Box onClick={() => this.handleDisplayFilter()} className="settings-filter">
                <img src={DropdownIcon} alt="Search Filter" />
                Filter
              </Box>
            </Grid>
            {/* filter for desktop  */}
            {window.innerWidth > 900 && (
              <Grid item xs className="settings-box-selectedItems">
                {selectedItems}
              </Grid>
            )}
          </Grid>
        </Grid>
        {displayFilter ? <Filter handleFilter={handleFilter} search={search} values={values} /> : ''}
      </Grid>
    );
  }
}
Header.propTypes = {
  search: PropTypes.func.isRequired,
  handleClickNew: PropTypes.func.isRequired,
  values: PropTypes.shape({
    members: PropTypes.bool,
    superAdmin: PropTypes.bool,
    receptionist: PropTypes.bool,
    membershipAdmin: PropTypes.bool,
  }).isRequired,
  handleFilter: PropTypes.func.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  resetFilters: PropTypes.func.isRequired,
};
export default Header;
