import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function CircularIndeterminate(props) {
  const { right, top } = props;

  const useStyles = makeStyles((theme) => ({
    progress: {
      margin: theme.spacing(0),
      padding: 0,
      color: '#2a9d8f',
      position: 'absolute',
      zIndex: 2,
      right: right ? right : 90,
      top: top && top
    },
  }));

  const classes = useStyles();

  return (
    <div>
      <CircularProgress className={classes.progress} size={props.size || '40px'} />
    </div>
  );
}
