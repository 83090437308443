import React from 'react';
import { Grid, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import DropdownIcon from '../../../../assets/icons/menu.png';
import MenuIcon from '../../../../materialUi/menuIcon';
import Filter from './filter';
import '../../../../css/receipts.css';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayFilter: false,
    };
  }

  handleDisplayFilter = () => {
    const { displayFilter } = this.state;
    if (displayFilter) {
      this.setState({ displayFilter: false });
    } else {
      this.setState({ displayFilter: true });
    }
  }

  render() {
    const {
      handleBack,
      handleSearch,
      handleExport,
      handleDate,
      values,
      messages,
      handleReset,
    } = this.props;
    const options = [
      'Download CSV',
      'Print',
    ];
    const { displayFilter } = this.state;

    return (
      <Grid container className="root receipts-header-spacing">
        <Grid item xs={12}>
          <Box className="receipts-back" onClick={handleBack}> &lt;  Back to Account Details </Box>
        </Grid>
        <Grid item xs={12} md={4} xl={6} className="receipts-title">
          Receipts List
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs>
              <Box onClick={() => this.handleDisplayFilter()} className="receipts-filter">
                <img src={DropdownIcon} alt="Search Filter" />
                Filter
              </Box>
            </Grid>
            <Grid item xs>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <MenuIcon list={options} onClick={(value) => handleExport(value)} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {
            (displayFilter)
              ? (
                <Filter
                  handleDate={handleDate}
                  values={values}
                  messages={messages}
                  handleReset={handleReset}
                  handleSearch={handleSearch}
                />
              ) : ''
         }
      </Grid>
    );
  }
}

Header.propTypes = {
  handleBack: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleExport: PropTypes.func.isRequired,
  handleDate: PropTypes.func.isRequired,
  values: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  messages: PropTypes.shape({
    startDate: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
    ]),
    endDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
  }).isRequired,
  handleReset: PropTypes.func.isRequired,
};
export default Header;
