import React from 'react';
import { Grid, Box } from '@material-ui/core';
import Moment from 'react-moment';
import DownloadIcon from '../../../assets/icons/download-receipt.svg';
import PrintIcon from '../../../assets/icons/printer-icon.svg';
import ReceiptPrint from './receiptPrint';
import SendEmailReceipt from '../../../materialUi/sendEmailReceipt';

function Receipt({ data, handlePrint }) {

  return (
    <div>
      <h3 className="registration-success-receipt-title">
        Receipt
      </h3>
      <SendEmailReceipt data={data} category="Program Registration" />
      <div style={{ textAlign: 'end' }}>
        <button type="button" className="success-receipt-button" onClick={handlePrint}>
          <img src={DownloadIcon} alt="download" />
        </button>
        <button type="button" className="success-receipt-button" onClick={handlePrint}>
          <img src={PrintIcon} alt="Print" />
        </button>
      </div>
      <div className="receipt-box">
        <Grid container>
          <Grid item xs={7}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box className="success-receipt-labels">
                Reference #:
              </Box>
              <Box className="success-receipt-values">
                {' '}
                {data.referenceNumber}
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box className="success-receipt-labels">
                Name:
              </Box>
              <Box className="success-receipt-values">
                {' '}
                {data.name}
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box className="success-receipt-labels">
                Address:
              </Box>
              <Box className="success-receipt-values">
                {' '}
                {data.address.street}
                ,
                {' '}
                {' '}
                {data.address.city}
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box className="success-receipt-labels">
                Date & Time:
              </Box>
              <Box className="success-receipt-values">
                {' '}
                {data.cardInformation ? (
                  <Moment format="MM/DD/YYYY hh:mm A">{data.cardInformation.created_at}</Moment>
                ) : (
                  <Moment format="MM/DD/YYYY hh:mm A">{new Date()}</Moment>
                )}
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box className="success-receipt-labels">
                Category:
              </Box>
              <Box className="success-receipt-values">
                {' '}
                {data.category}
              </Box>
            </Grid>
          </Grid>
          <Grid item xs>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box className="success-receipt-labels">
                Payment Method:
              </Box>
              <Box className="success-receipt-values">
                {data.paymentMethod}
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box className="success-receipt-labels">
                Card Number:
              </Box>
              <Box className="success-receipt-values">
                {' '}
                {data.cardInformation ? (
                  <>
                    ****
                    {data.cardInformation.card_details.card.last_4}
                  </>
                ) : (
                  'N/A'
                )}
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box className="success-receipt-labels">
                Card Type:
              </Box>
              <Box className="success-receipt-values">
                {' '}
                {data.cardInformation ? (
                  data.cardType
                ) : (
                  'N/A'
                )}
              </Box>
            </Grid>
            <hr />
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box className="success-receipt-labels">
                Total Amount:
              </Box>
              <Box className="success-receipt-values">
                {' '}
                $
                {' '}
                {
                  data.fee.toFixed(2)
                }
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <div className="hidden" id="receipt">
          <ReceiptPrint data={data} />
        </div>
      </div>
    </div>
  );
}

export default Receipt;
