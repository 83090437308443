import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Radio, RadioGroup, FormControlLabel, FormControl,
} from '@material-ui/core';

function Questions({ handleQuestions, questions, prescreeningError }) {
  return (
    <>
      {prescreeningError && (
      <Grid item xs={12} className="text-center covid19-prescreening-missingInformation">
            Please answer all pre-screening questions.
      </Grid>
      )}
      <Grid item xs={12} className="covid19-screening-spacing mt-4">
        <h2 className="covid19-screening-subTitle">
              Pre-Screening Questions
        </h2>
      </Grid>
      <Grid item xs={12} className="covid19-screening-spacing mt-4">
        <h3 className="covid19-screening-questions">
              1. Have you travelled outside of Canada in the past 14 days?
        </h3>
      </Grid>
      <Grid item xs={12} className="covid19-screening-spacing mt-3">
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="Have you travelled outside of Canada in the past 14 days?"
            name="travel"
            value={questions.Q1Answer}
            onChange={(value) => handleQuestions('Q1Answer', value)}
          >
            <FormControlLabel value="Yes" control={<Radio className="YesNo" />} label="Yes" />
            <FormControlLabel value="No" control={<Radio className="YesNo" />} label="No" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} className="covid19-screening-spacing mt-4">
        <h3 className="covid19-screening-questions">
              2. Have you tested positive for COVID-19 or had close
              contact with a confirmed/probable case of COVID-19 without wearing appropriate PPE?
        </h3>
      </Grid>
      <Grid item xs={12} className="covid19-screening-spacing mt-3">
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="Have you travelled outside of Canada in the past 14 days?"
            name="tested"
            value={questions.Q2Answer}
            onChange={(value) => handleQuestions('Q2Answer', value)}
          >
            <FormControlLabel value="Yes" control={<Radio className="YesNo" />} label="Yes" />
            <FormControlLabel value="No" control={<Radio className="YesNo" />} label="No" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} className="covid19-screening-spacing mt-4">
        <h3 className="covid19-screening-questions">
              3. Are you experiencing any of the following symptoms:
              fever/feverish, new or existing cough and difficulty breathing?
        </h3>
      </Grid>
      <Grid item xs={12} className="covid19-screening-spacing mt-3">
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="Have you travelled outside of Canada in the past 14 days?"
            name="symptoms"
            value={questions.Q3Answer}
            onChange={(value) => handleQuestions('Q3Answer', value)}
          >
            <FormControlLabel value="Yes" control={<Radio className="YesNo" />} label="Yes" />
            <FormControlLabel value="No" control={<Radio className="YesNo" />} label="No" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} className="covid19-screening-spacing mt-4">
        <h3 className="covid19-screening-questions">
              4. Have you had close contact with a person with an acute respiratory
               illness who has been outside of Canada in the last 14 days?
        </h3>
      </Grid>
      <Grid item xs={12} className="covid19-screening-spacing mt-3">
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="Have you travelled outside of Canada in the past 14 days?"
            name="contact"
            value={questions.Q4Answer}
            onChange={(value) => handleQuestions('Q4Answer', value)}
          >
            <FormControlLabel value="Yes" control={<Radio className="YesNo" />} label="Yes" />
            <FormControlLabel value="No" control={<Radio className="YesNo" />} label="No" />
          </RadioGroup>
        </FormControl>
      </Grid>
    </>
  );
}

Questions.propTypes = {
  handleQuestions: PropTypes.func.isRequired,
  questions: PropTypes.shape({
    Q1Answer: PropTypes.string,
    Q2Answer: PropTypes.string,
    Q3Answer: PropTypes.string,
    Q4Answer: PropTypes.string,
  }).isRequired,
  prescreeningError: PropTypes.bool.isRequired,
};

export default Questions;
