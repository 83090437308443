import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart, Bar, ResponsiveContainer, XAxis, YAxis, Tooltip, Legend,
} from 'recharts';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';

function CustomizedLabel(props) {
  const {
    x, y, value, color,
  } = props;
  return (
    <text
      x={x}
      y={y}
      dy={-10}
      dx={8}
      fontSize="14"
      fill={color}
      textAnchor="middle"
    >
      {value}
    </text>
  );
}

export default function ProgramsBarChart(props) {
  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { programsData } = props;
  const thisYear = new Date().getFullYear();
  const lastYear = new Date().getFullYear() - 1;

  let data = []

  if (programsData.length > 0) {
    data = [
      {
        name: 'Winter', [thisYear]: programsData[1].winter, [lastYear]: programsData[0].winter,
      },
      {
        name: 'Spring', [thisYear]: programsData[1].spring, [lastYear]: programsData[0].spring,
      },
      {
        name: 'Summer', [thisYear]: programsData[1].summer, [lastYear]: programsData[0].summer,
      },
      {
        name: 'Fall', [thisYear]: programsData[1].fall, [lastYear]: programsData[0].fall,
      },
    ];
  }

  if (programsData.length === 0) return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      className="graph-no-data"
    >
      No data to display
    </Grid>
  );

  return (
    <ResponsiveContainer width={mdScreen ? "83%" : "90%"} height={400} className="font-family-default">
      <BarChart
        width={100}
        height={300}
        data={data}
        margin={{
          top: 5, right: 0, left: 20, bottom: 5,
        }}
      >
        <Legend
          payload={
          [
            {
              id: 'thisYear', value: [thisYear], type: 'circle', color: '#e76f51',
            },
            {
              id: 'lastYear', value: [lastYear], type: 'circle', color: '#2a9d8f',
            },
          ]
        }
          layout="horizontal"
          verticalAlign="top"
          align="right"
          type="circle"
        />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip cursor={false} />
        <Bar dataKey={thisYear} fill="#e76f51" dot={false} radius={[100, 100, 0, 0]} barSize={20} label={<CustomizedLabel color="#e76f51" />} />
        <Bar dataKey={lastYear} fill="#2a9d8f" radius={[100, 100, 0, 0]} barSize={20} label={<CustomizedLabel color="#2a9d8f" />} />
      </BarChart>
    </ResponsiveContainer>
  );
}

ProgramsBarChart.propTypes = {
  programsData: PropTypes.arrayOf(
    PropTypes.shape({
      winter: PropTypes.number,
      spring: PropTypes.number,
      summer: PropTypes.number,
      fall: PropTypes.number,
    }),
  ).isRequired,
};
