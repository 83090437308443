import React from 'react';
import PropTypes from 'prop-types';
import Btn from '../../../materialUi/btn';

function WelcomePage({ setStep }) {
  return (
    <div className="root submissionList-welcome-wrapper">
      <h1 className="submissionList-welcome-title">
        Review Submission
      </h1>
      <h2 className="submissionList-welcome-text">
        You have already submitted your Personal Income Tax Form
        {' '}
        {new Date().getFullYear() - 1}
        .
        <br />
        <br />
        You can check the status of your submission by clicking the “View Status” button below.
      </h2>
      <div style={{ marginTop: '30px' }}>
        <Btn
          label="View Status"
          bgColor="#e76f50"
          color="white"
          colorHover="white"
          bgColorHover="#ff8c68"
          width={173}
          onClick={setStep}
          type="button"
        />
      </div>
    </div>
  );
}

WelcomePage.propTypes = {
  setStep: PropTypes.func.isRequired,
};

export default WelcomePage;
