import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import TextField from '../../../materialUi/textField';
import ActionButtons from './actionButtons';

function AddGuestForm({
  handleSubmit, openCancelAlert, handleCancelAlert,
  values, messages, errors, handleInputs,
}) {
  return (
    <Grid item xs={12}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Box className="covid19-guestForm-box">
          <Grid container>
            <Grid item xs={12} className="covid19-guestForm-title mb-3">
              <h2>General Information</h2>
            </Grid>
            <Grid item xs={12} className="covid19-guestForm-form">
              <TextField
                label="First Name"
                id="firstName"
                name="First Name"
                value={values.firstName}
                handleChanges={(value) => handleInputs('firstName', value.target.value, 'name', 'First Name')}
                helperText={messages.firstName}
                error={errors.firstName}
                readOnly={false}
              />
            </Grid>
            <Grid item xs={12} className="covid19-guestForm-form">
              <TextField
                label="Last Name"
                id="lastName"
                name="Last Name"
                value={values.lastName}
                handleChanges={(value) => handleInputs('lastName', value.target.value, 'name', 'Last Name')}
                helperText={messages.lastName}
                error={errors.lastName}
                readOnly={false}
              />
            </Grid>
            <Grid item xs={12} className="covid19-guestForm-form">
              <TextField
                label="Day Phone"
                id="dayPhone"
                name="Day Phone"
                value={values.dayPhone}
                handleChanges={(value) => handleInputs('dayPhone', value.target.value, 'phone', false)}
                helperText={messages.dayPhone}
                error={errors.dayPhone}
                readOnly={false}
              />
            </Grid>
            <Grid item xs={12} className="covid19-guestForm-form">
              <TextField
                label="Email(Optional)"
                id="email"
                name="Email"
                value={values.email}
                handleChanges={(value) => handleInputs('email', value.target.value, 'email')}
                helperText={messages.email}
                error={errors.email}
                readOnly={false}
              />
            </Grid>
            <ActionButtons
              handleSubmit={handleSubmit}
              openCancelAlert={openCancelAlert}
              handleCancelAlert={(value) => handleCancelAlert(value)}
            />
          </Grid>
        </Box>

      </Grid>
    </Grid>
  );
}

AddGuestForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  openCancelAlert: PropTypes.bool.isRequired,
  handleCancelAlert: PropTypes.func.isRequired,
  handleInputs: PropTypes.func.isRequired,
  values: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    dayPhone: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  messages: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    dayPhone: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    firstName: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
    lastName: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
    dayPhone: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
    email: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
  }).isRequired,
};

export default AddGuestForm;
