import React from 'react';
import PropTypes from 'prop-types';

function SubmissionFormExport({
  data,
}) {
  const pastYear = new Date().getFullYear() - 1;
  const renderDate = function (isoTime) {
    const timeStr = isoTime;
    const date = new Date(timeStr);
    let day = date.getDate();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    if (day < 10) {
      day = `0${day}`;
    }
    const dateStr = `${day}-${months[month - 1]}-${year}`;
    return dateStr;
  };


  const formatStatus = (status) => {
    if (status === 'missingInfo') {
      return 'Missing Info';
    }
    if (status === 'inReview') {
      return 'In Review';
    }
    return status;
  };

  const currentAddress = `${data.currentAddress.street} ${data.currentAddress.city} ${data.currentAddress.province} ${data.currentAddress.apt}` || `${'' + ' '}${data.currentAddress.postalCode}`;

  const renderExtraAddress = (item) => {
    return `${item.street} ${item.city} ${item.province} ${item.apt}` || `${'' + ' '}${item.postalCode} ${item.startDate} ${item.endDate}`;
  };
  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Form ID</th>
            <th scope="col">Submission Date</th>
            <th scope="col">Status</th>
            <th scope="col">Email</th>
            <th scope="col">Application Name</th>
            <th scope="col">Home Phone</th>
            <th scope="col">Work Phone</th>
            <th scope="col">Canada Status</th>
            <th scope="col">Language</th>
            <th scope="col">Province of Residence</th>
            <th scope="col">Marital Status</th>
            <th scope="col">Head Of Family</th>
            <th scope="col">Dependants</th>
            <th scope="col">Dependants Above Age</th>
            <th scope="col">Dependants Below Age</th>
            <th scope="col">First Time tax</th>
            <th scope="col">First Time tax Print Method</th>
            <th scope="col">Residing In Canada In {pastYear}</th>
            <th scope="col">Income Outside Canada</th>
            <th scope="col">Foreign Property In {pastYear}</th>
            <th scope="col">Current Address</th>
            {data.extraAddress && data.extraAddress.length > 0 && (
              data.extraAddress.map((item, index) => (
                <th scope="col">
                  Previous Address
                  {' '}
                  {index}
                </th>
              ))
            )}
            <th scope="col">Officer</th>
            <th scope="col">Worked in Canada in {pastYear}</th>
            <th scope="col">Different Employers in {pastYear}</th>
            <th scope="col">Canada benefits</th>
            <th scope="col">Received benefits</th>
            <th scope="col">Identifying Document</th>
            <th scope="col">Slips Of Income</th>
            <th scope="col">Documents Submission Method</th>
            <th scope="col">Bank Information</th>
            <th scope="col">Filling method</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {data.formID}
            </td>
            <td>
              {renderDate(data.createdAt)}
            </td>
            <td>
              {formatStatus(data.status)}
            </td>
            <td>
              {data.email}
            </td>
            <td>
              {data.fullName}
            </td>
            <td>
              {data.homePhone}
            </td>
            <td>
              {data.workPhone || 'N/A'}
            </td>
            <td>
              {data.canadaStatus}
            </td>
            <td>
              {data.language}
            </td>
            <td>
              {data.province}
            </td>
            <td>
              {data.maritalStatus}
            </td>
            <td>
              {data.familyHead}
            </td>
            <td>
              {data.anyDependents || 'N/A'}
            </td>
            <td>
              {data.dependantsAboveAge || 'N/A'}
            </td>
            <td>
              {data.dependantsBelowAge || 'N/A'}
            </td>
            <td>
              {data.firstTimeTax}
            </td>
            <td>
              {data.incomeOutsideCanada || 'N/A'}
            </td>
            <td>
              {data.residing}
            </td>
            <td>
              {data.incomeOutsideCanada || 'N/A'}
            </td>
            <td>
              {data.foreignProperty}
            </td>
            <td>
              {currentAddress}
            </td>
            {data.extraAddress && data.extraAddress.length > 0 && (
              data.extraAddress.map((item) => (
                <td>
                  {renderExtraAddress(item)}
                </td>
              ))
            )}
            <td>
              {data.isAnOfficer}
            </td>
            <td>
              {data.workInCanada}
            </td>
            <td>
              {data.differentEmployers}
            </td>
            <td>
              {data.receivedBenefits}
            </td>
            {data.benefits && data.benefits.length > 0 ? (
              <td>
                {data.benefits.map((item) => (
                  <>
                    {item}
-
                  </>
                ))}
              </td>
            ) : 'N/A'}
            <td>
              {data.identification}
            </td>
            {data.incomeSlip && data.incomeSlip.length > 0 ? (
              <td>
                {data.incomeSlip.map((item) => (
                  <>
                    {item}
-
                  </>
                ))}
              </td>
            ) : 'N/A'}
            <td>
              {data.submissionMethod}
            </td>
            {data.bankInformation && data.bankInformation.accountNumber && (
              <td>
                {`Transit # ${data.bankInformation.transitNumber} - Institution # ${data.bankInformation.institutionNumber} - Account # ${data.bankInformation.accountNumber}`}
              </td>
            )}
            <td>
              {data.filledTaxOption}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

SubmissionFormExport.propTypes = {

};

export default SubmissionFormExport;
