import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

function MultilineTextFields(props) {
  const {
    id, label, value, helperText, error, type, read, handleChanges, lines
  } = props;
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: (helperText.length > 0) ? '0px' : '22px',
      margin: '0 auto',
      fontSize: '15px',
      minHeight: '56px',
      '& label': {
        fontSize: '16px',
      },
    },
  }));
  const classes = useStyles();
  return (
    <TextField
      id={id}
      title={label}
      label={label}
      value={value}
      helperText={helperText}
      error={error === ''? '' : error}
      type={type}
      className={classes.root}
      variant="filled"
      InputProps={{ readOnly: read }}
      onChange={handleChanges}
      multiline
      rows={lines}
      {...props}
    />
  );
}

MultilineTextFields.prototype = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  helperText: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  type: PropTypes.string,
  handleChanges: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
}

MultilineTextFields.defaultProps = {
  readOnly: false,
  type: 'text'
}

export default MultilineTextFields;


// Example of use:
/*
<MultilineTextField
  id="qualificationSummary"
  label="Qualification Summary"
  value={values.qualificationSummary}
  helperText={messages.qualificationSummary}
  error={errors.qualificationSummary}
  handleChanges={handleInputs({ name: 'qualificationSummary', messageName: 'Qualification Summary', validationType: 'qualificationSummary', required: true })}
  maxLength="100"
/>
*/
