import axios from 'axios';
import baseURL from './baseURL';

export const ResetPassword = {
  sendResetAdminLink(email) {
    return axios.get(`${baseURL}api/sendResetAdminLink/${email}`);
  },
  sendResetMemberLink(id) {
    return axios.put(`${baseURL}api/sendResetMemberLink/${id}`, {
    });
  },
  resetPassword(userID, newPassword) {
    return axios.put(`${baseURL}api/resetPassword/${userID}`, {
      newPassword,
    });
  },
};

export default ResetPassword;
