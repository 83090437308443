import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import { Covid19API } from '../../../../API/API-covid19';
import Header from './components/header';
import CheckInsTable from './components/checkInsTable';
import TableExport from './components/tableExport';
import '../../../../css/reports.css';

export default class CheckInHistory extends Component {
  constructor(props) {
    super(props);
    const now = new Date();
    const newEndDate = now.setDate(now.getDate() + 1);
    this.state = {
      userCheckInData: [],
      status: [],
      result: [],
      selectedItems: [],
      search: '',
      values: {
        startDate: '',
        endDate: newEndDate,
        member: false,
        guest: false,
        staff: false,
        positive: false,
        negative: false,
      },
      messages: {
        startDate: '',
        endDate: '',
        member: '',
        guest: '',
        staff: '',
        positive: '',
        negative: '',
      },
    };
  }

  componentDidMount() {
    Covid19API.GET.getUserCheckIns().then((success) => {
      this.setState({ userCheckInData: success.data });
      console.log(success.data)
    }).catch((error) => {
      console.log(error);
    });
  }

  handleSelection = (userSelected, action) => {
    const { history } = this.props;
    if (action === 'View Details') {
      history.push(`/adminPortal/reports/checkInHistory/userHistory/${userSelected.id}/history`);
      sessionStorage.setItem('userInfo', JSON.stringify(userSelected));
    } else {
      history.push(`/adminPortal/reports/dailyCheckIn/${userSelected.dailyCheckInID}/history`);
      sessionStorage.setItem('userInfo', JSON.stringify(userSelected));
    }
  }


  handleDate=(name, value, type, requirement = '') => {
    const { values, messages } = this.state;
    values[name] = value;
    messages[name] = '';
    this.setState({ values, messages });
  }

  handleReset = () => {
    const { values, messages } = this.state;
    const now = new Date();
    const newEndDate = now.setDate(now.getDate() + 1);
    values.startDate = '';
    values.endDate = newEndDate;
    messages.startDate = '';
    messages.endDate = '';
    this.setState({ values, messages });
  }

  handleExport=(value) => {
    if (value === 'Print') {
      const printContent = document.getElementById('el').innerHTML;
      document.body.innerHTML = printContent;
      window.print();
      window.location = '/adminPortal/reports/checkInHistory';
    } else {
      const table = document.getElementById('el').innerHTML;
      const convertTable = table.toString().replace(/<div>/g, '')
        .toString().replace(/<\/div>/g, '')
        .toString()
        .replace(/<table class="table">/g, '')
        .toString()
        .replace(/<\/table>/g, '')
        .toString()
        .replace(/<td>/g, '')
        .toString()
        .replace(/<\/td>/g, ',')
        .toString()
        .replace(/<tr>/g, '')
        .toString()
        .replace(/<\/tr>/g, '\r\n')
        .toString()
        .replace(/<th scope="col">/g, '')
        .toString()
        .replace(/<\/th>/g, ',')
        .toString()
        .replace(/<thead>/g, '')
        .toString()
        .replace(/<\/thead>/g, '')
        .toString()
        .replace(/<tbody>/g, '')
        .toString()
        .replace(/<\/tbody>/g, '')
        .toString()
        .replace(/<\/t>/g, '')
        .toString()
        .replace(/<\/n>/g, '');
      const myLink = document.createElement('a');
      myLink.download = 'receipts.csv';
      myLink.href = `data:application/csv,${escape(convertTable)}`;
      myLink.click();
    }
  }

  handleFilter = (name, value, filterName, selectedItem) => {
    const { values, selectedItems } = this.state;
    if (value === true) {
      values[name] = value;
      this.setState({
        values,
        [filterName]: this.state[filterName].concat([selectedItem]),
        selectedItems: selectedItems.concat([
          <Box className="programsList-selectedItems" key={selectedItem} name={name} id={filterName}>
            {selectedItem}
            <i
              label="filterItems"
              className="far fa-times-circle ml-2 "
              onClick={() => this.handleFilter(name, false, filterName, selectedItem)}
              onKeyPress={() => this.handleFilter(name, false, filterName, selectedItem)}
              role="button"
              tabIndex={0}
            />
          </Box>]),
      });
    } else {
      // remove filter
      const updateArray = this.state[filterName];
      for (let i = 0; i < updateArray.length; i++) {
        if (updateArray[i] === selectedItem) {
          updateArray.splice(i, 1);
        }
      }

      // remove selectedItems
      const updateSelectedItems = this.state.selectedItems;
      for (let index = 0; index < updateSelectedItems.length; index++) {
        if (updateSelectedItems[index].key === selectedItem) {
          updateSelectedItems.splice(index, 1);
        }
      }

      values[name] = value;
      this.setState({ values, [filterName]: updateArray, selectedItems: updateSelectedItems });
    }
  }

  resetFilters = () => {
    const { values } = this.state;
    const now = new Date();
    const newEndDate = now.setDate(now.getDate() + 1);
    Object.keys(values).forEach((key) => {
      if (key === 'startDate') {
        values[key] = '';
      } else if (key === 'endDate') {
        values[key] = newEndDate;
      } else {
        values[key] = '';
      }
    });
    this.setState({
      values, status: [], selectedItems: [], search: '',
    });
  }


  render() {
    const { history } = this.props;
    const {
      userCheckInData,
      values,
      messages,
      status,
      result,
      selectedItems,
      search,
    } = this.state;
    return (
      <Grid item xs className="font-family-default">
        <Grid container>
          <Grid item xs={12}>
            <Header
              handleFilter={this.handleFilter}
              handleSearch={(value) => this.setState({ search: value })}
              selectedItems={selectedItems}
              handleExport={this.handleExport}
              handleDate={this.handleDate}
              values={values}
              messages={messages}
              handleReset={this.handleReset}
              status={status}
              result={result}
              resetFilters={this.resetFilters}
              history={history}
            />
            <CheckInsTable
              data={userCheckInData}
              handleClick={(userSelected) => this.handleSelection(userSelected)}
              values={values}
              searchItem={search}
              sortStatus={status}
              sortResult={result}
            />
          </Grid>
          <Grid className="table-export" id="el">
            <TableExport
              data={userCheckInData}
              searchItem={search}
              values={values}
              sortStatus={status}
              sortResult={result}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

CheckInHistory.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
