import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import StackedBarChart from './stackedBarChart';
import { DATE } from '../../../../utils/dateController';

export default function CheckInHistory(props) {

  const {
    dailyCheckInPerMonth, handleYearSelection, selectedYear,
  } = props.data;
  
  const splitMonth = DATE(true).split('-');
  const thisYear = splitMonth[2];
  const numberOfYears = thisYear - 2020;

  const years = [];
  for (let i = 0; i <= numberOfYears; i++) {
    years.push(2020 + i);
  }
  return (
    <Grid container className="root dashboard-tabs-spacing-newMember">
      <Grid item xs={6} className="dashboard-tabs-title">
      Check-In History
      </Grid>
      <Grid item xs={6} className="dashboard-tabs-datashow">

        <div className="dropdown">
          <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span className="dashboard-label-dropdown">{selectedYear}</span>
          </button>
          <div className="dropdown-menu dashboard-item-dropdown" aria-labelledby="dropdownMenuButton">
            {years.map((option) => <span key={option} className="dropdown-item " onClick={() => handleYearSelection(option)}>{option}</span>)}
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Paper className="dashboard-tabs-paper">
          <StackedBarChart dailyCheckInPerMonth={dailyCheckInPerMonth} />
          <span className="dashboard-totalVisits">Total Visits</span>
        </Paper>
      </Grid>
    </Grid>
  );
}

CheckInHistory.propTypes = {
  data: PropTypes.shape({
    selectedYear: PropTypes.number,
    dailyCheckInPerMonth: PropTypes.objectOf(PropTypes.any),
    handleYearSelection: PropTypes.func,
  }).isRequired,
};
