import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Script from 'react-load-script';
import TextField from '../../../../../materialUi/textField';
import '../../../../../css/registerMember.css';

const GOOGLE_API_KEY = `${process.env.REACT_APP_GOOGLE_API_KEY}`;

function Address(props) {
  const {
    formState,
    setValue,
    clearErrors,
    setError,
    register,
    data,
  } = props;

  const [apartment, setApartment] = React.useState();
  const [apartmentError, setApartmentError] = React.useState(null);

  const [currentAddress, setCurrentAddress] = React.useState({});
  const [defaultPostalCode, setDefaultPostalCode] = React.useState('');

  const handleApartment = (event) => {
    const { value } = event.target;
    if (value.length > 20) {
      setApartmentError('Max length 20');
    } else {
      setApartmentError(null);
    }
    setApartment(value);
    currentAddress.apt = value;
    setCurrentAddress(currentAddress);
    setValue('currentAddress', currentAddress);
  };

  const { errors } = formState;
  const options = { types: ['address'], componentRestrictions: { country: 'ca' } };
  let autocomplete;

  const handleScriptLoad = () => {
    autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'), options);
    /* global google */
    autocomplete.addListener('place_changed', handlePlaceSelect);
  };

  const registerCurrentAddress = (address) => {
    setCurrentAddress(address);
    setValue('currentAddress', address);
    clearErrors('currentAddress');
  };

  const setAddressErrors = () => {
    setValue('currentAddress', null, { shouldValidate: true });
    setError('currentAddress', {
      type: 'invalidAddress',
      message: 'Invalid Address',
    });
  };

  const handleAddress = (event) => {
    const { value } = event.target;
    setCurrentAddress(value);
    setError('currentAddress', {
      type: 'invalidAddress',
      message: 'Please start typing the street number, and select the address from the list',
    });
  };

  // <--- Address autocomplete validation --->
  const handlePlaceSelect = () => {
    const addressObject = autocomplete.getPlace();
    const address = addressObject.address_components;
    if (address) {
      if (
        address[0] == null
        || address[1] == null
        || address[2] == null
        || address[5] == null
        || address[6] == null
      ) {
        setAddressErrors();
      } else {
        let postalCode;
        for (let i = 0; i < address.length; i++) {
          for (let j = 0; j < address[i].types.length; j++) {
            if (address[i].types[j] === 'postal_code') {
              postalCode = address[i].long_name;
            }
          }
        }
        const currentAddressObj = {
          street: `${address[0].long_name} ${address[1].long_name}`,
          city: address[2].long_name,
          province: address[5].long_name,
          postalCode,
          apt: apartment,
        };
        registerCurrentAddress(currentAddressObj);
        setDefaultPostalCode(postalCode);
      }
    } else {
      setAddressErrors();
    }
  };

  React.useEffect(() => {
    if (!data.currentAddress) {
      register('currentAddress', { required: 'Address is required' });
    } else {
      setCurrentAddress(data.currentAddress);
      setDefaultPostalCode(data.currentAddress.postalCode);
      setApartment(data.currentAddress.apt);
    }
  }, [data, register]);

  return (
    <Grid container spacing={2}>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`}
        onLoad={handleScriptLoad}
      />
      <Grid item xs={12} lg>
        <TextField
          label="Current Address"
          id="autocomplete"
          name="currentAddress"
          value={(currentAddress.street) ? `${currentAddress.street}, ${currentAddress.city}, ${currentAddress.province}` : null}
          handleChanges={handleAddress}
          helperText={errors.currentAddress && errors.currentAddress.message}
          error={!!errors.currentAddress}
          readOnly={false}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={2}>
        <TextField
          label="Apt#"
          id="apt#"
          name="Apt Number"
          value={apartment}
          handleChanges={handleApartment}
          helperText={apartmentError || 'Optional'}
          error={apartmentError}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4} xl={3}>
        <TextField
          label="Postal Code"
          id="postalCode"
          name="Postal Code"
          value={defaultPostalCode}
          handleChanges={() => {}}
          helperText=""
          error={false}
          readOnly
        />
      </Grid>
    </Grid>
  );
}

Address.propTypes = {
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  formState: PropTypes.shape({
    errors: PropTypes.any,
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  data: PropTypes.shape(
    {
      currentAddress: PropTypes.string,
    },
  ).isRequired,
};


export default Address;
