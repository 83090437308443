import React from 'react';
import PropTypes, { shape } from 'prop-types';
import { Grid } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function CustomRadioGroup(props) {
  const {
    listOptions, value, handleChange, ariaLabel, name, isRegistration, inline
  } = props;

  if (isRegistration === true) {
    return (
      <RadioGroup
        aria-label={ariaLabel}
        name={name}
        value={value}
        onChange={handleChange}
        {...props}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          {listOptions.map((items) => (
            <Grid item xs={6}>
              <FormControlLabel
                key={items.label}
                value={items.value}
                control={<Radio />}
                label={items.label}
              />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    );
  }
  if (inline === true) {
    return (
      <RadioGroup
        aria-label={ariaLabel}
        name={name}
        value={value}
        onChange={handleChange}
        {...props}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          {listOptions.map((items) => (
            <Grid item>
              <FormControlLabel
                key={items.label}
                value={items.value}
                control={<Radio />}
                label={items.label}
              />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    );
  }
  return (
    <RadioGroup
      aria-label={ariaLabel}
      name={name}
      value={value}
      onChange={handleChange}
      {...props}
    >
      {listOptions.map((item) => (
        <FormControlLabel
          key={item.label}
          value={item.value}
          control={<Radio />}
          label={item.label}
        />
      ))}
    </RadioGroup>
  );
}

CustomRadioGroup.propTypes = {
  listOptions: PropTypes.arrayOf(shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]),
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]),
  })).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  handleChange: PropTypes.func,
  ariaLabel: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isRegistration: PropTypes.bool,
  inline: PropTypes.bool,
};

CustomRadioGroup.defaultProps = {
  handleChange: () => {},
  value: '',
  isRegistration: false,
  inline: false,
};

export default CustomRadioGroup;
