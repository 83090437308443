import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid, Box } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import logo from '../../assets/SCCC_LOGO_SMALL.png';
import BackButtons from '../../materialUi/backButton';
import TextField from '../../materialUi/textField';
import Button from '../../materialUi/btn';
import { userAPI } from '../../API/API-user';
import { EmailAPI } from '../../API/API-Email';
import './login.css';

function PreRegistration({ history }) {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  React.useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' });
  }, []);

  const [errorMessage, setErrorMessage] = React.useState();

  const handleRegistration = (data) => {
    const dataToSend = data;
    dataToSend.userType = 'provisory member';
    userAPI.POST.addProvisoryMember(dataToSend)
      .then((response) => {
        if (response.data.error) {
          setErrorMessage(response.data.error);
        } else {
          console.log(dataToSend.email);
          EmailAPI.EMAIL.emailTaxRegistration(dataToSend.email)
            .then((success) => {
              console.log(success);
            })
            .catch((error) => {
              console.log(error);
            });
          history.push('/preRegistrationSuccess');
        }
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Grid container className="logins-wrapper">
      <BackButtons label="Back to onboarding" handleClick={() => history.push('/')} />
      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Box>
            <img src={logo} alt="Sccc Logo" className="login-logo" />
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Box className="login-boxes">
            <form onSubmit={handleSubmit(handleRegistration)}>
              <h1 className="login-title">Join the SCCC Portal</h1>
              <h2 className="login-sub-title">Gain access to SCCC’s many services and become a member - for FREE!</h2>
              <div className="login-error-message">{errorMessage}</div>
              <Grid container>
                <Grid item xs={12}>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: 'Email is required',
                      maxLength: { value: 100, message: 'Max length 100' },
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'Invalid Email Format',
                      },
                    }}
                    render={({ field }) => (
                      <TextField id="email" type="search" name="email" label="Email address" helperText={errors.email ? errors.email.message : ''} error={!!errors.email} width="100%" {...field} />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    rules={{
                      maxLength: { value: 100, message: 'Max length 100' },
                      pattern: {
                        value: /^[\]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/i,
                        message: 'Invalid Phone Format',
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        id="phoneNumber"
                        type="search"
                        name="phoneNumber"
                        label="Phone number (Optional)"
                        helperText={errors.phoneNumber ? errors.phoneNumber.message : ''}
                        error={!!errors.phoneNumber}
                        width="100%"
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: 'Password is required',
                      maxLength: { value: 100, message: 'Max length 100' },
                      minLength: {
                        value: 8,
                        message: 'Password must have at least 8 characters',
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        id="password"
                        type="password"
                        name="password"
                        label="Password"
                        helperText={errors.password ? errors.password.message : ''}
                        error={!!errors.password}
                        width="100%"
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} className="login-button-wrapper">
                  <Button label="SIGN UP" type="submit" onClick={() => {}} bgColor="#e76f50" color="white" bgColorHover="#ff8c68" colorHover="white" width="100%" height={42} />
                </Grid>
              </Grid>
            </form>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Box className="login-boxes login-helper-box">
            <h3 className="login-title">Already a Member?</h3>
            <Grid item xs={12} className="login-button-wrapper">
              <Button
                label="SIGN IN"
                type="button"
                onClick={() => history.push('/memberLogin')}
                bgColor="white"
                color="#e76f50"
                bgColorHover="#eceff1"
                variant="outlined"
                borderColor="#e76f50"
                width="100%"
                height={42}
              />
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

PreRegistration.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(PreRegistration);
