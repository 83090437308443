import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import RegistrationHeader from './components/registrationHeader';
import RegistrationTable from './components/registrationTable';
import RegistrationTableExport from './components/registrationTableExport';
import AttendeesTable from './components/attendeesTable';
import AttendeesHeader from './components/attendeesHeader';
import WaitingListHeader from './components/waitingListHeader';
import WaitingListTable from './components/waitingListTable';
import WaitingListTableExport from './components/waitingListTableExport';
import { ProgramRegistrationAPI } from '../../../API/API-programRegistration';
import { ProgramsAPI } from '../../../API/API-programs';
import { EmailAPI } from '../../../API/API-Email';
import { CONVERTTIME } from '../../../utils/dateController';
import { ProgramAttendance } from '../../../API/API-programAttendance';
import { ProgramWeekScheduleAPI } from '../../../API/API-programWeekSchedule';
import AttendeesTableExport from './components/attendeesExportTable';
import Alert from '../../../materialUi/Alerts';
import DeleteParticipant from './components/deleteParticipant';
import DefaultDialog from '../../../materialUi/defaultDialog';
import '../../../css/registrationList.css';

export default class RegistrationList extends Component {
  constructor(props) {
    super(props);
    const { match } = this.props;
    this.state = {
      openDialog: false,
      dialogBody: '',
      dialogTitle: '',
      buttonOne: '',
      buttonTwo: '',
      programID: match.params.programID,
      programName: '',
      numberOfSpots: 0,
      attendanceNumberCheck: false,
      fee: 0,
      programData: '',
      weeklySchedule: null,
      selectedWeek: 0,
      openAlert: false,
      alertLocation: '',
      alertTitle: '',
      alertBody: '',
      alertStay: '',
      alertClose: '',
      openDeleteParticipant: false,
      participantData: {},
      registrationData: '',
      tableSelected: 'Attendees',
      attendanceData: '',
      selectedItems: [],
      search: '',
      status: [],
      mousePopOver: false,
      headerTotals: {
        registrations: 0,
        attendees: 0,
        waitingList: 0,
      },
      values: {
        approved: false,
        pending: false,
      },
    };
  }

  componentDidMount() {
    window.scroll({ top: 0, behavior: 'smooth' });
    const { programID, headerTotals } = this.state;

    // get program information
    ProgramsAPI.GET.getProgramByID(programID)
      .then((success) => {
        if (success.data.program[0].weeklyProgram) {
          // Weekly schedule API
          ProgramWeekScheduleAPI.GET.getProgramWeekScheduleByProgramID(programID)
            .then((response) => {
              this.setState({
                weeklySchedule: response.data,
              });
              console.log(response.data)
            }).catch((error) => {
              if (error.response) {
                this.setState({
                  openAlert: true,
                  alertLocation: '/adminPortal/programs',
                  alertTitle: error.response.data.errorTitle,
                  alertBody: error.response.data.errorMessage,
                  alertClose: 'CLOSE',
                });
              }
            });
        }
        this.setState({
          programName: success.data.program[0].name,
          programEnrolmentEndDate: success.data.program[0].enrollmentEndDate,
          numberOfSpots: success.data.program[0].numberOfSpots,
          fee: success.data.program[0].fee,
          programData: success.data.program[0],
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          openAlert: true,
          alertLocation: '/adminPortal/programs',
          alertTitle: 'Program not found!',
          alertBody: 'We could not find the program. Please try again later!',
          alertClose: 'CLOSE',
        });
      });

    // get the registration to the program
    ProgramRegistrationAPI.GET.getRegistrationsByProgramID(programID)
      .then((success) => {
        headerTotals.registrations = success.data.length;
        headerTotals.waitingList = this.calculateWaitingList(success.data);
        this.setState({ registrationData: success.data, headerTotals });
      })
      .catch((error) => {
        console.log(error);
      });

    // get the attendance data
    ProgramAttendance.GET.getProgramAttendanceByProgramID(programID)
      .then((success) => {
        headerTotals.attendees = success.data.familyMembers.length;
        this.setState({
          attendanceData: success.data,
          attendanceNumberCheck: success.data.familyMembers.length < 1,
          headerTotals,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  calculateWaitingList = (registrationData) => {
    let count = 0;
    for (let i = 0; i < registrationData.length; i++) {
      if (registrationData[i].programWaitingList && registrationData[i].programWaitingList.length > 0) {
        count += 1;
      }
    }
    return count;
  };

  handleSearch = (value) => {
    this.setState({ search: value });
  };

  handleFilter = (name, value, selectedStatus) => {
    const { values, status, selectedItems } = this.state;
    if (value === true) {
      values[name] = value;
      this.setState({
        values,
        status: status.concat(selectedStatus),
        selectedItems: selectedItems.concat([
          <span className="selcted-items " key={selectedStatus} name={name}>
            {selectedStatus}
            <i className="far fa-times-circle ml-2 " onClick={() => this.handleFilter(name, false, selectedStatus)} />
          </span>,
        ]),
      });
    } else {
      // remove filter
      const updateArray = status;
      for (let i = 0; i < updateArray.length; i++) {
        if (updateArray[i] === selectedStatus) {
          updateArray.splice(i, 1);
        }
      }

      // remove selectedItems
      const updateSelectedItems = selectedItems;
      for (let index = 0; index < updateSelectedItems.length; index++) {
        if (updateSelectedItems[index].key === selectedStatus) {
          updateSelectedItems.splice(index, 1);
        }
      }

      values[name] = value;
      this.setState({
        values,
        status: updateArray,
        selectedItems: updateSelectedItems,
      });
    }
  };

  resetFilters = () => {
    const { values } = this.state;
    Object.keys(values).forEach((key) => {
      values[key] = false;
    });
    this.setState({ values, search: '', selectedItems: [] });
  };

  handleRowSelection = (membershipID, paymentStatus, id) => {
    const { userData } = this.props;
    // const { programID } = this.state;
    if (userData.userType !== 'Program Staff' && userData.userType !== 'Program Admin') {
      // TODO: Removed for now because of the waiting list
      // if (paymentStatus === 'Pending') {
      //   history.push(`/adminPortal/programs/verifyRegistration/${id}/${programID}/${membershipID}`);
      // } else {
      window.location = `/adminPortal/membership/verifyMembership/${membershipID}`;
    }
  };

  handleExport = (name) => {
    const { programID } = this.state;
    const { history } = this.props;
    if (name === 'Program Details') {
      history.push(`/adminPortal/programs/programDetails/${programID}`);
    } else if (name === 'Attendance List') {
      history.push(`/adminPortal/programs/submitedAttendances/${programID}`);
    } else if (name === 'Print') {
      const printContent = document.getElementById('tableExport').innerHTML;
      document.body.innerHTML = printContent;
      window.print();
      window.location = `/adminPortal/programs/registrationList/${programID}`;
    } else {
      const date = new Date();
      const table = document.getElementById('tableExport').innerHTML;
      const convertTable = table
        .toString()
        .replace(/<div>/g, '')
        .toString()
        .replace(/<\/div>/g, '')
        .toString()
        .replace(/<table class="table">/g, '')
        .toString()
        .replace(/<\/table>/g, '')
        .toString()
        .replace(/<td>/g, '')
        .toString()
        .replace(/<\/td>/g, ',')
        .toString()
        .replace(/<tr>/g, '')
        .toString()
        .replace(/<\/tr>/g, '\r\n')
        .toString()
        .replace(/<th scope="col">/g, '')
        .toString()
        .replace(/<\/th>/g, ',')
        .toString()
        .replace(/<thead>/g, '')
        .toString()
        .replace(/<\/thead>/g, '')
        .toString()
        .replace(/<tbody>/g, '')
        .toString()
        .replace(/<\/tbody>/g, '')
        .toString()
        .replace(/<\/t>/g, '')
        .toString()
        .replace(/<\/n>/g, '');
      const myLink = document.createElement('a');
      myLink.download = `${CONVERTTIME(date, true)}-registeredMembersList.csv`;
      myLink.href = `data:application/csv,${escape(convertTable)}`;
      myLink.click();
    }
  };

  handleAddNewRegistrant = () => {
    const { programID, programData, weeklySchedule } = this.state;
    const { history, userData } = this.props;
    const { userType } = userData.userData;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const enrollmentEndDate = new Date(programData.enrollmentEndDate);
    enrollmentEndDate.setHours(0, 0, 0, 0);
    const enrollmentStartDate = new Date(programData.enrollmentStartDate);
    enrollmentStartDate.setHours(0, 0, 0, 0);
    if (enrollmentStartDate.getTime() > today.getTime()) {
      return this.setState({
        openAlert: true,
        alertLocation: `/adminPortal/programs/registrationList/${programID}`,
        alertTitle: 'Error',
        alertBody: `Program enrolment date starts on ${CONVERTTIME(enrollmentStartDate, true)}`,
        alertClose: 'hidden',
        alertStay: 'CLOSE',
      });
    }
    if (!programData.dropIn && enrollmentEndDate.getTime() < today.getTime()) {
      return this.setState({
        openAlert: true,
        alertLocation: `/adminPortal/programs/registrationList/${programID}`,
        alertTitle: 'Error',
        alertBody: 'Program enrolment date has passed.',
        alertClose: 'hidden',
        alertStay: 'CLOSE',
      });
    }
    // if (numberOfSpots < 1) {
    //   return this.setState({
    //     openAlert: true,
    //     alertLocation: `/adminPortal/programs/registrationList/${programID}`,
    //     alertTitle: 'Error',
    //     alertBody: 'There is no more spots lefts in this program.',
    //     alertClose: 'hidden',
    //     alertStay: 'CLOSE',
    //   });
    // }


    // TODO: Needs to be removed after we merge the adminPortal with scccPortal
    const typeOfPortal = () => {
      if (userType.toLowerCase() !== 'member' && userType.toLowerCase() !== 'public') {
        return 'adminPortal';
      }
      return 'scccPortal';
    };

    history.push(`/${typeOfPortal()}/programs/programRegistration/${programID}/Registration List`);
  };

  handleDeleteParticipant = (participantObj) => {
    const {
      fee, programID, numberOfSpots, programData, weeklySchedule, selectedWeek, registrationData,
    } = this.state;
    participantObj.fee = fee;
    participantObj.programID = programID;
    participantObj.numberOfSpots = numberOfSpots;
    participantObj.programAttendanceID = programData.programAttendance;
    if (weeklySchedule) {
      participantObj.weeklySchedule = weeklySchedule;
      participantObj.selectedWeek = weeklySchedule[selectedWeek];
    }
    this.setState({
      openDeleteParticipant: true,
      participantData: participantObj,
    });
  };

  handleSelectParticipant = (membershipID, familyMember_id) => {
    const { history, match } = this.props;
    history.push(`/adminPortal/programs/registrationDetails/${match.params.programID}/${membershipID}/${familyMember_id}`);
  };

  sendWaitingListEmail = async (registrationObj) => {
    const { programName, programID } = this.state;
    registrationObj.programName = programName;
    registrationObj.programID = programID;
    await EmailAPI.EMAIL.emailWaitingList(registrationObj)
      .then((success) => {
        console.log(success);
        this.setState({
          openAlert: true,
          alertLocation: '/adminPortal/programs',
          alertTitle: 'Success!',
          alertBody: `Email successfully sent to ${registrationObj.email}`,
          alertClose: 'hidden',
          alertStay: 'CLOSE',
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          openAlert: true,
          alertLocation: '/adminPortal/programs',
          alertTitle: 'Server Error!',
          alertBody: ' Please try again later!',
          alertClose: 'hidden',
          alertStay: 'CLOSE',
        });
      });
  };

  validateSpots = (registrationObj) => {
    const { membershipType } = registrationObj.membershipData;
    const { associateSpots, communitySpots, programID } = this.state.programData;
    const requestedSpots = registrationObj.familyMembersData.length;
    let result = true;
    if (membershipType.toLowerCase() === 'associate' && associateSpots < requestedSpots) result =  false;
    if (membershipType.toLowerCase() === 'community' && communitySpots < requestedSpots) result =  false;
    if (!result) {
      this.setState({
        openAlert: true,
        alertLocation: `/adminPortal/programs/editProgram/${programID}`,
        alertTitle: 'Cannot add attendees',
        alertBody: `  There are no ${membershipType.toLowerCase()} spots left. Please add ${requestedSpots} more ${membershipType.toLowerCase()} spots to the program`,
        alertClose: 'GO TO EDIT PROGRAM',
        alertStay: 'CLOSE',
      });
    }
    return result
  }

  sendErrorMessage = (title, message) => {
    this.setState({
      openAlert: true,
      alertLocation: '/adminPortal/programs',
      alertTitle: title,
      alertBody: message,
      alertClose: 'hidden',
      alertStay: 'CLOSE',
    });
  };

  getMemberFee = (membershipType) => {
    const { programData: { communityFee, associateFee } } = this.state;
    const type = membershipType.toLowerCase();
    if (type === 'associate') return associateFee;
    return communityFee;
  };

  checkFreeProgram = (registrationObj) => {
    const { programData: { variableFee, fee } } = this.state;
    const { membershipData: { membershipType } } =  registrationObj;
    const memberFee = variableFee !== 'yes' ? fee : this.getMemberFee(membershipType);
    if (memberFee === 0) return true
    return false;
  };

  sendPaymentEmail = async (registrationObj) => {
    const { email, phone } = registrationObj;
    if (!email && !phone) return this.sendErrorMessage('Error', 'There are no contact information on the registration');
    if (!email) return this.sendErrorMessage('Error', `There is no email related to this registration, please contact the participant by phone ${phone}`);
    return await this.sendWaitingListEmail(registrationObj);
  };

  submitFreeProgram = async (registrationObj) => {
    const { programID, programData } = this.state;
    const { waitingListData, programRegistrationID, familyMembersData } = registrationObj;
    const freePayment = true;
    registrationObj.waitingListID = waitingListData[0]._id
    registrationObj.programID = programID;
    registrationObj.familyMembers = familyMembersData;
    registrationObj.programData = [programData];
    await ProgramRegistrationAPI.PUT.programWaitingListPayment(registrationObj, programRegistrationID, freePayment)
    .then((response) => {
      console.log(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
  };

  handleFreeProgram = async (registrationObj) => {
    this.setState({
      openDialog: true,
      dialogTitle: 'Confirm',
      dialogBody: 'This is a free program. Do you wish to add the participant to the program attendance list?',
      registrationObj
    });
  };

  handleDialogActions = async (buttonName) => {
    const { registrationObj } = this.state;
    if (buttonName === 'buttonOne') return this.setState({ openDialog: false })
    await this.submitFreeProgram(registrationObj);
    window.location.reload();
  }

  handleSendNotification = async (value ,registrationObj) => {
    const { history } = this.props;
    const { programID } = this.state;
    const { membershipID, waitingListData } = registrationObj;
    const waitingListID = waitingListData[0]._id;

    if (!this.validateSpots(registrationObj)) return false;

    if (this.checkFreeProgram(registrationObj)) return await this.handleFreeProgram(registrationObj);

    if (value === 'Send payment email') return await this.sendPaymentEmail(registrationObj);

    return history.push(
      {
        pathname: `/adminPortal/programs/waitingList/registration/${programID}/${waitingListID}/${membershipID}/0`,  
        state: { from: this.props.location.pathname }
      }
    );
  };


  render() {
    const { history, userData } = this.props;
    const {
      programName,
      tableSelected,
      values,
      selectedItems,
      attendanceData,
      weeklySchedule,
      selectedWeek,
      search,
      registrationData,
      status,
      openAlert,
      alertLocation,
      alertTitle,
      alertBody,
      alertStay,
      alertClose,
      attendanceNumberCheck,
      openDeleteParticipant,
      participantData,
      headerTotals,
      dialogTitle,
      dialogBody,
      openDialog,
    } = this.state;
    return (
      <Grid item xs className="font-family-default">
        <DefaultDialog 
          title={dialogTitle} 
          body={dialogBody} 
          open={openDialog}
          buttonOne="Cancel"
          buttonTwo="Confirm"
          handleChanges={this.handleDialogActions}
        />
        {tableSelected === 'Attendees' && (
          <AttendeesHeader
            programName={programName}
            handleChangeTable={(value) => this.setState({ tableSelected: value })}
            handleFilter={this.handleFilter}
            search={this.handleSearch}
            handleAddNewProgram={this.handleAddNewRegistrant}
            handleExport={this.handleExport}
            handleBack={() => history.push('/adminPortal/programs/available')}
            values={values}
            resetFilters={this.resetFilters}
            userType={userData.userData.userType}
            attendanceNumberCheck={attendanceNumberCheck}
            headerTotals={headerTotals}
            weeklySchedule={weeklySchedule}
            selectedWeek={selectedWeek}
            handleSelectedWeek={(index) => this.setState({ selectedWeek: index })}
          />
        )}
        
        {tableSelected === 'Program Registrations' && (
          <RegistrationHeader
            programName={programName}
            handleChangeTable={(value) => this.setState({ tableSelected: value })}
            handleFilter={this.handleFilter}
            search={this.handleSearch}
            handleAddNewProgram={this.handleAddNewRegistrant}
            handleExport={this.handleExport}
            handleBack={() => history.push('/adminPortal/programs/available')}
            values={values}
            resetFilters={this.resetFilters}
            selectedItems={selectedItems}
            userType={userData.userData.userType}
            attendanceNumberCheck={attendanceNumberCheck}
            headerTotals={headerTotals}
            weeklySchedule={weeklySchedule}
          />
        )}
        {tableSelected === 'Waiting List' && (
          <WaitingListHeader
            programName={programName}
            handleChangeTable={(value) => this.setState({ tableSelected: value })}
            handleFilter={this.handleFilter}
            search={this.handleSearch}
            handleAddNewProgram={this.handleAddNewRegistrant}
            handleExport={this.handleExport}
            handleBack={() => history.push('/adminPortal/programs/available')}
            values={values}
            resetFilters={this.resetFilters}
            selectedItems={selectedItems}
            userType={userData.userData.userType}
            attendanceNumberCheck={attendanceNumberCheck}
            headerTotals={headerTotals}
          />
        )}

        {tableSelected === 'Attendees' && (
          <>
            <AttendeesTable
              attendanceData={weeklySchedule ? weeklySchedule[selectedWeek] : attendanceData}
              sortSearch={search}
              handleDeleteParticipant={this.handleDeleteParticipant}
              handleSelectParticipant={(membershipID, familyMember_id) => this.handleSelectParticipant(membershipID, familyMember_id)}
            />
          </>
        )}

        {tableSelected === 'Program Registrations'
        && (
        <RegistrationTable
          registrationData={registrationData}
          sortSearch={search}
          sortStatus={status}
          handleClick={this.handleRowSelection}
        />
        )}
        {tableSelected === 'Waiting List' && <WaitingListTable registrationData={registrationData} sortSearch={search} sortStatus={status} handleSendNotification={this.handleSendNotification} />}

        {tableSelected === 'Attendees' && (
          <div id="tableExport" className="hidden">
            <AttendeesTableExport
              attendanceData={weeklySchedule ? weeklySchedule[selectedWeek] : attendanceData}
              sortSearch={search}
            />
          </div>
        )}

        {tableSelected === 'Program Registrations' && (
          <div id="tableExport" className="hidden">
            <RegistrationTableExport registrationData={registrationData} sortSearch={search} sortStatus={status} />
          </div>
        )}
        {tableSelected === 'Waiting List' && (
          <div id="tableExport" className="hidden">
            <WaitingListTableExport registrationData={registrationData} sortSearch={search} sortStatus={status} />
          </div>
        )}

        <Alert open={openAlert} close={() => this.setState({ openAlert: false })} location={alertLocation} title={alertTitle} body={alertBody} stay={alertStay} leave={alertClose} />
        <DeleteParticipant open={openDeleteParticipant} close={() => this.setState({ openDeleteParticipant: false })} data={participantData} />
      </Grid>
    );
  }
}

RegistrationList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programID: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  userData: PropTypes.shape({
    userType: PropTypes.string,
  }),
};
