import React from 'react';
import PropTypes from 'prop-types';
import SuperAdminRoutes from './superAdmin';
import ReceptionistRoutes from './receptionist';
import ProgramAdminRoutes from './programAdmin';
import MembershipAdminRoutes from './membershipAdmin';
import ProgramStaffRoutes from './programStaff';
import SecurityRoutes from './security';
import TaxVolunteerRoutes from './taxVolunteer';

const AssignRoutes = function (userData) {
  if (userData !== '') {
    if (userData.userType === 'Super Admin') {
      return <SuperAdminRoutes userData={userData} />;
    }
    if (userData.userType === 'Receptionist') {
      return <ReceptionistRoutes userData={userData} />;
    }
    if (userData.userType === 'Program Admin') {
      return <ProgramAdminRoutes userData={userData} />;
    }
    if (userData.userType === 'Membership Admin') {
      return <MembershipAdminRoutes userData={userData} />;
    }
    if (userData.userType === 'Program Staff') {
      return <ProgramStaffRoutes userData={userData} />;
    }
    if (userData.userType === 'Tax Volunteer') {
      return <TaxVolunteerRoutes userData={userData} />;
    }
    if (userData.userType === 'Security') {
      return <SecurityRoutes userData={userData} />;
    }
  }
};

AssignRoutes.propTypes = {
  userData: PropTypes.shape({
    userType: PropTypes.string,
  }).isRequired,
};

export default AssignRoutes;
