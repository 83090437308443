import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import TextField from '../../../../../materialUi/textField';
import RadioGroup from '../../../../../materialUi/radioGroup';

function SectionThree(props) {
  const {
    control,
    formState: { errors },
    setValue,
    register,
    data,
    clearErrors,
    getValues,
    watch
  } = props;

  const [dependant, setDependant] = React.useState(data.anyDependents ? data.anyDependents : 'no');

  watch('dependantsBelowAge');
  watch('dependantsAboveAge');

  const YesNoQuestions = [
    {
      value: 'yes',
      label: 'Yes',
    },
    {
      value: 'no',
      label: 'No',
    },
  ];

  const handleDependant = (event) => {
    const { value } = event.target;
    if (value === 'no') {
      setValue('dependantsBelowAge', 1);
      setValue('dependantsAboveAge', 2);
    } else {
      setValue('dependantsBelowAge', '', {
        shouldValidate: false,
      });
      setValue('dependantsAboveAge', '', {
        shouldValidate: false,
      });
      clearErrors('dependantsBelowAge');
      clearErrors('dependantsAboveAge');
    }
    setValue('anyDependents', value);
    setDependant(value);
  };

  const validateDependents = (name, value) => {
    if (!value) return 'Required field';
    if (name === 'dependantsBelowAge' && parseInt(value, 10) === 0 && parseInt(getValues('dependantsAboveAge'), 10) === 0) return 'Please select at least one dependent'
    if (name === 'dependantsAboveAge' && parseInt(value, 10) === 0 && parseInt(getValues('dependantsBelowAge'), 10) === 0) return 'Please select at least one dependent'
    return true
  }

  React.useEffect(() => {
    register('anyDependents');
    setValue('anyDependents', 'no');
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <div className="taxes-question">6c. Do you have any dependants (Children)?</div>
      </Grid>
      <Grid item xs={12}>
        <RadioGroup
          listOptions={YesNoQuestions}
          handleChange={handleDependant}
          value={dependant} ariaLabel="6c. Do you have any dependants (Children)? "
          name="dependant"
        />
      </Grid>
      {dependant === 'yes' && (
        <Grid container>
          <Grid item xs={12}>
            <div className="taxes-question">6d. How many of them are less than 18 years of age?</div>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="dependantsBelowAge"
              control={control}
              rules={{
                validate: (value) => validateDependents('dependantsBelowAge', value),
                required: 'Required field',
                maxLength: { value: 2, message: 'Max length 2' },
                min: { value: 0, message: 'Min value 0' },
              }}
              render={({ field }) => (
                <TextField
                  id="dependantsBelowAge"
                  type="number"
                  name="dependantsBelowAge"
                  label="Below 18"
                  defaultValue={1}
                  helperText={errors.dependantsBelowAge ? errors.dependantsBelowAge.message : ''}
                  error={!!errors.dependantsBelowAge}
                  width="120px"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <div className="taxes-question">6e. How many are above 18?</div>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="dependantsAboveAge"
              control={control}
              rules={{
                required: 'Required field',
                maxLength: { value: 2, message: 'Max length 2' },
                validate: (value) => validateDependents('dependantsAboveAge', value),
                min: { value: 0, message: 'Min value 0' },
              }}
              render={({ field }) => (
                <TextField
                  id="dependantsAboveAge"
                  type="number"
                  name="dependantsAboveAge"
                  label="Above 18"
                  defaultValue={1}
                  helperText={errors.dependantsAboveAge ? errors.dependantsAboveAge.message : ''}
                  error={!!errors.dependantsAboveAge}
                  width="120px"
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}

SectionThree.propTypes = {
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  formState: PropTypes.shape({
    errors: PropTypes.any,
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
  data: PropTypes.shape({
    anyDependents: PropTypes.string,
  }).isRequired,
};

export default SectionThree;
