import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Header from './components/header';
import MemberAttendanceTable from './components/memberAttendanceTable';
import { MemberAttendanceAPI } from '../../../API/API-memberAttendance';
import Alert from '../../../materialUi/Alerts';
import TableExport from './components/tableExport';

export default class AttendanceList extends Component {
  constructor(props) {
    super(props);
    const { match } = this.props;
    this.state = {
      attendanceListDate: match.params.attendanceListDate,
      programID: match.params.programID,
      programName: '',
      attendanceListID: '',
      attendanceData: '',
      search: '',
      presentCouter: 0,
      absentCouter: 0,
      openAlert: false,
      alertLocation: '',
      alertTitle: '',
      alertBody: '',
      alertStay: '',
    };
  }

  componentDidMount() {
    const { attendanceListDate, programID } = this.state;
    // <--- get all programs on DB --->
    MemberAttendanceAPI.GET.getMembersAttendanceByDateAndProgramID(attendanceListDate, programID)
      .then((success) => {
        const attendanceDataFromSession = JSON.parse(sessionStorage.getItem(`editattendence_${programID}`));
        let dataToSaveOnState;
        // if there is data on session then change the variable to store on state
        if (attendanceDataFromSession) {
          dataToSaveOnState = attendanceDataFromSession;
        } else {
          dataToSaveOnState = success.data;
        }
        let present = 0; let
          absent = 0;
        for (let i = 0; i < dataToSaveOnState.length; i++) {
          if (success.data[i].status === 'Present') {
            present++;
          } else {
            absent++;
          }
        }
        this.setState({
          attendanceData: dataToSaveOnState,
          presentCouter: present,
          absentCouter: absent,
          programName: dataToSaveOnState[0].programName,
          attendanceListID: dataToSaveOnState[0].attendanceListID,
        });
        // save on session
        sessionStorage.setItem(`editattendence_${programID}`, JSON.stringify(dataToSaveOnState));
      }).catch((error) => {
        console.log(error);
      });
  }

  handleRowSelection = (membershipID) => {
    const { userData } = this.props;
    if (userData.userType.userType !== 'Program Staff' && userData.userType.userType !== 'Program Admin') {
      window.location = `/adminPortal/membership/verifyMembership/${membershipID}`;
    }
  }

  handleExport=(value) => {
    const { attendanceListDate, programID } = this.state;
    if (value === 'Print') {
      const printContent = document.getElementById('tableExport').innerHTML;
      document.body.innerHTML = printContent;
      window.print();
      window.location = `/adminPortal/programs/attendanceListDetails/${attendanceListDate}/${programID}`;
    } else {
      const table = document.getElementById('tableExport').innerHTML;
      const convertTable = table.toString().replace(/<div>/g, '')
        .toString().replace(/<\/div>/g, '')
        .toString()
        .replace(/<table class="table">/g, '')
        .toString()
        .replace(/<\/table>/g, '')
        .toString()
        .replace(/<td>/g, '')
        .toString()
        .replace(/<\/td>/g, ',')
        .toString()
        .replace(/<tr>/g, '')
        .toString()
        .replace(/<\/tr>/g, '\r\n')
        .toString()
        .replace(/<th scope="col">/g, '')
        .toString()
        .replace(/<\/th>/g, ',')
        .toString()
        .replace(/<thead>/g, '')
        .toString()
        .replace(/<\/thead>/g, '')
        .toString()
        .replace(/<tbody>/g, '')
        .toString()
        .replace(/<\/tbody>/g, '')
        .toString()
        .replace(/<\/t>/g, '')
        .toString()
        .replace(/<\/n>/g, '');
      const myLink = document.createElement('a');
      myLink.download = 'AttendanceList.csv';
      myLink.href = `data:application/csv,${escape(convertTable)}`;
      myLink.click();
    }
  }

  handleStatusClick = (status, index) => {
    const {
      attendanceData,
      presentCouter,
      absentCouter,
      programID,
    } = this.state;
    let present = 0; let
      absent = 0;
    const attendenceDataToUpdate = attendanceData;
    // check the status of family member and count them
    if (status === 'Present' && attendenceDataToUpdate[index].status !== 'Present') {
      if (attendenceDataToUpdate[index].status === 'Absent') {
        present++;
        absent--;
      } else {
        present++;
      }
    } else if (status === 'Absent' && attendenceDataToUpdate[index].status !== 'Absent') {
      if (attendenceDataToUpdate[index].status === 'Present') {
        absent++;
        present--;
      } else {
        absent++;
      }
    }
    // add the status to the family member
    attendenceDataToUpdate[index].status = status;
    attendenceDataToUpdate.present = parseInt(presentCouter, 10) + present;
    attendenceDataToUpdate.absent = parseInt(absentCouter, 10) + absent;

    // add to the state
    this.setState({
      presentCouter: parseInt(presentCouter, 10) + present,
      absentCouter: parseInt(absentCouter, 10) + absent,
      attendanceData: attendenceDataToUpdate,
    });
    // save on session
    sessionStorage.setItem(`editattendence_${programID}`, JSON.stringify(attendenceDataToUpdate));
  }

  handleSubmit = () => {
    const {
      attendanceData,
      attendanceListID,
      attendanceListDate,
      presentCouter,
      absentCouter,
      programID,
    } = this.state;

    const { history } = this.props;


    const attendanceListToSave = {
      attendanceListID,
      present: presentCouter,
      absent: absentCouter,
      memberAttendanceObj: attendanceData,
    };
    MemberAttendanceAPI.PUT.updateMemberAttendance(attendanceListDate, attendanceListID, attendanceListToSave)
      .then((success) => {
        console.log(success.data);
        sessionStorage.removeItem(`editattendence_${programID}`);
        history.push(`/adminPortal/programs/submitedAttendances/${programID}`);
      }).catch((error) => {
        console.log(error);
        this.setState({
          openAlert: true,
          alertLocation: '/adminPortal/programs',
          alertTitle: 'Server Error!',
          alertBody: 'Please try again later!',
          alertStay: 'CLOSE',
        });
      });
  }

  render() {
    const {
      programID,
      attendanceListDate,
      presentCouter,
      absentCouter,
      openAlert,
      alertLocation,
      alertTitle,
      alertBody,
      alertStay,
      attendanceData,
      search,
      programName,
    } = this.state;

    const { history } = this.props;
    return (
      <Grid item xs className="font-family-default">
        <Header
          search={(value) => this.setState({ search: value })}
          resetFilters={() => this.setState({ search: '' })}
          handleBack={() => history.push(`/adminPortal/programs/submitedAttendances/${programID}`)}
          handleSearch={this.handleSearch}
          presentCouter={presentCouter}
          absentCouter={absentCouter}
          handleSubmit={this.handleSubmit}
          handleExport={this.handleExport}
          programName={programName}
        />
        <Alert
          open={openAlert}
          close={() => this.setState({ openAlert: false })}
          location={alertLocation}
          title={alertTitle}
          body={alertBody}
          stay={alertStay}
          leave="hidden"
        />
        <MemberAttendanceTable
          search={search}
          data={attendanceData}
          attendanceListDate={attendanceListDate}
          handleClick={this.handleRowSelection}
          statusClick={this.handleStatusClick}
        />
        <div id="tableExport" className="hidden"><TableExport data={attendanceData} search={search} /></div>
      </Grid>
    );
  }
}

AttendanceList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      attendanceListDate: PropTypes.string,
      programID: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  userData: PropTypes.shape({
    userType: PropTypes.string,
  }).isRequired,
};
