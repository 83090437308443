import React from 'react';
import PropTypes from 'prop-types';
import {
  XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area,
} from 'recharts';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';
import Loading from '../../../../materialUi/loading';


export default function NewMembersChart(props) {
  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { numberOfMembersPerMonth } = props;
  let data = [
    {
      name: 'Jan', Memberships: numberOfMembersPerMonth[0],
    },
    {
      name: 'Feb', Memberships: numberOfMembersPerMonth[1],
    },
    {
      name: 'Mar', Memberships: numberOfMembersPerMonth[2],
    },
    {
      name: 'Apr', Memberships: numberOfMembersPerMonth[3],
    },
    {
      name: 'May', Memberships: numberOfMembersPerMonth[4],
    },
    {
      name: 'Jun', Memberships: numberOfMembersPerMonth[5],
    },
    {
      name: 'Jul', Memberships: numberOfMembersPerMonth[6],
    },
    {
      name: 'Aug', Memberships: numberOfMembersPerMonth[7],
    },
    {
      name: 'Sep', Memberships: numberOfMembersPerMonth[8],
    },
    {
      name: 'Oct', Memberships: numberOfMembersPerMonth[9],
    },
    {
      name: 'Nov', Memberships: numberOfMembersPerMonth[10],
    },
    {
      name: 'Dec', Memberships: numberOfMembersPerMonth[11],
    },
  ];

  if (!numberOfMembersPerMonth) return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Loading/>
    </Grid>
  );

    return (
      <ResponsiveContainer width={mdScreen ? "83%" : "90%"} height={400}>
        <AreaChart
          data={data}
          margin={{
            top: 40, right: 20, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
          <XAxis dataKey="name" />
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="15%" stopColor="#2a9d8f" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#2a9d8f" stopOpacity={0} />
            </linearGradient>
          </defs>
          <YAxis />
          <Tooltip />
          <Legend />
          <Area dataKey="Memberships" stroke="#2a9d8f" activeDot={{ r: 5 }} fillOpacity={1} fill="url(#colorUv)" />
        </AreaChart>
      </ResponsiveContainer>
    );
}

NewMembersChart.propTypes = {
  numberOfMembersPerMonth: PropTypes.arrayOf(PropTypes.number).isRequired,
};
