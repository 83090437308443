/* eslint-disable no-lonely-if */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableRow from '@material-ui/core/TableRow';
import { calculateAgeGroup } from '../../../../utils/dateController';
import '../../../../css/receipts.css';

function createData(membershipID, firstName, lastName, gender, age, phone, address, status, apt) {
  return {
    membershipID,
    firstName,
    lastName,
    gender,
    age,
    phone,
    address,
    status,
    apt,
  };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

// columns titles
const headRows = [
  {
    id: 'membershipID',
    numeric: false,
    disablePadding: true,
    label: 'Membership ID',
  },
  {
    id: 'firstName',
    numeric: false,
    disablePadding: false,
    label: 'First Name',
  },
  {
    id: 'lastName',
    numeric: false,
    disablePadding: false,
    label: 'Last Name',
  },
  {
    id: 'gender',
    numeric: false,
    disablePadding: false,
    label: 'Gender',
  },
  {
    id: 'age',
    numeric: false,
    disablePadding: false,
    label: 'Age Group',
  },
  {
    id: 'phone',
    numeric: false,
    disablePadding: false,
    label: 'Phone',
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: false,
    label: 'Address',
  },
  {
    id: 'apt',
    numeric: false,
    disablePadding: false,
    label: 'Apt',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="bg-light">
      <TableRow>
        {headRows.map((row, index) => (
          <TableCell
            key={index}
            align={row.numeric ? 'center' : 'left'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.firstName ? order : false}
            className="table-cell"
          >
            <TableSortLabel active={orderBy === row.firstName} direction={order} onClick={createSortHandler(row.id)}>
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

export default function MembersTable({ data, handleClick, search, sortAgeGroup, sortStatus }) {
  const rows = [];

  const formatAddress = (memberAddress) => {
    const address = `${memberAddress.street}, ${memberAddress.city} ${memberAddress.province}, ${memberAddress.postalCode}`;
    return address;
  };

  const getApt = (memberAddress) => {
    const apt = `${memberAddress.apt && memberAddress.apt}`;
    return apt;
  }

  const sortSearchTextField = (userData) =>
    userData.firstName.toLowerCase().includes(search.toLowerCase()) || userData.lastName.toLowerCase().includes(search.toLowerCase()) || userData.phone.toLowerCase().includes(search.toLowerCase());

  const sortByAge = (userAge, age) => userAge === age;

  const sortByStatus = (userStatus, userExpirationDate, status) => {
    const expirationDate = new Date(userExpirationDate);
    const todayDate = new Date();
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 30);
    if (status === 'expired') {
      if (expirationDate < todayDate) {
        return true;
      }
    } else if (status === 'expiring') {
      if (expirationDate > todayDate && expirationDate < endDate) {
        return true;
      }
    } else if (status === 'unverified') {
      if (userStatus.toLowerCase() === 'unverified') {
        return true;
      }
    } else {
      if (expirationDate > todayDate && expirationDate < endDate) {
        return false;
      }
      if (expirationDate < todayDate) {
        return false;
      }
      return userStatus.toLowerCase() === status.toLowerCase();
    }
  };

  const getMembershipStatus = (membershipData) => {
    const membershipDate = new Date(membershipData.expirationDate);
    const todayDate = new Date();
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 30);

    if (membershipData.status === 'Unverified') {
      return 'Unverified';
    }
    if (membershipDate > todayDate && membershipDate < endDate) {
      return 'Expiring';
    }
    if (membershipDate < todayDate) {
      return 'Expired';
    }
    return 'Active';
  };

  const pushFilterResults = (userData) => {
    rows.push(
      createData(
        userData.membershipID,
        userData.firstName,
        userData.lastName,
        userData.gender,
        calculateAgeGroup(userData.dateOfBirth),
        userData.phone,
        formatAddress(userData.membershipData[0].address),
        getMembershipStatus(userData.membershipData[0]),
        getApt(userData.membershipData[0].address)
      )
    );
  };

  if (data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].membershipData[0]) {
        if (data[i].membershipData[0].status !== 'inactive') {
          if (search) {
            if (sortSearchTextField(data[i])) {
              if (sortAgeGroup.length > 0) {
                sortAgeGroup.map((age) => {
                  if (sortByAge(calculateAgeGroup(data[i].dateOfBirth), age)) {
                    if (sortStatus.length > 0) {
                      sortStatus.map((status) => {
                        if (sortByStatus(data[i].membershipData[0].status, data[i].membershipData[0].expirationDate, status)) {
                          return pushFilterResults(data[i]);
                        }
                        return '';
                      });
                    } else {
                      return pushFilterResults(data[i]);
                    }
                  }
                  return '';
                });
              } else {
                if (sortStatus.length > 0) {
                  sortStatus.map((status) => {
                    if (sortByStatus(data[i].membershipData[0].status, data[i].membershipData[0].expirationDate, status)) {
                      return pushFilterResults(data[i]);
                    }
                    return '';
                  });
                } else {
                  pushFilterResults(data[i]);
                }
              }
            }
          } else {
            if (sortAgeGroup.length > 0) {
              sortAgeGroup.map((age) => {
                if (sortByAge(calculateAgeGroup(data[i].dateOfBirth), age)) {
                  if (sortStatus.length > 0) {
                    sortStatus.map((status) => {
                      if (sortByStatus(data[i].membershipData[0].status, data[i].membershipData[0].expirationDate, status)) {
                        return pushFilterResults(data[i]);
                      }
                      return '';
                    });
                  } else {
                    return pushFilterResults(data[i]);
                  }
                }
                return '';
              });
            } else {
              if (sortStatus.length > 0) {
                sortStatus.map((status) => {
                  if (sortByStatus(data[i].membershipData[0].status, data[i].membershipData[0].expirationDate, status)) {
                    return pushFilterResults(data[i]);
                  }
                  return '';
                });
              } else {
                pushFilterResults(data[i]);
              }
            }
          }
        }
      }
    }
  }

  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('firstName');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  return (
    <Grid className="font-family-default">
      <Grid className={classes.tableWrapper}>
        <Table className={classes.table} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
          <EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={data.length} />
          <TableBody>
            {stableSort(rows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow hover role="button" tabIndex={1} className="table-tabSelection" key={index} onClick={() => handleClick(row.membershipID)}>
                  <TableCell align="left">{row.membershipID}</TableCell>
                  <TableCell align="left" className="text-capitalize">{row.firstName}</TableCell>
                  <TableCell align="left" className="text-capitalize">{row.lastName}</TableCell>
                  <TableCell align="left">{row.gender}</TableCell>
                  <TableCell align="left">{row.age}</TableCell>
                  <TableCell align="left">{row.phone}</TableCell>
                  <TableCell align="left">{row.address}</TableCell>
                  <TableCell align="left">{row.apt}</TableCell>
                  <TableCell align="center" style={{ width: '15px' }}>
                    <div className={`memberList-${row.status}`}>{row.status}</div>
                  </TableCell>
                </TableRow>
              ))}
            {emptyRows > 0 && <TableRow style={{ height: 49 * emptyRows }} />}
          </TableBody>
        </Table>
      </Grid>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Grid>
  );
}

MembersTable.propTypes = {
  data: PropTypes.shape({
    membersData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    guestsData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    staffData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  }).isRequired,
  handleClick: PropTypes.func.isRequired,
  values: PropTypes.shape({
    preSchool: PropTypes.bool,
    child: PropTypes.bool,
    youth: PropTypes.bool,
    adult: PropTypes.bool,
    senior: PropTypes.bool,
  }).isRequired,
  sortAgeGroup: PropTypes.arrayOf(PropTypes.any).isRequired,
  sortStatus: PropTypes.arrayOf(PropTypes.any).isRequired,
  search: PropTypes.string.isRequired,
};
