import React from 'react';
import { Grid } from '@material-ui/core';
import Dropdown from '../../dropdown';
import PaymentForm from '../../paymentForm';
import ApproveDiscountDialog from './approveDiscount';
import Button from '../../btn';
import '../../../css/payment.css';

function PaymentMethod({
  handleSubmitPayment,
  initialFee,
  handleFee,
  userData,
  paymentStatus,
}) {
  const { userType } = userData.userData;
  const [paymentMethod, setPaymentMethod] = React.useState('');
  const [paymentMethodMessage, setPaymentMethodMessage] = React.useState(null);
  const [discount, setDiscount] = React.useState('-');
  const [openDialog, setOpenDialog] = React.useState(false);

  const discountList = [
    { name: '-' },
    { name: '10%' },
    { name: '20%' },
    { name: '30%' },
    { name: '40%' },
    { name: '50%' },
    { name: 'Free' },
  ];

  const publicPaymentOptions = [
    { name: 'Credit card' }
  ]

  const adminPaymentOptions = [
    { name: 'Credit card' },
    { name: 'Debit card' },
    { name: 'Apple pay' },
    { name: 'Cash' }
  ]

  const [payment] = React.useState(userType.toLowerCase() === 'member' ? publicPaymentOptions : adminPaymentOptions);


  const calculateDiscount = (percentage) => ((percentage / 100) * -initialFee + initialFee).toFixed(2);

  const getDiscount = (selectedDiscount) => {
    if (selectedDiscount === '-') {
      return initialFee;
    }
    if (selectedDiscount === '10%') {
      return calculateDiscount(10);
    }
    if (selectedDiscount === '20%') {
      return calculateDiscount(20);
    }
    if (selectedDiscount === '30%') {
      return calculateDiscount(30);
    }
    if (selectedDiscount === '40%') {
      return calculateDiscount(40);
    }
    if (selectedDiscount === '50%') {
      return calculateDiscount(50);
    }
    setPaymentMethod('Cash');
    setPaymentMethodMessage(null);
    return 0;
  };

  const handleDiscount = (event) => {
    const { value } = event.target;
    const newFee = getDiscount(value);
    if (value === '50%' || value === 'Free') {
      setOpenDialog(true);
    } else {
      handleFee(newFee);
    }
    setDiscount(value);
  };

  const handleDiscountPasswordValidation = (validation) => {
    if (validation) {
      const newFee = getDiscount(discount);
      handleFee(newFee);
      setOpenDialog(false);
    }
  };

  const handleSendCardInformation = (nonce, paymentMethod, full_name) => {
    handleSubmitPayment({
      type: 'card',
      nonce,
      cardHolderName: full_name,
    });
  };

  const handleSendCashPayment = () => {
    if (!paymentMethod) {
      setPaymentMethodMessage('Please, select one payment option.');
    } else {
      handleSubmitPayment({
        type: paymentMethod.toLowerCase(),
        nonce: false,
        cardHolderName: false,
      });
    }
  };

  const handlePaymentMethod = (event) => {
    setPaymentMethod(event.target.value);
    setPaymentMethodMessage(null);
  };


  return (
    <Grid item xs={12} md className="paymentMethod-wrapper">
      <Grid container spacing={2}>
        <Grid item xs={12} md={userType.toLowerCase() !== 'member' ? 6 : 12}>
          <Dropdown
            name="Payment"
            value={paymentMethod}
            handleChanges={handlePaymentMethod}
            error={!!paymentMethodMessage}
            helperText={paymentMethodMessage || ''}
            label="Payment method"
            listName={payment}
          />
        </Grid>
        {userType.toLowerCase() !== 'member' && (
          <Grid item xs={12} md={6}>
            <Dropdown
              name="Discount"
              value={discount}
              handleChanges={handleDiscount}
              error={false}
              helperText=""
              label="Discount(%)"
              listName={discountList}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          {paymentMethod === 'Credit card' ? (
            <PaymentForm
              handleSaveOnDB={handleSendCardInformation}
              paymentStatus={paymentStatus}
            />
          ) : (
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                label="SUBMIT PAYMENT"
                bgColor="#e76f50"
                color="white"
                bgColorHover="#ff8c68"
                colorHover="white"
                width={165}
                onClick={handleSendCashPayment}
              />
            </Grid>

          )}
        </Grid>
        <ApproveDiscountDialog
          open={openDialog}
          close={() => setOpenDialog(false)}
          handleValidation={handleDiscountPasswordValidation}
        />
      </Grid>
    </Grid>
  );
}

export default PaymentMethod;
