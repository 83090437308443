import React, { Component } from 'react';
import '../../../css/membershipCard.css';
import { Grid, Box } from '@material-ui/core';
import Btn from '../../../materialUi/btn';
import { membershipAPI } from '../../../API/API-membership';
import { BootstrapTextField } from '../../../materialUi/bootstrapInputs';
import { validateField } from '../../../utils/fieldsValidation';
import Alert from '../../../materialUi/Alerts';

class membershipCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAlert: false,
      alertClose: '',
      alertTitle: '',
      alertBody: '',
      membershipID: this.props.match.params.membershipID,
      primaryMemberID: '',
      membershipCard: '',
      membershipCardError: false,
      membershipCardMessage: '',
    };
  }

  componentDidMount() {
    membershipAPI.GET.getMembershipByID(this.props.match.params.membershipID)
      .then((success) => {
        this.setState({
          membershipCard: success.data.primaryMember.membershipCard,
          primaryMemberID: success.data.primaryMember._id,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          openAlert: true,
          alertTitle: 'User not found!',
          alertBody: 'We could not find the membership. Please try again later!',
          alertClose: 'CLOSE',
        });
      });
  }

  handleSubmit = () => {
    const { membershipCardError, membershipID, membershipCard, primaryMemberID } = this.state;
    if (membershipCardError === '' || membershipCardError === true) {
      this.setState({ membershipCardMessage: <div className="text-danger">Membership card cannot be empty!</div> });
    } else {
      membershipAPI.PUT.changeMembershipCard(membershipID, membershipCard, primaryMemberID)
        .then((success) => {
          console.log('success', success);
          window.location = `/adminPortal/membership/verifyMembership/${membershipID}`;
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
  };

  handleBack = () => {
    const { membershipID } = this.state;
    this.props.history.push(`/adminPortal/membership/verifyMembership/${membershipID}`);
  };

  handleInputs = (name, value, type, requirement = '') => {
    if (validateField(value, type, requirement) === true) {
      this.setState({
        membershipCard: value,
        membershipCardError: false,
        membershipCardMessage: validateField(value, type, requirement),
      });
    } else {
      this.setState({
        membershipCard: value,
        membershipCardError: true,
        membershipCardMessage: validateField(value, type, requirement),
      });
    }
  };

  render() {
    const { membershipCard, membershipCardMessage, membershipCardError, alertClose, openAlert, alertTitle, alertBody } = this.state;
    return (
      <Grid className="root changeCard-spacing">
        <Grid container>
          <Grid item xs>
            <Grid className="Back-changeCard">
              <Box onClick={this.handleBack}>&lt; Back to Account Details </Box>
            </Grid>
            <Grid item xs={12} className="Membership-card">
              Membership Card
            </Grid>
            <Grid item xs={12} lg={8} className="New-member-has-been-2 mb-5 mt-3 ">
              To successfully complete the membership, scan the barcode on the back of the membership card.
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box className="Barcode">Bar Code:</Box>
              <BootstrapTextField
                id="membershipCard"
                type="text"
                name="membershipCard"
                value={membershipCard}
                handleChanges={(value) => this.handleInputs('membershipCard', value.target.value, 'name', 'Membership Card')}
                message={membershipCardMessage}
                readOnly={false}
                error={membershipCardError}
              />
            </Grid>
            <Grid item xs={0} lg={6} />
            <Grid item xs={12}>
              <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <Grid className="ChangeCard-btn">
                  <Box className="ChangeCard-btn-done">
                    <Btn label="DONE" onClick={this.handleSubmit} bgColor="#e76f50" color="white" bgColorHover="#ff8c68" colorHover="white" width={128} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Alert open={openAlert} close={() => this.setState({ openAlert: false })} location="/adminPortal/membership" title={alertTitle} body={alertBody} stay="" leave={alertClose} />
      </Grid>
    );
  }
}

export default membershipCard;
