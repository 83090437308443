import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  PieChart, Pie, Cell, ResponsiveContainer,
} from 'recharts';


const COLORS = ['#2a9d8f', '#e76f51', '#e9c46a'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {(percent * 100).toFixed(0) > 1 && (
        `${(percent * 100).toFixed(0)}%`
      )}
    </text>
  );
};

export default class StatusPie extends PureComponent {
  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/3Leoa7f4/';

  render() {
    const {
      active,
      expiring,
      expired,
    } = this.props;
    const data = [
      { name: 'Active', value: active },
      { name: 'Expired', value: expired },
      { name: 'Expiring', value: expiring },
    ];
    return (
      <ResponsiveContainer width="100%" height={300}>
        <PieChart onMouseEnter={this.onPieEnter}>
          <Pie
            data={data}
            cx={window.innerWidth > 800 ? 180 : 140}
            cy={160}
            innerRadius={30}
            outerRadius={130}
            fill="#8884d8"
            paddingAngle={3}
            dataKey="value"
            labelLine={false}
            label={renderCustomizedLabel}
          >
            {
              data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
            }
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  }
}


StatusPie.propTypes = {
  active: PropTypes.number.isRequired,
  expiring: PropTypes.number.isRequired,
  expired: PropTypes.number.isRequired,
};
