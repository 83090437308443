import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { CONVERTTIME } from '../../../../utils/dateController';
import '../../../../css/registrationList.css';

function createData(
  membershipID,
  firstName,
  lastName,
  email,
  phone,
  registrationDate,
  paymentStatus,
  id
) {
  return {
    membershipID,
    firstName,
    lastName,
    email,
    phone,
    registrationDate,
    paymentStatus,
    id,
  };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  {
    id: 'membershipID',
    numeric: false,
    disablePadding: true,
    label: 'Membership ID',
  },
  {
    id: 'firstName',
    numeric: false,
    disablePadding: false,
    label: 'First name',
  },
  {
    id: 'lastName',
    numeric: false,
    disablePadding: false,
    label: 'Last Name',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'phone',
    numeric: false,
    disablePadding: false,
    label: 'Phone',
  },
  {
    id: 'registrationDate',
    numeric: false,
    disablePadding: false,
    label: 'Registration Date',
  },
  {
    id: 'paymentStatus',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="bg-light">
      <TableRow>
        <TableCell padding="checkbox" className="check" />
        {headRows.map((row) => (
          <TableCell
            key={row.id}
            align={row.numeric ? 'center' : 'left'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
            className="table-cell"
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => <div />;

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

export default function RegistrationTable({
  registrationData,
  sortStatus,
  handleClick,
  sortSearch,
}) {
  const rows = [];
  let i;
  for (i = 0; i < registrationData.length; i++) {
    if (sortStatus.length > 0) {
      // eslint-disable-next-line
      sortStatus.map((status) => {
        if (status.toLowerCase() === registrationData[i].paymentStatus.toLowerCase()) {
          if (sortSearch) {
            if (
              registrationData[i].membershipData.familyMembersInformation[0].firstName.toLowerCase().includes(sortSearch.toLowerCase()) ||
              registrationData[i].membershipData.familyMembersInformation[0].lastName.toLowerCase().includes(sortSearch.toLowerCase()) ||
              registrationData[i].email.toLowerCase().includes(sortSearch.toLowerCase()) ||
              registrationData[i].membershipID.includes(sortSearch)
            ) {
              rows.push(
                createData(
                  registrationData[i].membershipID,
                  registrationData[i].membershipData.familyMembersInformation[0].firstName,
                  registrationData[i].membershipData.familyMembersInformation[0].firstName,
                  registrationData[i].membershipData.email,
                  registrationData[i].familyMembersData[0].phone
                    ? registrationData[i].familyMembersData[0].phone
                    : '',
                  CONVERTTIME(registrationData[i].registrationDate, true),
                  registrationData[i].paymentStatus,
                  registrationData[i]._id
                )
              );
            }
          } else {
            rows.push(
              createData(
                registrationData[i].membershipID,
                registrationData[i].membershipData.familyMembersInformation[0].firstName,
                registrationData[i].membershipData.familyMembersInformation[0].lastName,
                registrationData[i].membershipData.email,
                registrationData[i].familyMembersData[0].phone
                  ? registrationData[i].familyMembersData[0].phone
                  : '',
                CONVERTTIME(registrationData[i].registrationDate, true),
                registrationData[i].paymentStatus,
                registrationData[i]._id
              )
            );
          }
        }
      });
    } else if (sortSearch) {
      if (
        registrationData[i].membershipData.familyMembersInformation[0].firstName.toLowerCase().includes(sortSearch.toLowerCase()) ||
        registrationData[i].membershipData.familyMembersInformation[0].lastName.toLowerCase().includes(sortSearch.toLowerCase()) ||
        registrationData[i].membershipData.email.toLowerCase().includes(sortSearch.toLowerCase()) ||
        registrationData[i].membershipID.includes(sortSearch)
      ) {
        rows.push(
          createData(
            registrationData[i].membershipID,
            registrationData[i].membershipData.familyMembersInformation[0].firstName,
            registrationData[i].membershipData.familyMembersInformation[0].lastName,
            registrationData[i].membershipData.email,
            registrationData[i].familyMembersData[0].phone
              ? registrationData[i].familyMembersData[0].phone
              : '',
            CONVERTTIME(registrationData[i].registrationDate, true),
            registrationData[i].paymentStatus,
            registrationData[i]._id
          )
        );
      }
    } else {
      rows.push(
        createData(
          registrationData[i].membershipID,
          registrationData[i].membershipData.familyMembersInformation[0].firstName,
          registrationData[i].membershipData.familyMembersInformation[0].lastName,
          registrationData[i].membershipData.email,
          registrationData[i].familyMembersData[0].phone
            ? registrationData[i].familyMembersData[0].phone
            : '',
          CONVERTTIME(registrationData[i].registrationDate, true),
          registrationData[i].paymentStatus,
          registrationData[i]._id
        )
      );
    }
  }

  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('registrationDate');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={() => handleClick(row.membershipID, row.paymentStatus, row.id)}
                      role="button"
                      tabIndex={0}
                      className="table-tabSelection"
                      key={index}
                    >
                      <TableCell padding="checkbox" className="check" />
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        className="table-cell"
                      >
                        {row.membershipID}
                      </TableCell>
                      <TableCell align="left" className="text-capitalize">{row.firstName}</TableCell>
                      <TableCell align="left" className="text-capitalize">{row.lastName}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">{row.phone}</TableCell>
                      <TableCell align="left">{row.registrationDate}</TableCell>
                      <TableCell align="center">
                        {row.paymentStatus.toLowerCase() === 'pending' && (
                          <div className="registrationList-pending">{row.paymentStatus}</div>
                        )}
                        {row.paymentStatus.toLowerCase() === 'approved' && (
                          <div className="registrationList-paid">{row.paymentStatus}</div>
                        )}
                        {row.paymentStatus.toLowerCase() === 'canceled' && (
                          <div className="registrationList-canceled">{row.paymentStatus}</div>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && <TableRow style={{ height: 49 * emptyRows }} />}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

RegistrationTable.propTypes = {
  sortStatus: PropTypes.arrayOf(PropTypes.string).isRequired,
  sortSearch: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  registrationData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      familyMembers: PropTypes.arrayOf(
        PropTypes.shape({
          phone: PropTypes.string,
          firstName: PropTypes.string,
          lastName: PropTypes.string,
          membershipID: PropTypes.string,
        })
      ),
    }),
  ]).isRequired,
};
