import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RadioGroup from '../../../../../materialUi/radioGroup';
import CurrentAddress from './currentAddress';
import ExtraAddress from './extraAddress';

function SectionTwo(props) {
  const {
    control,
    formState: { errors },
    register,
    setValue,
    data,
    clearErrors,
    unregister,
    getValues,
    watch
  } = props;


  const pastYear = new Date().getFullYear() - 1;

  const YesNoQuestions = [
    {
      value: 'yes',
      label: 'Yes',
    },
    {
      value: 'no',
      label: 'No',
    },
  ];

  const [extraAddress, setLocalExtraAddress] = React.useState([]);

  const handleAddNewAddress = () => {
    if (!getValues('extraAddress')) return setValue('extraAddress', [{}]);
    const copyExtraAddress = getValues('extraAddress');
    copyExtraAddress.push({});
    setValue('extraAddress', copyExtraAddress);
  };

  const clearDeletedAddressErrors = (deletedAddressNumber) => {
    clearErrors(`extraAddress${deletedAddressNumber}`);
    clearErrors(`extraAddressStartDate${deletedAddressNumber}`);
    clearErrors(`extraAddressEndDate${deletedAddressNumber }`);
    clearErrors(`extraAddressPostalCode${deletedAddressNumber}`);
    clearErrors(`extraAddressApt${deletedAddressNumber}`);
    setValue(`extraAddress${deletedAddressNumber}`, null)
    unregister(`extraAddress${deletedAddressNumber}`);
    unregister(`extraAddressStartDate${deletedAddressNumber}`);
    unregister(`extraAddressEndDate${deletedAddressNumber}`);
    unregister(`extraAddressPostalCode${deletedAddressNumber}`);
    unregister(`extraAddressApt${deletedAddressNumber}`);
  };

  const handleRemoveAddress = () => {
    if (!getValues('extraAddress') || getValues('extraAddress').length === 0) return false;
    const copyExtraAddress = getValues('extraAddress');
    copyExtraAddress.pop();
    setValue('extraAddress', copyExtraAddress);
    clearDeletedAddressErrors(copyExtraAddress.length);
  };

  // const handleAddExtraAddress = (newExtraAddress, addressID) => {
  //   const copyExtraAddress = getValues('extraAddress');
  //   copyExtraAddress[addressID - 1] = newExtraAddress;
  //   setValue('extraAddress', copyExtraAddress);
  // };

  React.useEffect(() => {
    if (data.extraAddress && data.extraAddress.length > 0) {
      register('extraAddress');
      watch('extraAddress');
      setValue('extraAddress', data.extraAddress)
      // console.log(data.extraAddress);
    } else {
      register('extraAddress');
      watch('extraAddress');
    }
  }, [data]);

  return (
    <>
      <Grid item xs={12}>
        <div className="taxes-question">
              9. From January 1st,
          {' '}
          {pastYear}
          {' '}
until December 31st,
          {' '}
          {pastYear}
          {' '}
(Tax year),
              have you lived in the same home address?
        </div>
      </Grid>
      <Grid item xs={12}>
        <span className="custom-error">
          {(errors.sameHomeAddress) && errors.sameHomeAddress.message}
        </span>
        <Controller
          name="sameHomeAddress"
          control={control}
          rules={{
            required: 'Please select One',
          }}
          render={({ field }) => (
            <RadioGroup
              listOptions={YesNoQuestions}
              ariaLabel={`9. From January 1st, ${pastYear} until December 31st, ${pastYear} (Tax year), have you lived in the same home address?`}
              name="sameHomeAddress"
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">
            9a. If yes, please provide the complete address.
        </div>
      </Grid>
      <Grid item xs={12}>
        <CurrentAddress {...props} data={data} />
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">
              9b. From January 1st,
          {' '}
          {pastYear}
          {' '}
until December 31st,
          {' '}
          {pastYear}
,
              if you have lived in more than one address, please provide them all and
              the periods of stay in each.
        </div>
      </Grid>
      <Grid item xs={12}>
        {getValues('extraAddress') && getValues('extraAddress').map((item, index) => (
          <ExtraAddress
            addressID={index}
            data={data}
            {...props}
          />
        ))}
        <button className="add-new-address" type="button" onClick={handleAddNewAddress}>
          <AddCircleOutlineIcon />
          <span className="add-new-address-text">
                   Add a new address
          </span>
        </button>
        {getValues('extraAddress') && getValues('extraAddress').length > 0 && (
        <button className="add-new-address" type="button" onClick={handleRemoveAddress}>
          <RemoveCircleOutlineIcon color="error" />
          <span className="remove-new-address-text">
                Remove address
          </span>
        </button>
        )}
      </Grid>
    </>
  );
}

SectionTwo.propTypes = {
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  unregister: PropTypes.func.isRequired,
  formState: PropTypes.shape({
    errors: PropTypes.any,
  }).isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  data: PropTypes.shape(
    {
      currentAddress: PropTypes.string,
      extraAddress: PropTypes.arrayOf(PropTypes.string),
    },
  ).isRequired,
};

export default SectionTwo;
