import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import Checkbox from '../../../../materialUi/checkBox';
import SearchTextField from '../../../../materialUi/searchTextField';
import '../../../../css/registrationList.css';

export default function Filter({
  search,
}) {
  return (
    <Grid item xs={12}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Box className="registrationList-searchField">
          <SearchTextField label="Search" search={(value) => search(value.target.value)} width={200} />
        </Box>
      </Grid>
    </Grid>
  );
}

Filter.propTypes = {
  search: PropTypes.func.isRequired,
};
