import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { ProgramsAPI } from '../../../../API/API-programs';
import '../../../../css/settings.css';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default class DeleteMemeber extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      errorMessage: false,
    };
  }

  handleDelete=() => {
    const {
        selectedProgramID,
    } = this.props;
    ProgramsAPI.PUT.deleteProgram(selectedProgramID).then((success) => {
          window.location.reload();
      }).catch((error) => {
        console.log(error.response)
        if (error.response.data) {
          if (error.response.data === 'Not allowed to delete') {
            this.setState({errorMessage: true});
          }
        }
      });
    
  }

  render() {
    const {
      open,
      close,
      selectedProgramName,
    } = this.props;

    const { errorMessage } = this.state;

    return (
      <Grid className="font-family-default">
        <Dialog
          onClose={close}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth="sm"
          fullWidth
        >
          <DialogContent dividers>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className="settings-delete-body"
            >
              <Box className="settings-delete-title">
                Delete
              </Box>
              <Grid>
                <Box onClick={() => close()} role="button"><i className="fas fa-times close-icon" /></Box>
              </Grid>
            </Grid>
            <Grid container className="settings-delete-body">
                {(errorMessage) && (
                  <div className="text-danger">
                    Not allowed to delete
                    <div className="text-danger">
                      There are members already registered for the program.
                    </div>
                  </div>
                )}
  
              <Box>
                Are you sure you want to Delete
                {' '}
                <b>{selectedProgramName}</b>
              </Box>
            </Grid>

          </DialogContent>
          <DialogActions>
            <Grid className="settings-btns-body-delete">
              <Button onClick={close} className="alert-btns">
                Cancel
              </Button>
              <Button onClick={this.handleDelete} className="alert-btns">
                Delete
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      </Grid>

    );
  }
}


DeleteMemeber.propTypes = {
  open: PropTypes.bool.isRequired,
  selectedProgramID: PropTypes.string.isRequired,
  selectedProgramName: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
};
