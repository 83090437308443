import React from 'react';
import { Grid, Box } from '@material-ui/core';
import CancelAlert from '../../../materialUi/cancelAlert';
import Btn from '../../../materialUi/btn';


export default function StepsButtons({ location, handleAction, nextLabel, handlePrevious, hideSave }) {
  const [openCancelAlert, setOpenCancelAlert] = React.useState();
  return (
    <Grid item xs={12} className="addProgram-btns" style={{ marginBottom: '30px' }}>
      <Grid container>
        <Grid item xs={12}>
          If you need support with this form, please email
          {' '}
          <span style={{ color: '#2a9d8f', fontWeight: 600 }}>taxportal@scaddingcourt.org</span>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="flex-end" alignItems="center">
        {handlePrevious &&  (
        <Box style={{ marginRight: '10px' }}>
          <Btn
            label="Previous"
            bgColor="#e76f50"
            color="white"
            colorHover="white"
            bgColorHover="#ff8c68"
            width={(window.innerWidth < 600) ? 100 : 128}
            onClick={handlePrevious}
            type="button"
          />
          <CancelAlert
            open={openCancelAlert}
            close={() => setOpenCancelAlert(false)}
            location={location}
          />
        </Box>
        )}
        {!hideSave && (
        <Box style={{ marginRight: '10px' }}>
          <Btn
            label="Save"
            bgColor="white"
            color="#E76F51"
            bgColorHover="#E76F51"
            colorHover="white"
            borderColor="#E76F51"
            variant="outlined"
            width={(window.innerWidth < 600) ? 100 : 128}
            onClick={() => handleAction('save')}
            type="submit"
          />
        </Box>
        )}
        <Btn
          label={nextLabel || 'Next'}
          bgColor="#e76f50"
          color="white"
          colorHover="white"
          bgColorHover="#ff8c68"
          width={(window.innerWidth < 600) ? 100 : 128}
          onClick={() => handleAction('next')}
          type="submit"
        />
      </Grid>
    </Grid>
  );
}
