/* eslint-disable no-lonely-if */
import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableRow from '@material-ui/core/TableRow';
import MemberIcon from '../../../../assets/icons/member-token.svg';
import StaffIcon from '../../../../assets/icons/staff-token.svg';
import GuestIcon from '../../../../assets/icons/guest-token.svg';
import '../../../../css/receipts.css';


function createData(
  checkInID,
  userType,
  firstName,
  lastName,
  date,
  screeningResult,
  id,
  phone,
  email,
  dailyCheckInID,
) {
  return {
    checkInID,
    userType,
    firstName,
    lastName,
    date,
    screeningResult,
    id,
    phone,
    email,
    dailyCheckInID,
  };
}


function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

// columns titles
const headRows = [
  {
    id: 'checkInID', numeric: false, disablePadding: true, label: 'Check-In ID',
  },
  {
    id: 'userType', numeric: true, disablePadding: true, label: 'User Type',
  },
  {
    id: 'firstName', numeric: false, disablePadding: false, label: 'First Name',
  },
  {
    id: 'lastName', numeric: false, disablePadding: false, label: 'Last Name ',
  },
  {
    id: 'date', numeric: false, disablePadding: false, label: 'Time of Check-In',
  },
  {
    id: 'screeningResult', numeric: true, disablePadding: false, label: 'Pre-screening Results',
  },
];


function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="bg-light">
      <TableRow>
        {headRows.map((row, index) => (
          <TableCell
            key={index}
            align={row.numeric ? 'center' : 'left'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.date ? order : false}
            className="table-cell"
          >
            <TableSortLabel
              active={orderBy === row.date}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

export default function CheckInTable({
  data,
}) {
  const rows = [];
  const determineUserType = (userData) => {
    if (!userData.user.userType) {
      return 'Member';
    }
    if (userData.user.userType === 'Guest') {
      return 'Guest';
    }
    return 'Staff';
  };

  const pushFilterResults = (userData) => {
    rows.push(
      createData(
        userData.checkInID,
        determineUserType(userData),
        userData.user.firstName,
        userData.user.lastName,
        userData.date,
        userData.screeningResult,
        userData.user._id,
        userData.user.phone || userData.user.dayPhone,
        userData.user.email,
        userData.dailyCheckInID,
      ),
    );
  };

  if (data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      pushFilterResults(data[i]);
    }
  }

  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('date');
  const [selected, setSelected] = React.useState([]);
  const [page] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage] = React.useState(3);


  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }


  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  return (
    <Grid className="font-family-default">
      <Paper className={classes.paper} elevation={2}>
        <Grid className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="button"
                      tabIndex={1}
                      className="table-tabSelection"
                      key={index}
                    >
                      <TableCell component="th" id={labelId} scope="row" padding="none" className="table-cell">
                        <span
                          className="reports-covid19-checkInID"
                          role="button"
                          onClick={() => window.location =`/adminPortal/reports/dailyCheckIn/${row.dailyCheckInID}/history`}
                        >
                          {row.dailyCheckInID}
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        {
                        (row.userType === 'Member') ? (
                          <img src={MemberIcon} alt="Member Token" className="covid19-screening-token mr-3" />
                        )
                          : (row.userType === 'Guest') ? (
                            <img src={GuestIcon} alt="Guest Token" className="covid19-screening-token mr-3" />
                          )
                            : <img src={StaffIcon} alt="Staff Token" className="covid19-screening-token mr-3" />
                        }
                      </TableCell>
                      <TableCell align="left">{row.firstName}</TableCell>
                      <TableCell align="left">{row.lastName}</TableCell>
                      <TableCell align="left"><Moment date={row.date} format="DD-MM-YY HH:mm a" /></TableCell>
                      <TableCell align="center" style={{ position: 'relative', width: '170px' }}>
                        <span className={`reports-covid19-${(row.screeningResult) ? 'negative' : 'positive'}`}>
                          {row.screeningResult ? 'Negative' : 'Positive'}
                        </span>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }} />
              )}
            </TableBody>
          </Table>
        </Grid>
      </Paper>
    </Grid>
  );
}


CheckInTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};
