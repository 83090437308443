import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import RadioGroup from '../../../../../materialUi/radioGroup';
import StepButtons from '../../../../../materialUi/stepButtons';
import Button from '../../../../../materialUi/btn';
import People from '../../../../../assets/icons/noun-people-458671.png';

function MembershipCategory({ handleFormSubmit }) {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue
  } = useForm();

  const YesNoQuestions = [
    {
      value: 'yes',
      label: 'Yes',
    },
    {
      value: 'no',
      label: 'No',
    },
  ];

  const categoryList = [
    {
      value: 'Family',
      label: 'Family',
    },
    {
      value: 'Senior',
      label: 'Senior (65+)',
    },
    {
      value: 'Adult',
      label: 'Adult',
    },
    {
      value: 'Child',
      label: 'Child',
    },
    {
      value: 'Youth',
      label: 'Youth (13-17)',
    },
  ];

  const [immediateMember, setImmediateMember] = React.useState('no');
  const isRegistration = window.innerWidth > 1240;

  React.useEffect(() => {
    const sessionValues = JSON.parse(sessionStorage.getItem('membershipRegistration'));
    if (sessionValues) {
      setValue('membershipCategory', sessionValues.membershipCategory);
    }
  }, []);

  return (
    <Grid item className="select-category-radio-wrapper">
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <h3 className="select-category-radio-title">
        Do you have immediate family members
        registered with us?
        </h3>
        <Grid item xs={12} className="select-category-radio-group">
          <RadioGroup
            listOptions={YesNoQuestions}
            handleChange={(value) => setImmediateMember(value.target.value)}
            value={immediateMember}
            ariaLabel="Do you have immediate family members registered with us?"
            name="dependant"
          />
        </Grid>
        {immediateMember === 'no' ? (
          <>
            <h3 className="select-category-radio-title">
            Select the type of membership category.
            </h3>
            <Grid item xs={12} className="select-category-radio-group">
              <span className="select-category-custom-error">
                {(errors.membershipCategory) && errors.membershipCategory.message}
              </span>
              <Controller
                name="membershipCategory"
                control={control}
                rules={{
                  required: 'Please select One',
                }}
                render={({ field }) => (
                  <RadioGroup
                    listOptions={categoryList}
                    ariaLabel="Select the type of membership category."
                    name="membershipCategory"
                    isRegistration={isRegistration}
                    {...field}
                  />
                )}
              />
            </Grid>
          </>
        ) : (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            className="select-category-registered-member-wrapper"
          >
            <Grid item>
              <img src={People} alt="People" />
            </Grid>
            <Grid
              item
              className="select-category-registered-member"
              role="application"
              tabIndex="0"
              aria-label="To register under existing members, please visit the reception to complete the registration process."
            >
            To
              {' '}
              <span className="fees-highlight-text">register under existing members</span>
            , please visit the reception
            to complete the registration process.
            </Grid>
          </Grid>
        )}
        {immediateMember === 'no' ? (
          <StepButtons location="/" handleSubmit={() => {}} type="submit" />
        ) : (
          <Grid item xs={12} className="select-category-cancel-button">
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              <Button
                label="Cancel"
                bgColor="#e76f50"
                color="white"
                colorHover="white"
                bgColorHover="#ff8c68"
                width={128}
                onClick={() => { window.location = '/'; }}
              />
            </Grid>
          </Grid>
        )}
      </form>
    </Grid>
  );
}

MembershipCategory.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
};

export default MembershipCategory;
