import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Successful from '../../../assets/icons/fireworks@3x.png';
import Button from '../../btn';
import Receipt from './receipt';
import ConfirmationEmail from '../../programRegistrationEmail';

function Success({ data, handleLeave, handlePrint }) {

  const membership = data.membershipData;

  const programName = data.programData[0].name

  return (
    <Grid container className="registration-success-wrapper">
      <Grid item xs={12} md={2}>
        <img src={Successful} alt="logo" width="104px" height="120px" />
      </Grid>
      <Grid item>
        <h1 className="registration-success-title">
          Successful!
        </h1>
        <h2 className="registration-success-text">
          Program Registration completed!
        </h2>
        {data && !data.freeProgram && (
          <Receipt data={data.receipt} handlePrint={handlePrint} />
        )}
        <div>
          {data && data.freeProgram && (
          <ConfirmationEmail
            email={membership.email}
            name={data.receipt.name}
            programName={programName}
          />
          )}
        </div>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          style={{ paddingTop: '25px' }}
        >
          <Button
            label="DONE"
            type="button"
            bgColor="#e76f50"
            color="white"
            bgColorHover="#ff8c68"
            colorHover="white"
            width={120}
            height={47}
            onClick={() => handleLeave()}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withRouter(Success);
