import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import DropdownIcon from '../../../../assets/icons/menu.png';
import MenuIcon from '../../../../materialUi/menuIcon';
import Filter from './filter';
import '../../../../css/receipts.css';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayFilter: false,
    };
  }

  handleDisplayFilter = () => {
    const { displayFilter } = this.state;
    const { resetFilters } = this.props;
    if (displayFilter) {
      this.setState({ displayFilter: false });
      // reset filter
      resetFilters('');
    } else {
      this.setState({ displayFilter: true });
    }
  };

  render() {
    const { handleSearch, handleExport, values, handleFilter, selectedItems } = this.props;
    const options = ['Download CSV', 'Print'];
    const { displayFilter } = this.state;

    return (
      <Grid container className="root receipts-header-spacing">
        <Grid className="mb-2">
          <button onClick={() => this.props.history.push('/adminPortal/reports')} type="button" className="reports-back-buttons">
            &lt; Back to Reports
          </button>
        </Grid>
        <Grid item xs={12} className="reports-receipts-title">
          Members Report
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs sm={2} lg={1}>
              <Box onClick={() => this.handleDisplayFilter()} className="receipts-filter">
                <img src={DropdownIcon} alt="Search Filter" />
                Filter
              </Box>
            </Grid>
            {/* filter for desktop  */}
            {window.innerWidth > 900 && (
              <Grid item xs className="programsList-box-selectedItems">
                {selectedItems}
              </Grid>
            )}
            <Grid item xs sm={1}>
              <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <MenuIcon list={options} onClick={(value) => handleExport(value)} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {displayFilter ? <Filter values={values} handleSearch={handleSearch} handleFilter={handleFilter} /> : ''}
      </Grid>
    );
  }
}

Header.propTypes = {
  values: PropTypes.shape({}).isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired,
  handleExport: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
};
export default withRouter(Header);
