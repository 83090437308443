import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import ReservationCard from './components/reservationCard';
import addNew from '../../../assets/icons/group-42.png';
import Btn from '../../../materialUi/btn';
import IconButton from '@material-ui/core/IconButton';

export default class ReservationSession extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { history, userData } = this.props;
        const userType = (userData) ? userData.userData.userType : 'none';

        return (
            <Grid item xs className='font-family-default reservation'>
                <Grid container>
                    <Grid xs={12} className='main-title'>
                        <header>
                            <h1>Session Reservations</h1>
                            <div className='admin-buttons'>
                                <Btn
                                    label="Edit Access"
                                    bgColor="white"
                                    color="#E76F51"
                                    bgColorHover="#E76F51"
                                    colorHover="white"
                                    borderColor="#E76F51"
                                    variant="outlined"
                                    width={148}
                                />
                                <Btn
                                    label="View Reservations"
                                    bgColor="#e76f50" 
                                    color="white" 
                                    bgColorHover="#ff8c68" 
                                    colorHover="white"
                                    width={210}
                                />
                            </div>
                        </header>
                        <Grid xs={12} className="description">
                            Due to COVID restrictions and safety concerns, members must reserve an available session for any program they wish to join.
                            <button type="button" className="edit-link">
                                Edit
                            </button>
                        </Grid>
                    </Grid>
                    <Grid xs={12} className='reservation-wrapper'>
                        <ReservationCard/>
                        <Grid xs={12}>
                            <Grid container className='add-new-btn'>
                                <Grid className='add-new-btn_img'>
                                    <img src={addNew} />
                                </Grid>
                                <Grid className='add-new-btn_label'>
                                    Add a new reservation program
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}