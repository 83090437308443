import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import { BootstrapTextField } from './bootstrapInputs';
import Alert from './Alerts';
import { EmailAPI } from '../API/API-Email';
import Button from './btn';

export const TIME = function (isoTime, validation) {
  const timeStr = isoTime;
  const date = new Date(timeStr);
  let day = date.getDate();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  let hours = date.getHours();
  const minutes = date.getMinutes();
  let ampm = 'am';
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  if (hours === 12) {
    ampm = 'pm';
  } else if (hours === 0) {
    hours = 12;
  } else if (hours > 12) {
    hours -= 12;
    ampm = 'pm';
  }
  let dateStr;
  if (validation === true) {
    day += 1;
    dateStr = `${months[month - 1]} ${day}, ${year}`;
  } else {
    dateStr = `${months[month - 1]} ${day}, ${year} - ${hours}:${minutes} ${ampm}`;
  }
  return dateStr;
};

function SendEmailReceipt({ data, category }) {
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertValues, setAlertValues] = React.useState({});
  const [email, setEmail] = React.useState('');
  const [emailMessage, setEmailMessage] = React.useState('');

  const handleEmail = (event) => {
    const { value } = event.target;
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      setEmailMessage('Invalid email.');
    } else {
      setEmailMessage('');
    }
    setEmail(value);
  };

  const handleSendEmail = () => {
    if (!email) return setEmailMessage('Email is required.');
    if (emailMessage) return false;

    // receipt Object
    const addressTosend = `${data.address.street} ${data.address.city} ${data.address.province} ${data.address.postalCode}`;
    const receiptObj = {
      referenceNumber: data.referenceNumber,
      name: data.name,
      cardType: data.cardType,
      category,
      paymentMethod: data.paymentMethod,
      address: addressTosend,
      date: TIME((data.cardInformation) ? data.cardInformation.created_at : new Date(), true),
      fee: data.fee,
    };

    EmailAPI.EMAIL.emailReceipt(email, receiptObj).then((success) => {
      console.log(success);
      setOpenAlert(true);
      setAlertValues({
        alertTitle: 'Email send!',
        body: 'Your receipt was successfully sent!',
        leave: 'hidden',
        stay: 'CLOSE',
      });
    }).catch((error) => {
      console.log(error);
    });
    
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      spacing={2}
      style={{ marginTop: '40px', marginBottom: '40px' }}
    >
      <Grid item style={{ marginTop: '10px' }}>Email:</Grid>
      <Grid item xs md={8} className="email-textfield">
        <BootstrapTextField
          id="email"
          type="text"
          name="email"
          value={email}
          handleChanges={handleEmail}
          message={emailMessage}
          readOnly={false}
          error={emailMessage !== ''}
        />
      </Grid>
      <Grid item className="email-button">
        <Button
          label="SEND"
          onClick={handleSendEmail}
          bgColor="#2a9d8f"
          color="white"
          bgColorHover="#414158"
          colorHover="white"
          outlineColor="#2a9d8f"
          width={94}
          height={45}
        />
      </Grid>
      <Alert
        open={openAlert}
        close={() => setOpenAlert(false)}
        location="/"
        title={alertValues.alertTitle}
        body={alertValues.body}
        stay={alertValues.stay}
        leave={alertValues.leave}
      />
    </Grid>
  );
}

SendEmailReceipt.propTypes = {

};

export default SendEmailReceipt;
