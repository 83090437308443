import React from 'react';
import { Grid, Box } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { Controller, useForm } from 'react-hook-form';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import QuestionnaireButtons from '../questionnaireButtons';
import TextField from '../../../../materialUi/textField';
import RadioGroup from '../../../../materialUi/radioGroup';


function Review({ handleNextStep, handlePrevious }) {
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    watch,
  } = useForm();

  const [formAction, setFormAction] = React.useState('');

  const handleFormAction = (action) => {
    setFormAction(action);
  };

  const handleFormSubmit = (formData) => {
    if (formAction === 'save') {
      console.log(formData);
    } else {
      handleNextStep(formData, 1);
    }
  };

  const filledTaxOptions = [
    {
      label: 'E-file',
      value: 'eFile',
    },
    {
      label: 'Provide me a hard copy',
      value: 'hardCopy',
    },
  ];

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Grid container>
        <Grid item xs={12} className="taxes-consent-title">
          Consent
        </Grid>
        <Grid item xs={12}>
          <div className="taxes-question">
          18. Please read the following carefully to provide your consent to
           having a volunteer file your tax returns
          </div>
        </Grid>
        <Grid item xs={12}>
          <span className="custom-error">
            {(errors.consentCheck) && errors.consentCheck.message}
          </span>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            className="taxes-checkboxes-wrapper"
          >
            <Box>
              <Controller
                name="consentCheck"
                control={control}
                rules={{
                  required: 'Please, accept the terms to continue',
                }}
                render={({ field }) => (
                  <Checkbox
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    {...field}
                  />
                )}
              />
            </Box>
            <Box className="taxes-checkbox-label">
            I am fully aware that my income tax and benefit return
             is being prepared by a volunteer under the Community Volunteer
              Income Tax Program and that this volunteer is not
               acting as an agent of the Canada Revenue Agency.
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} className="taxes-consent-textFields">
          <Controller
            name="consentSign"
            control={control}
            rules={{
              required: 'Your Name is required',
              maxLength: { value: 100, message: 'Max length 100' },
            }}
            render={({ field }) => (
              <TextField
                id="consentSign"
                type="search"
                name="consentSign"
                label="Your Name"
                helperText={errors.consentSign ? errors.consentSign.message : ''}
                error={!!errors.consentSign}
                width="100%"
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="taxes-question">
            19. Please select how you would like your taxes filed.
          </div>
        </Grid>
        <Grid item xs={12}>
          <span className="custom-error">
            {(errors.filledTaxOption) && errors.filledTaxOption.message}
          </span>
          <Controller
            name="filledTaxOption"
            control={control}
            rules={{
              required: 'Please select One',
            }}
            render={({ field }) => (
              <RadioGroup
                listOptions={filledTaxOptions}
                ariaLabel="19. Please select how you would like your taxes filed."
                name="filledTaxOption"
                radioMessage
                {...field}
              />
            )}
          />
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Box>
              <ErrorOutlineIcon color="error" />
            </Box>
            <Box className="taxes-important-review-text" style={{ maxWidth: '90%' }}>
            Should E-file NOT be possible, we will automatically print
             the documents for you to pick up/send to you.
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} className="taxes-consent-message">
          *You can review your application before submitting it to ensure
           all information is correct and the proper documents are uploaded.
        </Grid>

        {/* Action Buttons */}
        <hr className="tax_custom_hr" />
        <QuestionnaireButtons
          handleAction={(value) => handleFormAction(value)}
          nextLabel="Review"
          handlePrevious={handlePrevious}
          hideSave
        />
      </Grid>
    </form>
  );
}

export default Review;
