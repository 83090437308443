import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableRow from '@material-ui/core/TableRow';
import '../../../../../css/receipts.css';


function createData(
  checkInID,
  date,
  Q1Answer,
  Q2Answer,
  Q3Answer,
  Q4Answer,
  screeningResult,
) {
  return {
    checkInID,
    date,
    Q1Answer,
    Q2Answer,
    Q3Answer,
    Q4Answer,
    screeningResult,
  };
}


function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

// columns titles
const headRows = [
  {
    id: 'checkInID', numeric: false, disablePadding: true, label: 'Check-In ID',
  },
  {
    id: 'date', numeric: false, disablePadding: true, label: 'Date of Check-in',
  },
  {
    id: 'Q1Answer', numeric: false, disablePadding: false, label: 'Q1 Answer',
  },
  {
    id: 'Q2Answer', numeric: false, disablePadding: false, label: 'Q2 Answer',
  },
  {
    id: 'Q3Answer', numeric: false, disablePadding: false, label: 'Q3 Answer',
  },
  {
    id: 'Q4Answer', numeric: false, disablePadding: false, label: 'Q4 Answer',
  },
  {
    id: 'screeningResult', numeric: true, disablePadding: false, label: 'Results',
  },
];


function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="bg-light">
      <TableRow>
        {headRows.map((row, index) => (
          <TableCell
            key={index}
            align={row.numeric ? 'center' : 'left'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
            className="table-cell"
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

export default function ReceiptsTable({
  data,
}) {
  const rows = [];
  if (data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      rows.push(
        createData(
          data[i].checkInID,
          data[i].date,
          data[i].Q1Answer,
          data[i].Q2Answer,
          data[i].Q3Answer,
          data[i].Q4Answer,
          data[i].screeningResult,
        ),
      );
    }
  }

  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('date');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);


  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  return (
    <Grid className="font-family-default">
      <Grid className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
          />
          <TableBody>
            {stableSort(rows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="button"
                    tabIndex={1}
                    className="table-tabSelection"
                    key={index}
                  >
                    <TableCell component="th" id={labelId} scope="row" padding="none" className="table-cell">
                      {row.checkInID}
                    </TableCell>
                    <TableCell align="left"><Moment date={row.date} format="DD-MM-YY HH:mm a" /></TableCell>
                    <TableCell align="left">{row.Q1Answer}</TableCell>
                    <TableCell align="left">{row.Q2Answer}</TableCell>
                    <TableCell align="left">{row.Q3Answer}</TableCell>
                    <TableCell align="left">{row.Q4Answer}</TableCell>
                    <TableCell align="center" style={{ position: 'relative' }}>
                      <span className={`reports-covid19-${(row.screeningResult) ? 'negative' : 'positive'}`}>
                        {row.screeningResult ? 'Negative' : 'Positive'}
                      </span>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }} />
            )}
          </TableBody>
        </Table>
      </Grid>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Grid>
  );
}


ReceiptsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};
