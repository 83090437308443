import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Moment from 'react-moment';
import Logo from '../../../../assets/SCCC_LOGO_SMALL.png';
import '../../../../css/receipts.css';

function ReceiptPrint({ data }) {
  return (
    <Grid id="receipt" className="font-family-default">
      <Grid container>
        <Grid item xs className="receipt-print-title">
          Receipt
        </Grid>
        <Grid className="col text-right">
          <img src={Logo} alt="logo" width="140" height="50" />
        </Grid>
      </Grid>

      <Grid container>
        <Box xs={2} lg={1}>
          <CheckCircleIcon color="primary" />
        </Box>
        <Box className="receipt-alert-successfull-text" style={{ paddingTop: '2px' }}>
            This transaction was successfully processed
        </Box>
      </Grid>

      <div className="col-12 border-receipt pb-3 pt-3 receipt-main-text mt-3">
            Receipt Details
        <div className="row pl-2">
          <div className="col-12 col-sm-12 col-md ">
            <div className="row mt-2">
              <div className="col-6 col-sm-6 col-md-4 pr-0">
                Reference#:
              </div>
              <div className="col pl-0 receipt-detail-text">
                {data.referenceNumber}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md ">
            <div className="row mt-2">
              <div className="col-6 col-sm-6 col-md-4 pr-0">
                Payment Method:
              </div>
              <div className="col pl-0 receipt-detail-text">
                {data.cardInformation ? (
                  'Credit Card'
                ) : (
                  'Cash'
                )}
              </div>
            </div>
          </div>
        </div>


        <div className="row pl-2">
          <div className="col-12 col-sm-12 col-md">
            <div className="row mt-2">
              <div className="col-6 col-sm-6 col-md-4 pr-0">
                Name:
              </div>
              <div className="col pl-0 receipt-detail-text">
                {data.name}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md">
            <div className="row mt-2">
              <div className="col-6 col-sm-6 col-md-4 pr-0">
                Card Number:
              </div>
              <div className="col pl-0 receipt-detail-text">
                {data.cardInformation ? (
                  <>
                  ****
                    {data.cardInformation.card_details.card.last_4}
                  </>
                ) : (
                  'N/A'
                )}
              </div>
            </div>
          </div>
        </div>


        <div className="row pl-2">
          <div className="col-12 col-sm-12 col-md">
            <div className="row mt-2">
              <div className="col-6 col-sm-6 col-md-4 pr-0 ">
                        Address:
              </div>
              <div className="col pl-0 receipt-detail-text">
                {data.address.street}
,
                {' '}
                {data.address.city}
,
                {' '}
                {data.address.province}
,
                {' '}
                {data.address.postalCode}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md">
            <div className="row mt-2">
              <div className="col-6 col-sm-6 col-md-4 pr-0">
                        Card Type:
              </div>
              <div className="col pl-0 receipt-detail-text">
                {data.cardInformation ? (
                  data.cardType
                ) : (
                  'N/A'
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row pl-2">
          <div className="col-12 col-sm-12 col-md">
            <div className="row mt-2">
              <div className="col-6 col-sm-6 col-md-4 pr-0">
                        Date & Time:
              </div>
              <div className="col pl-0 receipt-detail-text">
                {data.cardInformation ? (
                  <Moment format="MM/DD/YYYY hh:mm A">{data.cardInformation.created_at}</Moment>
                ) : (
                  <Moment format="MM/DD/YYYY hh:mm A">{new Date()}</Moment>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md">
            <div className="row mt-2 total-hr">
              <hr />
            </div>
          </div>
        </div>

        <div className="row pl-2">
          <div className="col-12 col-sm-12 col-md">
            <div className="row mt-2">
              <div className="col-6 col-sm-6 col-md-4 pr-0">
                Category:
              </div>
              <div className="col pl-0 receipt-detail-text">
                {data.membershipCategory}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md">
            <div className="row mt-2">
              <div className="col-6 col-sm-6 col-md-4 pr-0">
                Total:
              </div>
              <div className="col pl-0 receipt-detail-text">
                $
                {
                   data.fee.toString().includes('.') ? data.fee : `${data.fee.toString()}.00`
                }
              </div>
            </div>
          </div>
        </div>

      </div>
    </Grid>
  );
}

ReceiptPrint.prototype = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

export default ReceiptPrint;
