import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class Alert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  componentDidUpdate(previousProps) {
    const { open } = this.props;
    if (previousProps.open !== open) {
      this.setState({ open });
    }
  }

  handleClose = () => {
    const { close } = this.props;
    this.setState({ open: false });
    close(false);
  };

  render() {
    const { title, body, stay, leave, location } = this.props;
    const { open } = this.state;
    return (
      <div>
        <Dialog aria-labelledby="customized-dialog-title" open={open} maxWidth="sm">
          <DialogContent dividers>
            <Typography gutterBottom className="alert-header">
              <b>{title}</b>
            </Typography>
            <Typography gutterBottom className="alert-text">
              {body}
            </Typography>
          </DialogContent>
          <DialogActions>
            <div id={stay}>
              <Button className="alert-LEAVE" onClick={this.handleClose}>
                <b>{stay}</b>
              </Button>
            </div>
            <div id={leave}>
              <Button className="alert-STAY" onClick={() => (window.location = `${location}`)}>
                <b>{leave}</b>
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default Alert;

/*
Implementation example:
    <Alert
      open={openAlert}
      close={() => this.setState({ openAlert: false })}
      location={alertLocation}
      title={alertTitle}
      body={alertBody}
      stay={alertStay}
      leave={alertClose}
    />
*/
