import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';

export default class ReservationList extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { history, userData } = this.props;
        const userType = (userData) ? userData.userData.userType : 'none';

        return (
            <Grid item xs className='font-family-default reservation'>
                <Grid container>
                    <Grid xs={12} className='main-title'>
                        Reservation List
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}