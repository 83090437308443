import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import AttendeesTable from "./components/attendeesTable";
import AttendeesHeader from "./components/attendeesHeader";
import { ProgramRegistrationAPI } from "../../../API/API-programRegistration";
import { ProgramsAPI } from "../../../API/API-programs";
import { EmailAPI } from "../../../API/API-Email";
import { CONVERTTIME } from "../../../utils/dateController";
import { ProgramWeekScheduleAPI } from "../../../API/API-programWeekSchedule";
import { ProgramAttendanceWithoutRegistration } from "../../../API/API-createattendeeswithoutregistration";
import Alert from "../../../materialUi/Alerts";
import DeleteParticipant from "./components/deleteParticipant";
import DefaultDialog from "../../../materialUi/defaultDialog";
import "../../../css/registrationList.css";
import { Tune } from "@material-ui/icons";

export default class RegistrationList extends Component {
  constructor(props) {
    super(props);
    const { match } = this.props;
    this.state = {
      openDialog: false,
      dialogBody: "",
      dialogTitle: "",
      buttonOne: "",
      buttonTwo: "",
      programID: match.params.programID,
      programName: "",
      numberOfSpots: 0,
      fee: 0,
      programData: "",
      weeklySchedule: null,
      selectedWeek: 0,
      openAlert: false,
      alertLocation: "",
      alertTitle: "",
      alertBody: "",
      alertStay: "",
      alertClose: "",
      openDeleteParticipant: false,
      participantData: {},
      registrationData: "",
      tableSelected: "Attendees",
      attendanceData: [],
      selectedItems: [],
      search: "",
      selectedDataForDelete: "",
      status: [],
      mousePopOver: false,
      headerTotals: {
        attendees: 0,
      },
      values: {
        startDate: "",
        endDate: "",
      },
      messages: {
        startDate: "",
        endDate: "",
      },
    };
  }

  componentDidMount() {
    window.scroll({ top: 0, behavior: "smooth" });
    const { programID, headerTotals } = this.state;

    // get program information
    ProgramsAPI.GET.getProgramByID(programID)
      .then((success) => {
        if (success.data.program[0].weeklyProgram) {
          // Weekly schedule API
          ProgramWeekScheduleAPI.GET.getProgramWeekScheduleByProgramID(
            programID
          )
            .then((response) => {
              this.setState({
                weeklySchedule: response.data,
              });
            })
            .catch((error) => {
              if (error.response) {
                this.setState({
                  openAlert: true,
                  alertLocation: "/adminPortal/programs",
                  alertTitle: error.response.data.errorTitle,
                  alertBody: error.response.data.errorMessage,
                  alertClose: "CLOSE",
                  headerTotals: {},
                });
              }
            });
        }
        this.setState({
          programName: success.data.program[0].name,
          programEnrolmentEndDate: success.data.program[0].enrollmentEndDate,
          numberOfSpots: success.data.program[0].numberOfSpots,
          fee: success.data.program[0].fee,
          programData: success.data.program[0],
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          openAlert: true,
          alertLocation: "/adminPortal/programs",
          alertTitle: "Program not found!",
          alertBody: "We could not find the program. Please try again later!",
          alertClose: "CLOSE",
        });
      });

    ProgramAttendanceWithoutRegistration.GET.getProgramAttendanceByAttendanceID(
      programID
    )
      .then((success) => {
        const totalFamilyMembers = success.data.reduce((total, attendance) => {
          return (
            total +
            (attendance.familyMembers ? attendance.familyMembers.length : 0)
          );
        }, 0);
        headerTotals.attendees = totalFamilyMembers;
        this.setState({
          attendanceData: success.data,
          headerTotals,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  resetFilters = () => {
    const { values, messages } = this.state;
    values.startDate = "";
    values.endDate = "";
    messages.startDate = "";
    messages.endDate = "";
    this.setState({ values, messages });
  };

  handleAddNewRegistrant = () => {
    const { programID, programData, weeklySchedule } = this.state;
    const { history, userData } = this.props;
    const { userType } = userData.userData;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const enrollmentEndDate = new Date(programData.enrollmentEndDate);
    enrollmentEndDate.setHours(0, 0, 0, 0);
    const enrollmentStartDate = new Date(programData.enrollmentStartDate);
    enrollmentStartDate.setHours(0, 0, 0, 0);
    sessionStorage.removeItem("exisitingAttendanceID");
    sessionStorage.removeItem("exisitingAttendanceDate");
    sessionStorage.removeItem("familyMembersInfo");
    // if (enrollmentStartDate.getTime() > today.getTime()) {
    //   return this.setState({
    //     openAlert: true,
    //     alertLocation: `/adminPortal/programs/registrationList/${programID}`,
    //     alertTitle: "Error",
    //     alertBody: `Program enrolment date starts on ${CONVERTTIME(
    //       enrollmentStartDate,
    //       true
    //     )}`,
    //     alertClose: "hidden",
    //     alertStay: "CLOSE",
    //   });
    // }
    if (!programData.dropIn && enrollmentEndDate.getTime() < today.getTime()) {
      return this.setState({
        openAlert: true,
        alertLocation: `/adminPortal/programs/registrationList/${programID}`,
        alertTitle: "Error",
        alertBody: "Program enrolment date has passed.",
        alertClose: "hidden",
        alertStay: "CLOSE",
      });
    }
    // if (numberOfSpots < 1) {
    //   return this.setState({
    //     openAlert: true,
    //     alertLocation: `/adminPortal/programs/registrationList/${programID}`,
    //     alertTitle: 'Error',
    //     alertBody: 'There is no more spots lefts in this program.',
    //     alertClose: 'hidden',
    //     alertStay: 'CLOSE',
    //   });
    // }

    // TODO: Needs to be removed after we merge the adminPortal with scccPortal
    const typeOfPortal = () => {
      if (
        userType.toLowerCase() !== "member" &&
        userType.toLowerCase() !== "public"
      ) {
        return "adminPortal";
      }
      return "scccPortal";
    };

    history.push(`/${typeOfPortal()}/programs/addAttendance/${programID}`);
  };

  handleDeleteParticipant = (participantObj) => {
    this.setState({
      openDeleteParticipant: true,
      selectedDataForDelete: participantObj.attendanceId
    });
   
  };

  sendWaitingListEmail = async (registrationObj) => {
    const { programName, programID } = this.state;
    registrationObj.programName = programName;
    registrationObj.programID = programID;
    await EmailAPI.EMAIL.emailWaitingList(registrationObj)
      .then((success) => {
        this.setState({
          openAlert: true,
          alertLocation: "/adminPortal/programs",
          alertTitle: "Success!",
          alertBody: `Email successfully sent to ${registrationObj.email}`,
          alertClose: "hidden",
          alertStay: "CLOSE",
        });
      })
      .catch((error) => {
        this.setState({
          openAlert: true,
          alertLocation: "/adminPortal/programs",
          alertTitle: "Server Error!",
          alertBody: " Please try again later!",
          alertClose: "hidden",
          alertStay: "CLOSE",
        });
      });
  };

  validateSpots = (registrationObj) => {
    const { membershipType } = registrationObj.membershipData;
    const {
      associateSpots,
      communitySpots,
      programID,
    } = this.state.programData;
    const requestedSpots = registrationObj.familyMembersData.length;
    let result = true;
    if (
      membershipType.toLowerCase() === "associate" &&
      associateSpots < requestedSpots
    )
      result = false;
    if (
      membershipType.toLowerCase() === "community" &&
      communitySpots < requestedSpots
    )
      result = false;
    if (!result) {
      this.setState({
        openAlert: true,
        alertLocation: `/adminPortal/programs/editProgram/${programID}`,
        alertTitle: "Cannot add attendees",
        alertBody: `  There are no ${membershipType.toLowerCase()} spots left. Please add ${requestedSpots} more ${membershipType.toLowerCase()} spots to the program`,
        alertClose: "GO TO EDIT PROGRAM",
        alertStay: "CLOSE",
      });
    }
    return result;
  };

  sendErrorMessage = (title, message) => {
    this.setState({
      openAlert: true,
      alertLocation: "/adminPortal/programs",
      alertTitle: title,
      alertBody: message,
      alertClose: "hidden",
      alertStay: "CLOSE",
    });
  };

  getMemberFee = (membershipType) => {
    const {
      programData: { communityFee, associateFee },
    } = this.state;
    const type = membershipType.toLowerCase();
    if (type === "associate") return associateFee;
    return communityFee;
  };

  checkFreeProgram = (registrationObj) => {
    const {
      programData: { variableFee, fee },
    } = this.state;
    const {
      membershipData: { membershipType },
    } = registrationObj;
    const memberFee =
      variableFee !== "yes" ? fee : this.getMemberFee(membershipType);
    if (memberFee === 0) return true;
    return false;
  };

  sendPaymentEmail = async (registrationObj) => {
    const { email, phone } = registrationObj;
    if (!email && !phone)
      return this.sendErrorMessage(
        "Error",
        "There are no contact information on the registration"
      );
    if (!email)
      return this.sendErrorMessage(
        "Error",
        `There is no email related to this registration, please contact the participant by phone ${phone}`
      );
    return await this.sendWaitingListEmail(registrationObj);
  };

  submitFreeProgram = async (registrationObj) => {
    const { programID, programData } = this.state;
    const {
      waitingListData,
      programRegistrationID,
      familyMembersData,
    } = registrationObj;
    const freePayment = true;
    registrationObj.waitingListID = waitingListData[0]._id;
    registrationObj.programID = programID;
    registrationObj.familyMembers = familyMembersData;
    registrationObj.programData = [programData];
    await ProgramRegistrationAPI.PUT.programWaitingListPayment(
      registrationObj,
      programRegistrationID,
      freePayment
    )
      .then((response) => {
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleDate = (name, value, type, requirement = "") => {
    const { values, messages } = this.state;
    values[name] = value;
    messages[name] = "";
    this.setState({ values, messages });
  };

  handleReset = () => {
    const { values, messages } = this.state;
    values.startDate = "";
    values.endDate = "";
    messages.startDate = "";
    messages.endDate = "";
    this.setState({ values, messages });
  };

  handleFreeProgram = async (registrationObj) => {
    this.setState({
      openDialog: true,
      dialogTitle: "Confirm",
      dialogBody:
        "This is a free program. Do you wish to add the participant to the program attendance list?",
      registrationObj,
    });
  };

  handleDialogActions = async (buttonName) => {
    const { registrationObj } = this.state;
    if (buttonName === "buttonOne") return this.setState({ openDialog: false });
    await this.submitFreeProgram(registrationObj);
    window.location.reload();
  };

  handleSendNotification = async (value, registrationObj) => {
    const { history } = this.props;
    const { programID } = this.state;
    const { membershipID, waitingListData } = registrationObj;
    const waitingListID = waitingListData[0]._id;

    if (!this.validateSpots(registrationObj)) return false;

    if (this.checkFreeProgram(registrationObj))
      return await this.handleFreeProgram(registrationObj);

    if (value === "Send payment email")
      return await this.sendPaymentEmail(registrationObj);

    return history.push({
      pathname: `/adminPortal/programs/waitingList/registration/${programID}/${waitingListID}/${membershipID}/0`,
      state: { from: this.props.location.pathname },
    });
  };

  render() {
    const { history, userData } = this.props;
    const {
      programName,
      tableSelected,
      values,
      selectedItems,
      attendanceData,
      weeklySchedule,
      selectedWeek,
      search,
      registrationData,
      status,
      openAlert,
      alertLocation,
      alertTitle,
      alertBody,
      alertStay,
      alertClose,
      messages,
      selectedDataForDelete,
      openDeleteParticipant,
      participantData,
      headerTotals,
      dialogTitle,
      dialogBody,
      openDialog,
    } = this.state;
    return (
      <Grid item xs className="font-family-default">
        <DefaultDialog
          title={dialogTitle}
          body={dialogBody}
          open={openDialog}
          buttonOne="Cancel"
          buttonTwo="Confirm"
          handleChanges={this.handleDialogActions}
        />

        <AttendeesHeader
          programName={programName}
          handleChangeTable={(value) => this.setState({ tableSelected: value })}
          handleAddNewProgram={this.handleAddNewRegistrant}
          handleExport={this.handleExport}
          handleBack={() => history.push("/adminPortal/programs/available")}
          values={values}
          userType={userData.userData.userType}
          headerTotals={headerTotals}
          weeklySchedule={weeklySchedule}
          selectedWeek={selectedWeek}
          handleDate={this.handleDate}
          messages={messages}
          handleReset={this.resetFilters}
          handleSelectedWeek={(index) => this.setState({ selectedWeek: index })}
        />

        <AttendeesTable
          attendanceData={attendanceData}
          sortSearch={search}
          values={values}
          programName={programName}
          handleDeleteParticipant={this.handleDeleteParticipant}
        />

        <Alert
          open={openAlert}
          close={() => this.setState({ openAlert: false })}
          location={alertLocation}
          title={alertTitle}
          body={alertBody}
          stay={alertStay}
          leave={alertClose}
        />
        <DeleteParticipant
          open={openDeleteParticipant}
          close={() => {
            this.setState({ openDeleteParticipant: false });
          }}
          data={selectedDataForDelete}
        />
      </Grid>
    );
  }
}

RegistrationList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programID: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  userData: PropTypes.shape({
    userType: PropTypes.string,
  }),
};
