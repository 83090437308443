import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import BackButton from '../../../materialUi/backButton';

function TopContent({ text, handleStep, disableBack }) {
  return (
    <>
      {disableBack !== true && (
      <Grid item xs={12}>
        <BackButton
          handleClick={handleStep}
          label={`Back to ${text}`}
        />
      </Grid>
      )}
      <Grid item xs={12}>
        <h1 className="taxes-title mt-2">Tax Questionnaire</h1>
        <h5 className="taxes-sub-title taxes-text-red">
              *Please note that you will be contacted for further questions to complete
              filing your taxes. This form is intended to simplify the process.
        </h5>
      </Grid>
    </>
  );
}

TopContent.propTypes = {
  step: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  handleStep: PropTypes.func.isRequired,
  disableBack: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(TopContent);
