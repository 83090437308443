import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import Alert from "../../../../../materialUi/Alerts";
import { ProgramsWaitingListAPI } from "../../../../../API/API-programWaitingList";
import ParticipantDropdown from "./participantDropdown";
import ParticipantFoundBox from "./participantFoundBox";
import MedicalInformation from "./medicalInformation";
import AttendeesTable from "./attendeesTable";
import StepButtons from "./stepButtons";
import DeleteParticipant from "./deleteParticipant";
import { ProgramAttendanceWithoutRegistration } from "../../../../../API/API-createattendeeswithoutregistration";

function ProgramParticipant(props) {
  const {
    register,
    watch,
    formState: { errors },
    getValues,
    setValue,
    data,
    programData,
    setAddedMembers,
    defaultMemberShip,
  } = props;

  const [openAlert, setOpenAlert] = useState(false);
  const [alertContent, setAlertContent] = useState({
    alertLocation: "",
    alertTitle: "Program Registration Error!",
    alertBody: "",
    alertClose: "hidden",
    alertStay: "CLOSE",
  });
  const [addData, setAddData] = useState(false);
  const [openDeleteParticipant, setOpenDeleteParticipant] = useState(false);
  const [membershipId, setMembershipId] = useState(null);
  const [showparticipants, setShowparticipants] = useState(false);
  const [addedMembers, setAddedMembersState] = useState([]);
  const [deleteparticipantData, setDeleteparticipantData] = useState([]);

  const alertMessage = (message) => {
    setAlertContent({
      alertLocation: "",
      alertTitle: "Program Registration Error!",
      alertBody: message,
      alertClose: "hidden",
      alertStay: "CLOSE",
    });
    setOpenAlert(true);
  };

  const [waitingList, setWaitingList] = React.useState();
  let copyFamilyMembers = getValues("familyMembers");

  const participantAlreadyAdded = (newParticipantID) => {
    copyFamilyMembers.some((item) => item._id === newParticipantID);
  };

  const getWaitingList = () => {
    const { programID } = programData;
    ProgramsWaitingListAPI.GET.getOneWaitingList(programID)
      .then((success) => {
        setWaitingList(success.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const participantAlreadyRegistered = (newParticipant) => {
    const { programAttendance } = programData;
    if (programData.weeklyProgram) return true;
    if (
      !newParticipant.programAttendance ||
      newParticipant.programAttendance.length === 0
    )
      return true;
    for (let i = 0; i < newParticipant.programAttendance.length; i++) {
      if (newParticipant.programAttendance[i] === programAttendance) {
        alertMessage("Family Member Already registered to this program.");
        return false;
      }
    }
    return true;
  };

  const participantHasAppliedToWaitingList = (newParticipant) => {
    for (let i = 0; i < waitingList.length; i++) {
      const { familyMembers } = waitingList[i];
      for (
        let memberIndex = 0;
        memberIndex < familyMembers.length;
        memberIndex++
      ) {
        if (familyMembers[memberIndex] === newParticipant._id) {
          alertMessage("Family Member is on waiting list");
          return false;
        }
      }
    }
    return true;
  };

  const getAge = function getAge(DOB) {
    const today = new Date();
    const birthDate = new Date(DOB);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age -= 1;
    }
    return age;
  };

  const checkProgramAges = (programAge, familyMemberAge) => {
    // FIX THIS
    // Date calculation wrong by 2 days
    const age = getAge(familyMemberAge);
    const splitAge = age.toString().split("");
    if (age < 0) return false;
    if (splitAge.length < 3) {
      if (programAge === "Youth (13-17)") {
        if (age >= 13 && age <= 17) return true;
      } else if (programAge === "Senior") {
        if (age >= 65) return true;
      } else if (programAge === "Adult (18+)") {
        if (age >= 18) return true;
      } else if (programAge === "Children (6-12)") {
        if (age >= 6 && age <= 12) return true;
      } else if (programAge === "Children (8-12)") {
        if (age >= 8 && age <= 12) return true;
      } else if (programAge === "Pre-school (0-5)") {
        if (age <= 5) return true;
      } else if (programAge === "Child and Youth") {
        if (age >= 6 && age <= 17) return true;
      } else if (programAge === "Youth and Adult") {
        if (age >= 13 && age <= 64) return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const checkWeeklyProgramAges = (familyMemberAge) => {
    const { ageMinimum, ageMaximum } = programData;
    const age = getAge(familyMemberAge);
    if (age < 0) return false;
    if (age >= parseInt(ageMinimum, 10) && age <= parseInt(ageMaximum, 10))
      return true;
    return false;
  };

  const isParticipantAgeCompatible = (newParticipant) => {
    const { ages, weeklyProgram, ageMinimum, ageMaximum } = programData;
    if (weeklyProgram) {
      if (checkWeeklyProgramAges(newParticipant.dateOfBirth)) return true;
    }
    if (ages === "All Ages") return true;
    if (checkProgramAges(ages, newParticipant.dateOfBirth)) return true;
    alertMessage(
      `Only ${ages ||
        `participants from ages ${ageMinimum} to ${ageMaximum} years old`} can register for this program.`
    );
    return false;
  };

  const isParticipantGenderCompatible = (newParticipant) => {
    const { gender } = programData;
    const programGender = gender.toLowerCase();
    const participantGender = newParticipant.gender.toLowerCase();
    if (programGender === "all") return true;
    if (programGender === "man" && participantGender === "man") return true;
    if (programGender === "woman" && participantGender === "woman") return true;
    if (programGender === "non-binary" && participantGender === "non-binary")
      return true;
    if (
      programGender === "woman and non-binary" &&
      (participantGender === "woman" || participantGender === "non-binary")
    )
      return true;
    if (
      programGender === "man and non-binary" &&
      (participantGender === "man" || participantGender === "non-binary")
    )
      return true;
    alertMessage(`Only ${gender} participants can register for this program.`);
    return false;
  };

  const returnWeeklySpotsBasedOnMembershipType = (week) => {
    const MEMBERSHIP_TYPE = getValues(
      "membership"
    ).membershipType.toLowerCase();
    if (MEMBERSHIP_TYPE === "associate") {
      return week.associateSpots - week.familyMembers.length;
    }
    return week.communitySpots - week.familyMembers.length;
  };

  const checkWeekSpots = (week) => {
    const spotsLeft = returnWeeklySpotsBasedOnMembershipType(week);
    if (spotsLeft > 0) return true;
    return false;
  };
  const handleDeleteClose = () => {
    setOpenDeleteParticipant(false);
  };
  const noSpotsLeftOnWeeklyProgram = () => {
    let result = [];
    programData.weeklySchedule.forEach((week) => {
      if (!checkWeekSpots(week)) {
        result.push(true);
      }
    });

    if (result.length === programData.weeklySchedule.length) {
      alertMessage("There are no spots left on this program.");
      return true;
    }
    return false;
  };

  const validateSelectedParticipant = (newParticipant) => {
    if (copyFamilyMembers && participantAlreadyAdded(newParticipant._id))
      return false;
    if (programData.weeklyProgram) {
      if (noSpotsLeftOnWeeklyProgram()) return false;
    }
    if (
      isParticipantAgeCompatible(newParticipant) &&
      isParticipantGenderCompatible(newParticipant) &&
      participantAlreadyRegistered(newParticipant) &&
      participantHasAppliedToWaitingList(newParticipant)
    ) {
      return true;
    }
    return false;
  };

  const addParticipant = (newParticipant) => {
    if (!copyFamilyMembers) {
      return setValue("familyMembers", [newParticipant]);
    }
    getValues("familyMembers").push(newParticipant);
    return setValue("familyMembers", getValues("familyMembers"));
  };

  const handleDelete = () => {
    const removeSelectedData = addedMembers.filter(
      (item) => item._id !== deleteparticipantData._id
    );
    setAddedMembersState(removeSelectedData);
    setOpenDeleteParticipant(false);
  };

  const handleParticipant = (event) => {
    const { value } = event.target;
    if (!value) return false;
    addParticipant(value);
  };

  const handleDeleteParticipant = (participantIndex) => {
    const familyMembers = getValues("familyMembers");
    if (familyMembers.length === 0) return false;
    familyMembers.splice(participantIndex, 1);
    return setValue("familyMembers", familyMembers);
  };

  const handleDeleteAttendee = (data) => {
    setDeleteparticipantData(data);
    setOpenDeleteParticipant(true);
  };

  React.useEffect(() => {
    setAddedMembers(addedMembers);
  }, [addedMembers, setAddedMembers]);

  const handleSubmit = () => {
    const tableData = JSON.parse(sessionStorage.getItem("familyMembersInfo"));
    const currentFamilyMembers = getValues("familyMembers") || [];

    if (tableData) {
      const mergedData = [...tableData, ...currentFamilyMembers];
      sessionStorage.setItem("familyMembersInfo", JSON.stringify(mergedData));
      setAddedMembersState(mergedData);
    } else {
      setAddedMembersState(currentFamilyMembers);
      sessionStorage.setItem(
        "familyMembersInfo",
        JSON.stringify(currentFamilyMembers)
      );
    }

    setValue("familyMembers", []);
    setShowparticipants(false);
    defaultMemberShip(true);
    window.scroll({ top: 0, behavior: 'smooth' });
    setAddData(true);
  };

  const handleMedicalInformation = (updatedFamilyMembers, index) => {
    copyFamilyMembers[index] = updatedFamilyMembers;
    setValue("familyMembers", copyFamilyMembers);
  };

  const displayMedicalInformation = (familyMemberAge) => {
    const age = getAge(familyMemberAge);
    if (age < 0) return false;
    if (age <= 12) return true;
    return false;
  };

  useEffect(() => {
    if (!data.familyMembers) {
      register("familyMembers", {
        required: "Program participant is required!",
      });
    }
    setShowparticipants(true);
    setMembershipId(getValues("membership")?._id);
    watch("familyMembers");
    getWaitingList();
  }, []);

  useEffect(() => {
    if (
      getValues("membership") &&
      membershipId !== getValues("membership")?._id
    ) {
      setShowparticipants(true);
    }
  }, [getValues("membership")]);

  if (getValues("membership") !== undefined) {
    sessionStorage.setItem(
      "memberShipInfo",
      JSON.stringify(getValues("membership"))
    );
  }

  useEffect(() => {
    const pathname = window.location.pathname;
    const segments = pathname.split("/");
    if (segments.length === 6) {
      const attendanceID = segments[segments.length - 1];
      sessionStorage.setItem(
        "exisitingAttendanceID",
        JSON.stringify(attendanceID)
      );

      if (attendanceID) {
        ProgramAttendanceWithoutRegistration.GET.getSingleAttendanceItemByAttendanceID(
          attendanceID
        )
          .then((success) => {
            const attendanceObject = success.data[0].familyMembers;
            setAddData(true);
            setAddedMembersState(attendanceObject);
            sessionStorage.setItem(
              "familyMembersInfo",
              JSON.stringify(attendanceObject)
            );
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, []);

  return (
    <Grid container>
      {getValues("membership") && showparticipants && (
        <>
          <Grid item xs={12}>
            <h3 className="programRegistration-titles">Program Participant</h3>
          </Grid>
          <Grid item xs={12}>
            <ParticipantDropdown
              id="familyMembers"
              label="Family Members"
              name="Family Members"
              value={getValues("familyMembers")}
              handleChanges={handleParticipant}
              helperText={
                errors.familyMembers ? errors.familyMembers.message : ""
              }
              error={!!errors.familyMembers}
              listName={
                getValues("membership")
                  ? getValues("membership").familyMembersInformation
                  : []
              }
            />
          </Grid>
        </>
      )}
      {getValues("familyMembers") &&
        copyFamilyMembers.map((item, index) => (
          <Grid
            container
            className="programRegistration-membership-box mb-4"
            key={item.firstName}
          >
            <ParticipantFoundBox
              participant={item}
              deleteParticipant={handleDeleteParticipant}
              index={index}
            />
            {(programData.ages === "Child and Youth" ||
              programData.ages === "Children (6-12)" ||
              displayMedicalInformation(item.dateOfBirth)) && (
              <MedicalInformation
                participant={item}
                index={index}
                handleMedicalInformation={handleMedicalInformation}
              />
            )}
          </Grid>
        ))}
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <StepButtons
          location="/adminPortal/programs/available"
          handleSubmit={handleSubmit}
          type="submit"
          disable={!showparticipants || !getValues("familyMembers")}
          message="Are you sure you want to leave this page before completing adding attendees ? Data you have entered may not be saved."
        />
      </Grid>
      <AttendeesTable
        membershipID={
          getValues("membership") ? getValues("membership").membershipID : 0
        }
        attendanceData={addedMembers}
        handleDeleteAttendee={handleDeleteAttendee}
        addData={addData}
      />
      <DeleteParticipant
        open={openDeleteParticipant}
        close={handleDeleteClose}
        handleDelete={() => handleDelete()}
        data={deleteparticipantData}
      />
      <Alert
        open={openAlert}
        title={alertContent.alertTitle}
        body={alertContent.alertBody}
        stay={alertContent.alertStay}
        leave={alertContent.alertClose}
        location={alertContent.alertLocation}
        close={() => setOpenAlert(false)}
      />
    </Grid>
  );
}

ProgramParticipant.propTypes = {
  formState: PropTypes.shape({
    errors: PropTypes.any,
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  data: PropTypes.shape({
    familyMembers: PropTypes.string,
  }).isRequired,
  programData: PropTypes.shape({
    membershipType: PropTypes.string,
    gender: PropTypes.string,
    ages: PropTypes.string,
    weeklyProgram: PropTypes.string,
    ageMinimum: PropTypes.string,
    ageMaximum: PropTypes.string,
  }).isRequired,
};

export default ProgramParticipant;
