import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid } from '@material-ui/core';
import { BootstrapTextField } from '../../../materialUi/bootstrapInputs';

function UserInfo({ userData, values }) {
  return (
    <Paper className="body-boxes m-3">
      <Grid item xs={12} className="Boxes-main-title">
        Personal Info
      </Grid>
      <Grid item xs={12} className="membershipInfo-boxes-spacing">
        <Grid container spacing={3}>
          <Grid item xs={2} className="pt-4">
            Day Phone Number:
          </Grid>
          <Grid item xs={5}>
            <BootstrapTextField
              id="dayPhone"
              type="text"
              name="Day Phone"
              value={userData.userData.dayPhone}
              handleChanges={() => {}}
              message=""
              readOnly={false}
              error={false}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="membershipInfo-boxes-spacing">
        <Grid container spacing={3}>
          <Grid item xs={2} className="pt-4">
            Night Phone Number:
          </Grid>
          <Grid item xs={5}>
            <BootstrapTextField
              id="nightPhone"
              type="text"
              name="Night Phone"
              value={userData.userData.nightPhone}
              handleChanges={() => {}}
              message=""
              readOnly={false}
              error={false}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="membershipInfo-boxes-spacing">
        <Grid container spacing={3}>
          <Grid item xs={2} className="pt-4">
            Email:
          </Grid>
          <Grid item xs={5}>
            <BootstrapTextField
              id="email"
              type="text"
              name="Email"
              value={userData.userData.email}
              handleChanges={() => {}}
              message=""
              readOnly={false}
              error={false}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

UserInfo.propTypes = {
  userData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    userType: PropTypes.string,
    dayPhone: PropTypes.string,
    nightPhone: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

export default UserInfo;
