import React from 'react';
import { Grid, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Stepper from '../../../materialUi/stepper';

function AddGuestHeader({ currentStep, handleBack }) {
  return (
    <>
      <Grid item xs={12}>
        <span
          className="addNewAttendance-back"
          onClick={handleBack}
          onKeyPress={handleBack}
          role="button"
          tabIndex={0}
        >
                &lt; Back
        </span>
      </Grid>
      <Grid item xs={12}>
        <h1 className="covid19-screening-title">Check-In</h1>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Box className="covid19-stepper-box">
            <Stepper
              labels={['General Information', 'Pre-Screening', 'Complete Check-in']}
              step={currentStep}
              background="transparent"
              bottomLabel
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

AddGuestHeader.propTypes = {
  currentStep: PropTypes.number.isRequired,
  handleBack: PropTypes.func.isRequired,
};

export default AddGuestHeader;
