import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import TextField from '../../../../../materialUi/textField';
import Dropdown from '../../../../../materialUi/dropdown';
import Alert from '../../../../../materialUi/Alerts';

function Members(props) {
  const {
    memberNumber,
    membershipCategory,
    addFamilyMember,
    data,
    formState,
    setError,
    setValue,
    control,
  } = props;
  const { errors } = formState;

  const relationshipList = [
    { name: 'Select One' },
    { name: 'Spouse' },
    { name: 'Child' },
    { name: 'Parent' },
    { name: 'Sibling' },
    { name: 'Grandparents' },
    { name: 'Grandchild' },
  ];

  const genderList = [
    { name: 'Man' },
    { name: 'Woman' },
    { name: 'Non-binary' },
    { name: 'I choose not to disclose' },
  ];

  const [dateError, setDateError] = React.useState();
  const [familyMemberModel, setModel] = React.useState({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    phone: '',
    school: '',
    gender: '',
  });

  const getAge = (date) => {
    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age -= 1;
    }
    return age;
  };

  const setFormErrors = () => {
    setValue(`dateOfBirth${memberNumber}`, '', { shouldValidate: true });
    setError(`dateOfBirth${memberNumber}`, {
      type: 'required',
      message: 'Please select a Category that best fits your age.',
    });
  };

  const compareUserAgeToSelectedCategory = (value) => {
    saveFamilyMembersModel('dateOfBirth', value);
    // FIX THIS
    // Date calculation wrong by 2 days
    let ageValidation = false;
    const age = getAge(value);
    const splitAge = age.toString().split('');
    if (age < 0) {
      ageValidation = true;
    }
    if (splitAge.length < 3) {
      if (membershipCategory === 'Youth') {
        if (age < 13 || age > 17) {
          ageValidation = true;
        }
      } else if (membershipCategory === 'Senior') {
        if (age < 65) {
          ageValidation = true;
        }
      } else if (membershipCategory === 'Adult') {
        if (age <= 18 && age >= 64) {
          ageValidation = true;
        }
      } else if (membershipCategory === 'Child') {
        if (age > 12) {
          ageValidation = true;
        }
      }
    }
    if (ageValidation) {
      setFormErrors();
      document.getElementById(`dateOfBirth${memberNumber}`).focus();
    }
    setDateError(ageValidation);
  };

  const saveFamilyMembersModel = (name, value) => {
    const familyMemberModelCopy = familyMemberModel;
    familyMemberModelCopy[name] = value;
    setModel(familyMemberModelCopy);
    addFamilyMember(familyMemberModelCopy, memberNumber);
  };

  const addSavedFamilyMembersToForm = (name, value) => {
    setValue(`${name}${memberNumber}`, value);
  };

  const addSavedFamilyMembers = () => {
    const memberObj = {};
    Object.keys(data.familyMembers[memberNumber]).forEach((key) => {
      addSavedFamilyMembersToForm(key, data.familyMembers[memberNumber][key]);
      memberObj[key] = data.familyMembers[memberNumber][key];
    });
    /*
      NOTE:
        Needs to send the values to the form. Otherwise it
        will replace when the user press submit again.
    */
    addFamilyMember(memberObj, memberNumber);
  };

  React.useEffect(() => {
    // registerNewFields();
    if (data.familyMembers) {
      if (data.familyMembers[memberNumber]) {
        addSavedFamilyMembers();
      }
    }
  }, [data]);

  return (
    <Grid container className="registration-form-members-wrapper">
      <Grid item xs={12}>
        <h2 className="registration-form-sub-title">
          {memberNumber === 0 ? 'Primary Member' : `Family Member ${memberNumber}`}
        </h2>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Controller
            name={`firstName${memberNumber}`}
            control={control}
            rules={{
              required: 'First Name is required',
              maxLength: { value: 100, message: 'Max length 100' },
              validate: (value) => saveFamilyMembersModel('firstName', value),
            }}
            render={({ field }) => (
              <TextField
                id={`firstName${memberNumber}`}
                type="text"
                name="firstName"
                label="First Name"
                helperText={errors[`firstName${memberNumber}`] ? errors[`firstName${memberNumber}`].message : ''}
                error={!!errors[`firstName${memberNumber}`]}
                width="100%"
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            name={`lastName${memberNumber}`}
            control={control}
            rules={{
              required: 'Last Name is required',
              maxLength: { value: 100, message: 'Max length 100' },
              validate: (value) => saveFamilyMembersModel('lastName', value),
            }}
            render={({ field }) => (
              <TextField
                id={`lastName${memberNumber}`}
                type="text"
                name="lastName"
                label="Last Name"
                helperText={errors[`lastName${memberNumber}`] ? errors[`lastName${memberNumber}`].message : ''}
                error={!!errors[`lastName${memberNumber}`]}
                width="100%"
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            name={`dateOfBirth${memberNumber}`}
            control={control}
            rules={{
              validate: (value) => compareUserAgeToSelectedCategory(value),
              required: 'Date of birth is required',
              maxLength: { value: 200, message: 'Max length 200' },
              pattern: {
                value: /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/i,
                message: 'Invalid Date Format',
              },
            }}
            render={({ field }) => (
              <TextField
                id={`dateOfBirth${memberNumber}`}
                type="date"
                name="dateOfBirth"
                label="Date of birth"
                helperText={errors[`dateOfBirth${memberNumber}`] ? errors[`dateOfBirth${memberNumber}`].message : ''}
                error={!!errors[`dateOfBirth${memberNumber}`]}
                width="100%"
                shrink
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            name={`phone${memberNumber}`}
            control={control}
            rules={{
              required: 'Phone number is required',
              maxLength: { value: 20, message: 'Max length 20' },
              pattern: {
                value: /^[\]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/i,
                message: 'Invalid Phone Format',
              },
              validate: (value) => saveFamilyMembersModel('phone', value),
            }}
            render={({ field }) => (
              <TextField
                id={`phone${memberNumber}`}
                type="text"
                name="phone"
                label="Phone#"
                helperText={errors[`phone${memberNumber}`] ? errors[`phone${memberNumber}`].message : ''}
                error={!!errors[`phone${memberNumber}`]}
                width="100%"
                {...field}
              />
            )}
          />
        </Grid>
        {memberNumber !== 0 && (
        <Grid item xs={12} md={4}>
          <Controller
            name={`relationship${memberNumber}`}
            control={control}
            defaultValue=""
            rules={{
              required: 'Relationship is required',
              maxLength: { value: 50, message: 'Max length 50' },
              validate: (value) => saveFamilyMembersModel('relationship', value),
            }}
            render={({ field }) => (
              <Dropdown
                id={`relationship${memberNumber}`}
                name="relationship"
                label="Relationship"
                helperText={errors[`relationship${memberNumber}`] ? errors[`relationship${memberNumber}`].message : ''}
                error={!!errors[`relationship${memberNumber}`]}
                listName={relationshipList}
                {...field}
              />
            )}
          />
        </Grid>
        )}
        <Grid item xs={12} md>
          <Controller
            name={`school${memberNumber}`}
            control={control}
            rules={{
              maxLength: { value: 50, message: 'Max length 50' },
              validate: (value) => saveFamilyMembersModel('school', value),
            }}
            render={({ field }) => (
              <TextField
                id={`school${memberNumber}`}
                type="text"
                name="school"
                label="School"
                helperText={errors[`school${memberNumber}`] ? errors[`school${memberNumber}`].message : 'Optional'}
                error={!!errors[`school${memberNumber}`]}
                width="100%"
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md>
          <Controller
            name={`gender${memberNumber}`}
            control={control}
            defaultValue=""
            rules={{
              required: 'Gender is required',
              maxLength: { value: 50, message: 'Max length 50' },
              validate: (value) => saveFamilyMembersModel('gender', value),
            }}
            render={({ field }) => (
              <Dropdown
                id={`gender${memberNumber}`}
                name="gender"
                label="Gender"
                helperText={errors[`gender${memberNumber}`] ? errors[`gender${memberNumber}`].message : ''}
                error={!!errors[`gender${memberNumber}`]}
                listName={genderList}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
      <Alert
        open={dateError}
        close={() => setDateError(false)}
        location="/"
        title={`Age not compatible to ${membershipCategory} category`}
        body="Please select a category that best fits your age."
        stay="Close"
        leave="hidden"
      />
    </Grid>
  );
}

Members.propTypes = {
  formState: PropTypes.shape({
    errors: PropTypes.any,
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  setError: PropTypes.func.isRequired,
  memberNumber: PropTypes.number.isRequired,
  membershipCategory: PropTypes.string.isRequired,
  data: PropTypes.shape(
    {
      membershipCategory: PropTypes.string,
      familyMembers: PropTypes.arrayOf(
        PropTypes.shape(
          {
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            dateOfBirth: PropTypes.string,
            phone: PropTypes.string,
            school: PropTypes.string,
            relationship: PropTypes.string,
            gender: PropTypes.string,
          },
        ),
      ),
    },
  ).isRequired,
  addFamilyMember: PropTypes.func.isRequired,
};

export default Members;
