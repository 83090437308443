import React, { Component } from 'react';
import { Grid, Box } from '@material-ui/core';
import Stepper from '../../../materialUi/stepper';
import { BootstrapTextField } from '../../../materialUi/bootstrapInputs';
import Btn from '../../../materialUi/btn';
import CancelAlert from '../../../materialUi/cancelAlert';
import { validateField } from '../../../utils/fieldsValidation';
import { FamilyAPI } from '../../../API/API-familyMembers';
import { membershipAPI } from '../../../API/API-membership';
import Alert from '../../../materialUi/Alerts';

export default class FamilyMemberCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAlert: false,
      membershipCard: '',
      membershipCardError: '',
      membershipCardMessage: '',
      openCancelAlert: '',
      membershipID: this.props.match.params.membershipID,
    };
  }

  componentDidMount() {
    membershipAPI.GET.getMembershipByID(this.props.match.params.membershipID)
      .then((success) => {
        console.log(success);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          openAlert: true,
          alertTitle: 'User not found!',
          alertBody: 'We could not find the membership. Please try again later!',
          alertClose: 'CLOSE',
        });
      });
    const memberFromSession = JSON.parse(sessionStorage.getItem('addMember1'));
    if (!memberFromSession) {
      this.props.history.push(`/adminPortal/membership/verifyMembership/${this.props.match.params.membershipID}`);
    } else {
      if (!memberFromSession.addFamilyValidation) {
        this.props.history.push(`/adminPortal/membership/addFamilyMember/${this.props.match.params.membershipID}`);
      }
    }
  }

  handleMembershipCard = (value, type) => {
    if (validateField(value, type) === true) {
      this.setState({ membershipCard: value, membershipCardError: false, membershipCardMessage: '' });
    } else {
      this.setState({ membershipCard: value, membershipCardError: true, membershipCardMessage: 'Membership Card cannot be empty' });
    }
  };

  handleSubmit = () => {
    const { membershipCardError, membershipCard, membershipID } = this.state;
    if (membershipCardError === false) {
      const familyMemberToSaveOnDB = JSON.parse(sessionStorage.getItem('addMember1'));
      familyMemberToSaveOnDB.membershipCard = membershipCard;
      FamilyAPI.POST.create(membershipID, familyMemberToSaveOnDB)
        .then((success) => {
          console.log(success);
          sessionStorage.removeItem('addMember1');
          window.location = `/adminPortal/membership/verifyMembership/${this.props.match.params.membershipID}`;
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({ membershipCardError: true, membershipCardMessage: 'Membership Card cannot be empty' });
    }
  };

  handleEnterPressed = (event) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      // 13 is the enter keycode
      this.handleSubmit();
    }
  };

  render() {
    const { alertClose, openAlert, alertTitle, alertBody, membershipCard, membershipCardError, membershipCardMessage, openCancelAlert } = this.state;
    return (
      <Grid item xs className="font-family-default">
        <Stepper labels={['Registration form', 'Membership Card']} step={1} />
        <Grid container className="addNewFamily-spacing">
          <Grid container>
            <Grid item xs={12}>
              <Box className="addnewFamily-back" onClick={() => this.props.history.push(`/adminPortal/membership/addFamilyMember/${this.props.match.params.membershipID}`)}>
                &lt; Back to Add Family Member
              </Box>
            </Grid>
            <Grid item xs={12} className="addNewFamily-title">
              Membership Card
            </Grid>
            <Grid item xs={8} className="addNewFamily-paragraph">
              To successfully complete the membership, scan the barcode on the back of the membership card.{' '}
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} lg={6}>
                  <Grid container className="addNewFamily-barcode-spacing">
                    <Grid item xs={2} className="addNewFamily-barcode">
                      Bar Code:
                    </Grid>
                    <Grid item xs onKeyPress={this.handleEnterPressed}>
                      <BootstrapTextField
                        id="emergencyWorkPhone"
                        type="text"
                        name="emergencyWorkPhone"
                        value={membershipCard}
                        handleChanges={(value) => this.handleMembershipCard(value.target.value, 'name', 'Membership Card')}
                        error={membershipCardError}
                        message={membershipCardMessage}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={0} lg={6} />
                <Grid item xs={12}>
                  <Grid container direction="row" justifyContent="flex-end" alignItems="center" className="addNewFamily-btn">
                    <Box className="addNewFamily-btn-done">
                      <Btn
                        label="Cancel"
                        bgColor="white"
                        color="black"
                        bgColorHover="#eceff1"
                        colorHover="white"
                        borderColor="white"
                        variant="outlined"
                        width={128}
                        onClick={() => this.setState({ openCancelAlert: true })}
                      />
                      <CancelAlert open={openCancelAlert} close={() => this.setState({ openCancelAlert: false })} location="/" />
                    </Box>
                    <Box className="addNewFamily-btn-done">
                      <Btn label="Submit" onClick={this.handleSubmit} bgColor="#e76f50" color="white" bgColorHover="#ff8c68" colorHover="white" width={128} />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Alert open={openAlert} close={() => this.setState({ openAlert: false })} location="/adminPortal/membership" title={alertTitle} body={alertBody} stay="" leave={alertClose} />
      </Grid>
    );
  }
}
