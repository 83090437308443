import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import QuestionnaireButtons from '../questionnaireButtons';
import Documents from './components/documents';
import SubmissionMethod from './components/submissionMethod';
import BankInformation from './components/bankInformation';
import '../../../../css/taxes.css';

function StepThree({ handleNextStep, data, handleAlert, editTax, handlePrevious }) {
  const useFormMethods = useForm({ mode: 'all' });
  const {
    handleSubmit,
    setValue,
    register,
    getValues,
    watch,
    setError,
    formState: { errors },
  } = useFormMethods;

  const pastYear = new Date().getFullYear() - 1;

  const [formAction, setFormAction] = React.useState('');

  const handleFormAction = (action) => {
    setFormAction(action);
  };

  // const removeOtherFromIncomeSlip = (formData) => {
  //   const otherSelection = formData.incomeSlip.indexOf('other');
  //   formData.incomeSlip.splice(otherSelection, 1);
  // };

  const checkFiles = (formData) => {
    if (getValues('submissionMethod') !== 'upload') return true;
    if (formData.documentsChecklist && formData.documentsChecklist.length < 3) return false;
    if (formData.selectedFiles && formData.selectedFiles.length < 3) return false;
    if (!formData.selectedFiles && formData.taxReturnFiles.length < 3) return false;
    return true;
  };

  const validateCheckList = (formData) => {
    if (!formData.documentsChecklist) return false;
    if (formData.documentsChecklist.length !== 3) return false;
    return true;
  };

  const handleFormSubmit = (formData) => {
    if (formData.readyToUpload === false) {
      return setError('readyToUpload', {
        type: 'manual',
        message: 'The renting receipts are required!',
      });
    }
    if (!checkFiles(formData)) {
      return setError('documentsChecklist', {
        type: 'manual',
        message: 'Please check and upload all three required documents',
      });
    }

    if (!validateCheckList(formData)) {
      return setError('documentsChecklist', {
        type: 'manual',
        message: 'Please make sure to upload and check all the documents checklist boxes',
      });
    }
    if (formAction === 'save') {
      // Please remove the "other" selection before submitting the form
      // removeOtherFromIncomeSlip(formData);
      // formData.incomeSlip = [];
      handleNextStep(formData, 2);
    } else {
      handleNextStep(formData, 3);
    }
  };

  const setInitialFormValues = () => {
    if (data.incomeSlip) {
      setValue('incomeSlip', data.incomeSlip, { shouldValidate: true });
    }
    if (data.identification) {
      setValue('identification', data.identification, { shouldValidate: true });
    }
    if (data.submissionMethod) {
      setValue('submissionMethod', data.submissionMethod, { shouldValidate: true });
      if (data.submissionMethod === 'upload') {
        if (data.taxReturnFiles.length > 0) {
          register('uploadMethod');
        } else {
          register('uploadMethod', { required: 'Select at least one File' });
        }
      }
      if (data.submissionEmail) {
        setValue('submissionEmail', data.submissionEmail, { shouldValidate: true });
      }
    }
    if (data.submissionMethod) {
      setValue('submissionMethod', data.submissionMethod, { shouldValidate: true });
    }
    if (data.taxReturnFiles) {
      setValue('taxReturnFiles', data.taxReturnFiles, { shouldValidate: true });
    }
    if (data.bankInformation) {
      setValue('bankInformation', data.bankInformation, { shouldValidate: true });
    }
    if (data.readyToUpload) {
      setValue('readyToUpload', data.readyToUpload);
    } else {
      setValue('readyToUpload', false);
    }
    if (data.documentsChecklist) {
      setValue('documentsChecklist', data.documentsChecklist);
    }
    console.log(data)
    watch('readyToUpload')
  };

  React.useEffect(() => {
    setInitialFormValues();
  }, [data]);

  return (
    <FormProvider {...useFormMethods}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container>
          {/* Form Body */}
          <Documents {...useFormMethods} data={data} />
          {getValues('readyToUpload') === true && (
            <>
              <SubmissionMethod {...useFormMethods} data={data} handleAlert={handleAlert}/>
              <BankInformation {...useFormMethods} data={data} />
            </>
          )}

          {/* Action Buttons */}
          <hr className="tax_custom_hr" />
          <QuestionnaireButtons
            handleAction={(value) => handleFormAction(value)}
            nextLabel={editTax ? 'Done' : false}
            handlePrevious={handlePrevious}
          />
        </Grid>
      </form>
    </FormProvider>
  );
}

export default StepThree;
