import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

/* < ------------   Membership routes  ---------------- > */
// Membership registration
import MembershipRegistration from '../../pages/membership/membershipRegistration/membershipRegistration';

// Membership List
import MembershipList from '../membership/memberList/membershipList';

// Verify Membership
import VerifyMembership from '../membership/verifyMembership/verifyMembership';

// change membership Type
import ChangeMembershipTypeStep1 from '../membership/changeMembershipType/step1/selectCategory';
import ChangeMembershipTypeStep2 from '../membership/changeMembershipType/step2/payment';
import ChangeMembershipTypeSuccess from '../membership/changeMembershipType/success/success';

// renew Membership
import RenewMembership from '../../pages/membership/renewMembership/renewMembership';

// Verify Address
import VerifyAddressStep1 from '../membership/verifyAddress/step1/verifyAddress';
import VerifyAddressStep2 from '../membership/verifyAddress/step2/payment';
import VerifyAddressSuccess from '../membership/verifyAddress/success/succes';

// Membership Receipt
import MembershipReceipt from '../membership/receipts/receipts';

// Add A Family Member
import AddNewFamilyMember from '../membership/addNewFamilyMember/addFamilyMember';
import FamilyMemberCard from '../membership/addNewFamilyMember/familyCard';

// Change Membership Card
import ChangeCard from '../membership/changeCard/membershipCard';

// Membership Attendance
import MembershipAttendance from '../programs/memberAttendance/memberAttendance';

/* < ---- Programs ---- > */

import AddNewProgram from '../../pages/programs/addNewProgram/addNewProgram';
import EditProgram from '../../pages/programs/editProgram/editProgram';
import ProgramDetails from '../../pages/programs/programDetails/programDetails';
import ProgramRegistration from '../../pages/programs/programRegistration/programRegistration';
import AddAttendance from '../../pages/programs/addAttendance/addAttendance';

// TODO: refactoring pending
import ProgramsList from '../programs/programsList/programsList';
import ProgramsHistory from '../programs/programsHistory/programsHistory';
import RegistrationList from '../programs/registrationList/registrationList';

// Attendance List
import CreateAttendance from '../programs/createAttendanceWithoutRegistration/createAttendanceWithoutRegistration';
import AddNewAttendance from '../programs/addNewAttendance/addNewAttendance';
import AttendanceListDetails from '../programs/attendanceListDetails/attendanceListDetails';
import EditAttendance from '../programs/editAttendance/editAttendance';

// Submitted Attendance list
import SubmitedAttendances from '../programs/submitedAttendances/submitedAttendances';

// Registration Details
import RegistrationDetails from '../programs/registrationDetails/registrationDetails';

import WaitingListPayment from '../../pages/programs/waitingListPayment/waitingListPayment';

/* < -------------------------------   Reports routes  ------------------------------------- > */
import Reports from '../reports/reports';

// Receipts
import Receipts from '../reports/revenue/receipts';

// Covid19
import CheckInHistory from '../reports/covid19/checkInHistory/checkInHistory';
import UserHistory from '../reports/covid19/userHistory/userHistory';
import DailyCheckInList from '../reports/covid19/dailyCheckInList/dailyCheckInList';
import DailyCheckIn from '../reports/covid19/dailyCheckIn/dailyCheckIn';

// Membership Reports
import MembersReport from '../reports/memberships/membersReport';

// Taxes
import TaxSubmissionList from '../reports/taxes/taxSubmissionList/taxSubmissionList';
import ViewTaxSubmission from '../reports/taxes/viewTaxSubmission/viewTaxSubmission';
import EditTaxSubmission from '../reports/taxes/editTaxSubmission/editTaxSubmission';

/* < -------------------------------   END: Reports routes  ------------------------------------- > */

// reservation routes
import ReservationSession from '../../pages/reservations/reservationSession/reservationSession';
import ReservationList from '../../pages/reservations/reservationList/reservationList';

import Dashboard from '../dashboard/dashboard';
import Settings from '../settings/settings';

// Covid19 Portal
import Covid19Portal from '../covid19/covid19';

// settings
import ProfileSettings from '../profile/profile';

import ImportData from '../../utils/dataMigration';

const SuperAdminRoutes = function (userData) {
  return (
    <Switch>
      {/* Import Data */}
      <Route path="/adminPortal/dataMigration" component={ImportData} />

      {/* Dashboard */}
      <Route path="/adminPortal/dashboard/:tab?" render={(props) => <Dashboard {...props} userData={userData} />} />


      {/* Membership Routes */}
      <Route path="/adminPortal/membership/register" render={(props) => <MembershipRegistration {...props} userData={userData} />} />
      <Route path="/adminPortal/membership/changeMembershipType/success" component={ChangeMembershipTypeSuccess} />
      <Route path="/adminPortal/membership/changeMembershipType/payment/:membershipID" component={ChangeMembershipTypeStep2} />
      <Route path="/adminPortal/membership/changeMembershipType/:membershipID" component={ChangeMembershipTypeStep1} />
      <Route path="/adminPortal/membership/verifyMembership/:membershipID" component={VerifyMembership} />
      <Route path="/adminPortal/membership/receipts/:membershipID" component={MembershipReceipt} />
      <Route path="/adminPortal/membership/verifyAddress/payment/:membershipID" component={VerifyAddressStep2} />
      <Route path="/adminPortal/membership/verifyAddress/success" component={VerifyAddressSuccess} />
      <Route path="/adminPortal/membership/verifyAddress/:membershipID" component={VerifyAddressStep1} />
      <Route path="/adminPortal/membership/addFamilyMember/:membershipID" component={AddNewFamilyMember} />
      <Route path="/adminPortal/membership/addFamilyMemberCard/:membershipID" component={FamilyMemberCard} />
      <Route path="/adminPortal/membership/changeCard/:membershipID" component={ChangeCard} />

      <Route path="/adminPortal/membership/renewMembership/:membershipID" render={(props) => <RenewMembership {...props} userData={userData} />} />

      <Route path="/adminPortal/membership" component={MembershipList} />

      {/* Programs Routes */}
      <Route path="/adminPortal/programs/registrationDetails/:programID/:membershipID/:familyMemberID" render={(props) => <RegistrationDetails {...props} userData={userData} />} />
      
      <Route path="/adminPortal/programs/memberProgramAttendance/:membershipID/:programID" component={MembershipAttendance} />
      <Route path="/adminPortal/programs/attendanceListDetails/:attendanceListDate/:programID" render={(props) => <AttendanceListDetails {...props} userData={userData} />} />
      <Route path="/adminPortal/programs/addNewAttendance/:programID" component={AddNewAttendance} />
      <Route path="/adminPortal/programs/submitedAttendances/:programID" component={SubmitedAttendances} />
      <Route path="/adminPortal/programs/programRegistration/:programID/:backPage?" render={(props) => <ProgramRegistration {...props} userData={userData} />} />
      <Route path="/adminPortal/programs/AddAttendance/:programID/:backPage?" render={(props) => <AddAttendance {...props} userData={userData} />} />
      <Route path="/adminPortal/programs/editProgram/:programID" component={EditProgram} />
      <Route path="/adminPortal/programs/createattendance/:programID" render={(props) => <CreateAttendance {...props} userData={userData} />}/>
      <Route path="/adminPortal/programs/editattendance/:programID/:attendeenceID" render={(props) => <EditAttendance {...props} userData={userData} />}/>
      <Route path="/adminPortal/programs/registrationList/:programID" render={(props) => <RegistrationList {...props} userData={userData} />} />
      <Route path="/adminPortal/programs/waitingList/registration/:programID/:waitingListID/:membershipID/:step?" render={(props) => <WaitingListPayment {...props} userData={userData} />} />
      <Route path="/adminPortal/programs/programDetails/:programID" render={(props) => <ProgramDetails {...props} userData={userData} />} />
      <Route path="/adminPortal/programs/addNewProgram" component={AddNewProgram} />
      <Route path="/adminPortal/programs/history" render={(props) => <ProgramsHistory {...props} userData={userData} />} />
      <Route path="/adminPortal/programs/*" render={(props) => <ProgramsList {...props} userData={userData} />} />
      

      {/* Reports */}
      <Route path="/adminPortal/reports/dailyCheckIn/:dailyCheckInID/:backRoute" component={DailyCheckIn} />
      <Route path="/adminPortal/reports/dailyCheckInList" component={DailyCheckInList} />
      <Route path="/adminPortal/reports/checkInHistory/userHistory/:userID/:backRoute" component={UserHistory} />
      <Route path="/adminPortal/reports/checkInHistory" component={CheckInHistory} />
      <Route path="/adminPortal/reports/membersReport" component={MembersReport} />

      <Route path="/adminPortal/reports/receipts" component={Receipts} />
      <Route path="/adminPortal/reports/taxes/edit/:formID" render={(props) => <EditTaxSubmission {...props} userData={userData} />} />
      <Route path="/adminPortal/reports/taxes/view/:formID/:tabNumber?" render={(props) => <ViewTaxSubmission {...props} userData={userData} />} />
      <Route path="/adminPortal/reports/taxes" render={(props) => <TaxSubmissionList {...props} userData={userData} />} />
      <Route path="/adminPortal/reports" render={(props) => <Reports {...props} userData={userData} />} />

      {/* Users List */}
      <Route path="/adminPortal/settings" component={Settings} />

      {/* Covid19 */}
      <Route path="/adminPortal/covid19" render={(props) => <Covid19Portal {...props} userData={userData} />} />

      {/* Profile */}
      <Route path="/adminPortal/profile" render={(props) => <ProfileSettings {...props} userData={userData} />} />

      {/* Reservation */}
      <Route
        path="/adminPortal/reservations/sessions"
        render={(props) => <ReservationSession {...props} userData={userData} />}
      />
      <Route
        path="/adminPortal/reservations/list"
        render={(props) => <ReservationList {...props} userData={userData} />}
      />

      <Route path="/adminPortal/*" exact component={MembershipList} />
    </Switch>
  );
};

export default SuperAdminRoutes;

SuperAdminRoutes.propTypes = {
  userData: PropTypes.shape({
    userType: PropTypes.string,
  }).isRequired,
};
