import React from 'react';
import { Grid } from '@material-ui/core';
import Stepper from '../../../materialUi/stepper';
import ProgramInformation from '../../../materialUi/programs/programForm//programInformation/programInformation';
import Schedule from '../../../materialUi/programs/programForm/schedule/schedule';
import ProgramStaff from '../../../materialUi/programs/programForm/programStaff/programStaff';
import { ProgramsAPI } from '../../../API/API-programs';
import PreLoading from '../../../materialUi/preLoading';
import Loading from '../../../materialUi/loading';
import '../../../css/addNewProgram.css';

function EditProgram({ match }) {
  const { programID } = match.params;
  const stepsList = ['Program Information', 'Schedule', 'Program Staff'];
  const [step, setStep] = React.useState(null);
  const [data, setData] = React.useState({});

  const saveDateOnSession = (newData) => {
    sessionStorage.setItem('editProgram', JSON.stringify(newData));
  };

  const submitEditedProgram = (dataToSave) => {
    ProgramsAPI.PUT.editProgram(programID, dataToSave).then((response) => {
      console.log(response);
      sessionStorage.clear();
      window.location = `/adminPortal/programs/registrationList/${programID}`;
    }).catch((error) => {
      console.log(error);
    });
  };

  const addNewData = (newData, newStep) => {
    let copyData = data;
    copyData = { ...data, ...newData };
    copyData.currentStep = newStep;
    setData(copyData);
    saveDateOnSession(copyData);
    return copyData;
  };

  const handleNextStep = (newData, newStep, submit = false) => {
    const dataToSave = addNewData(newData, newStep);
    setStep(newStep);
    if (submit) {
      submitEditedProgram(dataToSave);
    }
  };

  const addProgramToData = (program) => {
    setData(program);
    setStep(0);
  };

  const getProgram = React.useCallback(() => {
    ProgramsAPI.GET.getProgramByID(programID).then((response) => {
      const { program, instructor } = response.data;
      console.log(program[0])
      program[0].instructor = instructor;
      addProgramToData(program[0]);
    }).catch((error) => {
      console.log(error);
    });
  },[])

  React.useEffect(() => {
    async function fetchData() {
      await getProgram();
    }
    fetchData();
  }, [setData, setStep]);

  if (!Object.keys(data).length === 0) return <Loading top="50%" right="40%" />;

  return (
    <Grid item xs>
      <Stepper
        labels={stepsList}
        step={step}
      />
      {!data.name && (
        <div className="addNewProgram-loading">
          <PreLoading />
        </div>
      )}
      {step === 0 && <ProgramInformation data={data} handleNextStep={handleNextStep} />}
      {step === 1 && (
        <Schedule
          data={data}
          handleNextStep={handleNextStep}
          setStepBack={(newStep) => setStep(newStep)}
        />
      )}
      {step === 2 && (
        <ProgramStaff
          data={data}
          handleNextStep={handleNextStep}
          setStepBack={(newStep) => setStep(newStep)}
        />
      )}
    </Grid>
  );
}
export default EditProgram;
