import React from 'react';
import './style.css';

export const TextFieldDefaultValue = function (props) {
  let error = 'NO';
  if (props.error === true) {
    error = '';
  } else {
    error = 'hidden';
  }
  return (
    <div>
      <input
        type={props.type}
        className={`form-control input-success ${props.name !== 'postalCode' ? 'text-capitalize' : 'text-uppercase'}`}
        name={props.name}
        defaultValue={props.defaultValue}
        onChange={props.handleChanges}
        id={props.id}
        readOnly={props.readOnly}
      />
      <div className="col-12 text-danger" id={error}>
        {props.message}
      </div>
    </div>
  );
};

export const BootstrapTextField = function (props) {
  let error = 'NO';
  if (props.error === true) {
    error = '';
  } else {
    error = 'hidden';
  }
  return (
    <div>
      <input
        type={props.type}
        className="form-control input-success "
        name={props.name}
        value={props.value}
        onChange={props.handleChanges}
        readOnly={props.readOnly}
        id={props.id}
      />
      <div className="col-12 text-danger" id={error}>
        {props.message}
      </div>
    </div>
  );
};

export const BootstrapSelectDropdown = function (props) {
  let error = 'NO';
  if (props.error === true) {
    error = '';
  } else {
    error = 'hidden';
  }
  const handleChanges = (value) => {
    props.handleChanges(value);
  };

  if (props.name === 'language') {
    return (
      <div className="input-group">
        <select
          className="custom-select input-success"
          id="inputGroupSelect01"
          value={props.value}
          onChange={handleChanges}
        >
          {props.list.map((option, index) => (
            <option key={index}>{option.name}</option>
          ))}
        </select>
        <div className="col-12 text-danger" id={error}>
          {props.message}
        </div>
      </div>
    );
  }
  return (
    <div className="input-group">
      <select
        className="custom-select input-success"
        id="inputGroupSelect01"
        value={props.value && props.value.toLowerCase()}
        onChange={handleChanges}
      >
        {props.list.map((option, index) => (
          <option key={index}>{option.toLowerCase()}</option>
        ))}
      </select>
      <div className="col-12 text-danger" id={error}>
        {props.message}
      </div>
    </div>
  );
};
