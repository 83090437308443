import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useForm, FormProvider } from "react-hook-form";
import SearchMembers from "./components/searchMembers";
import ProgramParticipant from "./components/programParticipant";
import "../../../../css/programRegistration.css";

function RegistrationForm({ data, userData, programData }) {
  const useFormMethods = useForm({ mode: "all" });
  const [memberShipInfo, setMemberShipInfo] = React.useState("");
  const [isDefault, setIsDeafult] = React.useState(false);
  const [addedMembers, setAddedMembers] = React.useState([]);

  const { setValue } = useFormMethods;

  const handleFoundMembership = (msg) => {
    setMemberShipInfo(msg);
  };

  const handleDefaultMemberShip = (isAdded) => {
    if (isAdded) {
      setIsDeafult(false);
    }
  };

  useEffect(() => {
    if (data.membership) {
      setValue("membership", data.membership);
    }
    if (data.familyMembers) {
      setValue("familyMembers", data.familyMembers);
    }
    if (data.caregiverName) {
      setValue("caregiverName", data.caregiverName);
    }
    if (data.caregiverHomePhone) {
      setValue("caregiverHomePhone", data.caregiverHomePhone);
    }
    if (data.caregiverRelationship) {
      setValue("caregiverRelationship", data.caregiverRelationship);
    }
  }, []); 

  return (
    <FormProvider {...useFormMethods}>
      <div className="programRegistration-wrapper">
        <SearchMembers
          data={data}
          userData={userData}
          programData={programData}
          isMemberShipAdded={isDefault}
          setIsMemberShipAdded={setIsDeafult}
          sendMembership={handleFoundMembership}
          addedMembers={addedMembers}
          addedFamillyMembers={addedMembers}
          {...useFormMethods}
        />
        <ProgramParticipant
          data={data}
          defaultMemberShip={handleDefaultMemberShip}
          programData={programData}
          memberShipInfo={memberShipInfo}
          userData={userData}
          setAddedMembers={setAddedMembers}
          {...useFormMethods}
        />
      </div>
    </FormProvider>
  );
}

RegistrationForm.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  programData: PropTypes.shape({
    membership: PropTypes.string,
    weeklyProgram: PropTypes.bool,
  }).isRequired,
  userData: PropTypes.shape({
    userData: PropTypes.shape({
      userType: PropTypes.string,
    }),
  }).isRequired,
};

export default RegistrationForm;
