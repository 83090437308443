import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import StepButtons from '../../../../materialUi/stepButtons';
import BackButton from '../../../../materialUi/backButton';
import Alert from '../../../../materialUi/Alerts';
import ProgramDuration from './programDuration';
import Seasons from './seasons';
import SelectSchedule from './selectSchedule';
import CheckInDeclined from '../../../../assets/icons/checkIn-declined.svg';

function Schedule({ handleNextStep, setStepBack, data }) {
  const useFormMethods = useForm({ mode: 'all' });
  const { handleSubmit, setValue } = useFormMethods;

  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState({
    alertLocation: '',
    alertTitle: 'Error',
    alertBody: '',
    alertClose: 'hidden',
    alertStay: 'CLOSE',
  });

  const alertMessage = (message, hideButton) => {
    setAlertContent({
      alertLocation: '',
      alertTitle: 'Cannot change the schedule!',
      alertBody: message,
      alertClose: 'hidden',
      alertStay: hideButton ? 'hidden' : 'CLOSE',
    });
    setOpenAlert(true);
  };

  const handleFormSubmit = (formData) => {
    handleNextStep(formData, 2);
  };

  const validateRegisteredMembers = (data) => {
    let result = false;
    if (data.weeklySchedule) {
      data.weeklySchedule.forEach((item) => {
        if (item.familyMembers && item.familyMembers.length > 0) {
          result = true;
          return true;
        }
      });
    }
    return result;
  };

  const getWeeklyRegistrants = () => {
    if (data.weeklyProgram) {
      return validateRegisteredMembers(data);
    }
    return false;
  };

  React.useEffect(() => {
    if (data.durationStartDate) {
      setValue('durationStartDate', data.durationStartDate, { shouldValidate: true });
      setValue('durationEndDate', data.durationEndDate, { shouldValidate: true });
      setValue('seasons', data.seasons, { shouldValidate: true });
      setValue('typeOfSchedule', data.typeOfSchedule, { shouldValidate: true });
      if (data.typeOfSchedule === 'regular') {
        setValue('days', data.days, { shouldValidate: true });
        setValue('programStartTime', data.programStartTime, { shouldValidate: true });
        setValue('programEndTime', data.programEndTime, { shouldValidate: true });
      } else {
        setValue('variableSchedule', data.variableSchedule);
      }
    } else {
      setValue('durationStartDate', null);
      setValue('durationEndDate', null);
    }
  }, []);

  return (
    <FormProvider {...useFormMethods}>
      <form onSubmit={handleSubmit(handleFormSubmit)} className="addNewProgram-wrapper">
        <BackButton
          handleClick={() => {
            setStepBack(0);
          }}
          label="Back to Program Information"
        />
        <Grid container spacing={2} style={{ paddingTop: '15px' }}>
          <Grid item xs={12}>
            <h1 className="addNewProgram-titles">Schedule Information</h1>
          </Grid>
          {getWeeklyRegistrants() ? (
            <Grid item xs={12}>
              <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item className="addNewProgram-error-box">
                  <div className="addNewProgram-error-title">
                    <div>
                      <img src={CheckInDeclined} alt="cannot change" width={50} height={50} />
                    </div>
                    <div className="addNewProgram-error-text">Cannot change the schedule!</div>
                  </div>
                  <div className="addNewProgram-error-message">There are members already registered for the weekly program.</div>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <>
              <ProgramDuration {...useFormMethods} data={data} />
              <Seasons {...useFormMethods} data={data} />
              <SelectSchedule {...useFormMethods} data={data} />
            </>
          )}
        </Grid>

        {/* Action Buttons */}
        <hr className="tax_custom_hr" />
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
          <StepButtons location="/adminPortal/programs/available" handleSubmit={() => {}} type="submit" />
        </Grid>
        <Alert
          open={openAlert}
          title={alertContent.alertTitle}
          body={alertContent.alertBody}
          stay={alertContent.alertStay}
          leave={alertContent.alertClose}
          location={alertContent.alertLocation}
          close={() => setOpenAlert(false)}
        />
      </form>
    </FormProvider>
  );
}

export default Schedule;
