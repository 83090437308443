import React from 'react';
import { Grid } from '@material-ui/core';
import Fees from './fees';
import PaymentMethod from './paymentMethod';

function Payment(props) {

  return (
    <Grid container className="programRegistration-wrapper">
      <Grid item xs={12} className="Payment-title">
        Payment
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Fees
            {...props}
          />
          <PaymentMethod
            {...props}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Payment;
