import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import MenuIcon from '../../../../materialUi/menuIcon';
import '../../../../css/verifyMembership.css';

export const changeStatus = (membershipData) => {
  const membershipDate = new Date(membershipData.expirationDate);
  const todayDate = new Date();
  const endDate = new Date();
  endDate.setDate(endDate.getDate() + 30);

  if (membershipData.status === 'Unverified') {
    return 'Unverified';
  }
  if (membershipDate > todayDate && membershipDate < endDate) {
    return 'Expiring';
  }
  if (membershipDate < todayDate) {
    return 'Expired';
  }
  return 'Active';
};

export default class header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusBtn: '',
    };
  }

  componentDidUpdate(previousProps) {
    const { membershipData } = this.props;
    if (previousProps.membershipData !== membershipData) {
      this.setState({
        statusBtn: changeStatus(membershipData),
      });
    }
  }

  render() {
    const { memberName, handleBack, handleDropdownItems } = this.props;
    const { statusBtn } = this.state;

    const Active = [
      <div className="deactivated-dropdown-item" key="deactivated">
        Verify Address
      </div>,
      <div className="deactivated-dropdown-item" key="deactivated">
        Renew Membership
      </div>,
      <div className="active-dropdown-item" key="changeCard">
        Membership Card
      </div>,
      <div className="active-dropdown-item" key="changeMembershipType">
        Change Membership
      </div>,
      <div className="active-dropdown-item" key="receipts">
        Receipts
      </div>,
      <div className="active-dropdown-item-delete" key="delete">
        Delete Membership
      </div>,
    ];

    const Expired = [
      <div className="deactivated-dropdown-item" key="deactivated">
        Verify Address
      </div>,
      <div className="active-dropdown-item" key="renewMembership">
        Renew Membership
      </div>,
      <div className="active-dropdown-item" key="changeCard">
        Membership Card
      </div>,
      <div className="active-dropdown-item" key="changeMembershipType">
        Change Membership
      </div>,
      <div className="active-dropdown-item" key="receipts">
        Receipts
      </div>,
      <div className="active-dropdown-item-delete" key="delete">
        Delete Membership
      </div>,
    ];

    const Unverified = [
      <div className="active-dropdown-item" key="verifyAddress">
        Verify Address
      </div>,
      <div className="deactivated-dropdown-item" key="deactivated">
        Renew Membership
      </div>,
      <div className="deactivated-dropdown-item" key="deactivated">
        Membership Card
      </div>,
      <div className="active-dropdown-item" key="changeMembershipType">
        Change Membership
      </div>,
      <div className="deactivated-dropdown-item" key="deactivated">
        Receipts
      </div>,
      <div className="active-dropdown-item-delete" key="delete">
        Delete Membership
      </div>,
    ];

    let list;
    if (statusBtn === 'Active') {
      list = Active;
    } else if (statusBtn === 'Unverified') {
      list = Unverified;
    } else {
      list = Expired;
    }

    return (
      <>
        <Grid container className="font-family-default">
          <Grid item xs style={{ margin: '20px' }}>
            <Grid
              className="Back-to-Member-list"
              onClick={handleBack}
              onKeyPress={() => this.handleLogout()}
              role="button"
              tabIndex={0}
            >
              &lt; Back to Members List
            </Grid>
            <Grid className="verify-member-title">Membership</Grid>
          </Grid>
        </Grid>
        <Grid className="header-box">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid className="member-name text-capitalize">{memberName}</Grid>
            <Grid>
              <Grid container>
                <Grid item xs={6} style={{ marginTop: '16px' }}>
                  <span className={`verifyMembership-${statusBtn}`}>{statusBtn}</span>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: '12px' }}>
                  <MenuIcon
                    width={200}
                    list={list}
                    onClick={(value) => handleDropdownItems(value)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

header.propTypes = {
  membershipData: PropTypes.shape({
    expirationDate: PropTypes.string,
    status: PropTypes.string,
    membershipCategory: PropTypes.string,
  }).isRequired,
  memberName: PropTypes.string.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleDropdownItems: PropTypes.func.isRequired,
};
