import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { membershipAPI } from '../../../../API/API-membership';
import { FamilyAPI } from '../../../../API/API-familyMembers';
import { ProgramsWaitingListAPI } from '../../../../API/API-programWaitingList';
import { validateField } from '../../../../utils/fieldsValidation';
import { checkFieldsValidation, displayErrorMessages } from '../../../../utils/supportFunctions';
import successImage from '../../../../assets/icons/waitingSuccess.svg';
import Alert from '../../../../materialUi/Alerts';
import Submit from './components/submit';

class WaitingSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAlert: false,
      alertBody: '',
      alertLocation: '',
      alertTitle: '',
      alertStay: '',
      alertClose: '',
      disableBtn: false,
      radioSelection: 'email',
      familyMemberID: '',
      waitingPosition: 0,
      values: {
        phone: '',
        email: '',
      },
      messages: {
        phone: '',
        email: '',
      },
      errors: {
        phone: '',
        email: '',
      },
    };
  }

  componentDidMount() {
    this.getProgramWaitingLists();
    this.getUserInformation();
  }

  getUserInformation = () => {
    const { data, history } = this.props;
    membershipAPI.GET.getMembershipByID(data.membership.membershipId)
      .then((success) => {
        console.log(success.data);
        this.saveUserInformation(
          success.data.primaryMember.phone,
          success.data.membership.email,
          success.data.primaryMember._id,
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  saveUserInformation = (phone, email, id) => {
    const { values, errors } = this.state;
    values.phone = phone === null || phone === undefined || phone === '' ? '' : phone;
    values.email = email === null || email === undefined || email === '' ? '' : email;
    errors.phone = phone === null || phone === undefined || phone === '' ? '' : false;
    errors.email = email === null || email === undefined || email === '' ? '' : false;
    this.saveSessionValues(values);
    this.setState({ values, errors, familyMemberID: id });
  };

  saveSessionValues = (values) => {
    sessionStorage.setItem('phone', values.phone);
    sessionStorage.setItem('email', values.email);
  };

  getProgramWaitingLists = () => {
    const { data } = this.props;
    ProgramsWaitingListAPI.GET.getOneWaitingList(data.program.programID)
      .then((success) => {
        this.setState({ waitingPosition: success.data.length });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleInputs = (name, value, type, requirement = '') => {
    const { values, messages, errors } = this.state;
    if (validateField(value, type, requirement) === true) {
      values[name] = value;
      messages[name] = '';
      errors[name] = false;
    } else {
      values[name] = value;
      messages[name] = validateField(value, type, requirement);
      errors[name] = true;
    }
    this.setState({ values, messages, errors });
  };

  getSessionValues = () => ({
    email: sessionStorage.getItem('email'),
    phone: sessionStorage.getItem('phone'),
  });

  resetDataBaseOnTheRadioSelection = (selection) => {
    const { values, errors, messages } = this.state;
    if (selection === 'phone') {
      values.email = this.getSessionValues().email;
      messages.email = '';
      errors.email = this.getSessionValues().email === null
        || this.getSessionValues().email === undefined
        || this.getSessionValues().email === ''
        ? ''
        : false;
    } else {
      values.phone = this.getSessionValues().phone;
      messages.phone = '';
      errors.phone = this.getSessionValues().phone === null
        || this.getSessionValues().phone === undefined
        || this.getSessionValues().phone === ''
        ? ''
        : false;
    }
    this.setState({ values, messages, errors });
  };

  handleRadio = (value) => {
    this.resetDataBaseOnTheRadioSelection(value);
    this.setState({ radioSelection: value });
  };

  updatePhoneInformation = () => {
    const { values } = this.state;
    const { data } = this.props;
    FamilyAPI.PUT.updatePrimaryMemberInformation(data.membership.familyMembersInformation[0]._id, values.phone)
      .then((success) => {
        sessionStorage.setItem('phone', values.phone);
        this.setState({
          openAlert: true,
          alertLocation: '/portalMember',
          alertTitle: 'Success!',
          alertBody: 'Phone number has been submitted!',
          alertClose: 'hidden',
          alertStay: 'CLOSE',
          disableBtn: false,
        });
      })
      .catch((error) => {
        this.setState({
          openAlert: true,
          alertLocation: '/portalMember',
          alertTitle: 'Fail!',
          alertBody: 'An error has ocurred, please try again later.',
          alertClose: 'hidden',
          alertStay: 'CLOSE',
          disableBtn: false,
        });
      });
  };

  updateEmailInformation = () => {
    const { values } = this.state;
    const { data } = this.props;
    membershipAPI.PUT.editMembershipEmail(data.membership.membershipID, values.email)
      .then((success) => {
        sessionStorage.setItem('email', values.email);
        this.setState({
          openAlert: true,
          alertLocation: '/portalMember',
          alertTitle: 'Success!',
          alertBody: 'Email address has been submitted!',
          alertClose: 'hidden',
          alertStay: 'CLOSE',
          disableBtn: false,
        });
      })
      .catch((error) => {
        this.setState({
          openAlert: true,
          alertLocation: '/portalMember',
          alertTitle: 'Fail!',
          alertBody: 'An error has ocurred, please try again later.',
          alertClose: 'hidden',
          alertStay: 'CLOSE',
          disableBtn: false,
        });
      });
  };

  verifyInformationToUpdate = (selection) => {
    const { values, errors, messages } = this.state;
    if (selection === 'phone') {
      values.email = this.getSessionValues().email;
      messages.email = '';
      errors.email = false;
    } else {
      values.phone = this.getSessionValues().phone;
      messages.phone = '';
      errors.phone = false;
    }
    this.setState({ values, messages, errors });
  };

  handleSubmit = () => {
    const { radioSelection } = this.state;
    this.verifyInformationToUpdate(radioSelection);
    if (checkFieldsValidation(this.state)) {
      if (radioSelection === 'phone') {
        this.updatePhoneInformation();
      } else {
        this.updateEmailInformation();
      }
    } else {
      this.setState({
        errors: displayErrorMessages(this.state).errors,
        messages: displayErrorMessages(this.state).messages,
      });
    }
  };

  sendErrorMessage = () => {
    const { radioSelection, errors } = this.state;
    if (radioSelection === 'phone') {
      if (errors.phone !== false) {
        this.setState({
          openAlert: true,
          alertLocation: '/portalMember',
          alertTitle: 'Error!',
          alertBody:
            'Please provide us with an email address or phone number to receive further notifications',
          alertClose: 'hidden',
          alertStay: 'CLOSE',
          disableBtn: false,
        });
        return true;
      }
    } else if (errors.email !== false) {
      this.setState({
        openAlert: true,
        alertLocation: '/portalMember',
        alertTitle: 'Error!',
        alertBody:
            'Please provide us with an email address or phone number to receive further notifications',
        alertClose: 'hidden',
        alertStay: 'CLOSE',
        disableBtn: false,
      });
      return true;
    }
    return false;
  };

  handleDone = () => {
    const { radioSelection } = this.state;
    const { history, userData } = this.props;
    const { userType } = userData.userData;
    this.verifyInformationToUpdate(radioSelection);
    if (!this.sendErrorMessage()) {
      // TODO: Needs to be removed after we merge the adminPortal with scccPortal
      const typeOfPortal = () => {
        if (userType.toLowerCase() !== 'member' && userType.toLowerCase() !== 'public') {
          return 'adminPortal';
        }
        return 'scccPortal';
      };
      history.push(`/${typeOfPortal()}/programs/available/`);
      history.push('/adminPortal/programs/available');
      sessionStorage.clear();
    }
  };

  render() {
    const {
      waitingPosition,
      openAlert,
      alertLocation,
      alertTitle,
      alertBody,
      alertClose,
      alertStay,
    } = this.state;
    return (
      <Grid item xs className="font-family-default">
        <Grid container className="waiting-wrapper">
          <Grid item xs={12} md={3} lg={2}>
            <img src={successImage} alt="Success" />
          </Grid>
          <Grid item xs>
            <Grid container>
              <Grid item xs={12}>
                <h1 className="waiting-title">
You are #
                  {waitingPosition}
                  {' '}
on a waiting list !
                </h1>
                <h3 className="waiting-text-body1">
                  You are on a waiting list for the program as the maximum number of registrants has
                  been reached. SCCC team will contact you if the spot becomes available.
                </h3>
              </Grid>
              <Grid item xs={12}>
                <h2 className="waiting-subTitle">Receive a Notification</h2>
                <h3 className="waiting-text-body2">
                  Please provide us with an email address or phone number to receive further
                  notifications.
                </h3>
              </Grid>
              <Submit
                data={this.state}
                handleRadio={this.handleRadio}
                handleInputs={this.handleInputs}
                handleSubmit={this.handleSubmit}
                handleDone={this.handleDone}
              />
            </Grid>
            <Alert
              open={openAlert}
              close={() => this.setState({ openAlert: false })}
              location={alertLocation}
              title={alertTitle}
              body={alertBody}
              stay={alertStay}
              leave={alertClose}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

WaitingSuccess.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};


export default withRouter(WaitingSuccess);