import React, { useState, useEffect } from "react";
import { Grid, Box } from "@material-ui/core";
import Alert from "../../../../../materialUi/Alerts";
import SearchMemberTextField from "./searchMemberTextField";
import MemberFoundBox from "./memberFoundBox";
import { ProgramAttendanceWithoutRegistration } from "../../../../../API/API-createattendeeswithoutregistration";
import Btn from "../../../../../materialUi/btn";
import CancelAlert from "../../../../../materialUi/cancelAlert";
import { useHistory } from "react-router-dom";

function SearchMembers(props) {
  const {
    register,
    watch,
    formState: { errors },
    getValues,
    setValue,
    data,
    userData,
    programData,
    addedMembers,
    isMemberShipAdded,
    addedFamillyMembers,
    setIsMemberShipAdded,
    sendMembership,
  } = props;

  const { userType, membership } = userData.userData;
  const history = useHistory();

  const [openAlert, setOpenAlert] = useState(false);
  const [openCancelAlert, setOpenCancelAlert] = useState();
  const [memberFound, setMemberFound] = useState(false);
  const [pageId, setPageId] = useState(0);
  const [localMembership, setLocalMembership] = useState(null);
  const [alertContent, setAlertContent] = useState({
    alertLocation: "",
    alertTitle: "Error",
    alertBody: "",
    alertClose: "hidden",
    alertStay: "CLOSE",
  });

  const alertMessage = (message) => {
    setAlertContent({
      alertLocation: "",
      alertTitle: "Error",
      alertBody: message,
      alertClose: "hidden",
      alertStay: "CLOSE",
    });
    setOpenAlert(true);
  };

  const membershipTypeValidation = (member) => {
    const USER_MEMBERSHIP_CATEGORY =
      getValues("membership") && getValues("membership").membershipCategory;
    if (programData.membershipType !== "All") {
      if (programData.membershipType === "Child and Youth") {
        if (
          USER_MEMBERSHIP_CATEGORY !== "Child" &&
          USER_MEMBERSHIP_CATEGORY !== "Youth"
        ) {
          alertMessage(
            "Only Child (6-12) and Youth (13-17) membership categories can apply for this program."
          );
          return false;
        }
        if (programData.membershipType !== USER_MEMBERSHIP_CATEGORY) {
          alertMessage(
            `Only membership category ${programData.membershipType} can apply for this program.`
          );
          return false;
        }
      }
    }
    return true;
  };

  const handleMemberFound = (member) => {
    if (!member) return false;
    if (membershipTypeValidation(member) && memberStatusValidation(member)) {
      sendMembership(member);
      // setMemberFound(true);
      setLocalMembership(member);
      setIsMemberShipAdded(true);
      setValue("membership", member);
    }
  };

  const handleSubmit = () => {
    const data = addedFamillyMembers;
    const url = window.location.href;
    const parts = url.split("/");

    const getAttendanceId = JSON.parse(
      sessionStorage.getItem("exisitingAttendanceID")
    );
    const getAttendanceDate = JSON.parse(
      sessionStorage.getItem("exisitingAttendanceDate")
    );
    const id = getAttendanceId
      ? parts[parts.length - 2]
      : parts[parts.length - 1];
    const finalNewObject = {
      date: new Date(),
      programID: id,
      familyMembers: data,
    };
    const finalExisitingObject = {
      date: getAttendanceId ? getAttendanceDate : new Date(),
      programID: id,
      _id: getAttendanceId,
      familyMembers: data,
    };
    if (getAttendanceId && getAttendanceId !== undefined) {
      ProgramAttendanceWithoutRegistration.PUT.updateProgramAttendeeWithoutRegistartion(
        finalExisitingObject
      )
        .then((success) => {
          if (success)
          history.push(`/adminPortal/programs/createAttendance/${id}`);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      ProgramAttendanceWithoutRegistration.POST.createProgramAttendanceByAttendanceID(
        finalNewObject
      )
        .then((success) => {
          console.log(success);
          if (success)
          history.push(`/adminPortal/programs/createAttendance/${id}`);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const deleteSelectedMember = () => {
    if (membership) return false;
    if (getValues("familyMembers")) {
      setLocalMembership(null);
      setIsMemberShipAdded(!isMemberShipAdded);
      setValue("familyMembers", null);
    }
    else{
      setIsMemberShipAdded(!isMemberShipAdded);
    }
    setValue("membership", null);
  };

  watch("membership");

  watch("isMemberShipAdded");
  const alertMessageLeave = (message) => {
    setAlertContent({
      alertLocation: "/scccPortal",
      alertTitle: "Error",
      alertBody: message,
      alertClose: "CLOSE",
      alertStay: "hidden",
    });
    setOpenAlert(true);
  };

  const isAdminUser = () => {
    if (userData.userData.userType.toLowerCase() !== "member") return true;
    return false;
  };

  const memberStatusValidation = (membership) => {
    const USER_STATUS = membership.status.toLowerCase();
    const EXPIRATION_DATE = new Date(membership.expirationDate);

    if (USER_STATUS === "unverified") {
      if (isAdminUser()) {
        alertMessage(
          "Membership is status unverified, please verify the membership first."
        );
      } else {
        alertMessageLeave(
          "Please visit reception to verify your membership first."
        );
      }
      return false;
    }

    if (EXPIRATION_DATE <= new Date()) {
      if (isAdminUser(userData)) {
        alertMessage(
          "Membership is expired, please renew the membership first."
        );
      } else {
        alertMessageLeave(
          "Please visit reception to renew your membership first."
        );
      }
      return false;
    }

    return true;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const inputElement =  document.querySelector('input[placeholder="Search Membership"]');
      if (inputElement) {
        inputElement.focus();
        clearInterval(interval);
      }
    }, 100);
    return () => clearInterval(interval);
  }, [isMemberShipAdded]);

  React.useEffect(() => {
    const url = window.location.href;
    const parts = url.split("/");
    const id = parts[parts.length - 2];
    setPageId(id);
  }, []);

  React.useEffect(() => {
    const tableData = JSON.parse(sessionStorage.getItem("familyMembersInfo"));
    const isEditData = JSON.parse(
      sessionStorage.getItem("exisitingAttendanceID")
    );
    if (tableData || isEditData) {
      setMemberFound(true);
    }
    if (userData && userData.userData.membership) {
      memberStatusValidation(userData.userData.membership.membership);
    }
    if (!data.membership && !isMemberShipAdded) {
      register("membership", { required: "Membership is required!" });
    } else if (isMemberShipAdded) {
      setLocalMembership(null);
    } else {
      setLocalMembership(data.membership);
      setValue("membership", data.membership);
    }
    watch("membership");
  }, [userData.userData.membership, isMemberShipAdded]);

  useEffect(() => {
    if (isMemberShipAdded) {
      setLocalMembership(null);
    }
  }, [isMemberShipAdded]);


  return (
    <Grid container>
      <Grid item xs={12}>
        <h1 className="programRegistration-header">Add Attendees Form</h1>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Box style={{ marginRight: "5px" }}>
            <Btn
              label="Cancel"
              bgColor="white"
              color="black"
              bgColorHover="#eceff1"
              colorHover="black"
              borderColor="white"
              variant="outlined"
              width={128}
              onClick={() => setOpenCancelAlert(true)}
              type="button"
            />
            <CancelAlert
              open={openCancelAlert}
              close={() => setOpenCancelAlert(false)}
              location={`/adminPortal/programs/createAttendance/${pageId}`}
              message="Are you sure you want to leave this page before completing adding attendees ? Data you have entered may not be saved."
            />
          </Box>
          <Btn
            label={"Save"}
            onClick={handleSubmit}
            bgColor="#e76f50"
            color="white"
            colorHover="white"
            bgColorHover="#ff8c68"
            width={128}
            type="button"
            disable={!memberFound || addedMembers.length === 0}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <h2 className="programRegistration-titles">Membership</h2>
        {localMembership === null && !isMemberShipAdded ? (
          <SearchMemberTextField
            typeOfSearch="member"
            label="Search Membership"
            handleMemberFound={handleMemberFound}
            helperText={errors.membership ? errors.membership.message : ""}
            error={!!errors.membership}
          />
        ) : (
          <MemberFoundBox
            selectedMember={getValues("membership")}
            deleteSelectedMember={deleteSelectedMember}
          />
        )}
      </Grid>
      <Alert
        open={openAlert}
        title={alertContent.alertTitle}
        body={alertContent.alertBody}
        stay={alertContent.alertStay}
        leave={alertContent.alertClose}
        location={alertContent.alertLocation}
        close={() => setOpenAlert(false)}
      />
    </Grid>
  );
}

export default SearchMembers;
