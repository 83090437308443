import React from "react";
import { Grid, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import Btn from "../../../../../materialUi/btn";

export default function StepsButtons({ handleSubmit, type, disable }) {
  return (
    <Grid item xs={12} className="stepsButtons">
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Btn
          label={"Add"}
          onClick={handleSubmit}
          bgColor="#e76f50"
          color="white"
          colorHover="white"
          bgColorHover="#ff8c68"
          width={128}
          type={type}
          disable={disable}
        />
      </Grid>
    </Grid>
  );
}

StepsButtons.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired,
  type: PropTypes.string,
  disable: PropTypes.bool,
};

StepsButtons.defaultProps = {
  type: "button",
  disable: false,
};
