import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import BackButtons from '../../materialUi/backButton';
import logo from '../../assets/SCCC_LOGO_SMALL.png';
import Successful from '../../assets/icons/fireworks@3x.png';
import Button from '../../materialUi/btn';

function PreRegistrationSuccess({ history }) {
  return (
    <Grid container className="logins-wrapper">
      <BackButtons label="Back to onboarding" handleClick={() => history.push('/')} />
      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Box>
            <img src={logo} alt="Sccc Logo" className="login-logo" />
          </Box>
        </Grid>
      </Grid>
      <div className="pre-registration-body">
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={6}>
          <Grid item>
            <img src={Successful} alt="logo" width="104px" height="120px" />
          </Grid>
          <Grid item className="pre-registration-text-body">
            <h1 className="pre-registration-title">Successful!</h1>
            <h2 className="pre-registration-text">
              You have been successfully added to the SCCC community. To gain access to the rest of the portals wonderful features, you must complete your membership registration in the portal.
            </h2>
            <div>
              <Button label="LOGIN" type="button" onClick={() => history.push('/memberLogin')} bgColor="#e76f50" color="white" bgColorHover="#ff8c68" colorHover="white" width={120} height={47} />
            </div>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}

PreRegistrationSuccess.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default PreRegistrationSuccess;
