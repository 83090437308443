import React from 'react';
import { Grid, Box, Checkbox } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import TextField from '../../../../../materialUi/textField';
import { userAPI } from '../../../../../API/API-user';

function Email(props) {
  const { control, formState, setValue, getValues, userData } = props;
  const { errors } = formState;
  const userType = userData ? userData.userData.userType : false;

  React.useEffect(() => {
    setValue('shareEmail', true);
    if (userType && userType.toLowerCase() === 'provisory member') {
      setValue('email', userData.userData.email);
    }
  }, []);

  const handleEmail = async (value) => {
    if (!value) return true;
    if (userType && userType.toLowerCase() === 'provisory member') return true;
    let result = true;
    await userAPI.GET.checkUserEmail(value)
      .then((response) => {
        if (response.data) {
          result = 'Email already in use';
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  };

  return (
    <Grid container>
      <Grid item xs={12} style={{ paddingTop: '10px' }} className="registration-form-sub-title">
        Email
      </Grid>
      <Grid container>
        <Grid item xs={12} md={6}>
          <div className="registration-email-note">
            <span className="registration-email-note-red">*Note: </span> To receive your registration confirmation and receipt, an email will be needed.
          </div>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              maxLength: { value: 100, message: 'Max length 100' },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Invalid Email Format',
              },
              validate: (value) => handleEmail(value),
            }}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <TextField
                label="Email Address"
                id="Email"
                helperText={errors.email ? errors.email.message : 'Optional'}
                error={!!errors.email}
                readOnly={userType === 'provisory member'}
                inputRef={ref}
                onBlur={onBlur}
                onChange={onChange}
                value={value.toLowerCase()}
                name={name}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" className="registration-email-news-wrapper">
            <Box>
              <Controller
                name="shareEmail"
                control={control}
                render={({ field }) => <Checkbox inputProps={{ 'aria-label': 'primary checkbox' }} defaultChecked={getValues('shareEmail') ? getValues('shareEmail') === 'true' : true} {...field} />}
              />
            </Box>
            <Box className="registration-email-news">Receive latest news and information from Scadding Court Community Centre. You can unsubscribe anytime.</Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Email;
