import React from 'react';
import PropTypes from 'prop-types';
import TopContent from '../topContent';
import Consent from './consent';
import Review from './review';

function StepFour({ data, handleNextStep, cancelRoute, handlePrevious }) {
  const [step, setStep] = React.useState(0);
  const stepsList = ['Upload Documents', 'Consent', 'Review'];

  const handleBack = () => {
    if (step === 0) {
      handleNextStep(data, 2);
    } else {
      setStep(step - 1);
    }
  };

  const handleStepFourSteps = (formData, step) => {
    if (step === 1) {
      handleNextStep(formData, 3);
      setStep(step);
    } else {
      handleNextStep(formData, step);
    }
  };

  return (
    <>
      <TopContent
        step={step}
        text={stepsList[step]}
        handleStep={handleBack}
      />
      {step === 0 && <Consent handleNextStep={handleStepFourSteps} cancelRoute={cancelRoute} handlePrevious={handlePrevious} />}
      {step === 1 && <Review handleNextStep={handleStepFourSteps} data={data} />}
    </>
  );
}

StepFour.propTypes = {
  data: PropTypes.shape(
    {
      membershipCategory: PropTypes.string,
    },
  ).isRequired,
  handleNextStep: PropTypes.func.isRequired,
  cancelRoute: PropTypes.string.isRequired,
};

export default StepFour;
