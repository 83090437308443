import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Covid19API } from '../../../../API/API-covid19';
import Header from './components/header';
import UserHistoryTable from './components/userHistoryTable';
import '../../../../css/reports.css';

export default class UserHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userCheckInData: [],
      userInfo: {},
    };
  }

  componentDidMount() {
    const userInformation = JSON.parse(sessionStorage.getItem('userInfo'));
    Covid19API.GET.getSpecificUserCheckIn(userInformation.id).then((success) => {
      this.setState({ userCheckInData: success.data, userInfo: userInformation });
    }).catch((error) => {
      console.log(error);
    });
  }

  render() {
    const { history, match } = this.props;
    const { userCheckInData, userInfo } = this.state;
    return (
      <Grid item xs className="font-family-default">
        <Grid container>
          <Grid item xs={12} className="pl-5 pr-5">
            <Header
              history={history}
              userInfo={userInfo}
              backRoute={match.params.backRoute}
            />
            <UserHistoryTable
              data={userCheckInData}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

UserHistory.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      userID: PropTypes.string,
      backRoute: PropTypes.string,
    }),
  }).isRequired,
};
