import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(() => ({
  inputRoot: {
    borderWidth: '1px',
    borderColor: 'darkgrey !important',
    height: 41,
    top: '0px',
    fontSize: '14px',
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'darkgrey !important',
  },
  labelRoot: {
    top: '-5px',
    fontSize: '14px',
    '&$labelFocused': {
      color: 'darkgrey',
      top: '0px',
    },
  },
  labelFocused: {},
  inputFocused: {},
}));


export default function OutlinedTextFields(props) {
  const classes = useStyles();


  return (


    <TextField
      InputProps={{
        classes: {
          root: classes.inputRoot,
          notchedOutline: classes.notchedOutline,
        },
      }}
      InputLabelProps={{
        classes: {
          root: classes.labelRoot,
          focused: classes.labelFocused,
        },
      }}
      id="search"
      label={props.label}
      className={classes.textField}
      type="text"
      name="search"
      margin="normal"
      variant="outlined"
      style={{
        width: (props.width) ? props.width : 293, height: 41, marginTop: '0%', marginLeft: '0%',
      }}
      onChange={props.search}
    />
  );
}
