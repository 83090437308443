import React from 'react';
import { Grid, Box } from '@material-ui/core';
import Stepper from '../../../../materialUi/stepper';
import Fees from './components/fees';
import PaymentMethod from './components/paymentMethod';
import { membershipAPI } from '../../../../API/API-membership';
import { DATEPLUS } from '../../../../utils/dateController';
import { validateField } from '../../../../utils/fieldsValidation';
import { FamilyAPI } from '../../../../API/API-familyMembers';
import { CardAPI } from '../../../../API/API-Card';
import Alert from '../../../../materialUi/Alerts';
import '../../../../css/payment.css';

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      membershipID: this.props.match.params.membershipID,
      membership: '',
      familyMembers: [{}],
      openAlert: false,
      alertLocation: '',
      alertTitle: '',
      alertBody: '',
      alertClose: '',
      alertStay: '',
      loading: 'hidden',
      disableBtn: false,
      token: '',

      values: {
        fee: 0,
        paymentMethod: 'Select One',
        membershipCard0: '',
        cardName: '',
      },
      errors: {
        paymentMethod: '',
        fee: false,
        membershipCard0: '',
        cardName: '',
        cardNumber: '',
        cvv: '',
        date: '',
      },
      messages: {
        paymentMethod: '',
        fee: '',
        membershipCard0: '',
        cardName: '',
      },
    };
  }

  async componentDidMount() {
    // <--- get all family members related with the membership --->
    const verifyMembershipFromSession = JSON.parse(sessionStorage.getItem(`verifyMembership_${this.props.match.params.membershipID}`));

    if (verifyMembershipFromSession) {
      const { values, errors } = this.state;

      values.fee = verifyMembershipFromSession.membership.fee;
      if (verifyMembershipFromSession.membership.paymentMethod) {
        this.handleInputs('paymentMethod', verifyMembershipFromSession.membership.paymentMethod, 'dropdown');
      }

      // if is not validate go back
      if (!verifyMembershipFromSession.verifyAddressValidation) this.props.history.push('/adminPortal/membership');
      if (JSON.parse(sessionStorage.getItem('paymentInformation'))) this.props.history.push('/adminPortal/membership/verifyAddress/success');

      // if the user has already added a membership card number
      if (verifyMembershipFromSession.familyMembers) {
        const {
          messages,
        } = this.state;
        for (let i = 0; i < verifyMembershipFromSession.membership.familyMembers.length; i++) {
          const membershipCardName = `membershipCard${i}`;
          values[membershipCardName] = '';
          messages[membershipCardName] = '';
          errors[membershipCardName] = '';
          if (verifyMembershipFromSession.familyMembers[i].membershipCard) {
            this.handleInputs(membershipCardName, verifyMembershipFromSession.familyMembers[i].membershipCard, 'name', 'membershipCardName', i);
          }
        }
        this.setState({
          familyMembers: verifyMembershipFromSession.familyMembers,
          values,
          messages,
          errors,
        });

      // else get it from DB and create state errors,values and messages for them
      } else {
        const {
          membershipID,
          messages,
        } = this.state;
        await FamilyAPI.GET.getByMembershipID(membershipID).then((success) => {
          for (let i = 0; i < success.data.length; i++) {
            const updateMembershipCardName = `membershipCard${i}`;
            values[updateMembershipCardName] = '';
            messages[updateMembershipCardName] = '';
            errors[updateMembershipCardName] = '';
          }
          this.setState({
            values, messages, errors, familyMembers: success.data,
          });
          verifyMembershipFromSession.familyMembers = success.data;
          sessionStorage.setItem(`verifyMembership_${this.props.match.params.membershipID}`, JSON.stringify(verifyMembershipFromSession));
        }).catch((error) => {
          console.log(error);
        });
      }
      this.setState({
        membership: verifyMembershipFromSession,
        values,
        errors,
      });
    } else {
      this.setState({
        openAlert: true,
        alertLocation: '/adminPortal/membership',
        alertTitle: 'User not found!',
        alertBody: 'We could not find the membership. Please try again later!',
        alertClose: 'CLOSE',
      });
    }
  }

  handleInputs=(name, value, type, nameToDisplay = '', index = '') => {
    const {
      values,
      messages,
      errors,
      familyMembers,
    } = this.state;
    const updateFamilyMembers = familyMembers;
    const verifyMembershipFromSession = JSON.parse(sessionStorage.getItem(`verifyMembership_${this.props.match.params.membershipID}`));
    const membershipCardName = `membershipCard${index}`;

    if (validateField(value, type, nameToDisplay) === true) {
      if (index !== '') {
        updateFamilyMembers[index].membershipCard = value;
        values[membershipCardName] = value;
        messages[membershipCardName] = '';
        errors[membershipCardName] = false;
        // save familyMembers on session
        verifyMembershipFromSession.familyMembers[index].membershipCard = value;
        sessionStorage.setItem(`verifyMembership_${this.props.match.params.membershipID}`, JSON.stringify(verifyMembershipFromSession));
      } else {
        values[name] = value;
        messages[name] = '';
        errors[name] = false;
        // save data on session
        verifyMembershipFromSession.membership[name] = value;
        sessionStorage.setItem(`verifyMembership_${this.props.match.params.membershipID}`, JSON.stringify(verifyMembershipFromSession));
      }

      this.setState({
        values, messages, errors, familyMembers: updateFamilyMembers,
      });
    } else {
      if (index !== '') {
        updateFamilyMembers[index].membershipCard = value;
        values[membershipCardName] = value;
        messages[membershipCardName] = `${nameToDisplay} cannot be empty`;
        errors[membershipCardName] = true;
        // save familyMembers on session
        verifyMembershipFromSession.familyMembers[index].membershipCard = value;
        sessionStorage.setItem(`verifyMembership_${this.props.match.params.membershipID}`, JSON.stringify(verifyMembershipFromSession));
      } else {
        values[name] = value;
        messages[name] = validateField(value, type, nameToDisplay);
        errors[name] = true;
        // save data on session
        verifyMembershipFromSession.membership[name] = value;
        sessionStorage.setItem(`verifyMembership_${this.props.match.params.membershipID}`, JSON.stringify(verifyMembershipFromSession));
      }
      this.setState({ values, messages, errors });
    }
  }

  handleCard = (name, value, token) => {
    const { errors } = this.state;
    if (value === true) {
      errors[name] = false;
    }
    this.setState({ errors, token });
  }

  handleSaveOnDB=() => {
    let fieldsvalidationResult = 0;
    const {
      values,
      errors,
      membershipID,
      token,
      familyMembers,
      messages,
    } = this.state;
    // eslint-disable-next-line
    for(let key in errors ){
      if (errors[key] === true || errors[key] === '') {
        if ((key !== 'cardName') && (key !== 'cardNumber') && (key !== 'cvv') && (key !== 'date')) {
            fieldsvalidationResult++;
          }
      }
    }
    if (fieldsvalidationResult === 0) {
             // save membership on DB
             membershipAPI.PUT.verifyAddress(
                membershipID,
                membershipToSaveOnDB(this.state),
                familyMembers,
              ).then((success) => {
                console.log(success);
              }).catch((error) => {
                console.log(error);
                this.setState({
                  openAlert: true,
                  alertLocation: '/adminPortal/membership',
                  alertTitle: 'ERROR!',
                  alertBody: 'There is an error in the server. Please try again later!',
                  alertClose: 'CLOSE',
                  alertStay: 'hidden',
                  loading: 'hidden',
                });
              });
      
              sessionStorage.setItem('paymentInformation', JSON.stringify(membershipToSaveOnDB(this.state).receipt));
              this.props.history.push('/adminPortal/membership/verifyAddress/success');
    } else {
      // eslint-disable-next-line
    for(let key in errors ){
        if (errors[key] !== false) {
          errors[key] = true;
          messages[key] = 'Required Field!';
        }
      }
      this.setState({ errors, messages });
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {
      values,
      messages,
      errors,
      familyMembers,
      displayMembershipCard,
      disableBtn,
      loading,
      openAlert,
      alertLocation,
      alertTitle,
      alertBody,
      alertStay,
      alertClose,
      membershipID,
    } = this.state;
    return (
      <Grid item xs={12} className="font-family-default">
        <Stepper labels={['Verify Address', 'Payment & Membership Card']} step={1} />
        <Grid container className="payment-spacing">
          <Box className="Back-to-verify-address">
            <Box
              onClick={() => this.props.history.push(`/adminPortal/membership/verifyAddress/${this.props.match.params.membershipID}`)}
            >
              &lt; Back to Verify Address
            </Box>
          </Box>
          <Grid item xs={12} className="Payment-title">Payment</Grid>
          <Fees
            values={values}
            errors={errors}
            messages={messages}
            handleInputs={this.handleInputs}
            handleMembershipCard={this.handleMembershipCard}
            familyMembers={familyMembers}
            membershipID={membershipID}
          />
          <PaymentMethod
            displayMembershipCard={displayMembershipCard}
            values={values}
            errors={errors}
            handleInputs={this.handleInputs}
            messages={messages}
            handleSaveOnDB={this.handleSaveOnDB}
            handleCard={this.handleCard}
            disableBtn={disableBtn}
            loading={loading}
            membershipID={membershipID}
          />
        </Grid>
        <Alert
          open={openAlert}
          close={() => this.setState({ openAlert: false })}
          location={alertLocation}
          title={alertTitle}
          body={alertBody}
          stay={alertStay}
          leave={alertClose}
        />
      </Grid>
    );
  }
}


// support functions

const membershipToSaveOnDB = function (state) {
  const referenceNumber = Math.floor(100000 + Math.random() * 90000000);

  const membershipToSaveOnDB = {
    membershipID: state.membershipID,
    status: 'Active',
    expirationDate: DATEPLUS(),
    membershipType: state.membership.membershipType,
    membershipCategory: state.membership.membershipCategory,
    fee: state.values.fee,
    membershipCard: state.values.membershipCard0,
    receipt: {
      membershipID: state.membershipID,
      fee: state.values.fee,
      paymentDate: new Date(),
      paymentMethod: state.values.paymentMethod,
      status: 'Approved',
      category: 'Membership Registration',
      cardType: state.values.paymentMethod,
      referenceNumber,
      name: `${JSON.parse(sessionStorage.getItem(`verifyMembership_${state.membershipID}`)).primaryMember.firstName} ${JSON.parse(sessionStorage.getItem(`verifyMembership_${state.membershipID}`)).primaryMember.lastName}`,
      address: JSON.parse(sessionStorage.getItem(`verifyMembership_${state.membershipID}`)).membership.address,
    },
  };
  return membershipToSaveOnDB;
};

const paymentToSaveOnDB = function (state) {
  const referenceNumber = Math.floor(100000 + Math.random() * 90000000);
  const paymentToSaveOnDB = {
    membershipID: state.membershipID,
    fee: state.values.fee,
    paymentDate: new Date(),
    paymentMethod: state.values.paymentMethod,
    status: 'Approved',
    category: 'Membership Registration',
    cardType: state.values.paymentMethod,
    referenceNumber,
    name: `${JSON.parse(sessionStorage.getItem(`verifyMembership_${state.membershipID}`)).primaryMember.firstName} ${JSON.parse(sessionStorage.getItem(`verifyMembership_${state.membershipID}`)).primaryMember.lastName}`,
    address: state.membership.address,
  };
  return paymentToSaveOnDB;
};

export default Payment;
