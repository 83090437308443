import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@material-ui/core';
import { membershipAPI } from '../../../API/API-membership';
import { FamilyAPI } from '../../../API/API-familyMembers';
import PersonalInfo from './components/personalInfo';
import EmergencyContact from './components/emergencyContact';
import MembershipInfo from './components/membershipInfo';
import OtherInfo from './components/otherInfo';
import Btn from '../../../materialUi/btn';
import Alert from '../../../materialUi/Alerts';
import EditFamilyMember from './components/editFamilyMember';
import Header from './components/header';
import { validateField } from '../../../utils/fieldsValidation';
import RegisteredPrograms from './components/registeredPrograms';
import EditPassword from './components/editPassword';
import '../../../css/verifyMembership.css';

class VerifyMembership extends Component {
  constructor(props) {
    super(props);
    const { userData } = this.props;
    this.state = {
      membershipID: userData.userData.membershipID,
      membershipData: {},
      familyMembers: [],
      openAlert: false,
      editPasswordAlert: false,
      alertLocation: '',
      alertTitle: '',
      alertBody: '',
      alertStay: '',
      alertClose: '',
      dropdown: '',
      familyMembersToSave: [],
      editFamilyMember: false,
      changesUpdatedMessage: '',
      memberNumber: 0,
      primaryMemberID: '',
      hiddePrograms: '',

      errors: {
        firstName: false,
        lastName: false,
        email: false,
        gender: false,
        phone: false,
        dateOfBirth: false,
        address: false,
        apt: false,
        city: false,
        postalCode: false,
        emergencyName: false,
        emergencyRelationship: false,
        emergencyHomePhone: false,
        emergencyWorkPhone: false,
      },
      messages: {
        firstName: '',
        lastName: '',
        email: '',
        gender: '',
        relationship: '',
        dateOfBirth: '',
        phone: '',
        address: '',
        city: '',
        postalCode: '',
        apt: '',
        emergencyName: '',
        emergencyRelationship: '',
        emergencyHomePhone: '',
        emergencyWorkPhone: '',
      },
      values: {
        address: '',
        city: '',
        postalCode: '',
        membershipType: '',
        apt: '',

        firstName: '',
        lastName: '',
        relationship: '',
        email: '',
        phone: '',
        gender: '',
        age: '',
        dateOfBirth: '',
        school: '',
        emergencyName: '',
        emergencyRelationship: '',
        emergencyHomePhone: '',
        emergencyWorkPhone: '',
        learned: '',
        language: '',
      },
    };
  }

  async componentDidMount() {
    const { userData, history } = this.props;
    if (userData.userData.userType === 'provisory member') {
      await history.push('/scccPortal/taxes/lock');
    } else {
      const { membershipID } = this.state;
      // primary member ID to compare when fetch the family members
      let primaryMemberID;
      await membershipAPI.GET.getMembershipByID(membershipID)
        .then((success) => {
          this.setState({
            values: updateStateValues(this.state, success.data),
            membershipData: success.data.membership,
            primaryMemberID: success.data.primaryMember._id,
          });
          primaryMemberID = success.data.primaryMember._id;
        })
        .catch(() => {
          this.setState({
            openAlert: true,
            alertLocation: '/scccPortal/membership',
            alertTitle: 'User not found!',
            alertBody: 'We could not find the membership. Please try again later!',
            alertClose: 'CLOSE',
          });
        });

      FamilyAPI.GET.getByMembershipID(membershipID)
        .then((success) => {
          const familyMembersToAdd = [];
          // get all family members except the first one that is the primaryMember
          for (let i = 0; i < success.data.length; i++) {
            // display all family memmbers except the primary member
            if (primaryMemberID !== success.data[i]._id) {
              familyMembersToAdd.push(success.data[i]);
            }
          }
          this.setState({
            familyMembers: familyMembersToAdd,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  handleDropdownItems = (value) => {
    const { membershipID } = this.state;
    if (value.key === 'memberProgramsList') return this.props.history.push(`/scccPortal/programs/${value.key}`);
    if (value.key === 'deactivated') return '';
    if (value.key === 'editPassword') return this.setState({ editPasswordAlert: true });
    return this.props.history.push(`/scccPortal/membership/${value.key}/${membershipID}`);
  };

  // <--- input handlers --->
  handleInputs = (name, value, type, requirement = '') => {
    const { values, messages, errors } = this.state;
    if (validateField(value, type, requirement) === true) {
      values[name] = value;
      messages[name] = '';
      errors[name] = false;
      this.setState({ values, messages, errors });
    } else {
      values[name] = value;
      messages[name] = validateField(value, type, requirement);
      errors[name] = true;
      this.setState({ values, messages, errors });
    }
  };

  // <---  handle family member selection open alert --->
  handleFamilyMemberClick = (member) => {
    this.setState({
      editFamilyMember: true,
      memberNumber: member,
    });
  };

  // <--- handle Save btn --->
  // check if all required fields are set to false
  handleSubmit = () => {
    let fieldsvalidationResult = 0;
    const { errors } = this.state;
    // eslint-disable-next-line
    for (let key in errors) {
      if (errors[key] === true || errors[key] === '') {
        fieldsvalidationResult++;
      }
    }
    if (fieldsvalidationResult === 0) {
      membershipAPI.PUT.editOneMembership(this.state.membershipID, SaveMembershipOnDB(this.state.values, this.state.primaryMemberID))
        .then((success) => {
          console.log(success.data);
        })
        .catch((error) => {
          console.log(error);
        });
      // send a message alert
      this.setState({
        openAlert: true,
        alertLocation:  this.props.history.location.pathname,
        alertTitle: 'Membership Updated!',
        alertBody: 'The Membership was successfully updated!',
        alertClose: 'CLOSE',
        alertStay: 'hidden',
      });
    } else {
      const updateErrors = this.state.errors;
      const updateMessages = this.state.messages;
      // eslint-disable-next-line
      for (let key in updateErrors) {
        if (updateErrors[key] !== false) {
          updateErrors[key] = true;
          updateMessages[key] = 'Required Field!';
        }
      }
      // set errors to true and display all messages
      this.setState({ errors: updateErrors, messages: updateMessages });
      window.scrollTo(0, 0);
    }
  };

  handleProramSelection = (programID) => {
    const { membershipID } = this.state;
    window.location = `/scccPortal/programs/memberProgramAttendance/${membershipID}/${programID}`;
  };

  handleHiddePrograms = (value) => {
    this.setState({ hiddePrograms: value });
  };

  render() {
    const {
      membershipData,
      values,
      errors,
      messages,
      familyMembers,
      openAlert,
      alertLocation,
      alertTitle,
      alertBody,
      alertStay,
      alertClose,
      editFamilyMember,
      memberNumber,
      hiddePrograms,
      membershipID,
      editPasswordAlert,
    } = this.state;
    return (
      <>
        <Grid item xs>
          <Header
            membershipData={membershipData}
            handleDropdownItems={this.handleDropdownItems}
            handleBack={() => this.props.history.push('/scccPortal/membership')}
            memberName={`${values.firstName} ${values.lastName}`}
          />
        </Grid>
        <Grid item xs>
          <Grid container justifyContent="center" style={{ marginTop: '10px', padding: '10px' }}>
            <Grid item sm={12} md={12} lg>
              <PersonalInfo handleInputs={this.handleInputs} values={values} errors={errors} messages={messages} />
            </Grid>
            <Grid item sm={12} md={12} lg>
              <EmergencyContact handleInputs={this.handleInputs} values={values} errors={errors} messages={messages} />
              <Paper className="paper-family-box">
                <Grid item xs={12} className="Boxes-main-title">
                  Family Members
                </Grid>
                {familyMembers.map((value, index) => (
                  <Grid item xs={12} key={index} className="boxes-spacing">
                    <Grid container>
                      <Grid item xs={4} key={index}>
                        Member :
                        {index + 1}
                      </Grid>
                      <Grid item xs className="family-member" onClick={() => this.handleFamilyMemberClick(index)}>
                        {value.firstName}
                        &nbsp;
                        {value.lastName}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Paper>
            </Grid>
            <Grid item sm={12} md={12} lg>
              <MembershipInfo membershipData={membershipData} values={values} />
              <OtherInfo handleInputs={this.handleInputs} values={values} errors={errors} messages={messages} />
            </Grid>
            <Grid item xs={12} id={hiddePrograms}>
              <RegisteredPrograms handleProramSelection={this.handleProramSelection} handleHiddePrograms={this.handleHiddePrograms} membershipID={membershipID} />
            </Grid>
            <Grid item xs={12} style={{ marginTop: '30px' }}>
              <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid>
                  <Btn label="Save" onClick={() => this.handleSubmit()} bgColor="#e76f50" color="white" bgColorHover="#ff8c68" colorHover="white" width={128} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Alert open={openAlert} close={() => this.setState({ openAlert: false })} location={alertLocation} title={alertTitle} body={alertBody} stay={alertStay} leave={alertClose} />
        <EditFamilyMember
          open={editFamilyMember}
          memberNumber={memberNumber}
          familyMemberData={familyMembers[memberNumber]}
          familyMembersToSave={familyMembers}
          handleUpdateFamilyMember={this.handleUpdateFamilyMember}
          handleCloseEditMember={() => this.setState({ editFamilyMember: false })}
          membershipID={membershipID}
        />
        <EditPassword membershipID={membershipID} open={editPasswordAlert} close={() => this.setState({ editPasswordAlert: false })} />
      </>
    );
  }
}

VerifyMembership.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  userData: PropTypes.shape({
    userData: PropTypes.shape({
      membershipID: PropTypes.string,
      userType: PropTypes.string,
    }),
  }).isRequired,
};

const updateStateValues = (state, membershipData) => {
  const updateValues = state.values;
  updateValues.emergencyName = membershipData.membership.emergencyContact.name;
  updateValues.emergencyRelationship = membershipData.membership.emergencyContact.emergencyRelationship;
  updateValues.emergencyHomePhone = membershipData.membership.emergencyContact.homePhone;
  updateValues.emergencyWorkPhone = membershipData.membership.emergencyContact.workPhone;
  updateValues.language = membershipData.membership.language;
  updateValues.learned = membershipData.membership.learnedAboutTheCenterFrom;
  updateValues.email = membershipData.membership.email;
  updateValues.address = membershipData.membership.address;
  updateValues.membershipType = membershipData.membership.membershipType;
  updateValues.apt = membershipData.membership.address.apt;

  updateValues.gender = membershipData.primaryMember.gender;
  updateValues.school = membershipData.primaryMember.school;
  updateValues.phone = membershipData.primaryMember.phone;
  updateValues.firstName = membershipData.primaryMember.firstName;
  updateValues.lastName = membershipData.primaryMember.lastName;
  updateValues.relationship = membershipData.primaryMember.relationship;
  updateValues.dateOfBirth = membershipData.primaryMember.dateOfBirth;
  return updateValues;
};

const SaveMembershipOnDB = (state, primaryMemberID) => {
  let checkIfAddressHasChanged = state.address;
  if (typeof state.address !== 'object') {
    checkIfAddressHasChanged = state.address;
    const addressSplited = checkIfAddressHasChanged.split(',');
    checkIfAddressHasChanged = {
      street: `${addressSplited[0]} ${addressSplited[1]}`,
      postalCode: state.postalCode,
      province: addressSplited[3],
      city: addressSplited[2],
      apt: state.apt,
    };
  } else {
    checkIfAddressHasChanged.apt = state.apt;
  }

  const membership = {
    primaryMember: {
      firstName: state.firstName,
      lastName: state.lastName,
      age: state.age,
      dateOfBirth: state.dateOfBirth,
      gender: state.gender,
      relationship: state.relationship,
      school: state.school,
      phone: state.phone,
      _id: primaryMemberID,
    },
    emergencyContact: {
      name: state.emergencyName,
      emergencyRelationship: state.emergencyRelationship,
      homePhone: state.emergencyHomePhone,
      workPhone: state.emergencyWorkPhone,
    },
    address: checkIfAddressHasChanged,
    language: state.language,
    learnedAboutTheCenterFrom: state.learned,
    email: state.email,
    membershipType: state.membershipType,
  };
  return membership;
};
export default VerifyMembership;
