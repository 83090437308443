import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Controller } from 'react-hook-form';
import TextField from '../../../../materialUi/textField';
import DateTextField from '../../../../materialUi/dateTextField';

function VariableScheduleBox(props) {
  const { scheduleNumber, deleteSchedule } = props;
  const {
    control,
    formState: { errors },
    register,
    setValue,
    data,
    variableSchedule,
    watch
  } = props;

  const checkIfDateIsValid = (date) => {
    if (date.toString() === 'Invalid Date') {
      return 'Invalid Date Format';
    }
    return true;
  };

  const checkSameSchedule = () => {
    let result = true;
    const selectedDate = new Date(variableSchedule[scheduleNumber].date);
    const selectedStartTime = variableSchedule[scheduleNumber].startTime;
    const selectedEndTime = variableSchedule[scheduleNumber].endTime;
    variableSchedule.forEach((element, index) => {
      const comparedDate = new Date(element.date);
      if (
        index !== scheduleNumber
        && selectedDate.getTime() === comparedDate.getTime()
        && selectedStartTime === element.startTime
        && selectedEndTime === element.endTime
      ) {
        result = 'Schedule already selected, please select another date and/or time'
      }
    });
    return result;
  };

  const handleDate = (value) => {
    register('variableSchedule');
    if (checkIfDateIsValid(value) === true) {
      variableSchedule[scheduleNumber].date = value
      setValue('variableSchedule', variableSchedule);
      return checkSameSchedule();
    }
    return checkIfDateIsValid(value);
  };

  const handleTime = (name, value) => {
    if (value) {
      variableSchedule[scheduleNumber][name] = value;
      setValue('variableSchedule', variableSchedule);
      return checkSameSchedule();
    }
    return true;
  };

  React.useEffect(() => {
    watch('variableSchedule')
    console.log(variableSchedule)
  }, [data])

  return (
    <Grid item xs={12} className="addNewProgram-box">
      <Grid container spacing={4}>
        <h4 className="addNewProgram-box-title">
          Program Schedule
          {' '}
          {scheduleNumber + 1}
        </h4>
        <Grid item xs={12} md>
          <Controller
            name={`date${scheduleNumber}`}
            control={control}
            rules={{
              required: 'Date is required',
              validate: (value) => handleDate(value),
            }}
            defaultValue={data.variableSchedule && data.variableSchedule[scheduleNumber] && data.variableSchedule[scheduleNumber].date}
            render={({ field }) => (
              <DateTextField
                label="Date"
                name="Date"
                id={`date${scheduleNumber}`}
                handleChanges={() => {}}
                helperText={errors[`date${scheduleNumber}`] ? errors[`date${scheduleNumber}`].message : ''}
                error={!!errors[`date${scheduleNumber}`]}
                readOnly={false}
                {...field}
                value={variableSchedule[scheduleNumber].date}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md>
          <Controller
            name={`startTime${scheduleNumber}`}
            control={control}
            rules={{
              required: 'Start Time is required',
              validate: (value) => handleTime('startTime', value),
            }}
            defaultValue={data.variableSchedule && data.variableSchedule[scheduleNumber] && data.variableSchedule[scheduleNumber].startTime}
            render={({ field }) => (
              <TextField
                id={`startTime${scheduleNumber}`}
                name={`startTime${scheduleNumber}`}
                label="Start Time"
                helperText={errors[`startTime${scheduleNumber}`] ? errors[`startTime${scheduleNumber}`].message : ''}
                error={!!errors[`startTime${scheduleNumber}`]}
                width="100%"
                readOnly={false}
                type="time"
                shrink
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md>
          <Controller
            name={`endTime${scheduleNumber}`}
            control={control}
            rules={{
              required: 'End Time is required',
              validate: (value) => handleTime('endTime', value),
            }}
            defaultValue={data.variableSchedule && data.variableSchedule[scheduleNumber] && data.variableSchedule[scheduleNumber].endTime}
            render={({ field }) => (
              <TextField
                id={`endTime${scheduleNumber}`}
                type="time"
                name={`endTime${scheduleNumber}`}
                label="End Time"
                helperText={errors[`endTime${scheduleNumber}`] ? errors[`endTime${scheduleNumber}`].message : ''}
                error={!!errors[`endTime${scheduleNumber}`]}
                width="100%"
                readOnly={false}
                shrink
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
      <button
        className="addNewProgram-box-close-button"
        onClick={() => deleteSchedule(scheduleNumber)}
        type="button"
      >
        <CloseIcon fontSize="large" />
      </button>
    </Grid>
  );
}

VariableScheduleBox.propTypes = {
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  setValue: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  data: PropTypes.shape(
    {
      variableSchedule: PropTypes.arrayOf(PropTypes.any),
    },
  ).isRequired,
};

export default VariableScheduleBox;
