import React from 'react';
import { Grid } from '@material-ui/core';
import SelectCategory from './selectCategory/selectCategory';
import RegistrationForm from './registrationForm/registrationForm';
import Payment from './payment/payment';
import Stepper from '../../../materialUi/stepper';
import SuccessPage from './success/success';

function MembershipRegistration({ userData }) {
  const [step, setStep] = React.useState(-1);
  const [data, setData] = React.useState({});
  const [password, setPassword] = React.useState(null);

  const resetAllData = () => {
    setData({});
    setStep(-1);
    sessionStorage.clear()
  };

  const savePassword = (copyData) => {
    const dataWithoutPassword = copyData;
    setPassword(copyData.password);
    delete dataWithoutPassword.password;
    delete dataWithoutPassword.passwordCheck;
    return dataWithoutPassword;
  };

  const handleNextStep = (newData, newStep, reset = false) => {
    if (reset) {
      resetAllData();
    } else {
      let copyData = data;
      copyData = { ...data, ...newData };
      copyData.currentStep = newStep;
      if (newData.password) {
        copyData = savePassword(copyData);
      }
      setStep(newStep);
      setData(copyData);
      sessionStorage.setItem('membershipRegistration', JSON.stringify(copyData));
    }
  };

  React.useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' });
    const dataFromSession = JSON.parse(sessionStorage.getItem('membershipRegistration'));
    if (dataFromSession) {
      setData(dataFromSession);
      setStep(parseInt(dataFromSession.currentStep, 10));
      if (dataFromSession.payment) {
        setStep(2);
      }
    }
  }, []);

  return (
    <Grid item xs className="font-family-default">
      {
        step !== 2 && (
          <Stepper
            labels={['Registration form', 'Payment', 'Success']}
            step={step}
          />
        )
      }
      {step === -1 && <SelectCategory data={data} handleNextStep={handleNextStep} />}
      {step === 0 && <RegistrationForm data={data} handleNextStep={handleNextStep} userData={userData} />}
      {step === 1 && <Payment data={data} handleNextStep={handleNextStep} password={password} userData={userData} />}
      {step === 2 && <SuccessPage data={data} userData={userData} />}
    </Grid>
  );
}

export default MembershipRegistration;
