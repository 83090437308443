import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Paper } from '@material-ui/core';
import Icon from '../../../../../assets/icons/information.png';
import '../../../../../css/payment.css';

function fees({ values, membershipID }) {
  let membershipType;
  if (JSON.parse(sessionStorage.getItem(`changeMembership_${membershipID}`))) {
    membershipType = JSON.parse(sessionStorage.getItem(`changeMembership_${membershipID}`))
      .membership.membershipType;
  }

  return (
    <Grid item xs={12} md className="fees-col">
      <Paper className="payment-boxes-fees">
        <Box className="fee-title">Membership Fees</Box>
        <hr className="fees-hr" />
        <Grid className="payment-boxes-fees-spacing">
          <Grid container>
            <Grid item xs>
              <span className="fees-labels">Category</span>
            </Grid>
            <Grid item xs>
              <span className="fees-labels">Type</span>
            </Grid>
            <Grid item xs>
              <span className="fees-labels">Total Amount</span>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              {sessionStorage.getItem('membershipCategory')}
              &nbsp;
              <img src={Icon} alt="Question mark" width="12" height="12" />
            </Grid>
            <Grid item xs>
              {membershipType}
            </Grid>
            <Grid item xs>
              ${values.fee.toFixed(2)}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

fees.propTypes = {
  values: PropTypes.shape({
    fee: PropTypes.number,
  }).isRequired,
  membershipID: PropTypes.string.isRequired,
};

export default fees;
