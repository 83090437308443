import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Header from './components/header';
import SubimittedAttendanceTable from './components/subimittedAttendanceTable';
import { SubmittedAttendanceAPI } from '../../../API/API-submittedAttendanceList';
import { ProgramsAPI } from '../../../API/API-programs';
import TableExport from './components/tableExport';
import Alert from '../../../materialUi/Alerts';
import '../../../css/submitedAttendances.css';

export default class SubmitedAttendances extends Component {
  constructor(props) {
    super(props);
    const { match } = this.props;
    this.state = {
      programID: match.params.programID,
      programName: '',
      attendenceData: [],
      isThereAnyAttendanceToday: false,
      values: {
        startDate: '',
        endDate: '',
      },
      messages: {
        startDate: '',
        endDate: '',
      },
      openAlert: false,
      alertLocation: '',
      alertTitle: '',
      alertBody: '',
      alertStay: '',
      alertClose: '',
    };
  }

  componentDidMount() {
    const { programID, attendenceData } = this.state;
    SubmittedAttendanceAPI.GET.getSubmittedAttendanceList(programID)
      .then((success) => {
        this.setState({ attendenceData: success.data });
        // check if there is any subimitted list today
        for (let i = 0; i < success.data.length; i++) {
          const today = new Date();
          if (
            today.getFullYear() === new Date(success.data[i].date).getFullYear()
            && today.getMonth() === new Date(success.data[i].date).getMonth()
            && today.getDate() === new Date(success.data[i].date).getDate()
          ) {
            this.setState({ isThereAnyAttendanceToday: true });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // get program information
    ProgramsAPI.GET.getProgramByID(programID)
      .then((success) => {
        const copyAttendance = attendenceData;
        copyAttendance.program = success.data.program[0];
        console.log(copyAttendance);
        this.setState({
          programName: success.data.program[0].name,
          // attendenceData : copyAttendance,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          openAlert: true,
          alertLocation: '/adminPortal/programs',
          alertTitle: 'Program not found!',
          alertBody: 'We could not find the program. Please try again later!',
          alertClose: 'CLOSE',
        });
      });
  }

  handleDate = (name, value, type, requirement = '') => {
    const { values, messages } = this.state;
    values[name] = value;
    messages[name] = '';
    this.setState({ values, messages });
  };

  handleReset = () => {
    const { values, messages } = this.state;
    values.startDate = '';
    values.endDate = '';
    messages.startDate = '';
    messages.endDate = '';
    this.setState({ values, messages });
  };

  handleExport = (value) => {
    const { programID } = this.state;
    if (value === 'Print') {
      const printContent = document.getElementById('tableExport').innerHTML;
      document.body.innerHTML = printContent;
      window.print();
      window.location = `/adminPortal/programs/submitedAttendances/${programID}`;
    } else {
      const table = document.getElementById('tableExport').innerHTML;
      const convertTable = table
        .toString()
        .replace(/<div>/g, '')
        .toString()
        .replace(/<\/div>/g, '')
        .toString()
        .replace(/<table class="table">/g, '')
        .toString()
        .replace(/<\/table>/g, '')
        .toString()
        .replace(/<td>/g, '')
        .toString()
        .replace(/<\/td>/g, ',')
        .toString()
        .replace(/<tr>/g, '')
        .toString()
        .replace(/<\/tr>/g, '\r\n')
        .toString()
        .replace(/<th scope="col">/g, '')
        .toString()
        .replace(/<\/th>/g, ',')
        .toString()
        .replace(/<thead>/g, '')
        .toString()
        .replace(/<\/thead>/g, '')
        .toString()
        .replace(/<tbody>/g, '')
        .toString()
        .replace(/<\/tbody>/g, '')
        .toString()
        .replace(/<\/t>/g, '')
        .toString()
        .replace(/<\/n>/g, '');
      const myLink = document.createElement('a');
      myLink.download = 'AttendanceLists.csv';
      myLink.href = `data:application/csv,${escape(convertTable)}`;
      myLink.click();
    }
  };

  handleCreateNew = () => {
    const { isThereAnyAttendanceToday, programID } = this.state;
    const { history } = this.props;
    if (isThereAnyAttendanceToday) {
      this.setState({
        openAlert: true,
        alertLocation: '/adminPortal/programs',
        alertTitle: 'Cannot create a new attendance!',
        alertBody: 'Today`s attendance has already been submitted.',
        alertClose: 'hidden',
        alertStay: 'CLOSE',
      });
    } else {
      history.push(`/adminPortal/programs/addNewAttendance/${programID}`);
    }
  };

  render() {
    const { history } = this.props;
    const {
      attendenceData, programName, programID, values, messages, openAlert, alertLocation, alertTitle, alertBody, alertStay, alertClose,
    } = this.state;
    return (
      <Grid item xs className="font-family-default">
        <Header
          handleBack={() => history.push(`/adminPortal/programs/registrationList/${programID}`)}
          handleAddNewAttendance={this.handleCreateNew}
          handleExport={this.handleExport}
          programName={programName}
          handleDate={this.handleDate}
          values={values}
          messages={messages}
          handleReset={this.handleReset}
        />
        <SubimittedAttendanceTable handleClick={(value) => history.push(`/adminPortal/programs/attendanceListDetails/${value}/${programID}`)} data={attendenceData} values={values} />
        <div id="tableExport" className="hidden">
          <TableExport data={attendenceData} values={values} />
        </div>
        <Alert open={openAlert} close={() => this.setState({ openAlert: false })} location={alertLocation} title={alertTitle} body={alertBody} stay={alertStay} leave={alertClose} />
      </Grid>
    );
  }
}

SubmitedAttendances.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programID: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
