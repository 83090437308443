import React from 'react';
import PropTypes from 'prop-types';
import { CONVERTTIME } from '../../../../utils/dateController';
import '../../../../css/registrationList.css';

function createData(
  membershipID,
  firstName,
  lastName,
  email,
  phone,
  registrationDate,
  paymentStatus,
  id,
  numberOfAttendees
) {
  return {
    membershipID,
    firstName,
    lastName,
    email,
    phone,
    registrationDate,
    paymentStatus,
    id,
    numberOfAttendees,
  };
}
export default function WaitingListTableExport({ registrationData, sortStatus, sortSearch }) {
  let rows = [];
  for (let i = 0; i < registrationData.length; i++) {
    if (registrationData[i].programWaitingList) {
      if (sortStatus.length > 0) {
        // eslint-disable-next-line
        sortStatus.map((status) => {
          if (status.toLowerCase() === registrationData[i].paymentStatus.toLowerCase()) {
            if (sortSearch) {
              if (
                registrationData[i].membershipData.familyMembersInformation[0].firstName.toLowerCase().includes(sortSearch.toLowerCase()) ||
                registrationData[i].membershipData.familyMembersInformation[0].lastName.toLowerCase().includes(sortSearch.toLowerCase()) ||
                registrationData[i].membershipData.email.toLowerCase().includes(sortSearch.toLowerCase()) ||
                registrationData[i].membershipID.includes(sortSearch)
              ) {
                rows.push(
                  createData(
                    registrationData[i].membershipID,
                    registrationData[i].membershipData.familyMembersInformation[0].firstName,
                    registrationData[i].membershipData.familyMembersInformation[0].lastName,
                    registrationData[i].membershipData.email,
                    registrationData[i].familyMembersData[0].phone,
                    CONVERTTIME(registrationData[i].registrationDate, true),
                    registrationData[i].paymentStatus,
                    registrationData[i]._id,
                    registrationData[i].familyMembersData.length
                  )
                );
              }
            } else {
              rows.push(
                createData(
                  registrationData[i].membershipID,
                  registrationData[i].membershipData.familyMembersInformation[0].firstName,
                  registrationData[i].membershipData.familyMembersInformation[0].lastName,
                  registrationData[i].membershipData.email,
                  registrationData[i].familyMembersData[0].phone,
                  CONVERTTIME(registrationData[i].registrationDate, true),
                  registrationData[i].paymentStatus,
                  registrationData[i]._id,
                  registrationData[i].familyMembersData.length
                )
              );
            }
          }
        });
      } else if (sortSearch) {
        if (
          registrationData[i].membershipData.familyMembersInformation[0].firstName.toLowerCase().includes(sortSearch.toLowerCase()) ||
          registrationData[i].membershipData.familyMembersInformation[0].lastName.toLowerCase().includes(sortSearch.toLowerCase()) ||
          registrationData[i].membershipData.email.toLowerCase().includes(sortSearch.toLowerCase()) ||
          registrationData[i].membershipID.includes(sortSearch)
        ) {
          rows.push(
            createData(
              registrationData[i].membershipID,
              registrationData[i].membershipData.familyMembersInformation[0].firstName,
              registrationData[i].membershipData.familyMembersInformation[0].lastName,
              registrationData[i].membershipData.email,
              registrationData[i].familyMembersData[0].phone,
              CONVERTTIME(registrationData[i].registrationDate, true),
              registrationData[i].paymentStatus,
              registrationData[i]._id,
              registrationData[i].familyMembersData.length
            )
          );
        }
      } else {
        rows.push(
          createData(
            registrationData[i].membershipID,
            registrationData[i].membershipData.familyMembersInformation[0].firstName,
            registrationData[i].membershipData.familyMembersInformation[0].lastName,
            registrationData[i].membershipData.email,
            registrationData[i].familyMembersData[0].phone,
            CONVERTTIME(registrationData[i].registrationDate, true),
            registrationData[i].paymentStatus,
            registrationData[i]._id,
            registrationData[i].familyMembersData.length
          )
        );
      }
    }
  }

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Position</th>
            <th scope="col">Membership ID</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Phone</th>
            <th scope="col"># of Attendees</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((value, index) => (
            <tr key={index}>
              <td>{index}</td>
              <td>{value.membershipID}</td>
              <td>
                {value.firstName} {value.lastName}
              </td>
              <td>{value.email}</td>
              <td>{value.phone}</td>
              <td>{value.numberOfAttendees}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

WaitingListTableExport.propTypes = {
  sortStatus: PropTypes.arrayOf(PropTypes.string).isRequired,
  sortSearch: PropTypes.PropTypes.string.isRequired,
  registrationData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      familyMembers: PropTypes.arrayOf(
        PropTypes.shape({
          phone: PropTypes.string,
          firstName: PropTypes.string,
          lastName: PropTypes.string,
          membershipID: PropTypes.string,
        })
      ),
    }),
  ]).isRequired,
};
