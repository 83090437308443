export const fieldsMessages = function(name, requirement) {
  if (requirement === 'empty') {
    return `${name} cannot be empty`;
  }
  if (requirement === 'notZero') {
    return `${name} must be more than 0`;
  }
  if (requirement === 'too long') {
    return `${name} too long`;
  }
  if (requirement === 'not valid') {
    if (name === 'Phone') {
      return 'Phone Format 555-555-5555';
    }
    return `Not a Valid ${name}`;
  }
  if (requirement === 'dropdown') {
    return 'Please select one!';
  }
};

export const notEmptyValidation = function(value, nameToDisplay) {
  if (value.length < 1) {
    return `${nameToDisplay} cannot be empty`;
  }
  if (value.length > 50) {
    return `${nameToDisplay} too long`;
  }
  return true;
};

export const programDescriptionValidation = function(value, nameToDisplay) {
  if (value.length < 1) {
    return `${nameToDisplay} cannot be empty`;
  }
  if (value.length > 500) {
    return `${nameToDisplay} too long`;
  }
  return true;
};

export const ohipValidation = function(value, nameToDisplay) {
  if (value.length < 1) {
    return `${nameToDisplay} cannot be empty`;
  } else if (value.length < 10) {
    return `${nameToDisplay}: Minimum of 10 digits`;
  } else if (value.length > 50) {
    return `${nameToDisplay} too long`;
  }
  return true;
};

export const priceValidation = function(value) {
  if (!/^(\d*([.,](?=\d{3}))?\d+)+((?!\2)[.,]\d\d)?$/.test(value) && value.length > 0) {
    return fieldsMessages('Price', 'not valid');
  }
  if (value.length < 1) {
    return fieldsMessages('Price', 'empty');
  }
  return true;
};

export const programFeeValidation = function(value) {
  if (!/^(\d*([.,](?=\d{3}))?\d+)+((?!\2)[.,]\d\d)?$/.test(value) && value.length > 0) {
    return fieldsMessages('Price', 'not valid');
  }
  if (value.length < 1) {
    return fieldsMessages('Price', 'empty');
  }
  if (value === false) {
    return true;
  }
  return true;
};

export const phoneValidation = function(value, required) {
  if (value.length > 0) {
    if (!/^[\]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/im.test(value)) {
      return fieldsMessages('Phone', 'not valid');
    }

    return true;
  }
  if (required === true) {
    return true;
  }
  return fieldsMessages('Phone', 'empty');
};

export const ageValidation = function(value) {
  if (value.length < 1) {
    return fieldsMessages('Age', 'empty');
  }
  if (value.length > 10) {
    return fieldsMessages('Age', 'too long');
  }
  return true;
};

export const aptValidation = function(value) {
  if (value) {
    if (value.length > 10) {
      return fieldsMessages('Apt', 'too long');
    }
    return true;
  }
};

export const addressValidation = function(value, addressSelected) {
  if (value.length < 1) {
    return fieldsMessages('Address', 'empty');
  }
  if (addressSelected === true) {
    return true;
  }
  return fieldsMessages('Address', 'not valid');
};

export const dropdownValidation = function(value, required) {
  if ((value === 'Select One' || value === 'Choose') && !required) {
    return fieldsMessages('dropdown', 'dropdown');
  }
  if (value === 'Select One' && required === true) {
    return true;
  }
  return true;
};

export const dateValidation = function(value) {
  if (!/^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/.test(value)) {
    return fieldsMessages('Date', 'not valid');
  }
  return true;
};

export const startDateValidation = function(value, valueToCompare) {
  const dateAdjustment = new Date(value);
  dateAdjustment.setDate(dateAdjustment.getDate() + 1);
  if (value.toString() === 'Invalid Date') return value.toString();
  if (new Date(value).toISOString() > new Date(valueToCompare).toISOString()) {
    return 'Start date cannot be more than the end date';
  }
  return true;
};

export const endDateValidation = function(value, valueToCompare) {
  const dateAdjustment = new Date(value);
  dateAdjustment.setDate(dateAdjustment.getDate() + 1);
  if (value.toString() === 'Invalid Date') return value.toString();
  if (dateAdjustment.toISOString() < new Date(dateAdjustment).toISOString()) {
    return 'End date cannot be less than the start date';
  }
  if (dateAdjustment.toISOString() <= new Date().toISOString()) {
    return 'end date cannot be less than the today date';
  }
  return true;
};

export const timeValidation = function(value) {
  if (!/^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/.test(value)) {
    return fieldsMessages('Date', 'not valid');
  }
  return true;
};

export const scheduleDate = function(value, requiment) {
  if (value.toString() === 'Invalid Date') return value.toString();
  return true;
};

export const NotRequired = function(value, nameToDisplay) {
  if (value.length > 50) {
    return fieldsMessages(nameToDisplay, 'too long');
  }

  return true;
};

export const emailValidation = function(value, required) {
  if (value.length > 0) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      return fieldsMessages('Email', 'not valid');
    }
    return true;
  }
  if (required === true) {
    return fieldsMessages('Email', 'empty');
  }
  return true;
};

export const multilineField = function(value, nameToDisplay) {
  if (value.length > 150) {
    return fieldsMessages(nameToDisplay, 'too long');
  }
  return true;
};

export const validateField = function(value, type, extraRequirement = '') {
  if (type === 'phone') {
    return phoneValidation(value, extraRequirement);
  }
  if (type === 'dropdown') {
    return dropdownValidation(value, extraRequirement);
  }
  if (type === 'name') {
    return notEmptyValidation(value, extraRequirement);
  }
  if (type === 'programDescription') {
    return programDescriptionValidation(value, extraRequirement);
  }
  if (type === 'email') {
    return emailValidation(value, extraRequirement);
  }
  if (type === 'date') {
    return dateValidation(value);
  }
  if (type === 'age') {
    return ageValidation(value);
  }
  if (type === 'address') {
    return addressValidation(value, extraRequirement);
  }
  if (type === 'apt') {
    return aptValidation(value);
  }
  if (type === 'postalCode') {
    return notEmptyValidation(value, extraRequirement);
  }
  if (type === 'price') {
    return priceValidation(value);
  }
  if (type === 'programFee') {
    return programFeeValidation(value);
  }
  if (type === 'not required') {
    return NotRequired(value);
  }
  if (type === 'time') {
    return timeValidation(value);
  }
  if (type === 'startDate') {
    return startDateValidation(value, extraRequirement);
  }
  if (type === 'endDate') {
    return endDateValidation(value, extraRequirement);
  }
  if (type === 'multilineField') {
    return multilineField(value, extraRequirement);
  }
  if (type === 'ohip') {
    return ohipValidation(value, extraRequirement);
  }
  if (type === 'scheduleDate') {
    return scheduleDate(value, extraRequirement);
  }
  return false;
};
