import axios from 'axios';
import baseURL from './baseURL';
import TOKEN from './Frontend-Token';

export const ProgramAttendance = {
  GET: {
    getProgramAttendanceByProgramID(programID) {
      return axios.get(`${baseURL}api/getProgramAttendenceByProgramID/${programID}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    getOneByMembershipID(membershipID) {
      return axios.get(`${baseURL}api/getMemberAttendanceByMembershipID/${membershipID}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
  },
  PUT: {
    deleteProgramAttendee(programID, removeObj) {
      return axios.put(
        `${baseURL}api/deleteProgramAttendee/${programID}`,
        removeObj,
        {
          headers: { 'x-access-token': TOKEN },
        }
      );
    },
  },
};

export default ProgramAttendance;
