import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import { BootstrapTextField, BootstrapSelectDropdown } from '../../../../materialUi/bootstrapInputs';

export default function OtherInfo({ data }) {

  const medication = ['Yes', 'No'];
  return (
    <Grid container>
      <Grid item xs={12} className="programDetails-spacing">
        <Box className="programDetails-boxes">
          <Grid container>
            <Grid item xs={12} className="programDetails-boxes-main-title">
              Other Info:
            </Grid>
            <Grid item xs={12} className="programDetails-boxes-text-other">
              Does your child have any medical problems (including allergies)
               of which we should be aware?
            </Grid>
            <Grid item xs={12}>
              <BootstrapTextField
                id="medicationInformation"
                type="text"
                name="Medication Information"
                value={data.participantMedicalInformation.medicalInformation}
                handleChanges={(value) => false}
                message=""
                readOnly={false}
                error={false}
              />
            </Grid>
            <Grid item xs={12} className="programDetails-boxes-text-other mt-3">
              Does your child have any behavioural issues/special needs of which we should be aware?
            </Grid>
            <Grid item xs={12}>
              <BootstrapTextField
                id="specialInstructions"
                type="text"
                name="Special Instructions"
                value={data.participantMedicalInformation.specialInstructions}
                handleChanges={(value) => false}
                message=""
                readOnly={false}
                error={false}
              />
            </Grid>
            <Grid item xs={12} className="programDetails-boxes-text-other mt-3">
              Is your child taking any medication?
            </Grid>
            <Grid item xs={1}>
              <BootstrapSelectDropdown
                value={data.participantMedicalInformation.anyMedication}
                list={medication}
                handleChanges={(value) => false}
                message={''}
                error={''}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container className="programDetails-boxes-text-other">
                <Grid item xs>
                  <Grid container>
                    <Grid item xs={6} md={4} className="pt-2 ">
                      Medication Name:
                    </Grid>
                    <Grid item xs>
                      <BootstrapTextField
                        id="medicationName"
                        type="text"
                        name="Medication name"
                        value={data.participantMedicalInformation.medicationName}
                        handleChanges={(value) => false}
                        message="messages.emergencyName"
                        readOnly
                        error={false}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs>
                  <Grid container>
                    <Grid item xs={6} md={4} className="pt-2 pl-3">
                      When:
                    </Grid>
                    <Grid item xs>
                      <BootstrapTextField
                        id="medicationWhen"
                        type="text"
                        name="Medication when"
                        value={data.participantMedicalInformation.medicationWhen}
                        handleChanges={(value) => false}
                        message=""
                        readOnly
                        error={false}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs>
                  <Grid container>
                    <Grid item xs={6} md={4} className="pt-2 pl-3">
                      Dosage:
                    </Grid>
                    <Grid item xs>
                      <BootstrapTextField
                        id="dosage"
                        type="text"
                        name="Dosage"
                        value={data.participantMedicalInformation.dosage}
                        handleChanges={(value) => false}
                        message=""
                        readOnly={false}
                        error={false}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container className="programDetails-boxes-text-other">
                <Grid item xs>
                  <Grid container>
                    <Grid item xs={6} md={4} className="pt-2 ">
                      Doctor’s Name:
                    </Grid>
                    <Grid item xs>
                      <BootstrapTextField
                        id="doctorName"
                        type="text"
                        name="Doctor Name"
                        value={data.participantMedicalInformation.doctorName}
                        handleChanges={(value) => false}
                        message=""
                        readOnly
                        error={false}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs>
                  <Grid container>
                    <Grid item xs={6} md={4} className="pt-2 pl-3">
                      Doctor’s Phone Number:
                    </Grid>
                    <Grid item xs>
                      <BootstrapTextField
                        id="doctorNumber"
                        type="text"
                        name="Doctor Number"
                        value={data.participantMedicalInformation.doctorNumber}
                        handleChanges={(value) => false}
                        message=""
                        readOnly
                        error={false}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

OtherInfo.propTypes = {
  data: PropTypes.shape({
    participantMedicalInformation: PropTypes.shape({
      medicalInformation: PropTypes.string,
      specialInstructions: PropTypes.string,
      anyMedication: PropTypes.string,
      medicationName: PropTypes.string,
      medicationWhen: PropTypes.string,
      dosage: PropTypes.string,
      doctorName: PropTypes.string,
      doctorNumber: PropTypes.string,
    }),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      programID: PropTypes.string,
      membershipID: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

OtherInfo.defaultProps = {
  data: {
    participantMedicalInformation: {
      medicalInformation: '',
      specialInstructions: '',
      anyMedication: '',
      medicationName: '',
      medicationWhen: '',
      dosage: '',
      doctorName: '',
      doctorNumber: '',
    },
  },
};

