import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';


function CheckboxLabels(props) {
  const GreenCheckbox = withStyles({
    root: {
      marginRight: '0px !important',
      color: '#2a9d8f',
      fontFamily: ['Work Sans', 'sans-serif'],
      fontSize: props.fontSize,
      '&$checked': {
        color: '#2a9d8f',
      },
      bottom: props.bottom,
      height: '40px',
    },
    checked: {},

  })((props) => <Checkbox color="default" {...props} />);


  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });

  const handleChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
    props.click(event.target.checked);
  };

  const label = <span style={{ fontSize: props.fontSize }} className="Receive-latest-news">{props.message}</span>;

  return (
    <FormGroup row>
      <FormControlLabel
        control={(
          <GreenCheckbox
            checked={props.value}
            onChange={handleChange('checkedG')}
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            title={props.id}
          />
        )}
        className="greenCheck"
        label={label}
        {...props}
      />

    </FormGroup>
  );
}

export default CheckboxLabels;
