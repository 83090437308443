import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Box } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { Controller } from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '../../../../../materialUi/textField';
import InformationIcon from '../../../../../assets/icons/information.png';
import BankSampleImage from '../../../../../assets/icons/bankSample.svg';
import PDFIcon from '../../../../../assets/icons/pdf-icon.svg';
import PPTIcon from '../../../../../assets/icons/pptIcon.png';
import imageIcon from '../../../../../assets/icons/imageIcon.png';
import docIcon from '../../../../../assets/icons/docIcon.png';
import '../../../../../css/taxes.css';

function SectionThree(props) {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    register,
    data,
  } = props;

  const [errorMessage, setErrorMessage] = React.useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    if (!data.bankInformation) {
      register('bankInformation.transitNumber');
      register('bankInformation.institutionNumber');
      register('bankInformation.accountNumber');
    } else {
      setValue('transitNumber', data.bankInformation.transitNumber);
      setValue('institutionNumber', data.bankInformation.institutionNumber);
      setValue('accountNumber', data.bankInformation.accountNumber);
    }
  }, [data]);

  const handleValues = (name, value) => {
    const bankCopy = getValues('bankInformation');
    bankCopy[name] = value;
    setValue('bankInformation', bankCopy);
  };

  const [directDepositLocal, setDirectDepositLocal] = React.useState(data.directDepositForm ? data.directDepositForm : false);


  const validateFileSize = (file) => {
    if (file.size > 100000000) {
      setErrorMessage('File size too large! Please, select a file smaller than 100MB');
      return false;
    }
    return true;
  };

  const validateFileFormat = (file) => {
    if (file.type !== 'application/msword'
      && file.type !== 'application/pdf'
      && file.type !== 'application/vnd.ms-powerpoint'
      && file.type !== 'image/png'
      && file.type !== 'image/jpeg'
      && file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      setErrorMessage('Files must be in .pdf, .doc, .docx, .pptx, .png or .jpeg format');
      return false;
    }
    return true;
  };

  const handleDirectDeposit = (event) => {
    const { files } = event.target;
    if (validateFileSize(files[0]) && validateFileFormat(files[0])) {
      register('selectedDepositForm');
      setValue('selectedDepositForm', files);
      setDirectDepositLocal(files[0]);
      setErrorMessage('');
    }
  };

  const handleRemoveFile = () => {
    setDirectDepositLocal(false);
    register('directDepositForm');
    setValue('directDepositForm', []);
  };

  const renderIconBasedOnDocType = (item) => {
    if (item.type === 'application/msword' || item.fileType === '.doc') {
      return docIcon;
    } if (item.type === 'application/pdf' || item.fileType === '.pdf') {
      return PDFIcon;
    } if (item.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || item.fileType === '.pptx') {
      return PPTIcon;
    }
    return imageIcon;
  };

  return (
    <>
      <Grid item xs={12}>
        <div className="taxes-question">
          <span className="taxes-question-red">17. *Optional: </span>
           To ensure you receive your payments
           on time in the event of an emergency, please provide
            your Canadian bank institution information for direct deposit.
          <Tooltip
            classes="taxes-question-mark-tooltip"
            arrow
            placement="right"
            title={(
              <div className="position-relative">
                <span className="taxes-bank-void-text">
                  VOID
                </span>
                <img
                  width="100%"
                  src={BankSampleImage}
                  alt="Bank sample"
                />
              </div>
            )}
          >
            <img
              className="taxes-question-mark"
              src={InformationIcon}
              alt="Question mark"
            />
          </Tooltip>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={(isMobile) ? 0 : 4}>
          <Grid item xs={12} md={4}>
            <Controller
              name="transitNumber"
              control={control}
              rules={{
                validate: (value) => handleValues('transitNumber', value),
                maxLength: { value: 5, message: 'Max length 5' },
              }}
              render={({ field }) => (
                <TextField
                  defaultValue="00000"
                  id="transitNumber"
                  type="search"
                  name="transitNumber"
                  label="Transit No."
                  helperText={errors.transitNumber ? errors.transitNumber.message : ''}
                  error={!!errors.transitNumber}
                  width="100%"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="institutionNumber"
              control={control}
              rules={{
                validate: (value) => handleValues('institutionNumber', value),
                maxLength: { value: 3, message: 'Max length 3' },
              }}
              render={({ field }) => (
                <TextField
                  defaultValue="000"
                  id="institutionNumber"
                  type="search"
                  name="institutionNumber"
                  label="Inst. No."
                  helperText={errors.institutionNumber ? errors.institutionNumber.message : ''}
                  error={!!errors.institutionNumber}
                  width="100%"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="accountNumber"
              control={control}
              rules={{
                validate: (value) => handleValues('accountNumber', value),
                maxLength: { value: 10, message: 'Max length 10' },
              }}
              render={({ field }) => (
                <TextField
                  defaultValue="0000000000"
                  id="accountNumber"
                  type="search"
                  name="transitNumber"
                  label="Account No."
                  helperText={errors.accountNumber ? errors.accountNumber.message : ''}
                  error={!!errors.accountNumber}
                  width="100%"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} style={{ paddingTop: '0px' }}>
            <button
              tabIndex="0"
              type="button"
              className="upload-dialog-upload-button"
              style={{
                width: '306px',
                height: '36px',
                fontSize: '16px',
                fontWeight: 500,
              }}
            >
                  UPLOAD DIRECT DEPOSIT FORM
              <input
                id="myFileInput"
                name="taxReturnFiles"
                type="file"
                accept="image/*;capture=camera"
                className="upload-dialog-input-button"
                onChange={handleDirectDeposit}
              />
            </button>
            <div className="custom-error mt-3">
              {errorMessage}
            </div>
          </Grid>
        </Grid>
        {directDepositLocal && (
        <Grid container style={{ marginTop: '10px' }}>
          <Box className="upload-dialog-document">
            <img
              src={renderIconBasedOnDocType(directDepositLocal)}
              alt="PDF icon"
              width="25px"
              height="25px"
            />
            <span className="upload-dialog-document-text">
              {directDepositLocal.name || directDepositLocal.fileName}
              <button
                type="button"
                className="upload-dialog-remove-file"
                onClick={() => handleRemoveFile()}
              >
                <CloseIcon fontSize="small" />
              </button>
            </span>
          </Box>
        </Grid>
        )}
      </Grid>
    </>
  );
}

SectionThree.propTypes = {
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  formState: PropTypes.shape({
    errors: PropTypes.any,
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  data: PropTypes.shape(
    {
      bankInformation: PropTypes.arrayOf(PropTypes.shape(
        {
          transitNumber: PropTypes.string,
          institutionNumber: PropTypes.string,
          accountNumber: PropTypes.string,
          directDepositForm: PropTypes.string,
        },
      )),
    },
  ).isRequired,
};

export default SectionThree;
