import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Stepper, StepLabel } from '@material-ui/core';
import Step from '@material-ui/core/Step';


export default function HorizontalNonLinearStepper({
  step, labels, background, bottomLabel,
}) {
  const useStyles = makeStyles((theme) => ({
    body: {
      backgroundColor: background,
    },
    root: {
      width: (labels.length >= 3) ? '100%' : '50%',
      backgroundColor: background,
      overflow: 'hidden',
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    text: {
      fontFamily: 'Work Sans, sans-serif',
      fontSize: '14px',
      fontWeight: 500,
      color: '#333333',
    },
    stepActive: {
      '& $active': {
        color: '#2a9d8f',
      },
    },
    active: {},
  }));

  const classes = useStyles();
  return (
    <div className={classes.body}>
      <Stepper
        className={classes.root}
        alternativeLabel={(bottomLabel || window.innerWidth < 700)}
        activeStep={step}
        nonLinear
      >
        {labels.map((label, index) => (
          <Step key={label}>
            <StepLabel
              StepIconProps={{
                classes: {
                  active: classes.active,
                },
              }}
            >
              <span className={classes.text}>{label}</span>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

HorizontalNonLinearStepper.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  step: PropTypes.number,
  background: PropTypes.string,
  bottomLabel: PropTypes.bool,
};

HorizontalNonLinearStepper.defaultProps = {
  background: '#eceff1',
  bottomLabel: false,
  step: 0,
};
