import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import TextField from '../../../materialUi/textField';
import { userAPI } from '../../../API/API-user';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class editPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      membershipID: props.membershipID,
      password: '',
      passwordMessage: '',
      passwordError: '',
      serverResponse: '',
    };
  }

  handlePassword = (value) => {
    let message = '';
    let error = false;
    if (value.target.value.length < 1) {
      message = 'Current password cannot be empty';
      error = true;
    } else {
      message = '';
      error = false;
    }
    this.setState({
      password: value.target.value,
      passwordMessage: message,
      passwordError: error,
    });
  };

  handleSubmit = () => {
    const { password, passwordMessage, passwordError } = this.state;
    let fieldsvalidation = 0;
    if (passwordError !== false) {
      fieldsvalidation += 1;
      this.setState({
        passwordError: true,
        passwordMessage: passwordMessage === '' ? 'Required Field' : passwordMessage,
      });
    }
    if (fieldsvalidation === 0) {
      userAPI.GET.checkDiscountPassword(password)
        .then((success) => {
          console.log(success);
          this.handleClose(true);
        })
        .catch((error) => {
          if (error.response.status === 401) this.setState({ serverResponse: 'Wrong Password!' });
          if (error.response.status === 500)
            this.setState({ serverResponse: 'Server Error! Please try again later.' });
          console.log(error.response);
        });
    }
  };

  handleClose = (value) => {
    const { handleValidation } = this.props;
    this.setState({ serverResponse: '', password: '', passwordError: '', passwordMessage: '' });
    handleValidation(value);
  };

  render() {
    const { open, close } = this.props;
    const { password, passwordMessage, passwordError, serverResponse } = this.state;
    return (
      <div>
        <Dialog
          onClose={() => this.handleClose(false)}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth="xs"
          fullWidth
        >
          <DialogContent dividers>
            <Grid container>
              <Grid item xs>
                <Box className="Boxes-main-title">Confirm Discount</Box>
              </Grid>
              <Grid onClick={() => close(false)}>
                <i className="fas fa-times close-icon" />
              </Grid>
              <Grid item xs={12} className="verify-serverResponse">
                {serverResponse}
              </Grid>
              <Grid item xs={12}>
                <Box className="Boxes-main-body-text">
                  Please enter the password for the confirm 50% or higher discount.
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  label=" Password"
                  id="password"
                  name="Password"
                  value={password}
                  handleChanges={(value) => this.handlePassword(value)}
                  helperText={passwordMessage}
                  error={passwordError === '' ? false : passwordError}
                  readOnly={false}
                  type="password"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="m-2">
            <Button className="alert-btns" onClick={() => close(false)}>
              Cancel
            </Button>
            <Button className="alert-btns " onClick={this.handleSubmit}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

editPassword.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  handleValidation: PropTypes.func.isRequired,
};

export default editPassword;
