import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { Grid, Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import Button from '../../../materialUi/btn';
import MultilineTextField from '../../../materialUi/multilineTextField';

function DeclineSubmission({
  open, handleCancel, handleDeclineSubmission
}) {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="Upload dialog, upload your documents by selecting the documents and click on submit."
      fullWidth
      maxWidth="md"
    >
      <h1 className="taxes-confirm-submission-title">
        Decline Submission
      </h1>
      <DialogContent>
        <form onSubmit={handleSubmit(handleDeclineSubmission)}>
          <button
            type="button"
            className="upload-dialog-close"
            onClick={handleCancel}
          >
            <CloseIcon />
          </button>
          <Grid container>
            <Grid item xs={12}>
              <div className="taxes-question">
                Please, provide more information about the identified issue on the submission
                 file. Our team will review the information and make the necessary
                  adjustments to the file.
              </div>
            </Grid>
            <Grid item xs={12} className="taxes-consent-textFields">
              <Controller
                name="fileIssue"
                control={control}
                rules={{
                  required: 'Issue Description is required',
                  maxLength: { value: 500, message: 'Max length 500' },
                }}
                render={({ field }) => (
                  <MultilineTextField
                    id="fileIssue"
                    type="search"
                    name="fileIssue"
                    label="Issue Description"
                    helperText={errors.fileIssue ? errors.fileIssue.message : ''}
                    error={!!errors.fileIssue}
                    width="100%"
                    lines={6}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                style={{ padding: '8px 0px 26px 24px' }}
              >
                <Box style={{ marginRight: '10px' }}>
                  <Button
                    label="Cancel"
                    bgColor="white"
                    color="#E76F51"
                    bgColorHover="#E76F51"
                    colorHover="white"
                    borderColor="#E76F51"
                    variant="outlined"
                    width={128}
                    onClick={handleCancel}
                    type="button"
                  />
                </Box>
                <Button
                  label="DECLINE SUBMISSION"
                  bgColor="#e76f50"
                  color="white"
                  colorHover="white"
                  bgColorHover="#ff8c68"
                  width={210}
                  onClick={() => {}}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}

DeclineSubmission.propTypes = {
  handleDeclineSubmission: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  taxVolunteerName: PropTypes.string.isRequired,
};

export default DeclineSubmission;
