import React from 'react';
import { Grid, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import DatePicker from '../../../../../materialUi/datePicker';
import '../../../../../css/receipts.css';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayFilter: false,
    };
  }

  handleDisplayFilter = () => {
    const { displayFilter } = this.state;
    const { resetFilters } = this.props;
    if (displayFilter) {
      this.setState({ displayFilter: false });
      // reset filter
      resetFilters('');
    } else {
      this.setState({ displayFilter: true });
    }
  }

  render() {
    const {
      history,
      values,
      messages,
      handleDate,
      handleReset,
    } = this.props;

    return (
      <Grid container className="root receipts-header-spacing">
        <Grid item xs={12} className="mb-2">
          <button
            onClick={() => history.push('/adminPortal/reports')}
            type="button"
            className="reports-back-buttons"
          >
            &lt;  Back to Reports
          </button>
        </Grid>
        <Grid item xs={2} className="reports-receipts-title">
          Daily Check-In
        </Grid>
        <Grid item xs>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <Box className="receipts-filter-date">
              <DatePicker
                label="Start Date"
                id="startDate"
                name="Start Date"
                value={values.startDate}
                handleChanges={(value) => handleDate('startDate', value, 'date')}
                helperText={messages.startDate}
                error={messages.startDate !== ''}
                readOnly={false}
                dateFormat="MM-dd-yyyy"
              />
            </Box>
            <Box className="receipts-filter-date-to">
          To
            </Box>
            <Box className="receipts-filter-date">
              <DatePicker
                label="End Date"
                id="endDate"
                name="End Date"
                value={values.endDate}
                handleChanges={(value) => handleDate('endDate', value, 'date')}
                helperText={messages.endDate}
                error={messages.endDate !== ''}
                readOnly={false}
                type="date"
                dateFormat="MM-dd-yyyy"
              />
            </Box>
            <Box
              className="receipts-filter-restart"
              onClick={handleReset}
              onKeyPress={handleReset}
              tabIndex={0}
              role="button"
            >
              <i className="fas fa-redo-alt" />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

Header.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  values: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.number,
  }).isRequired,
  messages: PropTypes.shape({
    startDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.instanceOf(Date),
    ]),
    endDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),

  }).isRequired,
  handleDate: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
};
export default Header;
