import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import Successful from '../../../../assets/icons/fireworks@3x.png';
import Button from '../../../../materialUi/btn';
import Receipt from './receipt';

function Success({ data, history, userData }) {

  const handleDone = () => {
    sessionStorage.clear();
    if (userData.userData.userType.toLowerCase() === 'member') {
      return history.push(`/scccPortal/membership/${data.membership.membershipID}`);
    }
    return history.push(`/adminPortal/membership/verifyMembership/${data.membership.membershipID}`);
  };

  return (
    <Grid container className="registration-success-wrapper">
      <Grid item xs={12} md={2}>
        <img src={Successful} alt="logo" width="104px" height="120px" />
      </Grid>
      <Grid item>
        <h1 className="registration-success-title">
        Successful!
        </h1>
        <h2 className="registration-success-text">
          Membership successfully renewed.
        </h2>
        {data && (
          <Receipt data={data.membership} userData={userData} />
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          style={{ paddingTop: '25px' }}
        >
          <Button
            label="DONE"
            type="button"
            bgColor="#e76f50"
            color="white"
            bgColorHover="#ff8c68"
            colorHover="white"
            width={120}
            height={47}
            onClick={() => handleDone()}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

Success.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  data: PropTypes.shape(
    {
      membershipCategory: PropTypes.string,
      membershipType: PropTypes.string,
      contactName: PropTypes.string,
      email: PropTypes.string,
      cardType: PropTypes.string,
      membershipID: PropTypes.string,
      fee: PropTypes.string,
      familyMembers: PropTypes.arrayOf(
        PropTypes.shape(
          {
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            dateOfBirth: PropTypes.string,
            phone: PropTypes.string,
            relationship: PropTypes.string,
            gender: PropTypes.string,
            membershipID: PropTypes.string,
          },
        ),
      ),
      address: PropTypes.shape(
        {
          city: PropTypes.string,
          street: PropTypes.string,
          province: PropTypes.string,
          postalCode: PropTypes.string,
        },
      ),
    },
  ).isRequired,
};

export default withRouter(Success);
