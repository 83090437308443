import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, MenuItem } from "@material-ui/core";

function ParticipantDropdown(props) {
  const {
    listName,
    error,
    id,
    label,
    name,
    value,
    helperText,
    readOnly,
    handleChanges,
  } = props;
  const useStyles = makeStyles(() => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
      fontFamily: ["Work Sans", "sans-serif"],
      marginBottom: error ? "0px" : "20px",
    },
  }));

  const classes = useStyles();

  return (
    <TextField
      id={id}
      label={label}
      name={name}
      value={value}
      helperText={helperText}
      error={error === "" ? false : error}
      select
      className={classes.root}
      readOnly={readOnly}
      SelectProps={{
        MenuProps: {
          className: classes.menu,
        },
      }}
      onChange={handleChanges}
      variant="filled"
      {...props}
    >
      {listName.map((option, index) => (
        <MenuItem disabled={readOnly} key={index} value={option}>
          {option.firstName} {option.lastName}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default ParticipantDropdown;

ParticipantDropdown.propTypes = {
  listName: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
    })
  ),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  readOnly: PropTypes.bool,
  handleChanges: PropTypes.func.isRequired,
  programs: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
};

ParticipantDropdown.defaultProps = {
  listName: [{ name: "", value: "" }],
  programs: false,
  readOnly: false,
  helperText: "",
  error: false,
};
