import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import Logo from '../../../../assets/SCCC_LOGO_SMALL.png';
import { TIME } from '../../../../utils/dateController';
import '../../../../css/success.css';

class ReceiptPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      membershipData: '',
      address: '',
      paymentInformation: '',
    };
  }

  componentDidMount() {
    const paymentFromSession = JSON.parse(sessionStorage.getItem('paymentInformation'));

    if (paymentFromSession) {
      const memberFromSession = JSON.parse(sessionStorage.getItem(`verifyMembership_${paymentFromSession.membershipID}`));

      if (memberFromSession) {
        this.setState({
          paymentInformation: paymentFromSession,
          address: memberFromSession.membership.address,
          membershipData: memberFromSession.primaryMember,
        });
      }
    }
  }

  render() {
    const {
      paymentInformation,
      membershipData,
      address,
    } = this.state;
    return (
      <Grid item xs id="receipt">
        <Grid container>
          <Grid item xs>
            <span className="Receipt"> Receipt </span>
          </Grid>
          <Grid item xs className="text-right">
            <img src={Logo} alt="logo" width="140" height="50" />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={1}>
            <i className="fas fa-check-circle fa-2x Success-approved-icon " />
          </Grid>
          <Grid item xs className="text-left Success-approved-text">
            This transaction was successfully processed
          </Grid>
        </Grid>

        <div className="col-12 border-receipt pb-3 pt-3 receipt-main-text mt-3">
            Receipt Details
          <div className="row pl-2">
            <div className="col-12 col-sm-12 col-md ">
              <div className="row mt-2">
                <div className="col-6 col-sm-6 col-md-4 pr-0">
                        Reference#:
                </div>
                <div className="col pl-0 receipt-detail-text">
                  {(paymentInformation) ? paymentInformation.referenceNumber : ''}
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md ">
              <div className="row mt-2">
                <div className="col-6 col-sm-6 col-md-4 pr-0">
                        Payment Method:
                </div>
                <div className="col pl-0 receipt-detail-text">
                  {(paymentInformation) ? paymentInformation.paymentMethod : ''}
                </div>
              </div>
            </div>
          </div>


          <div className="row pl-2">
            <div className="col-12 col-sm-12 col-md">
              <div className="row mt-2">
                <div className="col-6 col-sm-6 col-md-4 pr-0">
                        Name:
                </div>
                <div className="col pl-0 receipt-detail-text">
                  {(membershipData) ? membershipData.firstName : ''}
                  {(membershipData) ? membershipData.lastName : ''}
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md">
              <div className="row mt-2">
                <div className="col-6 col-sm-6 col-md-4 pr-0">
                        Card Number:
                </div>
                <div className="col pl-0 receipt-detail-text">
                        N/A
                </div>
              </div>
            </div>
          </div>


          <div className="row pl-2">
            <div className="col-12 col-sm-12 col-md">
              <div className="row mt-2">
                <div className="col-6 col-sm-6 col-md-4 pr-0 ">
                        Address:
                </div>
                <div className="col pl-0 receipt-detail-text">
                  {(address) ? address.street : ''}
                    ,
                  {(address) ? address.city : ''}
                    ,
                  {(address) ? address.province : ''}
                    ,
                  {(address) ? address.postalCode : ''}
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md">
              <div className="row mt-2">
                <div className="col-6 col-sm-6 col-md-4 pr-0">
                        Card Type:
                </div>
                <div className="col pl-0 receipt-detail-text">
                  {(paymentInformation) ? paymentInformation.cardType : ''}
                </div>
              </div>
            </div>
          </div>

          <div className="row pl-2">
            <div className="col-12 col-sm-12 col-md">
              <div className="row mt-2">
                <div className="col-6 col-sm-6 col-md-4 pr-0">
                        Date & Time:
                </div>
                <div className="col pl-0 receipt-detail-text">
                  {TIME(this.state.paymentInformation.paymentDate, false)}
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md">
              <div className="row mt-2 total-hr">
                <hr />
              </div>
            </div>
          </div>

          <div className="row pl-2">
            <div className="col-12 col-sm-12 col-md">
              <div className="row mt-2">
                <div className="col-6 col-sm-6 col-md-4 pr-0">
                        Category:
                </div>
                <div className="col pl-0 receipt-detail-text">
                  {this.state.paymentInformation.category}
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md">
              <div className="row mt-2">
                <div className="col-6 col-sm-6 col-md-4 pr-0">
                        Total:
                </div>
                <div className="col pl-0 receipt-detail-text">
                    $
                  {' '}
                  {console.log(this.state.paymentInformation)}
                  {this.state.paymentInformation.fee}
.00
                </div>
              </div>
            </div>
          </div>

        </div>
      </Grid>
    );
  }
}

export default ReceiptPrint;
