/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import { taxReturnAPI } from '../../../../API/API-taxReturn';
import TopContent from './topContent';
import CustomTab from '../../../../materialUi/customTab';
import InformationIcon from '../../../../assets/icons/information.png';
import ViewForm from './submissionForm';
import UserDocuments from './userDocuments';
import AdminDocuments from './adminDocuments';
import ViewDocumentDialog from './viewDocumentDialog';
import UploadDialog from './uploadDialog';
import SubmissionFormExport from './submissionFormExport';
import DefaultAlert from '../../../../materialUi/defaultAlert';

function ViewTaxSubmission({ history, match }) {
  const { formID, tabNumber } = match.params;
  const [data, setData] = React.useState(false);
  const [localTabNumber, setTabNumber] = React.useState(tabNumber ? parseInt(tabNumber, 10): 0);
  const [openViewDocuments, setOpenViewDocuments] = React.useState(false);
  const [selectedDocument, setSelectedDocument] = React.useState();
  const [openUploadDialog, setOpenUploadDialog] = React.useState(false);
  const [userDocuments, setUserDocuments] = React.useState([]);
  const [defaultAlert, setDefaultAlert] = React.useState({
    open: false,
    severity: '',
    title: '',
    body: '',
  });

  const handleAlert = (severity, title, body) => {
    setDefaultAlert({
      open: true,
      severity,
      title,
      body,
    });
    setTimeout(() => {
      setDefaultAlert({
        open: false,
        severity,
        title,
        body,
      });
    }, 5000);
  };

  const tabOptions = [
    {
      label: 'Submission',
      value: 0,
    },
    {
      label: 'User Documents',
      value: 1,
    },
    {
      label: 'Admin Documents',
      value: 2,
    },
  ];

  const handleUserDocuments = (responseData) => {
    const taxDocuments = responseData.taxReturnFiles;
    let userDocumentsToSend = taxDocuments;
    if (responseData.directDepositForm) {
      userDocumentsToSend = taxDocuments.concat(responseData.directDepositForm);
    }
    setUserDocuments(userDocumentsToSend);
  };

  const getAllReturnFiles = () => {
    taxReturnAPI.GET.getTaxReturnByFormID(formID).then((response) => {
      console.log(response.data);
      setData(response.data[0]);
      handleUserDocuments(response.data[0]);
    }).catch((error) => {
      console.log(error);
    });
  };

  const updateTaxReturn = (newData) => {
    taxReturnAPI.PUT.adminUpdateOneTaxReturn(newData).then((response) => {
      console.log(response);
      getAllReturnFiles();
      handleAlert('success', 'Success', 'Tax return status successfully updated!');
    }).catch((error) => {
      console.log(error);
      handleAlert('success', 'Server error', 'Please try again later');
    });
  };

  const addDocuments = () => {
    setOpenUploadDialog(true);
  };


  const handleViewDocument = (selectedDoc) => {
    if (selectedDoc.fileType !== '.png'
      && selectedDoc.fileType !== '.jpg'
      && selectedDoc.fileType !== '.jpeg'
    ) {
      // TODO: File name is not correct
      window.location = selectedDoc.filePath;
    } else {
      setSelectedDocument(selectedDoc);
      setOpenViewDocuments(true);
    }
  };

  const handleEmail = () => {
    window.open(
      `mailto:${data.email}?subject=Subject&body=message%20goes%20here`,
      '_blank',
    );
  };

  const removeDocument = (newData, fileName) => {
    taxReturnAPI.PUT.removeOneTaxAdminFile(newData, fileName).then((response) => {
      console.log(response);
      getAllReturnFiles();
      handleAlert('success', 'Success', 'Admin file successfully removed!');
    }).catch((error) => {
      console.log(error);
      handleAlert('success', 'Server error', 'Please try again later');
    });
  };

  const handleDeleteDocument = (index) => {
    const fileName = data.adminDocuments[index].fileEncryptedName;
    data.adminDocuments.splice(index, 1);
    removeDocument(data, fileName);
  };

  const exportTable = () => {
    const table = document.getElementById('table-export').innerHTML;
    const convertTable = table.toString().replace(/<div>/g, '')
      .toString().replace(/<\/div>/g, '')
      .toString()
      .replace(/<table class="table">/g, '')
      .toString()
      .replace(/<\/table>/g, '')
      .toString()
      .replace(/<td>/g, '')
      .toString()
      .replace(/<\/td>/g, ',')
      .toString()
      .replace(/<tr>/g, '')
      .toString()
      .replace(/<\/tr>/g, '\r\n')
      .toString()
      .replace(/<th scope="col">/g, '')
      .toString()
      .replace(/<\/th>/g, ',')
      .toString()
      .replace(/<thead>/g, '')
      .toString()
      .replace(/<\/thead>/g, '')
      .toString()
      .replace(/<tbody>/g, '')
      .toString()
      .replace(/<\/tbody>/g, '')
      .toString()
      .replace(/<\/t>/g, '')
      .toString()
      .replace(/<\/n>/g, '');
    const myLink = document.createElement('a');
    myLink.download = `submission#${match.params.formID}.csv`;
    myLink.href = `data:application/csv,${escape(convertTable)}`;
    myLink.click();
  };

  const handleExport = () => {
    exportTable();
  };

  const handleSetTab = (value) => {
    setTabNumber(value);
    history.push(`/adminPortal/reports/taxes/view/${formID}/${value}`);
  }

  React.useEffect(() => {
    getAllReturnFiles();
  }, []);


  return (
    <div>
      {data && (
        <>
          <div className="table-export" id="table-export">
            <SubmissionFormExport
              data={data}
            />
          </div>
          <Grid container className="questionnaire-wrapper">
            <DefaultAlert
              open={defaultAlert.open}
              severity={defaultAlert.opeseverityn}
              title={defaultAlert.title}
              body={defaultAlert.body}
              top="120px"
              right="33px"
            />
            <TopContent
              data={data}
              tabNumber={localTabNumber}
              updateTaxReturn={updateTaxReturn}
              addDocuments={addDocuments}
              handleExport={handleExport}
            />
            <CustomTab
              tabOptions={tabOptions}
              setTabNumber={(value) => handleSetTab(value)}
              tabNumber={localTabNumber}
            />
          </Grid>
          {localTabNumber === 0 && (
            <ViewForm data={data} />
          )}
          {localTabNumber === 1 && (
            <>
              {data.submissionMethod === 'email' ? (
                <Box className="submissionList-list-status-message submissionList-status-message">
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Box className="pr-2">
                      <img src={InformationIcon} alt="information" />
                    </Box>
                    <Box className="submissionList-list-status-text">
                    This user has selected to e-mail their documents.
                     Please make sure to email them to start process by clicking
                      <button
                        type="button"
                        onClick={handleEmail}
                        className="taxes-mailto"
                      >
                        here
                      </button>
                    </Box>
                  </Grid>
                </Box>
              ) : data.submissionMethod === 'inPerson' ? (
                <Box className="submissionList-list-status-message submissionList-status-message">
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Box className="pr-2">
                      <img src={InformationIcon} alt="information" />
                    </Box>
                    <Box className="submissionList-list-status-text">
                    This user has selected to to drop-off their documents in person. Please make sure to email them to start that process by clicking
                      <button
                        type="button"
                        onClick={handleEmail}
                        className="taxes-mailto"
                      >
                        here
                      </button>
                    </Box>
                  </Grid>
                </Box>
              ) : (
                <UserDocuments
                  data={userDocuments}
                  handleViewDocument={handleViewDocument}
                />
              )}
            </>
          )}
          {localTabNumber === 2 && (
          <AdminDocuments
            data={data.adminDocuments}
            handleViewDocument={handleViewDocument}
            handleDeleteDocument={handleDeleteDocument}
          />
          )}
          <ViewDocumentDialog
            open={openViewDocuments}
            setOpen={() => setOpenViewDocuments(false)}
            document={selectedDocument}
          />
          <UploadDialog
            open={openUploadDialog}
            setOpen={() => setOpenUploadDialog(false)}
            handleCancel={() => setOpenUploadDialog(false)}
            handleUpdateList={getAllReturnFiles}
            data={data}
          />
        </>
      )}
    </div>
  );
}

ViewTaxSubmission.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  userData: PropTypes.shape({
    userData: PropTypes.object,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      formID: PropTypes.string,
    }),
  }).isRequired,
};

export default ViewTaxSubmission;
