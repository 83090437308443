import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/HighlightOff';

CustomChip.propTypes = {
  name: PropTypes.string.isRequired,
  handleFilter: PropTypes.func.isRequired,
};

export default function CustomChip({
  name, handleDelete,
}) {
  return (
    <Box className="custom-chip" name={name} key={name} id={name}>
      {name}
      <button
        type="button"
        label="name"
        className="custom-chip-close"
        onClick={handleDelete}
        tabIndex={0}
      >
        <CloseIcon fontSize="small" style={{ color: 'white', marginBottom: '1px' }} />
      </button>
    </Box>
  );
}
