import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Covid19API } from '../../../../API/API-covid19';
import Header from './components/header';
import CheckInsDataTable from './components/checkInsDataTable';
import '../../../../css/reports.css';

export default class DailyCheckInList extends Component {
  constructor(props) {
    super(props);
    const now = new Date();
    const newEndDate = now.setDate(now.getDate() + 1);
    this.state = {
      allDailyCheckIns: [],
      values: {
        startDate: '',
        endDate: newEndDate,
      },
      messages: {
        startDate: '',
        endDate: '',
      },
    };
  }

  componentDidMount() {
    Covid19API.GET.getAllDailyCheckIns().then((success) => {
      this.setState({ allDailyCheckIns: success.data });
    }).catch((error) => {
      console.log(error);
    });
  }

  handleSelection = (dailyCheckInSelected) => {
    const { history } = this.props;
    history.push(`/adminPortal/reports/dailyCheckIn/${dailyCheckInSelected.dailyCheckInID}/daily`);
    sessionStorage.setItem('dailyCheckIn', JSON.stringify(dailyCheckInSelected));
  }

  handleDate=(name, value, type, requirement = '') => {
    const { values, messages } = this.state;
    values[name] = value;
    messages[name] = '';
    this.setState({ values, messages });
  }

  handleReset = () => {
    const { values, messages } = this.state;
    const now = new Date();
    const newEndDate = now.setDate(now.getDate() + 1);
    values.startDate = '';
    values.endDate = newEndDate;
    messages.startDate = '';
    messages.endDate = '';
    this.setState({ values, messages });
  }


  render() {
    const { history } = this.props;
    const {
      allDailyCheckIns,
      values,
      messages,
    } = this.state;
    return (
      <Grid item xs className="font-family-default">
        <Grid container>
          <Grid item xs={12}>
            <Header
              history={history}
              values={values}
              messages={messages}
              handleReset={this.handleReset}
              handleDate={this.handleDate}
            />
            <CheckInsDataTable
              data={allDailyCheckIns}
              handleClick={(dailyCheckInSelected) => this.handleSelection(dailyCheckInSelected)}
              values={values}
            />
          </Grid>
          <Grid className="table-export" id="el">
            {/* <TableExport
              data={userCheckInData}
              searchItem={search}
              values={values}
              sortStatus={status}
              sortResult={result}
            /> */}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

DailyCheckInList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
