import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid, Box } from '@material-ui/core';
import Button from '../../../../materialUi/btn';
import BackButton from '../../../../materialUi/backButton';
import Alert from '../../../../materialUi/Alerts';
import { CONVERTTIME } from '../../../../utils/dateController';

function TopControl({
  data, userData, history, programID,
}) {
  const { userType, membership } = userData.userData;
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState({
    alertLocation: '',
    alertTitle: 'Error',
    alertBody: '',
    alertClose: 'hidden',
    alertStay: 'CLOSE',
  });

  const displayEditButton = () => {
    if (userType.toLowerCase() === 'super admin' || userType.toLowerCase() === 'program admin') {
      return true;
    }
    return false;
  };

  const isMember = () => {
    if (userType.toLowerCase() === 'member' || userType.toLowerCase() === 'public') {
      return true;
    }
    return false;
  };

  // TODO: Needs to be removed after we merge the adminPortal with scccPortal
  const typeOfPortal = () => {
    if (userType.toLowerCase() !== 'member' && userType.toLowerCase() !== 'public') {
      return 'adminPortal';
    }
    return 'scccPortal';
  };

  const alertMessage = (message) => {
    setAlertContent({
      alertLocation: '',
      alertTitle: 'Error',
      alertBody: message,
      alertClose: 'hidden',
      alertStay: 'CLOSE',
    });
    setOpenAlert(true);
  };

  const basicProgramValidation = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const enrollmentEndDate = new Date(data.enrollmentEndDate);
    enrollmentEndDate.setHours(0, 0, 0, 0);
    const enrollmentStartDate = new Date(data.enrollmentStartDate);
    enrollmentStartDate.setHours(0, 0, 0, 0);

    if (enrollmentStartDate.getTime() > today.getTime()) {
      alertMessage(
        `Program enrolment date starts on ${CONVERTTIME(enrollmentStartDate, true)}`,
      );
      return false;
    }

    if (!data.dropIn && enrollmentEndDate.getTime() < today.getTime()) {
      alertMessage(
        'Program enrolment date has passed.',
      );
      return false;
    }
    return true;
  };

  const publicUserValidation = () => {
    if (userType === 'public') {
      alertMessage(
        'Please login first, then to join the program.',
      );
      return false;
    }
    return true;
  };

  const memberValidation = () => {
    const USER_TYPE = userType.toLowerCase();
    if (userType !== 'member') return true;
    const USER_MEMBERSHIP_CATEGORY = membership.membership.membershipCategory.toLowerCase();
    const USER_STATUS = userData.userData.status.toLowerCase();
    const EXPIRATION_DATE = new Date(membership.membership.expirationDate);

    if (USER_TYPE === 'member') {
      if (USER_STATUS === 'unverified') {
        alertMessage(
          'Please visit reception to verify your membership first.',
        );
        return false;
      }

      if (EXPIRATION_DATE <= new Date()) {
        alertMessage(
          'Please visit reception to verify your membership first.',
        );
        return false;
      }

      if (data.membershipType.toLowerCase() !== 'all') {
        if (data.membershipType.toLowerCase() === 'child and youth') {
          if (USER_MEMBERSHIP_CATEGORY !== 'Child' && USER_MEMBERSHIP_CATEGORY !== 'Youth') {
            alertMessage(
              'Only Child (6-12) and Youth (13-17) membership categories can apply for this program.',
            );
          }
          return false;
        }
        if (data.membershipType !== USER_MEMBERSHIP_CATEGORY) {
          alertMessage(
            `Only membership category ${data.membershipType} can apply for this program.`,
          );
        }
        return false;
      }
    }
    return true;
  };

  const handleRegister = () => {
    if (
      basicProgramValidation()
      && publicUserValidation()
      && memberValidation()
    ) {
      history.push(`/${typeOfPortal()}/programs/programRegistration/${programID}/Program Details`);
    }
  };

  return (
    <Grid container className="programDetails-wrapper">
      <BackButton
        handleClick={() => {
          history.push(`/${typeOfPortal()}/programs/available`);
        }}
        label="Back to Programs List"
      />
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="programDetails-topControl-wrapper"
        >
          <Box>
            <h1 className="programDetails-title">
              {(data) && data.name}
            </h1>
          </Box>
          <Box>
            {displayEditButton() && (
            <Box className="programDetails-topBtn-spacing">
              <Button
                label="EDIT DETAILS"
                onClick={() => history.push(`/${typeOfPortal()}/programs/editProgram/${programID}`)}
                bgColor="white"
                color="#e76f50"
                bgColorHover="#eceff1"
                width={144}
                variant="outlined"
                borderColor="#e76f50"
              />
            </Box>
            )}
            <Button
              label={isMember() ? 'REGISTER' : 'ADD REGISTRANT'}
              onClick={handleRegister}
              bgColor="#e76f50"
              color="white"
              bgColorHover="#ff8c68"
              colorHover="white"
              width={185}
            />
          </Box>
        </Grid>
      </Grid>
      <Alert
        open={openAlert}
        title={alertContent.alertTitle}
        body={alertContent.alertBody}
        stay={alertContent.alertStay}
        leave={alertContent.alertClose}
        location={alertContent.alertLocation}
        close={() => setOpenAlert(false)}
      />
    </Grid>
  );
}

TopControl.propTypes = {
  data: PropTypes.shape(
    {
      name: PropTypes.string,
      dropIn: PropTypes.bool,
      enrollmentEndDate: PropTypes.string,
      enrollmentStartDate: PropTypes.string,
      membershipType: PropTypes.string,
    },
  ).isRequired,
  programID: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  userData: PropTypes.shape({
    userData: PropTypes.shape({
      userType: PropTypes.string,
    }),
  }).isRequired,
};

export default withRouter(TopControl);
