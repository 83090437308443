import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { ProgramsAPI } from '../../../API/API-programs';
import TopControl from './components/topControl';
import Details from './components/details';
import ProgramStaff from './components/programStaff';
import AdditionalInfo from './components/additionalInfo';

import '../../../css/programDetails.css';

function ProgramDetails({ history, match, userData }) {
  const { programID } = match.params;
  const [data, setData] = React.useState();
  const [instructor, setInstructor] = React.useState([]);

  const getProgram = () => {
    ProgramsAPI.GET.getProgramByID(programID).then((response) => {
      const { instructor, program } = response.data;
      setInstructor(instructor);
      setData(program[0]);
    }).catch((error) => {
      console.log(error);
    });
  };

  React.useEffect(() => {
    getProgram();
    window.scroll({ top: 0, behavior: 'smooth' });
  }, [setData]);

  return (
    <Grid item xs className="font-family-default">
      <TopControl
        data={data}
        userData={userData}
        programID={programID}
      />
      <Details
        data={data}
        userData={userData}
      />
      <Grid item xs={12} className="programDetails-spacing">
        <Box className="programDetails-boxes">
          <Grid item xs={12} className="programDetails-boxes-main-title">
            Program Staff:
          </Grid>
          {instructor.length > 0 && (
            instructor.map((item, index) => (
              <ProgramStaff
                data={item}
              />
            ))
          )}
        </Box>
      </Grid>
      <AdditionalInfo
        data={data}
      />
    </Grid>
  );
}
export default ProgramDetails;
