import React from 'react';
import { Grid, Box } from '@material-ui/core';
import Stepper from '../../../../materialUi/stepper';
import Fees from './components/fees';
import PaymentMethod from './components/paymentMethod';
import { membershipAPI } from '../../../../API/API-membership';
import { validateField } from '../../../../utils/fieldsValidation';
import { PRICE } from '../../../../utils/supportFunctions';
import { CardAPI } from '../../../../API/API-Card';
import Alert from '../../../../materialUi/Alerts';
import '../../../../css/payment.css';

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      membershipID: this.props.match.params.membershipID,
      membership: '',
      membershipCategory: sessionStorage.getItem('membershipCategory'),
      openAlert: false,
      alertLocation: '',
      alertTitle: '',
      alertBody: 'ERROR!',
      alertClose: '',
      alertStay: '',
      loading: 'hidden',
      disableBtn: false,
      token: '',

      values: {
        fee: 0,
        paymentMethod: 'Select One',
        cardName: '',
      },
      errors: {
        paymentMethod: '',
        fee: false,
        cardName: '',
        cardNumber: '',
        cvv: '',
        date: '',
      },
      messages: {
        paymentMethod: '',
        fee: '',
        cardName: '',
      },
    };
  }

  async componentDidMount() {
    const {
      values,
      membershipID,
      errors,
      membershipCategory,
    } = this.state;
    // <--- get all family members related with the membership --->
    const changeMembershipFromSession = JSON.parse(sessionStorage.getItem(`changeMembership_${this.props.match.params.membershipID}`));

    if (!sessionStorage.getItem('membershipCategory')) this.props.history.push(`/adminPortal/membership/changeMembershipType/${membershipID}`);
    if (!sessionStorage.getItem('changeMembershipValidation')) this.props.history.push(`/adminPortal/membership/changeMembershipType/${membershipID}`);

    if (changeMembershipFromSession) {
      values.fee = changeMembershipFromSession.membership.fee;
      if (changeMembershipFromSession.membership.paymentMethod) {
        this.handleInputs('paymentMethod', changeMembershipFromSession.membership.paymentMethod, 'dropdown');
      }

      if (changeMembershipFromSession.changeMembershipPaymentValidation) this.props.history.push('/adminPortal/membership/changeMembershipType/success');

      if (changeMembershipFromSession.membership.membershipCategory === sessionStorage.getItem('membershipCategory')) {
        this.setState({
          openAlert: true,
          alertLocation: `/adminPortal/membership/changeMembershipType/${membershipID}`,
          alertTitle: 'ERROR!',
          alertBody: 'Membership Category not allowed, Please select a different Membership Category',
          alertClose: 'CLOSE',
        });
      }
      // if is not validate go back
      if (JSON.parse(sessionStorage.getItem('changeMembershipPayment'))) {
        this.props.history.push('/adminPortal/membership/changeMembershipType/success');
      }
      this.setState({
        membership: changeMembershipFromSession,
        values,
        errors,
      });
    } else {
      membershipAPI.GET.getMembershipByID(membershipID).then((success) => {
        if (success.data.membership.membershipCategory === membershipCategory) {
          this.setState({
            openAlert: true,
            alertLocation: `/adminPortal/membership/changeMembershipType/${membershipID}`,
            alertTitle: 'ERROR!',
            alertBody: 'Membership Category not allowed, Please select a different Membership Category',
            alertClose: 'CLOSE',
          });
        }
        values.fee = PRICE(success.data.membership.membershipType, sessionStorage.getItem('membershipCategory'));
        const membershipTosave = success.data;
        sessionStorage.setItem(`changeMembership_${this.props.match.params.membershipID}`, JSON.stringify(membershipTosave));
        this.setState({
          membershipType: success.data.membership.membershipType,
          values,
          membership: success.data,
        });
      }).catch(() => {
        this.setState({
          openAlert: true,
          alertLocation: '/adminPortal/membership',
          alertTitle: 'User not found!',
          alertBody: 'We could not find the membership. Please try again later!',
          alertClose: 'CLOSE',
        });
      });
    }
  }

  handleInputs=(name, value, type, nameToDisplay = '', index = '') => {
    const {
      values,
      messages,
      errors,
    } = this.state;
    const changeMembershipFromSession = JSON.parse(sessionStorage.getItem(`changeMembership_${this.props.match.params.membershipID}`));

    if (validateField(value, type, nameToDisplay) === true) {
      values[name] = value;
      messages[name] = '';
      errors[name] = false;
      // save data on session
      changeMembershipFromSession.membership[name] = value;
      sessionStorage.setItem(`changeMembership_${this.props.match.params.membershipID}`, JSON.stringify(changeMembershipFromSession));
      this.setState({ values, messages, errors });
    } else {
      values[name] = value;
      messages[name] = validateField(value, type, nameToDisplay);
      errors[name] = true;
      // save data on session
      changeMembershipFromSession.membership[name] = value;
      sessionStorage.setItem(`changeMembership_${this.props.match.params.membershipID}`, JSON.stringify(changeMembershipFromSession));
      this.setState({ values, messages, errors });
    }
  }

  handleCard = (name, value, token) => {
    const { errors } = this.state;
    if (value === true) {
      errors[name] = false;
    }
    this.setState({ errors, token });
  }

  handleSaveOnDB=() => {
    let fieldsvalidationResult = 0;
    const {
      values,
      errors,
      membershipID,
      token,
      messages,
      membershipCategory,
    } = this.state;
    const changeMembershipFromSession = JSON.parse(sessionStorage.getItem(`changeMembership_${this.props.match.params.membershipID}`));
    // eslint-disable-next-line
    for(let key in errors ){
      if (errors[key] === true || errors[key] === '') {
        if (values.paymentMethod === 'Cash') {
          if ((key !== 'cardName') && (key !== 'cardNumber') && (key !== 'cvv') && (key !== 'date')) {
            fieldsvalidationResult++;
          }
        } else {
          fieldsvalidationResult++;
        }
      }
    }
    if (fieldsvalidationResult === 0) {
      // If user selects Card
      if (values.paymentMethod === 'Card') {
        const paymentObj = {
          name: values.cardName,
          amount: values.fee,
          email: JSON.parse(sessionStorage.getItem('membership')).membership.email,
          description: 'Change Membership Type',
          metadata: {
            name: values.cardName,
            membershipID,
            description: 'Change Membership Type',
          },
        };
        this.setState({ disableBtn: true, loading: '' });

        // save card on DB
        CardAPI.Card.cardPayment(token.token, paymentObj).then((success) => {
          console.log(success.data);
          if (success.data.paid) {
            membershipAPI.PUT.changeMembershipCategory(
              membershipID, membershipCategory, paymentToSaveOnDB(this.state),
            ).then((succ) => {
              console.log(succ);
              // save validation
              changeMembershipFromSession.changeMembershipPaymentValidation = true;
              changeMembershipFromSession.changeMembershipValidation = true;
              sessionStorage.removeItem('changeMembershipValidation');
              sessionStorage.setItem(`changeMembership_${this.props.match.params.membershipID}`, JSON.stringify(changeMembershipFromSession));
              sessionStorage.setItem('changeMembershipPayment', JSON.stringify(paymentToSaveOnDB(this.state)));

              this.props.history.push('/adminPortal/membership/changeMembershipType/success');
            }).catch((error) => {
              console.log(error);
              this.setState({
                openAlert: true,
                alertLocation: '/adminPortal/membership',
                alertTitle: 'ERROR!',
                alertBody: 'There is an error in the server. Please try again later!',
                alertClose: 'hidden',
                alertStay: 'CLOSE',
              });
            });
          }
        }).catch((error) => {
          console.log(error);
          // send a message alert
          this.setState({
            disableBtn: false,
            openAlert: true,
            alertTitle: 'Payment Declined!',
            alertBody: 'Please try again later!',
            alertClose: 'hidden',
            alertStay: 'CLOSE',
            loading: 'hidden',
          });
        });
      } else {
        membershipAPI.PUT.changeMembershipCategory(
          membershipID, membershipCategory, paymentToSaveOnDB(this.state),
        ).then((succ) => {
          console.log(succ);
          // save validation
          changeMembershipFromSession.changeMembershipPaymentValidation = true;
          changeMembershipFromSession.changeMembershipValidation = true;
          sessionStorage.removeItem('changeMembershipValidation');
          sessionStorage.setItem(`changeMembership_${this.props.match.params.membershipID}`, JSON.stringify(changeMembershipFromSession));
          sessionStorage.setItem('changeMembershipPayment', JSON.stringify(paymentToSaveOnDB(this.state)));

          this.props.history.push('/adminPortal/membership/changeMembershipType/success');
        }).catch((error) => {
          console.log(error);
          this.setState({
            openAlert: true,
            alertLocation: '/adminPortal/membership',
            alertTitle: 'ERROR!',
            alertBody: 'There is an error in the server. Please try again later!',
            alertClose: 'hidden',
            alertStay: 'CLOSE',
          });
        });
      }
    } else {
      // eslint-disable-next-line
    for(let key in errors ){
        if (errors[key] !== false) {
          errors[key] = true;
          messages[key] = 'Required Field!';
        }
      }
      this.setState({ errors, messages });
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {
      values,
      messages,
      errors,
      displayMembershipCard,
      disableBtn,
      loading,
      openAlert,
      alertLocation,
      alertTitle,
      alertBody,
      alertStay,
      alertClose,
      membershipID,
    } = this.state;
    return (
      <Grid item xs className="font-family-default">
        <Stepper labels={['Registration form', 'Payment', 'Success']} step={0} />
        <Grid container className="payment-spacing">
          <Box className="Back-to-verify-address">
            <Box
              onClick={() => this.props.history.push(`/adminPortal/membership/changeMembershipType/${this.props.match.params.membershipID}`)}
            >
              &lt; Back to Change Membership Type
            </Box>
          </Box>
          <Grid item xs={12} className="Payment-title">Payment</Grid>
          <Fees values={values} membershipID={membershipID} />
          <PaymentMethod
            displayMembershipCard={displayMembershipCard}
            values={values}
            errors={errors}
            handleInputs={this.handleInputs}
            messages={messages}
            handleSaveOnDB={this.handleSaveOnDB}
            handleCard={this.handleCard}
            disableBtn={disableBtn}
            loading={loading}
          />
        </Grid>
        <Alert
          open={openAlert}
          close={() => this.setState({ openAlert: false })}
          location={alertLocation}
          title={alertTitle}
          body={alertBody}
          stay={alertStay}
          leave={alertClose}
        />
      </Grid>
    );
  }
}


// support functions
const paymentToSaveOnDB = function (state) {
  const referenceNumber = Math.floor(100000 + Math.random() * 90000000);
  const paymentToSave = {
    membershipID: state.membershipID,
    status: 'Approved',
    fee: state.values.fee,
    paymentDate: new Date(),
    paymentMethod: state.values.paymentMethod,
    category: 'Change Membership Type',
    cardType: state.values.paymentMethod,
    referenceNumber,
    name: `${JSON.parse(sessionStorage.getItem(`changeMembership_${state.membershipID}`)).primaryMember.firstName} ${JSON.parse(sessionStorage.getItem(`changeMembership_${state.membershipID}`)).primaryMember.lastName}`,
    address: state.membership.membership.address,
  };
  return paymentToSave;
};

export default Payment;
