import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class CustomizedDialogs extends React.Component {
  state = {
    open: false,
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.close(false);
  };

  handleLeave = () => {
    if (this.props.clearSession) {
      sessionStorage.clear();
    }
    window.location = `${this.props.location}`;
  };

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.open !== this.props.open) {
      this.setState({ open: this.props.open });
    }
  }

  render() {
    return (
      <div>
        <Dialog aria-labelledby="customized-dialog-title" open={this.state.open}>
          <DialogContent dividers>
            <h4>
              <Typography gutterBottom>
                <b className="cancel-alert-header">Are you sure?</b>
                <br />
                {this.props.message ? this.props.message : 'Are you sure you want to leave this page before completing your registration? Data you have entered may not be saved. '}
              </Typography>
            </h4>
          </DialogContent>
          <DialogActions>
            <Button className="alert-btns" onClick={this.handleClose}>
              STAY
            </Button>
            <Button className="alert-btns" onClick={this.handleLeave}>
              LEAVE
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default CustomizedDialogs;
