import React, { Component } from 'react';
import { Grid, Box } from '@material-ui/core';
import FamilyMembers from './components/familyMemberForm';
import Stepper from '../../../materialUi/stepper';
import Btn from '../../../materialUi/btn';
import CancelAlert from '../../../materialUi/cancelAlert';
import { validateField } from '../../../utils/fieldsValidation';
import '../../../css/addNewFamilyMember.css';
import { membershipAPI } from '../../../API/API-membership';
import Alert from '../../../materialUi/Alerts';

export default class addFamilyMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAlert: false,
      alertTitle: '',
      alertBody: '',
      alertClose: '',
      familyCheckValidation: false,
      values: {
        firstName1: '',
        lastName1: '',
        dateOfBirth1: '',
        gender1: '',
        relationship1: '',
        phone1: '',
      },
      errors: {
        firstName1: '',
        lastName1: '',
        dateOfBirth1: '',
        gender1: '',
        relationship1: '',
        phone1: false,
      },
      messages: {
        firstName1: '',
        lastName1: '',
        dateOfBirth1: '',
        gender1: '',
        relationship1: '',
        phone1: '',
      },
    };
  }

  componentDidMount() {
    membershipAPI.GET.getMembershipByID(this.props.match.params.membershipID)
      .then((success) => {
        console.log(success);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          openAlert: true,
          alertTitle: 'User not found!',
          alertBody: 'We could not find the membership. Please try again later!',
          alertClose: 'CLOSE',
        });
      });
  }

  handleInputs = (name, value, type, requirement = '') => {
    const { values, messages, errors } = this.state;
    if (validateField(value, type, requirement) === true) {
      values[name] = value;
      messages[name] = '';
      errors[name] = false;
      this.setState({ values, messages, errors });
    } else {
      values[name] = value;
      messages[name] = validateField(value, type, requirement);
      errors[name] = true;
      this.setState({ values, messages, errors });
    }
  };

  // handle family members
  handleFamilyInputs = (name, value, error, memberNumber) => {
    const { values, errors } = this.state;
    const updateName = name + memberNumber;
    values[updateName] = value;
    errors[updateName] = error;
    this.setState({ values, errors });
  };

  handleSubmit = () => {
    this.setState({ familyCheckValidation: true });
    let fieldsvalidationResult = 0;
    const { errors, messages } = this.state;
    // eslint-disable-next-line
    for (let key in errors) {
      // eslint-disable-next-line
      if (errors[key] === true || errors[key] === '') {
        fieldsvalidationResult++;
      }
    }
    if (fieldsvalidationResult === 0) {
      this.props.history.push(`/adminPortal/membership/addFamilyMemberCard/${this.props.match.params.membershipID}`);
      const memberFromSession = JSON.parse(sessionStorage.getItem('addMember1'));
      memberFromSession.addFamilyValidation = true;
      sessionStorage.setItem('addMember1', JSON.stringify(memberFromSession));
    } else {
      // eslint-disable-next-line
      for (let key in errors) {
        if (errors[key] !== false) {
          errors[key] = true;
          messages[key] = 'Required Field!';
        }
      }
      // set errors to true and display all messages
      this.setState({ errors, messages });
      window.scrollTo(0, 0);
    }
  };

  handleEnterPressed = (event) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      // 13 is the enter keycode
      this.handleSubmit();
    }
  };

  render() {
    const { familyCheckValidation, alertClose, openAlert, alertTitle, alertBody, openCancelAlert } = this.state;
    return (
      <Grid item xs className="font-family-default">
        <Stepper labels={['Registration form', 'Membership Card']} step={0} />
        <Grid container className="addNewFamily-spacing">
          <Grid item xs={12}>
            <Box className="addnewFamily-back" onClick={() => this.props.history.push(`/adminPortal/membership/verifyMembership/${this.props.match.params.membershipID}`)}>
              &lt; Back to Verify Membership
            </Box>
          </Grid>
          <Grid item xs={12} className="addNewFamily-title">
            Family Member Registration Form
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} className="addNewFamily-form-spacing" onKeyPress={this.handleEnterPressed}>
            <FamilyMembers handleInputs={this.handleFamilyInputs} numberOfMembers={1} primaryMember={false} familyCheckValidation={familyCheckValidation} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md className="addNewFamily-spacing">
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Box className="addNewFamily-body-text">
                Annual membership is
                <span className="addNewFamily-body-text-highlight">valid for one year from date of joining. </span>
                <br />
                Memberships are not transferable and fees are non-refundable.
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} md className="addNewFamily-spacing">
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              <Box>
                <Btn
                  label="Cancel"
                  bgColor="white"
                  color="black"
                  bgColorHover="#eceff1"
                  colorHover="white"
                  borderColor="white"
                  variant="outlined"
                  width={128}
                  onClick={() => this.setState({ openCancelAlert: true })}
                />
                <CancelAlert open={openCancelAlert} close={() => this.setState({ openCancelAlert: false })} location="/" />
              </Box>
              <Box>
                <Btn label="Next" onClick={() => this.handleSubmit()} bgColor="#e76f50" color="white" bgColorHover="#ff8c68" colorHover="white" width={128} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs className="addNewFamily-spacing">
            <hr />
            <span className="addNewFamily-Bottom-text">
              The Personal Information on this form is collected under the authority of the
              <span className="addNewFamily-Bottom-text-highlight"> City of Toronto Act, 1997 </span>, and Article XI pf Ch. 169, of the
              <span className="addNewFamily-Bottom-text-highlight"> Toronto Municipal Code </span>. The information is used for the purpose pf the program registration, payment, mailings, including
              newsletters/surveys, and aggregate statistical reporting.
            </span>
          </Grid>
        </Grid>
        <Alert open={openAlert} close={() => this.setState({ openAlert: false })} location="/adminPortal/membership" title={alertTitle} body={alertBody} stay="" leave={alertClose} />
      </Grid>
    );
  }
}
