import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid } from '@material-ui/core';
import { BootstrapSelectDropdown } from '../../../../materialUi/bootstrapInputs';
import Language from '../../../../materialUi/languages';

function otherInfo({
  values,
  messages,
  errors,
  handleInputs,
}) {
  const learnedFrom = ['Select One', 'Friend', 'Facebook', 'Instagram', 'NewsLetter'];

  return (
    <Paper className="paper-otherInfo-box">
      <Grid item xs={12} className="Boxes-main-title">Other Info</Grid>
      <Grid item xs={12} className="boxes-spacing">
        <Grid container spacing={3}>
          <Grid item xs={4}>
                Language at Home:
          </Grid>
          <Grid item xs>
            <BootstrapSelectDropdown
              id="language"
              name="language"
              value={values.language}
              list={Language}
              handleChanges={(value) => handleInputs('language', value.target.value, 'not required')}
              message={messages.language}
              error={errors.language}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="boxes-spacing">
        <Grid container spacing={3}>
          <Grid item xs={4}>
          First Learned Centre from:
          </Grid>
          <Grid item xs>
            <BootstrapSelectDropdown
              id="learned"
              name="learned"
              value={values.learned}
              list={learnedFrom}
              handleChanges={(value) => handleInputs('learned', value.target.value, 'not required')}
              message={messages.learned}
              error={errors.learned}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

otherInfo.propTypes = {
  values: PropTypes.shape({
    language: PropTypes.string,
    learned: PropTypes.string,
  }).isRequired,
  messages: PropTypes.shape({
    language: PropTypes.string,
    learned: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    language: PropTypes.bool,
    learned: PropTypes.bool,
  }).isRequired,
  handleInputs: PropTypes.func.isRequired,
};

export default otherInfo;
