import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import MenuIcon from '../../../../materialUi/menuIcon';
import Btn from '../../../../materialUi/btn';
import Filter from './filter';
import DropdownIcon from '../../../../assets/icons/menu.png';
import '../../../../css/submitedAttendances.css';

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayFilter: false,
    };
  }

  handleDisplayFilter = () => {
    const { displayFilter } = this.state;
    const { handleReset } = this.props;
    if (displayFilter) {
      this.setState({ displayFilter: false });
      // reset filter
      handleReset('');
    } else {
      this.setState({ displayFilter: true });
    }
  };

  render() {
    const { handleBack, handleAddNewAttendance, programName, handleExport, handleDate, messages, values, handleReset } = this.props;

    const { displayFilter } = this.state;
    const options = ['Download CSV', 'Print'];

    return (
      <Grid container className="submitedAttendance-spacing">
        <Grid item xs={12}>
          <Grid className="submitedAttendance-back" onClick={handleBack} onKeyPress={handleBack} role="button" tabIndex={0}>
            &lt; Back to Registrations List
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} xl={6}>
          <h1 className="submitedAttendance-header">{programName}</h1>
        </Grid>
        <Grid item xs={12} md={8} xl={6}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Grid className="submitedAttendance-btn-createNew">
              <Btn label="Create New" onClick={handleAddNewAttendance} bgColor="#e76f50" color="white" bgColorHover="#ff8c68" colorHover="white" width={128} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs className="submitedAttendance-filter-space">
              <Box onClick={() => this.handleDisplayFilter()} role="button" tabIndex={0} onKeyPress={() => this.handleDisplayFilter()} className="submitedAttendance-filter">
                <img className="Filter" src={DropdownIcon} alt="Dropdown icon" />
                Filter
              </Box>
            </Grid>
            <Grid item xs>
              <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <MenuIcon list={options} onClick={(value) => handleExport(value)} />
              </Grid>
            </Grid>
            {displayFilter ? <Filter handleDate={handleDate} values={values} messages={messages} handleReset={handleReset} /> : ''}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

Header.propTypes = {
  handleBack: PropTypes.func.isRequired,
  handleAddNewAttendance: PropTypes.func.isRequired,
  handleExport: PropTypes.func.isRequired,
  programName: PropTypes.string.isRequired,
  handleDate: PropTypes.func.isRequired,
  values: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  messages: PropTypes.shape({
    startDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  }).isRequired,
  handleReset: PropTypes.func.isRequired,
};
