import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { ProgramAttendance } from '../../../../API/API-programAttendance';
import Checkbox from '../../../../materialUi/checkBox';
import '../../../../css/registrationList.css';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default class DeleteParticipant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refund: false,
    };
  }

  handleDelete=() => {
    const {
      data,
    } = this.props;
    const { refund } = this.state;
    data.refund = refund;
    if (data.weeklySchedule) {
    // TODO: Needs refactoring
      let multipleRegistration = false;
      data.weeklySchedule.forEach((week) => {
        if (week._id !== data.selectedWeek._id) {
          week.familyMembers.forEach((familyMember) => {
            if (familyMember._id === data.familyMemberID) {
              multipleRegistration = true;
              return true;
            }
          });
        }
      });
      data.multipleRegistration = multipleRegistration;
    }
    ProgramAttendance.PUT.deleteProgramAttendee(data.programID, data).then((success) => {
      console.log(success);
      window.location.reload();
    }).catch((error) => {
      console.log(error);
    });
  }

  render() {
    const {
      open,
      close,
      data,
    } = this.props;
    const { refund } = this.state;
    return (
      <Grid className="font-family-default">
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth="sm"
          fullWidth
        >
          <DialogContent dividers>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className="registrationList-delete-body"
            >
              <Box className="settings-delete-title">
                Confirmation
              </Box>
              <Grid>
                <Box onClick={() => close()} role="button"><i className="fas fa-times close-icon" /></Box>
              </Grid>
            </Grid>
            <Grid container className="registrationList-delete-body root">
              <Box className="registrationList-delete-body">
                Are you sure you want to Delete
                {' '}
                <b>{(data) && data.name}</b>
                ?
                {' '}
              </Box>
              {!data.fee ? (
                <>
                </>
              ) : (
                <Grid item xs={12} className="registrationList-checkbox">
                  <Checkbox value={refund} id="refund" message="Refund Payment" click={(value) => this.setState({ refund: value })} />
                </Grid>
              )}
            </Grid>

          </DialogContent>
          <DialogActions>
            <Grid className="registrationList-btns-body-delete">
              <Button onClick={close} className="alert-btns">
                Cancel
              </Button>
              <Button onClick={this.handleDelete} className="alert-btns">
                Delete
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      </Grid>

    );
  }
}


DeleteParticipant.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  close: PropTypes.func.isRequired,
};
