import React from 'react';
import PropTypes from 'prop-types';
import { CONVERTTIME, TIMETOAMPM } from '../../../../utils/dateController';


function createData(membershipDB_id, membershipID, firstName, lastName, email, phone, registrationDate, status) {
  return {
    membershipDB_id, membershipID, firstName, lastName, email, phone, registrationDate, status,
  };
}

export default function ProgramsLisTableExport({
  data,
  sortSeason,
  sortSearch,
  sortAges,
}) {
  const programsData = [];


  for (var i = 0; i < data.length; i++) {
    const duration = `${CONVERTTIME(data[i].durationStartDate, false)} - ${CONVERTTIME(data[i].durationEndDate, false)}`;
    const days = data.days && data.days.length > 0
      ? data.days
        .map((i) => i.substring(0, 3))
        .toString()
        .replace(/,/g, '-')
      : 'Variable';
    const time = data[i].programStartTime || data[i].weeklyStartTime ? `${TIMETOAMPM(data[i].programStartTime || data[i].weeklyStartTime)} - ${TIMETOAMPM(data[i].programEndTime || data[i].weeklyEndTime)}` : 'variable';
    // filter Starts
    if (sortSearch) {
      // if search field value
      if (data[i].name.toLowerCase().includes(sortSearch.toLowerCase())) {
        // if sort ages is set within search
        if (sortAges.length > 0) {
          // eslint-disable-next-line
      sortAges.map(age=>{
            if (data[i].ages.toLowerCase() === age.toLowerCase()) {
              // if sort seasons is set within search and ages
              if (sortSeason.length > 0) {
                sortSeason.map((seasons) => {
                  if (data[i].season.toLowerCase() === seasons.toLowerCase()) {
                    return programsData.push(
                      createData(data[i].programID, data[i].name, data[i].ages, days,
                        time, duration, data[i].fee, data[i].numberOfSpots, data[i].programAttendance, data[i].programStartTime || data[i].weeklyStartTime, data[i].durationEndDate, data[i].season),
                    );
                  }
                  return programsData.push(
                    createData(data[i].programID, data[i].name, data[i].ages, days,
                      time, duration, data[i].fee, data[i].numberOfSpots, data[i].programAttendance, data[i].programStartTime || data[i].weeklyStartTime, data[i].durationEndDate, data[i].season),
                  );
                });
              } else {
                programsData.push(
                  createData(data[i].programID, data[i].name, data[i].ages, days,
                    time, duration, data[i].fee, data[i].numberOfSpots, data[i].programAttendance, data[i].programStartTime || data[i].weeklyStartTime, data[i].durationEndDate, data[i].season),
                );
              }
            }
            return 'Not Found!';
          });
        } else {
          // if sort seasons is set
          if (sortSeason.length > 0) {
            // eslint-disable-next-line
        sortSeason.map(seasons=>{
              if (data[i].season.toLowerCase() === seasons.toLowerCase()) {
                return programsData.push(
                  createData(data[i].programID, data[i].name, data[i].ages, days,
                    time, duration, data[i].fee, data[i].numberOfSpots, data[i].programAttendance, data[i].programStartTime || data[i].weeklyStartTime, data[i].durationEndDate, data[i].season),
                );
              }
              return 'Not Found!';
            });
          } else {
            programsData.push(
              createData(data[i].programID, data[i].name, data[i].ages, days,
                time, duration, data[i].fee, data[i].numberOfSpots, data[i].programAttendance, data[i].programStartTime || data[i].weeklyStartTime, data[i].durationEndDate, data[i].season),
            );
          }
        }
      }
    } else {
      // if sort ages is set
      if (sortAges.length > 0) {
      // eslint-disable-next-line
      sortAges.map(age=>{
          if (data[i].ages.toLowerCase() === age.toLowerCase()) {
          // if sort season is set within ages
            if (sortSeason.length > 0) {
              sortSeason.map((seasons) => {
                if (data[i].season.toLowerCase() === seasons.toLowerCase()) {
                  return programsData.push(
                    createData(data[i].programID, data[i].name, data[i].ages, days,
                      time, duration, data[i].fee, data[i].numberOfSpots, data[i].programAttendance, data[i].programStartTime || data[i].weeklyStartTime, data[i].durationEndDate, data[i].season),
                  );
                }
                return 'Not Found!';
              });
            } else {
              programsData.push(
                createData(data[i].programID, data[i].name, data[i].ages, days,
                  time, duration, data[i].fee, data[i].numberOfSpots, data[i].programAttendance, data[i].programStartTime || data[i].weeklyStartTime, data[i].durationEndDate, data[i].season),
              );
            }
          }
          return 'Not Found!';
        });
      } else {
        // if sort seasons is set
        if (sortSeason.length > 0) {
        // eslint-disable-next-line
        sortSeason.map(seasons=>{
            if (data[i].season.toLowerCase() === seasons.toLowerCase()) {
              return programsData.push(
                createData(data[i].programID, data[i].name, data[i].ages, days,
                  time, duration, data[i].fee, data[i].numberOfSpots, data[i].programAttendance, data[i].programStartTime || data[i].weeklyStartTime, data[i].durationEndDate, data[i].season),
              );
            }

            return 'Not Found!';
          });
        } else {
          programsData.push(
            createData(data[i].programID, data[i].name, data[i].ages, days,
              time, duration, data[i].fee, data[i].numberOfSpots, data[i].programAttendance, data[i].programStartTime || data[i].weeklyStartTime, data[i].durationEndDate, data[i].season),
          );
        }
      }
    }
  }

  return (
    <div>

      <table className="table">
        <thead>
          <tr>
            <th scope="col">Program Name</th>
            <th scope="col">Ages</th>
            <th scope="col">Season</th>
            <th scope="col">Days</th>
            <th scope="col">Time</th>
            <th scope="col">Duration</th>
            <th scope="col">Spots Left</th>
          </tr>
        </thead>
        <tbody>


          {programsData.map((value, index) => (
            <tr key={index}>
              <td>
                {value.membershipID}
              </td>
              <td>
                {value.firstName}
              </td>
              <td>
                {value.lastName}
              </td>
              <td>
                {value.email}
              </td>
              <td>
                {value.phone}
              </td>
              <td>
                {CONVERTTIME(value.registrationDate, true)}
              </td>
              <td>
                {value.status}
              </td>
            </tr>
          ))}

        </tbody>
      </table>

    </div>
  );
}


ProgramsLisTableExport.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  sortSeason: PropTypes.arrayOf(PropTypes.string).isRequired,
  sortAges: PropTypes.arrayOf(PropTypes.string).isRequired,
  sortSearch: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
};
