import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function StatsOverview(props) {
  
  const {
    dailyCheckIn, negativePreScreening, positivePreScreening,
  } = props.data;

  return (
    <Grid container className="root dashboard-tabs-spacing">
      <Grid item xs={12} className="dashboard-tabs-title"> Stats Overview</Grid>

      <Grid container spacing={3}>
        <Grid item xs>
          <Paper className="dashboard-tabs-paper" style={{ height: '150px' }}>
            <Grid container>
              <Grid item xs={12} className="dashboard-tabs-box-title">
                Negative Pre-Screening
              </Grid>
              <Grid item xs={6} className="dashboard-tabs-box-text">
                {negativePreScreening}
              </Grid>
              <Grid item xs={6} className="dashboard-tabs-box-subText" />
              <Grid item xs={12} className="dashboard-tabs-box-bottom" />
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper className="dashboard-tabs-paper" style={{ height: '150px' }}>
            <Grid container>
              <Grid item xs={12} className="dashboard-tabs-box-title">
              Positive Pre-Screenings
              </Grid>
              <Grid item xs={6} className="dashboard-tabs-box-text">
                {positivePreScreening}
              </Grid>
              <Grid item xs={6} className="dashboard-tabs-box-subText" />
              <Grid item xs={12} className="dashboard-tabs-box-bottom" />
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper className="dashboard-tabs-paper" style={{ height: '150px' }}>
            <Grid container>
              <Grid item xs={12} className="dashboard-tabs-box-title">
              Total People in Facility
              </Grid>
              <Grid item xs={6} className="dashboard-tabs-box-text">
                {dailyCheckIn.capacity ?  dailyCheckIn.capacity - dailyCheckIn.availableSpots : 0}
              </Grid>
              <Grid item xs={6} className="dashboard-tabs-box-text text-right">
                {dailyCheckIn.capacity && (
                  <>
                    /
                    {dailyCheckIn.capacity}
                  </>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
}

StatsOverview.propTypes = {
  dailyCheckIn: PropTypes.shape({
    availableSpots: PropTypes.number,
    capacity: PropTypes.number,
  }),
  negativePreScreening: PropTypes.number.isRequired,
  positivePreScreening: PropTypes.number.isRequired,
};

StatsOverview.defaultProps = {
  dailyCheckIn: {
    availableSpots: 0,
    capacity: 0,
  },
};
