import React, { Component } from 'react';
import {
  CardNumberElement, CardCvcElement, CardExpiryElement, injectStripe,
} from 'react-stripe-elements';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import TextField from '../../../../../materialUi/textField';
import '../../../../../css/payment.css';


class checkoutForm extends Component {

  async handleCard(name, value) {
    await this.props.stripe.createToken({ name: 'Igor' }).then((success) => {
      this.props.handleCard(name, value, success);
    });
    this.props.handleInputs(name, value, 'name', name);
  }


  render() {
    const {
      values,
      messages,
      errors,
      handleInputs,
    } = this.props;
    return (
      <Grid container>
        <Grid item xs={12} className="checkout-spacing">
          <TextField
            label="Name on Card"
            id="Name on Card"
            value={values.cardName}
            handleChanges={(value) => handleInputs('cardName', value.target.value, 'name', 'Card Name')}
            readOnly={false}
            error={errors.cardName}
            helperText={messages.cardName}
          />
        </Grid>
        <Grid item xs={12} className="checkout-spacing">
          <CardNumberElement
            onChange={(value) => this.handleCard('cardNumber', value.complete)}
            placeholder="Card Number"
            style={{ base: { fontSize: '16px', fontFamily: "'Work Sans', sans-serif", color: 'black' } }}
          />
          {
            errors.cardNumber
              ? <Box className="error-stripe">Required Field</Box>
              : ''
          }
        </Grid>
        <Grid item xs className="checkout-spacing">
          <CardExpiryElement
            onChange={(value) => this.handleCard('date', value.complete)}
            placeholder="Expire Date"
          />
          {
            errors.date
              ? <Box className="error-stripe">Required Field</Box>
              : ''
          }
        </Grid>
        <Grid item xs className="checkout-ccv-spacing">
          <CardCvcElement
            onChange={(value) => this.handleCard('cvv', value.complete)}
            placeholder="CVV Code"
          />
          {
            errors.cvv
              ? <Box className="error-stripe">Required Field</Box>
              : ''
          }
        </Grid>
      </Grid>
    );
  }
}


checkoutForm.propTypes = {
  handleCard: PropTypes.func.isRequired,
  handleInputs: PropTypes.func.isRequired,
  values: PropTypes.shape({
    cardName: PropTypes.string,
  }).isRequired,
  messages: PropTypes.shape({
    cardName: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    cardName: PropTypes.bool,
    cvv: PropTypes.bool,
    date: PropTypes.bool,
    cardNumber: PropTypes.bool,
  }).isRequired,

};

export default injectStripe(checkoutForm);
