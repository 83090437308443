import React from "react";
import PropTypes from "prop-types";
import { Grid, Box } from "@material-ui/core";
import DatePicker from "../../../../materialUi/datePicker";
import "../../../../css/attendanceList.css";

export default function Filter({ handleDate, values, messages, handleReset }) {
  return (
    <Grid item xs={12} style={{ marginTop: "10px" }}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Box className="submitedAttendance-filter-date">
          <DatePicker
            label="Start Date"
            id="startDate"
            name="Start Date"
            value={values.startDate}
            handleChanges={(value) => handleDate("startDate", value, "date")}
            helperText={messages.startDate}
            error={messages.startDate !== ""}
            readOnly={false}
          />
        </Box>
        <Box className="submitedAttendance-filter-date-to">To</Box>
        <Box className="submitedAttendance-filter-date">
          <DatePicker
            label="End Date"
            id="endDate"
            name="End Date"
            value={values.endDate}
            handleChanges={(value) => handleDate("endDate", value, "date")}
            helperText={messages.endDate}
            error={messages.endDate !== ""}
            readOnly={false}
            type="date"
          />
        </Box>
        <Box
          className="submitedAttendance-filter-restart"
          onClick={handleReset}
        >
          <i className="fas fa-redo-alt" />
        </Box>
      </Grid>
    </Grid>
  );
}

Filter.propTypes = {
  values: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  messages: PropTypes.shape({
    startDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    endDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
  }).isRequired,
  handleDate: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
};
