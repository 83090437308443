import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { userAPI } from '../../../API/API-user';
import '../../../css/settings.css';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class DeleteUser extends React.Component {

  handleDelete=() => {
    const {
      userID,
    } = this.props;
    userAPI.PUT.delete(userID).then((success) => {
      console.log(success);
      window.location.reload();
    }).catch((error) => {
      console.log(error);
    });
  }

  render() {
    const {
      open,
      close,
      userIndex,
      usersData,
    } = this.props;

    return (
      <Grid className="font-family-default">
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth="sm"
          fullWidth
        >
          <DialogContent dividers>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className="settings-delete-body"
            >
              <Box className="settings-delete-title">
                Delete
              </Box>
              <Grid>
                <Box onClick={() => close()} role="button"><i className="fas fa-times close-icon" /></Box>
              </Grid>
            </Grid>
            <Grid container className="settings-delete-body">
              <Box>
                Are you sure you want to Delete
                {' '}
                <b>{(usersData[userIndex]) && usersData[userIndex].firstName}</b>
                {' '}
                <b>{(usersData[userIndex]) && usersData[userIndex].lastName}</b>
                ?
                {' '}
              </Box>
            </Grid>

          </DialogContent>
          <DialogActions>
            <Grid className="settings-btns-body-delete">
              <Button onClick={close} className="alert-btns">
                Cancel
              </Button>
              <Button onClick={this.handleDelete} className="alert-btns">
                Delete
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      </Grid>

    );
  }
}


DeleteUser.propTypes = {
  open: PropTypes.bool.isRequired,
  userID: PropTypes.string.isRequired,
  userIndex: PropTypes.number.isRequired,
  usersData: PropTypes.arrayOf(PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  })).isRequired,
  close: PropTypes.func.isRequired,
};


export default DeleteUser;
