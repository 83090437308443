import axios from 'axios';
import baseURL from './baseURL';
import TOKEN from './Frontend-Token';

export const ProgramWeekScheduleAPI = {
  GET: {
    getProgramWeekScheduleByProgramID(programID) {
      return axios.get(`${baseURL}api/getProgramWeekScheduleByProgramID/${programID}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
  },
};

export default ProgramWeekScheduleAPI;
