import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';

function StatsOverview(props) {
  
  const {
    newMemberships,
    newMembershipsLastMonth,
    fees,
    feesLastMonth,
    activeMembersThisMonth,
  } = props.data;

  const getPercentage = (increaseValue, originalValue) => {
    if (increaseValue === 0 && originalValue === 0) return 0;
    if (increaseValue === originalValue) return 0;
    if (increaseValue > originalValue) {
      return parseFloat(Math.round((increaseValue - originalValue) * 100)).toFixed();
    }
    if (increaseValue < originalValue) {
      return parseFloat(Math.round((increaseValue - originalValue) * 100)).toFixed();
    }

  };

  return (
    <Grid container className="root dashboard-tabs-spacing">
      <Grid item xs={6} className="dashboard-tabs-title">
        {' '}
        Stats Overview
      </Grid>
      <Grid item xs={6} className="dashboard-tabs-datashow">
        {' '}
        Showing data for this month
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs>
          <Paper className="dashboard-tabs-paper">
            <Grid container>
              <Grid item xs={12} className="dashboard-tabs-box-title">
                New Memberships
              </Grid>
              <Grid item xs={6} className="dashboard-tabs-box-text">
                {newMemberships}
              </Grid>
              <Grid item xs={6} className="dashboard-tabs-box-subText">
                {getPercentage(newMemberships, newMembershipsLastMonth) > 0 ? (
                  <i className="fas fa-long-arrow-alt-up dashboard-tabs-box-arrowUp" />
                ) : (
                  <i className="fas fa-long-arrow-alt-down dashboard-tabs-box-arrowDown" />
                )}
                <span
                  className={
                    getPercentage(newMemberships, newMembershipsLastMonth) > 0
                      ? 'dashboard-tabs-box-arrowUp'
                      : 'dashboard-tabs-box-arrowDown'
                  }
                >
                  {getPercentage(newMemberships, newMembershipsLastMonth)}%
                </span>
              </Grid>
              <Grid item xs={12} className="dashboard-tabs-box-bottom">
                Last Month
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper className="dashboard-tabs-paper">
            <Grid container>
              <Grid item xs={12} className="dashboard-tabs-box-title">
                Total Earnings
              </Grid>
              <Grid item xs={6} className="dashboard-tabs-box-text">
                ${fees.toFixed(2)}
              </Grid>
              <Grid item xs={6} className="dashboard-tabs-box-subText">
                {getPercentage(fees, feesLastMonth) > 0 ? (
                  <i className="fas fa-long-arrow-alt-up dashboard-tabs-box-arrowUp" />
                ) : (
                  <i className="fas fa-long-arrow-alt-down dashboard-tabs-box-arrowDown" />
                )}
                <span
                  className={
                    getPercentage(fees, feesLastMonth) > 0
                      ? 'dashboard-tabs-box-arrowUp'
                      : 'dashboard-tabs-box-arrowDown'
                  }
                >
                  {getPercentage(fees, feesLastMonth)}%
                </span>
              </Grid>
              <Grid item xs={12} className="dashboard-tabs-box-bottom">
                Last Month
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper className="dashboard-tabs-paper" style={{ height: '150px' }}>
            <Grid container>
              <Grid item xs={12} className="dashboard-tabs-box-title">
                Active Members
              </Grid>
              <Grid item xs={6} className="dashboard-tabs-box-text">
                {activeMembersThisMonth}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
}

StatsOverview.propTypes = {
  newMemberships: PropTypes.number.isRequired,
  newMembershipsLastMonth: PropTypes.number.isRequired,
  fees: PropTypes.number.isRequired,
  feesLastMonth: PropTypes.number.isRequired,
  activeMembersThisMonth: PropTypes.number.isRequired,
};

export default StatsOverview;
