import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Loading from './loading';
import './style.css';

export default function ContainedButtons(props) {
  const useStyles = makeStyles({
    root: {
      position: 'relative',
      background: props.bgColor,
      borderWidth: 1,
      borderRadius: 3,
      color: props.color,
      height: props.height ? props.height : 35,
      fontSize: props.fontSize ? props.fontSize : 16,
      fontWeight: 500,
      width: props.width,
      padding: '0 0px',
      borderColor: props.borderColor,
      fontFamily: 'Work Sans, sans-serif',
      '&:hover': {
        backgroundColor: props.bgColorHover,
        borderColor: props.colorHover,
        color: props.colorHover,
      },
      '&:focus': {
        outlineColor: props.outlineColor ? props.outlineColor : '#e76f50',
        outlineOffset: '4px'
      },
    },
  });

  let icon;
  if (props.label === 'LOGOUT') {
    icon = <i className="ml-2 fas fa-sign-out-alt" />;
  }
  const classes = useStyles();
  let variant = 'outlined';
  if (props.variant !== 'outlined') {
    variant = 'contained';
  }
  return (
    <Button
      variant={variant}
      size="large"
      className={classes.root}
      type={props.type ? props.type : "submit"}
      disabled={props.disable}
      onClick={props.onClick}
      aria-label={props.ariaLabel}
      id={props.addId}
    >
      {props.loading && (
        <span className="button-loading">
          <Loading size="30px" />
        </span>
      )}
      {props.label}
      {icon}
    </Button>
  );
}

/* Implementation example
  <Btn
    label="View"
    onClick={() => handleClick(row)}
    bgColor="#e76f50"
    color="white"
    bgColorHover="#ff8c68"
    colorHover="#ff8c68"
    width={123}
    height={24}
    fontSize={14}
  />

*/
