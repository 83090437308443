import React from 'react';
import { Grid, Box } from '@material-ui/core';
import Button from '../../materialUi/btn';
import Membership from './membership/membership';
import Programs from './programs/programs';
import Covid19 from './covid19/covid19';
import PropTypes from 'prop-types';
import './dashboard.css';

function Dashboard(props) {
  const { tab } = props.match.params;
  const { userData, history } = props;
  const { userType } = userData.userData;

  const [tabNumber, setTabNumber] = React.useState(tab ? parseInt(tab, 10) : 0);

  const validateSuperAdmin = (userType) => {
    return userType.toLowerCase() === 'super admin';
  };

  const validateProgramAdmin = (userType) => {
    return userType.toLowerCase() === 'program admin';
  };

  const validateMembershipAdmin = (userType) => {
    return userType.toLowerCase() === 'membership admin';
  };

  const displayMembership = () => {
    if (validateSuperAdmin(userType)) {
      return true;
    }
    if (validateMembershipAdmin(userType)) {
      return true;
    }
    return false;
  };

  const displayPrograms = () => {
    if (validateSuperAdmin(userType)) {
      return true;
    }
    if (validateProgramAdmin(userType)) {
      return true;
    }
    return false;
  };

  const displayCovid = () => {
    if (validateSuperAdmin(userType)) {
      return true;
    }
    return false;
  };

  const buttons = [
    {
      number: 0,
      label: 'ADD NEW MEMBER',
      location: '/adminPortal/membership/register',
      width: 180,
    },
    {
      number: 1,
      label: 'CREATE NEW PROGRAM',
      location: '/adminPortal/programs/addNewProgram/programInformation',
      width: 220,
    },
    {
      number: 2,
      label: 'VIEW FULL LIST',
      location: '/adminPortal/reports/checkInHistory',
      width: 150,
    },
  ];

  const handleQuickAction = (link) => {
    sessionStorage.clear();
    history.push(link)
  };

  React.useEffect(() => {
    setTabNumber(parseInt(tab ? tab : 0));
    if (userData.userData.userType.toLowerCase() === 'program admin') setTabNumber(1);
  }, [userData, tab]);

  return (
    <Grid item xs className="root dashboard-background">
      <Grid container className="root dashboard-spacing">
        <Grid item xs className="dashboard-title">
          Dashboard
        </Grid>
        <Grid item xs>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Box>
              {buttons.map((item) => (
                <>
                  {tabNumber === item.number && (
                    <Button
                      label={item.label}
                      onClick={() => handleQuickAction(item.location)}
                      bgColor="#e76f50"
                      color="white"
                      bgColorHover="#ff8c68"
                      colorHover="white"
                      width={item.width}
                    />
                  )}
                </>
              ))}
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            {displayMembership() && (
              <>
                <Box
                  className={tabNumber === 0 ? 'dashboard-activeTab' : 'dashboard-deactiveTab'}
                  onClick={() => history.push('/adminPortal/dashboard/0')}
                  onKeyPress={() => history.push('/adminPortal/dashboard/0')}
                  role="button"
                  tabIndex={0}
                >
                  Membership
                </Box>
              </>
            )}
            {displayMembership() && displayPrograms() && <Box className="dashboard-tabSpacing">|</Box>}
            {displayPrograms() && (
              <Box
                className={tabNumber === 1 ? 'dashboard-activeTab' : 'dashboard-deactiveTab'}
                onClick={() => history.push('/adminPortal/dashboard/1')}
                onKeyPress={() => history.push('/adminPortal/dashboard/1')}
                role="button"
                tabIndex={0}
              >
                Programs
              </Box>
            )}
            {displayCovid() && (
              <>
                <Box className="dashboard-tabSpacing">|</Box>
                <Box
                  className={tabNumber === 2 ? 'dashboard-activeTab' : 'dashboard-deactiveTab'}
                  onClick={() => history.push('/adminPortal/dashboard/2')}
                  onKeyPress={() => history.push('/adminPortal/dashboard/2')}
                >
                  COVID Check-in
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>

      {tabNumber === 0 && <Membership />}
      {tabNumber === 1 && <Programs />}
      {tabNumber === 2 && <Covid19 />}
    </Grid>
  );

}

export default Dashboard;

Dashboard.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      tab: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  userData: PropTypes.shape({
    userType: PropTypes.string,
  }).isRequired,
};
