import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import { FamilyAPI } from '../../../API/API-familyMembers';
import Header from './components/header';
import MembersTable from './components/membersTable';
import TableExport from './components/tableExport';
import '../../../css/reports.css';

export default class MembersReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      membersData: [],
      ageGroup: [],
      status: [],
      selectedItems: [],
      search: '',
      values: {
        preSchool: false,
        child: false,
        youth: false,
        adult: false,
        senior: false,
        active: false,
        unverified: false,
        expired: false,
        expiring: false,
      },
    };
  }

  componentDidMount() {
    FamilyAPI.GET.getFamilyMembersAndMembership()
      .then((success) => {
        this.setState({ membersData: success.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleSelection = (membershipID) => {
    const { history } = this.props;
    history.push(`/adminPortal/membership/verifyMembership/${membershipID}/history`);
  };

  downloadCSV = () => {
    const separator = ','
    var rows = document.querySelectorAll('table#' + 'membership_table' + ' tr');
    // Construct the CSV file
    var csv = [];
    for (var i = 0; i < rows.length; i++) {
        var row = [], cols = rows[i].querySelectorAll('td, th');
        for (var j = 0; j < cols.length; j++) {
            var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
            data = data.replace(/"/g, '""');
            data = data.replace('↑', '');
            data = data.replace('↓', '');
            row.push('"' + data + '"');
        }
        csv.push(row.join(separator));
    }
    var csv_string = csv.join('\n');

    // Download CSV
    var filename = 'membership-list';
    var link = document.createElement('a');
    link.style.display = 'none';
    link.setAttribute('target', '_blank');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  handleExport = (value) => {
    if (value === 'Print') {
      const printContent = document.getElementById('el').innerHTML;
      document.body.innerHTML = printContent;
      window.print();
      window.location = '/adminPortal/reports/membersReport';
    } else {
      this.downloadCSV()
    
    }
  };

  handleFilter = (name, value, filterName, selectedItem) => {
    const { values, selectedItems } = this.state;
    if (value === true) {
      values[name] = value;
      this.setState({
        values,
        [filterName]: this.state[filterName].concat([selectedItem]),
        selectedItems: selectedItems.concat([
          <Box className="programsList-selectedItems" key={selectedItem} name={name} id={filterName}>
            {selectedItem}
            <i
              label="filterItems"
              className="far fa-times-circle ml-2 "
              onClick={() => this.handleFilter(name, false, filterName, selectedItem)}
              onKeyPress={() => this.handleFilter(name, false, filterName, selectedItem)}
              role="button"
              tabIndex={0}
            />
          </Box>,
        ]),
      });
    } else {
      // remove filter
      const updateArray = this.state[filterName];
      for (let i = 0; i < updateArray.length; i++) {
        if (updateArray[i] === selectedItem) {
          updateArray.splice(i, 1);
        }
      }

      // remove selectedItems
      const updateSelectedItems = this.state.selectedItems;
      for (let index = 0; index < updateSelectedItems.length; index++) {
        if (updateSelectedItems[index].key === selectedItem) {
          updateSelectedItems.splice(index, 1);
        }
      }

      values[name] = value;
      this.setState({ values, [filterName]: updateArray, selectedItems: updateSelectedItems });
    }
  };

  resetFilters = () => {
    const { values } = this.state;
    Object.keys(values).forEach((key) => {
      values[key] = '';
    });
    this.setState({
      values,
      ageGroup: [],
      status: [],
      selectedItems: [],
      search: '',
    });
  };

  render() {
    const { membersData, values, ageGroup, selectedItems, search, status } = this.state;

    return (
      <Grid item xs className="font-family-default">
        <Grid container>
          <Grid item xs={12}>
            <Header
              values={values}
              handleSearch={(value) => this.setState({ search: value })}
              handleFilter={this.handleFilter}
              handleExport={this.handleExport}
              resetFilters={this.resetFilters}
              selectedItems={selectedItems}
            />
            <MembersTable 
              data={membersData} 
              handleClick={(userSelected) => this.handleSelection(userSelected)} 
              values={values} 
              sortAgeGroup={ageGroup} 
              sortStatus={status} 
              search={search} 
            />
          </Grid>
          <Grid className="table-export" id="el">
            <TableExport
              data={membersData} 
              values={values} 
              sortAgeGroup={ageGroup} 
              sortStatus={status} 
              search={search} 
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

MembersReport.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
