import axios from 'axios';
import baseURL from './baseURL';
import TOKEN from './Frontend-Token';

export const FamilyAPI = {
  POST: {
    create(membershipID, familyMembers) {
      return axios.post(
        `${baseURL}api/registerAFamilyMember`,
        {
          membershipID,
          familyMembers,
        },
        {
          headers: { 'x-access-token': TOKEN },
        }
      );
    },
  },

  PUT: {
    updateAFamilyMember(familyMemberID, familyMember) {
      return axios.put(
        `${baseURL}api/updateAFamilyMember/${familyMemberID}`,
        {
          familyMember,
        },
        {
          headers: { 'x-access-token': TOKEN },
        }
      );
    },
    addFamilyMemberCards(membershipID, familyMembers) {
      return axios.put(
        `${baseURL}api/addFamilyMembershipCards/${membershipID}`,
        {
          familyMembers,
        },
        {
          headers: { 'x-access-token': TOKEN },
        }
      );
    },
    deleteFamilyMember(familyMemberID, membershipID) {
      return axios.put(
        `${baseURL}api/deleteFamilyMember/${familyMemberID}`,
        {
          membershipID,
        },
        {
          headers: { 'x-access-token': TOKEN },
        }
      );
    },
    updatePrimaryMemberInformation(familyMemberID, phone) {
      return axios.put(
        `${baseURL}api/updatePrimaryMemberInformation/${familyMemberID}`,
        {
          phone,
        },
        {
          headers: { 'x-access-token': TOKEN },
        }
      );
    },
  },

  GET: {
    getByMembershipID(membershipID) {
      return axios.get(`${baseURL}api/getFamilyMemberBtMembershipID/${membershipID}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    getFamilyMemberByID(familyMemberID) {
      return axios.get(`${baseURL}api/getFamilyMemberByID/${familyMemberID}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    getAllFamilyMembers() {
      return axios.get(`${baseURL}api/getAllFamilyMembers`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    getFamilyMembersAndMembership() {
      return axios.get(`${baseURL}api/getFamilyMembersAndMembership`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
  },
};
export default FamilyAPI;
