import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { CONVERTTIME } from '../../../../utils/dateController';
import '../../../../css/addNewAttendance.css';


function createData(name, index, status, id) {
  return {
    name, index, status, id,
  };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

// collums titels
const headRows = [
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Name',
  },
  {
    id: 'present', numeric: false, disablePadding: false, label: 'Present',
  },
  {
    id: 'absent', numeric: false, disablePadding: false, label: 'Absent',
  },
  {
    id: 'attendanceStatus', numeric: false, disablePadding: false, label: 'Attendance Status',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="bg-light">
      <TableRow>
        <TableCell padding="checkbox" className="check" />
        {headRows.map((row) => (
          <TableCell
            key={row.id}
            align={row.numeric ? 'center' : 'left'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
            className="table-cell"
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}

            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const EnhancedTableToolbar = (props) => (<div />

);

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));


export default function MemberAttendanceTable({
  data, search, attendanceListDate, handleClick, statusClick,
}) {
  const rows = [];
  if (data) {
    for (let i = 0; i < data.length; i++) {
      if (search) {
        if (
          data[i].name.toLowerCase().includes(search.toLowerCase())
          || data[i].status.toLowerCase().includes(search.toLowerCase())
        ) {
          rows.push(
            createData(data[i].name, i, (data[i].status === 'Taken' ? 'Not Taken' : data[i].status), data[i].membershipID),
          );
        }
      } else {
        rows.push(
          createData(data[i].name, i, (data[i].status === 'Taken' ? 'Not Taken' : data[i].status), data[i].membershipID),
        );
      }
    }
  }


  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }


  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }


  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Grid item xs={12} className="today-date">
         Attendace List Date:
        {' '}
        {CONVERTTIME(attendanceListDate, 'attendance')}
      </Grid>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={() => handleClick(row.id)}
                      role="button"
                      tabIndex={0}
                      className="table-tabSelection text-capitalize"
                      key={row.name}
                    >
                      <TableCell padding="checkbox" className="check" />
                      <TableCell component="th" style={{ width: '500px' }} id={labelId} scope="row" padding="none" className="table-cell">
                        {row.name}
                      </TableCell>
                      <TableCell align="center">
                        <div
                          className={row.status === 'Present' ? 'addNewAttendance-present-btn-active' : 'addNewAttendance-present-btn'}
                          onClick={() => statusClick('Present', row.index)}
                          onKeyPress={() => statusClick('Present', row.index)}
                          role="button"
                          tabIndex={0}
                        >
                          <i className="fas fa-check" />
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div
                          className={row.status === 'Absent' ? 'addNewAttendance-absent-btn-active' : 'addNewAttendance-absent-btn'}
                          onClick={() => statusClick('Absent', row.index)}
                          onKeyPress={() => statusClick('Absent', row.index)}
                          role="button"
                          tabIndex={0}
                        >
                          <i className="fas fa-times" />
                        </div>
                      </TableCell>
                      <TableCell align="center" style={{ width: '200px' }}><div className={`addNewAttendance-${row.status}`}>{row.status}</div></TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 100 * emptyRows }} />
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

    </div>
  );
}

MemberAttendanceTable.propTypes = {
  attendanceListDate: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape({
      status: PropTypes.string,
      membershipID: PropTypes.string,
      name: PropTypes.string,
    })),
  ]).isRequired,
  search: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  statusClick: PropTypes.func.isRequired,
};
