import React from 'react';
import PropTypes from 'prop-types';

BackButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default function BackButton({ handleClick, label }) {
  return (
    <button className="back-button" onClick={handleClick}>
      &lt; {label}
    </button>
  );
}
