import React from 'react';
import PropTypes from 'prop-types';
import { CONVERTTIME } from '../../../../utils/dateController';
import '../../../../css/receipts.css';

function createData(name, programName, startTime, date, status, membershipID) {
  return {
    name, programName, startTime, date, status, membershipID,
  };
}
export default function MemberAttendanceTableExport({
  data,
  programID,
  searchItem,
  values,
  programStartTime,
}) {
  const rows = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i].programID === programID) {
      if (values.startDate !== '' && values.endDate !== '') {
        const dateAdjustment = new Date(data[i].date);
        dateAdjustment.setDate(dateAdjustment.getDate());
        if (
          new Date(data[i].date).toISOString() >= new Date(values.startDate).toISOString()
          && new Date(dateAdjustment).toISOString() <= new Date(values.endDate).toISOString()
        ) {
          if (searchItem) {
            if (
              data[i].name.toLowerCase().includes(searchItem.toLowerCase())
            ) {
              rows.push(
                createData(
                  data[i].name, data[i].programName,
                  data[i].startTime,
                  data[i].date,
                  data[i].status,
                  data[i].membershipID,
                ),
              );
            }
          } else {
            rows.push(
              createData(
                data[i].name, data[i].programName,
                data[i].startTime,
                data[i].date,
                data[i].status,
                data[i].membershipID,
              ),
            );
          }
        }
      } else if (searchItem) {
        if (
          data[i].name.toLowerCase().includes(searchItem.toLowerCase())
        ) {
          rows.push(
            createData(
              data[i].name, data[i].programName,
              data[i].startTime,
              data[i].date,
              data[i].status,
              data[i].membershipID,
            ),
          );
        }
      } else {
        rows.push(
          createData(
            data[i].name, data[i].programName,
            data[i].startTime,
            data[i].date,
            data[i].status,
            data[i].membershipID,
          ),
        );
      }
    }
  }

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Program Name</th>
            <th scope="col">Start Time</th>
            <th scope="col">Date</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              <td>
                {row.name}
              </td>
              <td>
                {row.programName}
              </td>
              <td>
                {programStartTime}
              </td>
              <td>
                {CONVERTTIME(row.date, true)}
              </td>
              <td>
                {row.status}
              </td>
            </tr>
          ))}

        </tbody>
      </table>

    </div>
  );
}

MemberAttendanceTableExport.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape({
      programID: PropTypes.string,
    })),
  ]).isRequired,
  searchItem: PropTypes.string.isRequired,
  programID: PropTypes.string.isRequired,
  programStartTime: PropTypes.string.isRequired,
  values: PropTypes.shape({
    startDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    endDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
  }).isRequired,
};
