import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/HighlightOff';
import BackButtons from '../../../../../materialUi/backButton';
import PreLoading from '../../../../../materialUi/preLoading';
import VolunteerSubmissionTable from './volunteerSubmissionTable';
import { taxReturnAPI } from '../../../../../API/API-taxReturn';
import MenuIcon from '../../../../../materialUi/menuIcon';
import FilterIcon from '../../../../../assets/icons/menu.png';
import Tab from '../../../../../materialUi/tab';
import DefaultAlert from '../../../../../materialUi/defaultAlert';
import VolunteerFilter from './volunteerFilter';
import VolunteerTableExport from './volunteerTableExport';

function VolunteerSubmission({
  userData,
  history, 
  data, 
  handleUpdateData, 
  filteredReports, 
  handleFilter, 
  setSearchInput,
  noData
}) {
  const menuOptions = ['Download CSV', 'Print'];
  const { _id } = userData.userData;
  const [currentData, setCurrentData] = React.useState(filteredReports);
  const [loading, setLoading] = React.useState(true);
  const [selectedTab, setSelectedTab] = React.useState(0);

  const [defaultAlert, setDefaultAlert] = React.useState({
    open: false,
    severity: '',
    title: '',
    body: '',
  });

  const handleAlert = (severity, title, body) => {
    setDefaultAlert({
      open: true,
      severity,
      title,
      body,
    });
    setTimeout(() => {
      setDefaultAlert({
        open: false,
        severity,
        title,
        body,
      });
    }, 5000);
  };

  const updateTaxReturn = (newData) => {
    taxReturnAPI.PUT.adminUpdateOneTaxReturn(newData).then((response) => {
      console.log(response);
      handleUpdateData();
      handleAlert('success', 'Success', 'Tax return status successfully updated!');
    }).catch((error) => {
      console.log(error);
      handleAlert('success', 'Server error', 'Please try again later');
    });
  };

  const handleStatusChange = (selectedStatus, index) => {
    const taxReturnCopy = currentData[index];
    taxReturnCopy.status = selectedStatus.value;
    updateTaxReturn(taxReturnCopy);
  };

  const tabList = ['All', 'In Review', 'Missing Info', 'Pending', 'Declined', 'Approved', 'CRA issue', 'Complete'];

  const renderDataBasedOnStatus = (currentTab) => {
    const taxReturnData = [];
    filteredReports.some((taxReturn) => {
      if (taxReturn.taxVolunteer === _id) {
        if (currentTab === 0) {
          taxReturnData.push(taxReturn);
        } else if (currentTab === 1 && taxReturn.status === 'inReview') {
          taxReturnData.push(taxReturn);
        } else if (currentTab === 2 && taxReturn.status === 'missingInfo') {
          taxReturnData.push(taxReturn);
        } else if (currentTab === 3 && taxReturn.status === 'pending') {
          taxReturnData.push(taxReturn);
        } else if (currentTab === 4 && taxReturn.status === 'declined') {
          taxReturnData.push(taxReturn);
        } else if (currentTab === 5 && taxReturn.status === 'approved') {
          taxReturnData.push(taxReturn);
        } else if (currentTab === 6 && taxReturn.status === 'craIssue') {
          taxReturnData.push(taxReturn);
        } else if (currentTab === 7 && taxReturn.status === 'complete') {
          taxReturnData.push(taxReturn);
        }
      }
      return false;
    });
    setLoading(false);
    return taxReturnData;
  };

  const handleTabs = (value) => {
    setSelectedTab(value);
    setCurrentData(renderDataBasedOnStatus(value));
  };

  const exportTable = () => {
    const table = document.getElementById('table-export').innerHTML;
    const convertTable = table.toString().replace(/<div>/g, '')
      .toString().replace(/<\/div>/g, '')
      .toString()
      .replace(/<table class="table">/g, '')
      .toString()
      .replace(/<\/table>/g, '')
      .toString()
      .replace(/<td>/g, '')
      .toString()
      .replace(/<\/td>/g, ',')
      .toString()
      .replace(/<tr>/g, '')
      .toString()
      .replace(/<\/tr>/g, '\r\n')
      .toString()
      .replace(/<th scope="col">/g, '')
      .toString()
      .replace(/<\/th>/g, ',')
      .toString()
      .replace(/<thead>/g, '')
      .toString()
      .replace(/<\/thead>/g, '')
      .toString()
      .replace(/<tbody>/g, '')
      .toString()
      .replace(/<\/tbody>/g, '')
      .toString()
      .replace(/<\/t>/g, '')
      .toString()
      .replace(/<\/n>/g, '');
    const myLink = document.createElement('a');
    myLink.download = 'taxQuestionnaireList.csv';
    myLink.href = `data:application/csv,${escape(convertTable)}`;
    myLink.click();
  };

  const printTable = () => {
    const printContent = document.getElementById('table-export').innerHTML;
    document.body.innerHTML = printContent;
    window.print();
    window.location = '/adminPortal/reports/taxes';
  };

  const handleExport = (value) => {
    if (value === 'Print') {
      printTable();
    } else {
      exportTable();
    }
  };

  const [displayFilter, setDisplayFilter] = React.useState(false);
  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState('');

  const handleDates = (name, value) => {
    if (!value) return false;
    if (value.toString() === 'Invalid Date') return false; 
    let dateArray = [startDate, endDate]
    if (name === 'startDate') {
      setStartDate(value);
      dateArray[0] = value;
    } else {
      setEndDate(value);
      dateArray[1] = value;
    }
    handleFilter('date', dateArray);
  };

  const resetDateFilter = () => {
    setStartDate('');
    setEndDate('');
  };

  const [isapList, setIsapList] = React.useState(
    [
      {
        label: 'Eligible ISAP',
        value: 'eligible',
        checked: false,
      },
      {
        label: 'Not Eligible ISAP',
        value: 'not eligible',
        checked: false,
      },
    ],
  );

  const handleIsap = (index) => {
    const isapCopy = isapList;
    const { checked } = isapCopy[index];
    isapCopy[index].checked = !checked;
    setIsapList(() => [...isapCopy]);
    let isapArray = []
    for(let i=0; i<isapList.length; i++){
      if(isapList[i].checked){
        isapArray.push(isapList[i])
      }
    }
    handleFilter('isap', isapArray);
  };

  const searchInputHandler = (value) => {
    setSearchInput(value);
    handleFilter('search', value);
  }

  React.useEffect(() => {
    setCurrentData(renderDataBasedOnStatus(selectedTab));
  }, [data, filteredReports]);


  return (
    <Grid container>
      <DefaultAlert
          open={defaultAlert.open}
          severity={defaultAlert.severity}
          title={defaultAlert.title}
          body={defaultAlert.body}
          top="80px"
      />
      <Grid item xs className="taxSubmissionList-wrapper position-relative">
        <BackButtons label="Back to Reports" handleClick={() => history.push('/adminPortal/reports')} />
        <h1 className="taxSubmissionList-title">
        Tax Questionnaire Submissions
        </h1>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="taxSubmissionList-table-filter"
        >
          <Box>
            <button
              type="button"
              className="taxSubmissionList-filter-button"
              onClick={() => setDisplayFilter(!displayFilter)}
            >
              <img src={FilterIcon} alt="Search Filter" />
                Filter
            </button>
            {isapList.map((item, index) => (
              item.checked && (
                <button
                  type="button"
                  className="taxSubmissionList-selectedStatus"
                  onClick={() => handleIsap(index)}
                >
                  {item.checked && item.label}
                  <span className="ml-2">
                    <CloseIcon fontSize="small" />
                  </span>
                </button>
              )
            ))}
          </Box>
          <Box>
            <MenuIcon list={menuOptions} onClick={(value) => handleExport(value)} />
          </Box>
        </Grid>
        {displayFilter && (
          <Grid className="taxSubmissionList-table-filter">
            <VolunteerFilter
              isapList={isapList}
              handleIsap={handleIsap}
              handleSearchInput={(value) => searchInputHandler(value)}
              startDate={startDate}
              endDate={endDate}
              handleDates={handleDates}
              resetDateFilter={resetDateFilter}
            />
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Tab
          tabItems={tabList}
          value={selectedTab}
          handleChange={handleTabs}
          minWidth="120px"
        />
      </Grid>
      <Grid item xs={12}>
        {currentData.length === 0 && loading && (
          <div className="taxSubmissionList-loading-table">
            <PreLoading />
          </div>
        )}
        <VolunteerSubmissionTable
          data={data}
          filteredReports={currentData}
          handleStatusChange={handleStatusChange}
          selectedTab={selectedTab}
          noData={noData}
        />
      </Grid>
      <div className="table-export" id="table-export">
        <VolunteerTableExport
          filteredReports={currentData}
        />
      </div>
    </Grid>
  );
}

VolunteerSubmission.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  userData: PropTypes.shape({
    userData: PropTypes.object,
  }).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  handleUpdateData: PropTypes.func.isRequired,
  noData: PropTypes.bool.isRequired,
};

export default withRouter(VolunteerSubmission);
