export const PRICE = function(type, category) {
  if (type === 'Community') {
    if (category === 'Family') {
      return 8;
    }
    if (category === 'Adult') {
      return 5;
    }
    if (category === 'Youth') {
      return 2;
    }
    if (category === 'Senior') {
      return 1;
    }
    if (category === 'Child') {
      return 1;
    }
  }
  if (type === 'Associate') {
    if (category === 'Family') {
      return 15;
    }
    if (category === 'Adult') {
      return 10;
    }
    if (category === 'Youth') {
      return 4;
    }
    if (category === 'Senior') {
      return 2;
    }
    if (category === 'Child') {
      return 2;
    }
  }
};

export const LOCATION = function(lat1, lon1, lat2, lon2) {
  const R = 6371; // km (change this constant to get miles)
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  if (d > 1) return Math.round(d * 1000);
  if (d <= 1) return Math.round(d * 1000);
  return d;
};

export const DetermineMembershipType = function(lat, long) {
  let memberType;
  const location1 = LOCATION(lat, long, 43.654965, -79.402086) < 300;
  const location2 = LOCATION(lat, long, 43.654072, -79.40744) < 300;
  const location3 = LOCATION(lat, long, 43.649958, -79.400788) < 300;
  const location4 = LOCATION(lat, long, 43.649174, -79.405466) < 300;
  const location5 = LOCATION(lat, long, 43.6444072, -79.403492) < 300;
  const location6 = LOCATION(lat, long, 43.645314, -79.39843) < 300;
  const location7 = LOCATION(lat, long, 43.643019, -79.398321) < 300;
  if (
    location1 === true ||
    location2 === true ||
    location3 === true ||
    location4 === true ||
    location5 === true ||
    location5 === true ||
    location6 === true ||
    location7 === true
  ) {
    memberType = 'Community';
  } else {
    memberType = 'Associate';
  }

  return memberType;
};

export const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);

export const formatePhoneNumber = (phone) => {
  const password = phone.toString().replace('-', '');
  const password1 = password.toString().replace('(', '');
  const password2 = password1.toString().replace(')', '');
  const passwordToSave = password2.toString().replace(' ', '');
  return passwordToSave;
};

export const identifyUserType = (user) => {
  if (user.userType === 'Member') {
    return 'member';
  }
  if (user.userType === 'Guest') {
    return 'guest';
  }
  return 'staff';
};

export const checkUserContactPhone = (user) => {
  if (user.phone && user.phone !== '') {
    return true;
  }
  if (user.dayPhone && user.dayPhone !== 'N/A') {
    return true;
  }
  if (user.nightPhone && (user.nightPhone === 'N/A' || user.nightPhone === '')) {
    return true;
  }
  if (user.email && user.email !== '') {
    return true;
  }
  return false;
};

export const sendErrorMessages = (state) => {
  const { messages, errors } = state;
  let fieldsvalidationResult = 0;
  Object.keys(errors).forEach((key) => {
    if (errors[key] === true || errors[key] === '') {
      fieldsvalidationResult += 1;
    }
  });
  if (fieldsvalidationResult === 0) {
    return true;
  }
  Object.keys(errors).forEach((key) => {
    if (errors[key] !== false) {
      errors[key] = true;
      messages[key] = 'Required Field!';
    }
  });
  window.scrollTo(0, 0);
  return { errors, messages };
};

export const checkFieldsValidation = (state) => {
  let validationResult = 0;
  const { errors } = state;
  Object.keys(errors).forEach((key) => {
    if (!Array.isArray(errors[key]) && typeof errors[key] !== 'object') {
      if (errors[key] === true || errors[key] === '') {
        validationResult++;
      }
    }
  });
  if (validationResult === 0) {
    return true;
  } else {
    return false;
  }
};

export const displayErrorMessages = (state) => {
  const { errors, messages } = state;
  Object.keys(errors).forEach((key) => {
    if (!Array.isArray(errors[key]) && typeof errors[key] !== 'object') {
      if (errors[key] !== false) {
        errors[key] = true;
        messages[key] = 'Required Field!';
      }
    }
  });
  window.scrollTo(0, 0);
  return { errors, messages };
};

export const getAllSeasons = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  if (month >= 0 && month <= 3) {
    return {
      winter: `Winter ${year}`,
      spring: `Spring ${year}`,
      summer: `Summer ${year}`,
      fall: `Fall ${year}`,
    };
  } else if (month >= 4 && month <= 6) {
    return {
      winter: `Winter ${year + 1}`,
      spring: `Spring ${year}`,
      summer: `Summer ${year}`,
      fall: `Fall ${year}`,
    };
  } else if (month >= 7 && month <= 9) {
    return {
      winter: `Winter ${year + 1}`,
      spring: `Spring ${year + 1}`,
      summer: `Summer ${year}`,
      fall: `Fall ${year}`,
    };
  } else if (month >= 10 && month <= 12) {
    return {
      winter: `Winter ${year + 1}`,
      spring: `Spring ${year + 1}`,
      summer: `Summer ${year + 1}`,
      fall: `Fall ${year}`,
    };
  }
};
