import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import { BootstrapTextField } from '../../../../materialUi/bootstrapInputs';

export default function CareGiverDetails({ data }) {

  return (
    <Grid container>
      <Grid item xs={6} className="programDetails-spacing">
        <Box className="programDetails-boxes">
          <Grid container>
            <Grid item xs={12} className="programDetails-boxes-main-title">
              Caregiver Information
            </Grid>
            <Grid item xs={12}>
              <Grid container className="programDetails-boxes-text">
                <Grid item xs={6} md={4} className="pt-2">
                  Name:
                </Grid>
                <Grid item xs>
                  <BootstrapTextField
                    id="caretName"
                    type="text"
                    name="Caregiver  Name"
                    value={(data.caregiverInformation) && data.caregiverInformation.name}
                    handleChanges={(value) => false}
                    message={''}
                    readOnly
                    error={false}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container className="programDetails-boxes-text">
                <Grid item xs={6} md={4} className="pt-2">
                  Home Phone:
                </Grid>
                <Grid item xs>
                  <BootstrapTextField
                    id="CareHomePhone"
                    type="text"
                    name="Caregiver Home Phone"
                    value={(data.caregiverInformation) && data.caregiverInformation.homePhone}
                    handleChanges={(value) => false}
                    message={''}
                    readOnly
                    error={false}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container className="programDetails-boxes-text">
                <Grid item xs={6} md={4} className="pt-2">
                  Work Phone:
                </Grid>
                <Grid item xs>
                  <BootstrapTextField
                    id="caregiverWorkPhone"
                    type="text"
                    name="Caregiver Home PHone"
                    value={(data.caregiverInformation) && data.caregiverInformation.workPhone}
                    handleChanges={(value) => false}
                    message={''}
                    readOnly
                    error={false}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container className="programDetails-boxes-text">
                <Grid item xs={6} md={4} className="pt-2">
                  Relationship:
                </Grid>
                <Grid item xs>
                  <BootstrapTextField
                    id="caregiverRelationship"
                    type="text"
                    name="Caregiver Relationship"
                    value={(data.caregiverInformation) && data.caregiverInformation.relationship}
                    handleChanges={(value) => false}
                    message={''}
                    readOnly
                    error={false}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={6} className="programDetails-spacing">
        <Box className="programDetails-boxes">
          <Grid container>
            <Grid item xs={12} className="programDetails-boxes-main-title">
              Emergency Contact
            </Grid>
            <Grid item xs={12} className="programDetails-emergencyContact">
              <Grid container className="programDetails-boxes-text">
                <Grid item xs={6} md={4} className="pt-2">
                  Name:
                </Grid>
                <Grid item xs>
                  <b>{data.membership.membership.emergencyContact.name}</b>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="programDetails-emergencyContact">
              <Grid container className="programDetails-boxes-text">
                <Grid item xs={6} md={4}>
                  Home Phone:
                </Grid>
                <Grid item xs>
                  <b>{data.membership.membership.emergencyContact.homePhone}</b>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="programDetails-emergencyContact">
              <Grid container className="programDetails-boxes-text">
                <Grid item xs={6} md={4}>
                  Work Phone:
                </Grid>
                <Grid item xs>
                  <b>{(data.membership.membership.emergencyContact.workPhone) ? data.membership.membership.emergencyContact.workPhone : 'N/A'}</b>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="programDetails-emergencyContact">
              <Grid container className="programDetails-boxes-text">
                <Grid item xs={6} md={4}>
                  Relationship:
                </Grid>
                <Grid item xs>
                  <b>{data.membership.membership.emergencyContact.emergencyRelationship}</b>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

CareGiverDetails.propTypes = {
  data: PropTypes.shape({
    caregiverInformation: PropTypes.shape({
      name: PropTypes.string,
      relationship: PropTypes.string,
      homePhone: PropTypes.string,
      workPhone: PropTypes.string,
    }),
    membership: PropTypes.shape({
      membership: PropTypes.shape({
        emergencyContact: PropTypes.shape({
          name: PropTypes.string,
          emergencyRelationship: PropTypes.string,
          homePhone: PropTypes.string,
          workPhone: PropTypes.string,
        }),
      }),
    }),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      programID: PropTypes.string,
      membershipID: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

CareGiverDetails.defaultProps = {
  data: {
    caregiverInformation: {
      name: '',
      relationship: '',
      homePhone: '',
      workPhone: '',
    },
    membership: {
      membership: {
        emergencyContact: {
          name: '',
          emergencyRelationship: '',
          homePhone: '',
          workPhone: '',
        },
      },
    },
  },
};
