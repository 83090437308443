import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import Checkbox from '../../../../materialUi/checkBox';
import SearchTextField from '../../../../materialUi/searchTextField';
import '../../../../css/programsList.css';

export default class Filter extends Component {
    constructor(props) {
        super(props);
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth();
        if (month >= 0 && month <= 3) {
            this.state = {
                winter: `Winter ${year}`, spring: `Spring ${year}`, summer: `Summer ${year}`, fall: `Fall ${year}`,
            };
        } else if (month >= 4 && month <= 6) {
            this.state = {
                winter: `Winter ${year + 1}`, spring: `Spring ${year}`, summer: `Summer ${year}`, fall: `Fall ${year}`,
            };
        } else if (month >= 7 && month <= 9) {
            this.state = {
                winter: `Winter ${year + 1}`, spring: `Spring ${year + 1}`, summer: `Summer ${year}`, fall: `Fall ${year}`,
            };
        } else if (month >= 10 && month <= 12) {
            this.state = {
                winter: `Winter ${year + 1}`, spring: `Spring ${year + 1}`, summer: `Summer ${year + 1}`, fall: `Fall ${year}`,
            };
        }
    }


    render() {

        const {
            search,
            values,
            handleFilter,
        } = this.props;
        const {
            winter,
            spring,
            fall,
            summer,
        } = this.state;
        return (
            <Grid item xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Box className="programsList-searchField">
                        <SearchTextField label="Search Program" search={(value) => search(value.target.value)} width={200} />
                    </Box>
                    <div className="dropdown programsList-btn-filter">
                        <button className="btn btn-white dropdown-toggle mr-2 admin-name" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="programsList-ages-dropdown">Ages</span>
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <span className="dropdown-item"><Checkbox fontSize={15} bottom={0} value={values.allAges} click={(value) => handleFilter('allAges', value, 'ages', 'All Ages')} message="All Ages" /></span>
                            <span className="dropdown-item"><Checkbox fontSize={15} bottom={0} value={values.preSchool} click={(value) => handleFilter('preSchool', value, 'ages', 'Pre-school (0-5)')} message="Pre-school (0-5)" /></span>
                            <span className="dropdown-item"><Checkbox fontSize={15} bottom={0} value={values.children} click={(value) => handleFilter('children', value, 'ages', 'Children (6-12)')} message="Children (6-12)" /></span>
                            <span className="dropdown-item"><Checkbox fontSize={15} bottom={0} value={values.children} click={(value) => handleFilter('children', value, 'ages', 'Children (8-12)')} message="Children (8-12)" /></span>
                            <span className="dropdown-item"><Checkbox fontSize={15} bottom={0} value={values.youth} click={(value) => handleFilter('youth', value, 'ages', 'Youth (13-17)')} message="Youth (13-17)" /></span>
                            <span className="dropdown-item"><Checkbox fontSize={15} bottom={0} value={values.adult} click={(value) => handleFilter('adult', value, 'ages', 'Adult (18+)')} message="Adult (18+)" /></span>
                            <span className="dropdown-item"><Checkbox fontSize={15} bottom={0} value={values.senior} click={(value) => handleFilter('senior', value, 'ages', 'Senior')} message="Senior" /></span>
                            <span className="dropdown-item">
                                <Checkbox fontSize={15} bottom={0} value={values.information} click={(value) => handleFilter('information', value, 'ages', 'Information & Referal')} message="Information & Referal" />
                            </span>
                        </div>
                    </div>
                    <div className="dropdown programsList-btn-filter">
                        <button className="btn btn-white dropdown-toggle mr-2 admin-name" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="programsList-seasons-dropdown">Seasons:</span>
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <span className="dropdown-item"><Checkbox fontSize={15} bottom={0} value={values.winter} click={(value) => handleFilter('winter', value, 'seasons', winter)} message={winter} /></span>
                            <span className="dropdown-item"><Checkbox fontSize={15} bottom={0} value={values.spring} click={(value) => handleFilter('spring', value, 'seasons', spring)} message={spring} /></span>
                            <span className="dropdown-item">
                                <Checkbox fontSize={15} bottom={0} value={values.summer} click={(value) => handleFilter('summer', value, 'seasons', summer)} message={summer} />
                            </span>
                            <span className="dropdown-item"><Checkbox fontSize={15} bottom={0} value={values.fall} click={(value) => handleFilter('fall', value, 'seasons', fall)} message={fall} /></span>
                        </div>
                    </div>
                </Grid>
            </Grid>
        );
    }
}

Filter.propTypes = {
    values: PropTypes.shape({
        allAges: PropTypes.bool,
        preSchool: PropTypes.bool,
        children: PropTypes.bool,
        youth: PropTypes.bool,
        adult: PropTypes.bool,
        senior: PropTypes.bool,
        information: PropTypes.bool,
        winter: PropTypes.bool,
        spring: PropTypes.bool,
        fall: PropTypes.bool,
        summer: PropTypes.bool,
    }).isRequired,
    search: PropTypes.func.isRequired,
    handleFilter: PropTypes.func.isRequired,
};
