import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import DropdownIcon from '../../../../assets/icons/menu.png';
import Btn from '../../../../materialUi/btn';
import Filter from './filter';
import '../../../../css/registrationList.css';

export default class AttendeesHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayFilter: false,
      mousePopOver: false,
    };
  }

  handleDisplayFilter = () => {
    const { displayFilter } = this.state;
    const { resetFilters } = this.props;
    if (displayFilter) {
      this.setState({ displayFilter: false });
      resetFilters('');
    } else {
      this.setState({ displayFilter: true });
    }
  };

  render() {
    const {
      handleBack, handleAddNewProgram, programName, handleDate, messages, handleReset, values, userType, headerTotals, 
    } = this.props;

    const { displayFilter } = this.state;

    let options;
   
      options = ['Download CSV', 'Print'];


    return (
      <Grid container className="registrationList-spacing">
        <Grid item xs={12}>
          <Grid className="registrationList-back" onClick={handleBack} onKeyPress={handleBack} role="button" tabIndex={0}>
            &lt; Back to Programs List
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} xl={6}>
          <h1 className="registrationList-header">{programName}</h1>
        </Grid>
        <Grid item xs={12} md={8} xl={6}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Grid className="registrationList-btn-createNew" id={userType === 'Program Staff' ? 'hidden' : ''}>
              <Btn label="CREATE NEW" onClick={handleAddNewProgram} bgColor="#e76f50" color="white" bgColorHover="#ff8c68" colorHover="white" width={128} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box className="registrationList-box">
            <h2 className="registrationList-activeList cursor-default">
              Total Attendees
             {' '} 
              {headerTotals.attendees}
            </h2>
            {' '}
           &nbsp;
            {' '}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs className="registrationList-filter-space">
              <Box
                onClick={() => this.handleDisplayFilter()}
                role="button"
                tabIndex={0}
                onKeyPress={() => this.handleDisplayFilter()}
                className="registrationList-filter"
              >
                <img className="Filter" src={DropdownIcon} alt="Dropdown icon" />
                Filter
              </Box>
            </Grid>
            <Grid item xs>
            </Grid>
            {displayFilter ? <Filter handleDate={handleDate} values={values} messages={messages} handleReset={handleReset} /> : ''}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

AttendeesHeader.propTypes = {
  programName: PropTypes.string.isRequired,
  handleChangeTable: PropTypes.func.isRequired,
  handleAddNewProgram: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired,
  headerTotals: PropTypes.shape({
    attendees: PropTypes.number,
  }).isRequired,
  handleDate: PropTypes.func.isRequired,
  values: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  messages: PropTypes.shape({
    startDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  }).isRequired,
  handleReset: PropTypes.func.isRequired,
};
