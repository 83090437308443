import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import Btn from '../../../../materialUi/btn';
import Filter from './filter';
import DropdownIcon from '../../../../assets/icons/menu.png';
import MenuIcon from '../../../../materialUi/menuIcon';
import '../../../../css/addNewAttendance.css';

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayFilter: false,
    };
  }

  handleDisplayFilter = () => {
    const { displayFilter } = this.state;
    const { resetFilters } = this.props;
    if (displayFilter) {
      this.setState({ displayFilter: false });
      // reset filter
      resetFilters('');
    } else {
      this.setState({ displayFilter: true });
    }
  };

  render() {
    const { handleBack, handleExport, programName, absentCouter, presentCouter, search, handleSubmit } = this.props;
    const { displayFilter } = this.state;
    const options = ['Download CSV', 'Print'];

    return (
      <Grid container className="addNewAttendance-spacing">
        <Grid item xs={12}>
          <Grid className="addNewAttendance-back" onClick={handleBack} onKeyPress={handleBack} role="button" tabIndex={0}>
            &lt; Back to Attendance Lists
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} xl={6}>
          <h1 className="addNewAttendance-header">{programName}</h1>
          <h2 className="addNewAttendance-header">Attendance</h2>
        </Grid>
        <Grid item xs={12} md={8} xl={6}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Grid className="addNewAttendance-btn-createNew">
              <Btn label="SUBMIT" onClick={handleSubmit} bgColor="#e76f50" color="white" bgColorHover="#ff8c68" colorHover="white" width={128} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs className="addNewAttendance-filter-space">
              <Box onClick={() => this.handleDisplayFilter()} role="button" tabIndex={0} onKeyPress={() => this.handleDisplayFilter()} className="addNewAttendance-filter">
                <img className="Filter" src={DropdownIcon} alt="Dropdown icon" />
                Filter
              </Box>
            </Grid>
            <Grid item xs>
              <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <MenuIcon list={options} onClick={(value) => handleExport(value)} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs>
          {displayFilter ? <Filter search={search} /> : ''}
        </Grid>

        <Grid item xs={12} md>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center" className="addNewAttendance-filter-spacing">
            <Box>
              Total Present:
              <h5 className="addNewAttendance-present">{presentCouter}</h5>
            </Box>
            <Box>
              Total Absent:
              <h5 className="addNewAttendance-absent">{absentCouter}</h5>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

Header.propTypes = {
  handleBack: PropTypes.func.isRequired,
  handleExport: PropTypes.func.isRequired,
  absentCouter: PropTypes.number.isRequired,
  presentCouter: PropTypes.number.isRequired,
  programName: PropTypes.string.isRequired,
  search: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
