import axios from 'axios';
import baseURL from './baseURL';
import TOKEN from './Frontend-Token';

export const ProgramAttendanceWithoutRegistration = {
  GET: {
    getProgramAttendanceByAttendanceID(attendanceID) {
      return axios.get(`${baseURL}api/getProgramAttendanceByAttendanceID/${attendanceID}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    getSingleAttendanceItemByAttendanceID(attendanceID) {
      return axios.get(`${baseURL}api/getSingleAttendanceItemByAttendanceID/${attendanceID}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
  },
  PUT: {
    updateProgramAttendeeWithoutRegistartion(updatedObject) {
      return axios.put(
        `${baseURL}api/updateProgramAttendeeWithoutRegistartion/${updatedObject._id}`,
        updatedObject,
        {
          headers: { 'x-access-token': TOKEN },
        }
      );
    },
  },
  POST: {
    createProgramAttendanceByAttendanceID(programAttendanceObj) {
      return axios.post(
        `${baseURL}api/createProgramAttendanceByAttendanceID`,
        programAttendanceObj,
        {
          headers: { 'x-access-token': TOKEN },
        },
      );
    },
},
DELETE: {
    deleteAttendanceByAttendanceID(attendanceID) {
        return axios.delete(
          `${baseURL}api/deleteAttendanceByAttendanceID/${attendanceID}`,
          {
            headers: { 'x-access-token': TOKEN },
          }
        );
      },
}
};

export default ProgramAttendanceWithoutRegistration;
