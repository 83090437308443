import axios from 'axios';
import baseURL from './baseURL';
import TOKEN from './Frontend-Token';

export const ProgramsAPI = {
  POST: {
    addNewProgram(programObj) {
      return axios.post(
        `${baseURL}api/addNewProgram`,
        programObj,
        {
          headers: { 'x-access-token': TOKEN },
        },
      );
    },
    programRegistrationEmail(email, programName, firstName, lastName) {
      return axios.post(
        `${baseURL}api/programRegistrationEmail`,
        {
          email,
          programName,
          firstName,
          lastName,
        },
        {
          headers: { 'x-access-token': TOKEN },
        },
      );
    },
  },
  PUT: {
    editProgram(programID, programObj) {
      return axios.put(
        `${baseURL}api/editProgram/${programID}`,
        programObj,
        {
          headers: { 'x-access-token': TOKEN },
        },
      );
    },
    deleteProgram(programID) {
      return axios.put(
        `${baseURL}api/deleteProgram/${programID}`,
        {},
        {
          headers: { 'x-access-token': TOKEN },
        },
      );
    },
  },
  GET: {
    getProgramByID(programID) {
      return axios.get(`${baseURL}api/getProgramByID/${programID}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    getAll() {
      return axios.get(`${baseURL}api/getAllPrograms`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    getProgramByInstructorID(instructorID) {
      return axios.get(`${baseURL}api/getProgramByInstructorID/${instructorID}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    getProgramsHistory() {
      return axios.get(`${baseURL}api/getProgramsHistory`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
  },
};

export default ProgramsAPI;
