import React from 'react';
import PropTypes from 'prop-types';
import { CONVERTTIME } from '../../../../utils/dateController';
import '../../../../css/receipts.css';

function tableExport({ receiptsData, searchItem }) {
  const data = [];
  for (let i = 0; i < receiptsData.length; i++) {
    if (searchItem) {
      if (receiptsData[i].cardType.toLowerCase().includes(searchItem.toLowerCase())
          || receiptsData[i].fee.toString().includes(searchItem.toLowerCase())
          || receiptsData[i].paymentMethod.toLowerCase().includes(searchItem.toLowerCase())) {
        data.push(receiptsData[i]);
      }
    } else {
      data.push(receiptsData[i]);
    }
  }

  return (
    <div>

      <table className="table">
        <thead>
          <tr>
            <th scope="col">Category</th>
            <th scope="col">Description</th>
            <th scope="col">Date of payment</th>
            <th scope="col">Payment Method</th>
            <th scope="col">Card Type</th>
            <th scope="col">Amount</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>

          {data.map((row, index) => (
            <tr key={index}>
              <td>
                {row.category}
              </td>
              <td>
                {row.description}
              </td>
              <td>
                {CONVERTTIME(row.paymentDate, true)}
              </td>
              <td>
                {row.paymentMethod}
              </td>
              <td>
                {row.cardType}
              </td>
              <td>
                {' '}
$
                {' '}
                {row.fee}
.00
                {' '}
              </td>
              <td>
                {row.status}
              </td>
            </tr>
          ))}

        </tbody>
      </table>

    </div>
  );
}

tableExport.prototype = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  searchItem: PropTypes.string.isRequired,
}

export default tableExport;
