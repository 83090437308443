/* eslint-disable no-lonely-if */
import React from 'react';
import PropTypes from 'prop-types';
import '../../../../../css/receipts.css';

function createData(
  checkInID,
  userType,
  firstName,
  lastName,
  date,
  screeningResult,
  id,
  phone,
  email,
) {
  return {
    checkInID,
    userType,
    firstName,
    lastName,
    date,
    screeningResult,
    id,
    phone,
    email,
  };
}
function tableExport({
  data, searchItem, values, sortStatus, sortResult,
}) {
  const rows = [];

  function padValue(value) {
    return (value < 10) ? `0${value}` : value;
  }
  function formatDate(dateVal) {
    const newDate = new Date(dateVal);

    const sMonth = padValue(newDate.getMonth() + 1);
    const sDay = padValue(newDate.getDate());
    const sYear = newDate.getFullYear();
    let sHour = newDate.getHours();
    const sMinute = padValue(newDate.getMinutes());
    let sAMPM = 'AM';

    const iHourCheck = parseInt(sHour);

    if (iHourCheck > 12) {
      sAMPM = 'PM';
      sHour = iHourCheck - 12;
    } else if (iHourCheck === 0) {
      sHour = '12';
    }

    sHour = padValue(sHour);

    return `${sMonth}-${sDay}-${sYear} ${sHour}:${sMinute} ${sAMPM}`;
  }
  const determineUserType = (userData) => {
    if (!userData.user.userType) {
      return 'Member';
    }
    if (userData.user.userType === 'Guest') {
      return 'Guest';
    }
    return 'Staff';
  };

  const determineScreeningResult = (userData) => {
    if (userData.screeningResult) {
      return 'Negative';
    }
    return 'Positive';
  };

  const sortByDate = (userData) => {
    const dateAdjustment = new Date(userData.date);
    dateAdjustment.setDate(dateAdjustment.getDate());
    return (
      new Date(userData.date).toISOString() >= new Date(values.startDate).toISOString()
          && new Date(dateAdjustment).toISOString() <= new Date(values.endDate).toISOString()
    );
  };

  const sortByStatus = (userData, status) => (determineUserType(userData) === status);

  const sortByResult = (userData, result) => (determineScreeningResult(userData) === result);

  const sortSearchTextField = (userData) => (
    userData.user.firstName.toLowerCase().includes(searchItem.toLowerCase())
    || userData.user.lastName.toLowerCase().includes(searchItem.toLowerCase())
    || (userData.user.phone || userData.user.dayPhone).toLowerCase().includes(searchItem.toLowerCase())
  );

  const pushFilterResults = (userData) => {
    rows.push(
      createData(
        userData.checkInID,
        determineUserType(userData),
        userData.user.firstName,
        userData.user.lastName,
        userData.date,
        userData.screeningResult,
        userData.user._id,
        userData.user.phone || userData.user.dayPhone,
        userData.user.email,
      ),
    );
  };
  if (data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      // < ---- Primary: (Sort by Date) Secondary: (Sort by Status || Search text field) ----- >
      if (values.startDate !== '') {
        if (sortByDate(data[i])) {
          // < ---- Sort by status || Search text field ----- >
          if (sortStatus.length > 0) {
            sortStatus.map((status) => {
              if (sortByStatus(data[i], status)) {
                if (searchItem) {
                  if (sortSearchTextField(data[i])) {
                    if (sortResult.length > 0) {
                      sortResult.map((result) => {
                        if (sortByResult(data[i], result)) {
                          pushFilterResults(data[i]);
                        }
                        return '';
                      });
                    } else {
                      pushFilterResults(data[i]);
                    }
                  }
                } else {
                  if (sortResult.length > 0) {
                    sortResult.map((result) => {
                      if (sortByResult(data[i], result)) {
                        pushFilterResults(data[i]);
                      }
                      return '';
                    });
                  } else {
                    pushFilterResults(data[i]);
                  }
                }
              } return '';
            });
            // < ---- Search text field || Sort by status ---- >
          } else if (searchItem) {
            if (sortSearchTextField(data[i])) {
              if (sortStatus.length > 0) {
                sortStatus.map((status) => {
                  if (sortByStatus(data[i], status)) {
                    if (sortResult.length > 0) {
                      sortResult.map((result) => {
                        if (sortByResult(data[i], result)) {
                          pushFilterResults(data[i]);
                        }
                        return '';
                      });
                    } else {
                      pushFilterResults(data[i]);
                    }
                  } return '';
                });
              } else {
                if (sortResult.length > 0) {
                  sortResult.map((status) => {
                    if (sortByResult(data[i], status)) {
                      pushFilterResults(data[i]);
                    }
                    return '';
                  });
                } else {
                  pushFilterResults(data[i]);
                }
              }
            }
          } else {
            if (sortResult.length > 0) {
              sortResult.map((result) => {
                if (sortByResult(data[i], result)) {
                  if (sortStatus.length > 0) {
                    sortStatus.map((status) => {
                      if (sortByStatus(data[i], status)) {
                        return pushFilterResults(data[i]);
                      }
                      return '';
                    });
                  } else {
                    pushFilterResults(data[i]);
                  }
                }
                return '';
              });
            } else {
              pushFilterResults(data[i]);
            }
          }
        }
        // < ---- Primary: (Sort by Status) Secondary: ( Sort by Date|| Search text field) ----- >
      } else {
        if (sortStatus.length > 0) {
          sortStatus.map((status) => {
            if (sortByStatus(data[i], status)) {
              if (searchItem) {
                if (sortSearchTextField(data[i])) {
                  if (sortResult.length > 0) {
                    sortResult.map((result) => {
                      if (sortByResult(data[i], result)) {
                        pushFilterResults(data[i]);
                      }
                      return '';
                    });
                  } else {
                    pushFilterResults(data[i]);
                  }
                }
              } else {
                if (sortResult.length > 0) {
                  sortResult.map((result) => {
                    if (sortByResult(data[i], result)) {
                      pushFilterResults(data[i]);
                    }
                    return '';
                  });
                } else {
                  pushFilterResults(data[i]);
                }
              }
            } return '';
          });
          // < ---- Primary: (Search text field) Secondary: ( Sort by Date|| Sort by Status) ----- >
        } else {
          if (searchItem) {
            console.log('text');
            if (sortSearchTextField(data[i])) {
              if (sortStatus.length > 0) {
                sortStatus.map((status) => {
                  if (sortByStatus(data[i], status)) {
                    if (sortResult.length > 0) {
                      sortResult.map((result) => {
                        if (sortByResult(data[i], result)) {
                          pushFilterResults(data[i]);
                        }
                        return '';
                      });
                    } else {
                      pushFilterResults(data[i]);
                    }
                  }
                  return '';
                });
              } else {
                if (sortResult.length > 0) {
                  sortResult.map((result) => {
                    if (sortByResult(data[i], result)) {
                      pushFilterResults(data[i]);
                    }
                    return '';
                  });
                } else {
                  pushFilterResults(data[i]);
                }
              }
            }
          } else {
            if (sortResult.length > 0) {
              sortResult.map((result) => {
                if (sortByResult(data[i], result)) {
                  pushFilterResults(data[i]);
                }
                return '';
              });
            } else {
              pushFilterResults(data[i]);
            }
          }
        }
      }
    }
  }

  return (
    <div>

      <table className="table">
        <thead>
          <tr>
            <th scope="col">Check-in ID</th>
            <th scope="col">User Type</th>
            <th scope="col">First Name</th>
            <th scope="col">Last Name</th>
            <th scope="col">Email</th>
            <th scope="col">Phone</th>
            <th scope="col">Time of Check-In</th>
            <th scope="col">Pre-screening Results</th>
          </tr>
        </thead>
        <tbody>

          {rows.map((row, index) => (
            <tr key={index}>
              <td>
                {row.checkInID}
              </td>
              <td>
                {row.userType}
              </td>
              <td>
                {row.firstName}
              </td>
              <td>
                {row.lastName}
              </td>
              <td>
                {row.email}
              </td>
              <td>
                {row.phone}
              </td>
              <td>
                {formatDate(row.date)}
              </td>
              <td>
                {row.screeningResult ? 'Negative' : 'Positive'}
              </td>
            </tr>
          ))}

        </tbody>
      </table>

    </div>
  );
}

tableExport.prototype = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  handleClick: PropTypes.func.isRequired,
  searchItem: PropTypes.string.isRequired,
  sortStatus: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  sortResult: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  values: PropTypes.shape({
    startDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    endDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
  }).isRequired,
};

export default tableExport;
