import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import HelpIcon from '@material-ui/icons/Help';
import { Grid, Box } from '@material-ui/core';
import Btn from '../../../../materialUi/btn';
import MousePopOver from '../../../../materialUi/mousePopOver';
import Filter from './filter';
import DropdownIcon from '../../../../assets/icons/menu.png';
import '../../../../css/addNewAttendance.css';

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayFilter: false,
      mousePopOver: false,
    };
  }

  handleDisplayFilter = () => {
    const { displayFilter } = this.state;
    const { handleReset } = this.props;
    if (displayFilter) {
      this.setState({ displayFilter: false });
      // reset filter
      handleReset('');
    } else {
      this.setState({ displayFilter: true });
    }
  };

  // returnSpotsBasedOnMembershipType = () => {
  //   const MEMBERSHIP_TYPE = membership.membership.membershipType.toLowerCase();
  //   if (MEMBERSHIP_TYPE === 'associate') {
  //     return data.associateSpots;
  //   }
  //   return data.communitySpots;
  // };

  // displayNumberOfSpots = () => {
  //   if (userType.toLowerCase() === 'member') {
  //     return returnSpotsBasedOnMembershipType();
  //   }
  //   return data.communitySpots + data.associateSpots;
  // };

  render() {
    const {
      handleBack, programName, handleSubmit, absentCouter, presentCouter, search, weeklySchedule, selectedWeek,
      handleSelectedWeek
    } = this.props;
    const { displayFilter, mousePopOver } = this.state;
    return (
      <Grid container className="addNewAttendance-spacing">
        <Grid item xs={12}>
          <Grid className="addNewAttendance-back" onClick={handleBack} onKeyPress={handleBack} role="button" tabIndex={0}>
            &lt; Back to Attendance List
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} xl={6}>
          <h1 className="addNewAttendance-header">{programName}</h1>
          <h2 className="addNewAttendance-header">Attendance</h2>
        </Grid>
        <Grid item xs={12} md={8} xl={6}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Grid className="addNewAttendance-btn-createNew">
              <Btn label="SUBMIT" onClick={handleSubmit} bgColor="#e76f50" color="white" bgColorHover="#ff8c68" colorHover="white" width={128} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {weeklySchedule && (
            <Grid container className="registrationList-weekly-wrapper">
              <Grid item xs={12} className="registrationList-weekly-box">
                <h3 className="registrationList-weekly-title">
                  Weekly Program
                  {' '}
                  <HelpIcon
                    className="registrationList-help-icon"
                    onMouseEnter={(event) => this.setState({ mousePopOver: event.currentTarget })}
                    onMouseLeave={() => this.setState({ mousePopOver: null })}
                    onClick={(event) => this.setState({ mousePopOver: event.currentTarget })}
                  />
                  <MousePopOver open={mousePopOver} message="Please, select a week below." />
                </h3>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                >
                  {weeklySchedule.map((option, index) => (
                    <Grid item>
                      <button
                        type="button"
                        className={`registrationList-weekly-options ${selectedWeek === index && 'registrationList-weekly-options-active'} `}
                        onClick={() => handleSelectedWeek(index)}
                      >
                        <Moment date={option.startDate} format="DD-MMM" />
                        {' '}
                        -
                        {' '}
                        {' '}
                        <Moment date={option.endDate} format="DD-MMM" />
                        {' '}
                        {' '}
                        (Spots left:
                        {' '}
                        {(option.communitySpots + option.associateSpots) - option.familyMembers.length}
                        )
                      </button>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item xs className="addNewAttendance-filter-space">
              <Box onClick={() => this.handleDisplayFilter()} role="button" tabIndex={0} onKeyPress={() => this.handleDisplayFilter()} className="addNewAttendance-filter">
                <img className="Filter" src={DropdownIcon} alt="Dropdown icon" />
                Filter
              </Box>
            </Grid>
            <Grid item xs>
              <Grid container direction="row" justifyContent="flex-end" alignItems="center" className="addNewAttendance-filter-spacing">
                <Box>
                  Total Present:
                  <h5 className="addNewAttendance-present">{presentCouter}</h5>
                </Box>
                <Box>
                  Total Absent:
                  <h5 className="addNewAttendance-absent">{absentCouter}</h5>
                </Box>
              </Grid>
            </Grid>
            {displayFilter ? <Filter search={search} /> : ''}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

Header.propTypes = {
  handleBack: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  absentCouter: PropTypes.number.isRequired,
  presentCouter: PropTypes.number.isRequired,
  programName: PropTypes.string.isRequired,
  search: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
};
