import React, { Component } from 'react';
import { Grid, Paper, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Script from 'react-load-script';
import SimpleReactValidator from 'simple-react-validator';
import { DetermineMembershipType } from '../../../../utils/supportFunctions';
import { BootstrapTextField, BootstrapSelectDropdown, TextFieldDefaultValue } from '../../../../materialUi/bootstrapInputs';

const GOOGLE_API_KEY = `${process.env.REACT_APP_GOOGLE_API_KEY}`;

class personalInfo extends Component {
  constructor(props) {
    super(props);
    // Bind Functions
    this.handleScriptLoad = this.handleScriptLoad.bind(this);
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.validator = new SimpleReactValidator();
  }

  handleScriptLoad() {
    const options = { types: ['address'], componentRestrictions: { country: 'ca' } };
    /* global google */
    this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'), options);
    this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
  }

  handlePlaceSelect() {
    const { handleInputs } = this.props;
    let membershipType;
    const addressObject = this.autocomplete.getPlace();
    const address = addressObject.address_components;
    if (address) {
      if (address[0] == null || address[1] == null || address[2] == null || address[5] == null || address[6] == null) {
        handleInputs('address', '', 'address');
      } else {
        let postalCode;
        membershipType = DetermineMembershipType(addressObject.geometry.location.lat(), addressObject.geometry.location.lng());
        for (let i = 0; i < address.length; i++) {
          for (let j = 0; j < address[i].types.length; j++) {
            if (address[i].types[j] === 'postal_code') {
              postalCode = address[i].long_name;
            }
          }
        }
        handleInputs('address', `${address[0].long_name},${address[1].long_name},${address[2].long_name},${address[5].long_name},${postalCode}`, 'address', true);
        handleInputs('postalCode', postalCode, 'postalCode', 'Postal Code');
        handleInputs('membershipType', membershipType, 'name');
      }
    } else {
      handleInputs('address', '', 'address');
    }
  }

  formatDate(date) {
    const convertedDate = date.split('T')[0];
    return convertedDate;
  }

  render() {
    let city;
    let postalCode;
    city = this.props.values.address.city;
    postalCode = this.props.values.address.postalCode;
    if (typeof this.props.values.address !== 'object') {
      const splitedAddress = this.props.values.address.split(',');
      city = splitedAddress[2];
      postalCode = splitedAddress[4];
    }
    const { values, messages, errors, handleInputs } = this.props;

    const genderList = ['Select One', 'Man', 'Woman', 'Non-binary', 'I choose not to disclose'];

    var dateOfBirth
    if (values.dateOfBirth) {
      dateOfBirth = this.formatDate(values.dateOfBirth);
    }

    return (
      <Paper className="body-boxes">
        <Script url={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`} onLoad={this.handleScriptLoad} />
        <Grid item xs={12} className="Boxes-main-title">
          Personal Information
        </Grid>
        <Grid item xs={12} className="boxes-spacing">
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Box className="boxes-labels">First Name:</Box>
            </Grid>
            <Grid item xs>
              <BootstrapTextField
                id="firstName"
                type="text"
                name="firstName"
                value={values.firstName}
                handleChanges={(value) => handleInputs('firstName', value.target.value, 'name', 'First Name')}
                message={messages.firstName}
                readOnly={false}
                error={errors.firstName}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="boxes-spacing">
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Box className="personalInfo-labels">Last Name:</Box>
            </Grid>
            <Grid item xs>
              <BootstrapTextField
                id="lastName"
                type="text"
                name="lastName"
                value={values.lastName}
                handleChanges={(value) => handleInputs('lastName', value.target.value, 'name', 'Last Name')}
                message={messages.lastName}
                readOnly={false}
                error={errors.lastName}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="boxes-spacing">
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Box className="personalInfo-labels">Date Of Birth:</Box>
            </Grid>
            <Grid item xs>
              <BootstrapTextField
                id="dateOfBirth"
                type="date"
                name="dateOfBirth"
                value={dateOfBirth}
                handleChanges={(value) => handleInputs('dateOfBirth', value.target.value, 'name')}
                message={messages.dateOfBirth}
                readOnly={false}
                error={errors.dateOfBirth}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="boxes-spacing">
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Box className="personalInfo-labels">Gender:</Box>
            </Grid>
            <Grid item xs>
              <BootstrapSelectDropdown
                value={values.gender}
                list={genderList}
                handleChanges={(value) => handleInputs('gender', value.target.value, 'dropdown')}
                message={messages.gender}
                error={errors.gender}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="boxes-spacing">
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Box className="personalInfo-labels">Phone:</Box>
            </Grid>
            <Grid item xs>
              <BootstrapTextField
                type="text"
                id="phone"
                name="phone"
                value={values.phone}
                handleChanges={(value) => handleInputs('phone', value.target.value, 'phone')}
                message={messages.phone}
                readOnly={false}
                error={errors.phone}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="boxes-spacing">
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Box className="personalInfo-labels">Address:</Box>
            </Grid>
            <Grid item xs>
              <TextFieldDefaultValue
                id="autocomplete"
                type="text"
                name="address"
                defaultValue={values.address.street}
                handleChanges={(value) => handleInputs('address', value.target.value, 'address')}
                message={messages.address}
                readOnly
                error={errors.address}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="boxes-spacing">
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Box className="personalInfo-labels">City / Town:</Box>
            </Grid>
            <Grid item xs>
              <TextFieldDefaultValue type="text" name="barCode" defaultValue={city} readOnly />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Box className="personalInfo-labels">Postal Code:</Box>
            </Grid>
            <Grid item xs>
              <TextFieldDefaultValue type="text" name="postalCode" defaultValue={postalCode} readOnly />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="boxes-spacing">
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Box className="personalInfo-labels">Apt:</Box>
            </Grid>
            <Grid item xs>
              <BootstrapTextField
                id="Apt"
                type="text"
                name="Apartment"
                value={values.apt}
                handleChanges={(value) => handleInputs('apt', value.target.value, 'not required', 'Apt')}
                message={messages.apt}
                readOnly={false}
                error={errors.apt}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="boxes-spacing">
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Box className="personalInfo-labels">School:</Box>
            </Grid>
            <Grid item xs>
              <BootstrapTextField
                id="school"
                type="text"
                name="school"
                value={values.school}
                handleChanges={(value) => handleInputs('school', value.target.value, 'name', 'School')}
                message={messages.school}
                readOnly={false}
                error={errors.school}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="boxes-spacing">
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Box className="personalInfo-labels">Email:</Box>
            </Grid>
            <Grid item xs>
              <BootstrapTextField
                id="email"
                type="text"
                name="email"
                value={values.email}
                handleChanges={(value) => handleInputs('email', value.target.value, 'email')}
                message={messages.email}
                readOnly={false}
                error={errors.email}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

personalInfo.propTypes = {
  values: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    dateOfBirth: PropTypes.string,
    gender: PropTypes.string,
    phone: PropTypes.string,
    address: PropTypes.any,
    city: PropTypes.string,
    postalCode: PropTypes.string,
    apt: PropTypes.string,
    school: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  messages: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    dateOfBirth: PropTypes.string,
    gender: PropTypes.string,
    phone: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
    apt: PropTypes.string,
    school: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    firstName: PropTypes.bool,
    lastName: PropTypes.bool,
    dateOfBirth: PropTypes.bool,
    gender: PropTypes.bool,
    phone: PropTypes.bool,
    address: PropTypes.bool,
    city: PropTypes.bool,
    postalCode: PropTypes.bool,
    apt: PropTypes.bool,
    school: PropTypes.bool,
    email: PropTypes.bool,
  }).isRequired,
  handleInputs: PropTypes.func.isRequired,
};
export default personalInfo;
