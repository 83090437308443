import React from 'react';
import { Grid } from '@material-ui/core';
import Successful from '../../../../assets/icons/fireworks@3x.png';
import Button from '../../../../materialUi/btn';
import Receipt from './receipt';
import './success.css';

function Success({ data, userData }) {

  const handleLogin = () => {
    sessionStorage.clear();
    if (userData) return window.location = 'adminPortal/membership';
    window.location = '/memberLogin';
  };

  return (
    <Grid container className="registration-success-wrapper">
      <Grid item xs={12} md={2}>
        <img src={Successful} alt="logo" width="104px" height="120px" />
      </Grid>
      <Grid item>
        <h1 className="registration-success-title">
          Successful!
        </h1>
        {data.paymentMethod !== 'cash' && (
          <h2 className="registration-success-text">
            {data.email ? (
              <>

                New member has been successfully added to the SCCC community.
                A confirmation email has been sent to you along with your receipt.
              </>
            ) : (
              <>
                New member has been successfully added to the SCCC community.
              </>
            )}
          </h2>
        )}
        {
          data.paymentMethod === 'cash' && !userData && (
            <h2 className="registration-success-text">
              <div><b>Next Step:</b></div> <br />
              Please visit the reception to verify your address and make the membership payment.
            </h2>
          )
        }
        {userData && data.fee === 0 && (
          <h2 className="registration-success-text">
            {data.email ? (
              <>

                New member has been successfully added to the SCCC community.
                A confirmation email has been sent to you along with your receipt.
              </>
            ) : (
              <>
                New member has been successfully added to the SCCC community.
              </>
            )}
          </h2>
        )}
        <div className="registration-success-green-text">
          * Membership ID will be required to log in to the portal.
        </div>
        <div className="registration-success-box">
          <div style={{ marginBottom: '16px', textTransform: 'capitalize' }}>
            Primary Member Name:
            {' '}
            {`${data.familyMembers[0].firstName} ${data.familyMembers[0].lastName}`}
          </div>
          <div style={{ marginBottom: '16px' }}>
            Membership ID:
            {' '}
            {data.membershipID}
          </div>
          <div className="registration-success-box-text">
            Email address:
            {' '}
            {data.email || 'Not Provided'}
          </div>
        </div>
        {userData && (
          <Receipt data={data} />
        )}
        {!userData && data.paymentMethod !== 'cash' && (
          <Receipt data={data} />
        )}
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          style={{ paddingTop: '25px' }}
        >
          <Button
            label={userData ? 'DONE' : 'LOGIN'}
            type="button"
            onClick={handleLogin}
            bgColor="#e76f50"
            color="white"
            bgColorHover="#ff8c68"
            colorHover="white"
            width={120}
            height={47}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Success;
