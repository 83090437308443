import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableRow from '@material-ui/core/TableRow';
import MenuIcon from '../../../../materialUi/menuIcon';
import Btn from '../../../../materialUi/btn';
import DownloadIcon from '../../../../assets/icons/download-icon.svg';


function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

// columns titles
const headRows = [
  {
    id: 'documentOrderNumber', numeric: false, disablePadding: true, label: '',
  },
  {
    id: 'documentName', numeric: false, disablePadding: true, label: 'Document Name',
  },
  {
    id: 'fileType', numeric: false, disablePadding: false, label: 'File Type',
  },
  {
    id: 'download', numeric: false, disablePadding: false, label: '',
  },
  {
    id: 'view', numeric: false, disablePadding: false, label: '',
  },
  {
    id: 'menu', numeric: false, disablePadding: false, label: '',
  },
];


function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="bg-light">
      <TableRow>
        {headRows.map((row) => (
          <TableCell
            key={row.id}
            align={row.numeric ? 'center' : 'left'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
            className="table-cell submissionList-table-title"
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
              className="submissionList-table-title"
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

function AdminDocuments({
  data,
  handleViewDocument,
  handleDownload,
  handleDeleteDocument,
}) {
  const menuOptions = ['Delete'];
  function createData(documentName, fileType, filePath, id, index) {
    return {
      documentName,
      fileType,
      filePath,
      id,
      index,
    };
  }

  const rows = [];

  for (let i = 0; i < data.length; i++) {
    rows.push(
      createData(
        data[i].fileName,
        data[i].fileType,
        data[i].filePath,
        data[i].userID,
        i,
      ),
    );
  }


  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('submissionDate');
  const [selected, setSelected] = React.useState([]);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);


  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  return (
    <Grid container className="mt-4">
      <Grid item xs={12} className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
          />
          <TableBody>
            {stableSort(rows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow
                  hover
                  key={row.membershipID}
                >
                  <TableCell component="th" scope="row" padding="none" className="table-cell">
                    {index + 1}
                  </TableCell>
                  <TableCell align="left">
                    {row.documentName}
                  </TableCell>
                  <TableCell align="left">
                    {row.fileType}
                  </TableCell>
                  <TableCell align="left">
                    <a href={row.filePath} download={row.documentName} target="blank">
                      <button
                        className="taxSubmissionList-table-download-button"
                        type="button"
                        onClick={() => {}}
                      >
                        <img src={DownloadIcon} alt="download icon" />
                        <span className="taxSubmissionList-table-download-text">Download</span>
                      </button>
                    </a>
                  </TableCell>
                  <TableCell align="center" style={{ width: '15px' }}>
                    <Btn
                      label="VIEW"
                      bgColor="#e76f50"
                      color="white"
                      colorHover="white"
                      bgColorHover="#ff8c68"
                      width={81}
                      height={27}
                      fontSize={14}
                      onClick={() => handleViewDocument(row)}
                      type="button"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <MenuIcon list={menuOptions} onClick={(value) => handleDeleteDocument(index)} />
                  </TableCell>
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }} />
            )}
          </TableBody>
        </Table>
      </Grid>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Grid>
  );
}

AdminDocuments.propTypes = {
  handleViewDocument: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleDeleteDocument: PropTypes.func.isRequired,
};

export default AdminDocuments;
