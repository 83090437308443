import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import Btn from '../../../materialUi/btn';
import CancelAlert from '../../../materialUi/cancelAlert';

function ActionButtons({ handleSubmit, openCancelAlert, handleCancelAlert }) {
  return (
    <Grid item xs={12} className="mt-4">
      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        <Btn label="Next" onClick={() => handleSubmit(false)} bgColor="#e76f50" color="white" bgColorHover="#ff8c68" colorHover="white" width={128} />
        <Box style={{ marginRight: '5px' }}>
          <Btn
            label="Cancel"
            bgColor="white"
            color="black"
            bgColorHover="#eceff1"
            colorHover="white"
            borderColor="white"
            variant="outlined"
            width={128}
            onClick={() => handleCancelAlert(true)}
            ariaLabel="Cancel Pre-screening"
          />
          <CancelAlert
            open={openCancelAlert}
            close={() => handleCancelAlert(false)}
            location="/adminPortal/covid19"
            message="Are you sure you want to leave this page before completing the pre-screening?"
            clearSession
          />
        </Box>
      </Grid>
    </Grid>
  );
}

ActionButtons.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  openCancelAlert: PropTypes.bool.isRequired,
  handleCancelAlert: PropTypes.func.isRequired,
};

export default ActionButtons;
