import axios from 'axios';
import baseURL from './baseURL';
import TOKEN from './Frontend-Token';

export const EmailAPI = {
  EMAIL: {
    emailReceipt(email, receiptObj) {
      return axios.post(
        `${baseURL}api/emailReceipt`,
        {
          email,
          referenceNumber: receiptObj.referenceNumber,
          name: receiptObj.name,
          cardType: receiptObj.cardType,
          category: receiptObj.category,
          paymentMethod: receiptObj.paymentMethod,
          address: receiptObj.address,
          date: receiptObj.date,
          total: receiptObj.fee,
        },
        {
          headers: { 'x-access-token': TOKEN },
        }
      );
    },
    emailConfirmation(email, membershipObj) {
      return axios.post(
        `${baseURL}api/emailConfirmation`,
        {
          email,
          membershipID: membershipObj.membershipID,
          firstName: membershipObj.firstName,
          lastName: membershipObj.lastName,
          emailBody: membershipObj.emailBody,
        },
        {
          headers: { 'x-access-token': TOKEN },
        }
      );
    },
    emailWaitingList(emailObj) {
      return axios.post(
        `${baseURL}api/emailWaitingList`,
        {
          emailObj,
        },
        {
          headers: { 'x-access-token': TOKEN },
        }
      );
    },
    emailProgramConfirmation(email, emailObj) {
      return axios.post(
        `${baseURL}api/programRegistrationEmail`,
        {
          email,
          name: emailObj.name,
          programName: emailObj.programName,
        },
        {
          headers: { 'x-access-token': TOKEN },
        },
      );
    },
    programRegistrationEmail(emailObj) {
      return axios.post(
        `${baseURL}api/programRegistrationEmail`,
        emailObj,
        {
          headers: { 'x-access-token': TOKEN },
        },
      );
    },
    emailTaxRegistration(email) {
      return axios.post(`${baseURL}api/emailTaxRegistration`, { email });
    },
    membershipConfirmation(data) {
      return axios.post(`${baseURL}api/membershipConfirmation`, data);
    },
    sendReceipt(data) {
      return axios.post(`${baseURL}api/sendEmailReceipt`, data);
    },
  },
};
export default EmailAPI;
