import React, { Component } from 'react';
import logo from '../../assets/SCCC_LOGO_SMALL.png';
import TextField from '../../materialUi/textField';
import Btn from '../../materialUi/btn';
import Alert from '../../materialUi/Alerts';
import { ResetPassword } from '../../API/API-resetPassword';

class adminForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailMessage: '',
      openAlert: false,
      alertBody: '',
      alertLocation: '',
      alertTitle: '',
      alertStay: '',
      alertClose: '',
      disableBtn: false,
    };
  }

  handleInputs = (value) => {
    let message = '';
    if (value.target.value.length > 0) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.target.value)) {
        message = 'Invalid Email';
      } else {
        message = null;
      }
    } else {
      message = 'Email cannot be empty';
    }
    this.setState({ email: value.target.value, emailMessage: message });
  };

  handleResetLink = () => {
    const { email, emailMessage } = this.state;
    if (emailMessage === null) {
      this.setState({ disableBtn: true });
      ResetPassword.sendResetAdminLink(email)
        .then((success) => {
          console.log(success);
          this.setState({
            openAlert: true,
            alertLocation: '/admin',
            alertTitle: 'Email Sent',
            alertBody: 'A Reset Link was sent to your email. Follow the Instructions and login again! ',
            alertClose: 'LOGIN',
            alertStay: 'CLOSE',
            disableBtn: false,
          });
        })
        .catch((error) => {
          if (error.response.status === 500 || error.response.status === 404) {
            this.setState({
              openAlert: true,
              alertLocation: '/adminForgotPassword',
              alertTitle: 'SERVER ERROR!',
              alertBody: 'There is an error in the server. Please try again later!',
              alertClose: 'hidden',
              alertStay: 'CLOSE',
              disableBtn: false,
            });
          } else if (error.response.status === 401) {
            this.setState({
              openAlert: true,
              alertLocation: '/adminForgotPassword',
              alertTitle: 'User not found!',
              alertBody: 'The User was not found! Please check the data entered!',
              alertClose: 'hidden',
              alertStay: 'CLOSE',
              disableBtn: false,
            });
          } else {
            this.setState({ invalidEmail: true });
          }
        });
    } else {
      this.setState({
        emailMessage: emailMessage === '' ? 'Required field!' : emailMessage,
      });
    }
  };

  render() {
    const { openAlert, alertLocation, alertTitle, alertBody, alertClose, alertStay, email, emailMessage, disableBtn } = this.state;
    return (
      <div className="container text-center ">
        <div className="row  header text-center pt-4">
          <div className="col-12 text-left">
            <div className="-Back-to-Member-lis" onClick={() => this.props.history.push('/admin')}>
              &lt; Back to login
            </div>
          </div>
          <div className="col-12 text-center mt-5">
            <img src={logo} alt="Logo" />
          </div>
        </div>
        <div className="d-flex flex-row justify-content-center flex-wrap">
          <div className="shadow-sm login-box ">
            <div className="row">
              <div className="col-12">
                <h1 className="Admin-Login ">Forgot Password</h1>
              </div>
              <div className="col-12">
                <h2 className="Enter-your-email-add">Enter your email address and we will send you a link to reset your password.</h2>
              </div>
              <div className="col-12">
                <TextField
                  label="Your Email"
                  id="Email"
                  value={email}
                  handleChanges={(value) => this.handleInputs(value)}
                  helperText={emailMessage}
                  error={emailMessage !== '' && emailMessage !== null}
                  readOnly={false}
                />
              </div>
              <div className="col-12 mt-5 text-center">
                <Btn
                  label="Send Reset Link"
                  disable={disableBtn}
                  onClick={this.handleResetLink}
                  bgColor="#e76f50"
                  color="white"
                  bgColorHover="#ff8c68"
                  colorHover="white"
                  height={42}
                  width={window.innerWidth < 600 ? 295 : 395}
                  loading={disableBtn}
                />
              </div>
            </div>
            <Alert open={openAlert} close={() => this.setState({ openAlert: false })} location={alertLocation} title={alertTitle} body={alertBody} stay={alertStay} leave={alertClose} />
          </div>
        </div>
      </div>
    );
  }
}

export default adminForgotPassword;
