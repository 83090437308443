import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { Paper, Grid } from '@material-ui/core';

function membershipInfo({
  membershipData,
  values,
}) {
  return (
    <Paper className="body-boxes">
      <Grid item xs={12} className="Boxes-main-title">Membership</Grid>
      <Grid item xs={12} className="membershipInfo-boxes-spacing">
        <Grid container spacing={3}>
          <Grid item xs={5}>
            Membership#:
          </Grid>
          <Grid item xs>
            {membershipData.membershipID}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="membershipInfo-boxes-spacing">
        <Grid container spacing={3}>
          <Grid item xs={5}>
          Expiry Date:
          </Grid>
          <Grid item xs>
            <Moment format="MM/DD/YYYY" >{membershipData.expirationDate}</Moment>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="membershipInfo-boxes-spacing">
        <Grid container spacing={3}>
          <Grid item xs={5}>
          1st Registered:
          </Grid>
          <Grid item xs>
            <Moment format="MM/DD/YYYY" >{membershipData.firstRegistered}</Moment>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="membershipInfo-boxes-spacing">
        <Grid container spacing={3}>
          <Grid item xs={5}>
          Issued On:
          </Grid>
          <Grid item xs>
            <Moment format="MM/DD/YYYY" >{membershipData.registrationDate}</Moment>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="membershipInfo-boxes-spacing">
        <Grid container spacing={3}>
          <Grid item xs={5}>
          Member Type:
          </Grid>
          <Grid item xs>
            {values.membershipType}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="membershipInfo-boxes-spacing">
        <Grid container spacing={3}>
          <Grid item xs={5}>
          Category:
          </Grid>
          <Grid item xs>
            {membershipData.membershipCategory}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="membershipInfo-boxes-spacing">
        <Grid container spacing={3}>
          <Grid item xs={5}>
          Fee:
          </Grid>
          <Grid item xs>
            $
            {membershipData.fee}
            .00
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

membershipInfo.propTypes = {
  values: PropTypes.shape({
    membershipType: PropTypes.string,
  }).isRequired,
  membershipData: PropTypes.shape({
    membershipID: PropTypes.string,
    expirationDate: PropTypes.string,
    registrationDate: PropTypes.string,
    membershipCategory: PropTypes.string,
    firstRegistered: PropTypes.string,
    fee: PropTypes.number,
  }).isRequired,
};

export default membershipInfo;
