import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import '../../../../css/receipts.css';

function createData(
  category,
  paymentDate,
  paymentMethod,
  cardType,
  amount,
  name,
  address,
  referenceNumber,
  paymentDateISO,
  status,
  description
) {
  return {
    category,
    paymentDate,
    paymentMethod,
    cardType,
    amount,
    name,
    address,
    referenceNumber,
    paymentDateISO,
    status,
    description
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

export default function ReceiptsTable({ data, handleClick, searchItem, values }) {
  const rows = [];
  if (data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      if (values.startDate !== '' && values.endDate !== '') {
        const dateAdjustment = new Date(data[i].paymentDate);
        dateAdjustment.setDate(dateAdjustment.getDate());
        if (
          new Date(data[i].paymentDate).toISOString() >= new Date(values.startDate).toISOString()
          && new Date(dateAdjustment).toISOString() <= new Date(values.endDate).toISOString()
        ) {
          if (searchItem) {
            if (
              data[i].name.toLowerCase().includes(searchItem.toLowerCase())
              || data[i].referenceNumber.toString().toLowerCase().includes(searchItem.toLowerCase())
              || data[i].membershipID.toString().toLowerCase().includes(searchItem.toLowerCase())
            ) {
              rows.push(
                createData(
                  data[i].category,
                  data[i].paymentDate,
                  data[i].paymentMethod,
                  data[i].cardType,
                  data[i].fee,
                  data[i].name,
                  data[i].address,
                  data[i].referenceNumber,
                  data[i].paymentDate,
                  data[i].status,
                  data[i].description && data[i].description,
                ),
              );
            }
          } else {
            rows.push(
              createData(
                data[i].category,
                data[i].paymentDate,
                data[i].paymentMethod,
                data[i].cardType,
                data[i].fee,
                data[i].name,
                data[i].address,
                data[i].referenceNumber,
                data[i].paymentDate,
                data[i].status,
                data[i].description && data[i].description,
              ),
            );
          }
        }
      } else if (searchItem) {
        if (
          data[i].category.toLowerCase().includes(searchItem.toLowerCase())
          || data[i].referenceNumber.toString().toLowerCase().includes(searchItem.toLowerCase())
        ) {
          rows.push(
            createData(
              data[i].category,
              data[i].paymentDate,
              data[i].paymentMethod,
              data[i].cardType,
              data[i].fee,
              data[i].name,
              data[i].address,
              data[i].referenceNumber,
              data[i].paymentDate,
              data[i].status,
              data[i].description && data[i].description,
            ),
          );
        }
      } else {
        rows.push(
          createData(
            data[i].category,
            data[i].paymentDate,
            data[i].paymentMethod,
            data[i].cardType,
            data[i].fee,
            data[i].name,
            data[i].address,
            data[i].referenceNumber,
            data[i].paymentDate,
            data[i].status,
            data[i].description && data[i].description,
          ),
        );
      }
    }
  }


  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }


  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <Grid className={classes.root}>
      <Grid className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}
        >
          <TableHead className="memberList-table-header">
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="left">Payment Date</TableCell>
              <TableCell align="left">Payment Method</TableCell>
              <TableCell align="left">Amount</TableCell>
              <TableCell align="left">Status</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                const receiptData = {
                  name: row.name,
                  address: row.address,
                  category: row.category,
                  paymentDate: row.paymentDate,
                  paymentMethod: row.paymentMethod,
                  cardType: row.cardType,
                  amount: row.amount,
                  id: row.referenceNumber,
                  paymentDateISO: row.paymentDateISO,
                  status: row.paymentStatus,
                  description: row.description
                };
                return (
                  <TableRow
                    hover
                    onClick={() => handleClick(receiptData)}
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    className="receipt-table-hover"
                  >
                    <TableCell component="th" id={labelId} scope="row" padding="none" className="table-cell">
                      {row.category}
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none" className="table-cell">
                      {row.description}
                    </TableCell>
                    <TableCell align="left"><Moment date={row.paymentDate} format="MM/DD/YYYY" /></TableCell>
                    <TableCell align="left">{row.paymentMethod}</TableCell>
                    <TableCell align="left">${row.amount}.00</TableCell>
                    <TableCell align="left"><Box className={(row.status === 'Approved' ? 'receipt-table-approved' : 'receipt-table-canceled')}>{row.status}</Box></TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }} />
            )}
          </TableBody>
        </Table>
      </Grid>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Grid>
  );
}

ReceiptsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  handleClick: PropTypes.func.isRequired,
  searchItem: PropTypes.string.isRequired,
  values: PropTypes.shape({
    startDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    endDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
  }).isRequired,
};
