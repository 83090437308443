import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
} from '@material-ui/core';
import AlertIcon from '../../../assets/icons/alert-circle.svg';
import MemberIcon from '../../../assets/icons/member-token.svg';
import StaffIcon from '../../../assets/icons/staff-token.svg';
import GuestIcon from '../../../assets/icons/guest-token.svg';
import { checkUserContactPhone } from '../../../utils/supportFunctions';

function CheckInHeader({ handleSubmit, handleBack, selectedUser }) {
  return (
    <>

      {handleBack && (
        <Grid item xs={6}>
          <span
            className="addNewAttendance-back"
            onClick={handleBack}
            onKeyPress={handleBack}
            role="button"
            tabIndex={0}
          >
                &lt; Back
          </span>
        </Grid>
      )}

      {handleSubmit && (
      <Grid item xs={6} className="covid19-skip-btn">
        <span
          className="addNewAttendance-back"
          onClick={() => handleSubmit(true)}
          onKeyPress={() => handleSubmit(true)}
          role="button"
          tabIndex={0}
        >
                Skip Pre-screening &gt;
        </span>
      </Grid>
      )}

      <Grid item xs={12}>
        <h1 className="covid19-screening-title">Check-In</h1>
      </Grid>
      <Grid item xs={12} className="covid19-screening-box-wrapper">
        <Grid className="covid19-screening-box">

          {
              (selectedUser.userType === 'Member') ? (
                <img src={MemberIcon} alt="Member Token" className="covid19-screening-token" />
              )
                : (selectedUser.userType === 'Guest') ? (
                  <img src={GuestIcon} alt="Guest Token" className="covid19-screening-token" />
                )
                  : <img src={StaffIcon} alt="Staff Token" className="covid19-screening-token" />
              }
          <span className="covid19-screening-token">
            <span className="covid19-screening-name">
              {`${selectedUser.firstName} ${selectedUser.lastName}`}
              {!checkUserContactPhone(selectedUser) && (
              <span className="covid19-screening-alert ml-2">
                <img src={AlertIcon} alt="Missing information icon" className="covid19-userFound-icon" />
              </span>
              )}
            </span>

          </span>
        </Grid>
      </Grid>
    </>
  );
}

CheckInHeader.propTypes = {
  selectedUser: PropTypes.shape({
    userType: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  handleBack: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  handleSubmit: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
};

CheckInHeader.defaultProps = {
  handleBack: false,
  handleSubmit: false,
};

export default CheckInHeader;
