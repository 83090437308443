/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Grid, Box } from '@material-ui/core';
import BackButton from '../../../../materialUi/backButton';
import StepButtons from '../../../../materialUi/stepButtons';
import Button from '../../../../materialUi/btn';
import Address from './components/address';
import FamilyMembers from './components/familyMembers';
import EmergencyContact from './components/emergencyContact';
import Email from './components/email';
import Password from './components/password';
import EmailAlert from './components/noEmailAlert';
import './registrationForm.css';

function RegistrationForm({ data, handleNextStep, userData }) {
  const userType = userData ? userData.userData.userType : false;
  const useFormMethods = useForm({ mode: 'all' });
  const { handleSubmit, register, unregister, setValue } = useFormMethods;

  const [familyMembers, setFamilyMembers] = React.useState([
    {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      phone: '',
      gender: '',
    },
  ]);
  const [noEmail, setNoEmail] = React.useState(false);
  const [formToSubmit, setFormToSubmit] = React.useState({});

  const handleFormSubmit = (formData) => {
    setFormToSubmit(formData);
    if (formData.email === '') {
      setNoEmail(true);
    } else {
      formData.email = formData.email.toLowerCase();
      handleNextStep(formData, 1);
    }
  };

  const addMember = () => {
    const familyMemberModel = {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      phone: '',
      relationship: '',
      gender: '',
    };
    setFamilyMembers((oldArray) => [...oldArray, familyMemberModel]);
  };

  const removeTheMemberOnSession = () => {
    const CURRENT_MEMBER_NUMBER = familyMembers.length - 1;
    const IS_MEMBER_SAVED = data.familyMembers[CURRENT_MEMBER_NUMBER];
    const familyMembersCopy = data.familyMembers;
    if (IS_MEMBER_SAVED) {
      familyMembersCopy.pop();
      data.familyMembers = familyMembersCopy;
      sessionStorage.setItem('membershipRegistration', JSON.stringify(data));
    }
  };

  const removeFamilyMemberFormValues = () => {
    unregister(`firstName${familyMembers.length}`);
    unregister(`lastName${familyMembers.length}`);
    unregister(`dateOfBirth${familyMembers.length}`);
    unregister(`phone${familyMembers.length}`);
    unregister(`relationship${familyMembers.length}`);
    unregister(`school${familyMembers.length}`);
    unregister(`gender${familyMembers.length}`);
    const SUBMITTED_FORM = data.familyMembers;
    if (SUBMITTED_FORM) {
      removeTheMemberOnSession();
    }
  };

  const handleDeleteMember = () => {
    removeFamilyMemberFormValues();
    const oldArray = familyMembers;
    oldArray.pop();
    setFamilyMembers([...oldArray]);
  };

  const addFamilyMemberToTheForm = (memberData, memberNumber) => {
    familyMembers[memberNumber] = memberData;
    setFamilyMembers((arrayToSave) => [...arrayToSave]);
    register('familyMembers');
    setValue('familyMembers', familyMembers);
  };

  React.useEffect(() => {
    if (data.familyMembers) {
      setFamilyMembers(data.familyMembers);
    }
    if (data.email) {
      setValue('email', data.email, { shouldValidate: true });
    }
    if (data.shareEmail) {
      setValue('shareEmail', data.shareEmail, { shouldValidate: true });
    }
    if (data.address) {
      setValue('address', data.address, { shouldValidate: true });
    }
  }, [data]);

  const handleBack = () => {
    handleNextStep(data, -1, 'reset');
  };

  return (
    <FormProvider {...useFormMethods}>
      <div className="registration-form-wrapper">
        <BackButton handleClick={handleBack} label="Back to Category Selection" />
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Grid container>
            <Grid item xs>
              <Grid item xs={12}>
                <h1 className="registration-form-title">Membership Registration Form</h1>
              </Grid>
              {familyMembers.map((item, index) => (
                <FamilyMembers key={index} data={data} memberNumber={index} membershipCategory={data.membershipCategory} addFamilyMember={addFamilyMemberToTheForm} {...useFormMethods} />
              ))}
              <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                {familyMembers.length > 1 && (
                  <span className="registration-form-delete-button">
                    <Button type="button" label="Delete" onClick={handleDeleteMember} bgColor="#e76f50" color="white" colorHover="white" bgColorHover="#ff8c68" width={128} />
                  </span>
                )}
                {data.membershipCategory === 'Family' && (
                  <Button
                    type="button"
                    label="ADD NEW FAMILY MEMBER"
                    onClick={() => addMember()}
                    bgColor="white"
                    color="#e76f50"
                    bgColorHover="#eceff1"
                    width={233}
                    variant="outlined"
                    borderColor="#e76f50"
                  />
                )}
              </Grid>
              <Address {...useFormMethods} data={data} />
              <EmergencyContact {...useFormMethods} data={data} />
              <Email {...useFormMethods} data={data} userData={userData} />
              {userType !== 'provisory member' && <Password {...useFormMethods} data={data} />}
              <Grid container>
                <Grid item xs={12}>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Box className="registration-buttons-message">
                      Annual membership is <span className="register-email-text-highlight">valid for one year from date of joining.</span> Memberships are not transferable and fees are non-refundable.
                    </Box>
                    <Box className="registration-step-buttons-wrapper">
                      <StepButtons location="/" handleSubmit={() => {}} type="submit" />
                    </Box>
                  </Grid>
                  <hr className="registration-buttons-hr" />
                  <Grid item xs={12} className="registration-bottom-text">
                    The Personal Information on this form is collected under the authority of the <span className="register-email-text-highlight">City of Toronto Act </span>, 1997, and Article XI pf
                    Ch. 169, of the Toronto Municipal Code. The information is used for the purpose of the program registration, payment, mailings, including newsletters/surveys, and aggregate
                    statistical reporting.
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <EmailAlert open={noEmail} handleClose={() => setNoEmail(false)} handleProceed={() => handleNextStep(formToSubmit, 1)} />
    </FormProvider>
  );
}

export default RegistrationForm;
