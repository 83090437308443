import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import BarChart from './barChart';

export default function Registrations(props) {

  const {
    programRegistrationsByYear,
  } = props.data;

  return (
    <Grid container className="root dashboard-tabs-spacing-newMember">
      <Grid item xs={6} className="dashboard-tabs-title">
        Registrations{' '}
        <span className="dashboard-tabs-title-description">
          Compared to the previous year
        </span>
      </Grid>
      <Grid item xs={12}>
        <Paper className="dashboard-tabs-paper">
          <BarChart programsData={programRegistrationsByYear} />
        </Paper>
      </Grid>
    </Grid>
  );
}

Registrations.propTypes = {
  data: PropTypes.shape({
    programRegistrationsByYear: PropTypes.arrayOf(
      PropTypes.shape({
        winter: PropTypes.number,
        spring: PropTypes.number,
        summer: PropTypes.number,
        fall: PropTypes.number,
      }),
    )
  }).isRequired,
};
