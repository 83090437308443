import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import RadioGroup from '../../../../../materialUi/radioGroup';
import Dropdown from '../../../../../materialUi/dropdown';

function SectionTwo(props) {
  const {
    control,
    formState: { errors },
    data,
  } = props;

  const pastYear = new Date().getFullYear() - 1;

  const YesNoQuestions = [
    {
      value: 'yes',
      label: 'Yes',
    },
    {
      value: 'no',
      label: 'No',
    },
  ];

  const provinceList = [
    {
      name: 'Alberta',
      value: 'Alberta',
    },
    {
      name: 'British Columbia',
    },
    {
      name: 'Manitoba',
    },
    {
      name: 'New Brunswick',
    },
    {
      name: 'Newfoundland and Labrador',
    },
    {
      name: 'Northwest Territories',
    },
    {
      name: 'Nova Scotia',
    },
    {
      name: 'Nunavut',
    },
    {
      name: 'Ontario',
      value: 'Ontario',
    },
    {
      name: 'Prince Edward Island',
    },
    {
      name: 'Quebec',
    },
    {
      name: 'Saskatchewan',
    },
    {
      name: 'Yukon',
    },
  ];
  const maritalList = [
    {
      value: 'single',
      label: 'Single',
    },
    {
      value: 'married',
      label: 'Married (or separated for less than 90 days)',
    },
    {
      value: 'Common-law',
      label: 'Common-law souse (or separated for less than 90 days)',
    },
    {
      value: 'separated',
      label: 'Separated ',
    },
    {
      value: 'divorced',
      label: 'Divorced   ',
    },
    {
      value: 'widowed',
      label: 'Widowed',
    },
  ];
  const languageList = [
    {
      value: 'english',
      label: 'English',
    },
    {
      value: 'french',
      label: 'French',
    },
  ];
  const maritalChangeList = [
    {
      value: 'change',
      label: 'Change',
    },
    {
      value: 'noChange',
      label: 'No change',
    },
  ];

  return (
    <>
      <Grid item xs={12}>
        <div className="taxes-question">
            4. What is your preferred language of correspondence and tax return?
        </div>
      </Grid>
      <Grid item xs={12}>
        <span className="custom-error">
          {(errors.language) && errors.language.message}
        </span>
        <Controller
          name="language"
          control={control}
          rules={{
            required: 'Please select One',
          }}
          render={({ field }) => (
            <RadioGroup
              listOptions={languageList}
              ariaLabel=" 4. What is your preferred language of correspondence and tax return?"
              name="language"
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">
            5. What is your province of residence (until December 31,
          {' '}
          {pastYear}
)?
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          name="province"
          control={control}
          rules={{
            required: 'Province is required',
          }}
          defaultValue={data.province}
          render={({ field }) => (
            <Dropdown
              name="province"
              label="province"
              id="province"
              helperText={errors.province ? errors.province.message : ''}
              error={!!errors.province}
              listName={provinceList}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">
            6.Please state your marital status as recorded until December 31,
          {' '}
          {pastYear}
.
        </div>
      </Grid>
      <Grid item xs={12}>
        <span className="custom-error">
          {(errors.maritalStatus) && errors.maritalStatus.message}
        </span>
        <Controller
          name="maritalStatus"
          control={control}
          rules={{
            required: 'Please select one',
          }}
          render={({ field }) => (
            <RadioGroup
              listOptions={maritalList}
              ariaLabel={`6.Please state your marital status as recorded until December 31, ${pastYear}.`}
              name="Marital status"
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">
            6a. Did your marital status change in
          {' '}
          {pastYear}
          {' '}
(including separated for less than 90 days)?
        </div>
      </Grid>
      <Grid item xs={12}>
        <span className="custom-error">
          {(errors.maritalChange) && errors.maritalChange.message}
        </span>
        <Controller
          name="maritalChange"
          control={control}
          rules={{
            required: 'Please select one',
          }}
          render={({ field }) => (
            <RadioGroup
              listOptions={maritalChangeList}
              ariaLabel={`6a. Did your marital status change in ${pastYear} (including separated for less than 90 days)?`}
              name="Marital status"
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">
            6b. Are you the head of the family?
        </div>
      </Grid>
      <Grid item xs={12}>
        <span className="custom-error">
          {(errors.familyHead) && errors.familyHead.message}
        </span>
        <Controller
          name="familyHead"
          control={control}
          rules={{
            required: 'Please select one',
          }}
          render={({ field }) => (
            <RadioGroup
              listOptions={YesNoQuestions}
              ariaLabel={`6a. Did your marital status change in ${pastYear} (including separated for less than 90 days)?`}
              name="familyHead"
              {...field}
            />
          )}
        />
      </Grid>
    </>
  );
}

SectionTwo.propTypes = {
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  formState: PropTypes.shape({
    errors: PropTypes.any,
  }).isRequired,
  data: PropTypes.shape(
    {
      province: PropTypes.string,
    },
  ).isRequired,
};

export default SectionTwo;
