import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import Successful from '../../../../assets/icons/fireworks@3x.png';
import PrintReceipt from './receiptPrint';
import { TIME } from '../../../../utils/dateController';
import { validateField } from '../../../../utils/fieldsValidation';
import EmailConfirmation from './components/emailConfirmation';
import { EmailAPI } from '../../../../API/API-Email';
import Receipt from './components/receipt';
import Alert from '../../../../materialUi/Alerts';
import '../../../../css/success.css';


class Success extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        email: '',
        emailReceipt: true,
      },
      errors: {
        email: false,
      },
      messages: {
        email: '',
      },

      primaryMember: '',
      address: '',
      paymentInformation: '',
      membershipID: '',
      emailBodyMessage: 'Congrats on changing your membership type for SCCC membership - you can now access a wide variety of programs and activities hosted here at the center.',

      alertLocation: '',
      alertTitle: '',
      alertBody: '',
      alertStay: '',
      alertClose: '',
      openAlert: false,
    };
  }

  componentDidMount() {
    const paymentFromSession = JSON.parse(sessionStorage.getItem('changeMembershipPayment'));
    const { values } = this.state;

    if (!paymentFromSession) {
      this.props.history.push('/adminPortal/membership');
    } else {
      const changeMembershipFromSession = JSON.parse(sessionStorage.getItem(`changeMembership_${paymentFromSession.membershipID}`));
      if (!changeMembershipFromSession) {
        this.props.history.push('/adminPortal/membership');
      } else {
        values.email = changeMembershipFromSession.membership.email;
        this.setState({
          values,
          address: changeMembershipFromSession.membership.address,
          primaryMember: changeMembershipFromSession.primaryMember,
        });
        if (!changeMembershipFromSession.changeMembershipPaymentValidation
           || !changeMembershipFromSession.changeMembershipValidation) this.props.history.push('/adminPortal/membership');
      }
      this.setState({
        paymentInformation: paymentFromSession,
        membershipID: paymentFromSession.membershipID,
      });
    }
  }

  handleInputs=(name, value, type, requirement = '') => {
    const { values, messages, errors } = this.state;
    if (validateField(value, type, requirement) === true) {
      values[name] = value;
      messages[name] = '';
      errors[name] = false;
      this.setState({ values, errors, messages });
    } else {
      values[name] = value;
      messages[name] = validateField(value, type, requirement);
      errors[name] = true;
      this.setState({ values, errors, messages });
    }
  }

  handleSendEmail =() => {
    const {
      paymentInformation,
      primaryMember,
      membershipID,
      emailBodyMessage,
      address,
      errors,
      values,


    } = this.state;
    // receipt Object
    const addressTosend = `${address.street} ${address.city} ${address.province} ${address.postalCode}`;
    const receiptObj = {
      referenceNumber: paymentInformation.referenceNumber,
      name: paymentInformation.name,
      cardType: paymentInformation.cardType,
      category: paymentInformation.category,
      paymentMethod: paymentInformation.paymentMethod,
      address: addressTosend,
      date: TIME(paymentInformation.paymentDate, true),
      fee: paymentInformation.fee,
    };

    // membership Object
    const membershipObj = {
      membershipID,
      firstName: primaryMember.firstName,
      lastName: primaryMember.lastName,
      emailBody: emailBodyMessage,
    };

    if (errors.email === true) {
      // Send error alert
      this.handleInputs = ('email', values.email, 'email', true);
    } else if (values.emailReceipt) {
      EmailAPI.EMAIL.emailReceipt(values.email, receiptObj).then((success) => {
        console.log(success);
      }).catch((error) => {
        console.log(error);
      });

      EmailAPI.EMAIL.emailConfirmation(values.email, membershipObj).then((success) => {
        console.log(success);
        this.setState({
          openAlert: true,
          alertLocation: '/adminPortal/membership',
          alertTitle: 'Email sent!',
          alertBody: 'The email confirmation and the receipt was successfully sent!',
          alertClose: 'hidden',
          alertStay: 'CLOSE',
        });
      }).catch((error) => {
        console.log(error);
      });
    } else {
      EmailAPI.EMAIL.emailConfirmation(values.email, membershipObj).then((success) => {
        console.log(success);
        this.setState({
          openAlert: true,
          alertLocation: '/adminPortal/membership',
          alertTitle: 'Email sent!',
          alertBody: 'The email confirmation was successfully sent!',
          alertClose: 'hidden',
          alertStay: 'CLOSE',
        });
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  handlePrintReceipt = () => {
    const printContent = document.getElementById('receipt').innerHTML;
    document.body.innerHTML = printContent;
    window.print();
    window.location = '/adminPortal/membership/changeMembershipType/success';
  }

  handleDone=() => {
    const { membershipID } = this.state;
    sessionStorage.clear();
    window.location = `/adminPortal/membership/verifyMembership/${membershipID}`;
  }

  render() {
    const {
      values,
      messages,
      errors,
      openAlert,
      alertLocation,
      alertTitle,
      alertBody,
      alertStay,
      alertClose,
    } = this.state;
    return (
      <Grid item xs className="success-spacing root">
        <Grid container className="success-row">
          <Grid item xs={12} md={2} className="logo-success">
            <img src={Successful} alt="logo" width="104px" height="120px" />
          </Grid>
          <Grid item xs={12} lg={8}>
            <EmailConfirmation
              handleSendEmail={this.handleSendEmail}
              values={values}
              errors={errors}
              messages={messages}
              handleInputs={this.handleInputs}
            />

            <Receipt handlePrintReceipt={this.handlePrintReceipt} handleDone={this.handleDone} />
          </Grid>
        </Grid>
        <Alert
          open={openAlert}
          close={() => this.setState({ openAlert: false })}
          location={alertLocation}
          title={alertTitle}
          body={alertBody}
          stay={alertStay}
          leave={alertClose}
        />
        <div className="hidden">
          <PrintReceipt id="receipt" />
        </div>
      </Grid>
    );
  }
}

export default Success;
