import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import HelpIcon from '@material-ui/icons/Help';
import Cookies from 'js-cookie';
import logo from '../../assets/SCCC_LOGO_SMALL.png';
import TextField from '../../materialUi/textField';
import Btn from '../../materialUi/btn';
import { authenticationAPI } from '../../API/API-authentication';
import Alert from '../../materialUi/Alerts';
// import MousePopOver from '../materialUi/mousePopOver';
import { formatePhoneNumber } from '../../utils/supportFunctions';

export default class MemberLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      membershipID: '',
      membershipIDMessage: '',
      password: '',
      passwordMessage: '',
      invalidEmail: 'hidden',
      InvalidPassword: 'hidden',
      openAlert: false,
      mousePopOver: false,
    };
  }

  componentDidMount() {
    const { match, history } = this.props;
    authenticationAPI.authentication
      .getUser(Cookies.get('token'))
      .then((success) => {
        if (success.data.userType !== 'Member' && success.data.userType !== 'provisory member') {
          return history.push('/adminPortal/membership');
        }
        if (match.params.action && match.params.action === 'waitingListPayment') {
          return window.location =`/scccPortal/programs/programRegistration/${match.params.action}/${match.params.param1}/${match.params.param2}/${match.params.param3}`;
        }
        return history.push('/scccPortal/membership');
      })
      .catch(() => {
        console.log('User not logged in!');
      });
  }

  handleMembershipId = (value) => {
    let message = '';
    if (value.target.value.length > 0) {
      message = null;
    } else {
      message = 'Membership ID cannot be empty';
    }
    this.setState({ membershipID: value.target.value.toLowerCase(), membershipIDMessage: message });
  };

  handlePassword = (value) => {
    let message = '';
    if (value.target.value.length < 1) {
      message = 'Password cannot be empty';
    } else if (value.target.value.length > 80) {
      message = 'Password cannot be more than 80 characters';
    } else {
      message = null;
    }
    this.setState({ password: value.target.value, passwordMessage: message });
  };

  // <--- check the credentials and save the token in the session --->
  handleLogin = () => {
    const {
      membershipID, membershipIDMessage, password, passwordMessage,
    } = this.state;
    if (membershipIDMessage === null && passwordMessage === null) {
      const pswToSend = formatePhoneNumber(password);
      authenticationAPI.authentication
        .login(membershipID, pswToSend)
        .then((success) => {
          this.redirectLoggedUser(success.data);
        })
        .catch((error) => {
          console.log(error);
          this.sendServerErrors(error.response.status);
        });
    } else {
      this.setState({
        membershipIDMessage: membershipIDMessage === '' ? 'Required field!' : membershipIDMessage,
        passwordMessage: passwordMessage === '' ? 'Required field!' : passwordMessage,
      });
    }
  };

  redirectLoggedUser = (response) => {
    const { match, history } = this.props;
    if (response.userType === 'Member' || response.userType === 'provisory member') {
      Cookies.set('token', response.token, { expires: 1 });
      if (match.params.action && match.params.action === 'waitingListPayment') {
        return window.location = `/scccPortal/programs/programRegistration/${match.params.action}/${match.params.param1}/${match.params.param2}/${match.params.param3}`;
      } else {
        sessionStorage.clear();
        window.location = '/scccPortal/membership';
      }
    } else {
      this.setState({ invalidEmail: '', InvalidPassword: 'hidden' });
    }
  };

  sendServerErrors = (statusCode) => {
    if (statusCode === 401) {
      return this.setState({ InvalidPassword: '', invalidEmail: 'hidden' });
    }
    if (statusCode === 400) {
      return this.setState({ invalidEmail: '', InvalidPassword: 'hidden' });
    }
    if (statusCode === 500 || statusCode === 404) {

      return this.setState({
        openAlert: true,
        alertLocation: '/memberLogin',
        alertTitle: `SERVER ERROR! ${statusCode}`,
        alertBody: `Please contact Scadding Court Community Centre for support. Contact information: (416)-392-0335`,
        alertClose: 'hidden',
        alertStay: 'CLOSE',
      });
    }
  };

  handleEnterPressed = (event) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      this.handleLogin();
    }
  };

  render() {
    const {
      invalidEmail,
      InvalidPassword,
      membershipID,
      membershipIDMessage,
      password,
      passwordMessage,
      openAlert,
      alertLocation,
      alertTitle,
      alertBody,
      alertClose,
      alertStay,
    } = this.state;
    const { history } = this.props;
    return (
      <div className="container text-center mt-4">
        <submit onKeyPress={this.handleEnterPressed}>
          <div className="row  header text-center pt-4">
            <div className="col-12 text-left">
              <div className="-Back-to-Member-lis" onClick={() => history.push('/')}>
                {' '}
                &lt; Back to onboarding{' '}
              </div>
            </div>
            <div className="col-12 text-center mt-5 ">
              <img src={logo} alt="Logo" />
            </div>
          </div>
          <div className="d-flex flex-row justify-content-center flex-wrap">
            <div className="shadow-sm login-box ">
              <div className="row">
                <div className="col-12">
                  <span className="Admin-Login ">Member Login </span>
                  {/* TODO: Removed, because now the user can create their password at the first time
                  <HelpIcon
                    className="memberLogin-icon"
                    onMouseEnter={(event) => this.setState({ mousePopOver: event.currentTarget })}
                    onMouseLeave={() => this.setState({ mousePopOver: null })}
                    onClick={(event) => this.setState({ mousePopOver: event.currentTarget })}
                  />
                  <MousePopOver open={mousePopOver} message="*First Time login Tip: Password is the emergency contact phone number" /> */}
                </div>
                <div className="col-12" id={invalidEmail}>
                  <span className="text-danger">User Not found! Please Try Again.</span>
                </div>
                <div className="col-12" id={InvalidPassword}>
                  <span className="text-danger">Invalid Password! Please Try Again.</span>
                </div>
                <div className="col-12 mt-5">
                  <TextField
                    label="Membership ID/ Membership Card"
                    id="membershipID"
                    value={membershipID}
                    handleChanges={(value) => this.handleMembershipId(value)}
                    helperText={membershipIDMessage}
                    error={membershipIDMessage !== '' && membershipIDMessage !== null}
                    readOnly={false}
                  />
                </div>
                <div className="col-12 mt-3">
                  <TextField
                    label="Password"
                    id="Name"
                    value={password}
                    handleChanges={(value) => this.handlePassword(value)}
                    helperText={passwordMessage}
                    error={passwordMessage !== '' && passwordMessage !== null}
                    readOnly={false}
                    type="password"
                  />
                </div>
                <div className="col-12 mt-3 text-right">
                  <Link to="/memberForgotPassword" className="links ">
                    <span className="FORGOT-PASSWORD mr-2">FORGOT PASSWORD?</span>
                  </Link>
                </div>
                <div className="col-12 mt-5 text-center">
                  <Btn
                    label="Login"
                    onClick={() => this.handleLogin()}
                    bgColor="#e76f50"
                    color="white"
                    bgColorHover="#ff8c68"
                    colorHover="white"
                    width={window.innerWidth < 600 ? 295 : 395}
                    height={42}
                  />
                </div>
                <div className="col-12 mt-3">
                  <hr />
                </div>
                <div className="col-12 text-right">
                  <span className="memberLogin-notAMember">NOT A MEMBER? </span>
                  <Link to="/scccPortal/membership" className="links ">
                    <span className="FORGOT-PASSWORD mr-2">SIGN UP</span>
                  </Link>
                </div>
              </div>
              <Alert open={openAlert} close={() => this.setState({ openAlert: false })} location={alertLocation} title={alertTitle} body={alertBody} stay={alertStay} leave={alertClose} />
            </div>
          </div>
        </submit>
      </div>
    );
  }
}

MemberLogin.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      action: PropTypes.string,
      param1: PropTypes.string,
      param2: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
