import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import TextField from '../../../../../materialUi/textField';
import Dropdown from '../../../../../materialUi/dropdown';
import '../../../../../css/taxes.css';

function SectionOne(props) {
  const {
    control,
    formState: { errors },
    data,
  } = props;

  const [status] = React.useState(null);
  const statusList = [
    {
      name: 'Canadian Citizen',
    },
    {
      name: 'Permanent Resident',
    },
    {
      name: 'Protected Person',
    },
    {
      name: 'Convention Refugee',
    },
    {
      name: 'Refugee Claimant ',
    },
    {
      name: 'Study Permit',
    },
    {
      name: 'Work Permit',
    },
    {
      name: 'Other',
    },
  ];


  return (
    <>
      <Grid item xs={12}>
        <div className="taxes-question">1. What is your name?</div>
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          name="fullName"
          control={control}
          rules={{
            required: 'Full name is required',
            maxLength: { value: 100, message: 'Max length 100' },
          }}
          render={({ field }) => (
            <TextField
              id="fullName"
              type="search"
              name="FullName"
              label="Full name"
              helperText={errors.fullName ? errors.fullName.message : ''}
              error={!!errors.fullName}
              width="100%"
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">2. Please provide a source of contact to reach you. </div>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            name="email"
            control={control}
            rules={{
              required: 'Email is required',
              maxLength: { value: 100, message: 'Max length 100' },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Invalid Email Format',
              },
            }}
            render={({ field }) => (
              <TextField
                id="email"
                type="search"
                name="email"
                label="Email address"
                helperText={errors.email ? errors.email.message : ''}
                error={!!errors.email}
                width="100%"
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm>
          <Controller
            name="homePhone"
            control={control}
            rules={{
              required: 'Home Phone is required',
              maxLength: { value: 20, message: 'Max length 20' },
              pattern: {
                value: /^[\]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/i,
                message: 'Invalid Phone Format',
              },
            }}
            render={({ field }) => (
              <TextField
                id="homePhone"
                type="search"
                name="homePhone"
                label="Home Phone"
                helperText={errors.homePhone ? errors.homePhone.message : ''}
                error={!!errors.homePhone}
                width="100%"
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm>
          <Controller
            name="workPhone"
            control={control}
            rules={{
              maxLength: { value: 20, message: 'Max length 20' },
              pattern: {
                value: /^[\]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/i,
                message: 'Invalid Phone Format',
              },
            }}
            render={({ field }) => (
              <TextField
                id="workPhone"
                type="search"
                name="workPhone"
                label="Home Phone"
                helperText={errors.workPhone ? errors.workPhone.message : 'Optional'}
                error={!!errors.workPhone}
                width="100%"
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">3. What is your status in Canada? </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          name="canadaStatus"
          control={control}
          rules={{
            required: 'Status is required',
          }}
          defaultValue={data.canadaStatus}
          render={({ field }) => (
            <Dropdown
              name="canadaStatus"
              label="Status"
              id="status"
              value={status}
              handleChanges={() => {}}
              helperText={errors.canadaStatus ? errors.canadaStatus.message : ''}
              error={!!errors.canadaStatus}
              listName={statusList}
              {...field}
            />
          )}
        />
      </Grid>
    </>
  );
}

SectionOne.propTypes = {
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  formState: PropTypes.shape({
    errors: PropTypes.any,
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
  data: PropTypes.shape(
    {
      status: PropTypes.string,
    },
  ).isRequired,
};

export default SectionOne;
