import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';

function createData(name, programName, startTime, date, status, membershipID) {
  return {
    name, programName, startTime, date, status, membershipID,
  };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Name',
  },
  {
    id: 'programName', numeric: false, disablePadding: false, label: 'Program Name',
  },
  {
    id: 'startTime', numeric: false, disablePadding: false, label: 'Start Time',
  },
  {
    id: 'date', numeric: false, disablePadding: false, label: 'Date',
  },
  {
    id: 'status', numeric: true, disablePadding: false, label: 'Status',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="bg-light">
      <TableRow>
        <TableCell padding="checkbox" className="check" />
        {headRows.map((row) => (
          <TableCell
            key={row.id}
            align={row.numeric ? 'center' : 'left'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
            className="table-cell"
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}

            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const EnhancedTableToolbar = (props) => (<div />

);

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));


export default function MemberAttendanceTable({
  data,
  programID,
  programStartTime,
  searchItem,
  values,
  notFoundMessage,
}) {
  const rows = [];

  for (let i = 0; i < data.length; i++) {
    if (data[i].programID === programID) {
      if (values.startDate !== '' && values.endDate !== '') {
        const dateAdjustment = new Date(data[i].date);
        dateAdjustment.setDate(dateAdjustment.getDate());
        if (
          new Date(data[i].date).toISOString() >= new Date(values.startDate).toISOString()
          && new Date(dateAdjustment).toISOString() <= new Date(values.endDate).toISOString()
        ) {
          if (searchItem) {
            if (
              data[i].name.toLowerCase().includes(searchItem.toLowerCase())
            ) {
              rows.push(
                createData(
                  data[i].name, data[i].programName,
                  data[i].startTime,
                  data[i].date,
                  data[i].status,
                  data[i].membershipID,
                ),
              );
            }
          } else {
            rows.push(
              createData(
                data[i].name, data[i].programName,
                data[i].startTime,
                data[i].date,
                data[i].status,
                data[i].membershipID,
              ),
            );
          }
        }
      } else if (searchItem) {
        if (
          data[i].name.toLowerCase().includes(searchItem.toLowerCase())
        ) {
          rows.push(
            createData(
              data[i].name, data[i].programName,
              data[i].startTime,
              data[i].date,
              data[i].status,
              data[i].membershipID,
            ),
          );
        }
      } else {
        rows.push(
          createData(
            data[i].name, data[i].programName,
            data[i].startTime,
            data[i].date,
            data[i].status,
            data[i].membershipID,
          ),
        );
      }
    }
  }


  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('date');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }


  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }


  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.date}
                    >
                      <TableCell padding="checkbox" className="check" />
                      <TableCell component="th" id={labelId} scope="row" padding="none" className="table-cell text-capitalize">
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.programName}</TableCell>
                      <TableCell align="left">{programStartTime}</TableCell>
                      <TableCell align="left" className="Members"><Moment date={row.date} format="DD/MM/YYYY" /></TableCell>
                      <TableCell align="center">
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <div className={`memberAttendance-${row.status}`}>{row.status}</div>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }} />
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

    </div>
  );
}


MemberAttendanceTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  searchItem: PropTypes.string.isRequired,
  programID: PropTypes.string.isRequired,
  programStartTime: PropTypes.string.isRequired,
  values: PropTypes.shape({
    startDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    endDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
  }).isRequired,
  notFoundMessage: PropTypes.string.isRequired,
};
