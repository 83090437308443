import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import Header from './components/header';
import ReceiptTable from './components/receiptsTable';
import { ReceiptsAPI } from '../../../API/API-receipts';
import TableExport from './components/tableExport';
import ReceiptAlert from './components/receiptAlert';
import SendEmail from './components/sendEmail';
import '../../../css/receipts.css';


class Receipts extends Component {
  constructor(props) {
    super(props);
    const now = new Date();
    const newEndDate = now.setDate(now.getDate() + 1);
    this.state = {
      receiptsData: [],
      search: '',
      status: [],
      selectedItems: [],
      receiptAlert: false,
      sendEmailAlert: false,
      selectedReceiptData: {},
      values: {
        startDate: '',
        endDate: newEndDate,
        approved: '',
        refunded: '',
      },
      messages: {
        startDate: '',
        endDate: '',
        approved: '',
        refunded: '',
      },
    };
  }

  componentDidMount() {
    ReceiptsAPI.GET.getReceipts()
      .then((success) => {
        this.setState({ receiptsData: success.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleFilter = (name, value, filterName, selectedAge) => {
    const { values, selectedItems } = this.state;
    if (value === true) {
      values[name] = value;
      this.setState({
        values,
        [filterName]: this.state[filterName].concat([selectedAge]),
        selectedItems: selectedItems.concat([
          <Box className="programsList-selectedItems" key={selectedAge} name={name} id={filterName}>
            {selectedAge}
            <i
              label="filterItems"
              className="far fa-times-circle ml-2 "
              onClick={() => this.handleFilter(name, false, filterName, selectedAge)}
              onKeyPress={() => this.handleFilter(name, false, filterName, selectedAge)}
              role="button"
              tabIndex={0}
            />
          </Box>,
        ]),
      });
    } else {
      // remove filter
      const updateArray = this.state[filterName];
      for (let i = 0; i < updateArray.length; i++) {
        if (updateArray[i] === selectedAge) {
          updateArray.splice(i, 1);
        }
      }

      // remove selectedItems
      const updateSelectedItems = this.state.selectedItems;
      for (let index = 0; index < updateSelectedItems.length; index++) {
        if (updateSelectedItems[index].key === selectedAge) {
          updateSelectedItems.splice(index, 1);
        }
      }

      values[name] = value;
      this.setState({ values, [filterName]: updateArray, selectedItems: updateSelectedItems });
    }
  };

  resetFilters = () => {
    const { values } = this.state;
    Object.keys(values).forEach((key) => {
      values[key] = false;
    });
    this.setState({
      values,
      status: [],
      selectedItems: [],
    });
  };

  handleExport = (value) => {
    if (value === 'Print') {
      const printContent = document.getElementById('el').innerHTML;
      document.body.innerHTML = printContent;
      window.print();
      window.location = '/adminPortal/reports/';
    } else {
      const table = document.getElementById('el').innerHTML;
      const convertTable = table
        .toString()
        .replace(/<div>/g, '')
        .toString()
        .replace(/<\/div>/g, '')
        .toString()
        .replace(/<table class="table">/g, '')
        .toString()
        .replace(/<\/table>/g, '')
        .toString()
        .replace(/<td>/g, '')
        .toString()
        .replace(/<\/td>/g, ',')
        .toString()
        .replace(/<tr>/g, '')
        .toString()
        .replace(/<\/tr>/g, '\r\n')
        .toString()
        .replace(/<th scope="col">/g, '')
        .toString()
        .replace(/<\/th>/g, ',')
        .toString()
        .replace(/<thead>/g, '')
        .toString()
        .replace(/<\/thead>/g, '')
        .toString()
        .replace(/<tbody>/g, '')
        .toString()
        .replace(/<\/tbody>/g, '')
        .toString()
        .replace(/<\/t>/g, '')
        .toString()
        .replace(/<\/n>/g, '');
      const myLink = document.createElement('a');
      myLink.download = 'receipts.csv';
      myLink.href = `data:application/csv,${escape(convertTable)}`;
      myLink.click();
    }
  };


  handleDate = (name, value, type, requirement = '') => {
    const { values, messages } = this.state;
    values[name] = value;
    messages[name] = '';
    this.setState({ values, messages });
  };

  handleReset = () => {
    const { values, messages } = this.state;
    const now = new Date();
    const newEndDate = now.setDate(now.getDate() + 1);
    values.startDate = '';
    values.endDate = newEndDate;
    messages.startDate = '';
    messages.endDate = '';
    this.setState({ values, messages });
  };

  render() {
    const {
      membershipID,
      receiptsData,
      search,
      receiptAlert,
      selectedReceiptData,
      values,
      messages,
      sendEmailAlert,
      selectedItems,
      status,
    } = this.state;
    const { history } = this.props;
    return (
      <Grid>
        <Header
          handleBack={() => history.push(`/adminPortal/membership/verifyMembership/${membershipID}`)}
          handleSearch={(value) => this.setState({ search: value })}
          handleExport={this.handleExport}
          handleDate={this.handleDate}
          values={values}
          messages={messages}
          handleReset={this.handleReset}
          status={status}
          selectedItems={selectedItems}
          resetFilters={this.resetFilters}
          handleFilter={this.handleFilter}
          history={history}
        />
        <ReceiptTable
          data={receiptsData}
          searchItem={search}
          handleClick={(value) => this.setState({ receiptAlert: true, selectedReceiptData: value })}
          values={values}
          sortStatus={status}
        />
        <Grid className="table-export" id="el">
          <TableExport data={receiptsData} searchItem={search} values={values} />
        </Grid>
        <ReceiptAlert
          open={receiptAlert}
          close={() => this.setState({ receiptAlert: false })}
          receiptData={selectedReceiptData}
          sendEmail={() => this.setState({ sendEmailAlert: true, receiptAlert: false })}
        />
        <SendEmail
          open={sendEmailAlert}
          close={() => this.setState({ sendEmailAlert: false })}
          receiptData={selectedReceiptData}
        />
      </Grid>
    );
  }
}

export default Receipts;

Receipts.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
