import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import RadioGroup from '../../../../materialUi/radioGroup';
import Regular from './regular';
import Variable from './variable';
import WeeklySchedule from './weeklySchedule';

function SelectSchedule(props) {
  const {
    control,
    register,
    unregister,
    getValues,
    clearErrors,
    watch,
    data,
  } = props;

  const typeOfScheduleList = [
    {
      value: 'regular',
      label: 'Regular',
    },
    {
      value: 'variable',
      label: 'Variable',
    },
  ];

  React.useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' });
    register('typeOfSchedule', 'regular');
    watch('typeOfSchedule');
  }, []);

  const removeRegularValues = () => {
    unregister('days');
    unregister('programStartTime');
    unregister('programEndTime');
    clearErrors('days');
    clearErrors('programStartTime');
    clearErrors('programEndTime');
    delete data.days;
  };

  const removeVariableValues = () => {
    const variableSchedule = getValues('variableSchedule');
    if (variableSchedule) {
      for (let i = 0; i < variableSchedule.length; i++) {
        unregister(`date${i}`);
        unregister(`startTime${i}`);
        unregister(`endTime${i}`);
        clearErrors(`date${i}`);
        clearErrors(`startTime${i}`);
        clearErrors(`endTime${i}`);
      }
      unregister('variableSchedule');
      delete data.variableSchedule;
    }
  };

  const handleTypeOfSchedule = (value) => {
    if (value === 'variable') {
      removeRegularValues();
    }
    if (value === 'regular') {
      removeVariableValues();
    }
    return true;
  };

  return (
    <>
      <Grid item xs={12}>
        <h2 className="addNewProgram-sub-titles">
        Schedule(s)
        </h2>
      </Grid>
      {!data.weeklyProgram ? (
        <>
          <Grid item xs={12} style={{ paddingBottom: '0px' }}>
            <Controller
              name="typeOfSchedule"
              control={control}
              defaultValue="regular"
              rules={{
                validate: (value) => handleTypeOfSchedule(value),
              }}
              render={({ field }) => (
                <RadioGroup
                  listOptions={typeOfScheduleList}
                  ariaLabel="Type Of Schedule"
                  name="Type of Schedule"
                  inline
                  {...field}
                />
              )}
            />
          </Grid>
          {getValues('typeOfSchedule') === 'variable' ? (
            <Grid item xs={12}>
              <Variable {...props} data={data} />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Regular {...props} data={data} />
            </Grid>
          )}
        </>
      ) : (
        <WeeklySchedule {...props} data={data} />
      )}
    </>
  );
}

SelectSchedule.propTypes = {
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  setValue: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
  data: PropTypes.shape(
    {
      typeOfSchedule: PropTypes.string,
      variableSchedule: PropTypes.arrayOf(PropTypes.any),
      programStartTime: PropTypes.string,
      programEndTime: PropTypes.string,
      days: PropTypes.arrayOf(PropTypes.any),
      weeklyProgram: PropTypes.bool,
    },
  ).isRequired,
};

export default SelectSchedule;
