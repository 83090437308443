import React from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import TextField from '../../../../../materialUi/textField';
import Dropdown from '../../../../../materialUi/dropdown';
import Button from '../../../../../materialUi/btn';

function MedicalInformation({
  participant,
  index,
  handleMedicalInformation,
}) {
  const useFormMethods = useForm({ mode: 'all' });

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    watch,
  } = useFormMethods;

  const [displayMedicalForm, setDisplayMedicalForm] = React.useState(true);

  const handleFormSubmit = (formData) => {
    const updatedFamilyMember = { ...participant, ...formData };
    handleMedicalInformation(updatedFamilyMember, index);
    setDisplayMedicalForm(false);
  };

  const handleSend = (formData) => {
    handleFormSubmit(formData)
  }

  const anyMedicationList = [
    { name: 'Yes' },
    { name: 'No' },
  ];

  const medicationWhenList = [
    { name: '12 am' },
    { name: '1 am' },
    { name: '2 am' },
    { name: '3 am' },
    { name: '4 am' },
    { name: '5 am' },
    { name: '6 am' },
    { name: '7 am' },
    { name: '8 am' },
    { name: '9 am' },
    { name: '1 0am' },
    { name: '11 am' },
    { name: '12 pm' },
    { name: '1 pm' },
    { name: '2 pm' },
    { name: '3 pm' },
    { name: '4 pm' },
    { name: '5 pm' },
    { name: '6 pm' },
    { name: '7 pm' },
    { name: '8 pm' },
    { name: '9 pm' },
    { name: '10 pm' },
    { name: '11 pm' },
  ];

  React.useEffect(() => {
    watch('anyMedication');
  }, []);

  return (
    <>
      {displayMedicalForm && (
        <form onSubmit={handleSubmit(handleFormSubmit)} style={{ width: '100%' }}>
          <Grid item xs={12} className="programRegistration-medical-fields">
            <Controller
              name="ohipNumber"
              control={control}
              rules={{
                required: 'OHIP Number is required',
                maxLength: { value: 10, message: 'Max length 10' },
              }}
              render={({ field }) => (
                <TextField
                  id="ohipNumber"
                  type="search"
                  name="ohipNumber"
                  label="OHIP Number"
                  helperText={errors.ohipNumber ? errors.ohipNumber.message : ''}
                  error={!!errors.ohipNumber}
                  width="100%"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <h4 className="programRegistration-medical-titles">
          Does your child have any medical problems (including allergies) of which we should be aware?
            </h4>
          </Grid>
          <Grid item xs={12} className="programRegistration-medical-fields">
            <Controller
              name="medicalInformation"
              control={control}
              rules={{
                maxLength: { value: 100, message: 'Max length 100' },
              }}
              render={({ field }) => (
                <TextField
                  id="medicalInformation"
                  type="search"
                  name="medicalInformation"
                  label="Enter the details"
                  helperText={errors.medicalInformation ? errors.medicalInformation.message : ''}
                  error={!!errors.medicalInformation}
                  width="100%"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <h4 className="programRegistration-medical-titles">
          Does your child have any behavioral issues/special needs of which we should be aware?
            </h4>
          </Grid>
          <Grid item xs={12} className="programRegistration-medical-fields">
            <Controller
              name="specialInstructions"
              control={control}
              rules={{
                maxLength: { value: 100, message: 'Max length 100' },
              }}
              render={({ field }) => (
                <TextField
                  id="specialInstructions"
                  type="search"
                  name="specialInstructions"
                  label="Enter the details"
                  helperText={errors.specialInstructions ? errors.specialInstructions.message : ''}
                  error={!!errors.specialInstructions}
                  width="100%"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <h4 className="programRegistration-medical-titles">
          Is your child taking any medication?
            </h4>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} className="programRegistration-medical-fields">
              <Grid item xs={4} sm={3} lg={2}>
                <Controller
                  name="anyMedication"
                  control={control}
                  rules={{
                    required: 'Please select one',
                  }}
                  render={({ field }) => (
                    <Dropdown
                      name="anyMedication"
                      label=""
                      id="anyMedication"
                      value=""
                      handleChanges={() => {}}
                      helperText={errors.anyMedication ? errors.anyMedication.message : ''}
                      error={!!errors.anyMedication}
                      listName={anyMedicationList}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} lg>
                <Controller
                  name="medicationName"
                  control={control}
                  rules={{
                    required: getValues('anyMedication') === 'Yes' ? 'Medication Name is required' : false,
                    maxLength: { value: 50, message: 'Max length 50' },
                  }}
                  render={({ field }) => (
                    <TextField
                      id="medicationName"
                      type="search"
                      name="medicationName"
                      label="Medication name"
                      helperText={errors.medicationName ? errors.medicationName.message : ''}
                      error={!!errors.medicationName}
                      width="100%"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <Controller
                  name="medicationWhen"
                  control={control}
                  rules={{
                    required: getValues('anyMedication') === 'Yes' ? 'Please select one.' : false,
                  }}
                  render={({ field }) => (
                    <Dropdown
                      name="medicationWhen"
                      label="When"
                      id="medicationWhen"
                      value=""
                      handleChanges={() => {}}
                      helperText={errors.medicationWhen ? errors.medicationWhen.message : ''}
                      error={!!errors.medicationWhen}
                      listName={medicationWhenList}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <Controller
                  name="dosage"
                  control={control}
                  rules={{
                    required: getValues('anyMedication') === 'Yes' ? 'Dosage is required' : false,
                    maxLength: { value: 50, message: 'Max length 50' },
                  }}
                  render={({ field }) => (
                    <TextField
                      id="dosage"
                      type="search"
                      name="Dosage"
                      label="Dosage"
                      helperText={errors.dosage ? errors.dosage.message : ''}
                      error={!!errors.dosage}
                      width="100%"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="doctorName"
                  control={control}
                  rules={{
                    required: 'Doctor Name is required',
                    maxLength: { value: 50, message: 'Max length 50' },
                  }}
                  render={({ field }) => (
                    <TextField
                      id="doctorName"
                      type="search"
                      name="doctorName"
                      label="Doctor name"
                      helperText={errors.doctorName ? errors.doctorName.message : ''}
                      error={!!errors.doctorName}
                      width="100%"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="doctorNumber"
                  control={control}
                  rules={{
                    required: 'Doctor Number is required',
                    maxLength: { value: 20, message: 'Max length 20' },
                    pattern: {
                      value: /^[\]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/i,
                      message: 'Invalid Phone Format',
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      id="doctorNumber"
                      type="search"
                      name="doctorNumber"
                      label="Doctor number"
                      helperText={errors.doctorNumber ? errors.doctorNumber.message : ''}
                      error={!!errors.doctorNumber}
                      width="100%"
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'end' }}>
            <Button
              label="CONFIRM"
              onClick={handleSubmit(handleSend)}
              bgColor="white"
              color="#e76f50"
              bgColorHover="#eceff1"
              width={144}
              variant="outlined"
              borderColor="#e76f50"
              type="button"
            />
          </Grid>
        </form>
      )}
    </>
  );
}


export default MedicalInformation;
