import React from 'react';
import PropTypes from 'prop-types';
import { CONVERTTIME } from '../../../../../utils/dateController';

function AdminTableExport({
  filteredReports,
  taxVolunteerData,
}) {

  const getTaxVolunteer = (volunteerID) => {
    let volunteerLabel = false;
    for (let i = 0; i < taxVolunteerData.length; i++) {
      if (taxVolunteerData[i]._id === volunteerID) {
        volunteerLabel = `${taxVolunteerData[i].firstName} ${taxVolunteerData[i].lastName}`;
      }
    }
    if (!volunteerLabel) {
      return 'NOT SELECTED';
    }
    return volunteerLabel;
  };

  const formatStatus = (status) => {
    if (status === 'missingInfo') {
      return 'Missing Info';
    }
    if (status === 'inReview') {
      return 'In Review';
    }
    return status;
  };

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Form ID</th>
            <th scope="col">Application Name</th>
            <th scope="col">Submission Date</th>
            <th scope="col">Email</th>
            <th scope="col">Assigned To</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredReports.map((item, index) => (
            <tr key={index}>
              <td>
                {item.formID}
              </td>
              <td>
                {item.fullName}
              </td>
              <td>
                {CONVERTTIME(item.createdAt)}
              </td>
              <td>
                {item.email}
              </td>
              <td>
                {getTaxVolunteer(item.taxVolunteer)}
              </td>
              <td>
                {formatStatus(item.status)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

AdminTableExport.propTypes = {
  filteredReports: PropTypes.arrayOf(PropTypes.shape({
    status: PropTypes.string,
    formID: PropTypes.string,
    fullName: PropTypes.string,
    createdAt: PropTypes.string,
    email: PropTypes.string,
    taxVolunteer: PropTypes.string,
    _id: PropTypes.string,
  })).isRequired,
  taxVolunteerData: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default AdminTableExport;
