import React, { Component } from 'react';
import { Grid, Box } from '@material-ui/core';
import { Covid19API } from '../../../API/API-covid19';
import StatsOverview from './components/statsOverview';
import CheckInHistory from './components/checkInHistory';
import Covid19Table from './components/covid19Table';
import { calculateDailyCheckInMonthData } from '../dashboardController';

class Covid19 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dailyCheckIn: {},
      usersCheckIns: [],
      positivePreScreening: 0,
      negativePreScreening: 0,
      selectedYear: new Date().getFullYear(),
      allDailyCheckIns: [],

    };
  }

  componentDidMount() {
    const dateObj = new Date();
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    const newDate = `${month}-${day}-${year}`;

    // Get the Daily check-in data
    // get user check-in and check the pre-screening results
    Covid19API.GET.getDailyCheckIn(newDate).then((success) => {
      this.setState({
        dailyCheckIn: success.data,
      });

      Covid19API.GET.getUseCheckInByDailyID(success.data.dailyCheckInID).then((response) => {
        let negative = 0;
        let positive = 0;
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].screeningResult === true) {
            negative += 1;
          } else {
            positive += 1;
          }
        }

        this.setState({
          positivePreScreening: positive,
          negativePreScreening: negative,
        });
      }).catch((error) => {
        console.log(error);
      });
    }).catch((error) => {
      console.log(error);
    });

    Covid19API.GET.getUserCheckIns().then((success) => {
      this.setState({
        usersCheckIns: success.data,
      });
    }).catch((error) => {
      console.log(error);
    });

    // Get all daily Check-in and get daily check-ins per month
    const { selectedYear } = this.state;
    Covid19API.GET.getAllDailyCheckIns().then((success) => {
      this.setState({
        allDailyCheckIns: success.data,
        dailyCheckInPerMonth: calculateDailyCheckInMonthData(success.data, selectedYear),
      });
    }).catch((error) => {
      console.log(error);
    });
  }


  handleYearSelection = (value) => {
    const { allDailyCheckIns } = this.state;
    this.setState({
      dailyCheckInPerMonth: calculateDailyCheckInMonthData(allDailyCheckIns, value),
      selectedMonth: value,
    });
  }

  render() {
    const {
      usersCheckIns,
    } = this.state;
    return (
      <Grid item xs className="font-family-default">
        <StatsOverview
         data={this.state}
        />

        <CheckInHistory
          data={this.state}
          handleYearSelection={this.handleYearSelection}
        />
        <Grid container className="root dashboard-tabs-spacing-newMember">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box className="dashboard-tabs-title">Check-in History</Box>
            <Box
              onClick={() => window.location = '/adminPortal/reports/checkInHistory'}
              onKeyPress={() => window.location = '/adminPortal/reports/checkInHistory'}
              role="button"
              tabIndex={0}
              className="dashboard-tabs-viewAll"
            >
              View All
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Covid19Table
              data={usersCheckIns}
            />
          </Grid>
        </Grid>

      </Grid>
    );
  }
}

export default Covid19;

