import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import SearchTextField from '../../../../../materialUi/searchTextField';
import DatePicker from '../../../../../materialUi/datePicker';
import Checkbox from '../../../../../materialUi/checkBox';
import '../../../../../css/receipts.css';

export default function VolunteerFilter({
  handleSearchInput,
  handleIsap,
  isapList,
  handleDates,
  startDate,
  endDate,
  resetDateFilter,
}) {

  const [startDateError, setStartErrorDate] = React.useState(false);
  const [endDateError, setEndErrorDate] = React.useState(false);

  const setDateError = (name, message) => {
    if (name === 'startDate') {
      setStartErrorDate(message);
    } else {
      setEndErrorDate(message);
    }
  }

  const validateDates = (name, value) => {
    if (value && value.toString() === 'Invalid Date') {
      return setDateError(name, 'Invalid Date');
    }
    handleDates(name, value);
    setDateError(name, false);
  }

  return (
    <Grid item xs={12} style={{ marginBottom: '10px' }}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Box className="taxSubmissionList-filter-searchBox ">
          <SearchTextField
            label="Search"
            search={(value) => handleSearchInput(value.target.value)}
            width={200}
          />
        </Box>
        <Box className="dropdown taxSubmissionList-button-filter mr-2">
          <button
            className="btn btn-white dropdown-toggle admin-name"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="taxSubmissionList-isap-dropdown">ISAP</span>
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            {isapList.map((item, index) => (
              <span key={index} className="dropdown-item">
                <Checkbox
                  fontSize={15}
                  bottom={0}
                  value={item.checked}
                  click={() => handleIsap(index)}
                  message={item.label}
                />
              </span>
            ))}
          </div>
        </Box>
        <Box className="taxSubmissionList-filter-date">
          <DatePicker
            label="Start Date"
            id="startDate"
            name="Start Date"
            value={startDate}
            handleChanges={(value) => validateDates('startDate', value)}
            helperText={''}
            error={false}
            readOnly={false}
          />
        </Box>
        <Box className="taxSubmissionList-filter-date-to">
          To
        </Box>
        <Box className="taxSubmissionList-filter-date">
          <DatePicker
            label="End Date"
            id="endDate"
            name="End Date"
            value={endDate}
            handleChanges={(value) => validateDates('endDate', value)}
            helperText={''}
            error={false}
            readOnly={false}
            type="date"
          />
        </Box>
        <Box
          className="taxSubmissionList-filter-restart"
          onClick={resetDateFilter}
          onKeyPress={resetDateFilter}
          tabIndex={0}
          role="button"
        >
          <i className="fas fa-redo-alt" />
        </Box>
      </Grid>
    </Grid>
  );
}

VolunteerFilter.propTypes = {
  handleSearchInput: PropTypes.func.isRequired,
  startDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  endDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  handleDates: PropTypes.func.isRequired,
  resetDateFilter: PropTypes.func.isRequired,
};
