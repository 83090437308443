import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import RadioGroup from '../../../../../materialUi/radioGroup';
import TextField from '../../../../../materialUi/textField';
import '../../../../../css/taxes.css';

function SectionOne(props) {
  const {
    control,
    formState: { errors },
    setValue,
    register,
    unregister,
    data,
  } = props;

  const pastYear = new Date().getFullYear() - 1;

  const YesNoQuestions = [
    {
      value: 'yes',
      label: 'Yes',
    },
    {
      value: 'no',
      label: 'No',
    },
  ];

  const firstTimeTaxPrintList = [
    {
      label: 'Email me a PDF copy to print myself ',
      value: 'email',
    },
    {
      label: 'Pick up form at Scadding Court Community Center',
      value: 'pickUp',
    },
  ];

  const [residing, setResiding] = React.useState('yes');

  const handleResiding = (event) => {
    const { value } = event.target;
    if (value === 'yes') {
      unregister('incomeOutsideCanada');
    } else {
      register('incomeOutsideCanada', { required: 'Please Select One' });
    }
    setValue('residing', value);
    setResiding(value);
  };

  const [firstTimeTax, setFirstTimeTax] = React.useState('no');

  const handleFirstTimeTax = (event) => {
    const { value } = event.target;
    if (value === 'no') {
      unregister('firstTimeTaxPrint');
    } else {
      register('firstTimeTaxPrint', { required: 'Please Select One' });
    }
    setValue('firstTimeTax', value);
    setFirstTimeTax(value);
  };

  React.useEffect(() => {
    if (!data.firstTimeTax) {
      register('firstTimeTax');
      setValue('firstTimeTax', 'no');
    } else {
      setFirstTimeTax(data.firstTimeTax);
      setResiding(data.residing);
    }
  }, [data, register, setValue, setFirstTimeTax, setResiding]);

  return (
    <>
      <Grid item xs={12}>
        <div className="taxes-question">
          7. Is this your first year to file for taxes in Canada?
        </div>
      </Grid>
      <Grid item xs={12}>
        <span className="custom-error">
          {(errors.firstTimeTax) && errors.firstTimeTax.message}
        </span>
        <RadioGroup
          listOptions={YesNoQuestions}
          handleChange={handleFirstTimeTax}
          value={firstTimeTax}
          ariaLabel="6c. Do you have any dependants (Children)? "
          name="dependant"
        />
      </Grid>
      {firstTimeTax === 'yes' && (
        <>
          <Grid item xs={12}>
            <div className="taxes-question">
              7a. If you answered “yes” above, we ask you to please visit
                the Scadding Court Community Centre (707 Dundas Street W) for
                  better assistance. Please select how you would like to print out the document.
            </div>
          </Grid>
          <Grid item xs={12}>
            <span className="custom-error">
              {(errors.firstTimeTaxPrint) && errors.firstTimeTaxPrint.message}
            </span>
            <Controller
              name="firstTimeTaxPrint"
              control={control}
              rules={{
                required: 'Please select One',
              }}
              render={({ field }) => (
                <RadioGroup
                  listOptions={firstTimeTaxPrintList}
                  ariaLabel="7a. If you answered “yes” above, we ask you to please visit
                  the Scadding Court Community Centre (707 Dundas Street W) for
                    better assistance. Please select how you would like to print out the document."
                  name="firstTimeTaxPrint"
                  {...field}
                />
              )}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <div className="taxes-question">
8. Were you residing outside Canada in
          {' '}
          {pastYear}
?
        </div>
      </Grid>
      <Grid item xs={12}>
        <RadioGroup
          listOptions={YesNoQuestions}
          ariaLabel={`8. Were you residing outside Canada in ${pastYear}?`}
          name={`8. Were you residing outside Canada in ${pastYear}?`}
          value={residing}
          handleChange={handleResiding}
        />
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">
          8a. If you answered ‘Yes’ above, please indicate how much income earned in the month(s) living outside Canada for the year
          {' '}
          {pastYear}
.
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          name="incomeOutsideCanada"
          control={control}
          rules={{
            maxLength: { value: 50, message: 'Max length 50' },
            pattern: {
              value: /^(\d*([.,](?=\d{3}))?\d+)+((?!\2)[.,]\d\d)?$/i,
              message: 'Invalid Income Format',
            },
          }}
          render={({ field }) => (
            <TextField
              id="amount"
              type="search"
              name="incomeOutsideCanada"
              label="Income outside Canada"
              helperText={errors.incomeOutsideCanada ? errors.incomeOutsideCanada.message : ''}
              error={!!errors.incomeOutsideCanada}
              width="100%"
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">
            8b. Did you own foreign property in
          {' '}
          {pastYear}
          {' '}
with a total cost over CAN$100,000?
        </div>
      </Grid>
      <Grid item xs={12}>
        <span className="custom-error">
          {(errors.foreignProperty) && errors.foreignProperty.message}
        </span>
        <Controller
          name="foreignProperty"
          control={control}
          rules={{
            required: 'Please select One',
          }}
          render={({ field }) => (
            <RadioGroup
              listOptions={YesNoQuestions}
              ariaLabel={`8b. Did you own foreign property in ${pastYear} with a total cost over CAN$100,000?`}
              name="foreignProperty"
              {...field}
            />
          )}
        />
      </Grid>
    </>
  );
}

SectionOne.propTypes = {
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  formState: PropTypes.shape({
    errors: PropTypes.any,
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
  data: PropTypes.shape(
    {
      firstTimeTax: PropTypes.string,
      residing: PropTypes.string,
    },
  ).isRequired,
};

export default SectionOne;
