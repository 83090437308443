import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import Button from './btn';
import TextField from './textField';
import './style.css';

const SdkPaymentForm = ({ handleSaveOnDB, paymentStatus }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    clearErrors,
  } = useForm();

  const [disableButton, setDisableButton] = React.useState(false);

  const paymentStyle = {
    '.input-container': {
      borderColor: 'transparent',
      borderRadius: '4px',
    },
    '.input-container.is-focus': {
      borderColor: '#4a9d8f',
    },
    input: {
      backgroundColor: 'rgba(0, 0, 0, 0.09)',

   },
  };

  const applicationId = process.env.REACT_APP_SQUARE_CLIENT_ID;
  const locationId = process.env.REACT_APP_SQUARE_LOCATION_ID;

  async function initializeCard(payments) {
    const card = await payments.card({
      style: paymentStyle,
    });
    await card.attach('#card-container');
    return card;
  }

  // Call this function to send a payment token, buyer name, and other detail
  // This function tokenizes a payment method.
  // The ‘error’ thrown from this async function denotes a failed tokenization,
  // which is due to buyer error (such as an expired card). It is up to the
  // developer to handle the error and provide the buyer the chance to fix
  // their mistakes.
  async function tokenize(paymentMethod) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult;
    } else {
      let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
      }
      throw new Error(errorMessage);
    }
  }

  // Helper method for displaying the Payment Status on the screen.
  // status is either SUCCESS or FAILURE;
  function displayPaymentResults(status) {
    const statusContainer = document.getElementById('payment-status-container');
    if (status === 'SUCCESS') {
      statusContainer.classList.remove('is-failure');
      statusContainer.classList.add('is-success');
    } else {
      statusContainer.classList.remove('is-success');
      statusContainer.classList.add('is-failure');
    }

    statusContainer.style.visibility = 'visible';
  }

  //loads the input field for credit card
  async function loaded() {
    if (!window.Square) {
      throw new Error('Square.js failed to load properly');
    }
    const payments = window.Square.payments(applicationId, locationId);
    let card;
    try {
      card = await initializeCard(payments);
      console.log('DONE!');
    } catch (e) {
      console.error('Initializing Card failed', e);
      return;
    }

    async function handlePaymentMethodSubmission(event, paymentMethod, card_name) {
      event.preventDefault();
      const cardButton = document.getElementById('card-button');

      try {
        cardButton.disabled = true;
        setDisableButton(true);
        const nonce = await tokenize(paymentMethod);
        displayPaymentResults('SUCCESS');

        await handleSaveOnDB(nonce.token, paymentMethod, card_name);
      } catch (e) {
        setDisableButton(false);
        cardButton.disabled = false;
        displayPaymentResults('FAILURE');
        console.error(e.message);
      }
    }

    const cardButton = document.getElementById('card-button');

    cardButton.addEventListener('click', async function(event) {
      let card_name = getValues('card_name');
      if (card_name === '' || card_name === undefined || card_name === null) {
        setError('card_name', { message: 'Name on the card is required' });
      } else if (card_name.length < 2) {
        setError('card_name', { message: 'Minimum length must be 2 characters' });
      } else if (card_name.length > 30) {
        setError('card_name', { message: 'Maximum length must be 30 characters' });
      } else {
        clearErrors('card_name');
        await handlePaymentMethodSubmission(event, card, card_name);
      }
    });
  }

  React.useEffect(() => {
    if (paymentStatus === true) return setDisableButton(false);
    loaded();
  }, [paymentStatus]);

  const handleForm = (formData, e) => {};

  const onError = (errors, e) => {
    console.log(errors, e);
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit(handleForm, onError)}>
        <div className="cardName-wrapper">
          <Controller
              name="card_name"
              control={control}
              rules={{
                required: 'Name on the card is required',
                minLength: { value: 2, message: 'Min length 2' },
                maxLength: { value: 30, message: 'Max length 30' },
                pattern: {
                  value: /^(?!\s)(?![\s\S]*\s$)[A-Za-z0-9 ]*[A-Za-z0-9][A-Za-z0-9 ]*$/i,
                  message: 'Can only contain letters and numbers.',
                },
              }}
              render={({ field }) => (
                <TextField
                  id="card_name"
                  type="search"
                  name="card_name"
                  label="Name on the card"
                  helperText={errors.card_name ? errors.card_name.message : ''}
                  error={!!errors.card_name}
                  width="100%"
                  {...field}
                />
              )}
            />
        </div>
        <div id="card-container"></div>
        <Button 
            label="Submit Payment" 
            bgColor="#e76f50" 
            color="white" 
            bgColorHover="#ff8c68" 
            colorHover="white" 
            width="100%" 
            type="submit" 
            addId="card-button" 
            disable={disableButton} 
          />
      </form>
      <div id="payment-status-container"></div>
    </>
  );
};

export default SdkPaymentForm;

