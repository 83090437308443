import React, { Component } from 'react';
import Script from 'react-load-script';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { Grid, Paper } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@material-ui/icons/Close';
import SimpleReactValidator from 'simple-react-validator';
import { DetermineMembershipType } from '../../../../../utils/supportFunctions';
import '../../../../../css/verifyAddress.css';

const GOOGLE_API_KEY = `${process.env.REACT_APP_GOOGLE_API_KEY}`;

class MemberInfo extends Component {
  constructor(props) {
    super(props);
    this.handleScriptLoad = this.handleScriptLoad.bind(this);
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.validator = new SimpleReactValidator();
    this.state = {
      editAddressInput: 'hidden',
      addressMessage: '',
    };
  }

  componentDidUpdate(previousProps) {
    const { addressError } = this.props;
    if (previousProps.addressError !== addressError) {
      if (addressError !== false) this.setState({ addressMessage: <div className="danger">Address Cannot be empty</div> });
    }
  }

  changeAddress=(value) => {
    const { handleInputs } = this.props;
    if (value.target.value.length < 1) {
      this.setState({ addressMessage: <div className="text-danger">Address Cannot be empty</div> });
      handleInputs('', true);
    } else {
      this.setState({ addressMessage: <div className="text-danger">Invalid Address</div> });
    }
  }

  handleEdit = () => {
    const { editAddressInput } = this.state;
    if (editAddressInput === 'text') {
      this.setState({ editAddressInput: 'hidden' });
    } else {
      this.setState({ editAddressInput: 'text' });
    }
  }

  handleScriptLoad() {
    const options = { types: ['address'], componentRestrictions: { country: 'ca' }};
    /* global google */
    this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'), options);
    this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
  }

  handlePlaceSelect() {
    const { handleInputs } = this.props;
    let memberType = '';
    const addressObject = this.autocomplete.getPlace();
    const address = addressObject.address_components;

    if (address) {
      if (
        address[0] === null
        || address[1] === null
        || address[2] === null
        || address[5] === null
        || address[6] === null
        || address[7] === null
        || !/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(address[7].long_name)
      ) {
        handleInputs('', true);
        this.setState({ addressMessage: <div className="text-danger">Invalid Address</div> });
      } else {
        let postalCode;
        memberType = DetermineMembershipType(
          addressObject.geometry.location.lat(),
          addressObject.geometry.location.lng(),
        );
        for (let i = 0; i < address.length; i++) {
          for (let j = 0; j < address[i].types.length; j++) {
            if (address[i].types[j] === 'postal_code') {
              postalCode = address[i].long_name;
            }
          }
        }
        handleInputs(`${address[0].long_name},${address[1].long_name},${address[2].long_name},${address[5].long_name},${postalCode},${memberType}`, false);
        this.setState({ editAddressInput: 'hidden' });
        this.setState({ addressMessage: <div className="text-danger" id="hidden" /> });
      }
    } else {
      handleInputs('', true);
      this.setState({ addressMessage: <div className="text-danger">Invalid Address</div> });
    }
  }

  render() {
    const { membershipData, address } = this.props;
    const { editAddressInput, addressMessage } = this.state;
    return (
      <Grid container>
        <Grid item xs={12} className="verify-memberInfo-spacing">
          <Paper className="verify-member-info">
            <Script
              url={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`}
              onLoad={this.handleScriptLoad}
            />
            <Grid container>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={4} className="first-col-label">
                    First Name:
                  </Grid>
                  <Grid item xs className="second-col-values">
                    {(membershipData.primaryMember) ? membershipData.primaryMember.firstName : ''}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={4} className="first-col-label">
                    Gender:
                  </Grid>
                  <Grid item xs className="second-col-values">
                    {(membershipData.primaryMember) ? membershipData.primaryMember.gender : ''}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={6} style={{ marginTop: '30px' }}>
                <Grid container>
                  <Grid item xs={4} className="first-col-label">
                  Last Name:
                  </Grid>
                  <Grid item xs className="second-col-values">
                    {(membershipData.primaryMember) ? membershipData.primaryMember.lastName : ''}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginTop: '30px' }}>
                <Grid container>
                  <Grid item xs={4} className="first-col-label">
                  Phone#:
                  </Grid>
                  <Grid item xs className="second-col-values">
                    {(membershipData.primaryMember) ? membershipData.primaryMember.phone : ''}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={6} style={{ marginTop: '30px' }}>
                <Grid container>
                  <Grid item xs={4} className="first-col-label">
                  Date of birth:
                  </Grid>
                  <Grid item xs className="second-col-values">
                    {(membershipData.primaryMember) ? <Moment date={membershipData.primaryMember.dateOfBirth} format="MM/DD/YYYY" /> : ''}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginTop: '30px' }}>
                <Grid container>
                  <Grid item xs={4} className="first-col-label">
                    Address:
                  </Grid>
                  <Grid item xs className="second-col-values">
                    <Grid>
                      {(address) ? `${address.street}, ${address.city}, ${address.province}` : ''}
                      <span
                        className={editAddressInput === 'text' ? 'CANCEL-btn' : 'EDIT-btn'}
                        onClick={this.handleEdit}
                        onKeyPress={this.handleEdit}
                        role="button"
                        tabIndex={0}
                      >
                        {editAddressInput === 'text'
                          ? (
                            <>
                              <CloseIcon fontSize="small" style={{ marginRight: '5px' }} />
                              CANCEL
                            </>
                          )
                          : (
                            <>
                              <CreateIcon fontSize="small" style={{ marginRight: '5px' }} />
                              EDIT
                            </>
                          )}
                      </span>

                    </Grid>
                    <input
                      type={editAddressInput}
                      name="address"
                      id="autocomplete"
                      defaultValue={(address) ? `${address.street}, ${address.city}, ${address.province}` : ''}
                      onChange={this.changeAddress}
                      className="form-control input-success"
                    />
                    <Grid item xs={12} className={editAddressInput !== 'text' && 'hidden'}>{addressMessage}</Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

MemberInfo.propTypes = {
  addressError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
  handleInputs: PropTypes.func.isRequired,
  membershipData: PropTypes.shape({
    primaryMember: PropTypes.shape({
      firstName: PropTypes.string,
      gender: PropTypes.string,
      lastName: PropTypes.string,
      phone: PropTypes.string,
      dateOfBirth: PropTypes.string,
    }),
  }).isRequired,
  address: PropTypes.shape({
    city: PropTypes.string,
    province: PropTypes.string,
    street: PropTypes.string,
  }).isRequired,
};

export default MemberInfo;
