import React from 'react';
import PropTypes from 'prop-types';
import '../../../../css/registrationList.css';

export default function MemberAttendanceTableExport({ data, search }) {
  function createData(name, index, status) {
    return { name, index, status };
  }
  const rows = [];
  if (data) {
    for (let i = 0; i < data.length; i++) {
      if (search) {
        if (data[i].name.toLowerCase().includes(search.toLowerCase())) {
          rows.push(
            createData(data[i].name, i, data[i].status.status),
          );
        }
      } else {
        rows.push(
          createData(data[i].name, i, data[i].status),
        );
      }
    }
  }

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Attendance Status</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((value, index) => (
            <tr key={index}>
              <td>
                {value.name}
              </td>
              <td>
                {value.status}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
MemberAttendanceTableExport.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape({
      status: PropTypes.string,
      membershipID: PropTypes.string,
      name: PropTypes.string,
    })),
  ]).isRequired,
  search: PropTypes.string.isRequired,
};
