import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ProgramsAPI } from '../../../API/API-programs';
import Header from './components/header';
import TableExport from './components/memberAttendanceTableExport';
import Alert from '../../../materialUi/Alerts';
import MemberAttendanceTable from './components/memberAttendanceTable';
import { MemberAttendanceAPI } from '../../../API/API-memberAttendance';


export default class MemberAttendance extends Component {
  constructor(props) {
    super(props);
    const { match } = this.props;
    this.state = {
      registrationData: [],
      search: '',
      membershipID: match.params.membershipID,
      programID: match.params.programID,
      programName: '',
      programStartTime: '',
      openAlert: false,
      alertLocation: '',
      alertTitle: '',
      alertBody: '',
      alertStay: '',
      alertClose: '',
      values: {
        startDate: '',
        endDate: '',
      },
      messages: {
        startDate: '',
        endDate: '',
      },
    };
  }

  componentDidMount() {
    const { membershipID, programID } = this.state;
    // <--- get all programs on DB --->
    MemberAttendanceAPI.GET.getOneByMembershipID(membershipID).then((success) => {
      this.setState({ registrationData: success.data });
    }).catch((error) => {
      this.setState({
        openAlert: true,
        alertLocation: '/scccPortal/programs',
        alertTitle: 'Program Attendance not found!',
        alertBody: 'We could not find the program attendance. Please try again later!',
        alertClose: 'CLOSE',
      });
    });
    // get program information
    ProgramsAPI.GET.getProgramByID(programID).then((success) => {
      this.setState({
        programName: success.data.program[0].name,
        programStartTime: success.data.program[0].programStartTime,
      });
    }).catch((error) => {
      console.log(error);
      this.setState({
        openAlert: true,
        alertLocation: '/scccPortal/programs',
        alertTitle: 'Program not found!',
        alertBody: 'We could not find the program. Please try again later!',
        alertClose: 'CLOSE',
      });
    });
  }


  handleDate = (name, value, type, requirement = '') => {
    const { values, messages } = this.state;
    values[name] = value;
    messages[name] = '';
    this.setState({ values, messages });
  }

  handleReset = () => {
    const { values, messages } = this.state;
    values.startDate = '';
    values.endDate = '';
    messages.startDate = '';
    messages.endDate = '';
    this.setState({ values, messages });
  }

  handleExport=(value) => {
    const { membershipID, programID } = this.state;
    if (value === 'Print') {
      const printContent = document.getElementById('el').innerHTML;
      document.body.innerHTML = printContent;
      window.print();
      window.location = `/scccPortal/programs/memberProgramAttendance/${membershipID}/${programID}`;
    } else {
      const table = document.getElementById('el').innerHTML;
      const convertTable = table.toString().replace(/<div>/g, '')
        .toString().replace(/<\/div>/g, '')
        .toString()
        .replace(/<table class="table">/g, '')
        .toString()
        .replace(/<\/table>/g, '')
        .toString()
        .replace(/<td>/g, '')
        .toString()
        .replace(/<\/td>/g, ',')
        .toString()
        .replace(/<tr>/g, '')
        .toString()
        .replace(/<\/tr>/g, '\r\n')
        .toString()
        .replace(/<th scope="col">/g, '')
        .toString()
        .replace(/<\/th>/g, ',')
        .toString()
        .replace(/<thead>/g, '')
        .toString()
        .replace(/<\/thead>/g, '')
        .toString()
        .replace(/<tbody>/g, '')
        .toString()
        .replace(/<\/tbody>/g, '')
        .toString()
        .replace(/<\/t>/g, '')
        .toString()
        .replace(/<\/n>/g, '');
      const myLink = document.createElement('a');
      myLink.download = 'membershipList.csv';
      myLink.href = `data:application/csv,${escape(convertTable)}`;
      myLink.click();
    }
  }

  render() {
    const { history } = this.props;
    const {
      programID,
      membershipID,
      programName,
      programStartTime,
      values,
      messages,
      items,
      registrationData,
      search,
      openAlert,
      alertLocation,
      alertTitle,
      alertBody,
      alertStay,
      alertClose,
    } = this.state;
    return (
      <Grid item xs className="font-family-default">
        <Header
          handleBack={() => window.location = `/scccPortal/membership/verifyMembership/${membershipID}`}
          handleProgramDetails={() => history.push(`/scccPortal/programs/programDetails/${programID}`)}
          programName={programName}
          handleReset={this.handleReset}
          handleDate={this.handleDate}
          handleSearch={(value) => this.setState({ search: value })}
          values={values}
          messages={messages}
          handleExport={this.handleExport}
        />
        <MemberAttendanceTable
          data={registrationData}
          searchItem={search}
          sortItem={items}
          programID={programID}
          programStartTime={programStartTime}
          values={values}
        />
        <Grid className="table-export" id="el">
          <TableExport
            data={registrationData}
            searchItem={search}
            values={values}
            programID={programID}
            programStartTime={programStartTime}
          />
        </Grid>
        <Alert
          open={openAlert}
          close={() => this.setState({ openAlert: false })}
          location={alertLocation}
          title={alertTitle}
          body={alertBody}
          stay={alertStay}
          leave={alertClose}
        />
      </Grid>
    );
  }
}

MemberAttendance.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      membershipID: PropTypes.string,
      programID: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};