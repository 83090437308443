import React from 'react';
import { Grid, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import CancelAlert from './cancelAlert';
import Btn from './btn';

export default function StepsButtons({ handleSubmit, location, type, disable, finalStep, message }) {
  const [openCancelAlert, setOpenCancelAlert] = React.useState();
  return (
    <Grid item xs={12} className="stepsButtons">
      <Grid container direction="row" justifyContent="flex-end" alignItems="center">
        <Box style={{ marginRight: '5px' }}>
          <Btn
            label="Cancel"
            bgColor="white"
            color="black"
            bgColorHover="#eceff1"
            colorHover="black"
            borderColor="white"
            variant="outlined"
            width={128}
            onClick={() => setOpenCancelAlert(true)}
            type="button"
          />
          <CancelAlert
            open={openCancelAlert}
            close={() => setOpenCancelAlert(false)}
            location={location}
            message={message ? message : ''}
          />
        </Box>
        <Btn
          label={finalStep ? 'Done' : 'Next'}
          onClick={handleSubmit}
          bgColor="#e76f50"
          color="white"
          colorHover="white"
          bgColorHover="#ff8c68"
          width={128}
          type={type}
          disable={disable}
        />
      </Grid>
    </Grid>
  );
}

StepsButtons.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired,
  type: PropTypes.string,
  disable: PropTypes.bool,
  finalStep: PropTypes.bool,
};

StepsButtons.defaultProps = {
  type: 'button',
  disable: false,
  finalStep: false,
};
