import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableRow from '@material-ui/core/TableRow';
import { CONVERTTIME } from '../../../../utils/dateController';
import '../../../../css/receipts.css';

function createData(
  membershipID,
  name,
  category,
  paymentDate,
  paymentMethod,
  amount,
  status,
  address,
  cardType,
  referenceNumber,
  paymentDateISO,
  description
) {
  return {
    membershipID,
    name,
    category,
    paymentDate,
    paymentMethod,
    amount,
    status,
    address,
    cardType,
    referenceNumber,
    paymentDateISO,
    description
  };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

// collums titles
const headRows = [
  {
    id: 'membershipID',
    numeric: false,
    disablePadding: true,
    label: 'Membership ID',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'category',
    numeric: false,
    disablePadding: false,
    label: 'Category',
  },
    {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
  },
  {
    id: 'paymentDate',
    numeric: false,
    disablePadding: false,
    label: 'Date of Payment',
  },
  {
    id: 'paymentMethod',
    numeric: false,
    disablePadding: false,
    label: 'Payment Method',
  },
  {
    id: 'amount',
    numeric: false,
    disablePadding: false,
    label: 'Amount',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="bg-light">
      <TableRow>
        {headRows.map((row, index) => (
          <TableCell
            key={index}
            align={row.numeric ? 'center' : 'left'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
            className="table-cell"
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

export default function ReceiptsTable({ data, handleClick, searchItem, values, sortStatus }) {
  const rows = [];
  if (data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      // if user search by date
      if (values.startDate !== '' && values.startDate !== false) {
        const dateAdjustment = new Date(data[i].paymentDate);
        dateAdjustment.setDate(dateAdjustment.getDate());
        if (
          new Date(data[i].paymentDate).toISOString() >= new Date(values.startDate).toISOString() &&
          new Date(dateAdjustment).toISOString() <= new Date(values.endDate).toISOString()
        ) {
          if (sortStatus.length > 0) {
            sortStatus.map((status) => {
              if (data[i].status.toLowerCase() === status.toLowerCase()) {
                console.log('DATE - SORT STATUS');
                return rows.push(
                  createData(
                    data[i].membershipID,
                    data[i].name,
                    data[i].category,
                    data[i].paymentDate,
                    data[i].paymentMethod,
                    data[i].fee.toFixed(2),
                    data[i].status,
                    data[i].address,
                    data[i].cardType,
                    data[i].referenceNumber,
                    data[i].paymentDate,
                    data[i].description && data[i].description
                  )
                );
              }
              return '';
            });
          } else {
            // if user search by date, search field
            // eslint-disable-next-line no-lonely-if
            if (searchItem) {
              // if user search by date, search field and by status
              if (
                data[i].name.toLowerCase().includes(searchItem.toLowerCase()) ||
                data[i].referenceNumber
                  .toString()
                  .toLowerCase()
                  .includes(searchItem.toLowerCase()) ||
                data[i].membershipID
                  .toString()
                  .toLowerCase()
                  .includes(searchItem.toLowerCase())
              ) {
                if (sortStatus.length > 0) {
                  sortStatus.map((status) => {
                    if (data[i].status.toLowerCase() === status.toLowerCase()) {
                      console.log('DATE - SEARCH FIELD - SORT STATUS');
                      return rows.push(
                        createData(
                          data[i].membershipID,
                          data[i].name,
                          data[i].category,
                          data[i].paymentDate,
                          data[i].paymentMethod,
                          data[i].fee.toFixed(2),
                          data[i].status,
                          data[i].address,
                          data[i].cardType,
                          data[i].referenceNumber,
                          data[i].paymentDate,
                          data[i].description && data[i].description
                        )
                      );
                    }
                    return '';
                  });
                } else {
                  console.log('DATE - SEARCH FIELD');
                  rows.push(
                    createData(
                      data[i].membershipID,
                      data[i].name,
                      data[i].category,
                      data[i].paymentDate,
                      data[i].paymentMethod,
                      data[i].fee.toFixed(2),
                      data[i].status,
                      data[i].address,
                      data[i].cardType,
                      data[i].referenceNumber,
                      data[i].paymentDate,
                      data[i].description && data[i].description
                    )
                  );
                }
              }
            } else {
              console.log('DATE');
              rows.push(
                createData(
                  data[i].membershipID,
                  data[i].name,
                  data[i].category,
                  data[i].paymentDate,
                  data[i].paymentMethod,
                  data[i].fee.toFixed(2),
                  data[i].status,
                  data[i].address,
                  data[i].cardType,
                  data[i].referenceNumber,
                  data[i].paymentDate,
                  data[i].description && data[i].description
                )
              );
            }
          }
        }
      } else if (searchItem) {
        if (
          data[i].name.toLowerCase().includes(searchItem.toLowerCase()) ||
          data[i].referenceNumber
            .toString()
            .toLowerCase()
            .includes(searchItem.toLowerCase()) ||
          data[i].membershipID
            .toString()
            .toLowerCase()
            .includes(searchItem.toLowerCase())
        ) {
          if (sortStatus.length > 0) {
            console.log('SEARCH ITEM - SORT STATUS');
            sortStatus.map((status) => {
              if (data[i].status.toLowerCase() === status.toLowerCase()) {
                return rows.push(
                  createData(
                    data[i].membershipID,
                    data[i].name,
                    data[i].category,
                    data[i].paymentDate,
                    data[i].paymentMethod,
                    data[i].fee.toFixed(2),
                    data[i].status,
                    data[i].address,
                    data[i].cardType,
                    data[i].referenceNumber,
                    data[i].paymentDate,
                    data[i].description && data[i].description
                  )
                );
              }
              return '';
            });
          } else {
            console.log('SEARCH ITEM');
            rows.push(
              createData(
                data[i].membershipID,
                data[i].name,
                data[i].category,
                data[i].paymentDate,
                data[i].paymentMethod,
                data[i].fee.toFixed(2),
                data[i].status,
                data[i].address,
                data[i].cardType,
                data[i].referenceNumber,
                data[i].paymentDate,
                data[i].description && data[i].description
              )
            );
          }
        }
      } else if (sortStatus.length > 0) {
        if (searchItem) {
          if (
            data[i].name.toLowerCase().includes(searchItem.toLowerCase()) ||
            data[i].referenceNumber
              .toString()
              .toLowerCase()
              .includes(searchItem.toLowerCase()) ||
            data[i].membershipID
              .toString()
              .toLowerCase()
              .includes(searchItem.toLowerCase())
          ) {
            sortStatus.map((status) => {
              if (data[i].status.toLowerCase() === status.toLowerCase()) {
                console.log('SORT STATUS - SEARCH ITEM');
                return rows.push(
                  createData(
                    data[i].membershipID,
                    data[i].name,
                    data[i].category,
                    data[i].paymentDate,
                    data[i].paymentMethod,
                    data[i].fee.toFixed(2),
                    data[i].status,
                    data[i].address,
                    data[i].cardType,
                    data[i].referenceNumber,
                    data[i].paymentDate,
                    data[i].description && data[i].description
                  )
                );
              }
              return '';
            });
          }
        } else {
          console.log('SORT STATUS');
          sortStatus.map((status) => {
            if (data[i].status.toLowerCase() === status.toLowerCase()) {
              return rows.push(
                createData(
                  data[i].membershipID,
                  data[i].name,
                  data[i].category,
                  data[i].paymentDate,
                  data[i].paymentMethod,
                  data[i].fee.toFixed(2),
                  data[i].status,
                  data[i].address,
                  data[i].cardType,
                  data[i].referenceNumber,
                  data[i].paymentDate,
                  data[i].description && data[i].description
                )
              );
            }
            return '';
          });
        }
      } else {
        console.log('ALL');
        rows.push(
          createData(
            data[i].membershipID,
            data[i].name,
            data[i].category,
            CONVERTTIME(data[i].paymentDate, true),
            data[i].paymentMethod,
            data[i].fee.toFixed(2),
            data[i].status,
            data[i].address,
            data[i].cardType,
            data[i].referenceNumber,
            data[i].paymentDate,
            data[i].description && data[i].description
          )
        );
      }
    }
  }

  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('paymentDate');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  return (
    <Grid className="font-family-default">
      <Grid className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
          />
          <TableBody>
            {stableSort(rows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                const receiptData = {
                  name: row.name,
                  address: row.address,
                  category: row.category,
                  paymentDate: row.paymentDate,
                  paymentMethod: row.paymentMethod,
                  cardType: row.cardType,
                  amount: row.amount,
                  id: row.referenceNumber,
                  paymentDateISO: row.paymentDateISO,
                  status: row.paymentStatus,
                  description: row.description
                };
                return (
                  <TableRow
                    hover
                    onClick={() => handleClick(receiptData)}
                    role="button"
                    tabIndex={1}
                    className="table-tabSelection"
                    key={index}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      className="table-cell"
                    >
                      {row.membershipID}
                    </TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">{row.category}</TableCell>
                    <TableCell align="left">{row.description}</TableCell>
                    <TableCell align="left">
                      <Moment date={row.paymentDate} format="MM/DD/YYYY" />
                    </TableCell>
                    <TableCell align="left">{row.paymentMethod}</TableCell>
                    <TableCell align="left">${row.amount}</TableCell>
                    <TableCell align="left">
                      <Box
                        className={
                          row.status === 'Approved'
                            ? 'receipt-table-approved'
                            : 'receipt-table-canceled'
                        }
                      >
                        {row.status}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && <TableRow style={{ height: 49 * emptyRows }} />}
          </TableBody>
        </Table>
      </Grid>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Grid>
  );
}

ReceiptsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  handleClick: PropTypes.func.isRequired,
  searchItem: PropTypes.string.isRequired,
  sortStatus: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  values: PropTypes.shape({
    startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  }).isRequired,
};
