import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

// Membership Attendance
import MembershipAttendance from '../programs/memberAttendance/memberAttendance';

/* < ---- Programs ---- > */

import ProgramDetails from '../../pages/programs/programDetails/programDetails';
import ProgramRegistration from '../../pages/programs/programRegistration/programRegistration';

//Crate Attendees without registration
import AddAttendance from '../../pages/programs/addAttendance/addAttendance';
import CreateAttendance from '../programs/createAttendanceWithoutRegistration/createAttendanceWithoutRegistration';
import EditAttendance from '../programs/editAttendance/editAttendance';

import ProgramsList from '../programs/programsList/programsList';

// Registration List
import RegistrationList from '../programs/registrationList/registrationList';

// Registration Details
import RegistrationDetails from '../programs/registrationDetails/registrationDetails';

import WaitingListPayment from '../../pages/programs/waitingListPayment/waitingListPayment';

// Attendance List
import AddNewAttendance from '../programs/addNewAttendance/addNewAttendance';
import AttendanceListDetails from '../programs/attendanceListDetails/attendanceListDetails';

// Submitted Attendance list
import SubmitedAttendances from '../programs/submitedAttendances/submitedAttendances';

// settings
import ProfileSettings from '../profile/profile';

const ProgramStaffRoutes = function(userData) {
  return (
    <Switch>
      {/* Programs Routes */}
      <Route path="/adminPortal/programs/waitingList/registration/:programID/:waitingListID/:membershipID/:step?" render={(props) => <WaitingListPayment {...props} userData={userData} />} />
      <Route path="/adminPortal/programs/registrationDetails/:programID/:membershipID/:familyMemberID" render={(props) => <RegistrationDetails {...props} userData={userData} />} />
      <Route path="/adminPortal/programs/memberProgramAttendance/:membershipID/:programID" component={MembershipAttendance} />
      <Route path="/adminPortal/programs/attendanceListDetails/:attendanceListDate/:programID" render={(props) => <AttendanceListDetails {...props} userData={userData} />} />
      <Route path="/adminPortal/programs/createattendance/:programID" render={(props) => <CreateAttendance {...props} userData={userData} />}/>
      <Route path="/adminPortal/programs/editattendance/:programID/:attendeenceID" render={(props) => <EditAttendance {...props} userData={userData} />}/>
      <Route path="/adminPortal/programs/AddAttendance/:programID/:backPage?" render={(props) => <AddAttendance {...props} userData={userData} />} />
      <Route path="/adminPortal/programs/addNewAttendance/:programID" component={AddNewAttendance} />
      <Route path="/adminPortal/programs/submitedAttendances/:programID" component={SubmitedAttendances} />
      <Route path="/adminPortal/programs/registrationList/:programID" render={(props) => <RegistrationList {...props} userData={userData} />} />
      <Route path="/adminPortal/programs/programRegistration/:programID/:backPage?" render={(props) => <ProgramRegistration {...props} userData={userData} />} />
      <Route path="/adminPortal/programs/programDetails/:programID" render={(props) => <ProgramDetails {...props} userData={userData} />} />
      <Route path="/adminPortal/programs" render={(props) => <ProgramsList {...props} userData={userData} />} />

      {/* Profile */}
      <Route path="/adminPortal/profile" render={(props) => <ProfileSettings {...props} userData={userData} />} />

      <Route path="*" exact render={(props) => <ProgramsList {...props} userData={userData} />} />
    </Switch>
  );
};

export default ProgramStaffRoutes;

ProgramStaffRoutes.propTypes = {
  userData: PropTypes.shape({
    userType: PropTypes.string,
  }).isRequired,
};
