import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import Header from './layout/header';
import Sidebar from './layout/sideBar';
import { authenticationAPI } from '../API/API-authentication';
import AssignRoutes from './routes/assignRoutes';
import Loading from '../materialUi/loading';

class adminPortal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: '',
    };
  }

  async componentDidMount() {
    // Authenticate the admin user login
    await authenticationAPI.authentication
      .getUser(Cookies.get('token'))
      .then((success) => {
        this.validateUser(success.data);
      })
      .catch(() => {
        this.redirectUserToAdminLogin();
      });
  }

  validateUser = (data) => {
    if (data.userType === 'Member' || data.userType === false) {
      this.props.history.push('/');
    } else {
      this.setState({ userData: data });
      Cookies.set('userInfo', { userType: data.userType });
    }
  };

  redirectUserToAdminLogin = () => {
    this.setState({ userData: false });
    Cookies.set('userInfo', {});
  };

  render() {
    const { userData } = this.state;
    if (userData !== false) {
      return (
        <Grid>
          <BrowserRouter>
            <Header userData={userData} />
            <Grid container>
              {userData.userType !== 'Security' && <Sidebar userData={userData} />}
              <Grid item xs>
                {AssignRoutes(userData)}
              </Grid>
            </Grid>
          </BrowserRouter>
        </Grid>
      );
    }
    window.location = '/admin';
    return (
      <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Loading/>
    </Grid>
    )
  }
}

export default adminPortal;
