import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Dropdown from '../../../../../materialUi/dropdown';
import Button from '../../../../../materialUi/btn';
import PaymentForm from '../../../../../materialUi/paymentForm';
import ApproveDiscountDialog from './approveDiscount';
import '../../../../../css/payment.css';

function PaymentMethod(
  {
    handleSaveOnDB,
    paymentMethod,
    setPaymentMethod,
    paymentStatus,
    handleFee,
    initialFee,
    userData
  }
) {
  const [discount, setDiscount] = React.useState('-');
  const [openDialog, setOpenDialog] = React.useState(false);

  const discountList = [
    { name: '-' },
    { name: '10%' },
    { name: '20%' },
    { name: '30%' },
    { name: '40%' },
    { name: '50%' },
    { name: 'Free' },
  ];

  const publicPaymentOptions = [
    { name: 'Credit card' },
    { name: 'Cash' }
  ]

  const adminPaymentOptions = [
    { name: 'Credit card' },
    { name: 'Debit card' },
    { name: 'Apple pay' },
    { name: 'Cash' }
  ]


  const [paymentMethods, setLocalPaymentMethod] = React.useState(userData ? adminPaymentOptions : publicPaymentOptions);

  const calculateDiscount = (percentage) => ((percentage / 100) * -initialFee + initialFee).toFixed(2);


  const getDiscount = (selectedDiscount) => {
    setLocalPaymentMethod(
      [
        { name: 'Credit card' },
        { name: 'Debit card' },
        { name: 'Apple pay' },
        { name: 'Cash' }
      ]);
    if (selectedDiscount === '-') {
      return initialFee;
    }
    if (selectedDiscount === '10%') {
      return calculateDiscount(10);
    }
    if (selectedDiscount === '20%') {
      return calculateDiscount(20);
    }
    if (selectedDiscount === '30%') {
      return calculateDiscount(30);
    }
    if (selectedDiscount === '40%') {
      return calculateDiscount(40);
    }
    if (selectedDiscount === '50%') {
      return calculateDiscount(50);
    }
    setLocalPaymentMethod([{ name: 'Cash' }]);
    setPaymentMethod('Cash');
    return 'Free';
  };

  const handleDiscountPasswordValidation = (validation) => {
    if (validation) {
      const newFee = getDiscount(discount);
      handleFee(newFee);
      setOpenDialog(false);
    }
  };

  const handleSendCardInformation = (nonce, paymentMethod, full_name) => {
    handleSaveOnDB(nonce, full_name);
  };

  const handleSendCashPayment = () => {
    handleSaveOnDB(false, false, paymentMethod.toLowerCase());
  }

  const handleDiscount = (event) => {
    const { value } = event.target;
    const newFee = getDiscount(value);
    if (value === '50%' || value === 'Free') {
      setOpenDialog(true);
    } else {
      handleFee(newFee);
    }
    setDiscount(value);
  };

  const validateShowDiscount = () => {
    if (!userData) return false;
    const { userType } = userData.userData;
    if (
      userType.toLowerCase() !== 'member' ||
      userType.toLowerCase() !== 'provisory member'
    ) return true;
    return false;
  };

  return (
    <Grid item xs={12} md className="paymentMethod-col">
      <Grid container spacing={2}>
        <Grid item xs={12} md={validateShowDiscount() ? 6 : 12}>
          <Dropdown
            name="Payment"
            value={paymentMethod}
            handleChanges={(value) => setPaymentMethod(value.target.value)}
            error={false}
            helperText=""
            label="Payment method"
            listName={paymentMethods}
          />
        </Grid>
        {validateShowDiscount() && (
          <Grid item xs={12} md={6}>
            <Dropdown
              name="Discount"
              value={discount}
              handleChanges={handleDiscount}
              error={false}
              helperText=""
              label="Discount(%)"
              listName={discountList}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          {paymentMethod === 'Credit card' ? (
            <PaymentForm handleSaveOnDB={handleSendCardInformation} paymentStatus={paymentStatus} />
          ) : (
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                label="Next"
                bgColor="#e76f50"
                color="white"
                type="button"
                bgColorHover="#ff8c68"
                colorHover="white"
                width={165}
                onClick={handleSendCashPayment}
              />
            </Grid>
          )}
        </Grid>
        <ApproveDiscountDialog
          open={openDialog}
          close={() => setOpenDialog(false)}
          handleValidation={handleDiscountPasswordValidation}
        />
      </Grid>
    </Grid>
  );
}

PaymentMethod.propTypes = {
  handleSaveOnDB: PropTypes.func.isRequired,
};

export default PaymentMethod;
