import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Btn from '../../../materialUi/btn';
import SearchTextField from '../../../materialUi/searchTextField';
import UserFoundIcon from '../../../assets/icons/userFound.svg';
import AlertIcon from '../../../assets/icons/alert-circle.svg';

function searchBox({ handleSearchUsers, usersFound, handleSearch, handleAddNewGuest, handleCheckout }) {
  return (
    <>
      <Grid container className="covid19-checkIn-box">
        <Grid item xs={12} className="covid19-checkIn-title-wrapper">
          <h1 className="covid19-checkIn-title">Check In</h1>
        </Grid>
        <Grid item xs={12}>
          <SearchTextField label="Search by name, phone# or email" search={(value) => handleSearch(value)} width={window.innerWidth < 600 ? 250 : 295} />
        </Grid>
        <Grid item xs={12} className="covid19-checkIn-btn-wrapper">
          <Btn label="SEARCH" onClick={handleSearchUsers} bgColor="#e76f50" color="white" bgColorHover="#ff8c68" colorHover="white" height={36} width={window.innerWidth < 600 ? 250 : 295} />
        </Grid>
        <Grid item xs={12} className="covid19-usersFound-wrapper">
          <Grid container direction="row" justifyContent="center" alignItems="center">
            {usersFound && (
              <>
                {usersFound.length > 0 && (
                  <h3 className="covid19-usersFound">
                    <img src={UserFoundIcon} alt="User Found" className="covid19-userFound-icon" />
                    {usersFound.length > 1 ? `${usersFound.length} Users found` : `${usersFound.length} User found`}
                  </h3>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      {!usersFound && (
        <Grid container className="covid19-checkout-box">
          <Grid item xs={12}>
            <h2 className="covid19-checkout-title">Visitor Left Building?</h2>
          </Grid>
          <Grid item xs={12}>
            <Btn
              label="Checkout"
              onClick={handleCheckout}
              bgColor="white"
              color="#e76f50"
              variant="outlined"
              bgColorHover="#eceff1"
              borderColor="#e76f50"
              height={36}
              width={window.innerWidth < 600 ? 250 : 295}
            />
          </Grid>
        </Grid>
      )}
      {usersFound.length < 1 && usersFound.length !== false && (
        <>
          {usersFound.length < 1 && (
            <Grid container className="covid19-checkout-box-notFound">
              <Grid item xs={12}>
                <h3 className="covid19-usersFound">
                  <img src={AlertIcon} alt="User not found" className="covid19-userFound-icon" />
                  User not found
                </h3>
              </Grid>
              <Grid item xs={12} className="mt-4">
                <Btn label="add to list" onClick={handleAddNewGuest} bgColor="#2A9D8F" color="#ffffff" variant="outlined" bgColorHover="#2A9D8F" borderColor="#2A9D8F" height={36} width={202} />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
}

searchBox.propTypes = {
  handleSearchUsers: PropTypes.func.isRequired,
  usersFound: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]).isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleAddNewGuest: PropTypes.func.isRequired,
  handleCheckout: PropTypes.func.isRequired,
};

export default searchBox;
