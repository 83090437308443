import React from 'react';
import { Grid } from '@material-ui/core';
import Fees from './fees';
import PaymentMethod from './paymentMethod';
import { CardAPI } from '../../../../API/API-Card';
import { membershipAPI } from '../../../../API/API-membership';
import Alert from '../../../../materialUi/Alerts';

function Payment({
  data, handleData, initialFee, handleConfirmation, userData
}) {
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertValues, setAlertValues] = React.useState({});

  const [paymentStatus, setPaymentStatus] = React.useState(false);


  const addNecessaryFields = (paymentMethod, cardType) => {
    const REFERENCE_NUMBER = Math.floor(100000 + Math.random() * 90000000);
    const copyData = data;
    copyData.membership.paymentMethod = paymentMethod;
    copyData.membership.cardType = cardType;
    copyData.membership.referenceNumber = REFERENCE_NUMBER;
    copyData.membership.name = `${data.primaryMember.firstName} ${data.primaryMember.lastName}`;
    return copyData;
  };

  const submitRenewMembership = async (newData) => {
    newData.membership.fee = newData.membership.fee === 'Free' ? 0 : newData.membership.fee;
    console.log(newData.membership.fee)
    await membershipAPI.PUT.renewMembership(newData.membership).then((response) => {
      console.log(response);
      handleConfirmation(newData);
    }).catch((error) => {
      console.log(error);
    });
  };

  const submitCashPayment = async (type) => {
    const newData = addNecessaryFields(type, type);
    await submitRenewMembership(newData);
  };

  const handleApprovedCardPayment = async (responseData) => {
    const cardType = responseData.result.payment.card_details.card.card_brand;
    const newData = addNecessaryFields('Credit card', cardType);
    newData.membership.cardType = cardType;
    newData.membership.cardInformation = responseData.result.payment;
    await submitRenewMembership(newData);
  };

  const formateCardPaymentFee = (fee) => {
    return fee.toString().includes('.') ? fee.toString().replace('.', '') : `${fee.toString()}00`;
  };

  const submitCardPayment = (nonce, cardHolderName) => {
    console.log(formateCardPaymentFee(data.membership.fee))
    CardAPI.Square.cardPayment(
      nonce,
      data.membership.membershipID,
      `Renew Membership,  Membership: ${data.membership.membershipID}, Card Holder Name: ${cardHolderName}`,
      `${formateCardPaymentFee(data.membership.fee)}`,
    )
      .then(async (response) => {
        await handleApprovedCardPayment(response.data);
      })
      .catch((error) => {
        console.log(error);
        setOpenAlert(true);
        setPaymentStatus(true);
        setAlertValues({
          alertTitle: 'PAYMENT ERROR!',
          body: 'Payment Declined. Please, check the data entered.',
          leave: 'hidden',
          stay: 'CLOSE',
        });
      });
  };

  const handleSubmitPayment = (paymentObj) => {
    const { type, nonce, cardHolderName } = paymentObj;
    if (type === 'card') {
      submitCardPayment(nonce, cardHolderName);
    } else {
      submitCashPayment(type);
    }
  };
  return (
    <Grid container>
      <Grid item xs={12} className="Payment-title">
        Payment
      </Grid>
      <Fees data={data} />
      <PaymentMethod
        data={data}
        initialFee={initialFee}
        handleData={handleData}
        submitPayment={handleSubmitPayment}
        userData={userData}
        paymentStatus={paymentStatus}
      />
      <Alert
        open={openAlert}
        close={() => setOpenAlert(false)}
        location="/"
        title={alertValues.alertTitle}
        body={alertValues.body}
        stay={alertValues.stay}
        leave={alertValues.leave}
      />
    </Grid>
  );
}

export default Payment;
