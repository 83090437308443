import React from 'react';
import BackButton from '../../../materialUi/backButton';
import { membershipAPI } from '../../../API/API-membership';
import Payment from './payment/payment';
import Success from './success/success';
import '../../../css/renewMembership.css';

function RenewMembership({ history, match, userData }) {
  const { membershipID } = match.params;
  const [data, setData] = React.useState();
  const [step, setStep] = React.useState(0);
  const [initialFee, setInitialFee] = React.useState(0);

  const calculateMembershipPrice = (type, category) => {
    const formattedType = type.toLowerCase();
    const formattedCategory = category.toLowerCase();
    if (formattedType === 'community') {
      if (formattedCategory === 'family') {
        return 8;
      }
      if (formattedCategory === 'adult') {
        return 5;
      }
      if (formattedCategory === 'youth') {
        return 2;
      }
      if (formattedCategory === 'senior') {
        return 1;
      }
      if (formattedCategory === 'child') {
        return 1;
      }
    }
    if (formattedType === 'associate') {
      if (formattedCategory === 'family') {
        return 15;
      }
      if (formattedCategory === 'adult') {
        return 10;
      }
      if (formattedCategory === 'youth') {
        return 4;
      }
      if (formattedCategory === 'senior') {
        return 2;
      }
      if (formattedCategory === 'child') {
        return 2;
      }
    }
  };


  const setInitialData = (data) => {
    const copyData = data;
    const fee = calculateMembershipPrice(data.membership.membershipType, data.membership.membershipCategory);
    setInitialFee(fee);
    copyData.membership.fee = fee;
    setData(copyData);
  };

  const isMembershipExpired = (membershipData) => {
    const membershipDate = new Date(membershipData.expirationDate);
    const todayDate = new Date();
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 30);
    if (membershipDate > todayDate && membershipDate < endDate) {
      return true;
    }
    if (membershipDate < todayDate) {
      return true;
    }
    return false;
  };

  const handleActiveMembership = () => {
    const membershipData = sessionStorage.getItem(`renewMembership_${membershipID}`) ? JSON.parse(sessionStorage.getItem(`renewMembership_${membershipID}`)) : false;
    if (membershipData) {
      setStep(1);
      setData(membershipData);
    } else {
      if (userData.userData.userType.toLowerCase() === 'member') {
        return history.push(`/scccPortal/membership/${membershipID}`);
      }
      return history.push(`/adminPortal/membership/verifyMembership/${membershipID}`);
    }
  };

  const validateRenewMembership = (responseData) => {
    if (isMembershipExpired(responseData.membership) === true) {
      setInitialData(responseData);
    } else {
      handleActiveMembership();
    }
  };

  const getMembershipData = () => {
    membershipAPI.GET.getMembershipByID(membershipID).then((response) => {
      console.log(response.data)
      validateRenewMembership(response.data);
    }).catch((error) => {
      console.log(error);
    });
  };

  const handleData = (newData) => {
    setData((currentData) => ({ ...currentData, newData }));
  };

  const handleConfirmation = (newData) => {
    setStep(1);
    setData(newData);
    sessionStorage.setItem(`renewMembership_${membershipID}`, JSON.stringify(newData));
  };

  const handleBackButton = () => {
    if (userData.userData.userType.toLowerCase() === 'member') {
      return history.push(`/scccPortal/membership/${membershipID}`);
    }
    return history.push(`/adminPortal/membership/verifyMembership/${membershipID}`)
  }

  React.useEffect(() => {
    async function fetchData() {
      await getMembershipData();
    }
    fetchData();
  }, []);


  return (
    <div className="renewMembership-wrapper">
      {step === 0 ? (
        <>
          <BackButton
            handleClick={handleBackButton}
            label="Back to verify membership"
          />
          <Payment
            data={data}
            handleData={handleData}
            initialFee={initialFee}
            handleConfirmation={handleConfirmation}
            userData={userData}
          />
        </>
      ) : (
        <Success data={data} userData={userData} />
      )}
    </div>
  );
}
export default RenewMembership;
