import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, FormControl, FormGroup, FormControlLabel, Checkbox,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import RadioGroup from '../../../../../materialUi/radioGroup';
import TextField from '../../../../../materialUi/textField';
import '../../../../../css/taxes.css';

function Documents(props) {
  const {
    control,
    formState: { errors },
    setValue,
    register,
    clearErrors,
    setError,
    unregister,
    data,
    getValues
  } = props;

  const pastYear = new Date().getFullYear() - 1;
  const [identification, setIdentification] = React.useState('driverLicence');
  const identificationList = [
    {
      label: 'Driver Licence',
      value: 'driverLicence',
    },
    {
      label: 'Passport',
      value: 'passport',
    },
    {
      label: 'Ontario Photo Card',
      value: 'ontarioPhotoCard',
    },
    {
      label: 'Landing Paper',
      value: 'landingPaper',
    },

    {
      label: 'Permanent Resident (PR) Card',
      value: 'PR',
    },
    {
      label: 'Refugee Claimant Document',
      value: 'refugee',
    },
    {
      label: 'Other',
      value: 'other',
    },
  ];

  const letTheUserSpecify = () => {
    register('identification', { required: 'Required Field' });
    setValue('identification', '');
  };

  const registerTheIdentification = (value) => {
    register('identification');
    setValue('identification', value);
  };

  const handleIdentification = (event) => {
    const { value } = event.target;
    const isOtherSelected = value === 'other';
    if (isOtherSelected) {
      letTheUserSpecify();
    } else {
      registerTheIdentification(value);
    }
    setIdentification(value);
  };

  const incomeSlipList = [
    {
      label: 'Salary and Wages [T4]',
      value: 'T4',
    },
    {
      label: 'Other Income [T4A e.g. CREB, CRB, etc.]',
      value: 'otherIncome',
    },
    {
      label: 'Pension & Retirement [T4A(P)]',
      value: 'T4A(P)',
    },
    {
      label: 'Old Age Security Benefits [T4A(OAS)]',
      value: 'T4A(OAS)',
    },
    {
      label: 'Employment Insurance [T4E]',
      value: 'T4E',
    },
    {
      label: 'Interest & Dividends [T5]',
      value: 'T5',
    },
    {
      label: 'Statement of Trust Income [T3]',
      value: 'T3',
    },
    {
      label: 'Government Assistance/ Social Assistance [T5007]',
      value: 'T5007',
    },
    {
      label: 'Universal Child Care Benefit Statement [RC62]',
      value: 'RC62',
    },
    {
      label: 'Self-employed business income',
      value: 'selfEmployed',
    },
    {
      label: `No income to report for ${pastYear}`,
      value: 'noIncome',
    },
    {
      label: 'Other',
      value: 'other',
    },
  ];

  const [incomeSlip, setIncomeSlip] = React.useState([]);
  const [selectedSlip, setSelectedSlip] = React.useState({
    T4: false,
    otherIncome: false,
    'T4A(P)': false,
    'T4A(OAS)': false,
    T4E: false,
    T5: false,
    T3: false,
    T5007: false,
    RC62: false,
    selfEmployed: false,
    noIncome: false,
    other: false,
  });

  const removePreviousSelectedIncomeSlip = (name) => {
    const index = incomeSlip.indexOf(name);
    incomeSlip.splice(index, 1);
  };

  const addOrRemoveSelectedItems = (name) => {
    const sameIncomeSlip = incomeSlip.includes(name);
    if (sameIncomeSlip) {
      removePreviousSelectedIncomeSlip(name);
    } else {
      incomeSlip.push(name);
    }
  };

  const setIncomeSlipAndRemoveErrors = () => {
    clearErrors('incomeSlip');
    setValue('incomeSlip', incomeSlip);
  };

  const updateIncomeSlip = () => {
    register('incomeSlip', { required: 'Please select at least one' });
    const THERE_ARE_NO_INCOME_SLIP = incomeSlip.length === 0;
    if (THERE_ARE_NO_INCOME_SLIP) {
      setError('incomeSlip', {
        type: 'Required',
        message: 'Please select at least one',
      });
    } else {
      setIncomeSlipAndRemoveErrors();
    }
  };

  const [otherIncome, setOtherIncome] = React.useState('');

  const removeOtherIncomeFromTheForm = () => {
    const OTHER_INCOME_INDEX = incomeSlip.indexOf(otherIncome);
    unregister('otherIncome');
    deleteOtherIncome(OTHER_INCOME_INDEX);
    setOtherIncome('');
  };

  const handleIncomeSlip = (event) => {
    const { name } = event.target;
    addOrRemoveSelectedItems(name);
    setSelectedSlip({ ...selectedSlip, [name]: event.target.checked });
    const IS_OTHER_SELECTED = name === 'other';
    if (IS_OTHER_SELECTED) {
      const NO_OTHER_FOUND = !incomeSlip.includes('other');
      if (NO_OTHER_FOUND) {
        removeOtherIncomeFromTheForm();
      }
    }
    updateIncomeSlip();
  };

  const deleteOtherIncome = (OTHER_INCOME_INDEX) => {
    const copyIncomeSlip = incomeSlip;
    if (OTHER_INCOME_INDEX !== -1) {
      copyIncomeSlip.splice(OTHER_INCOME_INDEX, 1);
    }
    setIncomeSlip(copyIncomeSlip);
  };

  const addOtherIncomeToIncomeSlipList = (value) => {
    const previousValue = otherIncome;
    const OTHER_INCOME_INDEX = incomeSlip.indexOf(previousValue);
    deleteOtherIncome(OTHER_INCOME_INDEX);
    incomeSlip.push(value);
    setOtherIncome(value);
    updateIncomeSlip();
  };


  const otherIncomeValidation = (value) => {
    const EMPTY_VALUE = value === '';
    register('otherIncome', { required: 'Required Field' });
    if (EMPTY_VALUE) {
      setError('otherIncome', {
        type: 'Required',
        message: 'Required Field',
      });
    } else if (value.length > 50) {
      setError('otherIncome', {
        type: 'Max length',
        message: 'Max Length 50',
      });
    } else {
      unregister('otherIncome');
    }
    addOtherIncomeToIncomeSlipList(value);
  };

  const handleOtherIncome = (event) => {
    const { value } = event.target;
    otherIncomeValidation(value);
  };

  const findIdentifications = (savedID) => {
    for (let i = 0; i < identificationList.length; i++) {
      if (identificationList[i].value === savedID) {
        return true;
      }
    }
    return false;
  };

  const handleSavedIdentification = (savedID) => {
    if (findIdentifications(savedID)) {
      setIdentification(savedID);
    } else {
      setIdentification('other');
    }
  };

  const checkIncomeSlips = [
    'T4',
    'otherIncome',
    'T4A(P)',
    'T4A(OAS)',
    'T4E',
    'T5',
    'T3',
    'T5007',
    'RC62',
    'selfEmployed',
    'noIncome',
    'other',
  ];

  const setIncomeSlipSpecified = (newIncomeSlip) => {
    for (let i = 0; i < newIncomeSlip.length; i++) {
      if (!checkIncomeSlips.includes(newIncomeSlip[i])) {
        setOtherIncome(newIncomeSlip[i]);
        incomeSlip.push(newIncomeSlip[i]);
      }
    }
  };

  const calculateToUpdateIncomeSlip = (newIncomeSlip) => {
    const incomeSlipCopy = selectedSlip;
    for (let i = 0; i < newIncomeSlip.length; i++) {
      Object.keys(selectedSlip).forEach((key) => {
        if (key === newIncomeSlip[i]) {
          incomeSlipCopy[key] = true;
          if (!incomeSlip.includes(newIncomeSlip[i])) {
            incomeSlip.push(newIncomeSlip[i]);
          }
        }
      });
    }
    setIncomeSlipSpecified(newIncomeSlip);
    setSelectedSlip(incomeSlipCopy);
  };

  const handleSavedIncomeSlip = (newIncomeSlip) => {
    calculateToUpdateIncomeSlip(newIncomeSlip);
  };

  const readyToUploadList = [
    {
      value: true,
      label: 'Ready to upload',
    },
    {
      value: false,
      label: 'Not available',
    },
  ];

  const handleReadyToUpload = (event) => {
    const { value } = event.target;
    setValue('readyToUpload', value === 'true');
    if (value === 'true') {
      clearErrors('readyToUpload')
    }
  };


  React.useEffect(() => {
    const savedIdentification = data.identification;
    const savedIncomeSlip = data.incomeSlip && data.incomeSlip.length > 0;
    if (savedIdentification) {
      handleSavedIdentification(data.identification);
    } else {
      register('identification');
      setValue('identification', 'driverLicence');
    }
    if (savedIncomeSlip) {
      handleSavedIncomeSlip(data.incomeSlip);
    } else {
      register('incomeSlip', { required: 'Please select at least one income slip' });
    }
  }, [data]);

  return (
    <>
      <Grid item xs={12}>
        <div className="taxes-question">
          13. Please specify which government issued ID
           (Identifying Document) you will use to file your tax:
        </div>
      </Grid>
      <Grid item xs={12}>
        <span className="custom-error">
          {(errors.governmentID) && errors.governmentID.message}
        </span>
        <RadioGroup
          listOptions={identificationList}
          ariaLabel="13. Please specify which government issued ID
              (Identifying Document) you will use to file your tax:"
          name="13.Please specify which government issued ID
              (Identifying Document) you will use to file your tax:"
          value={identification}
          handleChange={handleIdentification}
        />
      </Grid>
      {identification === 'other' && (
      <Grid item xs={6}>
        <Controller
          name="identification"
          control={control}
          rules={{
            maxLength: { value: 100, message: 'Max length 100' },
          }}
          render={({ field }) => (
            <TextField
              id="identification"
              type="search"
              name="identification"
              label="Please specify"
              helperText={errors.identification ? errors.identification.message : ''}
              error={!!errors.identification}
              width="100%"
              {...field}
            />
          )}
        />
      </Grid>
      )}
      <Grid item xs={12}>
        <div className="taxes-question">
          14. Please state all slips of income that you have received:
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="custom-error">
          {(errors.incomeSlip) && errors.incomeSlip.message}
        </div>
        <FormControl
          component="fieldset"
        >
          <FormGroup>
            {
              incomeSlipList.map((item) => (
                <FormControlLabel
                  key={item.value}
                  control={(
                    <Checkbox
                      checked={selectedSlip[item.value]}
                      onChange={(event) => handleIncomeSlip(event)}
                      name={item.value}
                    />
                    )}
                  label={item.label}
                />
              ))
            }
          </FormGroup>
        </FormControl>
      </Grid>
      {incomeSlip.includes('other') && (
      <Grid item xs={6}>
        <TextField
          id="otherIncome"
          type="search"
          name="otherIncome"
          label="Please specify"
          helperText={errors.otherIncome ? errors.otherIncome.message : ''}
          error={!!errors.otherIncome}
          value={otherIncome}
          handleChanges={handleOtherIncome}
          width="100%"
        />
      </Grid>
      )}
      <Grid item xs={12}>
        <div className="taxes-question">
          15. Do you have all rent receipts of the year
          {' '}
          {pastYear}
?
        </div>
        <h5 className="taxes-sub-title">
          *For those who reside in a building, please ask the management office
          to provide you with a letter of your rent payments.
        </h5>
      </Grid>
      <Grid item xs={12}>
        <span className="custom-error">
          {(errors.readyToUpload) && errors.readyToUpload.message}
        </span>
        <RadioGroup
          listOptions={readyToUploadList}
          handleChange={handleReadyToUpload}
          value={getValues('readyToUpload')}
          ariaLabel="Ready to upload? "
          name="readyToUpload"
        />
      </Grid>
    </>
  );
}

Documents.propTypes = {
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  formState: PropTypes.shape({
    errors: PropTypes.any,
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  data: PropTypes.shape(
    {
      identification: PropTypes.string,
      incomeSlip: PropTypes.arrayOf(PropTypes.string),
    },
  ).isRequired,
};

export default Documents;
