import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import Checkbox from '../../../../materialUi/checkBox';
import SearchTextField from '../../../../materialUi/searchTextField';
import '../../../../css/programsList.css';

export default class Filter extends Component {
  constructor(props) {
    super(props);
  }


  render() {

    const {
      search,
    } = this.props;
    return (
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Box className="programsList-searchField">
            <SearchTextField label="Search Program" search={(value) => search(value.target.value)} width={200} />
          </Box>
        </Grid>
      </Grid>
    );
  }
}

Filter.propTypes = {
  values: PropTypes.shape({
    allAges: PropTypes.bool,
    preSchool: PropTypes.bool,
    children: PropTypes.bool,
    youth: PropTypes.bool,
    adult: PropTypes.bool,
    senior: PropTypes.bool,
    information: PropTypes.bool,
    winter: PropTypes.bool,
    spring: PropTypes.bool,
    fall: PropTypes.bool,
    summer: PropTypes.bool,
  }).isRequired,
  search: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired,
};
