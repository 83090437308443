import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import TextField from '../../../materialUi/textField';
import Dropdown from '../../../materialUi/dropdown';
import { userAPI } from '../../../API/API-user';
import { validateField } from '../../../utils/fieldsValidation';
import '../../../css/settings.css';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class AddNewUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        firstName: '',
        lastName: '',
        dayPhone: '',
        nightPhone: '',
        email: '',
        userType: 'Select One',
        password: '',
        checkPassword: '',
      },
      messages: {
        firstName: '',
        lastName: '',
        dayPhone: '',
        nightPhone: '',
        email: '',
        userType: '',
        password: '',
        checkPassword: '',
      },
      errors: {
        firstName: '',
        lastName: '',
        dayPhone: '',
        nightPhone: '',
        email: '',
        userType: '',
        password: '',
        checkPassword: '',
      },
    };
  }

  handleInputs =(name, value, type, requirement = '') => {
    const {
      values,
      messages,
      errors,
    } = this.state;
    if (validateField(value, type, requirement) === true) {
      values[name] = value;
      messages[name] = '';
      errors[name] = false;
    } else {
      values[name] = value;
      messages[name] = validateField(value, type, requirement);
      errors[name] = true;
    }
    this.setState({ values, messages, errors });
  }

  handlePassword = (value) => {
    const { values, messages, errors } = this.state;
    if (value.length < 1) {
      messages.password = 'Password cannot be empty';
      errors.password = true;
    } else if (value.length < 6) {
      messages.password = 'Password must be 6 digits long';
      errors.password = true;
    } else {
      messages.password = '';
      errors.password = false;
    }
    values.password = value;
    this.setState({ values, messages, errors });
  }

  handlePasswordCheck = (value) => {
    const { values, messages, errors } = this.state;
    if (value.length < 1) {
      messages.checkPassword = 'Password cannot be empty';
      errors.checkPassword = true;
    } else if (value.length < 6) {
      messages.checkPassword = 'Password must be 6 digits long';
      errors.checkPassword = true;
    } else if (values.password !== value) {
      messages.checkPassword = 'Password does not match!';
      errors.checkPassword = true;
    } else {
      messages.checkPassword = '';
      errors.checkPassword = false;
    }
    values.checkPassword = value;
    this.setState({ values, messages, errors });
  }

  handleSubmit = () => {
    let fieldsvalidationResult = 0;
    const { values, messages, errors } = this.state;
    Object.keys(errors).forEach((key) => {
      if (errors[key] === false) {
        fieldsvalidationResult++;
      }
    });

    const userObj = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      userType: values.userType,
      programs: values.selectedPrograms,
      dayPhone: values.dayPhone,
      nightPhone: values.nightPhone,
    };

    if (fieldsvalidationResult === 8) {
      userAPI.POST.register(userObj).then((success) => {
        console.log(success);
        window.location.reload();
      }).catch((error) => {
        console.log(error);
        if (error.response) {
          if (error.response.data === 'email already exist') {
            messages.email = 'Email is already in use.';
            errors.email = true;
            this.setState({
              values, messages, errors,
            });
          }
        }
      });
    } else {
      Object.keys(errors).forEach((key) => {
        if (errors[key] !== false) {
          messages[key] = (messages[key].lenght > 0) ? messages[key] : 'Required Field';
          errors[key] = true;
          console.log(key);
        }
      });
      this.setState({ errors });
    }
  }

  render() {
    const userTypes = [
      { name: 'Select One' },
      { name: 'Super Admin' },
      { name: 'Program Admin' },
      { name: 'Membership Admin' },
      { name: 'Tax Volunteer' },
      { name: 'Program Staff' },
      { name: 'Receptionist' },
      { name: 'Security' },
    ];

    const {
      values,
      messages,
      errors,
    } = this.state;

    const {
      open,
      close,
    } = this.props;

    return (
      <Grid className="font-family-default">
        <Dialog
          onClose={() => close()}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth="md"
          fullWidth
        >
          <DialogContent dividers>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className="settings-edit-body"
            >
              <Box className="settings-edit-title">
                Add New User
              </Box>
              <Grid>
                <Box onClick={() => close()} role="button"><i className="fas fa-times close-icon" /></Box>
              </Grid>
            </Grid>
            <Grid container spacing={1} className="settings-edit-body">
              <Grid item xs={12} md={6}>
                <TextField
                  name="First Name"
                  label="First Name"
                  id="firstName"
                  value={values.firstName}
                  handleChanges={(value) => this.handleInputs('firstName', value.target.value, 'name', 'First Name')}
                  helperText={messages.firstName}
                  error={errors.firstName}
                  readOnly={false}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="last Name"
                  label="Last Name"
                  id="lastName"
                  value={values.lastName}
                  handleChanges={(value) => this.handleInputs('lastName', value.target.value, 'name', 'Last Name')}
                  helperText={messages.lastName}
                  error={errors.lastName}
                  readOnly={false}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="Day Phone"
                  label="Day Phone"
                  id="dayPhone"
                  value={values.dayPhone}
                  handleChanges={(value) => this.handleInputs('dayPhone', value.target.value, 'phone')}
                  helperText={messages.dayPhone}
                  error={errors.dayPhone}
                  readOnly={false}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="Night Phone"
                  label="Night Phone"
                  id="nightPhone"
                  value={values.nightPhone}
                  handleChanges={(value) => this.handleInputs('nightPhone', value.target.value, 'phone')}
                  helperText={messages.nightPhone}
                  error={errors.nightPhone}
                  readOnly={false}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="Email"
                  label="Email"
                  id="Email"
                  value={values.email}
                  handleChanges={(value) => this.handleInputs('email', value.target.value, 'email')}
                  helperText={messages.email}
                  error={errors.email}
                  readOnly={false}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Dropdown
                  name="User Type"
                  id="userType"
                  label="User Type"
                  value={values.userType}
                  handleChanges={(value) => this.handleInputs('userType', value.target.value, 'dropdown')}
                  helperText={messages.userType}
                  error={errors.userType}
                  listName={userTypes}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="Password"
                  label="Password"
                  id="password"
                  value={values.password}
                  handleChanges={(value) => this.handlePassword(value.target.value)}
                  helperText={messages.password}
                  error={(errors.password === '') ? false : errors.password}
                  readOnly={false}
                  type="password"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="Re-enter Password"
                  label="Re-enter Password"
                  id="checkPassword"
                  value={values.checkPassword}
                  handleChanges={(value) => this.handlePasswordCheck(value.target.value)}
                  helperText={messages.checkPassword}
                  error={(errors.checkPassword === '') ? false : errors.checkPassword}
                  readOnly={false}
                  type="password"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid className="settings-btns-body">
              <Button onClick={() => close()} className="alert-btns">
                Cancel
              </Button>
              <Button className="alert-btns" onClick={this.handleSubmit}>
                Create
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

AddNewUser.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default AddNewUser;
