import React, { Component } from 'react';
import { Grid, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import DropdownIcon from '../../../../assets/icons/menu.png';
import Filter from './filter';
import '../../../../css/programsList.css';

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayFilter: false,
    };
  }


  handleDisplayFilter = () => {
    const { displayFilter } = this.state;
    const { resetFilters } = this.props;
    if (displayFilter) {
      this.setState({ displayFilter: false });
      // reset filter
      resetFilters('');
    } else {
      this.setState({ displayFilter: true });
    }
  }

  render() {

    const {
      selectedItems,
      handleFilter,
      search,
      values,
    } = this.props;

    const {
      displayFilter,
    } = this.state;

    return (
      <Grid container className="programsList-spacing">
        <Grid item xs={12} md={4} xl={6} className="programsList-header">
         Programs List
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs sm={2} lg={1} className="programsList-filter">
              <Box onClick={() => this.handleDisplayFilter()} role="button" tabIndex={0} onKeyPress={() => this.handleDisplayFilter()}>
                <img src={DropdownIcon} alt="Search Filter" />
                Filter
              </Box>
            </Grid>
            {/* filter for desktop  */}
            {
              (window.innerWidth > 900)
              && (
              <Grid item xs className="programsList-box-selectedItems">
                {selectedItems}
              </Grid>
              )
            }
          </Grid>
        </Grid>
        {/* filter for mobile  */}
        { (window.innerWidth < 900)
          && (
          <Grid container>
            <Grid item xs={12} className="programsList-box-selectedItems">
              {selectedItems}
            </Grid>
          </Grid>
          )}
        {
            (displayFilter)
              ? (
                <Filter
                  handleFilter={handleFilter}
                  search={search}
                  values={values}
                />
              ) : ''
         }

      </Grid>
    );
  }
}

Header.propTypes = {
  resetFilters: PropTypes.func.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleFilter: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ])).isRequired,
};
