import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, withStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { FamilyAPI } from '../../../../API/API-familyMembers';
import { CONVERBIRTH } from '../../../../utils/dateController';
import { BootstrapTextField, BootstrapSelectDropdown } from '../../../../materialUi/bootstrapInputs';
import { validateField } from '../../../../utils/fieldsValidation';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class editFamilyMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      familyMemberID: '',

      values: {
        firstName: '',
        lastName: '',
        phone: '',
        dateOfBirth: '',
        relationship: '',
        gender: '',
        school: '',
        membershipCard: '',
      },

      errors: {
        firstName: false,
        lastName: false,
        phone: false,
        dateOfBirth: false,
        relationship: false,
        gender: false,
        school: false,
        membershipCard: false,
      },
      messages: {
        firstName: '',
        lastName: '',
        phone: '',
        dateOfBirth: '',
        relationship: '',
        gender: '',
        school: '',
        membershipCard: '',
      },
    };
  }

  componentDidUpdate(previousProps) {
    const { values } = this.state;
    const { familyMemberData, open } = this.props;
    if (previousProps.familyMemberData !== familyMemberData) {
      const updateValues = values;
      updateValues.firstName = familyMemberData.firstName;
      updateValues.lastName = familyMemberData.lastName;
      updateValues.phone = familyMemberData.phone;
      updateValues.dateOfBirth = CONVERBIRTH(familyMemberData.dateOfBirth, true);
      updateValues.relationship = familyMemberData.relationship;
      updateValues.gender = familyMemberData.gender;
      updateValues.school = familyMemberData.school;
      updateValues.membershipCard = familyMemberData.membershipCard;
      this.setState({ values: updateValues, familyMemberID: familyMemberData._id });
    }
    if (previousProps.open !== open) {
      this.setState({ open });
    }
  }

  // <--- input handlers --->
  handleInputs = (name, value, type, requirement = '') => {
    const { values, messages, errors } = this.state;
    if (validateField(value, type, requirement) === true) {
      values[name] = value;
      messages[name] = '';
      errors[name] = false;
      this.setState({ values, messages, errors });
    } else {
      values[name] = value;
      messages[name] = validateField(value, type, requirement);
      errors[name] = true;
      this.setState({ values, messages, errors });
    }
  };

  handleClose = () => {
    const { handleCloseEditMember } = this.props;
    this.setState({ open: false });
    handleCloseEditMember();
  };

  handleDeleteFamilyMember = () => {
    const { familyMemberID } = this.state;
    const { membershipID } = this.props;
    FamilyAPI.PUT.deleteFamilyMember(familyMemberID, membershipID)
      .then((success) => {
        console.log(success);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleSubmit = () => {
    let fieldsvalidationResult = 0;
    const { errors, messages, values, familyMemberID } = this.state;
    const { membershipID } = this.props;
    // eslint-disable-next-line
    for (let key in errors) {
      if (errors[key] === true || errors[key] === '') {
        fieldsvalidationResult++;
      }
    }
    if (fieldsvalidationResult === 0) {
      // prepare familyMember Object
      const familyMember = {
        firstName: values.firstName,
        lastName: values.lastName,
        age: values.age,
        dateOfBirth: values.dateOfBirth,
        gender: values.gender,
        relationship: values.relationship,
        school: values.school,
        phone: values.phone,
        membershipCard: values.membershipCard,
      };

      FamilyAPI.PUT.updateAFamilyMember(familyMemberID, familyMember)
        .then((success) => {
          console.log('success', success);
          window.location = `/adminPortal/membership/verifyMembership/${membershipID}`;
        })
        .catch((error) => {
          console.log('error', error);
        });
    } else {
      // eslint-disable-next-line
      for (let key in updateErrors) {
        if (errors[key] !== false) {
          errors[key] = true;
          messages[key] = 'Required Field!';
        }
      }
      // set errors to true and display all messages
      this.setState({ errors, messages });
      window.scrollTo(0, 0);
    }
  };

  render() {
    const gender = ['Select One', 'Man', 'Woman', 'Non-binary', 'I choose not to disclose'];
    const relationship = ['Select One', 'Spouse', 'Child', 'Parent', 'Sibling', 'Grand Parents', 'Grand Child'];
    const { values, messages, errors, open } = this.state;
    return (
      <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="md" fullWidth>
        <DialogContent dividers>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Box className="verify-member-title">
              Edit: &nbsp;
              {values.firstName}
              &nbsp;
              {values.lastName}
            </Box>
            <Grid onClick={() => this.handleDeleteFamilyMember()}>
              <DeleteIcon className="delete-icon" fontSize="small" />
              <span className="family-member-delete">Delete</span>
            </Grid>
          </Grid>
          <Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                First Name:
                <br />
                <BootstrapTextField
                  id="firstName"
                  type="text"
                  name="First Name"
                  value={values.firstName}
                  handleChanges={(value) => this.handleInputs('firstName', value.target.value, 'name', 'First Name')}
                  message={messages.firstName}
                  readOnly={false}
                  error={errors.firstName}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                Last Name:
                <br />
                <BootstrapTextField
                  id="lastName"
                  type="text"
                  name="Last Name"
                  value={values.lastName}
                  handleChanges={(value) => this.handleInputs('lastName', value.target.value, 'name', 'Last Name')}
                  message={messages.lastName}
                  readOnly={false}
                  error={errors.lastName}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                Date of Birth:
                <br />
                <BootstrapTextField
                  id="dateOfBirth"
                  type="date"
                  name="Date Of Birth"
                  value={values.dateOfBirth}
                  handleChanges={(value) => this.handleInputs('dateOfBirth', value.target.value, 'name')}
                  message={messages.dateOfBirth}
                  readOnly={false}
                  error={errors.dateOfBirth}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                Gender:
                <br />
                <BootstrapSelectDropdown
                  value={values.gender}
                  list={gender}
                  handleChanges={(value) => this.handleInputs('gender', value.target.value, 'dropdown')}
                  message={messages.gender}
                  error={errors.gender}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                Relationship:
                <br />
                <BootstrapSelectDropdown
                  value={values.relationship}
                  list={relationship}
                  handleChanges={(value) => this.handleInputs('relationship', value.target.value, 'dropdown')}
                  message={messages.relationship}
                  error={errors.relationship}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                School:
                <br />
                <BootstrapTextField
                  id="school"
                  type="text"
                  name="School"
                  value={values.school}
                  handleChanges={(value) => this.handleInputs('school', value.target.value, 'name', 'School')}
                  message={messages.school}
                  readOnly={false}
                  error={errors.school}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                Phone:
                <br />
                <BootstrapTextField
                  id="phone"
                  type="text"
                  name="Phone"
                  value={values.phone}
                  handleChanges={(value) => this.handleInputs('phone', value.target.value, 'phone')}
                  message={messages.phone}
                  readOnly={false}
                  error={errors.phone}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                Membership Card:
                <br />
                <BootstrapTextField
                  id="membershipCard"
                  type="text"
                  name="Membership Card"
                  value={values.membershipCard}
                  handleChanges={(value) => this.handleInputs('membershipCard', value.target.value, 'name', 'Membership Card')}
                  message={messages.membershipCard}
                  readOnly={false}
                  error={errors.membershipCard}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ margin: '12px' }}>
          <Button className="alert-btns" onClick={this.handleClose}>
            Cancel
          </Button>
          <Button className="alert-btns" onClick={() => this.handleSubmit()}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

editFamilyMember.propTypes = {
  familyMemberData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phone: PropTypes.string,
    dateOfBirth: PropTypes.string,
    relationship: PropTypes.string,
    gender: PropTypes.string,
    school: PropTypes.string,
    membershipCard: PropTypes.string,
    _id: PropTypes.string,
  }),
  handleCloseEditMember: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  membershipID: PropTypes.string.isRequired,
};

editFamilyMember.defaultProps = {
  familyMemberData: {},
};

export default editFamilyMember;
