import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Stepper from '../../../materialUi/stepper';
import ProgramInformation from '../../../materialUi/programs/programForm/programInformation/programInformation';
import Schedule from '../../../materialUi/programs/programForm/schedule/schedule';
import ProgramStaff from '../../../materialUi/programs/programForm/programStaff/programStaff';
import { ProgramsAPI } from '../../../API/API-programs';
import '../../../css/addNewProgram.css';

function AddNewProgram({ history }) {
  const stepsList = ['Program Information', 'Schedule', 'Program Staff'];
  const [step, setStep] = React.useState(0);
  const [data, setData] = React.useState({});

  const saveDateOnSession = (newData) => {
    sessionStorage.setItem('addNewProgram', JSON.stringify(newData));
  };

  const submitNewProgram = (dataToSave) => {
    ProgramsAPI.POST.addNewProgram(dataToSave).then((response) => {
      console.log(response);
      history.push('/adminPortal/programs/available');
      sessionStorage.clear();
    }).catch((error) => {
      console.log(error);
    });
  };

  const addNewData = (newData, newStep) => {
    let copyData = data;
    copyData = { ...data, ...newData };
    copyData.currentStep = newStep;
    setData(copyData);
    saveDateOnSession(copyData);
    return copyData;
  };

  const handleNextStep = async (newData, newStep, submit = false) => {
    const dataToSave = addNewData(newData, newStep);
    setStep(newStep);
    if (submit) {
      await submitNewProgram(dataToSave);
    }
  };

  React.useEffect(() => {
    const SESSION_DATA = JSON.parse(sessionStorage.getItem('addNewProgram'));
    if (SESSION_DATA) {
      setData(SESSION_DATA);
      // TODO: try to update the data without updating the step
      setStep(SESSION_DATA.currentStep);
    }
  }, [setData, setStep]);

  return (
    <Grid item xs>
      <Stepper
        labels={stepsList}
        step={step}
      />
      {step === 0 && <ProgramInformation data={data} handleNextStep={handleNextStep} />}
      {step === 1 && (
        <Schedule
          data={data}
          handleNextStep={handleNextStep}
          setStepBack={(newStep) => setStep(newStep)}
        />
      )}
      {step === 2 && (
        <ProgramStaff
          data={data}
          handleNextStep={handleNextStep}
          setStepBack={(newStep) => setStep(newStep)}
        />
      )}
    </Grid>
  );
}

AddNewProgram.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default AddNewProgram;
