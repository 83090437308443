import { CONVERTTIME, DATETOSEASON, GETLASTSEASON } from '../../utils/dateController';


export const MEMBERCATEGORY = (data) => {
  let adultCount = 0;
  let youthCount = 0;
  let childCount = 0;
  let seniorCount = 0;
  let familyCount = 0;

  for (let i = 0; i < data.length; i++) {
    if (data[i].status !== 'inactive') {
      if (data[i].membershipCategory === 'Adult') {
        adultCount++;
      }
      if (data[i].membershipCategory.toLowerCase() === 'youth') {
        youthCount++;
      }
      if (data[i].membershipCategory.toLowerCase() === 'child') {
        childCount++;
      }
      if (data[i].membershipCategory.toLowerCase() === 'senior') {
        seniorCount++;
      }
      if (data[i].membershipCategory.toLowerCase() === 'family') {
        familyCount++;
      }
    }
  }
  const dataArray = [childCount, youthCount, adultCount, seniorCount, familyCount];
  return dataArray;
};

export const MEMBERSEXPIIRATION = function (data) {
  let countActive = 0;
  let countExpired = 0;
  let countExpiring = 0;
  let countUnverified = 0;
  for (let i = 0; i < data.length; i++) {
    if (data[i].status !== 'inactive') {
      const membershipDate = new Date(data[i].expirationDate);
      const todayDate = new Date();
      const endDate = new Date();
      endDate.setDate(endDate.getDate() + 30);

      if (membershipDate > todayDate
        && membershipDate < endDate) {
        countExpiring++;
      } else if (membershipDate < todayDate) {
        countExpired++;
      } else if (data[i].status === 'Unverified') {
        countUnverified++;
      } else {
        countActive++;
      }
    }
  }
  const dataArray = [countActive, countExpiring, countExpired, countUnverified];
  return dataArray;
};

export const YEARSFILTER = function (membershipData, filterYear) {
  const monthsCounter = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  for (let i = 0; i < membershipData.length; i++) {
    if (membershipData[i].status !== 'inactive') {
      const month1 = CONVERTTIME(membershipData[i].registrationDate, true);
      const split = month1.split('-');
      const registrationMonth = split[1];
      const registrationYear = split[2];
      if (filterYear.toString() === registrationYear.toString()) {
        if (registrationMonth === 'Jan') {
          monthsCounter[0] += 1;
        } if (registrationMonth === 'Feb') {
          monthsCounter[1] += 1;
        } if (registrationMonth === 'Mar') {
          monthsCounter[2] += 1;
        } if (registrationMonth === 'Apr') {
          monthsCounter[3] += 1;
        } if (registrationMonth === 'May') {
          monthsCounter[4] += 1;
        } if (registrationMonth === 'Jun') {
          monthsCounter[5] += 1;
        } if (registrationMonth === 'Jul') {
          monthsCounter[6] += 1;
        } if (registrationMonth === 'Aug') {
          monthsCounter[7] += 1;
        } if (registrationMonth === 'Sep') {
          monthsCounter[8] += 1;
        } if (registrationMonth === 'Oct') {
          monthsCounter[9] += 1;
        } if (registrationMonth === 'Nov') {
          monthsCounter[10] += 1;
        } if (registrationMonth === 'Dec') {
          monthsCounter[11] += 1;
        }
      }
    }
  }

  return monthsCounter;
};

export const NEWMEMBERSHIPS = (data) => {
  let newMemberships = 0;
  let newMembershipsLastMonth = 0;
  const thisMonth = new Date().getMonth();
  const lastMonth = (thisMonth === 0) ? 11 : new Date().getMonth() - 1;
  const thisYear = new Date().getFullYear();
  const dezemberLastYear = new Date().getFullYear() - 1;

  for (let i = 0; i < data.length; i++) {
    if (data[i].status !== 'inactive') {
      const membershipThisMonth = new Date(data[i].firstRegistered).getMonth();
      const membershipThisYear = new Date(data[i].firstRegistered).getFullYear();
      if (
        membershipThisYear === thisYear
          && membershipThisMonth === thisMonth) {
        newMemberships++;
      }
      if (
        membershipThisYear === thisYear
        && membershipThisMonth === lastMonth
      ) {
        newMembershipsLastMonth++;
      }
      // if last month is dezember
      if (thisMonth === 0) {
        if (
          membershipThisYear === dezemberLastYear
          && membershipThisMonth === lastMonth
        ) {
          newMembershipsLastMonth++;
        }
      }
    }
  }
  return [newMemberships, newMembershipsLastMonth];
};

export const FEES = function (data) {
  let fees = 0;
  let feesLastMonth = 0;
  const thisMonth = new Date().getMonth();
  const lastMonth = (thisMonth === 0) ? 11 : new Date().getMonth() - 1;
  const thisYear = new Date().getFullYear();
  const dezemberLastYear = new Date().getFullYear() - 1;


  for (let i = 0; i < data.length; i++) {
    if (
      data[i].category === 'Membership Registration'
      || data[i].category === 'Change Membership Type'
      || data[i].category === 'Renew Membership') {
      const feesThisMonth = new Date(data[i].paymentDate).getMonth();
      const feesThisYear = new Date(data[i].paymentDate).getFullYear();
      if (
        feesThisYear === thisYear
          && feesThisMonth === thisMonth) {
        fees += data[i].fee;
      }
      if (
        feesThisYear === thisYear
        && feesThisMonth === lastMonth
      ) {
        feesLastMonth += data[i].fee;
      }
      if (thisMonth === 0) {
        if (
          feesThisYear === dezemberLastYear
          && feesThisMonth === lastMonth
        ) {
          feesLastMonth += data[i].fee;
        }
      }
    }
  }
  return [fees, feesLastMonth];
};

export const ACTIVEMEMBERS = function (data) {
  let activeMembers = 0;
  for (let i = 0; i < data.length; i++) {
    if (data[i].status !== 'inactive') {
      const membershipDate = new Date(data[i].expirationDate);
      const todayDate = new Date();
      const endDate = new Date();
      endDate.setDate(endDate.getDate() + 30);
      if (membershipDate > todayDate
        && membershipDate < endDate) {
        continue;
      } else if (membershipDate < todayDate) {
        continue;
      } else if (data[i].status === 'Unverified') {
        continue;
      } else {
        activeMembers++;
      }
    }
  }
  return activeMembers;
};

export const PROGRAMREGISTRATIONS = function (data) {
  const today = new Date();
  let thisSeason = 0;
  let lastSeason = 0;
  for (let i = 0; i < data.length; i++) {
    if (today.getFullYear() === new Date(data[i].registrationDate).getFullYear()) {
      if (DATETOSEASON(today) === DATETOSEASON(new Date(data[i].registrationDate))) {
        thisSeason += 1;
      }
      if (GETLASTSEASON(today) === DATETOSEASON(new Date(data[i].registrationDate))) {
        lastSeason += 1;
      }
    }
  }

  return [lastSeason, thisSeason];
};

export const PROGRAMSTOTALBYSEASON = function (data) {
  const today = new Date();
  let thisSeason = 0;
  let lastSeason = 0;
  for (let i = 0; i < data.length; i++) {
    if (today.getFullYear() === new Date(data[i].creationDate).getFullYear()) {
      if (DATETOSEASON(today) === DATETOSEASON(new Date(data[i].creationDate))) {
        thisSeason += 1;
      }
      if (GETLASTSEASON(today) === DATETOSEASON(new Date(data[i].creationDate))) {
        lastSeason += 1;
      }
    }
  }
  return [lastSeason, thisSeason];
};

export const PROGRAMREGISTRATIONSBYYEAR = function (data) {
  const today = new Date();
  const thisYear = {
    winter: 0,
    spring: 0,
    summer: 0,
    fall: 0,
  };
  const lastYear = {
    winter: 0,
    spring: 0,
    summer: 0,
    fall: 0,
  };
  for (let i = 0; i < data.length; i++) {
    if (today.getFullYear() === new Date(data[i].registrationDate).getFullYear()) {
      if (DATETOSEASON(new Date(data[i].registrationDate)) === 'winter') {
        thisYear.winter += 1;
      }
      if (DATETOSEASON(new Date(data[i].registrationDate)) === 'spring') {
        thisYear.winter += 1;
      }
      if (DATETOSEASON(new Date(data[i].registrationDate)) === 'summer') {
        thisYear.winter += 1;
      }
      if (DATETOSEASON(new Date(data[i].registrationDate)) === 'fall') {
        thisYear.winter += 1;
      }
    }
    if (today.getFullYear() - 1 === new Date(data[i].registrationDate).getFullYear()) {
      if (DATETOSEASON(new Date(data[i].registrationDate)) === 'winter') {
        lastYear.winter += 1;
      }
      if (DATETOSEASON(new Date(data[i].registrationDate)) === 'spring') {
        lastYear.winter += 1;
      }
      if (DATETOSEASON(new Date(data[i].registrationDate)) === 'summer') {
        lastYear.winter += 1;
      }
      if (DATETOSEASON(new Date(data[i].registrationDate)) === 'fall') {
        lastYear.winter += 1;
      }
    }
  }
  return [lastYear, thisYear];
};


export const calculateDailyCheckInMonthData = function (dailyCheckInData, filterYear) {
  const monthsCounter = {
    jan: { members: 0, staff: 0, guests: 0 },
    feb: { members: 0, staff: 0, guests: 0 },
    mar: { members: 0, staff: 0, guests: 0 },
    apr: { members: 0, staff: 0, guests: 0 },
    may: { members: 0, staff: 0, guests: 0 },
    jun: { members: 0, staff: 0, guests: 0 },
    jul: { members: 0, staff: 0, guests: 0 },
    aug: { members: 0, staff: 0, guests: 0 },
    sep: { members: 0, staff: 0, guests: 0 },
    oct: { members: 0, staff: 0, guests: 0 },
    nov: { members: 0, staff: 0, guests: 0 },
    dez: { members: 0, staff: 0, guests: 0 },
  };

  const addMonthValues = (monthName, data) => {
    monthsCounter[monthName].members += data.members.length;
    monthsCounter[monthName].staff += data.staff.length;
    monthsCounter[monthName].guests += data.guests.length;
  };

  for (let i = 0; i < dailyCheckInData.length; i++) {
    if (new Date(dailyCheckInData[i].date).getFullYear() === filterYear) {
      if (new Date(dailyCheckInData[i].date).getMonth() === 0) {
        addMonthValues('jan', dailyCheckInData[i]);
      }
      if (new Date(dailyCheckInData[i].date).getMonth() === 1) {
        addMonthValues('feb', dailyCheckInData[i]);
      }
      if (new Date(dailyCheckInData[i].date).getMonth() === 2) {
        addMonthValues('mar', dailyCheckInData[i]);
      }
      if (new Date(dailyCheckInData[i].date).getMonth() === 3) {
        addMonthValues('apr', dailyCheckInData[i]);
      }
      if (new Date(dailyCheckInData[i].date).getMonth() === 4) {
        addMonthValues('may', dailyCheckInData[i]);
      }
      if (new Date(dailyCheckInData[i].date).getMonth() === 5) {
        addMonthValues('jun', dailyCheckInData[i]);
      }
      if (new Date(dailyCheckInData[i].date).getMonth() === 6) {
        addMonthValues('jul', dailyCheckInData[i]);
      }
      if (new Date(dailyCheckInData[i].date).getMonth() === 7) {
        addMonthValues('aug', dailyCheckInData[i]);
      }
      if (new Date(dailyCheckInData[i].date).getMonth() === 8) {
        addMonthValues('sep', dailyCheckInData[i]);
      }
      if (new Date(dailyCheckInData[i].date).getMonth() === 9) {
        addMonthValues('oct', dailyCheckInData[i]);
      }
      if (new Date(dailyCheckInData[i].date).getMonth() === 10) {
        addMonthValues('nov', dailyCheckInData[i]);
      }
      if (new Date(dailyCheckInData[i].date).getMonth() === 11) {
        addMonthValues('dez', dailyCheckInData[i]);
      }
    }
  }

  return monthsCounter;
};
