import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Paper } from '@material-ui/core';
import Icon from '../../../../../assets/icons/information.png';
import { BootstrapTextField } from '../../../../../materialUi/bootstrapInputs';
import '../../../../../css/payment.css';

class fees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      membershipCardError: 'hidden',
    };
  }

  render() {
    const {
      familyMembers,
      values,
      messages,
      errors,
      membershipID,
    } = this.props;
    const { membershipCardError } = this.state;
    let familyMembersTodisplay = [];
    let membershipType;
    let membershipCategory;
    if (familyMembers !== '') {
      familyMembersTodisplay = familyMembers;
    }
    if (JSON.parse(sessionStorage.getItem(`verifyMembership_${membershipID}`))) {
      membershipType = JSON.parse(sessionStorage.getItem(`verifyMembership_${membershipID}`)).membership.membershipType;
      membershipCategory = JSON.parse(sessionStorage.getItem(`verifyMembership_${membershipID}`)).membership.membershipCategory;
    }

    return (
      <Grid item xs={12} md className="fees-col">
        <Paper className="payment-boxes-fees">
          <Box className="fee-title">Membership Fees</Box>
          <hr className="fees-hr" />
          <Grid className="payment-boxes-fees-spacing">
            <Grid container>
              <Grid item xs>
                <span className="fees-labels">Category</span>
              </Grid>
              <Grid item xs>
                <span className="fees-labels">Type</span>
              </Grid>
              <Grid item xs>
                <span className="fees-labels">Total Amount</span>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs>
                {membershipCategory}
                &nbsp;
                <img src={Icon} alt="Question mark" width="12" height="12" />
              </Grid>
              <Grid item xs>
                {membershipType}
              </Grid>
              <Grid item xs>
                $
                {values.fee}
                .00
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        <Grid container className="membershipCard-box">
          <Grid item xs>
            <Box className="Payment-title">Membership Card</Box>
            <Box className="Membership-card-text">To successfully complete the  membership, scan the barcode on the back of the membership card. </Box>
            <Grid container>
              <Grid item xs={12} className="membershipCard-error" id={membershipCardError}>Membership cards are required!</Grid>
              <Grid item xs={12}>
                {familyMembersTodisplay.map((option, index) => (
                  <Grid container key={index} style={{ marginTop: '20px' }}>
                    <Grid item xs={12} md={12} lg>
                      {
                        (index === 0)
                          ? (
                            <Grid item xs>
                              <b>
                                {option.firstName}
                                &nbsp;
                                     {option.lastName}
                                :&nbsp;
                                   </b>
                              (Primary)
                                 </Grid>
                          )
                          : (
                            <Grid item xs>
                              <b>
                                {option.firstName}
                                &nbsp;
                                     {option.lastName}
                              </b>
                            </Grid>
                          )
                      }
                    </Grid>
                    <Grid item xs={12} md={12} lg>
                      <BootstrapTextField
                        id={`membershipCard${index}`}
                        type="text"
                        name="Membership Card"
                        value={values[`membershipCard${index}`]}
                        handleChanges={(value) => this.props.handleInputs(`membershipCard${index}`, value.target.value, 'name', `${option.firstName} card`, index)}
                        message={messages[`membershipCard${index}`]}
                        readOnly={false}
                        error={errors[`membershipCard${index}`]}
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

fees.propTypes = {
  familyMembers: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  values: PropTypes.shape({
    fee: PropTypes.number,
  }).isRequired,
  messages: PropTypes.objectOf(PropTypes.string).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  membershipID: PropTypes.string.isRequired,
};

fees.defaultProps = {
  familyMembers: [{}],
};

export default fees;
