/* eslint-disable no-restricted-syntax */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import TextField from '../../../../materialUi/textField';
import Dropdown from '../../../../materialUi/dropdown';
import { validateField } from '../../../../utils/fieldsValidation';
import '../../../../css/registerMember.css';

class familyMemberForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        gender: 'Choose',
        relationship: 'Select One',
        phone: '',
        school: '',
      },
      messages: {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        gender: '',
        relationship: 'Eg. Spouse',
        phone: 'Optional',
        school: 'Optional',
      },
      errors: {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        gender: '',
        relationship: '',
        phone: false,
        school: false,
      },
    };
  }

  // check if there are values coming from the session
  // case true: check if they are valid sending back to the parent handleInputs
  // case false: do not display any message
  componentDidMount() {
    const { numberOfMembers } = this.props;
    const member = JSON.parse(sessionStorage.getItem(`addMember${numberOfMembers}`));
    if (member !== null) {
      // eslint-disable-next-line
      for (let values in member) {
        if (values === 'firstName' && member[values] !== '') this.handleFamilyInputs('firstName', member[values], 'name', 'First Name');
        if (values === 'lastName' && member[values] !== '') this.handleFamilyInputs('lastName', member[values], 'name', 'Last Name');
        if (values === 'dateOfBirth' && member[values] !== '') this.handleFamilyInputs('dateOfBirth', member[values], 'date');
        if (values === 'gender' && member[values] !== 'Choose') this.handleFamilyInputs('gender', member[values], 'dropdown');
        if (values === 'relationship' && member[values] !== 'Select One') this.handleFamilyInputs('relationship', member[values], 'dropdown');
        if (values === 'phone' && member[values] !== '') this.handleFamilyInputs('phone', member[values], 'phone', true);
        if (values === 'school' && member[values] !== '') this.handleFamilyInputs('school', member[values], 'not required');
      }
    }
  }

  componentDidUpdate(previousProps) {
    const { numberOfMembers, familyCheckValidation } = this.props;
    if (previousProps.familyCheckValidation !== familyCheckValidation) {
      const { errors, messages } = this.state;
      if (familyCheckValidation) {
        let fieldsvalidationResult = 0;
        // eslint-disable-next-line
        for (let key in errors) {
          if (errors[key] === true || errors[key] === '' || errors[key] === 'Select One') {
            fieldsvalidationResult++;
          }
        }
        if (fieldsvalidationResult !== 0) {
          const updateErrors = errors;
          const updateMessages = messages;
          for (const key in updateErrors) {
            if (updateErrors[key] !== false) {
              updateErrors[key] = true;
              updateMessages[key] = 'Required Field!';
            }
          }
          // set errors to true and display all messages
          this.setState({ errors: updateErrors, messages: updateMessages });
        }
      }
    }
    const { values } = this.state;
    const member = {
      firstName: values.firstName,
      lastName: values.lastName,
      age: values.age,
      dateOfBirth: values.dateOfBirth,
      gender: values.gender,
      relationship: values.relationship,
      phone: values.phone,
      school: values.school,
    };
    sessionStorage.setItem(`addMember${numberOfMembers}`, JSON.stringify(member));
  }

  handleFamilyInputs = (name, value, type, requirement = '') => {
    const { values, messages, errors } = this.state;
    const { handleInputs, numberOfMembers } = this.props;
    if (validateField(value, type, requirement) === true) {
      values[name] = value;
      messages[name] = '';
      errors[name] = false;
      handleInputs(name, value, false, numberOfMembers);
    } else {
      values[name] = value;
      messages[name] = validateField(value, type, requirement);
      errors[name] = true;
      handleInputs(name, value, true, numberOfMembers);
    }
    this.setState({ values, messages, errors });
  };

  render() {
    const { values, messages, errors } = this.state;
    const { numberOfMembers } = this.props;
    const relationship = [{ name: 'Select One' }, { name: 'Spouse' }, { name: 'Child' }, { name: 'Parent' }, { name: 'Sibling' }, { name: 'Grand Parents' }, { name: 'Grand Child' }];
    const gender = [{ name: 'Choose' }, { name: 'Man' }, { name: 'Woman' }, { name: 'Non-binary' }, { name: 'I choose not to disclose' }];
    return (
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} className="register-family-cols">
            <b>
              Member&nbsp;
              {numberOfMembers}
            </b>
          </Grid>
          <Grid item xs={12} md className="register-cols">
            <TextField
              label="First Name"
              id="First Name"
              value={values.firstName}
              handleChanges={(value) => this.handleFamilyInputs('firstName', value.target.value, 'name', 'First Name')}
              helperText={messages.firstName}
              error={errors.firstName === '' ? false : errors.firstName}
              readOnly={false}
              type="text"
            />
          </Grid>
          <Grid item xs={12} md className="register-cols">
            <TextField
              label="Last Name"
              id="Last Name"
              value={values.lastName}
              handleChanges={(value) => this.handleFamilyInputs('lastName', value.target.value, 'name', 'Last Name')}
              helperText={messages.lastName}
              error={errors.lastName === '' ? false : errors.lastName}
              readOnly={false}
            />
          </Grid>
          <Grid item xs={12} md className="register-cols">
            <TextField
              label="Date of Birth"
              id="Date Of Birth"
              value={values.dateOfBirth}
              handleChanges={(value) => this.handleFamilyInputs('dateOfBirth', value.target.value, 'date')}
              helperText={messages.dateOfBirth}
              error={errors.dateOfBirth === '' ? false : errors.dateOfBirth}
              readOnly={false}
              type="date"
              shrink
            />
          </Grid>
          <Grid item xs={12} md className="register-cols">
            <TextField
              label="Phone#"
              id="Phone"
              value={values.phone}
              handleChanges={(value) => this.handleFamilyInputs('phone', value.target.value, 'phone', true)}
              helperText={messages.phone === '' ? 'Optional' : messages.phone}
              error={errors.phone === '' ? false : errors.phone}
              readOnly={false}
            />
          </Grid>
        </Grid>
        <Grid container className="family-row">
          <Grid item xs={12} md className="register-cols">
            <Dropdown
              label="Relationship"
              name="Relationship"
              id="Relationship"
              value={values.relationship}
              handleChanges={(value) => this.handleFamilyInputs('relationship', value.target.value, 'dropdown')}
              helperText={messages.relationship}
              error={errors.relationship}
              listName={relationship}
            />
          </Grid>
          <Grid item xs={12} md className="register-cols">
            <TextField
              label="School"
              id="School"
              value={values.school}
              handleChanges={(value) => this.handleFamilyInputs('school', value.target.value, 'not required')}
              helperText={messages.school === '' ? 'Optional' : messages.school}
              error={errors.school === '' ? false : errors.school}
              readOnly={false}
              type="text"
            />
          </Grid>
          <Grid item xs={12} md className="register-cols">
            <Dropdown
              label="Gender"
              name="Gender"
              id="Gender"
              value={values.gender}
              handleChanges={(value) => this.handleFamilyInputs('gender', value.target.value, 'dropdown')}
              helperText={messages.gender}
              error={errors.gender === '' ? false : errors.gender}
              listName={gender}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

familyMemberForm.propTypes = {
  numberOfMembers: PropTypes.number.isRequired,
  handleInputs: PropTypes.func.isRequired,
  familyCheckValidation: PropTypes.bool.isRequired,
};

export default familyMemberForm;
