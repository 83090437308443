import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import SearchTextField from '../../../../materialUi/searchTextField';
import DatePicker from '../../../../materialUi/datePicker';
import '../../../../css/receipts.css';

export default function filter({
  handleDate,
  values,
  messages,
  handleReset,
  handleSearch,
}) {
  return (
    <Grid item xs={12}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Box className="receipts-filter-searchBox">
          <SearchTextField label="Search" search={(value) => handleSearch(value.target.value)} />
        </Box>
        <Box className="receipts-filter-date">
          <DatePicker
            label="Start Date"
            id="startDate"
            name="Start Date"
            value={values.startDate}
            handleChanges={(value) => handleDate('startDate', value, 'date')}
            helperText={messages.startDate}
            error={messages.startDate !== ''}
            readOnly={false}
          />
        </Box>
        <Box className="receipts-filter-date-to">
          To
        </Box>
        <Box className="receipts-filter-date">
          <DatePicker
            label="End Date"
            id="endDate"
            name="End Date"
            value={values.endDate}
            handleChanges={(value) => handleDate('endDate', value, 'date')}
            helperText={messages.endDate}
            error={messages.endDate !== ''}
            readOnly={false}
            type="date"
          />
        </Box>
        <Box className="receipts-filter-restart" onClick={handleReset}>
          <i className="fas fa-redo-alt" />
        </Box>
      </Grid>
    </Grid>
  );
}

filter.propTypes = {
  values: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  messages: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  handleDate: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
};
