import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '../../../../../materialUi/radioGroup';

function SectionThree(props) {
  const {
    control,
    formState: { errors },
    setValue,
    clearErrors,
    register,
    unregister,
    data,
  } = props;

  const [benefits, setBenefits] = React.useState('no');
  const [appliedForBenefits, setAppliedBenefits] = React.useState('no');
  const pastYear = new Date().getFullYear() - 1;

  const handleBenefits = (value) => {
    if (value === 'yes') {
      register('benefits', { required: 'Please Select One' });
    } else {
      unregister('benefits');
    }
    setValue('receivedBenefits', value);
    setBenefits(value);
  };

  const handleAppliedBenefits = (value) => {
    setValue('appliedForBenefits', value);
    setAppliedBenefits(value);
  };

  const YesNoQuestions = [
    {
      value: 'yes',
      label: 'Yes',
    },
    {
      value: 'no',
      label: 'No',
    },
  ];

  const [allBenefits, setAllBenefits] = React.useState([]);
  const [selectedBenefit, setSelectedBenefit] = React.useState({
    CERB: false,
    CESB: false,
    CRB: false,
    CRCB: false,
    CRSB: false,
  });

  const allBenefitsList = [
    {
      label: 'Canada Emergency Response Benefit (CERB)',
      value: 'CERB',
    },
    {
      label: 'Canada Emergency Student Benefit (CESB)',
      value: 'CESB',
    },
    {
      label: ' Canada Recovery Benefit (CRB)',
      value: 'CRB',
    },
    {
      label: 'Canada Recovery Caregiving Benefit (CRCB)',
      value: 'CRCB',
    },
    {
      label: 'Canada Recovery Sickness Benefit (CRSB)',
      value: 'CRSB',
    },
  ];

  const handleAllBenefits = (event) => {
    const value = event.target.name;
    if (allBenefits.includes(value)) {
      const index = allBenefits.indexOf(value);
      allBenefits.splice(index, 1);
    } else {
      allBenefits.push(value);
    }
    clearErrors('benefits');
    if (allBenefits.length === 0) {
      setValue('benefits', null, { shouldValidate: true });
    } else {
      setAllBenefits(allBenefits);
      setValue('benefits', allBenefits);
    }
    setSelectedBenefit({ ...selectedBenefit, [event.target.name]: event.target.checked });
  };

  const renderBenefits = (newBenefits) => {
    const benefitsCopy = selectedBenefit;
    for (let i = 0; i < newBenefits.length; i++) {
      Object.keys(selectedBenefit).forEach((key) => {
        if (key === newBenefits[i]) {
          benefitsCopy[key] = true;
        }
      });
    }
    setAllBenefits(newBenefits);
    setSelectedBenefit(benefitsCopy);
  };

  React.useEffect(() => {
    if (data.receivedBenefits) {
      renderBenefits(data.benefits);
      setBenefits(data.receivedBenefits);
    } else {
      register('receivedBenefits');
      setValue('receivedBenefits', 'no');
    }

    if (data.appliedForBenefits) {
      setAppliedBenefits(data.appliedForBenefits);
    } else {
      register('appliedForBenefits');
      setValue('appliedForBenefits', 'no');
    }
  }, [data]);

  return (
    <>
      <Grid item xs={12}>
        <div className="taxes-question">
          10. Are you an officer or a servant of another country? (Such as a diplomat, or a family member or an employee of such a person who were exempt from tax at any time in the taxation year).
        </div>
      </Grid>
      <Grid item xs={12}>
        <span className="custom-error">{errors.isAnOfficer && errors.isAnOfficer.message}</span>
        <Controller
          name="isAnOfficer"
          control={control}
          rules={{
            required: 'Please select One',
          }}
          render={({ field }) => (
            <RadioGroup
              listOptions={YesNoQuestions}
              ariaLabel=" 10. Are you an officer or a servant of another country?
                  (Such as a diplomat, or a family member or an employee
                   of such a person who were exempt from tax at any time in the taxation year)."
              name="isAnOfficer"
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">11. Did you work in Canada in {pastYear}?</div>
      </Grid>
      <Grid item xs={12}>
        <span className="custom-error">{errors.workInCanada && errors.workInCanada.message}</span>
        <Controller
          name="workInCanada"
          control={control}
          rules={{
            required: 'Please select One',
          }}
          render={({ field }) => <RadioGroup listOptions={YesNoQuestions} ariaLabel={`11. Did you work in Canada in ${pastYear}?`} name="workInCanada" {...field} />}
        />
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">11a. Did you work for different employers in {pastYear}?</div>
      </Grid>
      <Grid item xs={12}>
        <span className="custom-error">{errors.differentEmployers && errors.differentEmployers.message}</span>
        <Controller
          name="differentEmployers"
          control={control}
          rules={{
            required: 'Please select One',
          }}
          render={({ field }) => <RadioGroup listOptions={YesNoQuestions} ariaLabel={`11a. Did you work for different employers in ${pastYear}?`} name="differentEmployers" {...field} />}
        />
      </Grid>
      <Grid item xs={12} className="questionnaire-important-text">
        *If you answered ‘Yes’ above, please upload all T4 slips (you should receive them from your employer(s) before filing your tax).
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">12. Have you received any of the COVID-19 emergency or recovery benefits from the CRA before December 31, {pastYear}? (T4A slip(s))</div>
      </Grid>
      <Grid item xs={12}>
        <RadioGroup
          listOptions={YesNoQuestions}
          handleChange={(value) => handleBenefits(value.target.value)}
          value={benefits}
          ariaLabel={`12. Have you received any of the COVID-19 emergency or recovery benefits from the CRA before December 31, ${pastYear}? (T4A slip(s))`}
          name="dependant"
        />
      </Grid>
      <Grid item xs={12}>
        <div className="taxes-question">12a. Have you applied to Employment Insurance (EI) and received any COVID benefits from Service Canada (T4E slip)?</div>
      </Grid>
      <Grid item xs={12}>
        <RadioGroup
          listOptions={YesNoQuestions}
          handleChange={(value) => handleAppliedBenefits(value.target.value)}
          value={appliedForBenefits}
          ariaLabel="Have you applied to Employment Insurance (EI) and received any COVID benefits from Service Canada (T4E slip)?"
          name="dependant"
        />
      </Grid>
      {benefits === 'yes' && (
        <>
          <Grid item xs={12}>
            <div className="taxes-question">If you answered “yes” above, please check all that apply:</div>
          </Grid>
          <Grid item xs={12}>
            <div className="custom-error">{errors.benefits && errors.benefits.message}</div>
            <FormControl component="fieldset">
              <FormGroup>
                {allBenefitsList.map((item) => (
                  <FormControlLabel control={<Checkbox checked={selectedBenefit[item.value]} onChange={(event) => handleAllBenefits(event)} name={item.value} />} label={item.label} />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
        </>
      )}
    </>
  );
}

SectionThree.propTypes = {
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  formState: PropTypes.shape({
    errors: PropTypes.any,
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
  data: PropTypes.shape(
    {
      benefits: PropTypes.arrayOf(PropTypes.string),
      receivedBenefits: PropTypes.string,
    },
  ).isRequired,
};

export default SectionThree;
