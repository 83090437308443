import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import SearchTextField from '../../../../materialUi/searchTextField';
import '../../../../css/programsList.css';

export default class Filter extends Component {

  render() {
    const { search } = this.props;
    // const { winter, spring, fall, summer } = this.state;
    return (
      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
          <Box className="programsList-searchField">
            <SearchTextField
              label="Search Program"
              search={(value) => search(value.target.value)}
              width={200}
            />
          </Box>
        </Grid>
      </Grid>
    );
  }
}

Filter.propTypes = {
  search: PropTypes.func.isRequired,
};
