/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import {
  Grid, FormControl, FormGroup, FormControlLabel, Checkbox,
} from '@material-ui/core';
import { TIMETOAMPM } from '../../../../../utils/dateController';
import Alert from '../../../../../materialUi/Alerts';


function Schedule(props) {
  const {
    formState: { errors },
    programData,
    register,
    getValues,
    setValue,
    watch,
    data
  } = props;

  watch('familyMembers');

  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState({
    alertLocation: '',
    alertTitle: 'Program Registration Error!',
    alertBody: '',
    alertClose: 'hidden',
    alertStay: 'CLOSE',
  });

  const alertMessage = (message) => {
    setAlertContent({
      alertLocation: '',
      alertTitle: 'Program Registration Error!',
      alertBody: message,
      alertClose: 'hidden',
      alertStay: 'CLOSE',
    });
    setOpenAlert(true);
  };


  const removeUserSelectedWeekFromSelectedWeeks = (index) => {
    const SELECTED_WEEKS_COPY = getValues('selectedWeeks');
    SELECTED_WEEKS_COPY.splice(index, 1);
    setValue('selectedWeeks', SELECTED_WEEKS_COPY);
  };

  const validateWeekSelection = (userSelectedWeek) => {
    const SELECTED_WEEKS_COPY = getValues('selectedWeeks');
    if (SELECTED_WEEKS_COPY.length === 0) return false;
    const result = SELECTED_WEEKS_COPY.some((item, index) => {
      const sameWeekValidation = userSelectedWeek.startDate === item.startDate;
      if (sameWeekValidation) {
        removeUserSelectedWeekFromSelectedWeeks(index);
      }
      return sameWeekValidation;
    });
    return result;
  };

  const addSelectedWeek = (selectedWeek) => {
    const SELECTED_WEEKS_COPY = getValues('selectedWeeks');
    SELECTED_WEEKS_COPY.push(selectedWeek);
    setValue('selectedWeeks', SELECTED_WEEKS_COPY);
    return true;
  };

  const findSelectScheduleIndex = (selectedWeek) => {
    const SELECTED_WEEKS_COPY = getValues('selectedWeeks');
    let result = 0;
    SELECTED_WEEKS_COPY.forEach((item, index) => {
      if (item._id === selectedWeek._id) {
        result = index;
      }
    });
    return result;
  };

  const addFamilyMembersToProgramWeeklySchedule = (selectedWeek) => {
    const selectedIndex = findSelectScheduleIndex(selectedWeek);
    getValues('familyMembers').forEach(
      (member) => {
        const SELECTED_WEEKS_COPY = getValues('selectedWeeks');
        SELECTED_WEEKS_COPY[selectedIndex].familyMembers.push(member);
        setValue('selectedWeeks', SELECTED_WEEKS_COPY);
        return false
      }
    );
  };

  const removeFamilyMembersFromProgramWeeklySchedule = (selectedWeek) => {
    const selectedIndex = findSelectScheduleIndex(selectedWeek);
    getValues('familyMembers').forEach(
      (member, index) => {
        const SELECTED_WEEKS_COPY = getValues('selectedWeeks');
        if (SELECTED_WEEKS_COPY.length > 0) {
          SELECTED_WEEKS_COPY[selectedIndex].familyMembers.splice(index, 1);
          setValue('selectedWeeks', SELECTED_WEEKS_COPY);
        }
      }
    );
  };

  const handleSelectedWeek = (event, userSelectedWeek) => {
    const SAME_WEEK_WAS_SELECTED = validateWeekSelection(userSelectedWeek);
    if (SAME_WEEK_WAS_SELECTED) return removeFamilyMembersFromProgramWeeklySchedule(userSelectedWeek);
    addSelectedWeek(userSelectedWeek);
    addFamilyMembersToProgramWeeklySchedule(userSelectedWeek);
    return true;
  };

  const returnWeeklySpotsBasedOnMembershipType = (week) => {
    const MEMBERSHIP_TYPE = getValues('membership').membershipType.toLowerCase();
    if (MEMBERSHIP_TYPE === 'associate') {
      return week.associateSpots - week.familyMembers.length;
    }
    return week.communitySpots - week.familyMembers.length;
  };

  const thereAreSpotsLeft = (week) => {
    const participants = getValues('familyMembers').length;
    const spotsLeft = returnWeeklySpotsBasedOnMembershipType(week);
    if (participants <= spotsLeft) return true;
    return false;
  };

  React.useEffect(() => {
    register('selectedWeeks', { required: 'Please select at least one schedule' });
    setValue('selectedWeeks', []);
  }, []);

  const formateLabel = (item) => (
    <>
      <Moment date={item.startDate} format="MMM DD" />
      {' - '}
      <Moment date={item.endDate} format="MMM DD, YYYY" />
      {', '}
      {TIMETOAMPM(programData.weeklyStartTime)}
      {' - '}
      {TIMETOAMPM(programData.weeklyEndTime)}
    </>
  );

  const memberAlreadyRegistered = (userSelectedWeek) => {
    let result = false;
    getValues('familyMembers').forEach(
      (member) => {
        if (userSelectedWeek.familyMembers.includes(member._id)) return result = true
        return result = false
      }
    );
    return result
  }

  const renderSchedules = (weeklySchedules) => {
    const renderWeeks = []
    weeklySchedules.forEach((week, index) => {
      if (!memberAlreadyRegistered(week)) renderWeeks.push(week);
    })
    return renderWeeks;
  };

  const [localFamilyMembers, setLocalFamilyMembers] = React.useState(getValues('familyMembers').length);
  const [localWeeks, setLocalWeeks] = React.useState(renderSchedules(programData.weeklySchedule));

  React.useEffect(() => {
    if (localFamilyMembers !== getValues('familyMembers')) {
      setLocalFamilyMembers(getValues('familyMembers').length);
      const result = renderSchedules(programData.weeklySchedule)
      if (result.length === 0) {
        setValue('familyMembers', []);
        alertMessage('Selected family member already registered to all weeks, please select another family member.');
      }
      return setLocalWeeks(renderSchedules(programData.weeklySchedule));
    }
  }, [getValues('familyMembers')])

  return (
    <Grid container style={{ marginTop: '30px' }}>
      <Grid item xs={12}>
        <h3 className="programRegistration-titles">Schedule(s)</h3>
      </Grid>
      <Grid item xs={12} className="custom-error">
        {errors.selectedWeeks && errors.selectedWeeks.message}
      </Grid>
      <FormControl
        component="fieldset"
        style={{ width: '100%' }}
      >
        <FormGroup>
          <Grid container>
            {localWeeks.length === 0 ? (
              <div style={{ color: '#f44336' }}>The family member(s) is already registered to all weeks.</div>
            ) : (
              <>
                {
                  localWeeks.map((item, index) => (
                    <>
                      {thereAreSpotsLeft(item) && (
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={(
                              <Checkbox
                                value={item.familyMembers}
                                onChange={(event) => handleSelectedWeek(event, item)}
                                name={formateLabel(item)}
                              />
                            )}
                            label={formateLabel(item)}
                          />
                        </Grid>
                      )}
                    </>
                  ))
                }
              </>
            )}

          </Grid>
        </FormGroup>
      </FormControl>
      <Alert
        open={openAlert}
        title={alertContent.alertTitle}
        body={alertContent.alertBody}
        stay={alertContent.alertStay}
        leave={alertContent.alertClose}
        location={alertContent.alertLocation}
        close={() => setOpenAlert(false)}
      />
    </Grid>
  );
}

Schedule.propTypes = {
  formState: PropTypes.shape({
    errors: PropTypes.any,
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  data: PropTypes.shape(
    {
      name: PropTypes.string,
    },
  ).isRequired,
  programData: PropTypes.shape(
    {
      weeklyProgram: PropTypes.bool,
      weeklySchedule: PropTypes.arrayOf(PropTypes.any),
      weeklyStartTime: PropTypes.string,
      weeklyEndTime: PropTypes.string,
    },
  ).isRequired,
};

export default Schedule;
