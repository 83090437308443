import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Btn from '../../../materialUi/btn';
import CheckInSuccess from '../../../assets/icons/checkIn-success.svg';
import CheckInDeclined from '../../../assets/icons/checkIn-declined.svg';

function ResultMessage({ screeningResult, selectedUser, handleBack }) {
  return (
    <Grid item xs={12} className="mt-0 mt-sm-5">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid container className="covid19-checkInResult-box">
          <Grid item xs={12} className={(screeningResult) ? 'covid19-checkInResult-success' : 'covid19-checkInResult-declined'}>
            <img
              src={(screeningResult) ? CheckInSuccess : CheckInDeclined}
              alt={(screeningResult) ? 'Check-In Successful!' : 'Check-In Declined!'}
              className="covid19-checkInResult-img"
            />
            <h2 className="covid19-checkInResult-title">
              {(screeningResult) ? 'Check-In Successful!' : 'Check-In Not Permitted'}
            </h2>
          </Grid>
          <Grid item xs={12} className="covid19-checkInResult-subTitle">
            COVID-19 Screening Results:
            {' '}
            <span className={(screeningResult) ? 'covid19-checkInResult-negative' : 'covid19-checkInResult-positive'}>Negative</span>
          </Grid>
          <Grid item xs={12} className="covid19-checkInResult-text">
            {(screeningResult) ? (
              `${selectedUser.firstName} ${selectedUser.lastName} has been checked-in and is permitted to enter the facility.`
            ) : (
              `${selectedUser.firstName} ${selectedUser.lastName} should not be permitted to enter facility.`
            )}
          </Grid>
          <Grid item xs={12} className="pb-5">
            <Btn
              label={(screeningResult) ? 'Done' : 'back to main page'}
              onClick={handleBack}
              bgColor="#e76f50"
              color="#ffffff"
              bgColorHover="#e76f50"
              borderColor="#e76f50"
              height={36}
              width={(screeningResult) ? 112 : 200}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

ResultMessage.propTypes = {
  screeningResult: PropTypes.bool.isRequired,
  selectedUser: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  handleBack: PropTypes.func.isRequired,
};

ResultMessage.defaultProps = {

};

export default ResultMessage;
