import axios from 'axios';
import baseURL from './baseURL';
import TOKEN from './Frontend-Token';

export const authenticationAPI = {
  GET: {
    getAllUser() {
      return axios.get(`${baseURL}api/getAllUsers`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
  },
  authentication: {
    getUser(token) {
      return axios.get(`${baseURL}api/getUser`, { headers: { 'x-access-token': token } });
    },
    login(email, password) {
      return axios.post(`${baseURL}api/userLogin`, {
        email,
        password,
      });
    },
    logout() {
      return axios.get(`${baseURL}api/userLogout`, { headers: { 'x-access-token': TOKEN } });
    },
  },
};

export default authenticationAPI;
