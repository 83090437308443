import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Dropdown from '../../../../materialUi/dropdown';
import PaymentForm from '../../../../materialUi/paymentForm';
import ApproveDiscountDialog from './approveDiscount';
import Button from '../../../../materialUi/btn';
import '../../../../css/payment.css';

function PaymentMethod({
  submitPayment,
  initialFee,
  handleFee,
  userData,
  paymentStatus
}) {
  const { userType } = userData.userData;
  const [paymentMethod, setPaymentMethod] = React.useState(null);
  const [paymentMethodMessage, setPaymentMethodMessage] = React.useState(null);
  const [discount, setDiscount] = React.useState('-');
  const [openDialog, setOpenDialog] = React.useState(false);

  const discountList = [
    { name: '-' },
    { name: '10%' },
    { name: '20%' },
    { name: '30%' },
    { name: '40%' },
    { name: '50%' },
    { name: 'Free' },
  ];

  const publicPaymentOptions = [
    { name: 'Credit card' }
  ]

  const adminPaymentOptions = [
    { name: 'Credit card' },
    { name: 'Debit card' },
    { name: 'Apple pay' },
    { name: 'Cash' }
  ]

  const [paymentMethods, setLocalPaymentMethod] = React.useState(
    userType.toLowerCase() === 'member' ? publicPaymentOptions : adminPaymentOptions
  );

  const calculateDiscount = (percentage) => ((percentage / 100) * -initialFee + initialFee).toFixed(2);

  const getDiscount = (selectedDiscount) => {
    setLocalPaymentMethod([{ name: 'Credit card' }, { name: 'Cash' }]);
    if (selectedDiscount === '-') {
      return initialFee;
    }
    if (selectedDiscount === '10%') {
      return calculateDiscount(10);
    }
    if (selectedDiscount === '20%') {
      return calculateDiscount(20);
    }
    if (selectedDiscount === '30%') {
      return calculateDiscount(30);
    }
    if (selectedDiscount === '40%') {
      return calculateDiscount(40);
    }
    if (selectedDiscount === '50%') {
      return calculateDiscount(50);
    }
    setLocalPaymentMethod([{ name: 'Cash' }]);
    setPaymentMethod('Cash')
    return 'Free';
  };

  const handleDiscount = (event) => {
    const { value } = event.target;
    const newFee = getDiscount(value);
    if (value === '50%' || value === 'Free') {
      setOpenDialog(true);
    } else {
      handleFee(newFee);
    }
    setDiscount(value);
  };

  const handleDiscountPasswordValidation = (validation) => {
    if (validation) {
      const newFee = getDiscount(discount);
      handleFee(newFee);
      setOpenDialog(false);
    }
  };

  const handleSendCardInformation = (nonce, paymentMethod, full_name) => {
    submitPayment({
      type: 'card',
      nonce,
      cardHolderName: full_name,
    });
  };

  const handleSendCashPayment = () => {
    if (!paymentMethod) {
      setPaymentMethodMessage('Please, select one payment option.');
    } else {
      submitPayment({
        type: paymentMethod.toLowerCase(),
        nonce: false,
        cardHolderName: false,
      });
    }
  };

  const handlePaymentMethod = (event) => {
    setPaymentMethod(event.target.value);
    setPaymentMethodMessage(null);
  };

  React.useEffect(() => {
  }, [userData]);


  return (
    <Grid item xs={12} md className="paymentMethod-wrapper">
      <Grid container spacing={2}>
        <Grid item xs={12} md={userType.toLowerCase() !== 'member' ? 6 : 12}>
          <Dropdown
            name="Payment"
            value={paymentMethod}
            handleChanges={handlePaymentMethod}
            error={!!paymentMethodMessage}
            helperText={paymentMethodMessage || ''}
            label="Payment method"
            listName={paymentMethods}
          />
        </Grid>
        {userType.toLowerCase() !== 'member' && (
          <Grid item xs={12} md={6}>
            <Dropdown
              name="Discount"
              value={discount}
              handleChanges={handleDiscount}
              error={false}
              helperText=""
              label="Discount(%)"
              listName={discountList}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          {paymentMethod === 'Credit card' ? (
            <PaymentForm
              handleSaveOnDB={handleSendCardInformation}
              paymentStatus={paymentStatus}
            />
          ) : (
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                label="SUBMIT PAYMENT"
                bgColor="#e76f50"
                color="white"
                bgColorHover="#ff8c68"
                colorHover="white"
                width={165}
                onClick={handleSendCashPayment}
              />
            </Grid>

          )}
        </Grid>
        <ApproveDiscountDialog
          open={openDialog}
          close={() => setOpenDialog(false)}
          handleValidation={handleDiscountPasswordValidation}
        />
      </Grid>
    </Grid>
  );
}

PaymentMethod.propTypes = {
  submitPayment: PropTypes.func.isRequired,
  handleFee: PropTypes.func.isRequired,
  initialFee: PropTypes.string.isRequired,
};

export default PaymentMethod;
