import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import './style.css';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function DefaultDialog(props) {
  const { title, body, buttonOne, buttonTwo, open, handleChanges } = props;
  return (
    <div>
      <Dialog aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="alert-dialog-title" className="default-alert-title">
          {title}
        </DialogTitle>
        <DialogContent className="default-alert-body">
          <Typography gutterBottom >
            {body}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button className="default-dialog-buttons" onClick={() => handleChanges('buttonOne')}>
            {buttonOne}
          </Button>
          <Button className="default-dialog-buttons" onClick={() => handleChanges('buttonTwo')}>
            {buttonTwo}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DefaultDialog;
