import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import SearchTextField from '../../../../materialUi/searchTextField';
import Checkbox from '../../../../materialUi/checkBox';
import '../../../../css/receipts.css';

export default function filter({
  values,
  handleSearch,
  handleFilter,
}) {
  return (
    <Grid item xs={12} className="reports-covid19-filter-wrapper">
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
        <Box className="receipts-filter-searchBox">
          <SearchTextField
            label="Search by name, phone #,"
            search={(value) => handleSearch(value.target.value)}
            width={256}
          />
        </Box>
        <div className="dropdown ageGroup-filter">
          <button
            className="btn btn-white dropdown-toggle mr-2 ageGroup-input"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="ageGroup-input-icon">Age Group</span>
          </button>
          <div
            className="dropdown-menu ageGroup-filter-popup "
            aria-labelledby="dropdownMenuButton"
          >
            <span className="dropdown-item">
              <Checkbox
                fontSize={15}
                bottom={0}
                value={values.preSchool}
                click={(value) => handleFilter('preSchool', value, 'ageGroup', 'Pre-school')}
                message="Pre-school (0-5)"
              />
            </span>
            <span className="dropdown-item">
              <Checkbox
                fontSize={15}
                bottom={0}
                value={values.child}
                click={(value) => handleFilter('child', value, 'ageGroup', 'Child')}
                message="Child (6-12)"
              />
            </span>
            <span className="dropdown-item">
              <Checkbox
                fontSize={15}
                bottom={0}
                value={values.youth}
                click={(value) => handleFilter('youth', value, 'ageGroup', 'Youth')}
                message="Youth (13-17)"
              />
            </span>
            <span className="dropdown-item">
              <Checkbox
                fontSize={15}
                bottom={0}
                value={values.adult}
                click={(value) => handleFilter('adult', value, 'ageGroup', 'Adult')}
                message="Adult (18+)"
              />
            </span>
            <span className="dropdown-item">
              <Checkbox
                fontSize={15}
                bottom={0}
                value={values.senior}
                click={(value) => handleFilter('senior', value, 'ageGroup', 'Senior')}
                message="Senior"
              />
            </span>
          </div>
        </div>
        <div className="dropdown status-filter">
          <button
            className="btn btn-white dropdown-toggle mr-2 status-filter-input"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="receipt-covid19-filter-dropdown">Status</span>
          </button>
          <div className="dropdown-menu status-filter-popup" aria-labelledby="dropdownMenuButton">
            <span className="dropdown-item">
              <Checkbox
                fontSize={15}
                bottom={0}
                value={values.active}
                click={(value) => handleFilter('active', value, 'status', 'Active')}
                message="Active"
              />
            </span>
            <span className="dropdown-item">
              <Checkbox
                fontSize={15}
                bottom={0}
                value={values.unverified}
                click={(value) => handleFilter('unverified', value, 'status', 'unverified')}
                message="Unverified"
              />
            </span>
            <span className="dropdown-item">
              <Checkbox
                fontSize={15}
                bottom={0}
                value={values.expired}
                click={(value) => handleFilter('expired', value, 'status', 'expired')}
                message="Expired"
              />
            </span>
            <span className="dropdown-item">
              <Checkbox
                fontSize={15}
                bottom={0}
                value={values.expiring}
                click={(value) => handleFilter('expiring', value, 'status', 'expiring')}
                message="Expiring"
              />
            </span>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

filter.propTypes = {
  values: PropTypes.shape({
    preSchool: PropTypes.bool,
    children: PropTypes.bool,
    youth: PropTypes.bool,
    adult: PropTypes.bool,
    senior: PropTypes.bool,
    active: PropTypes.bool,
    unverified: PropTypes.bool,
    expired: PropTypes.bool,
    expiring: PropTypes.bool,
  }).isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired,
};
