import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import TextField from '../../../../materialUi/textField';
import CheckBox from '../../../../materialUi/checkBox';

function Regular(props) {
  const {
    control,
    formState: { errors },
    register,
    setValue,
    setError,
    clearErrors,
    data,
  } = props;

  const [days, setDays] = React.useState(data.days ? data.days : []);

  const handleDaysError = (validation) => {
    if (validation) {
      setError('days', {
        type: 'Required',
        message: 'Please select at least one day',
      });
    } else {
      clearErrors('days');
    }
  };

  const handleDays = (name) => {
    const copyDays = [...days];
    if (!copyDays.includes(name)) {
      copyDays.push(name);
    } else {
      const INDEX = copyDays.indexOf(name);
      copyDays.splice(INDEX, 1);
    }
    setDays(copyDays);
    setValue('days', copyDays);

    handleDaysError(copyDays.length === 0);
  };

  React.useEffect(() => {
    register('days', { required: 'Please select at least one day' });
  }, []);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Controller
          name="programStartTime"
          control={control}
          rules={{
            required: 'Program Start Time is required',
            maxLength: { value: 100, message: 'Max length 100' },
          }}
          render={({ field }) => (
            <TextField
              id="programStartTime"
              type="search"
              name="programStartTime"
              label="Program Start Time"
              helperText={errors.programStartTime ? errors.programStartTime.message : ''}
              error={!!errors.programStartTime}
              width="100%"
              readOnly={false}
              type="time"
              shrink
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          name="programEndTime"
          control={control}
          rules={{
            required: 'Program End Time is required',
            maxLength: { value: 100, message: 'Max length 100' },
          }}
          render={({ field }) => (
            <TextField
              id="programEndTime"
              type="search"
              name="programEndTime"
              label="Program End Time"
              helperText={errors.programEndTime ? errors.programEndTime.message : ''}
              error={!!errors.programEndTime}
              width="100%"
              readOnly={false}
              type="time"
              shrink
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} className="addNewProgram-days">
        <Grid container>
          <Grid item xs>
            <Grid container>
              <Grid item xs={12}>
                <CheckBox
                  id="Monday"
                  value={days.includes('monday')}
                  message="Monday"
                  click={(value) => handleDays('monday')}
                />
              </Grid>
              <Grid item xs={12}>
                <CheckBox
                  id="Friday"
                  value={days.includes('friday')}
                  message="Friday"
                  click={(value) => handleDays('friday')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <Grid container>
              <Grid item xs={12}>
                <CheckBox
                  id="Tuesday"
                  value={days.includes('tuesday')}
                  message="Tuesday"
                  click={(value) => handleDays('tuesday')}
                />
              </Grid>
              <Grid item xs={12}>
                <CheckBox
                  id="Saturday"
                  value={days.includes('saturday')}
                  message="Saturday"
                  click={(value) => handleDays('saturday')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <Grid container>
              <Grid item xs={12}>
                <CheckBox
                  id="Wednesday"
                  value={days.includes('wednesday')}
                  message="Wednesday"
                  click={(value) => handleDays('wednesday')}
                />
              </Grid>
              <Grid item xs={12}>
                <CheckBox
                  id="Sunday"
                  value={days.includes('sunday')}
                  message="Sunday"
                  click={(value) => handleDays('sunday')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <Grid container>
              <Grid item xs={12}>
                <CheckBox
                  id="Thursday"
                  value={days.includes('thursday')}
                  message="Thursday"
                  click={(value) => handleDays('thursday')}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="addNewProgram-days">
        {errors.days && (
          <span className="addNewProgram-days-error">
            {errors.days.message}
          </span>
        )}
      </Grid>
    </Grid>
  );
}

Regular.propTypes = {
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  formState: PropTypes.shape({
    errors: PropTypes.any,
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  data: PropTypes.shape(
    {
      programStartTime: PropTypes.string,
      programEndTime: PropTypes.string,
      days: PropTypes.arrayOf(PropTypes.any),
    },
  ).isRequired,
};

export default Regular;
