import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid, Box } from '@material-ui/core';
import BallIcon from '@material-ui/icons/FiberManualRecord';
import BackButton from '../../../../materialUi/backButton';
import MenuDropdown from '../../../../materialUi/menuDropdown';
import MenuIcon from '../../../../materialUi/menuIcon';
import Button from '../../../../materialUi/btn';

function TopContent(
  {
    history, updateTaxReturn, tabNumber, handleExport, data, addDocuments,
  },
) {
  const menuOptions = ['Download CSV'];
  const statusList = [
    {
      label: 'In Review',
      value: 'inReview',
    },
    {
      label: 'Missing Info',
      value: 'missingInfo',
    },
    {
      label: 'Pending',
      value: 'pending',
    },
    {
      label: 'CRA Issue',
      value: 'craIssue',
    },
    {
      label: 'Complete',
      value: 'complete',
    },
  ];


  const formatStatus = (status) => {
    if (status === 'missingInfo') {
      return 'Missing Info';
    }
    if (status === 'inReview') {
      return 'In Review';
    }
    if (status === 'craIssue') {
      return 'CRA Issue';
    }
    return status;
  };

  const handleStatusChange = (selectedStatus) => {
    const taxReturnCopy = data;
    taxReturnCopy.status = selectedStatus.value;
    updateTaxReturn(taxReturnCopy);
  };

  return (
    <>
      <Grid item xs={12}>
        <BackButton
          handleClick={() => history.push('/adminPortal/reports/taxes')}
          label="Back to tax questionnaire submissions"
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Box>
            <h1 className="taxes-title mt-4 mb-3">
              Tax Questionnaire #
              {data.formID}
              <span className={`taxSubmissionList-status-ball-${data.status}`}>
                <BallIcon fontSize="small" />
              </span>
              <MenuDropdown
                listItems={statusList}
                handleChanges={(selectedStatus) => handleStatusChange(selectedStatus)}
                selectedItemLabel={formatStatus(data.status)}
              />
            </h1>
          </Box>
          <Box>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              {tabNumber === 0 && (
                <Box>
                  <Button
                    label="Edit"
                    bgColor="#e76f50"
                    color="white"
                    colorHover="white"
                    bgColorHover="#ff8c68"
                    width={(window.innerWidth < 600) ? 100 : 128}
                    onClick={() => history.push(`/adminPortal/reports/taxes/edit/${data._id}`)}
                    type="submit"
                  />
                </Box>
              )}
              {tabNumber === 2 && (
              <Box>
                <Button
                  label="ADD ATTACHMENTS"
                  bgColor="#e76f50"
                  color="white"
                  colorHover="white"
                  bgColorHover="#ff8c68"
                  width={197}
                  height={35}
                  fontSize={16}
                  onClick={addDocuments}
                  type="submit"
                />
              </Box>
              )}
              {tabNumber === 0 && (
              <Box>
                <MenuIcon list={menuOptions} onClick={(value) => handleExport(value)} />
              </Box>
              )}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

TopContent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  updateTaxReturn: PropTypes.func.isRequired,
  tabNumber: PropTypes.number.isRequired,
  handleExport: PropTypes.func.isRequired,
  addDocuments: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(TopContent);
