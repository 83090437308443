import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import { Elements, StripeProvider } from 'react-stripe-elements';
import TextField from '../../../../../materialUi/textField';
import '../../../../../css/payment.css';
import Dropdown from '../../../../../materialUi/dropdown';
import Btn from '../../../../../materialUi/btn';
import CancelAlert from '../../../../../materialUi/cancelAlert';
import Loading from '../../../../../materialUi/loading';
import CheckoutForm from './checkoutForm';

class PaymentMethod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openCancelAlert: false,
    };
  }

  componentDidMount() {
    const { handleInputs, membershipID } = this.props;
    // get the values from membership Obj on session
    const verifyMembershipFromSession = JSON.parse(sessionStorage.getItem(`verifyMembership_${membershipID}`));
    if (verifyMembershipFromSession) {
      if (verifyMembershipFromSession.membership.paymentMethod) {
        if (verifyMembershipFromSession.membership.paymentMethod !== 'Select One') {
          handleInputs('paymentMethod', verifyMembershipFromSession.membership.paymentMethod, 'dropdown');
        }
      }
      if (verifyMembershipFromSession.membership.fee) {
        if (verifyMembershipFromSession.membership.fee !== '') {
          handleInputs('fee', verifyMembershipFromSession.membership.fee, 'price');
        }
      }
    }
  }

  render() {
    const { values, messages, errors, handleInputs, handleCard, loading, disableBtn, handleSaveOnDB } = this.props;
    const { openCancelAlert } = this.state;
    const payment = [ { name: 'Select One' }, { name: 'Credit card' },
    { name: 'Debit card' },
    { name: 'Apple pay' },
    { name: 'Cash' }];

    return (
      <Grid item xs={12} md className="paymentMethod-col">
        <Grid container>
          <Grid item xs={12}>
            <TextField
              label="Amount"
              id="amount"
              value={values.fee}
              handleChanges={(value) => handleInputs('fee', value.target.value, 'price')}
              readOnly={false}
              error={errors.fee}
              helperText={messages.fee}
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              label="Payment Method"
              id="Payment Method"
              name="Payment Method"
              value={values.paymentMethod}
              handleChanges={(value) => handleInputs('paymentMethod', value.target.value, 'dropdown')}
              helperText={messages.paymentMethod}
              error={errors.paymentMethod}
              listName={payment}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs className="stripe-spacing">
            {values.paymentMethod === 'Card' ? (
              <Grid item xs>
                <h1 className="Payment-title">Card Information</h1>
                <StripeProvider apiKey="pk_test_2hYpsuDxwtVVUdztyx2D18BY002CrjMW7e">
                  <div className="example">
                    <Elements>
                      <CheckoutForm values={values} errors={errors} messages={messages} handleInputs={handleInputs} handleCard={handleCard} />
                    </Elements>
                  </div>
                </StripeProvider>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        <Grid container className={values.paymentMethod === 'Card' ? 'btns-payment-bottom' : 'bottom'}>
          <Grid item xs>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              <Box>
                <Btn
                  label="Cancel"
                  bgColor="white"
                  color="black"
                  bgColorHover="#eceff1"
                  colorHover="white"
                  borderColor="white"
                  variant="outlined"
                  width={128}
                  onClick={() => this.setState({ openCancelAlert: true })}
                />
                <CancelAlert open={openCancelAlert} close={() => this.setState({ openCancelAlert: false })} location="/adminPortal/membership" />
              </Box>
              <Box>
                <span className={loading}>
                  <Loading />
                </span>
                <Btn disable={disableBtn} label="Next" onClick={handleSaveOnDB} bgColor="#e76f50" color="white" bgColorHover="#ff8c68" colorHover="white" width={128} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

PaymentMethod.propTypes = {
  handleInputs: PropTypes.func.isRequired,
  handleCard: PropTypes.func.isRequired,
  loading: PropTypes.string.isRequired,
  disableBtn: PropTypes.bool.isRequired,
  handleSaveOnDB: PropTypes.func.isRequired,
  values: PropTypes.shape({
    fee: PropTypes.number,
    paymentMethod: PropTypes.string,
  }).isRequired,
  messages: PropTypes.shape({
    fee: PropTypes.string,
    paymentMethod: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    fee: PropTypes.bool,
    paymentMethod: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }).isRequired,
  membershipID: PropTypes.string.isRequired,
};

export default PaymentMethod;
