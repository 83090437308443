import React from 'react';
import { Grid } from '@material-ui/core';
import Icon from '../../../../../assets/icons/information.png';

function FeesBox(props) {
  const fees = [
    {
      name: 'Family',
      icon: Icon,
      community: 8,
      associate: 15,
    },
    {
      name: 'Adult',
      icon: false,
      community: 5,
      associate: 10,
    },
    {
      name: 'Youth (13-17)',
      icon: false,
      community: 2,
      associate: 4,
    },
    {
      name: 'Senior (65+)',
      icon: false,
      community: 1,
      associate: 2,
    },
    {
      name: 'Child',
      icon: false,
      community: 1,
      associate: 2,
    },
  ];

  return (
    <Grid
      item
      className="select-category-fees-box"
      role="application"
      tabIndex="0"
      aria-label={ `Annual Membership Fees, There are two membership categories, Community and Associate. Community memberships are available to
      residents in our primary service boundaries: Spadina Ave, College St, Euclid Ave, and Front St W.
      Associate memberships are available to residents who live outside the catchment area.
      For renewals , please see reception.
      Prices.
      Family, Community, $8, Associate, $15.
      Adult, Community, $5, Associate, $10.
      Youth (13-17), Community, $2, Associate, $4.
      Senior (65+), Community, $1, Associate, $2.
      Child, Community, $1, Associate, $2.
      `}
    >
      <h2 className="select-category-fees-box-title">
        Annual Membership Fees
      </h2>
      <hr className="fees-box-custom-hr" />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="fees-box-table-top"
      >
        <Grid item className="fees-box-table-title">
          Type
        </Grid>
        <Grid item className="fees-box-table-title fees-box-table-title-small">
          Community
        </Grid>
        <Grid item className="fees-box-table-title fees-box-table-title-small">
          Associate
        </Grid>
      </Grid>
      {fees.map((item) => (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item className="fees-box-table-fees">
            {item.name}
            {item.icon && (
              <>
              &nbsp;
                <img src={Icon} alt="Question mark" width="12" height="12" />
              </>
            )}
          </Grid>
          <Grid item className="fees-box-table-fees fees-box-table-fees-small">
            $
            {item.community}
          </Grid>
          <Grid item className="fees-box-table-fees fees-box-table-fees-small">
            $
            {item.associate}
          </Grid>
        </Grid>
      ))}
      <hr className="fees-box-custom-hr-small" />
      <Grid container>
        <Grid item xs={12} className="fees-box-information">
          <span className="fees-highlight-text">Community memberships </span>
          are available to residents in our primary service boundaries:
           Spadina Ave, College St, Euclid Ave, and Front St W.
        </Grid>
        <Grid item xs={12} className="fees-box-information">
          <span className="fees-highlight-text">Associate memberships </span>
          are available to residents who live
           outside the catchment area.
        </Grid>
        <Grid item xs={12} className="fees-box-information remove-bottom-margin">
          <span className="fees-highlight-text">For renewals </span>
          , please see reception.
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FeesBox;
