import 'date-fns';
import React from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export default function DateTextField(props) {
  const {
    label,
    id,
    name,
    value,
    handleChanges,
    helperText,
    error,
    readOnly,
  } = props;

  const useStyles = makeStyles(() => ({
    root: {
      fontSize: 16,
      fontWeight: 500,
      fontFamily: 'Work Sans, sans-serif',
      marginTop: '0px',
      marginBottom: (error) ? '0px' : '20px',
      width: '100%',
    },
  }));
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        label={label}
        id={id}
        name={name}
        helperText={helperText}
        error={error}
        readOnly={readOnly}
        disableToolbar
        variant="inline"
        inputVariant="filled"
        format="MM/dd/yyyy"
        margin="normal"
        value={(value === '') ? new Date() : value}
        onChange={handleChanges}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        className={classes.root}
        orientation="landscape"
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
}

DateTextField.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  handleChanges: PropTypes.func.isRequired,
  helperText: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
};
