import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import TabPanel from '../../../../../materialUi/tab';
import SearchTextField from '../../../../../materialUi/searchTextField';
import '../../../../../css/receipts.css';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      history,
      match,
      selectedData,
      handleTabSelection,
      handleSearch,
    } = this.props;

    const tabItems = [
      'Members',
      'Guests',
      'Staff',
    ];

    return (
      <Grid container className="root receipts-header-spacing">
        <Grid className="mb-2">
          <button
            onClick={() => {
              if (match.params.backRoute === 'history') {
                history.push('/adminPortal/reports/checkInHistory');
              } else {
                history.push('/adminPortal/reports/dailyCheckInList');
              }
            }}
            type="button"
            className="reports-back-buttons"
          >
            &lt;  {match.params.backRoute === 'history' ? 'Back to Check-in History' : 'Back to Daily Check-in List'} 
          </button>
        </Grid>
        <Grid item xs={12} className="reports-receipts-title">
          Daily Check-In
        </Grid>
        <Grid item xs={6} className="mt-2 covid19-tabs">
          <TabPanel
            tabItems={tabItems}
            value={(selectedData === 'membersData') ? 0 : (selectedData === 'guestsData') ? 1 : 2}
            handleChange={(event, data) => handleTabSelection(event, data)}
          />
        </Grid>
        <Grid item xs={6} className="text-right">
          <SearchTextField
            label="Search by name, phone #, email"
            search={(value) => handleSearch(value.target.value)}
            width={296}
          />
        </Grid>
      </Grid>
    );
  }
}

Header.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      backRoute: PropTypes.string,
    }),
  }).isRequired,
  selectedData: PropTypes.string.isRequired,
  handleTabSelection: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
};
export default Header;
