import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import Logo from '../../../../assets/SCCC_LOGO_SMALL.png';
import { TIME } from '../../../../utils/dateController';
import '../../../../css/receipts.css';

function ReceiptPrint({ receiptData }) {
  let street; let city; let province; let
    postalCode;
  if (receiptData.address) {
    street = receiptData.address.street;
    city = receiptData.address.city;
    province = receiptData.address.province;
    postalCode = receiptData.address.postalCode;
  }
  return (
    <Grid id="receipt" className="font-family-default">
      <Grid container>
        <Grid item xs className="receipt-print-title">
          Receipt
        </Grid>
        <Grid className="col text-right">
          <img src={Logo} alt="logo" width="140" height="50" />
        </Grid>
      </Grid>

      <Grid container>
        <Box xs={2} lg={1}>
          <i className="fas fa-check-circle fa-2x receipt-alert-icon" />
        </Box>
        <Box className="receipt-alert-successfull-text">
            This transaction was successfully processed
        </Box>
      </Grid>

      <div className="col-12 border-receipt pb-3 pt-3 receipt-main-text mt-3">
            Receipt Details
        <div className="row pl-2">
          <div className="col-12 col-sm-12 col-md ">
            <div className="row mt-2">
              <div className="col-6 col-sm-6 col-md-4 pr-0">
                        Reference#:
              </div>
              <div className="col pl-0 receipt-detail-text">
                {receiptData.id}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md ">
            <div className="row mt-2">
              <div className="col-6 col-sm-6 col-md-4 pr-0">
                        Payment Method:
              </div>
              <div className="col pl-0 receipt-detail-text">
                {receiptData.paymentMethod}
              </div>
            </div>
          </div>
        </div>


        <div className="row pl-2">
          <div className="col-12 col-sm-12 col-md">
            <div className="row mt-2">
              <div className="col-6 col-sm-6 col-md-4 pr-0">
                        Name:
              </div>
              <div className="col pl-0 receipt-detail-text">
                {receiptData.name}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md">
            <div className="row mt-2">
              <div className="col-6 col-sm-6 col-md-4 pr-0">
                        Card Number:
              </div>
              <div className="col pl-0 receipt-detail-text">
                        N/A
              </div>
            </div>
          </div>
        </div>


        <div className="row pl-2">
          <div className="col-12 col-sm-12 col-md">
            <div className="row mt-2">
              <div className="col-6 col-sm-6 col-md-4 pr-0 ">
                        Address:
              </div>
              <div className="col pl-0 receipt-detail-text">
                {street}
,
                {' '}
                {city}
,
                {' '}
                {province}
,
                {' '}
                {postalCode}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md">
            <div className="row mt-2">
              <div className="col-6 col-sm-6 col-md-4 pr-0">
                        Card Type:
              </div>
              <div className="col pl-0 receipt-detail-text">
                {receiptData.cardType}
              </div>
            </div>
          </div>
        </div>

        <div className="row pl-2">
          <div className="col-12 col-sm-12 col-md">
            <div className="row mt-2">
              <div className="col-6 col-sm-6 col-md-4 pr-0">
                        Date & Time:
              </div>
              <div className="col pl-0 receipt-detail-text">
                {TIME(receiptData.paymentDateISO, false)}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md">
            <div className="row mt-2 total-hr">
              <hr />
            </div>
          </div>
        </div>

        <div className="row pl-2">
          <div className="col-12 col-sm-12 col-md">
            <div className="row mt-2">
              <div className="col-6 col-sm-6 col-md-4 pr-0">
                        Category:
              </div>
              <div className="col pl-0 receipt-detail-text">
                {receiptData.category}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md">
            <div className="row mt-2">
              <div className="col-6 col-sm-6 col-md-4 pr-0">
                        Total:
              </div>
              <div className="col pl-0 receipt-detail-text">
                ${receiptData.amount}
              </div>
            </div>
          </div>
        </div>

      </div>
    </Grid>
  );
}

ReceiptPrint.prototype = {
  receiptData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

export default ReceiptPrint;
