/* eslint-disable no-lone-blocks */
import React from 'react';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import MenuIcon from '../../../materialUi/menuIcon';
import BackButton from '../../../materialUi/backButton';
import '../../../css/verifyMembership.css';

function Header(props) {
  const { handleMenu, userData, history } = props;

  let optionList = [
    'Change Password',
  ];

  const { userType } = userData.userData;
  const { firstName } = userData.userData;
  const { lastName } = userData.userData;

  if (userType === 'Super Admin' || userType === 'Membership Admin') {
    optionList = [
      'Change Password',
      'Change Discount Password',
    ];
  }

  return (
    <>
      <Grid container className="font-family-default">
        {userType.toLowerCase() === 'security' && (
          <Grid item xs={12} style={{ margin: '20px 20px 0 20px' }}>
            <BackButton
              handleClick={() => history.push('/adminPortal/covid19')}
              label="Back to Covid19 Check In"
            />
          </Grid>
        )}
        <Grid item xs style={{ margin: '20px' }}>
          <Grid className="verify-member-title">Profile Setting</Grid>
        </Grid>
      </Grid>
      <Grid className="header-box">
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid className="user-name text-capitalize">
            {firstName}
            {' '}
            {lastName}
            <span className="user-type">{userType}</span>
          </Grid>
          <Grid>
            <Grid container>
              <Grid item xs={6} style={{ paddingLeft: '12px' }}>
                <MenuIcon width={250} list={optionList} onClick={(value) => handleMenu(value)} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

Header.propTypes = {
  userData: PropTypes.shape({
    userData: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      userType: PropTypes.string,
    }),
  }).isRequired,
  handleMenu: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(Header);
