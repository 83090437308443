import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid, Box } from '@material-ui/core';
import { ProgramRegistrationAPI } from '../../../../API/API-programRegistration';

class otherInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      familyMembers: [],
    };
  }

  componentDidMount() {
    const { handleHiddePrograms, membershipID } = this.props;
    ProgramRegistrationAPI.GET.getOneByMembershipID(membershipID).then((success) => {
      this.setState({ familyMembers: success.data });
      console.log(success.data);
      handleHiddePrograms('');
    }).catch((error) => {
      console.log(error);
      handleHiddePrograms('hidden');
    });
  }


  render() {
    const { familyMembers } = this.state;
    const { handleProramSelection } = this.props;
    return (
      <Paper className="body-boxes">
        <Grid item xs={12} className="attendanceLabel">Registered Programs</Grid>
        <Grid item xs={12}>To view attendance, select a program below. </Grid>
        <Grid item xs={12} className="boxes-spacing">
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            {familyMembers.map((value, index) => (
              <Box
                key={value.programID}
                className="programsLabels"
                onClick={() => handleProramSelection(value.programID)}
              >
                {value.programName}
              </Box>
            ))}
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

otherInfo.propTypes = {
  handleProramSelection: PropTypes.func.isRequired,
  handleHiddePrograms: PropTypes.func.isRequired,
  membershipID: PropTypes.string.isRequired,
};

export default otherInfo;
