import axios from 'axios';
import baseURL from './baseURL';
import TOKEN from './Frontend-Token';

export const ProgramRegistrationAPI = {
  POST: {
    addNewProgramRegistration(programRegistrationObj) {
      return axios.post(
        `${baseURL}api/addNewProgramRegistration`,
        programRegistrationObj,
        {
          headers: { 'x-access-token': TOKEN },
        },
      );
    },
    freeProgramRegistration(programRegistrationObj) {
      return axios.post(
        `${baseURL}api/freeProgramRegistration`,
        programRegistrationObj,
        {
          headers: { 'x-access-token': TOKEN },
        },
      );
    },
    waitingListRegistration(programRegistrationObj) {
      return axios.post(
        `${baseURL}api/waitingListRegistration`,
        programRegistrationObj,
        {
          headers: { 'x-access-token': TOKEN },
        },
      );
    },


    create(programRegistrationObj) {
      return axios.post(
        `${baseURL}api/programAdminRegistration`,
        {
          programRegistrationObj,
        },
        {
          headers: { 'x-access-token': TOKEN },
        }
      );
    },
    memberCreate(programRegistrationObj) {
      return axios.post(
        `${baseURL}api/programMemberRegistration`,
        {
          programRegistrationObj,
        },
        {
          headers: { 'x-access-token': TOKEN },
        }
      );
    },
  },
  PUT: {
    programWaitingListPayment(data, programRegistrationID, freeProgram = false) {
      return axios.put(
        `${baseURL}api/programWaitingListPayment/${programRegistrationID}/${freeProgram}`,
        data,
        {
          headers: { 'x-access-token': TOKEN },
        }
      );
    },
  },
  GET: {
    getRegistrationsByProgramID(programID) {
      return axios.get(`${baseURL}api/getRegistrationsByProgramID/${programID}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    getOneByMembershipID(membershipID) {
      return axios.get(`${baseURL}api/getProgramRegistrationByMembershipID/${membershipID}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    getPendingProgramRegistration(programRegistrationID) {
      return axios.get(`${baseURL}api/getPendingProgramRegistration/${programRegistrationID}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    getAllProgramRegistrations() {
      return axios.get(`${baseURL}api/getAllProgramRegistrations/`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    getProgramRegistrationDetails(programID, membershipID) {
      return axios.get(`${baseURL}api/getProgramRegistrationDetails/${programID}/${membershipID}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
    getRegistrationByWaitingListID(waitingListID) {
      return axios.get(`${baseURL}api/getRegistrationByWaitingListID/${waitingListID}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
  },
};

export default ProgramRegistrationAPI;
