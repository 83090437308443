import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

/* <---- User Profile ----> */
import VerifyMembership from '../membership/verifyMembership/verifyMembership';
import Receipts from '../membership/receipts/receipts';

// renew Membership
import RenewMembership from '../membership/renewMembership/renewMembership';

/* <---- Programs ----> */
import ProgramsList from '../programs/programsList/programsList';
import ProgramDetails from '../programs/programDetails/programDetails';
import ProgramRegistration from '../programs/programRegistration/programRegistration';
import WaitingListPayment from '../programs/waitingListPayment/waitingListPayment';


import MemberAttendance from '../programs/memberAttendance/memberAttendance';

/* <---- Tax ----> */
import TaxQuestionnaire from '../taxes/questionnaire/questionnaire';
import SubmissionList from '../taxes/submissionList/submissionList';
import LockedScreen from '../taxes/lockedScreen';
import EditTaxSubmission from '../taxes/editTaxSubmission/editTaxSubmission';
import ViewUserSubmission from '../taxes/viewUserSubmission/viewUserSubmission';

const MembersRoutes = function (userData) {
  if (userData !== '') {
    return (
      <Switch>
        {/* Programs */}
        <Route
          path="/scccPortal/programs/programRegistration/waitingListPayment/:programID/:waitingListID/:membershipID"
          render={(props) => <WaitingListPayment {...props} userData={userData} />}
        />
        <Route
          path="/scccPortal/programs/programRegistration/:programID/:backPage?"
          render={(props) => <ProgramRegistration {...props} userData={userData} />}
        />
        <Route
          path="/scccPortal/programs/memberProgramAttendance/:membershipID/:programID"
          component={MemberAttendance}
        />
        <Route
          path="/scccPortal/programs/programDetails/:programID"
          render={(props) => <ProgramDetails {...props} userData={userData} />}
        />
        <Route
          path="/scccPortal/programs"
          render={(props) => <ProgramsList {...props} userData={userData} />}
        />

        {/* Membership */}

        <Route path="/scccPortal/membership/renewMembership/:membershipID" render={(props) => <RenewMembership {...props} userData={userData} />} />

        <Route path="/scccPortal/membership/receipts/:membershipID" component={Receipts} />
        <Route
          path="/scccPortal/membership"
          render={(props) => <VerifyMembership {...props} userData={userData} />}
        />

        {/* Taxes */}
        <Route 
          path="/scccPortal/taxes/view/:formID" 
          render={(props) => <ViewUserSubmission {...props} userData={userData} />} 
        />
        <Route 
          path="/scccPortal/taxes/edit/:formID" 
          render={(props) => <EditTaxSubmission {...props} userData={userData} />} 
        />
        <Route
          path="/scccPortal/taxes/questionnaire"
          render={(props) => <TaxQuestionnaire {...props} userData={userData} />}
        />
        <Route
          path="/scccPortal/taxes/lock"
          render={(props) => <LockedScreen {...props} userData={userData} />}
        />
        <Route
          path="/scccPortal/taxes/submissions"
          render={(props) => <SubmissionList {...props} userData={userData} />}
        />

        <Route path="*" render={(props) => <VerifyMembership {...props} userData={userData} />} />
      </Switch>
    );
  }
};

MembersRoutes.propTypes = {
  userData: PropTypes.shape({
    userType: PropTypes.string,
  }).isRequired,
};

export default MembersRoutes;
