import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import TextField from '../../../materialUi/textField';
import Dropdown from '../../../materialUi/dropdown';
import { userAPI } from '../../../API/API-user';
import { validateField } from '../../../utils/fieldsValidation';
import { ProgramsAPI } from '../../../API/API-programs';
import '../../../css/settings.css';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class EditUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPrograms: false,
      programsSelected: [],
      deleteErrorMessage: false,
      values: {
        firstName: '',
        lastName: '',
        dayPhone: '',
        nightPhone: '',
        email: '',
        userType: 'Select One',
        selectedPrograms: '',
      },
      messages: {
        firstName: '',
        lastName: '',
        dayPhone: '',
        nightPhone: '',
        email: '',
        userType: '',
        selectedPrograms: '',
      },
      errors: {
        firstName: false,
        lastName: false,
        dayPhone: false,
        nightPhone: false,
        email: false,
        userType: false,
        selectedProgram: false,
      },
    };
  }

  async componentDidUpdate(previousProps) {
    const { usersData, userIndex } = this.props;
    const { values } = this.state;
    if (previousProps.userIndex !== userIndex) {
      values.firstName = usersData[userIndex].firstName;
      values.lastName = usersData[userIndex].lastName;
      values.email = usersData[userIndex].email;
      values.userType = usersData[userIndex].userType;
      values.dayPhone = usersData[userIndex].dayPhone;
      values.nightPhone = usersData[userIndex].nightPhone;
      let programsToshow = [];
      // <-- if the user is an instructor show the programs --->
      if (usersData[userIndex].userType === 'Program Staff') {

        // <--- get all programs on DB --->
        // check which programs are the instructor registered
        ProgramsAPI.GET.getProgramByInstructorID(usersData[userIndex]._id).then((success) => {
          console.log(success);
          programsToshow = success.data;
          this.setState({
            values, showPrograms: true, programsSelected: programsToshow, deleteErrorMessage: false,
          });
        }).catch((error) => {
          console.log(error);
        });
      } else {
        this.setState({
          values, showPrograms: false, programsSelected: programsToshow, deleteErrorMessage: false,
        });
      }
    }
  }

  handleInputs =(name, value, type, requirement = '') => {
    const {
      values,
      messages,
      errors,
    } = this.state;
    if (validateField(value, type, requirement) === true) {
      values[name] = value;
      messages[name] = '';
      errors[name] = false;
    } else {
      values[name] = value;
      messages[name] = validateField(value, type, requirement);
      errors[name] = true;
    }
    if (name === 'userType') {
      if (value === 'Program Staff') {
        values[name] = value;
        this.setState({ showPrograms: true, values });
      }
      if (value !== 'Program Staff') {
        values.selectedProgram = '';
        messages.selectedProgram = '';
        errors.selectedProgram = false;
        this.setState({ showPrograms: false, programsSelected: [] });
      }
    }
    this.setState({ values, messages, errors });
  }

  handleDeleteBtn = () => {
    const { programsSelected} = this.state;
    const { handleDelete } = this.props;
    if (programsSelected.length > 0) {
      this.setState({
        deleteErrorMessage: true,
      });
    } else {
      handleDelete();
    }
  }
  
  handleSubmit = () => {
    let fieldsvalidationResult = 0;
    const {
      values,
      messages,
      errors,
      programsSelected,
    } = this.state;
    const { userID } = this.props;

    Object.keys(errors).forEach((key) => {
      if (errors[key] === false) {
        fieldsvalidationResult++;
      }
    });
    console.log(fieldsvalidationResult);
    // <-- If the fields are validated -->
    if (fieldsvalidationResult === 7) {
      const userObj = {
        firstName: values.firstName,
        lastName: values.lastName,
        dayPhone: values.dayPhone,
        nightPhone: values.nightPhone,
        email: values.email,
        userType: values.userType,
        programs: programsSelected,
      };
      userAPI.PUT.editUser(userID, userObj).then((success) => {
        console.log(success);
        window.location.reload();
      }).catch((error) => {
        console.log(error.response);
        if (error.response) {
          if (error.response.data === 'email already exist') {
            messages.email = 'Email is already in use.';
            errors.email = true;
            this.setState({
              values, messages, errors,
            });
          }
        }
      });
    } else {
      Object.keys(errors).forEach((key) => {
        if (errors[key] !== false) {
          messages[key] = (messages[key].length > 0) ? messages[key] : 'Required Field';
          errors[key] = true;
        }
      });
      this.setState({ errors });
    }
  }

  render() {
    const userTypes = [
      { name: 'Select One' },
      { name: 'Super Admin' },
      { name: 'Program Admin' },
      { name: 'Membership Admin' },
      { name: 'Tax Volunteer' },
      { name: 'Program Staff' },
      { name: 'Receptionist' },
      { name: 'Security' },
    ];

    const {
      values,
      messages,
      errors,
      showPrograms,
      programsSelected,
      deleteErrorMessage,
    } = this.state;

    const {
      open,
      close,
    } = this.props;

    return (
      <Grid className="font-family-default">
        <Dialog
          onClose={() => close()}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth="md"
          fullWidth
        >
          <DialogContent dividers>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className="settings-edit-body"
            >
              <Box className="settings-edit-title">
                Edit
              </Box>
              <Grid onClick={this.handleDeleteBtn}>
                <DeleteIcon className="settings-delete-icon" fontSize="small" />
                <span className="settings-delete">Delete</span>
              </Grid>
            </Grid>
            <Grid container className="settings-deleteErrorMessage" id={deleteErrorMessage ? '' : 'hidden'}>
                Cannot delete a Program Staff that has an active program assigned.
            </Grid>
            <Grid container spacing={1} className="settings-edit-body">
              <Grid item xs={12} md={6}>
                <TextField
                  name="First Name"
                  label="First Name"
                  id="firstName"
                  value={values.firstName}
                  handleChanges={(value) => this.handleInputs('firstName', value.target.value, 'name', 'First Name')}
                  helperText={messages.firstName}
                  error={errors.firstName}
                  readOnly={false}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="last Name"
                  label="Last Name"
                  id="lastName"
                  value={values.lastName}
                  handleChanges={(value) => this.handleInputs('lastName', value.target.value, 'name', 'Last Name')}
                  helperText={messages.lastName}
                  error={errors.lastName}
                  readOnly={false}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="Day Phone"
                  label="Day Phone"
                  id="dayPhone"
                  value={values.dayPhone}
                  handleChanges={(value) => this.handleInputs('dayPhone', value.target.value, 'phone')}
                  helperText={messages.dayPhone}
                  error={errors.dayPhone}
                  readOnly={false}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="Night Phone"
                  label="Night Phone"
                  id="nightPhone"
                  value={values.nightPhone}
                  handleChanges={(value) => this.handleInputs('nightPhone', value.target.value, 'phone')}
                  helperText={messages.nightPhone}
                  error={errors.nightPhone}
                  readOnly={false}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="Email"
                  label="Email"
                  id="Email"
                  value={values.email}
                  handleChanges={(value) => this.handleInputs('email', value.target.value, 'email')}
                  helperText={messages.email}
                  error={errors.email}
                  readOnly={false}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Dropdown
                  name="User Type"
                  id="userType"
                  label="User Type"
                  value={values.userType}
                  handleChanges={(value) => this.handleInputs('userType', value.target.value, 'dropdown')}
                  helperText={messages.userType}
                  error={errors.userType}
                  listName={userTypes}
                />
              </Grid>
              {
                showPrograms && (
                  <Grid item xs={12}>
                    <b>Program Staff Programs:</b>
                    {' '}
                    {programsSelected.map((option, index) => (
                      <span
                        key={index}
                        className="settings-programSelection"
                        role="button"
                        tabIndex={0}
                        onClick={() => window.location = `/adminPortal/programs/programDetails/${option.programID}`}
                        onKeyPress={() => window.location = `/adminPortal/programs/programDetails/${option.programID}`}
                      >
                        {option.name}
                        ,
                        {' '}
                      </span>
                    ))}
                  </Grid>
                )
              }
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid className="settings-btns-body">
              <Button onClick={() => close()} className="alert-btns">
                Cancel
              </Button>
              <Button className="alert-btns" onClick={this.handleSubmit}>
                Update
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}


EditUsers.propTypes = {
  open: PropTypes.bool.isRequired,
  userID: PropTypes.string.isRequired,
  userIndex: PropTypes.number.isRequired,
  usersData: PropTypes.arrayOf(PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    userType: PropTypes.string,
    selectedPrograms: PropTypes.string,
    dayPhone: PropTypes.string,
    nightPhone: PropTypes.string,
    programs: PropTypes.any,
  })).isRequired,
  close: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default EditUsers;
