import axios from 'axios';
import baseURL from './baseURL';
import TOKEN from './Frontend-Token';

export const CardAPI = {
  Square: {
    cardPayment(nonce, membershipID, note, amount) {
      return axios.post(
        `${baseURL}api/process-payment`,
        {
          body: nonce,
          membershipID,
          note,
          amount,
        },
        {
          headers: { 'x-access-token': TOKEN },
        }
      );
    },
  },
};

export default CardAPI;
