import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import Header from './components/header';
import Table from './components/memberListTable';
import { membershipAPI } from '../../../API/API-membership';
import PreLoading from '../../../materialUi/preLoading';
import TableExport from './components/tableExport';

class MembershipList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      membershipData: [],
      search: '',
      status: [],
      selectedItems: [],
      values: {
        active: false,
        unverified: false,
        expiring: false,
        expired: false,
      },
    };
  }

  componentDidMount() {
    membershipAPI.GET.getAllMemberships()
      .then((success) => {
        this.setState({ membershipData: success.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleFilter = (name, value, selectedAge) => {
    const { values, selectedItems, status } = this.state;
    const updateValues = values;
    if (value === true) {
      updateValues[name] = value;
      this.setState({
        values: updateValues,
        status: status.concat([selectedAge]),
        selectedItems: selectedItems.concat([
          <Box className="memberList-selectedItems" key={selectedAge} name={name}>
            {selectedAge}
            <i
              label="filterItems"
              className="far fa-times-circle ml-2"
              onClick={() => this.handleFilter(name, false, selectedAge)}
              onKeyPress={() => this.handleFilter(name, false, selectedAge)}
              role="button"
              tabIndex={0}
            />
          </Box>,
        ]),
      });
    } else {
      // remove filter
      for (let i = 0; i < status.length; i++) {
        if (status[i] === selectedAge) {
          status.splice(i, 1);
        }
      }

      // remove selectedItems
      for (let index = 0; index < selectedItems.length; index++) {
        if (selectedItems[index].key === selectedAge) {
          selectedItems.splice(index, 1);
        }
      }

      updateValues[name] = value;
      this.setState({ values, status, selectedItems });
    }
  };

  resetFilters = () => {
    const { values } = this.state;
    for (const key in values) {
      values[key] = false;
    }
    this.setState({ values, status: [], selectedItems: [] });
  };

  handleMemberSelection = (event, membershipID) => {
    const { history } = this.props;
    history.push(`/adminPortal/membership/verifyMembership/${membershipID}`);
  };

  handleExport = (value) => {
    if (value === 'Print') {
      const printContent = document.getElementById('el').innerHTML;
      document.body.innerHTML = printContent;
      window.print();
      window.location = '/adminPortal/membership';
    } else {
      const table = document.getElementById('el').innerHTML;
      const convertTable = table
        .toString()
        .replace(/<div>/g, '')
        .toString()
        .replace(/<\/div>/g, '')
        .toString()
        .replace(/<table class="table">/g, '')
        .toString()
        .replace(/<\/table>/g, '')
        .toString()
        .replace(/<td>/g, '')
        .toString()
        .replace(/<\/td>/g, ',')
        .toString()
        .replace(/<tr>/g, '')
        .toString()
        .replace(/<\/tr>/g, '\r\n')
        .toString()
        .replace(/<th scope="col">/g, '')
        .toString()
        .replace(/<\/th>/g, ',')
        .toString()
        .replace(/<thead>/g, '')
        .toString()
        .replace(/<\/thead>/g, '')
        .toString()
        .replace(/<tbody>/g, '')
        .toString()
        .replace(/<\/tbody>/g, '')
        .toString()
        .replace(/<\/t>/g, '')
        .toString()
        .replace(/<\/n>/g, '');
      const myLink = document.createElement('a');
      myLink.download = 'membershipList.csv';
      myLink.href = `data:application/csv,${escape(convertTable)}`;
      myLink.click();
    }
  };

  render() {
    const { values, selectedItems, membershipData, search, status } = this.state;
    return (
      <Grid item xs className="root position-relative">
        <Header
          search={(value) => this.setState({ search: value })}
          handleExport={this.handleExport}
          values={values}
          handleFilter={this.handleFilter}
          selectedItems={selectedItems}
          resetFilters={this.resetFilters}
        />
        {membershipData.length === 0 && (
          <div className="loading-wrapper">
            <PreLoading />
          </div>
        )}
        <Table data={membershipData} sortSearch={search} sortStatus={status} handleMemberSelection={this.handleMemberSelection} />
        <div className="table-export" id="el">
          <TableExport data={membershipData} sortSearch={search} sortStatus={status} />
        </div>
      </Grid>
    );
  }
}

export default MembershipList;

MembershipList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
