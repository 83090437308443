import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import DateTextField from '../../../../materialUi/dateTextField';

function ProgramDuration(props) {
  const {
    control,
    formState: { errors },
    getValues,
    data,
    watch,
    register,
    setValue,
  } = props;

  const checkIfDateIsValid = (date) => {
    if (date.toString() === 'Invalid Date') {
      return 'Invalid Date Format';
    }
    return true;
  };

  const setWeeklySchedule = (startDate, endDate) => {
    const result = calculateWeeks(startDate, endDate);
    sessionStorage.setItem('originalSchedule', JSON.stringify(result));
    if (getValues('weekdaysOnly')) return false;
    data.weeklySchedule = result;
    register('weeklySchedule');
    setValue('weeklySchedule', data.weeklySchedule);
  };

  const handleStartDate = (value) => {
    const END_DATE = getValues('durationEndDate') ? new Date(getValues('durationEndDate')) : false;

    const START_DATE = new Date(value);

    if (END_DATE && START_DATE > END_DATE) {
      return 'Start Date cannot be more than End Date';
    }
    if (data.weeklyProgram === true) {
      setWeeklySchedule(START_DATE, END_DATE);
    }
    return checkIfDateIsValid(value);
  };

  const handleEndDate = (value) => {
    const START_DATE = getValues('durationStartDate') ? new Date(getValues('durationStartDate')) : false;

    const END_DATE = new Date(value);

    if (START_DATE && END_DATE < START_DATE) {
      return 'Start Date cannot be more than End Date';
    }

    if (data.weeklyProgram === true) {
      setWeeklySchedule(START_DATE, END_DATE);
    }
    return checkIfDateIsValid(value);
  };

  const calculateWeeks = (startDate, endDate) => {
    const start = startDate;
    const end = endDate;
    let sDate;
    let eDate;
    const dateArr = [];

    while (start <= end) {
      if (start.getDay() === 1 || (dateArr.length === 0 && !sDate)) {
        sDate = new Date(start.getTime());
      }

      if ((sDate && start.getDay() === 0) || start.getTime() === end.getTime()) {
        eDate = new Date(start.getTime());
      }

      if (sDate && eDate) {
        dateArr.push({
          startDate: sDate,
          endDate: eDate,
          communitySpots: data.communitySpots,
          associateSpots: data.associateSpots,
          programID: data.programID,
        });
        sDate = undefined;
        eDate = undefined;
      }

      start.setDate(start.getDate() + 1);
    }

    return dateArr;
  };

  React.useEffect(() => {
    watch('durationEndDate');
    watch('durationStartDate');
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <h2 className="addNewProgram-sub-titles">Program Duration</h2>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Controller
              name="durationStartDate"
              control={control}
              rules={{
                required: 'Duration start date is required',
                validate: (value) => handleStartDate(value),
              }}
              defaultValue={data.durationStartDate}
              render={({ field }) => (
                <DateTextField
                  label="Duration start date"
                  name="durationStartDate"
                  id="durationStartDate"
                  helperText={errors.durationStartDate ? errors.durationStartDate.message : ''}
                  error={!!errors.durationStartDate}
                  readOnly={false}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="durationEndDate"
              control={control}
              rules={{
                required: 'Duration end date is required',
                validate: (value) => handleEndDate(value),
              }}
              defaultValue={data.durationEndDate}
              render={({ field }) => (
                <DateTextField
                  label="Duration end date"
                  name="durationEndDate"
                  id="durationEndDate"
                  helperText={errors.durationEndDate ? errors.durationEndDate.message : ''}
                  error={!!errors.durationEndDate}
                  readOnly={false}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

ProgramDuration.propTypes = {
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  formState: PropTypes.shape({
    errors: PropTypes.any,
  }).isRequired,
  getValues: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  data: PropTypes.shape({
    durationStartDate: PropTypes.string,
    durationEndDate: PropTypes.string,
    weeklySchedule: PropTypes.arrayOf(PropTypes.any),
    associateSpots: PropTypes.number,
    communitySpots: PropTypes.number,
    programID: PropTypes.number,
  }).isRequired,
};

export default ProgramDuration;
