import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CONVERTTIME } from '../../../../utils/dateController';
import {
  changeStatus, searchMembers, pushMembershipResults,
} from './listFunctions';

class tableExport extends Component {

  render() {
    const rows = [];
    const {
      data,
      sortStatus,
      sortSearch,
    } = this.props;
    for (var i = 0; i < data.length; i++) {
      if (data[i].status !== 'inactive') {
        if (sortStatus.length > 0) {
          // eslint-disable-next-line
            sortStatus.map(status=>{
            if (status !== 'Unverified') {
              if (changeStatus(data[i]).toLowerCase() === status.toLowerCase()) {
                if (sortSearch) {
                  if (searchMembers(data[i], sortSearch)) {
                    return pushMembershipResults(rows, data[i]);
                  }
                } else {
                  return pushMembershipResults(rows, data[i]);
                }
              }
            } else if (changeStatus(data[i]).toLowerCase() === status.toLowerCase()) {
              if (sortSearch) {
                if (searchMembers(data[i], sortSearch)) {
                  return pushMembershipResults(rows, data[i]);
                }
              } else {
                return pushMembershipResults(rows, data[i]);
              }
            }
          });
        } else if (sortSearch) {
          if (searchMembers(data[i], sortSearch)) {
            pushMembershipResults(rows, data[i]);
          }
        } else {
          pushMembershipResults(rows, data[i]);
        }
      }
     
    }

    return (
      <div>

        <table className="table">
          <thead>
            <tr>
              <th scope="col">Membership ID</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Phone</th>
              <th scope="col">Registration Date</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>

            {rows.map((value, index) => (
              <tr key={index}>
                <td>
                  {value.membershipID}
                </td>
                <td className="text-capitalize"> 
                  {value.name}
                </td>
                <td>
                  {value.email}
                </td>
                <td>
                  {value.phone}
                </td>
                <td>
                  {CONVERTTIME(value.registrationDate, true)}
                </td>
                <td>
                  {value.status}
                </td>
              </tr>
            ))}

          </tbody>
        </table>

      </div>
    );
  }
}

tableExport.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  sortStatus: PropTypes.arrayOf(PropTypes.string).isRequired,
  sortSearch: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
};

export default tableExport;
