import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import DropdownIcon from '../../../../assets/icons/menu.png';
import MenuIcon from '../../../../materialUi/menuIcon';
import Btn from '../../../../materialUi/btn';
import Filter from './filter';
import '../../../../css/registrationList.css';

export default class RegistrationHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayFilter: false,
    };
  }

  handleDisplayFilter = () => {
    const { displayFilter } = this.state;
    const { resetFilters } = this.props;
    if (displayFilter) {
      this.setState({ displayFilter: false });
      // reset filter
      resetFilters('');
    } else {
      this.setState({ displayFilter: true });
    }
  };

  render() {
    const { handleBack, handleAddNewProgram, programName, handleChangeTable, handleExport, handleFilter, search, values, userType, attendanceNumberCheck, headerTotals, weeklySchedule } = this.props;

    const { displayFilter } = this.state;
    let options;
    if (userType === 'Receptionist' || attendanceNumberCheck) {
      options = ['Program Details', 'Download CSV', 'Print'];
    } else {
      options = ['Program Details', 'Attendance List', 'Download CSV', 'Print'];
    }

    return (
      <Grid container className="registrationList-spacing">
        <Grid item xs={12}>
          <Grid className="registrationList-back" onClick={handleBack} onKeyPress={handleBack} role="button" tabIndex={0}>
            &lt; Back to Programs List
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} xl={6}>
          <h1 className="registrationList-header">{programName}</h1>
        </Grid>
        <Grid item xs={12} md={8} xl={6}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Grid className="programsList-btn-createNew" id={userType === 'Program Staff' ? 'hidden' : ''}>
              <Btn label="REGISTER" onClick={handleAddNewProgram} bgColor="#e76f50" color="white" bgColorHover="#ff8c68" colorHover="white" width={128} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box onClick={() => handleChangeTable('Attendees')} onKeyPress={() => handleChangeTable('Attendees')} role="button" tabIndex={0} className="registrationList-box">
            <h2 className="registrationList-deactivatedList">Attendees {headerTotals.attendees}</h2>
          </Box>{' '}
          &nbsp; | &nbsp;{' '}
          {!weeklySchedule && (
            <>
              <Box 
                onClick={() => handleChangeTable('Waiting List')} 
                onKeyPress={() => handleChangeTable('Waiting List')} 
                role="button" 
                tabIndex={0} 
                className="registrationList-box"
              >
                <h2 className="registrationList-deactivatedList">Waiting List {headerTotals.waitingList}</h2>
              </Box>{' '}
              &nbsp; | &nbsp;{' '}
            </>
          )}
          <Box className="registrationList-box">
            <h2 className="registrationList-activeList">Program Registration {headerTotals.registrations}</h2>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs className="registrationList-filter-space">
              <Box onClick={() => this.handleDisplayFilter()} role="button" tabIndex={0} onKeyPress={() => this.handleDisplayFilter()} className="registrationList-filter">
                <img className="Filter" src={DropdownIcon} alt="Dropdown icon" />
                Filter
              </Box>
            </Grid>
            <Grid item xs>
              <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <MenuIcon width={180} list={options} onClick={(value) => handleExport(value)} />
              </Grid>
            </Grid>
            {displayFilter ? <Filter handleFilter={handleFilter} search={search} values={values} displaySort={false} /> : ''}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

RegistrationHeader.propTypes = {
  programName: PropTypes.string.isRequired,
  handleChangeTable: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  handleAddNewProgram: PropTypes.func.isRequired,
  handleExport: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  values: PropTypes.shape({
    approved: PropTypes.bool,
    pending: PropTypes.bool,
  }).isRequired,
  resetFilters: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired,
  attendanceNumberCheck: PropTypes.bool.isRequired,
  headerTotals: PropTypes.shape({
    registrations: PropTypes.number,
    attendees: PropTypes.number,
    waitingList: PropTypes.number,
  }).isRequired,
};
