import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import MenuIcon from '../../../../materialUi/menuIcon';
import '../../../../css/verifyMembership.css';


export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusBtn: '',
    };
  }

  handleDropdownItems = (value) => {
    const { history, match } = this.props;
    if (value.props.children) {
      history.push(`/adminPortal/membership/verifyMembership/${match.params.membershipID}`);
    }
  }

  render() {
    const {
      data,
      history,
      match
    } = this.props;
    const list = [
      <div key="viewMembership">View Membership</div>,
      <div key="print">Print</div>,
    ];


    return (
      <>
        <Grid container className="font-family-default">
          <Grid item xs style={{ margin: '20px' }}>
            <Grid
              className="Back-to-Member-list"
              onClick={() => history.push(`/adminPortal/programs/registrationList/${match.params.programID}`)}
              onKeyPress={() => this.handleLogout()}
              role="button"
              tabIndex={0}
            >
              &lt;  Back to Registrations List
            </Grid>
            <Grid className="verify-member-title">
              Program Participant Detail
            </Grid>
          </Grid>
        </Grid>
        <Grid className="header-box">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid className="member-name">
              {data.participantData.firstName} {data.participantData.firstName}
            </Grid>
            <Grid>
              <Grid container>
                <Grid item xs={6} style={{ marginTop: '16px' }}>
                  <span className="verifyMembership-Active">
                    {data.paymentStatus}
                  </span>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: '12px' }}>
                  <MenuIcon
                    width={200}
                    list={list}
                    onClick={(value) => this.handleDropdownItems(value)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

Header.propTypes = {
  data: PropTypes.shape({
    participantData: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    paymentStatus: PropTypes.string,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      programID: PropTypes.string,
      membershipID: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

Header.defaultProps = {
  data: {
    participantData: {
      firstName: '',
      lastName: '',
    },
    paymentStatus: '',
  },
};

