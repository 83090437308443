import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Box, Radio, RadioGroup, FormControl, FormGroup, FormControlLabel, Checkbox,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import Button from '../../../../../materialUi/btn';
import TextField from '../../../../../materialUi/textField';
import UploadDialog from './uploadDialog';
import PDFIcon from '../../../../../assets/icons/pdf-icon.svg';
import PPTIcon from '../../../../../assets/icons/pptIcon.png';
import imageIcon from '../../../../../assets/icons/imageIcon.png';
import docIcon from '../../../../../assets/icons/docIcon.png';
import '../../../../../css/taxes.css';

function SubmissionMethod(props) {
  const {
    control,
    formState: { errors },
    setValue,
    register,
    clearErrors,
    getValues,
    watch,
    data,
    handleAlert
  } = props;

  const pastYear = new Date().getFullYear() - 1;

  watch('submissionMethod');
  watch('documentsChecklist');

  const handleSubmissionMethod = (event) => {
    const { value } = event.target;
    setValue('submissionMethod', value);
    if (value !== 'email') {
      setValue('submissionEmail', '');
      clearErrors('submissionEmail');
    }
    if (getValues('submissionMethod') === 'upload' && !getValues('taxReturnFiles')) {
      register('uploadMethod', { required: 'Please check and upload all three required documents' });
      setValue('uploadMethod', '');
    } else {
      setValue('uploadMethod', 'null');
      clearErrors('uploadMethod');
      clearErrors('documentsChecklist');
    }
  };

  const [uploadDialog, setUploadDialog] = React.useState(false);
  const [taxReturnFiles, setTaxReturnFiles] = React.useState((data.taxReturnFiles) ? data.taxReturnFiles : []);

  const handleUploadDocuments = (selectedFiles) => {
    setUploadDialog(false);
    if (selectedFiles.length === 0) return false;
    register('selectedFiles');
    setValue('selectedFiles', selectedFiles);
    setTaxReturnFiles(Array.from(selectedFiles));
    setValue('uploadMethod', 'null');
    clearErrors('uploadMethod');
    if (selectedFiles.length === 0) {
      register('uploadMethod', { required: 'Please check and upload all three required documents' });
      setValue('uploadMethod', '');
    }
    clearErrors('documentsChecklist');
    handleAlert('success', 'Success', 'Files added to your tax return file!');
  };

  const renderIconBasedOnDocType = (item) => {
    if (item.type === 'application/msword' || item.fileType === '.doc' || item.fileType === '.docx') {
      return docIcon;
    } if (item.type === 'application/pdf' || item.fileType === '.pdf') {
      return PDFIcon;
    } if (item.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || item.fileType === '.pptx') {
      return PPTIcon;
    }
    return imageIcon;
  };

  const handleRemoveFile = async (index) => {
    const copyFiles = taxReturnFiles;
    copyFiles.splice(index, 1);
    setTaxReturnFiles(Array.from(copyFiles));
    data.taxReturnFiles = copyFiles;
    setValue('selectedFiles', copyFiles);
    if (copyFiles.length === 0) {
      register('uploadMethod', { required: 'Please check and upload all three required documents' });
      setValue('uploadMethod', null);
    }
    handleAlert('success', 'Success', 'File removed from your tax return file!');
  };

  const documentsList = [
    {
      label: 'Government Issued ID',
      value: 'government',
    },
    {
      label: 'All slips',
      value: 'all slips',
    },
    {
      label: `All rent receipts from January to December ${pastYear}`,
      value: 'all rents',
    },
  ];

  const [checklist, setChecklist] = React.useState([]);

  const handleDocumentsChecklist = (event) => {
    const { name } = event.target;
    const copyChecklist = checklist;
    if (copyChecklist.includes(name)) {
      copyChecklist.splice(copyChecklist.indexOf(name), 1);
    } else {
      copyChecklist.push(name);
    }
    setChecklist(copyChecklist);
    setValue('documentsChecklist', copyChecklist);
    if (copyChecklist.length >= 3) clearErrors('documentsChecklist');
  };

  React.useEffect(() => {
    register('submissionMethod', { required: 'Select one Submission Method' });
    if (getValues('submissionMethod') === 'upload') {
      if (!data.documentsChecklist) {
        register('documentsChecklist' , { required: 'Please check and upload all three required documents' });
      }
    }
    if (data.documentsChecklist) {
      setChecklist(data.documentsChecklist);
    }
  }, [data, register, errors]);

  return (
    <Grid item xs={12}>
      <RadioGroup
        aria-label="Submission Method"
        name="submissionMethod"
        onChange={handleSubmissionMethod}
      >
        <Grid item xs={12}>
          <div className="taxes-question">
            16. Please state through which method you will
            be submitting documents in order to file for your tax return.
          </div>
          <span className="custom-error">
            {(errors.submissionMethod) && errors.submissionMethod.message}
          </span>
        </Grid>
        <Grid item xs={12}>
          <div className="taxes-custom-radio-wrapper">
            <Radio
              title="emailMethod"
              className="YesNo"
              name="emailMethod"
              icon={<RadioButtonUncheckedIcon fontSize="medium" />}
              checkedIcon={<RadioButtonCheckedIcon fontSize="medium" />}
              value="email"
              checked={getValues('submissionMethod') === 'email'}
            />
            <span>
              E-mail
              <span className="radio-sub-label">
                *Note: you will receive an email requesting you to provide the documents.
              </span>
            </span>
          </div>
        </Grid>
        <Grid item xs={12} className="taxes-custom-textfield-wrapper">
          <Controller
            name="submissionEmail"
            control={control}
            rules={{
              required: (getValues('submissionMethod') === 'email') ? 'Email is required' : false,
              maxLength: { value: 100, message: 'Max length 100' },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Invalid Email Format',
              },
            }}
            render={({ field }) => (
              <TextField
                id="submissionEmail"
                type="search"
                name="submissionEmail"
                label="Enter email address"
                helperText={errors.submissionEmail ? errors.submissionEmail.message : ''}
                error={!!errors.submissionEmail}
                width="100%"
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="taxes-custom-radio-wrapper">
            <Radio
              title="uploadMethod"
              className="YesNo"
              name="uploadMethod"
              icon={<RadioButtonUncheckedIcon fontSize="medium" />}
              checkedIcon={<RadioButtonCheckedIcon fontSize="medium" />}
              value="upload"
              checked={getValues('submissionMethod') === 'upload'}
            />
            <span>
               Upload Documents (i.e. Photos, PDF)
              <span className="radio-sub-label">
                *Please make sure you have a smart device to take photos of your ID and documents.
              </span>
            </span>
          </div>
          <span className="custom-error">
            {(errors.uploadMethod) && errors.uploadMethod.message}
          </span>
        </Grid>
        <Grid item xs={12} className="taxes-custom-button-wrapper">
          {getValues('submissionMethod') === 'upload' && (
            <>
              <Grid item xs={12}>
                <b>Documents checklist:</b>
                <div className="custom-error">
                  {(errors.documentsChecklist) && errors.documentsChecklist.message}
                </div>
                  {
                    documentsList.map((item, index) => (
                      <FormControlLabel
                        key={item.value}
                        control={(
                          <Checkbox
                            checked={checklist.includes(item.value)}
                            onChange={(event) => handleDocumentsChecklist(event, index)}
                            name={item.value}
                          />
                          )}
                        label={item.label}
                      />
                    ))
                  }
              </Grid>
              <Button
                label="ADD DOCUMENTS"
                bgColor="#2A9D8F"
                color="white"
                colorHover="white"
                bgColorHover="#2A9D8F"
                outlineColor="#2A9D8F"
                width={245}
                onClick={() => setUploadDialog(true)}
                type="button"
              />
              <Grid container style={{ marginTop: '10px' }}>
                {taxReturnFiles.map((item, index) => (
                  <Box className="upload-dialog-document" key={item.name}>
                    <img
                      src={renderIconBasedOnDocType(item)}
                      alt="PDF icon"
                      width="25px"
                      height="25px"
                    />
                    <span className="upload-dialog-document-text">
                      {item.name || item.fileName}
                      <button
                        type="button"
                        className="upload-dialog-remove-file"
                        onClick={() => handleRemoveFile(index)}
                      >
                        <CloseIcon fontSize="small" />
                      </button>
                    </span>
                  </Box>
                ))}
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <span className="custom-error">
            {(errors.inPersonMethod) && errors.inPersonMethod.message}
          </span>
          <div className="taxes-custom-radio-wrapper">
            <Radio
              title="inPersonMethod"
              className="YesNo"
              name="inPersonMethod"
              icon={<RadioButtonUncheckedIcon fontSize="medium" />}
              checkedIcon={<RadioButtonCheckedIcon fontSize="medium" />}
              value="inPerson"
              checked={getValues('submissionMethod') === 'inPerson'}
            />
            <span>
              In-person drop off (at Scadding Court Community Centre, 707 Dundas Street W)
            </span>
          </div>
        </Grid>
        <UploadDialog
          open={uploadDialog}
          setOpen={() => { setUploadDialog(!uploadDialog); }}
          handleCancel={() => setUploadDialog(false)}
          handleSubmit={handleUploadDocuments}
          handleRemoveFile={handleRemoveFile}
          data={data}
        />
      </RadioGroup>
    </Grid>
  );
}

SubmissionMethod.propTypes = {
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  formState: PropTypes.shape({
    errors: PropTypes.any,
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  handleAlert: PropTypes.func.isRequired,
  data: PropTypes.shape(
    {
      submissionMethod: PropTypes.string,
      taxReturnFiles: PropTypes.any,
    },
  ).isRequired,
};

export default SubmissionMethod;
