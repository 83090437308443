import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Controller, useWatch } from 'react-hook-form';
import TextField from '../../../../materialUi/textField';
import Dropdown from '../../../../materialUi/dropdown';

function Demographics(props) {
    const {
        control,
        formState: { errors },
        getValues,
        watch,
        data,
        clearErrors,
    } = props;

    const genderList = [
        { name: 'All' },
        { name: 'Man' },
        { name: 'Woman' },
        { name: 'Non-binary' },
        { name: 'Woman and non-binary' },
        { name: 'Man and non-binary' },
    ];
    const membershipType = [{ name: 'All' }, { name: 'Family' }, { name: 'Adult' }, { name: 'Youth' }, { name: 'Senior' }, { name: 'Child' }, { name: 'Child and Youth' }];
    const agesList = [
        { name: 'All Ages' },
        { name: 'Pre-school (0-5)' },
        { name: 'Children (6-12)' },
        { name: 'Children (8-12)' },
        { name: 'Youth (13-17)' },
        { name: 'Adult (18+)' },
        { name: 'Senior' },
        { name: 'Child and Youth' },
        { name: 'Youth and Adult' },
        { name: 'Information & Referal' },
    ];

    React.useEffect(() => {
        watch('weeklyProgram');
        // if (!getValues('weeklyProgram')) {
        //   clearErrors('communitySpots');
        // }
    }, []);

    return (
        <>
            <Grid item xs={12}>
                <h1 className="addNewProgram-titles">
                    Demographics
                </h1>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="gender"
                            control={control}
                            rules={{
                                required: 'Gender is required',
                            }}
                            defaultValue={data.gender}
                            render={({ field }) => (
                                <Dropdown
                                    name="gender"
                                    label="Gender"
                                    id="gender"
                                    value=""
                                    handleChanges={() => { }}
                                    helperText={errors.gender ? errors.gender.message : ''}
                                    error={!!errors.gender}
                                    listName={genderList}
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="membershipType"
                            control={control}
                            rules={{
                                required: 'Gender is required',
                            }}
                            defaultValue={data.membershipType}
                            render={({ field }) => (
                                <Dropdown
                                    name="membershipType"
                                    label="Membership Type"
                                    id="membershipType"
                                    value=""
                                    handleChanges={() => { }}
                                    helperText={errors.membershipType ? errors.membershipType.message : ''}
                                    error={!!errors.membershipType}
                                    listName={membershipType}
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {!getValues('weeklyProgram') && (
                <Grid item xs={12}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name="communitySpots"
                                        control={control}
                                        defaultValue={data.communitySpots}
                                        rules={{
                                            validate: (value) => {
                                                console.log(value)
                                                if ((value === undefined || value === null) && getValues('weeklyProgram')) return true;
                                                if ((value === undefined || value === null) && !getValues('weeklyProgram')) return 'Community Spots is required';
                                                return true;
                                            },
                                            maxLength: { value: 4, message: 'Max length 4' },
                                            pattern: {
                                                value: /^(\d*([.,](?=\d{3}))?\d+)+((?!\2)[.,]\d\d)?$/i,
                                                message: 'Only numbers are allowed',
                                            },
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                id="communitySpots"
                                                type="number"
                                                name="communitySpots"
                                                label="Community Spots"
                                                helperText={errors.communitySpots ? errors.communitySpots.message : ''}
                                                error={!!errors.communitySpots}
                                                width="100%"
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name="associateSpots"
                                        control={control}
                                        defaultValue={data.associateSpots}
                                        rules={{
                                            validate: (value) => {
                                                if ((value === undefined || value === null) && getValues('weeklyProgram')) return true;
                                                if ((value === undefined || value === null) && !getValues('weeklyProgram')) return 'Associate Spots is required';
                                                return true;
                                            },
                                            maxLength: { value: 4, message: 'Max length 4' },
                                            pattern: {
                                                value: /^(\d*([.,](?=\d{3}))?\d+)+((?!\2)[.,]\d\d)?$/i,
                                                message: 'Only numbers are allowed',
                                            },
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                id="associateSpots"
                                                type="number"
                                                name="associateSpots"
                                                label="Associate Spots"
                                                helperText={errors.associateSpots ? errors.associateSpots.message : ''}
                                                error={!!errors.associateSpots}
                                                width="100%"
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="ages"
                                control={control}
                                rules={{
                                    validate: (value) => {
                                        if (!value && getValues('weeklyProgram')) return true;
                                        if (!value && !getValues('weeklyProgram')) return 'Ages is required';
                                        return true;
                                    },
                                }}
                                defaultValue={data.ages}
                                render={({ field }) => (
                                    <Dropdown
                                        name="ages"
                                        label="Ages"
                                        id="ages"
                                        value=""
                                        handleChanges={() => { }}
                                        helperText={errors.ages ? errors.ages.message : ''}
                                        error={!!errors.ages}
                                        listName={agesList}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {getValues('weeklyProgram') && getValues('weeklyProgram') === true && (
                <Grid item xs={12}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="associateSpots"
                                control={control}
                                defaultValue={data.associateSpots}
                                rules={{
                                    required: getValues('weeklyProgram') && getValues('weeklyProgram') === true ? 'Associate Spots is required' : false,
                                    maxLength: { value: 4, message: 'Max length 4' },
                                    pattern: {
                                        value: /^(\d*([.,](?=\d{3}))?\d+)+((?!\2)[.,]\d\d)?$/i,
                                        message: 'Only numbers are allowed',
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        id="associateSpots"
                                        type="search"
                                        name="associateSpots"
                                        label="Associate Spots"
                                        helperText={errors.associateSpots ? errors.associateSpots.message : ''}
                                        error={!!errors.associateSpots}
                                        width="100%"
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="communitySpots"
                                control={control}
                                defaultValue={data.communitySpots}
                                rules={{
                                    required: getValues('weeklyProgram') && getValues('weeklyProgram') === true ? 'Community Spots is required' : false,
                                    maxLength: { value: 4, message: 'Max length 4' },
                                    pattern: {
                                        value: /^(\d*([.,](?=\d{3}))?\d+)+((?!\2)[.,]\d\d)?$/i,
                                        message: 'Only numbers are allowed',
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        id="communitySpots"
                                        type="search"
                                        name="communitySpots"
                                        label="Community Spots"
                                        helperText={errors.communitySpots ? errors.communitySpots.message : ''}
                                        error={!!errors.communitySpots}
                                        width="100%"
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {getValues('weeklyProgram') && getValues('weeklyProgram') === true && (
                <Grid item xs={12}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="ageMinimum"
                                control={control}
                                defaultValue={data.ageMinimum}
                                rules={{
                                    required: getValues('weeklyProgram') && getValues('weeklyProgram') === true ? 'Age Minimum is required' : false,
                                    maxLength: { value: 2, message: 'Max length 2' },
                                    pattern: {
                                        value: /^(\d*([.,](?=\d{3}))?\d+)+((?!\2)[.,]\d\d)?$/i,
                                        message: 'Only numbers are allowed',
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        id="ageMinimum"
                                        type="search"
                                        name="ageMinimum"
                                        label="Age Minimum"
                                        helperText={errors.ageMinimum ? errors.ageMinimum.message : ''}
                                        error={!!errors.ageMinimum}
                                        width="100%"
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="ageMaximum"
                                control={control}
                                defaultValue={data.ageMaximum}
                                rules={{
                                    required: getValues('weeklyProgram') && getValues('weeklyProgram') === true ? 'Age Maximum is required' : false,
                                    maxLength: { value: 3, message: 'Max length 3' },
                                    pattern: {
                                        value: /^(\d*([.,](?=\d{3}))?\d+)+((?!\2)[.,]\d\d)?$/i,
                                        message: 'Only numbers are allowed',
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        id="ageMaximum"
                                        type="search"
                                        name="ageMaximum"
                                        label="Age Maximum"
                                        helperText={errors.ageMaximum ? errors.ageMaximum.message : ''}
                                        error={!!errors.ageMaximum}
                                        width="100%"
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    );
}

Demographics.propTypes = {
    control: PropTypes.objectOf(PropTypes.any).isRequired,
    formState: PropTypes.shape({
        errors: PropTypes.any,
    }).isRequired,
    setValue: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    getValues: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
    data: PropTypes.shape(
        {
            gender: PropTypes.string,
            membershipType: PropTypes.string,
            numberOfSpots: PropTypes.string,
            communitySpots: PropTypes.string,
            associateSpots: PropTypes.string,
            ages: PropTypes.string,
            ageMinimum: PropTypes.string,
            ageMaximum: PropTypes.string,
        },
    ).isRequired,
};

export default Demographics;
