import axios from 'axios';
import baseURL from './baseURL';
import TOKEN from './Frontend-Token';

export const SubmittedAttendanceAPI = {
  POST: {
    addNewAttendanceList(attendanceListObj) {
      return axios.post(
        `${baseURL}api/registerAttendanceList`,
        {
          attendanceListObj,
        },
        {
          headers: { 'x-access-token': TOKEN },
        }
      );
    },
  },
  GET: {
    getSubmittedAttendanceList(programID) {
      return axios.get(`${baseURL}api/getSubmittedAttendanceLists/${programID}`, {
        headers: { 'x-access-token': TOKEN },
      });
    },
  },
};
export default SubmittedAttendanceAPI;
