import React from 'react';
import PropTypes from 'prop-types';
import { CONVERTTIME } from '../../../../utils/dateController';
import '../../../../css/receipts.css';

function createData(
  membershipID,
  name,
  category,
  paymentDate,
  paymentMethod,
  amount,
  status,
  address,
  cardType,
  referenceNumber,
  paymentDateISO,
  description,
) {
  return {
    membershipID,
    name,
    category,
    paymentDate,
    paymentMethod,
    amount,
    status,
    address,
    cardType,
    referenceNumber,
    paymentDateISO,
    description,
  };
}
function tableExport({ data, searchItem, values }) {
  const rows = [];
  for (let i = 0; i < data.length; i++) {
    if (values.startDate !== '' && values.endDate !== '') {
      const dateAdjustment = new Date(data[i].paymentDate);
      dateAdjustment.setDate(dateAdjustment.getDate());
      if (
        new Date(data[i].paymentDate).toISOString() >= new Date(values.startDate).toISOString()
        && new Date(dateAdjustment).toISOString() <= new Date(values.endDate).toISOString()
      ) {
        if (searchItem) {
          if (
            data[i].name.toLowerCase().includes(searchItem.toLowerCase())
            || data[i].referenceNumber.toString().toLowerCase().includes(searchItem.toLowerCase())
            || data[i].membershipID.toString().toLowerCase().includes(searchItem.toLowerCase())
          ) {
            rows.push(
              createData(
                data[i].membershipID,
                data[i].name,
                data[i].category,
                CONVERTTIME(data[i].paymentDate, true),
                data[i].paymentMethod,
                data[i].fee,
                data[i].status,
                data[i].address,
                data[i].cardType,
                data[i].referenceNumber,
                data[i].paymentDate,
                data[i].description && data[i].description,
              ),
            );
          }
        } else {
          rows.push(
            createData(
              data[i].membershipID,
              data[i].name,
              data[i].category,
              CONVERTTIME(data[i].paymentDate, true),
              data[i].paymentMethod,
              data[i].fee,
              data[i].status,
              data[i].address,
              data[i].cardType,
              data[i].referenceNumber,
              data[i].paymentDate,
              data[i].description && data[i].description,
            ),
          );
        }
      }
    } else if (searchItem) {
      if (
        data[i].name.toLowerCase().includes(searchItem.toLowerCase())
          || data[i].referenceNumber.toString().toLowerCase().includes(searchItem.toLowerCase())
          || data[i].membershipID.toString().toLowerCase().includes(searchItem.toLowerCase())
      ) {
        rows.push(
          createData(
            data[i].membershipID,
            data[i].name,
            data[i].category,
            CONVERTTIME(data[i].paymentDate, true),
            data[i].paymentMethod,
            data[i].fee,
            data[i].status,
            data[i].address,
            data[i].cardType,
            data[i].referenceNumber,
            data[i].paymentDate,
            data[i].description && data[i].description,
          ),
        );
      }
    } else {
      rows.push(
        createData(
          data[i].membershipID,
          data[i].name,
          data[i].category,
          CONVERTTIME(data[i].paymentDate, true),
          data[i].paymentMethod,
          data[i].fee,
          data[i].status,
          data[i].address,
          data[i].cardType,
          data[i].referenceNumber,
          data[i].paymentDate,
          data[i].description && data[i].description,
        ),
      );
    }
  }

  return (
    <div>

      <table className="table">
        <thead>
          <tr>
            <th scope="col">Membership ID</th>
            <th scope="col">Name</th>
            <th scope="col">Category</th>
            <th scope="col">Description</th>
            <th scope="col">Payment date</th>
            <th scope="col">Payment Method</th>
            <th scope="col">Amount</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>

          {rows.map((row, index) => (
            <tr key={index}>
              <td>
                {row.membershipID}
              </td>
              <td>
                {row.name}
              </td>
              <td>
                {row.category}
              </td>
              <td>
                {row.description}
              </td>
              <td>
                {CONVERTTIME(row.paymentDate, true)}
              </td>
              <td>
                {row.paymentMethod}
              </td>
              <td>
                {' '}
$
                {' '}
                {row.amount}
.00
                {' '}
              </td>
              <td>
                {row.status}
              </td>
            </tr>
          ))}

        </tbody>
      </table>

    </div>
  );
}

tableExport.prototype = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  searchItem: PropTypes.string.isRequired,
  values: PropTypes.shape({
    startDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    endDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
  }).isRequired,
};

export default tableExport;
