import 'date-fns';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export default function MaterialUIPickers({
  label,
  id,
  name,
  value,
  handleChanges,
  helperText,
  error,
  readOnly,
  dateFormat,
}) {
  const useStyles = makeStyles(() => ({
    root: {
      fontSize: 16,
      fontWeight: 500,
      padding: '0 0px',
      fontFamily: 'Work Sans, sans-serif',
      marginTop: '0px',
      marginBottom: error ? '0px' : '25px',

      '& .MuiIconButton-root': {
        paddingBottom: '2px'
      },
      '& .MuiFormLabel-filled': {
        top: '5px'
      },
    },
    notchedOutline: {
      borderColor: 'darkgrey !important',
      height: 46,
      top: '0px',
    },
    adornedEnd: {
      paddingRight: '0px',
    },
    inputAdornedEnd: {
      paddingTop: '16px',
      paddingBottom: '5px'
    },
    labelRoot: {
      height: 40,
      fontSize: '14px',
      top: '0px',
      '&$labelFocused': {
        color: 'darkgrey',
        top: '5px',
      },
    },
    labelFocused: {},
  }));
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        className={classes.root}
        label={label}
        id={id}
        name={name}
        value={(value === '') ? new Date() : value}
        onChange={handleChanges}
        helperText={helperText}
        error={error}
        readOnly={readOnly}
        disableToolbar
        variant="inline"
        inputVariant="outlined"
        format={dateFormat}
        margin="normal"
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        InputProps={{
          classes: {
            notchedOutline: classes.notchedOutline,
            adornedEnd: classes.adornedEnd,
            inputAdornedEnd: classes.inputAdornedEnd,
          },
        }}
        InputLabelProps={{
          classes: {
            root: classes.labelRoot,
            focused: classes.labelFocused,
          },
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

MaterialUIPickers.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  handleChanges: PropTypes.func.isRequired,
  helperText: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  dateFormat: PropTypes.string,
};

MaterialUIPickers.defaultProps = { 
  dateFormat: 'MM/dd/yyyy',
}


/*
Implementation example:
  <DatePicker
    label="Start Date"
    id="startDate"
    name="Start Date"
    value={values.startDate}
    handleChanges={(value) => handleDate('startDate', value, 'date')}
    helperText={messages.startDate}
    error={messages.startDate !== ''}
    readOnly={false}
  />
*/
