import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
} from '@material-ui/core';
import CheckInHeader from './checkInHeader';
import Questions from './questions';
import ActionButtons from './actionButtons';
import CheckInResult from './checkInResult';
import { Covid19API } from '../../../API/API-covid19';
import AlertIcon from '../../../assets/icons/alert-circle.svg';
import TextField from '../../../materialUi/textField';
import { validateField } from '../../../utils/fieldsValidation';
import { checkUserContactPhone } from '../../../utils/supportFunctions';


export default class PreScreening extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0,
      openCancelAlert: false,
      screeningResult: false,
      prescreeningError: false,
      values: {
        Q1Answer: '',
        Q2Answer: '',
        Q3Answer: '',
        Q4Answer: '',
        phone: '',
      },
      messages: {
        phone: '',
      },
      errors: {
        phone: false,
      },
    };
  }

  componentDidMount() {
    const { selectedUser } = this.props;
    const { screeningResult, errors } = this.state;
    const preScreeningFromSession = JSON.parse(sessionStorage.getItem('preScreening'));
    if (preScreeningFromSession && preScreeningFromSession.preScreeningValidation) {
      this.setState({ currentStep: 1, screeningResult: preScreeningFromSession.screeningResult });
    } else {
      sessionStorage.setItem('preScreening', JSON.stringify({
        preScreeningValidation: false, screeningResult,
      }));
    }
    errors.phone = checkUserContactPhone(selectedUser) ? false : '';
    this.setState({ errors });
  }

  handleQuestions = (name, value) => {
    const { values } = this.state;
    values[name] = value.target.value;
    this.setState({ values });
  }

  handleInputs = (name, value, type, requirement = '') => {
    const { values, messages, errors } = this.state;
    if (validateField(value, type, requirement) === true) {
      values[name] = value;
      messages[name] = '';
      errors[name] = false;
    } else {
      values[name] = value;
      messages[name] = validateField(value, type, requirement);
      errors[name] = true;
    }
    this.setState({
      values, messages, errors, prescreeningError: false,
    });
  }


  handleLiveUpdate = (values) => {
    const { selectedUser, dailyCheckIn } = this.props;
    Covid19API.EntranceUpdate.checkIn(dailyCheckIn, values);
    if (!checkUserContactPhone(selectedUser)) {
      Covid19API.EntranceUpdate.updateUserInformation(selectedUser, values.phone);
    }
  }

  updateSessionValues =(skipPrescreening) => {
    const preScreeningFromSession = JSON.parse(sessionStorage.getItem('preScreening'));
    preScreeningFromSession.preScreeningValidation = true;
    preScreeningFromSession.screeningResult = this.validateSubmission(skipPrescreening);
    sessionStorage.setItem('preScreening', JSON.stringify(preScreeningFromSession));
  }

  handleCheckIn = (values, skipPrescreening) => {
    Covid19API.POST.userCheckIn(values).then((success) => {
      this.setState({ currentStep: 1, screeningResult: this.validateSubmission(skipPrescreening) });
      this.updateSessionValues(skipPrescreening);
      this.handleLiveUpdate(values);
    }).catch((err) => {
      console.log(err);
    });
  }

  checkIfUserAlreadyCheckedIn = () => {
    const { selectedUser, dailyCheckIn } = this.props;
    let listToCheck = 'members';
    if (selectedUser.userType === 'Guest') {
      listToCheck = 'guests';
    } else if (selectedUser.userType === 'Member') {
      listToCheck = 'members';
    } else {
      listToCheck = 'staff';
    }
    for (let i = 0; i < dailyCheckIn[listToCheck].length; i++) {
      if (dailyCheckIn[listToCheck][i] === selectedUser._id) {
        return true;
      }
    }
    return false;
  }

  validateSubmission = (skipPrescreening) => {
    const { values, errors, messages } = this.state;
    const checkScreeningError = ((!skipPrescreening) && (values.Q1Answer === '' || values.Q2Answer === '' || values.Q3Answer === '' || values.Q4Answer === ''));
    if (checkScreeningError) {
      this.setState({ prescreeningError: true });
      window.scrollTo(0, 0);
      return 'fail';
    }
    if (errors.phone !== false) {
      errors.phone = true;
      messages.phone = 'Required field!';
      this.setState({ errors, messages });
      return 'fail';
    }
    if (skipPrescreening) {
      values.Q1Answer = 'No';
      values.Q2Answer = 'No';
      values.Q3Answer = 'No';
      values.Q4Answer = 'No';
      this.setState({ values });
    }
    const screeningResult = ((values.Q1Answer === 'No' && values.Q2Answer === 'No' && values.Q3Answer === 'No' && values.Q4Answer === 'No') || (skipPrescreening));
    return screeningResult;
  }

  handleSubmit = (skipPrescreening) => {
    const { values } = this.state;
    const { selectedUser, dailyCheckIn } = this.props;
    values.selectedUser = selectedUser;
    values.screeningResult = this.validateSubmission(skipPrescreening);
    values.dailyCheckInID = dailyCheckIn.dailyCheckInID;
    values.userAlreadyCheckedIn = this.checkIfUserAlreadyCheckedIn();

    if (this.validateSubmission(skipPrescreening) !== 'fail') {
      this.handleCheckIn(values, skipPrescreening);
    }
  }

  render() {
    const {
      openCancelAlert,
      currentStep,
      values,
      screeningResult,
      messages,
      errors,
      prescreeningError,
    } = this.state;
    const { selectedUser, handleBack } = this.props;
    return (
      <Grid item xs={12} className="root covid19-wrapper">
        <Grid container>

          {currentStep === 0 && (
            <>
              <CheckInHeader
                handleBack={handleBack}
                handleSubmit={this.handleSubmit}
                selectedUser={selectedUser}
              />
              <Questions
                questions={values}
                handleQuestions={this.handleQuestions}
                prescreeningError={prescreeningError}
              />
              {!checkUserContactPhone(selectedUser) && (
                <Grid item xs={6} className="covid19-screening-spacing mt-2">
                  <div className="pb-2">
                    <img src={AlertIcon} alt="Missing information icon" className="covid19-userFound-icon" />
                    <span className="covid19-prescreening-missingInformation">
                      Required: Contact Information
                    </span>
                  </div>
                  <TextField
                    label="Phone Number"
                    id="phone"
                    name="Phone"
                    value={values.phone}
                    handleChanges={(value) => this.handleInputs('phone', value.target.value, 'phone', false)}
                    helperText={messages.phone}
                    error={errors.phone}
                    readOnly={false}
                  />
                </Grid>
              )}
              <Grid item xs={12} className="covid19-screening-spacing mt-5">
                <ActionButtons
                  handleSubmit={this.handleSubmit}
                  openCancelAlert={openCancelAlert}
                  handleCancelAlert={(value) => this.setState({ openCancelAlert: value })}
                />
              </Grid>
            </>
          )}

          {currentStep === 1 && (
            <CheckInResult
              screeningResult={screeningResult}
              selectedUser={selectedUser}
              handleBack={handleBack}
            />
          )}

        </Grid>
      </Grid>
    );
  }
}


PreScreening.propTypes = {
  selectedUser: PropTypes.shape({
    userType: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  handleBack: PropTypes.func.isRequired,
  dailyCheckIn: PropTypes.shape({
    dailyCheckInID: PropTypes.string,
  }).isRequired,
};
