import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import { Covid19API } from '../../../API/API-covid19';
import MenuIcon from '../../../materialUi/menuIcon';
import ChangeCapacity from './changeCapacity';
import '../../../css/covid19.css';

export default class Capacity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      capacity: 0,
      capacityID: '',
      openCapacityChange: false,
    };
  }

  componentDidMount() {
    Covid19API.GET.getCovid19Capacity()
      .then((success) => {
        this.setState({ capacityID: success.data[0].capacityID });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const { handleViewUsers, showBackButton, dailyCheckIn, userData } = this.props;
    const { openCapacityChange, capacityID } = this.state;
    const options = ['Edit Capacity'];
    return (
      <>
        {showBackButton && (
          <Grid item xs={2}>
            <span
              className="addNewAttendance-back"
              onClick={() => handleViewUsers(false)}
              onKeyPress={() => handleViewUsers(false)}
              role="button"
              tabIndex={0}
            >
              &lt; Back
            </span>
          </Grid>
        )}
        <Grid item xs>
          <Grid
            container
            direction="row"
            justify={window.innerWidth < 600 ? 'center' : 'flex-end'}
            alignItems="center"
          >
            <Box className="covid19-capacity-box">
              <h2 className="covid19-max-label">{dailyCheckIn.capacity}</h2>
              <h3 className="covid19-max-text">Max Capacity Allowed</h3>
              {userData.userData.userType === 'Super Admin' && (
                <div className="covid19-change-capacity">
                  <MenuIcon
                    list={options}
                    onClick={() => this.setState({ openCapacityChange: true })}
                  />
                </div>
              )}
            </Box>
            <Box className="covid19-capacity-current-box">
              <Grid container>
                <Grid item xs={6}>
                  <h2 className="covid19-current-label">
                    {dailyCheckIn.capacity - dailyCheckIn.availableSpots}
                  </h2>
                </Grid>
                <Grid item xs={6}>
                  <h3 className="covid19-current-text">People currently in Facility</h3>
                </Grid>
                <Grid item xs={12}>
                  <h3 className="covid19-current-bottom">
                    {dailyCheckIn.availableSpots} people left allowed to enter
                  </h3>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <ChangeCapacity
            open={openCapacityChange}
            close={() => this.setState({ openCapacityChange: false })}
            capacityID={capacityID}
            dailyCheckIn={dailyCheckIn}
          />
        </Grid>
      </>
    );
  }
}

Capacity.propTypes = {
  handleViewUsers: PropTypes.func.isRequired,
  showBackButton: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]).isRequired,
  dailyCheckIn: PropTypes.shape({
    availableSpots: PropTypes.number,
    capacity: PropTypes.number,
  }).isRequired,
  userData: PropTypes.shape({
    userType: PropTypes.string,
  }).isRequired,
};
