import React from 'react';
import { Grid, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Btn from '../../../../materialUi/btn';


export default function SubmitButtons({ handleBack, handleSubmit }) {
  return (
    <Grid item xs={12} className="addProgram-btns" style={{paddingBottom: '40px'}}>
      <Grid container direction="row" justifyContent="flex-end" alignItems="center">
        <Box style={{ marginRight: '5px' }}>
          <Btn
            label="Previous"
            bgColor="white"
            color="black"
            bgColorHover="#eceff1"
            colorHover="black"
            borderColor="white"
            variant="outlined"
            width={128}
            onClick={handleBack}
            type="submit"
          />
        </Box>
        <Btn
          label="Submit"
          bgColor="#e76f50"
          color="white"
          colorHover="white"
          bgColorHover="#ff8c68"
          width={128}
          onClick={handleSubmit}
          type="submit"
        />
      </Grid>
    </Grid>
  );
}

SubmitButtons.propTypes = {
  handleBack: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
