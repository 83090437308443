/* eslint-disable no-lonely-if */
import React from 'react';
import PropTypes from 'prop-types';
import { calculateAgeGroup } from '../../../../utils/dateController';
import '../../../../css/receipts.css';

function createData(membershipID, firstName, lastName, gender, age, phone, address, status, apt) {
  return {
    membershipID,
    firstName,
    lastName,
    gender,
    age,
    phone,
    address,
    status,
    apt
  };
}
function TableExport({
  data, search, sortAgeGroup, sortStatus,
}) {
  const rows = [];

  const formatAddress = (memberAddress) => {
    const address = `${memberAddress.street} - ${memberAddress.city} ${memberAddress.province} - ${memberAddress.postalCode}`;
    return address;
  };

  const getApt = (memberAddress) => {
    const apt = `${memberAddress.apt && memberAddress.apt}`;
    return apt;
  }


  const sortSearchTextField = (userData) => userData.firstName.toLowerCase().includes(search.toLowerCase())
    || userData.lastName.toLowerCase().includes(search.toLowerCase())
    || userData.phone.toLowerCase().includes(search.toLowerCase());

  const sortByAge = (userAge, age) => userAge === age;

  const sortByStatus = (userStatus, userExpirationDate, status) => {
    const expirationDate = new Date(userExpirationDate);
    const todayDate = new Date();
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 30);
    if (status === 'expired') {
      if (expirationDate < todayDate) {
        return true;
      }
    } else if (status === 'expiring') {
      if (expirationDate > todayDate && expirationDate < endDate) {
        return true;
      }
    } else if (status === 'unverified') {
      if (userStatus.toLowerCase() === 'unverified') {
        return true;
      }
    } else {
      if (expirationDate > todayDate && expirationDate < endDate) {
        return false;
      }
      if (expirationDate < todayDate) {
        return false;
      }
      return userStatus.toLowerCase() === status.toLowerCase();
    }
  };

  const getMembershipStatus = (membershipData) => {
    const membershipDate = new Date(membershipData.expirationDate);
    const todayDate = new Date();
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 30);

    if (membershipData.status === 'Unverified') {
      return 'Unverified';
    }
    if (membershipDate > todayDate && membershipDate < endDate) {
      return 'Expiring';
    }
    if (membershipDate < todayDate) {
      return 'Expired';
    }
    return 'Active';
  };

  const pushFilterResults = (userData) => {
    rows.push(
      createData(
        userData.membershipID,
        userData.firstName,
        userData.lastName,
        userData.gender,
        calculateAgeGroup(userData.dateOfBirth),
        userData.phone,
        formatAddress(userData.membershipData[0].address),
        getMembershipStatus(userData.membershipData[0]),
        getApt(userData.membershipData[0].address)
      ),
    );
  };

  if (data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].membershipData[0]) {
        if (data[i].membershipData[0].status !== 'inactive') {
          if (search) {
            if (sortSearchTextField(data[i])) {
              if (sortAgeGroup.length > 0) {
                sortAgeGroup.map((age) => {
                  if (sortByAge(calculateAgeGroup(data[i].dateOfBirth), age)) {
                    if (sortStatus.length > 0) {
                      sortStatus.map((status) => {
                        if (
                          sortByStatus(
                            data[i].membershipData[0].status,
                            data[i].membershipData[0].expirationDate,
                            status,
                          )
                        ) {
                          return pushFilterResults(data[i]);
                        }
                        return '';
                      });
                    } else {
                      return pushFilterResults(data[i]);
                    }
                  }
                  return '';
                });
              } else {
                if (sortStatus.length > 0) {
                  sortStatus.map((status) => {
                    if (
                      sortByStatus(
                        data[i].membershipData[0].status,
                        data[i].membershipData[0].expirationDate,
                        status,
                      )
                    ) {
                      return pushFilterResults(data[i]);
                    }
                    return '';
                  });
                } else {
                  pushFilterResults(data[i]);
                }
              }
            }
          } else {
            if (sortAgeGroup.length > 0) {
              sortAgeGroup.map((age) => {
                if (sortByAge(calculateAgeGroup(data[i].dateOfBirth), age)) {
                  if (sortStatus.length > 0) {
                    sortStatus.map((status) => {
                      if (
                        sortByStatus(
                          data[i].membershipData[0].status,
                          data[i].membershipData[0].expirationDate,
                          status,
                        )
                      ) {
                        return pushFilterResults(data[i]);
                      }
                      return '';
                    });
                  } else {
                    return pushFilterResults(data[i]);
                  }
                }
                return '';
              });
            } else {
              if (sortStatus.length > 0) {
                sortStatus.map((status) => {
                  if (
                    sortByStatus(
                      data[i].membershipData[0].status,
                      data[i].membershipData[0].expirationDate,
                      status,
                    )
                  ) {
                    return pushFilterResults(data[i]);
                  }
                  return '';
                });
              } else {
                pushFilterResults(data[i]);
              }
            }
          }
        }
      }
    }
  }


  return (
    <div>
      <table className="table" id="membership_table">
        <thead>
          <tr>
            <th scope="col">Membership ID</th>
            <th scope="col">First Name</th>
            <th scope="col">Last Name</th>
            <th scope="col">Gender</th>
            <th scope="col">Age Group</th>
            <th scope="col">Phone</th>
            <th scope="col">Address</th>
            <th scope="col">Apt</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              <td>{row.membershipID}</td>
              <td className="text-capitalize">{row.firstName}</td>
              <td className="text-capitalize">{row.lastName}</td>
              <td>{row.gender}</td>
              <td>{row.age}</td>
              <td>{row.phone}</td>
              <td>{row.address}</td>
              <td>{row.apt}</td>
              <td>{row.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

TableExport.prototype = {
  data: PropTypes.shape({
    membersData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    guestsData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    staffData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  }).isRequired,
  values: PropTypes.shape({
    preSchool: PropTypes.bool,
    child: PropTypes.bool,
    youth: PropTypes.bool,
    adult: PropTypes.bool,
    senior: PropTypes.bool,
  }).isRequired,
  sortAgeGroup: PropTypes.arrayOf(PropTypes.any).isRequired,
  sortStatus: PropTypes.arrayOf(PropTypes.any).isRequired,
  search: PropTypes.string.isRequired,
};

export default TableExport;
