import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Btn from '../../../../../materialUi/btn';
import CheckBox from '../../../../../materialUi/checkBox';
import { BootstrapTextField } from '../../../../../materialUi/bootstrapInputs';
import '../../../../../css/success.css';

function emailConfirmation({
  values,
  messages,
  errors,
  handleInputs,
  handleSendEmail,
}) {
  return (
    <Grid container>
      <Grid item xs={12} className="Successful-title">Successful!</Grid>
      <Grid item xs={12} className="Successful-paragraph"> Membership Type Successfully changed!</Grid>
      <Grid item xs={12} className="Successful-confirmation">Confirmation and Receipt</Grid>
      <Grid item xs={12} className="Successful-email">Email Membership Confirmation</Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} md={1} className="Success-email-label">Email:</Grid>
          <Grid item xs={12} md={9}>
            <BootstrapTextField
              id="email"
              type="text"
              name="email"
              value={values.email}
              handleChanges={(value) => handleInputs('email', value.target.value, 'email', true)}
              message={messages.email}
              readOnly={false}
              error={errors.email}
            />
            <CheckBox fontSize={15} bottom={0} value={values.emailReceipt} click={(value) => handleInputs('emailReceipt', value, 'not required')} message="Email Receipt" />
          </Grid>
          <Grid item xs={2} md={2} className="sendEmail-btn">
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Btn label="SEND" onClick={handleSendEmail} bgColor="#2a9d8f" color="white" bgColorHover="#414158" colorHover="#ff8c68" width={94} height={45} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

emailConfirmation.propTypes = {
  values: PropTypes.shape({
    email: PropTypes.string,
    emailReceipt: PropTypes.bool,
  }).isRequired,
  messages: PropTypes.shape({
    email: PropTypes.string,
    emailReceipt: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    email: PropTypes.bool,
    emailReceipt: PropTypes.bool,
  }).isRequired,
  handleInputs: PropTypes.func.isRequired,
  handleSendEmail: PropTypes.func.isRequired,
};

export default emailConfirmation;
