import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Btn from '../../../materialUi/btn';
import PhoneIcon from '../../../assets/icons/userFound-phone.svg';
import EmailIcon from '../../../assets/icons/userFound-email.svg';
import AlertIcon from '../../../assets/icons/alert-circle.svg';
import { identifyUserType, checkUserContactPhone } from '../../../utils/supportFunctions';

function UsersFound({ userData, handleSelectedUser }) {
  return (
    <Grid container className="covid19-userFound-box">
      <Grid
        item
        xs={3}
        sm={2}
        className={`covid19-${identifyUserType(userData)}`}
      >
        {identifyUserType(userData) === 'member' && 'M'}
        {identifyUserType(userData) === 'guest' && 'G' }
        {identifyUserType(userData) !== 'guest' && identifyUserType(userData) !== 'member' && 'S' }
      </Grid>
      <Grid item xs={9} sm={7}>
        <Grid container className="covid19-userFound-info">
          <Grid item xs={12}>
            <h3 className="covid19-userFound-name">
              {`${userData.firstName} ${userData.lastName}` }
            </h3>
          </Grid>
          <Grid item xs={12}>
            <h3 className="covid19-userFound-email">
              <img
                src={(!userData.email || userData.email === '') ? AlertIcon : EmailIcon}
                alt={(!userData.email || userData.email === '') ? 'Email icon' : 'No email found'}
                width="16px"
                height="16px"
                className="covid19-userFound-icon"
              />
              {(!userData.email || userData.email === '') ? <span className="covid19-text-alert">No email found</span> : userData.email}
            </h3>
          </Grid>
          <Grid item xs={12}>
            <h3 className="covid19-userFound-phone">
              <img
                src={checkUserContactPhone(userData) ? PhoneIcon : AlertIcon}
                width="16px"
                height="16px"
                alt="User email"
                className="covid19-userFound-icon"
              />
              {(checkUserContactPhone(userData))
                ? userData.phone || userData.dayPhone || userData.nightPhone
                : <span className="covid19-text-alert">No phone number found</span>}
            </h3>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs className="covid19-userFound-btn">
        <Btn
          label="CheckIn"
          onClick={() => handleSelectedUser(userData)}
          bgColor="#2A9D8F"
          color="#ffffff"
          bgColorHover="#2A9D8F"
          variant="outlined"
          borderColor="#2A9D8F"
          height={34}
          width={119}
          ariaLabel={`${userData.firstName} ${userData.lastName}, email,${userData.email}, phone number,${userData.phone || userData.dayPhone || userData.nightPhone}CheckIn`}
        />
      </Grid>
    </Grid>
  );
}

UsersFound.propTypes = {
  userData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    dayPhone: PropTypes.string,
    nightPhone: PropTypes.string,
  }).isRequired,
  handleSelectedUser: PropTypes.func.isRequired,
};

export default UsersFound;
